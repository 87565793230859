import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import { IActivity } from "./ViewProfileScreenController.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  profileId: string | number;
  accountType: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: { id: string; attributes: { [key: string]: string | null } }[];
  readMorendex: string | number;
  page: number;
  has_more: boolean;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
export default class ActivitySectionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getActivityId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      data: [],
      readMorendex: "",
      page: 1,
      has_more: false,
      loading: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(_from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getActivityId) {
      this.setState({ loading: false });
      if (responseJson?.data) {
        const count = responseJson.meta.more_records
          ? this.state.page + 1
          : this.state.page;
        this.setState({
          data: [...this.state.data, ...responseJson.data],
          has_more: responseJson.meta.more_records,
          page: count,
        });
      } else {
        this.setState({ data: [] });
      }
    }
    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    this.getActivtyFn(this.props.profileId, this.props.accountType);
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.profileId !== prevProps.profileId) {
      this.setState({ data: [], page: 1 });
      this.getActivtyFn(this.props.profileId, this.props.accountType);
    }
  }

  readMoreFn = (
    e: React.MouseEvent<HTMLElement>,
    id: string | number | null
  ) => {
    e.stopPropagation();
    if (id) {
      this.setState({ readMorendex: id });
    }
  };

  showMoreList = () => {
    this.getActivtyFn();
  };
  returnVideoName = (type?: string | null) => {
    return type === "Vimeo" ? "VIMEO.COM" : "YOUTUBE.COM";
  };

  getActivtyFn = async (id?: string | number, type?: string) => {
    this.setState({ loading: true });
    const param =
      type === "community" ? `&community_id=${id}` : `&account_id=${id}`;
    const loginTokn = await getStorageData("LoginSuccessToken");
    const signupToken = await getStorageData("SignupSuccessToken");
    const header = {
      token: signupToken ?? loginTokn,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getActivityId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getactivityEnd +
        `?page=${this.state.page}&per_page=3` +
        (id ? param : "")
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getOtherProfileApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  navigateofPost = (id: string | number) => {
    const navigationMsg = new Message(
      getName(MessageEnum.NavigationPostMessage)
    );
    navigationMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    navigationMsg.addData(getName(MessageEnum.UrlPathParamMessage), id);
    this.send(navigationMsg);
  };
}
// Customizable Area End
