import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes?: any;
    openModal: boolean;
    handleClose: any;
    handleSubmitClick: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean;
    imageLoad: string;
    uploadImage: any | ArrayBuffer | null | unknown;
    uploadImageError: boolean;
    uploadImageErrorMsg: string;
    drop: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
// Customizable Area Start
export default class ChangeWallpaperModalController extends BlockComponent<
    Props,
    S,
    SS
> {
    apiCreatePostCallId: any
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            imageLoad: "upload",
            uploadImage: "",
            uploadImageError: false,
            uploadImageErrorMsg: "",
            drop: "Or drag a file here",
        }
    }

    handleModalClose = () => {
        this.setState({
            imageLoad: "upload", drop: "Or drag a file here",
            uploadImage: "", uploadImageError: false, uploadImageErrorMsg: ""
        })
        this.props.handleClose()
    }

    

    handleDrop = (event: any) => {
        event.preventDefault();
        this.setState(
            { drop: "Uploading...", uploadImageError: false, uploadImageErrorMsg: "" }
        )
        const uploadedFilesArray = event.dataTransfer.files;
        if (uploadedFilesArray[0]?.type?.includes("image")) {
            this.setState({ uploadImage: uploadedFilesArray[0] })
        }
        else {
            this.setState(
                { drop: "Or drag a file here" }
                )
            toast.error("Please upload a valid image type file")
        }
    };

    ReaderImg = (e: any) => {
        const uploadedFile = e.target.files[0]
        if (uploadedFile.type?.includes("image")) {
            this.setState(
                { drop: "Uploading...",
                 uploadImageError: false, 
                 uploadImageErrorMsg: "" }
            )
            this.setState(
                    { uploadImage: e.target.files[0] }
                )
        }
        else {
            toast.error("Please upload a valid image type file")
            this.setState(
                { drop: "Or drag a file here" }
            )
        }
    }

    

    handleDragOver = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
    }

    handleChangeTab = (tab: string) => {
        this.setState({
            imageLoad: tab, drop: "Or drag a file here",
            uploadImage: "", uploadImageError: false, uploadImageErrorMsg: ""
        })
    }

    handleDragEnd = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
    }

    handleUploadImage = () => {
        if (this.validatePostImage()) {
            this.props.handleSubmitClick(this.state.uploadImage)
        }
    }

    validatePostImage = () => {
        let isValid = true
        if (!this.state.uploadImage) {
            this.setState({
                uploadImageError: true,
                uploadImageErrorMsg: "Please upload an image"
            })
            isValid = false
        }
        return isValid;
    }

   

    async componentDidMount() {
        super.componentDidMount();
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): Promise<void> {
        if (!prevProps.openModal && this.props.openModal) {
            this.setState({
                imageLoad: "upload", drop: "Or drag a file here",
                uploadImage: "", uploadImageError: false, uploadImageErrorMsg: ""
            })
        }
    }

    async receive(_from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );


        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const otpAuthTkn = message.getData(
                getName(MessageEnum.AuthTokenDataMessage)
            );
        }
        // Customizable Area End
    }

}

// Customizable Area End