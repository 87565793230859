import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { getSignupLoginTokens, handleErrorArray } from "../../../components/src/common";
// Customizable Area Start
import { toast } from "react-toastify";
import React, {  RefObject } from 'react';
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isOtpSent: boolean;
    isSignUp: boolean;
    otpFormFields: string[];
    otpTiming: number;
    errorMessage: string;
    fullPhoneNumber: string;
    isDisabled:boolean;
    fieldError: string;
    otpRelatedError: string;
    isLoadingShow: boolean;
    otpValidateToken: string;
    isVerficationSkipped: boolean;
    focus: number;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
// Customizable Area Start
export default class PhoneVerificationLoginFlowController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    sendOtpForPhoneVerificationApiCallId: string = "";
    verifyOtpForVerificationApiCallId: string = "";
    skipVerificationApiCallId: string = "";
    textInputRefs: RefObject<HTMLInputElement>[];
    // Customizable Area End
    constructor(props: Props) {
        
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start

        this.state = {
            isOtpSent: false,
            isSignUp: false,
            focus: 0,
            otpFormFields: ['','','',''],
            otpTiming: 60,
            errorMessage: '',
            fullPhoneNumber: '',
            fieldError: '',
            otpRelatedError: '',
            isDisabled:false,
            isLoadingShow: false,
            otpValidateToken: "",
            isVerficationSkipped: false
        }

        this.subScribedMessages = [
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];
        this.textInputRefs = Array.from({ length: 4 }).map(() => React.createRef());

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    
    async receive(_from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        this.setState({
            isLoadingShow: false
        });
        if(apiRequestCallId === this.sendOtpForPhoneVerificationApiCallId){
            this.handleSendOtpForVerificationApiResponse(responseJson);
        }
        if(apiRequestCallId === this.verifyOtpForVerificationApiCallId){
            this.handleVerifyOtpVerificationApiResponse(responseJson);
        }
        if(apiRequestCallId === this.skipVerificationApiCallId){
            this.handleSkipVerificationApiResponse(responseJson);
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const isSingUp = this.props.navigation.getParam("navigationBarTitleText")
        if(isSingUp === 'issignup'){
            this.setState({isSignUp: true})
        }
    }

    handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const phoneRegex = /^[0-9\b]+$/;
        this.setState({errorMessage: "", fieldError: ""});
        if (e.target.value === "" || phoneRegex.test(e.target.value)) {
            this.setState({ fullPhoneNumber: e.target.value })
        }
    };

   
    handleOTPInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const { value } = e.target;
        const nextIndex = index + 1;
        const prevIndex = index - 1;
        const newValue = value.slice(-1);

        this.setState((prevState) => {
          const updatedFields = [...prevState.otpFormFields];
          updatedFields[index] = newValue;
          return { otpFormFields: updatedFields };
        }, () => {
          if (newValue && nextIndex < 4) {
            // Move focus to the next field if a digit is entered
            this.textInputRefs[nextIndex].current?.focus();
          } else if (!newValue && prevIndex >= 0) {
            // Move focus to the previous field if a digit is erased
            this.textInputRefs[prevIndex].current?.focus();
          }
        });
      };
      
      
      handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement |HTMLDivElement>, index: number) => {
        if (e.key === "Backspace") {
          const { value } = e.target as HTMLInputElement;
          const prevIndex = index - 1;
      
          if (value === "" && prevIndex >= 0) {
            // Clear the previous field and move focus to it
            this.setState((prevState) => {
              const updatedFields = [...prevState.otpFormFields];
              updatedFields[prevIndex] = "";
              return { otpFormFields: updatedFields };
            }, () => {
              this.textInputRefs[prevIndex].current?.focus();
            });
          }
        }
      };
      
      
      
    validatePhoneNumber = () => {
        let isValidPhoneNumber = true;
        if(this.state.fullPhoneNumber.trim().length === 0){
            this.setState({
                errorMessage: "Please Enter Phone number"
            });
            isValidPhoneNumber = false;
        }
        return isValidPhoneNumber;
    }

    validateOtp = () => {
        let isValid = true;
        this.state.otpFormFields.forEach((otp: string) => {
            if(otp.trim().length === 0){
                isValid = false;
            }
        });
        if(!isValid){
            this.setState({fieldError:"", otpRelatedError: "Enter 4 digit OTP"});
        }
        return isValid;
    }

    rseendCode = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        this.startTimer();
        this.handlePhoneNumberSubmit();
    }
    handlePhoneNumberSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
        console.log("fghjkl",this.state.isSignUp)
        e?.preventDefault();
        this.setState({
            otpFormFields: ["","","",""]
        })
        if(!this.validatePhoneNumber()){
            return false;
        }
        this.showLoading();
        const { signupToken, loginToken } = await getSignupLoginTokens();

        const bodyData = {
            data: {
                attributes:{
                    full_phone_number: this.state.fullPhoneNumber
                }
            }
        }

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: signupToken || loginToken
        };
        const signupHeader = {
            "Content-Type": configJSON.validationApiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.sendOtpForPhoneVerificationApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.state.isSignUp ? configJSON.PhoneVerficationEndPointWeb :configJSON.sendOtpForLoginVerificationApiEndpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify( this.state.isSignUp ?  signupHeader : header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.sendOtpForLoginVerificationApiMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(bodyData),
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleOtpSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(!this.validateOtp()){
            return false;
        }
        this.showLoading();

        const httpBody = {
            "otp_token": this.state.otpValidateToken,
            pin: this.state.otpFormFields.join('')
        };
        const signUpBody = {
            "token": this.state.otpValidateToken,
            pin: this.state.otpFormFields.join('')
        };
        const { signupToken, loginToken } = await getSignupLoginTokens();

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: signupToken || loginToken
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.verifyOtpForVerificationApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.state.isSignUp ? configJSON.phoneOTPEndpointWeb : configJSON.verifyOtpForLoginVerificationApiEndpoint 
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.verifyOtpForLoginVerificationApiMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(this.state.isSignUp ? signUpBody : httpBody),
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    handleSkipVerificationClick = () => {

        this.setState({
            isVerficationSkipped: true
        });
       toast.success("Veirfication will be skipped for 30 days, Verify your phone number!")
    }

    skipVerificationApiCall = async () => {
        this.showLoading();
        const { signupToken, loginToken } = await getSignupLoginTokens();

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: signupToken || loginToken
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.skipVerificationApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.skipVerificationApiEndpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.skipVerificationApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    showLoading = () => {
        this.setState({
            isLoadingShow: true
        });
    }

    startTimer = () => {
        // Disable the button to prevent multiple clicks
        this.setState({ isDisabled: true });
    
        // Set the timer for 60 seconds
      let timerInterval = setInterval(() => {
          this.setState((prevState) => ({
           otpTiming: prevState.otpTiming - 1,
          }));
    
          // Check if the timer has reached 0
          if (this.state.otpTiming < 0) {
            // Enable the button and reset the timer
            this.setState({
              otpTiming: 60,
              isDisabled: false,
            });
    
            // Clear the interval to stop the timer
            clearInterval(timerInterval);
          }
        }, 1000);
      };
    

    handleSendOtpForVerificationApiResponse = (responseJson: {meta:{token:string},errors:{[keys: string]:string}[]}) => {
        if(responseJson.meta){
            this.setState({
                otpValidateToken: responseJson.meta.token,
                isOtpSent: true,
               
            });
         
        } else if(responseJson.errors){
            handleErrorArray(responseJson.errors);
        }
    }

    handleVerifyOtpVerificationApiResponse = (responseJson: { meta: { token: string }, messages: [{ otp: string }], errors: { [keys: string]: string }[] }) => {
        if (responseJson && responseJson.meta && this.state.isSignUp) {
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), "UserProfileInformation");
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        }else if (responseJson && responseJson.messages) {
            toast.success(responseJson.messages[0].otp);
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingScreen");
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
            if (this.state.isVerficationSkipped) {
                this.skipVerificationApiCall();
            }

        } else if (responseJson.errors) {
            handleErrorArray(responseJson.errors);
        }
    }

    handleSkipVerificationApiResponse = (responseJson: {message: string}) => {
        
        if(responseJson && responseJson.message){
            toast.success(responseJson.message);
            const nevigate = new Message(getName(MessageEnum.NavigationMessage));
            nevigate.addData(getName(MessageEnum.NavigationTargetMessage), "LandingScreen");
            nevigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(nevigate); 
        }
    }

    // Customizable Area End
    
}
// Customizable Area End