import React from "react";

// Customizable Area Start
import ViewOtherUserProfileController, {
  Props,
} from "./ViewOtherUserProfileController.web";
import UserProfileWallpaperSection from "./UserProfileWallpaperSection.web";
import UserProfileInformationSection from "./UserProfileInformationSection.web";
import OrganizationProfileSectionWeb from "./OrganizationProfileSection.web";
import ActivitySection from "./ActivitySection.web";
import AchievementSection from "./AchievementSection.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

// Customizable Area Start

export default class ViewOtherUserProfile extends ViewOtherUserProfileController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const is_private = this.state.data.attributes.is_private && !this.state.data.attributes.is_in_connection && !this.isOwnProfile
    return (
      <React.Fragment>
        {this.state.showLoading && <Loader loading={this.state.showLoading} />}
        <UserProfileWallpaperSection
          data={this.state.data}
          is_private={is_private}
          navigation={this.props.navigation}
          id={"wallpaer-section"}
          isOwnProfile={this.isOwnProfile}
          isCommunity={this.isCommunity}
        />
        {!is_private && (
          <>
            {
              <UserProfileInformationSection
                data={this.state.data}
                navigation={this.props.navigation}
                id={"information-section"}
                isOwnProfile={this.isOwnProfile}
                isCommunity={this.isCommunity}
                mutualConnectionData={this.state.mutualConnectionData}
              />
            }
            {!this.isCommunity && (
              <OrganizationProfileSectionWeb
                data={this.state.data}
                navigation={this.props.navigation}
                id={"org-section"}
                isOwnProfile={this.isOwnProfile}
              />
            )}
            {this.state.data.id && (
              <ActivitySection
                accountType={this.isCommunity ? "community" : "account"}
                profileId={this.state.data.id}
                navigation={this.props.navigation}
              />
            )}
            <AchievementSection
              isOwnProfile={this.isOwnProfile}
              removeAchievement={this.removeAchievement}
              data={(this.state.data.attributes?.achievements?.data ?? []).map(
                (item) => ({
                  id: item.id,
                  type: item.type,
                  attributes: item.attributes,
                })
              )}
              navigation={this.props.navigation}
            />
          </>
        )}
      </React.Fragment>
    );
  }
}
// Customizable Area End
