Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
const urlConfig = require("../../../framework/src/config");
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.getUserEndPoint = "get_user";

exports.btnExampleTitle = "CLICK ME";
exports.baseURL = urlConfig.baseURL;
exports.LikeEndPoint = "like_unlike_post";
exports.getApiMethode= "GET";
exports.post="posts/posts/";
exports.events= "bx_block_events/events/";
exports.PostAPIEndpoint= "POST";
exports.community_data= "community_data";
exports.getUserProfileDataApiEndPoint = "get_user?is_post_invisible=true";
exports.getCommunityProfileDataApiEndPoint = 'get_community?is_post_invisible=true';
// Customizable Area End