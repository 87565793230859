import React from "react";
import {
  Box,
  Typography,
  // Customizable Area Start
  Grid,
  ListItem,
  Card,
  Avatar,
  Switch,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LogoutModal from "./LogoutModal.web";
import UserIcon from "./SvgIcon/UserIcon.web";
import ReasonIcon from "./SvgIcon/ReasonIcon.web";
import PasswordIcon from "./SvgIcon/PasswordIcon.web";
import DropdownIcon from "./SvgIcon/DropdownIcon.web";
import BackIcon from "./SvgIcon/BackIcon.web";
import EditIcon from "./SvgIcon/EditIcon.web";
import { Lock , Logout, Notification, Privacy, Support, Deactive, Eye, Hideeye, Profile} from "./assets";
import "./Settings.web.css";
import CustomTextField from "../../../components/src/CustomTextField.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import Loader from "../../../components/src/Loader.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

 import SettingsController, { Props } from "./SettingsController";
/*istanbul ignore next */
export default class Settings extends SettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSubBranch = () => {
    return (
      <>
        {this.state.selectedSetting === "profile" && (
          <Card style={webStyle.cardContent}>
            <Grid item xs={12}>
              <Typography variant="body1" style={webStyle.txtHeader}>
                Edit Profile
              </Typography>
            </Grid>
            <hr style={webStyle.horizontal} />
            <Grid item xs={12}>
              <Typography variant="body1" style={webStyle.DisplaName}>
                Display Name
              </Typography>
              <Typography variant="body1" style={webStyle.setDisplayName}>
                Set my Display Name
              </Typography>
            </Grid>
            <Grid item xs={12} style={webStyle.cardGrid}>
              <div style={{ display: "flex" }}>
                <div>
                  <Typography variant="body1" style={webStyle.userNameText}>
                    Username
                  </Typography>

                  <Typography variant="body1" style={webStyle.userNameSub}>
                    You can add any username.
                  </Typography>
                </div>
                <div
                  style={webStyle.editIcon}
                  id="username-edit"
                  onClick={this.openUsernameTab}
                >
                  {" "}
                  <EditIcon
                    width="24"
                    height="24"
                    viewBox="0 0 34 34"
                    stroke={"#808080"}
                    strokeWidth="2.0"
                    fill={"none"}
                  />
                </div>
              </div>
            </Grid>

            <br></br>
            <br></br>
          </Card>
        )}
        {this.state.selectedSetting === "notification" && (
          <Card style={webStyle.cardContent} className="SettingMainBox">
            <Grid item xs={12}>
              <Typography variant="body1" style={webStyle.txtHeader}>
                Notification Settings
              </Typography>
            </Grid>
            <hr style={webStyle.horizontal} />

            <Grid item xs={12} style={{ display: "flex" }}>
              <Typography variant="body1" style={webStyle.DisplaName}>
                Assessment Notification
              </Typography>
              <div
                style={{
                  position: "absolute",
                  right: "7rem",
                  marginTop: "23px",
                }}
              >
                <Switch
                  checked={this.state.AssessmentCheck}
                  onChange={this.handleAssessmentToggle}
                />
              </div>
            </Grid>

            <Grid item xs={12} style={{ display: "flex" }}>
              <Typography variant="body1">
                <div style={webStyle.assesmentScore}> Assessment Score</div>
                <div style={webStyle.AssessmentScoreSub}>Change in Score</div>
              </Typography>
              <div
                style={{
                  position: "absolute",
                  right: "7rem",
                  marginTop: "23px",
                }}
              >
                <Switch
                  checked={this.state.AssessmentScore}
                  onChange={this.handleAssessmentScoreToggle}
                />
              </div>
            </Grid>

            <Grid item xs={12} style={{ display: "flex" }}>
              <Typography variant="body1">
                <div style={webStyle.assesmentScore}>Assessment Alerts</div>
                <div style={webStyle.AssessmentScoreSub}>
                  Organization created an assessment to complete.
                </div>
              </Typography>
              <div
                style={{
                  position: "absolute",
                  right: "7rem",
                  marginTop: "23px",
                }}
              >
                <Switch
                  checked={this.state.AssessmentAlert}
                  onChange={this.handleAssessmentAlertToggle}
                />
              </div>
            </Grid>

            <Grid item xs={12} style={{ display: "flex" }}>
              <Typography variant="body1">
                <div style={webStyle.assesmentScore}>Assessment Reminder</div>
                <div style={webStyle.AssessmentScoreSub}>
                  Assessment is closing in 7 days.
                </div>
              </Typography>
              <div
                style={{
                  position: "absolute",
                  right: "7rem",
                  marginTop: "23px",
                }}
              >
                <Switch
                  checked={this.state.AssesmentRemainder}
                  onChange={this.handleAssessmentRemainderToggle}
                />
              </div>
            </Grid>

            <Grid item xs={12} style={{ display: "flex" }}>
              <Typography variant="body1">
                <div style={webStyle.assesmentScore}>Assessment Reminder</div>
                <div style={webStyle.AssessmentScoreSub}>
                  Assessment is closing in 24 hours.
                </div>
              </Typography>
              <div
                style={{
                  position: "absolute",
                  right: "7rem",
                  marginTop: "23px",
                }}
              >
                <Switch
                  checked={this.state.AssesmentRemainder}
                  onChange={this.handleAssessmentRemainderToggle}
                />
              </div>
            </Grid>

            <Grid item xs={12} style={{ display: "flex" }}>
              <Typography variant="body1" style={webStyle.DisplaName}>
                Appointment Reminder
              </Typography>
              <div
                style={{
                  position: "absolute",
                  right: "7rem",
                  marginTop: "23px",
                }}
              >
                <Switch
                  checked={this.state.AssesmentRemainder}
                  onChange={this.handleAssessmentRemainderToggle}
                />
              </div>
            </Grid>

            <Grid item xs={12} style={{ display: "flex" }}>
              <Typography variant="body1" style={webStyle.DisplaName}>
                Chat Notification
              </Typography>
              <div
                style={{
                  position: "absolute",
                  right: "7rem",
                  marginTop: "23px",
                }}
              >
                <Switch />
              </div>
            </Grid>

            <Grid item xs={12} style={{ display: "flex" }}>
              <Typography variant="body1" style={webStyle.DisplaName}>
                Content Notification
              </Typography>
              <div
                style={{
                  position: "absolute",
                  right: "7rem",
                  marginTop: "23px",
                }}
              >
                <Switch
                  checked={this.state.AssessmentContent}
                  onChange={this.handleContentAlertToggle}
                />
              </div>
            </Grid>

            <Grid item xs={12} style={{ display: "flex" }}>
              <Typography variant="body1">
                <div style={webStyle.assesmentScore}>Community Content</div>
                <div style={webStyle.AssessmentScoreSub}>
                  Content uploaded by community.
                </div>
              </Typography>
              <div
                style={{
                  position: "absolute",
                  right: "7rem",
                  marginTop: "23px",
                }}
              >
                <Switch checked={this.state.AssessmentContent} />
              </div>
            </Grid>

            <Grid item xs={12} style={{ display: "flex" }}>
              <Typography variant="body1">
                <div style={webStyle.assesmentScore}>
                  Peer Professional Content
                </div>
                <div style={webStyle.AssessmentScoreSub}>
                  Content uploaded by peer professional.
                </div>
              </Typography>
              <div
                style={{
                  position: "absolute",
                  right: "7rem",
                  marginTop: "23px",
                }}
              >
                <Switch checked={this.state.AssessmentContent} />
              </div>
            </Grid>
          </Card>
        )}

        {this.state.selectedSetting === "privacy" && (
          <Card style={webStyle.cardContentPrivacy} className="SettingMainBox">
            <Grid item xs={12}>
              <Typography variant="body1" style={webStyle.txtHeader}>
                Privacy
              </Typography>
            </Grid>
            <hr style={webStyle.horizontal} />
            <Grid item xs={12}>
              <Typography variant="body1" style={webStyle.DisplaName}>
                Account Privacy
              </Typography>
            </Grid>

            <Grid item xs={12} style={webStyle.cardGrid}>
              <div style={{ display: "flex" }}>
                <div>
                  <Typography variant="body1" style={webStyle.userNameText}>
                    Public Account
                  </Typography>
                  <Typography variant="body1" style={webStyle.userNameSub}>
                    Anyone can search your account.
                  </Typography>
                </div>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value="public"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    test-id="radioPublic"
                    onClick={this.publicSubmit}
                    style={{ position: "absolute", right: "7rem" }}
                    value={this.state.public_post}
                    control={<Radio />}
                    label=""
                  />
                </RadioGroup>
              </div>
            </Grid>
            <br></br>
            <br></br>
            <Grid item xs={12} style={webStyle.cardGrid}>
              <div style={{ display: "flex" }}>
                <div>
                  <Typography variant="body1" style={webStyle.userNameText}>
                    Private Account
                  </Typography>
                  <Typography variant="body1" style={webStyle.userNameSub}>
                    Your account is non-searchable.
                  </Typography>
                </div>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value="private"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    test-id="radioPrivate"
                    onClick={this.privateSubmit}
                    style={{ position: "absolute", right: "7rem" }}
                    value={this.state.private_post}
                    control={<Radio />}
                    label=""
                  />
                </RadioGroup>
              </div>
            </Grid>
            <br></br>
            <br></br>
            <Grid item xs={12} style={{ display: "flex" }}>
              <Typography variant="body1">
                <div style={webStyle.DisplaName}>Assessment Score</div>
                <div style={webStyle.AssessmentScoreSubNoti}>
                  Allow community admin access to see my scores for all
                  assessments.
                </div>
              </Typography>

              <div
                style={{
                  position: "absolute",
                  right: "7rem",
                  marginTop: "23px",
                }}
              >
                <Switch />
              </div>
            </Grid>
            <br></br>
            <br></br>
          </Card>
        )}
      </>
    );
  };

  renderSubRendering = () => {
    return (
      <>
        {!this.state.isLocalAdminFlag && (
          <Grid
            item
            xs={12}
            style={{
              marginTop: "3px",
              display: "flex",
              cursor: "pointer",
              backgroundColor: "white",
              borderRadius: "14px",
              height: "60px",
              border:
                this.state.selectedSetting == "deactivate"
                  ? "1px solid #005487"
                  : "",
            }}
          >
            <Grid item xs={6} style={webStyle.gridIcon}>
              <img src={Deactive} alt="Deavtive" />
            </Grid>
            <Grid item xs={6} style={webStyle.gridText}>
              <ListItem onClick={this.deactivateOption}>
                <Typography variant="body1" style={{ minWidth: "15rem" }}>
                  Deactivate
                </Typography>
              </ListItem>
            </Grid>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          style={{
            marginTop: "3px",
            display: "flex",
            cursor: "pointer",
            backgroundColor: "white",
            borderRadius: "14px",
            height: "60px",
            border:
              this.state.selectedSetting == "support"
                ? "1px solid #005487"
                : "",
          }}
        >
          <Grid item xs={6} style={webStyle.gridIcon}>
            <img src={Support} alt="support" />
          </Grid>
          <Grid item xs={6} style={webStyle.gridText}>
            <ListItem onClick={this.supportOption}>
              <Typography variant="body1" style={{ minWidth: "15rem" }}>
                Support Request
              </Typography>
            </ListItem>
          </Grid>
        </Grid>
        {!this.state.isLocalAdminFlag && (
          <Grid
            item
            xs={12}
            style={{
              marginTop: "3px",
              display: "flex",
              cursor: "pointer",
              backgroundColor: "white",
              borderRadius: "14px",
              height: "60px",
              border:
                this.state.selectedSetting == "privacy"
                  ? "1px solid #005487"
                  : "",
            }}
          >
            <Grid item xs={6} style={webStyle.gridIcon}>
              <img src={Privacy} alt="private" />
            </Grid>
            <Grid item xs={6} style={webStyle.gridText}>
              <ListItem onClick={this.privacyOption}>
                <Typography variant="body1" style={{ minWidth: "15rem" }}>
                  Privacy
                </Typography>
              </ListItem>
            </Grid>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          style={{
            marginTop: "3px",
            display: "flex",
            cursor: "pointer",
            backgroundColor: "white",
            borderRadius: "14px",
            height: "60px",
            border:
              this.state.selectedSetting == "notification"
                ? "1px solid #005487"
                : "",
          }}
        >
          <Grid item xs={6} style={webStyle.gridIcon}>
            <img src={Notification} alt="notification" />
          </Grid>
          <Grid item xs={6} style={webStyle.gridText}>
            <ListItem onClick={this.notificationOption}>
              <Typography variant="body1" style={{ minWidth: "15rem" }}>
                Notification Settings
              </Typography>
            </ListItem>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            marginTop: "3px",
            display: "flex",
            cursor: "pointer",
            backgroundColor: "white",
            borderRadius: "14px",
            height: "60px",
            border:
              this.state.selectedSetting == "profile"
                ? "1px solid #005487"
                : "",
          }}
        >
          <Grid item xs={6} style={webStyle.gridIcon}>
            <img src={Profile} alt="profile" />
          </Grid>
          <Grid item xs={6} style={webStyle.gridText}>
            <ListItem onClick={this.profileOption}>
              <Typography variant="body1" style={{ minWidth: "15rem" }}>
                Profile Settings
              </Typography>
            </ListItem>
          </Grid>
        </Grid>

        {!this.state.isLocalAdminFlag && (
          <Grid
            item
            xs={12}
            style={{
              marginTop: "3px",
              display: "flex",
              cursor: "pointer",
              backgroundColor: "white",
              borderRadius: "14px",
              height: "60px",
              border:
                this.state.selectedSetting == "password"
                  ? "1px solid #005487"
                  : "",
            }}
          >
            <Grid item xs={6} style={webStyle.gridIcon}>
              <img src={Lock} alt="lock-icon" />
            </Grid>
            <Grid item xs={6} style={webStyle.gridText}>
              <ListItem onClick={this.PasswordOption}>
                <Typography variant="body1" style={{ minWidth: "15rem" }}>
                  Change Password
                </Typography>
              </ListItem>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  renderDeactivate = () => {
    return (
      <>
        {this.state.selectedSetting === "deactivate" && (
          <Card style={webStyle.cardContent} className="SettingMainBox">
            <Grid item xs={12}>
              <Typography variant="body1" style={webStyle.txtHeader}>
                Deactivate
              </Typography>
            </Grid>
            <hr style={webStyle.horizontal} />
            <Grid item xs={12} style={{ marginLeft: "1rem" }}>
              <Typography variant="body1" style={webStyle.DisplaName}>
                Temporarily deactivate your account
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ display: "flex", marginLeft: "0.5rem" }}
            >
              <Typography variant="body1">
                <div style={webStyle.AssessmentScoreSub}>
                  Hi{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {this.state.userName}
                  </span>
                  <br></br>
                  You are deactivation your account, this means your account
                  will be hidden until you reactive it by loggin back in.{" "}
                </div>
              </Typography>
            </Grid>

            <Grid item xs={12} style={{ marginLeft: "1rem" }}>
              <Typography variant="body1" style={webStyle.DisplaName}>
                Keeping your data safe
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ display: "flex", marginLeft: "0.5rem" }}
            >
              <Typography variant="body1">
                <div style={webStyle.AssessmentScoreSub}>
                  Nothing is more important to us than safety and security of
                  the community. People put their trust in us, so we will never
                  break their trust when it comest to safety and security of you
                  data.
                </div>
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ marginLeft: "2rem", marginTop: "4rem" }}
            >
              <FormControl style={{ width: "45rem" }}>
                <Typography
                  component={"span"}
                  className={`Heading6 BoldFont PrimaryColor2`}
                >
                  Why are you deactivate your account?
                </Typography>
                <Grid sm={8} lg={10} xl={12}>
                  <CustomSelectDropdown
                    placeHolderText="Select Timezone of Event"
                    fieldId="event-timezone"
                    fieldValue={this.state.timezone}
                    fieldName="timezone"
                    handleChange={this.handleChangeDropdownValue}
                    MenuProps={MenuProps}
                    renderValue={this.renderValueTimezone}
                    fieldError={this.state.timezoneError}
                    fieldMenuItems={this.state.timezoneList}
                    iconComponent={
                      <DropdownIcon
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke={"#005487"}
                        strokeWidth="1.5"
                        fill={"none"}
                      />
                    }
                    startAdornmentIcon={
                      <ReasonIcon
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke={"#005487"}
                        strokeWidth="1.0"
                        fill={"none"}
                      />
                    }
                  />
                </Grid>

                {this.state.timezoneError && (
                  <FormHelperText style={{ marginLeft: "10px" }}>
                    {this.state.timezoneErrorMessage}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ marginLeft: "2rem", marginTop: "2rem" }}
            >
              <FormControl style={{ width: "45rem" }}>
                <Typography
                  component={"span"}
                  className={`Heading6 BoldFont PrimaryColor2`}
                >
                  Please enter your password
                </Typography>
                <Grid sm={8} lg={10} xl={12}>
                  <CustomTextField
                    placeHolderText="Enter your Password"
                    fieldName="password"
                    fieldId="password-id"
                    fieldValue={this.state.password}
                    fieldType={this.state.showPass ? "text" : "password"}
                    handleChange={this.handlePasswordChange}
                    fieldError={this.state.passwordError}
                    startAdornmentIcon={
                      <PasswordIcon
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke={"#005487"}
                        strokeWidth="1.0"
                        fill={"none"}
                      />
                    }
                  />
                </Grid>
                {this.state.showPass ? (
                  <img
                    onClick={this.EyeChange}
                    style={{
                      cursor: "pointer",
                      width: "2rem",
                      height: "27px",
                      position: "relative",
                      left: "34.5rem",
                      bottom: "3rem",
                    }}
                    src={Eye}
                    alt="eye"
                  />
                ) : (
                  <img
                    onClick={this.EyeChange}
                    style={{
                      cursor: "pointer",
                      width: "2rem",
                      height: "27px",
                      position: "relative",
                      left: "34.5rem",
                      bottom: "3rem",
                    }}
                    src={Hideeye}
                    alt="eye"
                  />
                )}
                {this.state.passwordError && (
                  <FormHelperText
                    style={{ marginLeft: "10px", marginTop: "-17px" }}
                  >
                    {this.state.passwordErrorMessage}
                  </FormHelperText>
                )}
              </FormControl>
              <div onClick={this.navigateForgotPassword}>
                <Typography variant="body1" style={webStyle.forgot}>
                  Forgot Password?
                </Typography>
              </div>
            </Grid>

            <div style={{ marginTop: "4rem" }}>
              <hr style={webStyle.horizontal} />
            </div>

            <Grid item xs={12}>
              <Box mt={2} className={` Heading5 BoldFont`} width="100%">
                <CustomButton
                  btnStyle={webStyle.Publishbtn}
                  btnText={"Save"}
                  buttonId="user-profile-add-social-publish-btn"
                  type="submit"
                  handleButtonClick={this.handleDeactivateModal}
                />
              </Box>
            </Grid>
          </Card>
        )}
      </>
    );
  };
  renderPassord = () => {
    return (
      <>
        {this.state.selectedSetting === "password" && (
          <Card className="SettingMainBox" style={webStyle.cardContent}>
            <Grid item xs={12}>
              <Grid style={{ display: "flex" }} item xs={12}>
                <div
                  onClick={this.handleBack}
                  style={{
                    marginTop: "22px",
                    marginLeft: "18px",
                    maxWidth: "54px",
                    width: "auto",
                  }}
                >
                  <BackIcon
                    width="24"
                    height="24"
                    stroke={"black"}
                    viewBox="0 0 24 24"
                    fill={"none"}
                    strokeWidth="1.5"
                  />
                </div>
                <Typography style={webStyle.txtHeader} variant="body1">
                  Change Password
                </Typography>
              </Grid>
            </Grid>
            <hr style={webStyle.horizontal} />
            <Grid
              item
              xs={12}
              style={{ marginTop: "2rem", marginLeft: "2rem" }}
            >
              <FormControl style={{ width: "45rem" }}>
                <Grid sm={8} lg={10} xl={12}>
                  <CustomTextField
                    placeHolderText="Enter your current password"
                    fieldName="password"
                    fieldId="pass-id"
                    fieldValue={this.state.current_password}
                    fieldType={this.state.showPass ? "text" : "password"}
                    handleChange={this.handleCurrentPasswordChange}
                    startAdornmentIcon={<img src={Lock} alt="lock-icon" />}
                    fieldError={this.state.current_password_Error}
                  />
                </Grid>
                {this.state.showPass ? (
                  <img
                    onClick={this.EyeChange}
                    style={{
                      cursor: "pointer",
                      width: "2rem",
                      height: "27px",
                      position: "relative",
                      left: "34.5rem",
                      bottom: "3rem",
                    }}
                    src={Hideeye}
                    alt="eye"
                  />
                ) : (
                  <img
                    onClick={this.EyeChange}
                    style={{
                      cursor: "pointer",
                      width: "2rem",
                      height: "27px",
                      position: "relative",
                      left: "34.5rem",
                      bottom: "3rem",
                    }}
                    src={Eye}
                    alt="eye"
                  />
                )}

                {this.state.current_password_Error && (
                  <FormHelperText style={{ marginLeft: "10px" }}>
                    {this.state.current_password_ErrorMessage}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ marginLeft: "2rem", marginTop: "2rem" }}
            >
              <FormControl style={{ width: "45rem" }}>
                <Grid sm={8} lg={10} xl={12}>
                  <CustomTextField
                    placeHolderText="Enter New Password"
                    fieldName="new-password"
                    fieldId="new-id"
                    fieldValue={this.state.change_password}
                    fieldType={this.state.showPassOne ? "text" : "password"}
                    handleChange={this.handleForgotPasswordChange}
                    startAdornmentIcon={<img src={Lock} alt="lock-icon" />}
                    fieldError={this.state.change_password_Error}
                  />
                </Grid>
                {this.state.showPassOne ? (
                  <img
                    onClick={this.EyeChangeOne}
                    style={{
                      cursor: "pointer",
                      width: "2rem",
                      height: "27px",
                      position: "relative",
                      left: "34.5rem",
                      bottom: "3rem",
                    }}
                    src={Hideeye}
                    alt="eye"
                  />
                ) : (
                  <img
                    onClick={this.EyeChangeOne}
                    style={{
                      cursor: "pointer",
                      width: "2rem",
                      height: "27px",
                      position: "relative",
                      left: "34.5rem",
                      bottom: "3rem",
                    }}
                    src={Eye}
                    alt="eye"
                  />
                )}
                {this.state.change_password_Error && (
                  <FormHelperText style={{ marginLeft: "10px" }}>
                    {this.state.change_password_ErrorMessage}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ marginLeft: "2rem", marginTop: "2rem" }}
            >
              <FormControl style={{ width: "45rem" }}>
                <Grid sm={8} lg={10} xl={12}>
                  <CustomTextField
                    placeHolderText="Re-enter New Password"
                    fieldName="confirmpassword"
                    fieldId="confirm-id"
                    fieldValue={this.state.reenter_password}
                    fieldType={this.state.showPassTwo ? "text" : "password"}
                    handleChange={this.handleConfirmPasswordChange}
                    startAdornmentIcon={<img src={Lock} alt="lock-icon" />}
                    fieldError={this.state.reenter_password_Error}
                  />
                </Grid>
                {this.state.showPassTwo ? (
                  <img
                    onClick={this.EyeChangeTwo}
                    style={{
                      cursor: "pointer",
                      width: "2rem",
                      height: "27px",
                      position: "relative",
                      left: "34.5rem",
                      bottom: "3rem",
                    }}
                    src={Hideeye}
                    alt="eye"
                  />
                ) : (
                  <img
                    onClick={this.EyeChangeTwo}
                    style={{
                      cursor: "pointer",
                      width: "2rem",
                      height: "27px",
                      position: "relative",
                      left: "34.5rem",
                      bottom: "3rem",
                    }}
                    src={Eye}
                    alt="eye"
                  />
                )}
                {this.state.reenter_password_Error && (
                  <FormHelperText style={{ marginLeft: "10px" }}>
                    {this.state.reenter_password_ErrorMessage}
                  </FormHelperText>
                )}
              </FormControl>
              <div onClick={this.navigateForgotPassword}>
                <Typography variant="body1" style={webStyle.forgot}>
                  Forgot Password?
                </Typography>
              </div>
            </Grid>
            <div style={{ marginTop: "11rem" }}>
              <hr style={webStyle.horizontal} />
            </div>

            <Grid item xs={12}>
              <Box mt={2} className={` Heading5 BoldFont`} width="100%">
                <CustomButton
                  btnStyle={webStyle.Publishbtn}
                  btnText={"Save"}
                  buttonId="user-profile-add-social-publish-btn"
                  type="submit"
                  handleButtonClick={this.handleChangePasswordSubmit}
                />
              </Box>
            </Grid>
          </Card>
        )}
      </>
    );
  };
  renderUserName = () => {
    return (
      <>
        {this.state.selectedSetting === "Username" && (
          <Card className="SettingMainBox" style={webStyle.cardContent}>
            <Grid item xs={12}>
              <Grid item xs={12} style={{ display: "flex" }}>
                <div
                  style={{ marginTop: "22px", marginLeft: "18px" }}
                  onClick={this.handleBack}
                >
                  <BackIcon
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke={"black"}
                    strokeWidth="1.5"
                    fill={"none"}
                  />
                </div>
                <Typography variant="body1" style={webStyle.txtHeader}>
                  Username
                </Typography>
              </Grid>
            </Grid>
            <hr style={webStyle.horizontal} />
            <Grid
              item
              xs={12}
              style={{ marginLeft: "2rem", marginTop: "2rem" }}
            >
              <FormControl style={{ width: "45rem" }}>
                <Typography
                  component={"span"}
                  className={`Heading6 BoldFont PrimaryColor2`}
                >
                  Username
                </Typography>
                <Grid sm={8} lg={10} xl={12}>
                  <CustomTextField
                    placeHolderText="Enter your User Name"
                    fieldName="username"
                    fieldId="user-id"
                    fieldValue={this.state.userName}
                    fieldType="text"
                    handleChange={this.handleUsernameChange}
                    startAdornmentIcon={
                      <UserIcon
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke={"#005487"}
                        strokeWidth="1.0"
                        fill={"none"}
                      />
                    }
                    fieldError={this.state.usernameError}
                  />
                </Grid>
                {this.state.usernameError && (
                  <FormHelperText style={{ marginLeft: "10px" }}>
                    {this.state.usernameErrorMessage}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <div style={{ marginTop: "11rem" }}>
              <hr style={webStyle.horizontal} />
            </div>

            <Grid item xs={12}>
              <Box mt={2} className={` Heading5 BoldFont`} width="100%">
                <CustomButton
                  btnStyle={webStyle.Publishbtn}
                  btnText={"Save"}
                  buttonId="user-profile-add-social-publish-btn"
                  type="submit"
                  handleButtonClick={this.handleUsernameSubmit}
                />
              </Box>
            </Grid>
          </Card>
        )}

        {this.state.selectedSetting === "Fullname" && (
          <Card style={webStyle.cardContent} className="SettingMainBox">
            <Grid item xs={12}>
              <Grid item xs={12} style={{ display: "flex" }}>
                <div
                  style={{ marginTop: "22px", marginLeft: "18px" }}
                  onClick={this.handleBack}
                >
                  <BackIcon
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke={"black"}
                    strokeWidth="1.5"
                    fill={"none"}
                  />
                </div>
                <Typography variant="body1" style={webStyle.txtHeader}>
                  Full Name
                </Typography>
              </Grid>
            </Grid>
            <hr style={webStyle.horizontal} />
            <Grid
              item
              xs={12}
              style={{ marginLeft: "2rem", marginTop: "2rem" }}
            >
              <FormControl style={{ width: "45rem" }}>
                <Typography
                  component={"span"}
                  className={`Heading6 BoldFont PrimaryColor2`}
                >
                  First Name
                </Typography>
                <Grid sm={8} lg={10} xl={12}>
                  <CustomTextField
                    placeHolderText="Enter your First Name"
                    fieldName="firstname"
                    fieldId="first-id"
                    fieldValue={this.state.firstName}
                    fieldType="text"
                    handleChange={this.handleFirstnameChange}
                    fieldError={this.state.firstnameError}
                    startAdornmentIcon={
                      <UserIcon
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke={"#005487"}
                        strokeWidth="1.0"
                        fill={"none"}
                      />
                    }
                  />
                </Grid>
                {this.state.firstnameError && (
                  <FormHelperText style={{ marginLeft: "10px" }}>
                    {this.state.firstnameErrorMessage}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ marginLeft: "2rem", marginTop: "2rem" }}
            >
              <FormControl style={{ width: "45rem" }}>
                <Typography
                  component={"span"}
                  className={`Heading6 BoldFont PrimaryColor2`}
                >
                  Last Name
                </Typography>
                <Grid sm={8} lg={10} xl={12}>
                  <CustomTextField
                    placeHolderText="Enter your Last Name"
                    fieldName="lastname"
                    fieldId="lastname-id"
                    fieldValue={this.state.lastName}
                    fieldType="text"
                    handleChange={this.handlelastnameChange}
                    fieldError={this.state.lastnameError}
                    startAdornmentIcon={
                      <UserIcon
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke={"#005487"}
                        strokeWidth="1.0"
                        fill={"none"}
                      />
                    }
                  />
                </Grid>
                {this.state.lastnameError && (
                  <FormHelperText style={{ marginLeft: "10px" }}>
                    {this.state.lastnameErrorMessage}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <div style={{ marginTop: "4rem" }}>
              <hr style={webStyle.horizontal} />
            </div>

            <Grid item xs={12}>
              <Box mt={2} className={` Heading5 BoldFont`} width="100%">
                <CustomButton
                  btnStyle={webStyle.Publishbtn}
                  btnText={"Save"}
                  buttonId="user-profile-add-social-publish-btn"
                  type="submit"
                  handleButtonClick={this.handleFullnameSubmit}
                />
              </Box>
            </Grid>
          </Card>
        )}
      </>
    );
  };
  // Customizable Area End

render() {
   return (
      // Customizable Area Start
      this.state.spinner ? (
        <Loader loading={this.state.spinner} />
      ) : (
        <>
          <ThemeProvider theme={theme}>
            <Box>
              <Grid container item sm={12}>
                <Grid item sm={12} md={12} lg={4}>
                  <Card style={webStyle.cardStyle}>
                    <Grid container item xs={12}>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", marginTop: "17px" }}
                      >
                        <Grid
                          item
                          xs={4}
                          style={{ maxWidth: "5rem", width: "auto" }}
                        >
                          <Avatar
                            alt="Avatar"
                            src={this.state.profile_picture}
                            style={webStyle.avatar}
                          />
                        </Grid>
                        <Grid item xs={8} style={webStyle.gridAvatar}>
                          <Typography variant="body1" style={webStyle.userName}>
                            {this.state.user_name}
                          </Typography>
                          <Typography
                            variant="body1"
                            style={{ minWidth: "10rem", marginLeft: "22px" }}
                          >
                            {this.state.email}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        marginTop: "3px",
                        display: "flex",
                        cursor: "pointer",
                        backgroundColor: "white",
                        borderRadius: "14px",
                        height: "60px",
                        border:
                          this.state.selectedSetting == "logout"
                            ? "1px solid #005487"
                            : "",
                      }}
                    >
                      <Grid item xs={6} style={webStyle.gridIcon}>
                        <img src={Logout} alt="logout" />
                      </Grid>
                      <Grid item xs={6} style={webStyle.gridText}>
                        <ListItem onClick={this.logOutoption}>
                          <Typography
                            variant="body1"
                            style={{ minWidth: "15rem" }}
                          >
                            Log out
                          </Typography>
                        </ListItem>
                      </Grid>
                    </Grid>
                    {this.renderSubRendering()}
                  </Card>
                </Grid>
                <br />
                <br />
                <Grid item sm={12} md={12} lg={8} style={{ marginTop: "7px" }}>
                  {this.renderDeactivate()}
                  {this.renderSubBranch()}
                  {this.renderUserName()}

                  {this.renderPassord()}
                </Grid>
              </Grid>
            </Box>
            <Box>
              {/* Video Embed Popup Start */}
              <LogoutModal
                title={this.state.title}
                deactivateSubmit={this.handleDeactivateSubmit}
                open={this.state.openLogout}
                onClose={this.handleCloseModal}
                handleLogout={this.handleLogout}
              />
              {/* Video Embed Popup End */}
            </Box>
          </ThemeProvider>
        </>
      )

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  forgot: {
    textAlign: "end",
    marginRight: "34px",

    color: "gray",
    fontWeight: "bold",
  } as const,
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  cardStyle: {
    width: "auto",
    marginLeft: "4rem",
    border: "solid 1px #808080",
    borderRadius: "12px",
    height: "auto",
    backgroundColor: "#f4f4f4",
  } as const,
  gridIcon: {
    marginTop: "17px",
    marginLeft: "23px",
    width: "auto",
    maxWidth: "2rem",
  },
  gridMain: {
    marginTop: "3px",
    display: "flex",
    cursor: "pointer",
    backgroundColor: "white",
    borderRadius: "14px",
    height: "60px",
  },
  gridText: {
    marginRight: "118px",
    color: "#808080",
    marginTop: "8px",
  },
  avatar: {
    width: "48px",
    height: "48px",
    margin: "0 12px 25px 19px",
  },
  gridAvatar: {
    marginRight: "4rem",
    marginLeft: "-1rem",
  },
  userName: {
    width: "107px",
    margin: "1px 101px 4px 22px",
    fontSize: "17px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.29",
    letterSpacing: "-0.41px",
    textAlign: "start",
    color: "#000",
    minWidth: "10rem",
    maxWidth: "12rem",
    wordWrap: "break-word",
  } as const,
  userEmail: {
    width: "128px",
    height: "22px",
    margin: "4px 80px 24px 12px",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.83",
    letterSpacing: "-0.41px",
    textAlign: "start",
    color: "#000",
    minWidth: "10rem",
    wordWrap: "break-word",
  } as const,

  cardContentPrivacy: {
    width: "-webkit-fill-available;",
    marginLeft: "4rem",
    borderRadius: "12px",
    height: "auto",
    backgroundColor: "#fff",
  },
  cardContent: {
    width: "auto",
    marginLeft: "4rem",
    borderRadius: "12px",
    height: "auto",
    backgroundColor: "#fff",
  },
  txtHeader: {
    width: "120px",
    height: "22px",
    margin: "0 286px 48px 0",
    fontSize: "20px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.1",
    letterSpacing: "-0.41px",
    color: "#005487",
    marginTop: "21px",
    marginLeft: "30px",
    minWidth: "30rem",
  } as const,
  horizontal: {
    marginTop: "-14px",
    width: "100%",
    height: "1px",
    transform: "rotate(0deg)",
    border: "solid 0.3px #808080",
    backgroundColor: "#808080",
  } as const,
  DisplaName: {
    width: "127px",
    height: "22px",
    margin: "35px 279px 4px 15px",
    fontSize: "17px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.29",
    letterSpacing: "-0.41px",
    color: "#000",
    minWidth: "33rem",
  } as const,
  setDisplayName: {
    width: "160px",
    height: "22px",
    margin: "6px 246px 20px 15px",
    fontSize: "14px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.57",
    letterSpacing: "-0.41px",
    color: "#808080",
    minWidth: "20rem",
  } as const,
  userNameText: {
    width: "88px",
    height: "22px",
    margin: "9px 680px 3px 15px",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.38",
    letterSpacing: "-0.41px",
    color: "#808080",
    minWidth: "20rem",
  } as const,
  userNameSub: {
    width: "186px",
    height: "22px",
    margin: "7px 582px 0 15px",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.57",
    letterSpacing: "-0.41px",
    color: "#808080",
    minWidth: "20rem",
  } as const,
  cardGrid: {
    backgroundColor: "#f4f4f4",
    marginRight: "18px",
    marginLeft: "18px",
    height: "80px",
    borderRadius: "8px",
    border: "solid 2px #f4f4f4",
  } as const,
  horizontalLeft: {
    width: "398px",
    height: "0.1px",
    margin: "31px 8px 30.9px 0",
    transform: "rotate(0deg)",
    border: "solid 0.5px #808080",
  } as const,
  orText: {
    marginTop: "21px",
    marginRight: "6px",
    fontSize: "12px",
    color: "#808080",
  } as const,
  AssessmentScoreSub: {
    width: "114px",
    height: "22px",
    margin: "3px 680px 17px 27px",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.57",
    letterSpacing: "-0.41px",
    color: "#808080",
    minWidth: "34rem",
  } as const,
  AssessmentScoreSubNoti: {
    width: "114px",
    height: "22px",
    margin: "3px 680px 17px 27px",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.57",
    letterSpacing: "-0.41px",
    color: "#808080",
    minWidth: "20rem",
  } as const,
  assesmentScore: {
    width: "157px",
    height: "22px",
    margin: "20px 637px 3px 27px",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.38",
    letterSpacing: "-0.41px",
    color: "#808080",
    minWidth: "26rem",
  } as const,
  Publishbtn: {
    background: "#01548a",
    color: "#fff",
    width: "70%",
    borderRadius: "0px",
    padding: "18px",
    marginLeft: "8rem",
    marginBottom: "21px",
  } as const,
  editIcon: {
    position: "sticky",
    right: "2rem",
    marginTop: "9px",
    cursor: "pointer",
  } as const,
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "300px",
      borderRadius: "12px",
    },
  },
};

// Customizable Area End
