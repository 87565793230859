import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { isLocalAdmin } from "../../../components/src/common";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  // Customizable Area End
}

// Customizable Area Start
interface IContactInfo {
  phoneNo: string;
  email: string;
  website: string;
  socialMediaUrls: string[];
}
// Customizable Area End
interface S {
  // Customizable Area Start
  contactInfo: IContactInfo;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start

export default class ContactInfoController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  type: string = "";
  getcommunityInfo: string = "";
  getUserProfileInfo: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    // Customizable Area Start

    this.state = {
      contactInfo: {
        phoneNo: "",
        email: "",
        website: "",
        socialMediaUrls: [],
      },
      isLoading: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(_from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson?.data) {
      if (apiRequestCallId === this.getUserProfileInfo) {
        this.setState({
          isLoading: false,
        });
        const {
          website,
          email,
          social_media_urls,
          full_phone_number,
        } = responseJson.data.attributes;
        const urls = social_media_urls.map((item: { url: string }) => item.url);
        this.setState({
          contactInfo: {
            phoneNo: full_phone_number,
            email: email,
            website: website,
            socialMediaUrls: [...urls],
          },
        });
      } else if (apiRequestCallId === this.getcommunityInfo) {
        this.setState({
          isLoading: false,
        });
        const { website, phone_number, email } = responseJson.data.attributes;
        this.setState({
          contactInfo: {
            phoneNo: phone_number,
            email: email,
            website: website,
            socialMediaUrls: [],
          },
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    const isLA = await isLocalAdmin();
    const param = this.props.navigation.getParam("id");
    if(param){
      if (param.toLowerCase().includes("community")) {
        this.type = "community";
        this.getCommunityDetails(param.split("-")[0]);
      }else {
        this.getAccountDetails(param);
      }
    } else {
      if (isLA) {
        this.getCommunityDetails(param);
        this.type = "community";
      } else {
        this.getAccountDetails(param);
      }
    }
  }

  getHeader = async () => {
    let signupToken = await getStorageData("SignupSuccessToken");
    let loginToken = await getStorageData("LoginSuccessToken");

    const header = {
      token: signupToken || loginToken,
      "Content-Type": configJSON.apiContentType,
    };

    return header;
  };

  getCommunityDetails = async (id: string) => {
    this.setState({
      isLoading: true,
    });
    const header = await this.getHeader();
    const requestMessageCommunity = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getcommunityInfo = requestMessageCommunity.messageId;
    requestMessageCommunity.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOtherCommunityApiEndpoint +
        `/${id}` +
        "?is_post_invisible=true"
    );

    requestMessageCommunity.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageCommunity.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getOtherProfileApiMethod
    );
    runEngine.sendMessage(requestMessageCommunity.id, requestMessageCommunity);
  };

  getAccountDetails = async (id: string) => {
    this.setState({
      isLoading: true,
    });
    const header = await this.getHeader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileInfo = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOtherProfileApiEndpoint +
        `/${id}` +
        "?is_post_invisible=true"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getOtherProfileApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCloseClick = () => {
    this.props.navigation.goBack();
  };

  // Customizable Area End
}
// Customizable Area End
