import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    styled
} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import { AvatarGroup } from '@material-ui/lab';
import Avatar from '@material-ui/core/Avatar';
import CalenderEventsController, {Props, EventData, configJSON } from "./CalenderEventsController.web";
import moment from "moment";
import { Calendar} from "react-multi-date-picker";
import Loader from "../../../components/src/Loader.web";
import { RightArrow, squreCalender } from "./assets";
import CreateEventStepOneModal from "../../postcreation/src/CreateEventStepOneModal.web";
// Customizable Area End

export default class CalenderEvents extends CalenderEventsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        return (
            <MainContainer>
                {this.state.createEvent && <CreateEventStepOneModal eventId={''} getItem={this.NavigateToCalendarDetails} navigation={this.props.navigation} id={''} openModal={this.state.createEvent} handleClose={this.closeModal} info={{
                    name: "",
                    is_private: false,
                    type: "",
                    image: ""
                }}/>}
                {this.state.loading && <Loader loading={this.state.loading} />}
                <Box display="flex" alignItems="center" p={"24px"} borderBottom={"1px solid #808080"}>
                    <Box component={"h3"} m={0} fontFamily={"Verdana"} fontSize={"20px"} color={"#005487"} fontWeight={"bold"}>Calendar</Box>
                </Box>
                <Box display={"flex"} justifyContent={"space-between"} px={"24px"}>
                    <Button data-test-id="btnDaily" onClick={() => this.handleheader('daily')} className={this.state.selectAppBar === "daily" ? "borderBtn" : "imageBtn"}>{configJSON.daily}</Button>
                    <Button data-test-id="btnWeekly" onClick={() => this.handleheader('weekly')} className={this.state.selectAppBar === "weekly" ? "borderBtn" : "imageBtn"}>{configJSON.weekly}</Button>
                    <Button data-test-id="btnMonthly" onClick={() => this.handleheader('monthly')} className={this.state.selectAppBar === "monthly" ? "borderBtn" : "imageBtn"}>{configJSON.monthly}</Button>
                </Box>
                <CalendarBox component={"section"}>
                    <CustomCalender
                        format="DD MMM YYYY"
                        monthYearSeparator=" "
                        data-test-id={"calendar"}
                        onChange={this.handleChangeCalender}
                        multiple={this.state.selectAppBar !== "daily"}
                        onMonthChange={this.onMonthChange}
                        value={this.state.highLightedDate}
                    />
                </CalendarBox> 
                <Box component="section" display={"flex"} padding={"24px"} gridGap={"18px"} flexDirection={"column"}>
                    {this.state.arrayHolder.length === 0 ?
                        (<Typography variant="h6" align="center" color="textSecondary">{this.state.message}</Typography>)
                        : (this.state.arrayHolder.map((item: EventData, index: number) => {
                            return (
                                <EventContainer container data-test-id={`eventData`} key={item.id} onClick={() => this.NavigateToCalendarDetails(item.id)}>
                                    <Grid className="GridCardImgContainer" item xs={5}>
                                        {item.attributes.cover_image && <img className="CardImg" src={item.attributes.cover_image} alt={"event-img"} />}
                                        <div className="GradientBox"></div>
                                    </Grid>
                                    <Grid className="GridContentContainer" item xs={7}>
                                        <Box display={"flex"} justifyContent={"space-between"}>
                                            <Box maxWidth={"80%"}>
                                                <Box component={"h4"} m={0} sx={{ fontSize: { xs: "18px", sm: "24px" } }} fontWeight={"bold"} fontFamily={"verdana"} className="ellipsis">
                                                        {item.attributes.event_name}
                                                </Box>
                                                <Box component={"p"} m={0} mt={"3px"} sx={{ fontSize: { xs: "14px", sm: "18px" } }} fontWeight={"normal"} className="ellipsis" color="#808080" fontFamily={"verdana"}>
                                                    {item.attributes.full_name ? item.attributes.full_name : item.attributes.community_name}
                                                </Box>
                                            </Box>
                                            <Box component={"p"} fontFamily={"Verdana"}  sx={{ fontSize: { xs: "10px", sm: "12px" } }} fontWeight={"bold"} color={"#005487"} m={0} textAlign={"center"}>
                                                {moment(new Date(item.attributes.start_date)).format("DD")}<br />
                                                {moment(new Date(item.attributes.start_date)).format("MMMM").toUpperCase().slice(0, 3)}
                                            </Box>
                                        </Box>
                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                            <AvatarGroup spacing={20} max={3}>
                                                {item.attributes.interest_event_count[0].account_images.map((img) => {
                                                    return (
                                                        <Avatar src={String(img.photo)} key={String(img.photo)} />
                                                    )
                                                })}
                                            </AvatarGroup>
                                            <Box borderRadius={"10px"} className="ellipsis" padding={"4px 7px"} component={"p"} fontFamily={"Verdana"} sx={{fontSize:{xs:"12px",sm:"14px"}}} fontWeight={"bold"} color={"#fff"} bgcolor={this.bgColor[index%this.bgColor.length]} m={0} textAlign={"center"}>
                                            {moment(item.attributes.start_time, "HH:mm:ss").format('hh:mm A')} - {moment(item.attributes.end_time, "HH:mm:ss").format('hh:mm A')}
                                            </Box>
                                        </Box>
                                    </Grid>
                                </EventContainer>
                            )
                        }
                        ))
                    }
                </Box>   
                <Box display={"flex"} justifyContent={"center"} mt="100px" pb="42px">
                    <Button className="event-btn" data-test-id="create-event" onClick={this.handleNavigate} startIcon={<img src={squreCalender} alt="img"/>} endIcon={<img src={RightArrow} alt="arrow"/>}>Create an Event</Button>
                </Box>
            </MainContainer>
        );
    }
    // Customizable Area End
}

// Customizable Area Start

 const CustomCalender = styled(Calendar)({
    width: "100%",
    borderRadius: "10px",
    boxShadow:"0 2px 8px 0 rgba(0, 0, 0, 0.5)",

    "& .rmdp-header":{
        margin: 0,
        padding: 0,
        "& > div:first-of-type":{
            display: "grid !important",
            gridTemplateColumns: "80% 10% 10%",
            alignItems: "center",
            padding: "16px",
            "@media (min-width:960px)":{
                gridTemplateColumns: "90% 5% 5%",
            },
        },
        "& .rmdp-right":{
            order: 3
        },
        "& .rmdp-left":{
            order: 2
        },
        "& .rmdp-header-values":{
            "& span":{
                "padding": 0
            },
            order: 1,
            fontFamily: "Verdana",
            fontSize: "16px",
            color: "#005487",
            fontWeight: 'bold',
            width:"100%",
            textAlign: 'start',
        }
    },

    "& .rmdp-calendar": {
        width: "100%",
        padding: 0
    },

    "& .rmdp-day-picker div": {
        width: "100%",
        cursor: "none ",
    },

    "& .rmdp-day":{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    "& .rmdp-day span":{
        position: "unset",
        fontSize: "16px",
        height: "32px",
        fontFamily: "Verdana",
        width: "32px",
        color: "005487"
    },
    "& .rmdp-week":{
        height: "40px",
        alignItems: "center"
    },
    "& .rmdp-week-day": {
        color: "#808080",
        fontSize: "14px",
        fontFamily: "Verdana"
    },
    "& .rmdp-day.rmdp-selected span:not(.highlight)":{
        backgroundColor:"#005487" 
    },
});

const MainContainer = styled(Box)({
    backgroundColor: "#fff",
    borderRadius: "12px",
    "& .imageBtn": {
        borderRadius: "0px",
        width: "30%",
        minWidth: "75px",
        maxWidth: "270px",
        height: "60px",
        color: "#808080",
        border: 0,
        fontSize:"16px",
        textTransform: "none"
    },
    "& .borderBtn": {
        borderBottom: "2px solid #005487",
        color: "#005487",
        borderRadius: "0px",
        minWidth: "75px",
        fontSize:"16px",
        width: "30%",
        textTransform: "none",
        height: "60px",
        maxWidth: "270px",
    },
    "& .event-btn":{
        fontFamily: "Verdana",
        textTransform: "none",
        fontSize: "17px",
        color: '#005487',
        fontWeight: 'bold'
    }
});


const CalendarBox = styled(Box)({
    margin: "20px 0px",
    paddingInline: "24px",
});

const EventContainer = styled(Grid)({
    height: "140px",
    display: "flex",
    width:"auto",
    justifyContent: "flex-end",
    borderRadius: "15px",
    overflow: "hidden",
    marginInline: "2px",
    boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.25)",
    cursor: "pointer",
    "& .GradientBox":{
        position: "absolute",
        top: "0px",
        height: "100%",
        width: "100%",
        background:"linear-gradient(-90deg,#fff 0%,rgba(255, 255, 255, 0.337) 50%,rgba(255, 255, 255, 0) 100%)",
    },
    "& .GridCardImgContainer":{
        position: "relative",
        height: "inherit"
    },
    "& .CardImg":{
        height: "100%",
        width: "100%",
    },
    "& .GridContentContainer":{
        backgroundColor: "white",
        justifyContent: "space-between",
        padding: "20px 16px 16px",
        boxSizing: "border-box",
        height: "inherit",
        display: "flex",
        flexDirection: "column"
    },
    "& .MuiAvatarGroup-avatar":{
        borderWidth: "1px",
        width: "31px",
        height: "31px",
        fontSize: "14px",
    },
    "& .ellipsis":{
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
});
// Customizable Area End
