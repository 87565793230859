Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.sellerDetailsApiContentType = "application/json";
exports.sellersAPIEndPoint = "custom_form/seller_accounts";

exports.sellerDetailsAPIMethodPUT = "PUT";
exports.sellerDetailsAPIMethodPOST = "POST";
exports.sellerDetailsAPIMethodPATCH = "PATCH";
exports.getSellersAPIMethod = "GET";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customform";
exports.labelBodyText = "customform Body";
exports.errorMessageShopName = "Shop name is mandatory";
exports.errorMessageGstin = "GSTIN is mandatory";

exports.btnExampleTitle = "CLICK ME";

exports.congrats = "Congratulations!";
exports.formSubmitted = "Your form is submitted";
exports.sellerDetails = "Seller Details";

exports.postAchievements='bx_block_achievements/achievements/';
exports.specificAchievenment="bx_block_achievements/achievements/";
exports.achievements="Achievements";
exports.addMore="Add More";
exports.bulkUpload="BulkUpload";
exports.customUpload="CustomForm";
exports.choose_file="Choose a file"
exports.title="Title*";
exports.description="Description*";
exports.ceu="CEU's gained from training*";
exports.cred="Credential URL*";
exports.organization="Issuing Organization*";
exports.expire="This credential does not expire";
exports.Issuing_Date="Issuing Date*";
exports.Expiration_Date="Expiration Date*";
exports.Next="Next";
// Customizable Area End
