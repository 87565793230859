import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { toast } from "react-toastify"
import { getSignupLoginTokens, handleErrorArray } from "../../../components/src/common";
// Customizable Area Start
import React from "react";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean;
    friendsList: {name: string, id: string, profilePicture: string,is_blocked_by_community:boolean}[];
    isShowAllEnabled: boolean;
    anchorEl: null | HTMLElement;
    showSearchModal: boolean;    
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class FriendsListController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getFriendListApiCallId: any;
    removeFriendApiCallId: string = "";
    userId: string = "";
    signupToken: string = "";
    loginToken: string = "";
    isOwnUser: boolean = false;
    friendListRef: React.RefObject<any>;
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.friendListRef = React.createRef();


        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),

            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ]
        this.state = {
            friendsList: [],
            isShowAllEnabled: false,
            isLoading: false,
            anchorEl: null,
            showSearchModal: false,
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
        // Customizable Area End
    }

    async receive(_from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );


        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        
        if(apiRequestCallId === this.getFriendListApiCallId){
            this.setState({isLoading:false})
            if(responseJson 
                    && responseJson.errors){
              this.setState({
                friendsList: []
              })
            } else if(responseJson 
                    && responseJson.data){
              this.setState({
                friendsList: responseJson.data.map((obj: { id: string, attributes: { profile_picture: string, full_name: string}})=>({id: obj.id,name: obj.attributes.full_name, profilePicture: obj.attributes.profile_picture,is_blocked_by_community:false}))
              })
            }
        }
        else if(apiRequestCallId === this.removeFriendApiCallId){
            this.setState({isLoading:false})
            if(responseJson && responseJson.data){
                toast.success(responseJson.data.message);
                this.getFriendsList()
            } 
            else if(responseJson.errors) {
                handleErrorArray(responseJson.errors)
            }
        }
        // Customizable Area End
    }


    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const { signupToken, loginToken, profileData } = await getSignupLoginTokens();

        this.signupToken = signupToken;
        this.loginToken = loginToken;

        const userId = this.props.navigation.getParam("id");
        if (userId) {
            this.userId = userId;
            this.isOwnUser = profileData.id === userId
        }
        this.getFriendsList();
    }

    getFriendsList = () => {
        const header = {
            token: this.signupToken || this.loginToken,
            "Content-Type": configJSON.apiContentType,
        }
        this.setState({isLoading:true});
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        this.getFriendListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getFriendsListApiEndpoint + `?account_id=${this.userId}`
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getFriendsListApiMethod
        )
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleRemoveFriend = (id: string) => {
        this.setState({isLoading:true})
        const header = {
            token: this.signupToken || this.loginToken,
            "Content-Type": configJSON.apiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.removeFriendApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.removeFriendApiEndpoint + `?account_id=${id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.removeFriendApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleRequestManagementNavigation = () => {
        this.props.navigation.navigate("FriendRequests")
    }

    showSearchModal = () => {
        this.setState({
            showSearchModal:true,
        });
    }

    closeSearchModal = () => {
        this.getFriendsList()
        this.setState({
            showSearchModal: false
        })
    }

    // Customizable Area End

}
