Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
const urlConfig = require("../../../framework/src/config");
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.PostAPiMethod ="POST";
exports.PutAPiMethod ="PUT";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.CalenderEventsApiEndPoint="bx_block_events/events?events=monthly";
exports.eventPath="/bx_block_events/events"
exports.baseURL = urlConfig.baseURL;
exports.reportContent= "If you don’t want to add any reason for reporting, you can choose none option.";
exports.reportEventEndPOint='/bx_block_events/events/report_event'
exports.reportPostEnd="report_post";
exports.reportQuestion="Why are you reporting this post?";
// Customizable Area End