// Customizable Area Start
import React from "react";
import { Box, InputAdornment, TextField, Modal, Typography, Button, Backdrop, Fade, Avatar,CircularProgress } from '@material-ui/core';
import CommentPostController from "./MainCommentPostController.web";
export const configJSON = require("./config");
import SingleChatList from "./SingleCommentList.web";
import { styled } from "@material-ui/core/styles";
import { SmallCamera, DarkSend, CloseIcon, CloudIcon, blueLike, simpleLike } from "./assets";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class CommentPost extends CommentPostController {
    // Customizable Area Start

    showLike = () => {
        const data = this.props.data
        if (data?.attributes) {
            const isLikedByCurrentUser = data.attributes.is_liked;
            const likeCount = data.attributes.post_like_count ? data.attributes.post_like_count : 0;
            if (isLikedByCurrentUser) {
                if (likeCount > 1) {
                    return <><img src={blueLike} alt="blueLike" />You and {likeCount - 1} other</>
                } else {
                    return <><img src={blueLike} alt="blueLike" />You</>
                }
            } else if (likeCount>0){
                return <><img src={simpleLike} alt="simpleLike" />{likeCount} like</>
            }
        }
    }

    renderNestedComments = (comments: any) => {
        const text = this.state.showAll ? "Hide comments" : "View all comments";
        return <>
            <Box display={"flex"} flexDirection={"column"} className="gap12" fontFamily="Verdana" fontSize={"12px"} py="12px">
                <Box m={0} component="p" display={"flex"} alignItems={"center"} width="max-content" className="gap12 pointer" onClick={this.handleOpenLike} data-test-id="like-count">{this.showLike()}</Box>
                <Box m={0} component="p" >{this.showComment()}</Box>
            </Box>
            {comments.length > 0 && comments.map((comment: { id: string, type: string, attributes: any }, i: number) => {
                if (!this.state.showAll && i > 1) {
                    return null
                }
                return (<>
                    {(i === 0 && comments.length > 2) && <Box component={"p"} onClick={this.handleShowAll} className="pointer" fontFamily="Verdana" m={0} mb={"12px"} display={"inline"} fontSize={"12px"}>{text}</Box>}
                    <Box key={comment.id} display={"flex"} >
                        <SingleChatList key={`${comment.id + i}`} navigation={this.props.navigation} id={""} postId={this.props.data.id}
                            refreshData={this.props.refreshData}
                            data={comment} openChildrenId={this.state.openChildrenId}
                            setOpenChildrenId={this.setOpenChildrenId} hasParent={true} commentId={comment.id}/>
                    </Box>
                </>
                )
            })
            }
        </>
    }

    renderImgModal = () => { 
        return(
            <ImgModal
            onClose={this.handleClose}
            open={this.state.openImgModel}
                aria-labelledby="image-modal"
                aria-describedby="image-modal-description"
            >
                <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: "100%", height: "100%" }}>
                    <Box sx={{ width: { xs: "80%", md: "50%", lg: "40%" }, borderRadius: "12px" }} bgcolor="#fff">
                        <Box display="flex" justifyContent="space-between" alignItems="center" p="20px" color="#808084" sx={{ borderBottom: "1px solid #808080" }}>
                            <Typography variant="subtitle1" className="box-title">{"Select Image"}</Typography>
                            <img src={CloseIcon} alt="CloseIcon" className="pointer" onClick={this.handleClose}/>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ borderBottom: "1px solid #808080" }}>
                            <Box p="20px" sx={{ borderBottom: "1px solid #005487" }} color="#005487">{"Upload"}</Box>
                        </Box>
                        {this.state.imageUrl ?
                            <Box component="div" display="flex" alignItems="center" flexDirection="column" justifyContent="center" sx={{ height: "246px" }}>
                                <img src={this.state.imageUrl} alt="selectedimg" className="selected-img" />
                            </Box>
                            : <Box component="div" data-test-id="drop-id" display="flex" alignItems="center" flexDirection="column" justifyContent="center" sx={{ height: "246px" }}
                                onDrop={(e) => {
                                    this.handleOnImportFile(e.dataTransfer.files);
                                    e.preventDefault();
                                }}
                                onDragOver={e => e.preventDefault()}>
                                <input type='file' id="fileInput" data-test-id="fileInput" className='input-hide'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.handleOnImportFile(e.target.files!);
                                        e.preventDefault();
                                    }}
                                    accept="image/png, image/jpg, image/jpeg,"
                                />
                                <img src={CloudIcon} alt="CloudIcon" />
                                <Button
                                    className={"uploadBtn"}
                                    data-test-id="user-add-btn"
                                    type="button"
                                    onClick={this.handleOpenUploadBtn}
                                >Browse</Button>
                                <Typography variant="subtitle2" align="center" className="drag-text">Or drag a file here</Typography>
                            {this.state.error && <Box component="p" color="red">{this.state.error}</Box>}
                            </Box>}
                        <Box display="flex" p="20px" justifyContent="flex-end" sx={{ borderTop: "1px solid #808080" }}>
                            <Button
                                className={"Publishbtn"}
                                type="submit"
                                data-test-id="user-publish-btn"
                                onClick={this.handleCloseImgModel}
                            >Done</Button>
                        </Box>
                    </Box>
                </Box>
            </ImgModal>
        )
    }

    renderLikeModal = () => {
        return (
            <LikeModal
                open={this.state.likeModal}
                onClose={this.handleCloseLike}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 200
                }}
            >
                <Fade in={this.state.likeModal}>
                    <Box sx={{ width: { xs: "75%", sm: "60%", md: "50%", lg: "35%" } }} className="fadeContainer" display="flex" alignItems="center" justifyContent="center" >
                        <Box bgcolor="#fff" borderRadius="12px" width="100%">
                            <Box sx={{ border: "1px solid black", margin: "20px", padding: "20px", borderRadius: "12px " }}>
                                <Box component="p" color="#005487" fontFamily={"Verdana"} fontWeight={"bold"} fontSize={"20px"} mt={0}>
                                    Likes
                                </Box>
                                <Box sx={{ margin: "auto", height: "340px" }} className="gap-10">
                                    {this.state.loadingpostLike ? 
                                      <Box margin={"auto"}> <CircularProgress /></Box>
                                    : this.state.postLikeUserList.map(item => {
                                        return (
                                            <Box key={item.name} display="flex" >
                                                <Avatar className="avatar" src={item.profile_pic} data-test-id="like-profile-img" onClick={()=>this.userRedirection(item.id,item.type)}/>
                                                <Box component="p" fontFamily={"Verdana"} fontWeight={"bold"} fontSize={"17px"} m={0} ml={"12px"}>{item.name}</Box>
                                            </Box>
                                        )
                                    }
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </LikeModal>
        )
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        return (
            <>
                {this.renderImgModal()}
                {this.renderLikeModal()}
                {this.props.data?.attributes &&
                    <Wrapper bgcolor="#fff" color="#808080" mt="2px">
                        <Box display={"flex"} flexDirection="column" sx={{ width: { xs: "95%", md: "90%" }, maxHeight: "350px", overflow: "auto" }} m="auto">
                            {this.renderNestedComments(this.props.data.attributes.comments.data)}

                        </Box>
                        <Box borderTop="1px solid #808080" py="15px" px="24px" display="flex" alignItems="center" sx={{ width: "100%", boxSizing: "border-box" }}>

                            <Box display="flex" bgcolor="#eeeeee" alignItems="center" justifyContent="space-between" sx={{ borderRadius: "50px", width: "100%", overflow: "hidden" }}>
                                <TextField
                                    placeholder="Add comment"
                                    type="text"
                                    size="small"
                                    name="mainComment"
                                    style={{
                                        borderRadius: "12px",
                                        backgroundColor: "#eee",
                                        padding: "8px 10px",
                                        color: "#808084",
                                        width: "100%"
                                    }}
                                    onChange={this.handleChangeMainComment}
                                    value={this.state.mainCommentMessage}
                                    data-test-id="main-comment"
                                    multiline
                                    InputProps={{
                                        disableUnderline: true,
                                        endAdornment: (
                                            <Box style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                                                <InputAdornment position="end">
                                                    <img src={SmallCamera} alt="SmallCamera" data-test-id="camera-icon-id" className="pointer" onClick={this.openModel} />
                                                </InputAdornment>
                                            </Box>
                                        ),
                                    }}
                                />
                            </Box>
                            <Box data-test-id="send-id" className="pointer" component="div" sx={{ marginLeft: "10px" }} display="flex" justifyContent="center" alignItems="center" onClick={this.sendComment}>
                                <img src={DarkSend} alt="DarkSend" />
                            </Box>
                        </Box>
                    </Wrapper>
                }
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const Wrapper = styled(Box)({
    "& .pointer": {
        cursor: "pointer"
    },
    "& .gap12": {
        gap: "12px",
    }
})

const LikeModal = styled(Modal)({
    "& .fadeContainer": {
        backgroundColor: "transparent",
        display: "flex",
        margin: "auto",
        transform: "translate(-50%, -50%)",
        top: "50%",
        left: "50%",
        position: "absolute",
    },
    "& .gap-10": {
        gap: "10px",
        display: "flex",
        overflowY: "auto",
        flexDirection: "column"
    },
    "& .avatar": {
        cursor: "pointer"
    }
});

const ImgModal = styled(Modal)({
    "& .input-hide": {
        display: "none"
    },
    "& .drag-text": {
        fontSize: "17px",
        fontFamily: "Verdana",
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        color: "#808084",
    },
    "& .selected-img": {
        objectFit: "cover",
        aspectRatio:"1/1",
        height: "90%",
    },
    "& .box-title":{
        fontFamily:"Verdana",
        fontSize:"20px",
    },
    "& .Publishbtn": {
        width: "160px",
        color: "#fff",
        height: "48px",
        padding: "15px 56px 14px 57px",
        margin: "23px 7px 0 38px",
        border: "solid 1px #005487",
        marginTop: "8px",
        backgroundColor: "#01548a",
    },
    "& .uploadBtn": {
        color: "#fff",
        width: "160px",
        margin: "15px",
        height: "48px",
        border: "solid 1px #005487",
        padding: "15px 56px 14px 57px",
        backgroundColor: "#01548a"
    },
});

export default CommentPost;
// Customizable Area End