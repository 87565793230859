Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
exports.putApiMethod = 'PUT';

exports.getChatListApiEndPoint = 'chat/chats';
exports.showChatApiEndPoint = 'chat/chats';
exports.getChatHistoryApiEndPoint = 'chat/chats/history';
exports.searchMessageApiEndPoint = 'chat/chats/search_messages';
exports.searchChatGroupApiEndPoint = 'chat/chats/search';
exports.createChatRoomApiEndPoint = 'chat/chats';
exports.addUserToChatApiEndPoint = 'chat/chats/add_user';
exports.leaveChatApiEndPoint = 'chat/chats/leave';
exports.sendMessageApiEndPoint = 'chat/chats';
exports.updateReadMessageApiEndPoint = 'chat/chats/read_messages';
exports.updateChatApiEndPoint = 'chat/chats';

exports.getMyChatsApiEndpoint = 'chat/chats/mychats';

exports.createButtonText = 'Create';
exports.createRoomButtonText = 'Create Room'
exports.addButtonText = 'Add';
exports.closeButtonText = 'Close';
exports.addAccountText = 'Add Account';
exports.leaveChatRoomText = 'Leave Chat';
exports.sendText = 'Send';
exports.cancelText = 'Cancel';
exports.errorTitle = 'Error';
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.';

exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
exports.putApiMethod = 'PUT';
exports.deleteApiMethod = 'DELETE';

exports.fetchConversations= "bx_block_chat9/chats/fetch_conversations";
exports.privateConversation="bx_block_chat9/chats/create_conversation";
exports.createGroup="bx_block_chat9/chats/create_group_conversation";
exports.generateToken="bx_block_chat9/chats/generate_access_token";
exports.friendList="friend_list";
exports.deleteConversation="bx_block_chat9/chats/delete_conversation?conversation_sid=";
exports.exitConversation="bx_block_chat9/chats/remove_participants";
exports.reportConversation="bx_block_chat9/chats/report_chat";
exports.notificationEnd="bx_block_chat9/chats/notification_changes";
exports.fetchSingleConversation="bx_block_chat9/chats/get_specific_conversation?conversation_sid=";
exports.addMemberEnd="bx_block_chat9/chats/add_participants";
exports.getCommunity="get_community";
exports.getUser="get_user?is_post_invisible=true";
exports.followerList="user_followers_list";
exports.chatName="Chat";
exports.send_a_message="Send a Message";
exports.toName="To";
exports.new_group="New Group";
exports.groupProfile="Group Profile";
exports.groupName="Group Name";
exports.groupPlaceholder="Add Group Name here";
exports.userError="User not found";
exports.toPlaceholder="Type name or multiple names...";
exports.friendError="Please make friends to chat.";
exports.messagePlaceholder="Type message...";
exports.optionMute="Mute notifications";
exports.optionUnMute="UnMute notification";
exports.optionDelete="Delete chat";
exports.optionExit="Exit group";
exports.optionReport="Report";
exports.optionAddMember="Add Members";
exports.doneBtn="Done";
exports.browseBtn="Browse";
exports.exitGroupMessage="Are you sure you want to exist this group?";
exports.deleteGroupMessage="Are you sure you want to delete this chat?";
exports.reportTitle= "Report something that doesn't look right.";
exports.reportDescription="If you don't want to add any reason for reporting,you can choose none option.";
exports.reportDropdown="Why are you reporting this chat?";
exports.instruction=" You can create a group of particular user if more than one users are selected.";
exports.send_private="Send privately";
exports.create_group="Create Group";
exports.dragSentence="Or drag a file here";
exports.Friend="Friends";
exports.Followers="Followers";
exports.select_members="Select Members";
  // Customizable Area End