import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import moment from 'moment';
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

interface IUser {
  id: string;
  type: string;
  attributes: {
    activated: boolean;
    country_code: string;
    first_name: string;
    full_phone_number: string;
    email: string;
    last_name: string;
    full_name: string;
    phone_number: string;
    job_title: string;
    type: string;
    pronouns: string;
    language: string[];
    race: string;
    ethnicity: string;
    state: string;
    is_private: boolean;
    gender: string;
    city: string;
    country: string; // Type depends on your country object structure
    dob: string;
    timezone: string;
    created_at: string;
    is_local_admin: boolean;
    updated_at: string;
    device_id: string | null;
    profile_picture: string;
    follower_count: number;
    is_Community_present: boolean;
    background_image: string | null;
    selected_communities: { data: { id: string, attributes: { profile_pic: string, name: string } } } | null;
    friend_list_count: number;
    is_in_connection: boolean;
    is_friend_request_sent: boolean;
    is_biometric_or_facial_available: boolean;
    is_already_request_available: boolean;
    total_post_count: number;
  }
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: {
    profile_picture: string;
    pronouns: string;
    dob: string;
    language: string[];
    race: string;
    ethnicity: string;
    gender: string;
    country: string;
    state: string;
    city: string;
    timezone: string;
    full_name: string;
    job_title: string;
    email: string;
    full_phone_number: string;
  },
  errors: {
    profile_picture: string
    city: string,
    race: string,
    ethnicity: string,
    country: string,
    gender: string,
    state: string,
    timezone: string,
    job_title: string,
    full_name: string,
  };
  isLoading: boolean;
  language_list: {id: string, value: string, label: string}[];
  gender_ist: {id: string, value: string, label: string}[];
  race_list: {id: string, value: string, label: string}[];
  pronounce_list: {id: string, value: string, label: string}[];
  ethnicity_list: {id: string, value: string, label: string}[];
  country_list: {id: string, value: string, label: string, country_code: string}[];
  state_list: {id: string, value: string, label: string}[];
  selectedFile: File | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UserProfileInformationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserDetailsId: string = "";
  apiPronounListId: string = '';
  apiRaceListId: string = '';
  apiEthinicityListId: string = '';
  apiGenderListId: string = '';
  apiCountryListId: string = '';
  apiStateListId: string = '';
  apiLanguageListId: string = '';
  apiUpdateUserId: string = '';
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: {
        profile_picture: "",
        pronouns: '',
        dob: '',
        language: [],
        race: '',
        ethnicity: '',
        email: '',
        gender: '',
        country: '',
        state: '',
        city: '',
        full_phone_number: '',
        timezone: '',
        full_name: '',
        job_title: ''
      },
      errors:{
        profile_picture: "",
        ethnicity: '',
        race: '',
        country: '',
        state: '',
        gender: '',
        city: '',
        full_name: '',
        job_title: '',
        timezone: '',
      },
      isLoading: false,
      language_list: [],
      gender_ist: [],
      race_list: [],
      pronounce_list: [],
      ethnicity_list:[],
      country_list:[],
      state_list:[],
      selectedFile: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  timezoneList=[
    {
      id:"1",
      label:"Eastern",
      value:"Eastern",
    },
    {
      id:"2",
      label:"Central",
      value:"Central",
    },
    {
      id:"3",
      label:"Mountain",
      value:"Mountain",
    },
    {
      id:'4',
      label:"AZ",
      value:"AZ",
    },
    {
      id:"5",
      label:"Pacific",
      value:"Pacific",
    }
  ];

  handleChangeDropdownValue = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = e.target;
    if(name==="language" ){
      if (Array.isArray(value)) {
        // If value is an array, you can filter it
        const filteredValue = value.filter(item => item);
        // Now you can do something with filteredValue
        this.setState(prevState => ({
          data: {
            ...prevState.data,
            language: filteredValue
          }
        }));
      }
      return;
    }
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [name]: value
      },errors: {
        ...prevState.errors,
        [name]: '',
      }
    }));
  }

  handleChangeCountry = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState(preState=>({
        data: {
          ...preState.data,
          country: e.target.value,
          state: '',
        },
        errors: {
          ...preState.errors,
          country: ''
        }
    }))
    this.getStateList(e.target.value)
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    switch (apiRequestCallId) {
      case this.apiGetUserDetailsId:
        {
          this.getUserDetailsResponse(responseJson)
        }
        break;
      case this.apiLanguageListId:
        {
          this.setState({language_list:responseJson.data.map((item:string)=>({id:item,value:item,label:item}))})
        }
        break; 
      case this.apiPronounListId:
        {
          this.setState({pronounce_list:responseJson.data.map((item:string)=>({id:item,value:item,label:item}))})
        }
        break; 
      case this.apiCountryListId:
        {
          this.setState({country_list:responseJson.data.map((item:{country_code:string,country_name:string})=>({id:item.country_name,value:item.country_name,country_code:item.country_code,label:item.country_name}))})
        }
        break; 
      case this.apiRaceListId:
        {
          this.setState({race_list:responseJson.data.map((item:string)=>({id:item,value:item,label:item}))})
        }
        break; 
      case this.apiGenderListId:
        {
          this.setState({gender_ist:responseJson.data.map((item:string)=>({id:item,value:item,label:item}))})
        }
        break;
      case this.apiEthinicityListId:
        {
          this.setState({ethnicity_list:responseJson.data.map((item:string)=>({id:item,value:item,label:item}))})
        }
        break;
      case this.apiStateListId:
        {
          this.setState({isLoading: false, state_list:responseJson.data.map((item:string)=>({id:item,value:item,label:item}))})
        }
        break;
      case this.apiUpdateUserId:
        {
          this.setState({selectedFile:null,isLoading:false});
          if(responseJson.data){
            toast.success("User updated successfully.")
            this.redirectionafterUpdate()
           }else if(responseJson.errors){
            responseJson.errors.forEach((error: { [key: string]: string }) => {
              Object.entries(error).forEach(([_key, value]) => {
                  toast.error(value);
              });
            });
           }
        }
        break;
      default: return;
    }
  }

  redirectionafterUpdate=()=>{
    const param = this.props.navigation.getParam("navigationBarTitleText") ?? "";
    if(param && param.includes("profile")){
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "UserProfileInformationEdit");
  
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(
        getName(MessageEnum.NavigationScreenNameMessage),
        "view-profile"
      )
      this.send(message);
    }else{
      this.props.navigation.navigate("UserProfileInformationEdit");
    }
  }

  getUserDetailsResponse = (responseJson:{data:IUser}) => {
    this.setState({isLoading:false})
    if(responseJson.data){
      this.setState(prev => ({ ...prev, data: responseJson.data.attributes }));
      responseJson.data.attributes.country && this.getStateList(responseJson.data.attributes.country);
    }
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    this.getUserDetails();
    [
      "get_countries",
      "ethnicity_list",
      "gender_list",
      "language_list",
      "race_list",
      "pronounce_list"
    ].forEach((endpoint) => this.getDrpdownList(endpoint));
  }

  getDrpdownList = async (endPoint: string) => {
    const signupToken = await getStorageData('SignupSuccessToken');
    const loginToken = await getStorageData('LoginSuccessToken')
    const { ethnicity_list, gender_list, language_list, race_list, pronounce_list } = configJSON;
    const header = {
      token: signupToken || loginToken,
      "Content-Type": configJSON.apiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    switch (endPoint) {
      case pronounce_list:
        {
          this.apiPronounListId = requestMessage.messageId;
        }
        break;
      case language_list:
        {
          this.apiLanguageListId = requestMessage.messageId;
        }
        break;
      case gender_list:
        {
          this.apiGenderListId = requestMessage.messageId;
        }
        break;
      case ethnicity_list:
        {
          this.apiEthinicityListId = requestMessage.messageId;
        }
        break;
      case race_list:
        {
          this.apiRaceListId = requestMessage.messageId;
        }
        break;
      default:
        {
          this.apiCountryListId = requestMessage.messageId;
        }
        break;
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStateList = async (value: string) => {
    this.setState({isLoading:true})
    const countryCode = this.state.country_list.find(item=>item.value===value)?.country_code;
    const signupToken = await getStorageData('SignupSuccessToken');
    const loginToken = await getStorageData('LoginSuccessToken')
    const header = {
      token: signupToken || loginToken,
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.apiStateListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.get_states + `?country_code=${countryCode}`
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getUserDetails = async () => {
    this.setState({isLoading:true})
    const signupToken = await getStorageData('SignupSuccessToken');
    const loginToken = await getStorageData('LoginSuccessToken')
    const header = {
      token: signupToken || loginToken,
      "Content-Type": configJSON.apiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.apiGetUserDetailsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.get_user
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  checkRequiredfield = () => {
    let errors = {
      profile_picture: '',
      race: '',
      ethnicity: '',
      country: '',
      gender: '',
      state: '',
      city: '',
      timezone: '',
      full_name: '',
      job_title: ''
    };
    const { profile_picture, gender, state, city, country, full_name , ethnicity, race, job_title , timezone} = this.state.data
    if (!profile_picture) {
        errors.profile_picture = "Profile picture is required";
    }

    if (!full_name) {
        errors.full_name = "Full name is required";
    }

    if (!job_title) {
        errors.job_title = "Job title is required";
    }

    if (!race) {
        errors.race = "Race is required";
    }

    if (!ethnicity) {
        errors.ethnicity = "Ethnicity is required";
    }

    if (!gender) {
        errors.gender = "Gender is required";
    }

    if (!country) {
        errors.country = "Country is required";
    }

    if (!state) {
        errors.state = "State is required";
    }

    if (!city) {
        errors.city = "City is required";
    }

    if (!timezone) {
        errors.timezone = "Timezone is required";
    }

    const hasErrors = Object.values(errors).some(value => value !== '');
    this.setState({errors: errors});
    return hasErrors
  }

  updateUserDetails = async () => {
    const hasErrors =  this.checkRequiredfield();
    if(hasErrors) return;
    this.setState({isLoading:true});
    let body = new FormData();
    body.append("account[name]",this.state.data.full_name);
    body.append("account[job_title]",this.state.data.job_title);
    body.append("account[dob]",this.state.data.dob);
    body.append("account[race]",this.state.data.race);
    body.append("account[ethnicity]",this.state.data.ethnicity);
    body.append("account[gender]",this.state.data.gender);
    body.append("account[pronouns]",this.state.data.pronouns);
    body.append("account[timezone]",this.state.data.timezone);
    {this.state.selectedFile && body.append("account[profile_pic]",this.state.selectedFile)}
    body.append("account[country]",this.state.data.country);
    body.append("account[state]",this.state.data.state);
    body.append("account[city]",this.state.data.city);
    this.state.data.language.forEach(item=>body.append("account[language][]",item));

    const signupToken1 = await getStorageData('SignupSuccessToken');
    const loginToken = await getStorageData('LoginSuccessToken')
    const header = {
      token: signupToken1 || loginToken,
      "Content-Type": configJSON.apiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.apiUpdateUserId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.update_user
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleChangeField = (e:React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
        data: {
            ...prevState.data,
            [name]: value
        },
        errors: {
          ...prevState.errors,
          [name]: ''
        }
    }));
  }

  uploadProfileImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const tempFile = e.target.files[0];
      this.setState(prev => ({
        ...prev,
        data: {
          ...prev.data,
          profile_picture: window.URL.createObjectURL(tempFile)
        },
        errors: {
          ...prev.errors,
          profile_picture: ''
        },
        selectedFile: tempFile
      }));
    }
  }

  handleDateChange =(e:Date) => {
    const tempDate: string = moment(e).format('MM/DD/YYYY');
    this.setState(prev => ({
      ...prev,
      data: {
        ...prev.data,
        dob:tempDate
      }}
    ))
  }
  // Customizable Area End
}
