import React from "react";

import {
  // Customizable Area Start
  Card,
  Grid,
  MenuItem,
  Box,
  Typography,
  MenuList,
  IconButton,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import { arrowBack, dropDownIcon, rightCircleArrowIcon } from "./assets";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Root = styled(Grid)({
  position: "relative",
  borderRadius: "12px",
})

const MainCard = styled((Card))({
  borderRadius: "12px",
});

const Header = styled((Box))({
  display: "flex",
  position: 'relative',
  padding: "25px 0px 23px 24px",
  borderBottom: "1px solid #808080",
  "& .pointer":{
    cursor: "pointer"
  }
});

const Heading = styled((Typography))({
  color: "#005487",
  lineHeight: 1.1,
  letterSpacing: "-0.41px",
  fontFamily: "Verdana",
  fontSize: "20px",
  marginRight: "9px",
  fontWeight: "bold",
  marginLeft: "9px"
})

const UserCard = styled(Card)({
  padding: "23px 24px 0px 24px",
})

const UserCardSubheading = styled(Typography)({
  fontSize: "17px",
  marginBottom: "18px",
  fontFamily: "Verdana",
  fontWeight: "bold",
})

const UserList = styled(MenuList)({
  maxHeight: "800px",
  overflowY: "auto",
  minHeight: "360px",
  padding: "0px !important",
  width: "100% !important",
  '&::-webkit-scrollbar': {
    width: '2px',
    height: '2px',
    background: 'gray',
  }
})
const UserListItem = styled(MenuItem)({
  borderRadius: "12px",
  marginBottom: "16px",
  marginLeft: "4px",
  marginRight: "4px",
  height: '60px',
  display: "flex",
  boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
  justifyContent: "space-between",
  padding: "0 25px 0 20px",
  "&:hover":{
    border: "1px solid #005487",
    backgroundColor: "#fff"
  }
})

const UserInfo = styled(Box)({
  display: "flex",
  justifyContent: "center",
})

const DropDownList = styled(Box)({
  position: "absolute",
  top: "50px",
  backgroundColor: "#fff",
  borderRadius: "12px",
  marginLeft: "auto",
  zIndex: 1,
  boxShadow: "2px 4px 16px 4px rgba(0, 0, 0, 0.25)",
  padding: "20px",
  width: "480px",
  boxSizing: "border-box",
  "@media(max-width:600px)": {
    width: "320px",
  },
  "@supports (-moz-appearance:none)": {
    maxWidth: "-moz-available", // Apply for Firefox
  },
  "@supports (-webkit-appearance:none)": {
    maxWidth: "-webkit-fill-available", // Apply for WebKit-based browsers
  },
});
const DropdownListItem = styled(MenuItem)({
  padding: "20px 0 21px 19.5px",
  fontFamily: "Verdana",
  fontSize: "16px",
  color: "#808080",
  '&.Mui-selected': {
    backgroundColor: '#005586',
    color: "#fff",
    "&:hover": {
      backgroundColor: "#005586",
    },
  },
  "&:hover": {
    color: "#fff",
    backgroundColor: "#005586",
  },

})
const DropDownIcon = styled((IconButton))({
  paddingBottom:0,
  paddingTop:0,
  "& .dropdown-img": {
      height: "22px",
      width: "22px",
    }
})
// Customizable Area End

import AssessmentTestUserListingController, {
  Props,
  configJSON,
} from "./AssessmentTestUserListingController";
import Loader from "../../../components/src/Loader.web";
import { handleInvalidData } from "../../../components/src/common";

export class AssessmentTestUserListing extends AssessmentTestUserListingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Root xs={12} >
        <Loader loading={this.state.showLoading} />
        <MainCard >
          <Header >
            <img className="pointer" src={arrowBack} alt="back" data-test-id="back-icon" onClick={this.handleBackNavigation}/>
            <Heading >{configJSON.labelIndivisualLabel}</Heading>
            <DropDownIcon
              aria-label="Expand"
              onClick={() => this.setState({ showDropDown: !this.state.showDropDown })}
            >
              <img src={dropDownIcon} alt="DropDownIcon" className="dropdown-img"/>
            </DropDownIcon>
              {this.state.showDropDown && (
              <DropDownList  sx={{left:{xs: "unset",sm:"200px"},right: {xs:0, sm: "unset"}}}>
                {this.state.dropDownList.map((e, i: number) => {
                  return (
                    <DropdownListItem key={`key-${e.id}-${i + 1}`} selected={this.state.selectedMenu === e.id} onClick={() => this.handleMenuChange(e.id)}>
                      <Typography variant="inherit">{e.title}</Typography>
                    </DropdownListItem>
                  );
                })}
              </DropDownList>)}
          </Header>
          <Box>
            <UserCard >
              <UserCardSubheading >{configJSON.labelUserList}</UserCardSubheading>
              <UserList>
                {this.state.usersList.map((e, i: number) => {
                  return (
                    <UserListItem onClick={() => this.handleNavigation(e.id)} key={i}>
                      <UserInfo>

                        <img style={{borderRadius: '50%'}} width="40px" height="40px" src={e.attributes.profile} />
                        <Box component={"p"} fontFamily={"Verdana"} fontWeight={"bold"} fontSize={"17px"} m={0} ml={"16px"}>{e.attributes.user_name}</Box>
                      </UserInfo>
                      <img src={rightCircleArrowIcon} />
                    </UserListItem>
                  )
                })}
                {
                  this.state.usersList.length === 0 && <Typography
                    className={`Heading5`}
                    gutterBottom
                    variant="body2"
                    component="p"
                  >No users found.</Typography>
                }
              </UserList>

            </UserCard>
          </Box>
        </MainCard>
      </Root>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default AssessmentTestUserListing
// Customizable Area End
