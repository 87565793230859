// Customizable Area Start
import React from "react";
import {
  Box, FormControl, FormHelperText, FormControlLabel, RadioGroup, Radio, TextField, InputAdornment, Button
} from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import CreateEventStepOneModalController, {
  Props,
} from "./CreateEventStepOneModalController.web";
import CustomDatepicker from "../../../components/src/CustomDatepicker.web";
import Fade from "@material-ui/core/Fade";
import { BlankCalender, CameraIcon, CloseIcon, Dropdown, LocationMinus, Time, blueLink, calendar, editcopy } from "./assets";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import moment from 'moment';
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

// Customizable Area Start
const Poststyles = (theme: Theme) =>
  createStyles({
    inputHide: {
      display: "none"
    },
    heightResponsive: {
      overflowY: "auto",
      height: "526px",
      maxHeight: "60vh",
    },
    error: {
      fontSize: "12px",
      fontFamily: "Verdana",
      color: "red",
    },
    thumbnail: {
      width: "100%",
      height: "100%",
      borderRadius: "12px"
    },
    pointer: {
      cursor: "pointer"
    },
    modal: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
    },
    radioWrapper:{
      "& fieldset":{
        paddingLeft: "10px",
      },
      "& .MuiButtonBase-root": {
        padding: "3px !important",
      },
      "& .MuiFormControlLabel-label":{
        fontSize: "16px",
        alignItems: "normal",
      },
      "& .MuiIconButton-label": {
        width:"20px",
        height: "20px"
      },
      "& .MuiFormGroup-root":{
        flexDirection: "row",
        gridGap: "20px"
      },
      "& .Mui-checked":{
        color: "#005487",
      },
      "& .MuiRadio-root":{
        color: "#005487",
      },
    },
    label: {
      marginBottom: "8px",
      color: "#005487",
      fontSize: "14px",
      fontWeight: "bold",
      fontFamily: "Verdana",
    },
    inputField: {
      "& .MuiOutlinedInput-input": {
        paddingBlock: "20px",
      }
    },
    inputFieldTextArea: {
      "& .MuiInputBase-root": {
          alignItems: "start"
      },
      "& .MuiInputAdornment-root": {
          marginTop: "10px",
      },
  },
    Publishbtn: {
      height: "48px",
      fontSize: "16px",
      fontFamily: "Verdana",
            fontWeight: "bold",
            backgroundColor: "#005487",
            color: "#fff",
            width: "160px",
            textTransform: "none",
            "&:hover": {
                backgroundColor: "#005487"
            }
    },
    PublishbtnExpand:{
      height: "48px",
      fontWeight: "bold",
      fontSize: "16px",
      fontFamily: "Verdana",
            color: "#fff",
            backgroundColor: "#005487",
            textTransform: "none",
            width: "100%",
            "&:hover": {
              backgroundColor: "#005487",
            },
    },
  });
// Customizable Area End
export class CreateEventStepOneModal extends CreateEventStepOneModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderPublishBtn = () => {
    return (
      <Box display="flex" justifyContent="end" alignItems={"center"} borderTop={!this.state.expandDescription ? "1px solid #808080" : ''} p={"20px"}>
        <Button variant="contained" type="submit" data-test-id="publish-btn" onClick={this.handlePublish} className={this.state.expandDescription ? this.props.classes.PublishbtnExpand : this.props.classes.Publishbtn}>{this.state.expandDescription ? "Done" : "Publish"}</Button>
      </Box>
    )
  }

  renderInPerson = () => {
    return(<>
      {this.state.data.event_type === 'in-person' && <Box>
      <Box component={"label"} className={this.props.classes.label}>Address*</Box>
      <Box display={"flex"} flexDirection={"column"} gridGap={"8px"}>
        <FormControl fullWidth>

          <TextField
            placeholder="Enter your Street"
            name="street"
            className={this.props.classes.inputField}
            type="text"
            data-test-id="street"
            value={this.state.data.street}
            onChange={this.handleChange}
            fullWidth
            variant="outlined"
            error={Boolean(this.state.error.street)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" >
                  <img src={LocationMinus} />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            placeholder="Enter your City*"
            name="city"
            className={this.props.classes.inputField}
            type="text"
            data-test-id="city"
            value={this.state.data.city}
            onChange={this.handleChange}
            fullWidth
            variant="outlined"
            error={Boolean(this.state.error.city)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" >
                  <img src={LocationMinus} />
                </InputAdornment>
              ),
            }}
          />
          {this.state.error.city && (
            <FormHelperText className={this.props.classes.error}>
              {this.state.error.city}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl fullWidth>
          <TextField
            placeholder="Enter your State*"
            name="state"
            className={this.props.classes.inputField}
            type="text"
            data-test-id="state"
            value={this.state.data.state}
            onChange={this.handleChange}
            fullWidth
            variant="outlined"
            error={Boolean(this.state.error.state)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" >
                  <img src={LocationMinus} />
                </InputAdornment>
              ),
            }}
          />
          {this.state.error.state && (
            <FormHelperText className={this.props.classes.error}>
              {this.state.error.state}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl fullWidth>
          <TextField
            placeholder="Enter your Zipcode*"
            name="zipcode"
            className={this.props.classes.inputField}
            type="number"
            data-test-id="zipcode"
            value={this.state.data.zipcode}
            onChange={this.handleChange}
            fullWidth
            variant="outlined"
            error={Boolean(this.state.error.zipcode)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" >
                  <img src={LocationMinus} />
                </InputAdornment>
              ),
            }}
          />
          {this.state.error.zipcode && (
            <FormHelperText className={this.props.classes.error}>
              {this.state.error.zipcode}
            </FormHelperText>
          )}
        </FormControl>
      </Box>
      </Box>}
      </>
    )
  }

  renderVirtual = () => {
    return(
      <>
      {this.state.data.event_type === 'virtual' && <FormControl fullWidth>
                  <Box component={"label"} className={this.props.classes.label}>Event URL*</Box>
                  <TextField
                    placeholder="Add Event URL"
                    name="event_url"
                    className={this.props.classes.inputField}
                    type="url"
                    data-test-id="event_url"
                    value={this.state.data.event_url}
                    onChange={this.handleChange}
                    fullWidth
                    variant="outlined"
                    error={Boolean(this.state.error.event_url)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" >
                          <img src={blueLink} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {this.state.error.event_url && (
                    <FormHelperText className={this.props.classes.error}>
                      {this.state.error.event_url}
                    </FormHelperText>
                  )}
                </FormControl>}
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <Modal
        aria-labelledby="transition-modal-title"
        className={this.props.classes.modal}
        aria-describedby="transition-modal-description"
        open={this.props.openModal}
        closeAfterTransition
        onClose={this.props.handleClose}
        BackdropProps={{
          timeout: 200,
        }}
        BackdropComponent={Backdrop}
      >
        <Fade in={this.props.openModal}>
            <Box sx={{ width: { xs: "80%", md: "70%", lg: "50%" }, maxWidth: "604px" }} bgcolor={"#fff"} borderRadius="12px" >
             {this.state.loading && <Loader loading={this.state.loading}/>}
             {!this.state.expandDescription && <Box display={"flex"} justifyContent={"space-between"} p={"24px"} borderBottom={"1px solid #808080"}>
                <Box component={"h4"} fontFamily={"Verdana"} fontSize={"20px"} fontWeight={"bold"} color={"#808080"} m={0} data-test-id="title">
                  Create an Event
                </Box>
                <img src={CloseIcon} alt="close" className={this.props.classes.pointer} onClick={this.props.handleClose} />
              </Box>}
              <Box component={'section'} className={this.props.classes.heightResponsive}>
              <Box p={"24px"} pt={"20px"} display={"flex"} flexDirection={"column"} gridGap={"20px"}>
                {!this.state.expandDescription && <>
                <FormControl fullWidth>
                  <Box component="div" className={this.props.classes.pointer} display="flex" data-test-id="drop-id" flexDirection="column" alignItems="center" justifyContent="center" borderRadius={"12px"} bgcolor={"#f4f4f4"} sx={{ height: "160px" }} gridGap={"7px"}
                    onDrop={(e) => {
                      this.handleOnImportFile(e.dataTransfer.files)
                      e.preventDefault();
                    }}
                    onClick={this.handleOpenUploadBtn}
                    onDragOver={e => e.preventDefault()}>
                    <input type='file' data-test-id="fileInput" id="fileInput" className={this.props.classes.inputHide}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        this.handleOnImportFile(e.target.files!)
                        e.preventDefault();
                      }}
                      accept="image/png, image/jpeg, image/jpg,"
                    />
                    {this.state.data.cover_image ?
                      <img src={this.state.data.cover_image} className={this.props.classes.thumbnail} alt="thumbnail" />
                      : <>
                        <img src={CameraIcon} alt="camera" />
                        <Box component={"p"} fontFamily={"Verdana"} fontWeight={"bold"} color="#808080" fontSize={"17px"} m={0} textAlign={"center"}>Upload Cover Image</Box>
                        <Box component={"p"} fontFamily={"Verdana"} fontWeight={"normal"} color="#808080" fontSize={"17px"} m={0} textAlign={"center"}>Upload cover image minimum width <br />400 pixel (16:9).</Box>
                      </>}
                  </Box>
                  {this.state.error.cover_image && (
                    <FormHelperText className={this.props.classes.error}>
                      {this.state.error.cover_image}
                    </FormHelperText>
                  )}
                </FormControl>
                <Box display={"flex"} flexDirection={"column"} className={this.props.classes.radioWrapper}>
                  <Box component={"label"} className={this.props.classes.label}>Event Type*</Box>
                  <FormControl component="fieldset">
                    <RadioGroup >
                      <FormControlLabel value="virtual" control={<Radio size="small" data-test-id="virtual-id" checked={this.state.data.event_type === 'virtual'} onChange={this.radioChange} />} label="Virtual" />
                      <FormControlLabel value="in-person" control={<Radio size="small" data-test-id="person-id" checked={this.state.data.event_type === 'in-person'} onChange={this.radioChange} />} label="In-person" />
                    </RadioGroup>
                    {this.state.error.event_type && (
                      <FormHelperText className={this.props.classes.error}>
                        {this.state.error.event_type}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                {this.renderVirtual()}
                <FormControl fullWidth>
                  <Box component={"label"} className={this.props.classes.label}>Event Name*</Box>
                  <TextField
                    placeholder="Enter your Event Name"
                    name="event_name"
                    className={this.props.classes.inputField}
                    type="text"
                    data-test-id="event_name"
                    value={this.state.data.event_name}
                    onChange={this.handleChange}
                    fullWidth
                    variant="outlined"
                    error={Boolean(this.state.error.event_name)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" >
                          <img src={BlankCalender} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {this.state.error.event_name && (
                    <FormHelperText className={this.props.classes.error}>
                      {this.state.error.event_name}
                    </FormHelperText>
                  )}
                </FormControl>
                {this.renderInPerson()}
                <Box>
                <FormControl fullWidth>
                  <Box component={"label"} className={this.props.classes.label}>Timezone of Event*</Box>
                  <CustomSelectDropdown
                    fieldId="timezone"
                    fieldValue={this.state.data.timezone}
                    fieldName="timezone"
                    handleChange={this.handleChangeDropDown}
                    MenuProps={MenuProps}
                    renderValue={""}
                    fieldError={Boolean(this.state.error.timezone)}
                    fieldMenuItems={[...this.timezoneList]}
                    iconComponent={<img src={Dropdown} alt="dropdown" />}
                    startAdornmentIcon={<img src={Time} alt="time" />}
                    placeHolderText="Select Timezone of Event"
                  />
                  {this.state.error.timezone && (
                    <FormHelperText className={this.props.classes.error}>
                      {this.state.error.timezone}
                    </FormHelperText>
                  )}
                </FormControl>
                </Box>
                    <Box>
                <Box component={"label"} className={this.props.classes.label}>Date*</Box>
                <Box display={"flex"} flexDirection={"column"} gridGap={"8px"}>
                  <FormControl fullWidth>
                    <CustomDatepicker disableFutureDate={false} disablePast={true} className="paddingRight" error={Boolean(this.state.error.start_date)} placeHolderText={"Start Date"} format="DD-MM-YYYY" selectedDate={moment(this.state.data.start_date, 'DD/MM/YYYY').format('MM/DD/YYYY')} handleChangeDate={this.handleStartDateChange} calendarIcon={<img src={calendar} alt="claender" />} openCalendarIcon={<img src={Dropdown} alt="dropdoen" />} />
                    {this.state.error.start_date && (
                      <FormHelperText className={this.props.classes.error}>
                        {this.state.error.start_date}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl fullWidth>
                    <CustomDatepicker disableFutureDate={false} disablePast={true} className="paddingRight" error={Boolean(this.state.error.end_date)} placeHolderText={"End Date"} format="DD-MM-YYYY" selectedDate={moment(this.state.data.end_date, 'DD/MM/YYYY').format('MM/DD/YYYY')} handleChangeDate={this.handleEndDateChange} calendarIcon={<img src={calendar} alt="claender" />} openCalendarIcon={<img src={Dropdown} alt="dropdoen" />} />
                    {this.state.error.end_date && (
                      <FormHelperText className={this.props.classes.error}>
                        {this.state.error.end_date}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                </Box>
                <Box>
                <Box component={"label"} className={this.props.classes.label}>Time*</Box>
                <Box display={"flex"} flexDirection={"column"} gridGap={"8px"}>
                  <FormControl fullWidth>
                    <CustomSelectDropdown
                      fieldId="start_time"
                      fieldValue={this.state.data.start_time}
                      fieldName="start_time"
                      handleChange={this.handleChangeDropDown}
                      MenuProps={MenuProps}
                      renderValue={""}
                      fieldError={Boolean(this.state.error.start_time)}
                      fieldMenuItems={[...this.state.timezoneEndTimeList]}
                      iconComponent={<img src={Dropdown} alt="dropdown" />}
                      startAdornmentIcon={<img src={Time} alt="time" />}
                      placeHolderText="Start Time"
                    />
                    {this.state.error.start_time && (
                      <FormHelperText className={this.props.classes.error}>
                        {this.state.error.start_time}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl fullWidth>
                    <CustomSelectDropdown
                      fieldId="end_time"
                      fieldValue={this.state.data.end_time}
                      fieldName="end_time"
                      handleChange={this.handleChangeDropDown}
                      MenuProps={MenuProps}
                      renderValue={""}
                      fieldError={Boolean(this.state.error.end_time)}
                      fieldMenuItems={[...this.state.timezoneEndTimeList]}
                      iconComponent={<img src={Dropdown} alt="dropdown" />}
                      startAdornmentIcon={<img src={Time} alt="time" />}
                      placeHolderText="End Time"
                    />
                    {this.state.error.end_time && (
                      <FormHelperText className={this.props.classes.error}>
                        {this.state.error.end_time}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                </Box>
                </>}

                <FormControl fullWidth>
                  <Box component={"label"} className={this.props.classes.label}>Event Description*</Box>
                  <TextField
                    placeholder="Add an Event Description here"
                    name="description"
                    className={this.props.classes.inputFieldTextArea}
                    type="text"
                    multiline
                    minRows={this.state.expandDescription ? 15 : 4}
                    data-test-id="description"
                    value={this.state.data.description}
                    onChange={this.handleChange}
                    onClick={this.handleExpandDescription}
                    fullWidth
                    variant="outlined"
                    error={Boolean(this.state.error.description)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" >
                          <img src={editcopy} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {this.state.error.description && (
                    <FormHelperText className={this.props.classes.error}>
                      {this.state.error.description}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              </Box>
              {this.renderPublishBtn()}
            </Box>
        </Fade>
      </Modal>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "300px",
      borderRadius:"12px"
    }
  },
};
export default withStyles(Poststyles, { withTheme: true })(CreateEventStepOneModal);
// Customizable Area End
