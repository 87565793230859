// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  Grid,
  TextField,
  FormControl,

} from "@material-ui/core";

import { SideImage, Logo } from "./assets";
import PhoneVerificationNewController, {
  Props
} from "./PhoneVerificationNewController.web";
export const configJSON = require("./config");
import "./PhoneVerificationNew.web.css";
import PhoneIcon from "@material-ui/icons/Phone";


// Customizable Area End

// Customizable Area Start
const webStyling = {
  logoCard: {
    width: "200px",
    height: "200px",
    boxShadow: "-1px 3px 13px -1px rgba(0,0,0,0.75)",
    borderRadius: "50%",
    marginTop: "38px"
  },
  Phone: {
    color: "#01548A",
    fontSize: "25px",
    fontWeight: "bold",

   
  } as const,
  PhoneDesc: {
    color: "#989898",
    marginTop: "30px",

  },
  InputField: {
    marginTop: "10px"
  },
  


  LoginBtn: {
    width: "100%",
    textTransform: "none",
    backgroundColor: "#01548A",
    color: "#fff",
    fontWeight: "bold",
    borderRadius: "6px",
    marginTop: "5%",
    marginBottom: "5%"
  } as const,
  otppin: {
    color: "grey",
    fontSize: 14,
    width: "35px",
    height: "35px",
    margin: "5px 5px",
    borderRadius: "5px",
    border: "1px solid #b3b3b3",
    textAlign: "center",
    padding: "5px 0px 5px 0px",
    justifyContent: "center",
 
    backgroundColor: "#fafafa",

  } as const,
  fieldError: {
    fontSize: "16px",
    textAlign: "start",

    color: "red",
   
  } as const,
  mainGridContainer: {
    height: "inherit"
  },


};
// Customizable Area End
/*istanbul ignore next */
export default class PhoneVerificationNew extends PhoneVerificationNewController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    return (
      <Box height={"inherit"} id="Main" className="PhoneVerifyMainBox">
        <Grid style={webStyling.mainGridContainer} container>
          <Grid item xs={12} sm={5}>
            <img
              src={SideImage}
              alt="side-img-signUp"
              style={{ width: "100%", height: "100%" }}
            />
          </Grid>

          <Grid item xs={12} sm={7} style={{ backgroundColor: "#fff" }}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Box style={webStyling.logoCard}>
                  <img
                    src={Logo}
                    alt="logo-img"
                    style={{ padding: 0, width: "200px", height: "200px" }}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Grid item style={{ marginTop: "20px" }}>
                  {this.state.show === false ? (
                    <Box>
                      <Box style={{ textAlign: "center" }}>
                        <Typography
                          variant="h4"
                          style={{
                            color: "#01548A",
                            fontWeight: "bold",
                            fontSize: "25px",
                            marginTop:"20px"
                            
                          }}
                        >
                          Phone Verification
                        </Typography>
                        <Typography
                          variant="body2"
                          style={webStyling.PhoneDesc}
                          
                        >
                          Enter Your Phone Number to receive a <br /> One Time
                          Password
                        </Typography>
                      </Box>
                      <Box style={{ marginTop: "10px" }}>
                        <form
                          onSubmit={this.handleSubmit}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px"
                          }}
                        >
                          <FormControl style={webStyling.InputField}>
                            <TextField
                              id="PhoneNumber"
                              data-test-id="txtname"
                              variant="outlined"
                              size="small"
                              placeholder="Phone Number"
                              type="number"
                              onChange={e => this.handleChange(e)}
                              value={this.state.full_phone_number}
                              name="full_phone_number"
                              error={this.state.errorMsg !== ""}
                              helperText={this.state.errorMsg}
                              fullWidth
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start" style={{marginLeft:"-78px"}}>
                                    <PhoneIcon
                                      style={{ width: 20, height: 20 }}
                                    />
                                  </InputAdornment>
                                )
                              }}
                            />
                          </FormControl>

                          <Typography
                            style={{
                              textAlign: "start",
                              color: "red",
                              fontSize: "16px"
                            }}
                          >
                            {this.state.FieldError}
                          </Typography>

                          <Box
                            style={{ marginTop: "40px", textAlign: "center" }}
                          >
                            <Button
                              type="submit"
                              variant="contained"
                              style={{
                                width: "100%",
                                textTransform: "none",
                                backgroundColor: "#01548A",
                                color: "#fff",
                                fontWeight: "bold",
                                borderRadius: "6px",
                                marginTop: "5%",
                                marginBottom: "5%"
                              }}
                              data-test-id="btnSignUp"
                            >
                              Send OTP
                            </Button>
                          </Box>
                        </form>
                      </Box>
                 
                    </Box>
                  ) : (
                    <Box>
                      <Box style={{ textAlign: "center" }}>
                        <Typography
                          variant="h4"
                          style={{
                            color: "#01548A",
                            fontSize: "25px",
                            fontWeight: "bold"
                          }}
                        >
                          Verification Code
                        </Typography>
                        <Typography
                          variant="body2"
                          style={webStyling.PhoneDesc}
                        >
                          OTP has been send to your Phone Number,
                          <br />
                          Please Verify.
                        </Typography>
                      </Box>
                      <Box style={{ marginTop: "10px" }}>
                        <form
                          onSubmit={this.verifyOtp}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px"
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "20px",
                              marginTop: "20px"
                            }}
                          >
                            {this.state.otp.map((value: any, index: any) => (
                              <input
                                id="myInput"
                                data-test-id="txtOTPpin"
                                name={
                                  index == 0 ? "otp_code" : `otp${index + 1}`
                                }
                                key={index}
                                type="number"
                                onKeyDown={e => this.handleKeydown(e, index)}
                                autoFocus={index === 0}
                                onInput={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 1);
                                }}
                                value={value}
                                onChange={e => this.handleOTPChange(e, index)}
                                maxLength={1}
                                pattern="\d*"
                                inputMode="numeric"
                                style={{
                                  color: "grey",
                                  fontSize: 14,
                                  width: "35px",
                                  height: "35px",
                                  margin: "5px 5px",
                                  borderRadius: "5px",
                                  border: "1px solid #b3b3b3",
                                  textAlign: "center",
                                  justifyContent: "center",
                                  backgroundColor: "#fafafa",
                                  padding: "5px 0px 5px 0px"
                                }}
                              />
                            ))}
                          </Box>

                          {this.state.otpError !== "" && (
                            <p
                              style={{
                                color: "red",
                                fontSize: "14px",
                                textAlign: "center"
                              }}
                            >
                              {this.state.otpError}
                            </p>
                          )}
                          <Typography
                            style={{
                              textAlign: "center",
                              color: "red",
                              fontSize: "16px"
                            }}
                          >
                            {this.state.FieldError}
                          </Typography>
                          <Box
                            style={{
                              textAlign: "center"
                            }}
                          >
                            {this.state.isDisabled ? (
                              <Typography
                                style={{
                                  fontSize: "12px",
                                  color: "red"
                                }}
                                variant="body2"
                              >
                                00:{this.state.otpTimer}
                              </Typography>
                            ) : (
                              <Typography
                                style={{
                                  fontSize: "12px"
                                }}
                                variant="body2"
                              >
                                {configJSON.resendCode} &nbsp;
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginTop: "30px",
                                    color: "#01548a",
                                    cursor: "pointer"
                                  }}
                                  onClick={this.handleSubmit}
                                >
                                  {configJSON.resendLabel}
                                </span>
                              </Typography>
                            )}
                          </Box>

                          <Box
                            style={{ marginTop: "40px", textAlign: "center" }}
                          >
                            <Button
                              data-test-id="btnVerifyOTP"
                              type="submit"
                              variant="contained"
                              style={{
                                width: "100%",
                                textTransform: "none",
                                backgroundColor: "#01548A",
                                color: "#fff",
                                fontWeight: "bold",
                                borderRadius: "6px",
                                marginTop: "5%",
                                marginBottom: "5%"
                              }}
                            >
                              Verify
                            </Button>
                          </Box>
                        </form>
                      </Box>


                  
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End
