Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.getTokenSwitchAPIEndpoint= "bx_block_chat9/switch_accounts";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LinkedAccounts";
exports.labelBodyText = "LinkedAccounts Body";

exports.btnExampleTitle = "CLICK ME";
exports.get_community = "get_community";
exports.getApiMethod = "GET";
exports.postApiMethode = "POST";
exports.updateEnd = "communities/:";
exports.createCommunityEnd = "communities";
exports.putApiMethod = "PUT";
exports.switchAccountEnd="bx_block_chat9/switch_accounts";
// Customizable Area End