// Customizable Area Start
import React from "react";
import { Button, Box, FormControl, InputAdornment, TextField, FormHelperText } from '@material-ui/core';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import VideoTypePostController, { Props } from "./VideoTypePostStepTwoController.web";
import { vimeoIcon, blueLink, exportIcon, youtubeIcon, CloseIcon } from "./assets"
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

// Customizable Area Start
const Poststyles = (theme: Theme) =>
    createStyles({
        menuIcon: {
            width: "24px",
            height: "24px",
            objectFit: "contain",
        },
        heightResponsive: {
            maxHeight: "85%",
            overflowY: "auto",
        },
        label: {
            marginBottom: "8px",
            color: "#005487",
            fontSize: "14px",
            fontFamily: "Verdana",
            fontWeight: "bold",
        },
        inputField: {
            "& .MuiOutlinedInput-input": {
                paddingBlock: "20px",
            }
        },
        linkColor: {
            color: "#005487",
            wordWrap: "break-word",
            display: "flex",
            alignItems: "center",
            width: "maxContent"
        },
        exportIcon: {
            width: "16px",
            height: "16px",
            marginLeft: "3px"
        },
        Publishbtn: {
            height: "56px",
            fontSize: "17px",
            fontFamily: "Verdana",
            textTransform: "none",
            fontWeight: "bold",
            backgroundColor: "#005487",
            color: "#fff",
            width: "100%",
            "&:hover": {
                backgroundColor: "#005487",
            }
        },
        informationScroll: {
            overflowY: "auto",
            maxHeight: "70vh"
        },
        modal: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        error: {
            color: "red",
        }

    })
// Customizable Area End


export class VideoTypePost extends VideoTypePostController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={this.props.open}
                onClose={this.props.handleClose}
                closeAfterTransition
                className={this.props.classes.modal}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 200,
                }}
            >
                <Fade in={this.props.open}>
                        <Box sx={{ width: { xs: "80%", md: "70%", lg: "50%" }, maxWidth: "604px" }} bgcolor={"#fff"} className={this.props.classes.heightResponsive} borderRadius="12px" >
                        {this.state.isLoading && <Loader loading={this.state.isLoading} />}
                            <Box sx={{ border: "1px solid black", margin: "20px", padding: "20px", borderRadius: "12px", gridGap: { xs: "20px", md: "22px" } }} display={"flex"} flexDirection={"column"} >
                                {this.state.information ?
                                    <>
                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                            <Box component={"h4"} display={"flex"} alignItems={"center"} m={0} color={"#005487"} sx={{ fontFamily: "Verdana", fontSize: "17px", fontWeight: 'bold' }}>{this.props.type==="Vimeo"? "Vimeo":"Youtube"} Embed</Box>
                                            <img src={CloseIcon} alt="close" data-test-id="close-guidline" onClick={this.closeInformation} />
                                        </Box>
                                        <Box className={this.props.classes.informationScroll}>
                                            <Box component={"h6"} display={"flex"} alignItems={"center"} m={0} mt={"20px"} mb="8px" sx={{ fontFamily: "Verdana", fontSize: "16px", fontWeight: 'bold' }}>{this.props.type==="Vimeo"? "Vimeo":"Youtube"} Embed</Box>
                                            <Box component={"p"} m={0} mb={"5px"} sx={{ fontFamily: "Verdana", fontSize: "14px" }}>
                                                Embed accessing this app you are agreeing to be bound by these app Terms and Conditions of use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable.
                                            </Box>
                                            <Box component={"p"} m={0} mb={"5px"} sx={{ fontFamily: "Verdana", fontSize: "14px" }}>
                                                If you do not agree with any of these terms, you are prohibited from using or accessing this application. This materials contained in this application are protected by applicable copyright and trade mark law.                                            </Box>
                                            <Box component={"p"} m={0} mb={"5px"} sx={{ fontFamily: "Verdana", fontSize: "14px" }}>
                                                By accessing this app you are agreeing to be bound by these app Terms and Conditions of use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this application. This materials contained in this application are protected by applicable copyright and trade mark law.                                            </Box>
                                            <Box component={"p"} m={0} mb={"5px"} sx={{ fontFamily: "Verdana", fontSize: "14px" }}>
                                                By accessing this app you are agreeing to be bound by these app Terms and Conditions of use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this application.                                            </Box>
                                            <Box component={"p"} m={0} mb={"5px"} sx={{ fontFamily: "Verdana", fontSize: "14px" }}>
                                                This materials contained in this application are protected by applicable copyright and trade mark law.By accessing this app you are agreeing to be bound by these app Terms and Conditions of use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws.                                            </Box>
                                            <Box component={"p"} m={0} mb={"5px"} sx={{ fontFamily: "Verdana", fontSize: "14px" }}>
                                                Embed accessing this app you are agreeing to be bound by these app Terms and Conditions of use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable.
                                            </Box>
                                            <Box component={"p"} m={0} mb={"5px"} sx={{ fontFamily: "Verdana", fontSize: "14px" }}>
                                                Embed accessing this app you are agreeing to be bound by these app Terms and Conditions of use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable.
                                            </Box>
                                            <Box component={"p"} m={0} mb={"5px"} sx={{ fontFamily: "Verdana", fontSize: "14px" }}>
                                                Embed accessing this app you are agreeing to be bound by these app Terms and Conditions of use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable.
                                            </Box>
                                        </Box>
                                    </>
                                    :
                                    <>
                                        <Box component={"h4"} display={"flex"} alignItems={"center"} m={0} sx={{ gridGap: "12px", fontFamily: "Verdana", fontSize: "17px", fontWeight: 'bold' }}>
                                            <img src={this.props.type === "Vimeo"? vimeoIcon : youtubeIcon} className={this.props.classes.menuIcon} alt="video" />
                                            {this.props.type === "Vimeo" ? "Vimeo Embed" : "YouTube Embed"}
                                        </Box>
                                        <FormControl fullWidth>
                                            <Box component={"label"} className={this.props.classes.label}>Embed code</Box>
                                            <TextField
                                                placeholder="Add or Paste embed code"
                                                name="url"
                                                className={this.props.classes.inputField}
                                                type="url"
                                                value={this.state.url}
                                                data-test-id="url-id"
                                                onChange={this.handleChangeUrl}
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(this.state.error)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start" >
                                                            <img src={blueLink} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <FormHelperText>
                                                Add or paste embed code to the content you want to display on your site.
                                            </FormHelperText>
                                            {this.state.error && (
                                                <FormHelperText className={this.props.classes.error}>
                                                    {this.state.error}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                        <Box fontFamily={"Verdana"} fontSize={"16px"}><a href="javascript:void(0);" className={this.props.classes.linkColor} onClick={this.openInformation} data-test-id="embeded-link">Learn more about embeds<img src={exportIcon} alt="exporticon" className={this.props.classes.exportIcon} /></a></Box>

                                        <Box width="100%" display={"flex"} justifyContent={"center"} marginTop={"150px"}>
                                            <Button variant="contained" type="submit" data-test-id="publish-btn" onClick={this.handlePublish} className={this.props.classes.Publishbtn}>Publish</Button>
                                        </Box>
                                    </>}
                            </Box>
                        </Box>
                </Fade>
            </Modal >
            // Customizable Area End
        )
    }
}

// Customizable Area Start

export default withStyles(Poststyles, { withTheme: true })(VideoTypePost);
// Customizable Area End