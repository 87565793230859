import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { Typography, Box, Grid, Card, Button } from "@material-ui/core";
// Customizable Area Start
import Divider from "@material-ui/core/Divider";
import FriendsRequestListController, { configJSON, Props } from "./FriendsRequestListController.web";
import { CloseIcon } from "./assets";
import Loader from "../../../components/src/Loader.web";


const FriendsRequestListStyles = (theme: Theme) =>
    createStyles({
        root: {
            maxWidth: "100%",
            borderRadius: "12px",
        },
        textColor: {
            color: "#005487",
        },
        cradBody: {
            padding: "24px",
        },
        cardUserImage: {
            height: "50px",
            width: "50px",
            borderRadius: "50%",
            cursor:"pointer"
        },
        cardText: {
            fontFamily: "Verdana",
            fontSize: " 17px",
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "1.29",
            letterSpacing: "-0.41px",
            color: "#000",
        },
        showallbtn: {
            color: "#005487",
            textAlign: "center",
            fontFamily: "Verdana",
            fontSize: " 17px",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "1.29",
            letterSpacing: "-0.41px",
            marginTop: "1.7rem",
        },
        btn: {
            padding: "2.5px 25px",
            border: "solid 1px #005487",
            fontFamily: "Verdana",
            color: "#005487",
            fontSize: "14px !important",
            borderRadius: "0px",
            textTransform: "initial",
            "@media(max-width:535px)":{
                marginTop: "3px",
                width:"100px"
            }
        },
        btn1: {
            backgroundColor: "#005487",
            marginRight: "12px",
            color: "white",
            "&:hover": {
                color: "white",
                backgroundColor: "#005487",
            },
        },
        text: {
            fontDamily: "Verdana",
            fontSize: "12px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "1.83",
            letterSpacing: "-0.41px",
            color: "#808080",
        },
        mutualImage: {
            height: "16px",
            width: "16px",
            borderRadius: "50%",
        },
        mutualImage1: {
            position: "absolute",
        },
        mutualImage2: {
            position: "absolute",
            top: "12%",
            left: "29%",
        },
        mt36: {
            marginTop: "34px",
        },
        pointer: {
            cursor: "pointer",
        }
    })
// Customizable Area End

// Customizable Area Start
export class FriendsRequestList extends FriendsRequestListController {
    constructor(props: Props) {
        super(props);
    }

    renderShowAll = () => {
        const { classes } = this.props;

        return (
            <>
                {(this.state.shouldShowAllBtn && this.state.friendsRequestCount > 5) && <Box display={"flex"} justifyContent={"center"} mt={"23px"}>
                    <Box component="p" data-test-id="show-all-button" onClick={this.hideShowAllButton} sx={{ fontSize: "17px", fontFamily: "Verdana" }} m={0} className={classes.pointer} color={"#005487"}>{configJSON.show_all}</Box>
                </Box>}
            </>
        )
    }

    renderLopp = () => {
        const { classes } = this.props;
        const isRecommanded = this.type === "Recommandation";
        return(
            <>{
            this.state.friendsRequestList?.filter((_, index: number) => !this.state.shouldShowAllBtn ? true : index < 5).map((friendsList, listIndex: number) => (
                <Box py={'16px'} key={`friendslist-${friendsList.id}`} display={"flex"} data-test-id="list-item">
                        <img
                            onClick={()=> this.handleFriendClick(friendsList.attributes.sender_id)}
                            src={String(friendsList.attributes.profile_picture)}
                            alt="..."
                            data-test-id="profile-img"
                            className={classes.cardUserImage}
                        />
                        <Box
                            ml="12px"
                            display={"flex"}
                            justifyContent={"space-between"}
                            width={"100%"}
                        >
                            <Box>
                                <Box component="p" fontSize="17px" sx={{fontWeight:"bold",fontFamily:"Verdana"}} m={0}>
                                    {friendsList.attributes.full_name}
                                </Box>
                                <Box>
                                    <Box display={"flex"} position="relative">
                                        <Box
                                            display={"flex"}
                                            justifyContent={"center"}
                                            alignItems={friendsList.attributes.mutual_friends_count[0].mutual_count === 1 ?"center" : ""}
                                            
                                        >
                                            {
                                                friendsList.attributes.mutual_friends_count[0].mutual_count === 0 &&
                                                <Box ml="12px">
                                                <Typography className={classes.text}>
                                                   --
                                                </Typography>
                                            </Box>
                                            }
                                            {friendsList.attributes.mutual_friends_count[0].mutual_count === 1 && (
                                                <img
                                                    className={classes.mutualImage}
                                                    src={friendsList.attributes.mutual_friends_count[0].files[0].photo ?? ""}
                                                    alt="..."
                                                />
                                            )}
                                            {friendsList.attributes?.mutual_friends_count[0].mutual_count >= 2 && (
                                                <Box
                                                    paddingRight={"10px"}
                                                    width={"2rem"}
                                                    position={"relative"}
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                >
                                                    <img
                                                        className={`${classes.mutualImage} ${classes.mutualImage1}`}
                                                        src={friendsList.attributes?.mutual_friends_count[0].files[0]?.photo ?? ""}
                                                        alt="..."
                                                    />
                                                    <img
                                                        className={`${classes.mutualImage} ${classes.mutualImage2}`}
                                                        src={friendsList.attributes?.mutual_friends_count[0].files[1]?.photo ?? ""}
                                                        alt="..."
                                                    />
                                                </Box>
                                            )}
                                        </Box>
                                        {friendsList.attributes.mutual_friends_count[0].mutual_count > 0  && <Box ml="12px">
                                            <Typography className={classes.text}>
                                              {friendsList.attributes.mutual_friends_count[0].mutual_count} Mutal Friends
                                            </Typography>
                                        </Box>}
                                    </Box>
                                </Box>
                                <Box marginTop="7px">
                                    <Button data-test-id={"accept-button-0"} onClick={()=> isRecommanded ? this.sendFriendRequest(friendsList.id) : this.updateFriendRequest(true,friendsList.id)} className={`${classes.btn} ${classes.btn1}`}>
                                       { isRecommanded ? "Add friend" : "Accept"}
                                    </Button>
                                    <Button data-test-id={"delete-button-0"} onClick={()=> isRecommanded ? this.removeRecommendation(friendsList.id) : this.updateFriendRequest(false,friendsList.id)}  className={`${classes.btn}`}>{ isRecommanded ? "Remove" : "Delete"}</Button>
                                </Box>
                            </Box>
                            <Box component={"p"} m={0} sx={{fontFamily:"Verdana",fontSize:"12px"}} color={"#808080"}>
                                {friendsList.attributes.requested_at}
                            </Box>
                        </Box>
                    </Box>
                
            ))
        }</>
        )
    }
    
    render() {
        const { classes } = this.props;
        const isRecommanded = this.type === "Recommandation";

        return (
            <Grid item xs={12}>
                <Loader loading={this.state.isLoading} />
                <Card className={`${classes.root}`}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} padding={"24px"}>
                        <Box component={"p"} m={0} color={"#005487"} sx={{fontFamily:"Verdana", fontSize:"20px", fontWeight:"bold"}}>{configJSON.request_management}</Box>
                        <img data-test-id={"request-management-list-close"} className={classes.pointer}  onClick={this.handleCloseClick} src={CloseIcon} />
                    </Box>
                    <Divider />
                    <Box className={classes.cradBody}>
                        <Box padding={"8px"} bgcolor={"#f4f4f4"}>
                            <Box padding={"24px"} bgcolor={"white"}>
                                <Box
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    mb={"20px"}
                                >
                                    <Box component={"p"} m={0} color={"#005487"} sx={{fontFamily:"Verdana", fontSize:"20px", fontWeight:"bold"}}>{isRecommanded? configJSON.recommonded_for_you :configJSON.friend_request}</Box>
                                </Box>
                                <Box>
                                    {
                                        !this.state.isLoading && this.state.friendsRequestList.length === 0 ?
                                            <p data-test-id={"no-request-found-text"}>No Friends Request Found</p> :
                                           this.renderLopp()
                                    }

                                </Box>
                                {this.renderShowAll()}
                            </Box>
                        </Box>
                    </Box>
                </Card>
            </Grid>
        );
    }
}

export default withStyles(FriendsRequestListStyles, { withTheme: true })(FriendsRequestList);
// Customizable Area End