import React from "react";

import {
    // Customizable Area Start
    Card,
    Grid,
    MenuItem,
    Box,
    Typography,
    IconButton,

    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import { arrowBack, dropDownIcon } from "./assets";
const Root = styled(Grid)({
    borderRadius: "12px",
    position: "relative",
})



const CustomCard = styled((Box))({
    borderRadius: "12px",
    overflow: "unset",
    marginBottom: "12px",
    backgroundColor: "#fff",
});

const Header = styled((Box))({
    padding: "25px 0px 23px 24px",
    borderBottom: "1px solid #808080",
    display: "flex",
    alignItems: "center",
    "& .pointer":{
        cursor: "pointer",
    }
});

const Heading = styled((Typography))({
    marginRight: "9px",
    color: "#005487",
    lineHeight: 1.1,
    letterSpacing: "-0.41px",
    fontSize: "20px",
    fontWeight: "bold",
    fontFamily: "Verdana",
    marginLeft: "8px",
})


const MainBox = styled((Box))({
    padding: "24px"
})

const TestCardSubheading = styled(Typography)({
    fontSize: "17px",
    marginBottom: "18px",
    fontFamily: "Verdana",
    fontWeight: "bold",
})


const TestListItem = styled('li')({
    borderRadius: "12px",
    height: '88px',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
    padding: "0px 25px",
    margin: "0px 4px",
    cursor: "pointer",
    "&:hover":{
        border: "1px solid #005487",
    }
})

const TestInfor = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .test-icon": {
        height: "35px",
        width: "35px"
    }
})

const ScrollableBox = styled(Box)({
    overflowY: "auto",
    '&::-webkit-scrollbar': {
        width: '2px',
        height: '2px',
        background: 'gray',
    }
})

const ListContainer = styled('ul')({
    padding: 0,
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    margin: "0px 0px 4px"
})

import AssessmentTestViewListingController, {
    Props,
    configJSON,
} from "./AssessmentTestViewListingController";
import { checkListIcon, rightCircleArrowIcon } from "./assets";
import Loader from "../../../components/src/Loader.web";

const DropDownList = styled(Box)({
    position: "absolute",
    top: "50px",
    marginLeft: "auto",
    borderRadius: "12px",
    boxShadow: "2px 4px 16px 4px rgba(0, 0, 0, 0.25)",
    zIndex: 1,
    padding: "20px",
    width: "480px",
    boxSizing: "border-box",
    maxHeight: "640px",
    backgroundColor: "#fff",
    overflowY: "auto",
    "@media(max-width:600px)": {
        width: "320px",
    },
    "@supports (-webkit-appearance:none)": {
      maxWidth: "-webkit-fill-available", // Apply for WebKit-based browsers
    },
    "@supports (-moz-appearance:none)": {
        maxWidth: "-moz-available", // Apply for Firefox
    },
});
const DropdownListItem = styled(MenuItem)({
    fontFamily: "Verdana",
    padding: "20px 0 21px 19.5px",
    color: "#808080",
    fontSize: "16px",
    '&.Mui-selected': {
        backgroundColor: '#005586',
        color: "#fff",
        "&:hover": {
            backgroundColor: "#005586",
        },
    },
    "&:hover": {
        backgroundColor: "#005586",
        color: "#fff",
    },

})
const DropDownIcon = styled((IconButton))({
    paddingTop:0,
    paddingBottom:0,
    position: 'relative',
    "& .dropdown-img": {
        height: "22px",
        width: "22px",
      }
})
// Customizable Area End

export class AssessmentTestViewListing extends AssessmentTestViewListingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Root xs={12} >
                <Loader loading={this.state.isLoading} />

                <CustomCard >
                    <Header >
                            <img className="pointer" src={arrowBack} alt="back" data-test-id="back-icon" onClick={this.handleBackNavigation}/>
                        <Heading >
                            {!this.isCommunity && configJSON.labelViewTest}
                            {this.isCommunity && 'My Community'}
                        </Heading>

                        {this.isCommunity && <DropDownIcon
                            aria-label="Expand"
                            onClick={() => this.setState({ showDropDown: !this.state.showDropDown })}
                        >
                            <img src={dropDownIcon} alt="DropDownIcon" className="dropdown-img"/>
                        </DropDownIcon>}
                            {this.state.showDropDown && (<DropDownList sx={{left:{xs: "unset",sm:"200px"},right: {xs:0, sm: "unset"}}} >
                                {this.state.dropDownList.map((e, i: number) => {
                                    return (
                                        <DropdownListItem key={`key-${e.id}-${i + 1}`} selected={this.state.selectedMenu === e.id} onClick={() => this.handleDropdownChange(e)}>
                                            <Typography variant="inherit">{e.title}</Typography>
                                        </DropdownListItem>
                                    );
                                })}
                            </DropDownList>)}
                    </Header>
                        <MainBox >
                                {!this.isCommunity &&
                                    <Box mb="34px" component={"section"}>
                                        <TestCardSubheading >{configJSON.labelPendingTest}</TestCardSubheading>
                                        <ScrollableBox sx={{maxHeight : "420px" }}>
                                            <ListContainer>
                                            {this.state.testList.pending_quizes.length > 0 ?
                                                this.state.testList.pending_quizes.map((e, i: number) => {
                                                    return (
                                                        <TestListItem onClick={() => this.handlePendingAssessmentClick(e.quiz_id)} key={`key-${e.quiz_id}-${i+0}`}>
                                                            <TestInfor>
                                                                <img className="test-icon" src={checkListIcon} alt="test-icon" />
                                                                <Box component={"p"} fontFamily={"Verdana"} fontWeight={"bold"} fontSize={"20px"} m={0} ml={"24px"}>{e.title}</Box>
                                                            </TestInfor>
                                                            <img src={rightCircleArrowIcon} alt="go" />
                                                        </TestListItem>
                                                    )
                                                })
                                                :
                                            <Box component="p" fontFamily={"Verdana"} fontSize={"17px"}>
                                                {configJSON.pendingQuiz_label}</Box>}
                                            </ListContainer>
                                        </ScrollableBox>
                                    </Box>
                                }
                                <Box component={"section"}>
                                    {!this.isCommunity &&
                                        <TestCardSubheading >{configJSON.labelCompletedTest}</TestCardSubheading>
                                    }
                                    {this.isCommunity &&
                                            <Box component={"h4"} m={0} mb={"18px"} fontFamily={"Verdana"} fontSize={"16px"} fontWeight={"bold"}>Test Uploaded</Box>
                                    }
                                <ScrollableBox sx={{maxHeight : this.isCommunity ?  "800px" : "420px" }}>
                                    <ListContainer>
                                        {this.state.testList.completed_quizes.length > 0 ?
                                            this.state.testList.completed_quizes.map((e, i: number) => {
                                                return (
                                                    <TestListItem data-test-id="test-list-id" onClick={() => this.handleAssessmentClick(e.quiz_id)} key={`key-${e.quiz_id}-${i+0}`}>
                                                        <TestInfor>
                                                            <img className="test-icon" src={checkListIcon} alt="test-icon" />
                                                            <Box component={"p"} fontFamily={"Verdana"} fontWeight={"bold"} fontSize={"20px"} m={0} ml={"24px"}>{e.title}</Box>
                                                        </TestInfor>
                                                        <img src={rightCircleArrowIcon} alt="go"/>
                                                    </TestListItem>
                                                )
                                            })
                                            :
                                            <Box component="p" fontFamily={"Verdana"} fontSize={"17px"}>{configJSON.noCompletedQuizesLabel}</Box>}
                                    </ListContainer>
                                        </ScrollableBox>
                                </Box>
                        </MainBox>
                </CustomCard>
            </Root>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default AssessmentTestViewListing
// Customizable Area End
