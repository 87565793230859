// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  FormControlLabel,
  InputAdornment,
  Grid,
  FormControl,
  TextField,
  Checkbox,
  Modal,
} from "@material-ui/core";
import SignInNewController, { Props } from "./SignUpNewController.web";
import { SideImage, Logo, Mail, Lock, Eye, Hideeye } from "./assets";
import { styled } from "@material-ui/core/styles";
import TermsAndConditions from "../../TermsAndConditions/src/TermsAndConditions.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

 export default class SignInNew extends SignInNewController {
      constructor(props: Props) {
         super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
  render() {

    return (
      <>
        <Wrapper container>
          {this.state.isLoading && <Loader loading={this.state.isLoading} />}
          <Grid item xs={false} md={5} component={Box} sx={{ height: "100vh", display: { xs: 'none', md: 'block' } }}>
            <img src={SideImage} alt="side-panel" width="100%" height="100%" />
          </Grid>

          <Grid item xs={12} md={7} className="scrollWrapper">
            <Box component={'section'} className="rightSection" sx={{ width: "85%", maxWidth: "500px", paddingY: '20px' }}>
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <img src={Logo} alt="logo-img" className="LoginLogoImg" />
                <Box component={'h1'} fontFamily={"Veradana"} fontSize={"50px"} color={"#005487"} fontWeight={'bold'} m={0} mt={{ xs: "30px", md: '40px' }}>Signup</Box>
              </Box>
              <form data-test-id="publish-btn" className="form" onSubmit={this.handleSubmit}>
              <Box display={"flex"} flexDirection={'column'} alignItems={'center'} width={'100%'} mt={{ xs: '60px', md: '80px' }} mb={'16px'} gridGap={'24px'}>
                <FormControl fullWidth>
                  <TextField
                    data-test-id="txtInputEmail"
                    variant="outlined"
                    size="medium"
                    placeholder="Email ID"
                    className="inputfield"
                    name="email"
                    type="email"
                    autoFocus={true}
                    error={Boolean(this.state.errors.email)}
                    value={this.state.data.email}
                    onChange={this.handleFieldChange}
                    fullWidth
                    helperText={this.state.errors.email}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" >
                          <img src={Mail} alt="email" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    data-test-id="txtInputPassword"
                    variant="outlined"
                    size="medium"
                    placeholder="Password"
                    name="password"
                    type={this.state.icon.first ? "text" : "password"}
                    error={Boolean(this.state.errors.password)}
                    value={this.state.data.password}
                    onChange={this.handleFieldChange}
                    fullWidth
                    helperText={this.state.errors.password}
                    className="inputfield"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" >
                          <img src={Lock} alt="Lock" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start" >
                          <img className="pointer" data-test-id="eye-icon" src={this.state.icon.first ? Eye : Hideeye} alt="eye" onClick={this.eyeIconPass} />
                        </InputAdornment>
                      )
                    }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    data-test-id="password_confirmation"
                    variant="outlined"
                    size="medium"
                    placeholder="Confirm Password"
                    name="password_confirmation"
                    type={this.state.icon.second ? "text" : "password"}
                    error={Boolean(this.state.errors.password_confirmation)}
                    value={this.state.data.password_confirmation}
                    onChange={this.handleFieldChange}
                    fullWidth
                    helperText={this.state.errors.password_confirmation}
                    className="inputfield"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" >
                          <img src={Lock} alt="Lock" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start" >
                          <img className="pointer" data-test-id="eye-icon" src={this.state.icon.second ? Eye : Hideeye} alt="eye" onClick={this.eyeChangeConfPass} />
                        </InputAdornment>
                      )
                    }}
                  />
                </FormControl>
              </Box>
              <Box width="100%">
                <Box display={'flex'} alignItems={'center'} className="checkbox" width={"100%"}>
                  <FormControlLabel
                        data-test-id="btnCheck"
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.agree}
                        onChange={this.setAgree}
                        name="agree"
                      />
                    }
                    label="I accept to the"
                  />
                  <span className="termsText" onClick={this.handleOpen}>Terms and Conditions.</span>
                </Box>
                {this.state.errors.agreeError && <span className="error">{this.state.errors.agreeError}</span>}
              </Box>
              <Box display="flex" width={'100%'} mt={{ xs: "60px", md: '80px' }} mb={{ xs: '25px', md: '31px' }}>
                <Button variant="contained" type="submit" className={"publishbtnExpand"}>Register</Button>
              </Box>
              </form>
              <Box component={'p'} fontFamily={"Veradana"} fontSize={"17px"} color={"#808080"} fontWeight={'bold'} m={0}>Already have an account? <span className="redirect" data-test-id="redirect-id" onClick={this.handleLogin}>LogIn.</span></Box>
            </Box>
          </Grid>
          <Modal
            open={this.state.modalVisible}
            onClose={this.handleAcceptBtn}
          >
            <TermsAndConditions
              navigation={this.props.navigation}
              id=""
              handleAccept={this.handleAcceptBtn}
              setAgree={this.setAgree}
              agree={this.state.agree}
            />
          </Modal>
        </Wrapper>
      </>
    );
  }
    // Customizable Area End
}
// Customizable Area Start
const Wrapper = styled(Grid)({
  background: '#fff',
  "& .scrollWrapper": {
    display:'grid',
    placeItems: 'center',
    height: '100vh',
    overflowY: 'auto'
  },
  "& .rightSection": {
    flexDirection: "column",
    alignItems: "center",
    display: 'flex',
    justifyContent: "center"
  },
  "& .pointer": {
    cursor: 'pointer'
  },
  "& .LoginLogoImg": {
    width: "250px",
    boxShadow: "0 4px 8px 0 rgba(32, 32, 32, 0.2)",
    height: "250px",
    borderRadius: '50%',
  },
  "& .checkbox": {
    "& .MuiFormControlLabel-label": {
      fontsize: '15px',
      color: '#808080',
    },
    "& .MuiFormControlLabel-root":{
      marginRight: "6px"
    },
    "& .termsText":{
      cursor: 'pointer',
      fontFamily: "Verdana",
      color: '#005487',
      textDecoration: 'underline'
    }
  },
  "& .inputfield": {
    "& .MuiOutlinedInput-input": {
      paddingBlock: "20px"
    }
  },
  "& .form":{
    margin: 0,
    width: '100%'
  },
  "& .publishbtnExpand": {
    height: "56px",
    fontWeight: "bold",
    fontSize: "16px",
    color: "#fff",
    fontFamily: "Verdana",
    width: "100%",
    backgroundColor: "#005487",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#005487",
    }
  },
  "& .redirect": {
    textDecoration: 'underline',
    fontWeight: 'bold',
    cursor: 'pointer',
    textUnderlineOffset: '6px',
    color: '#005487',
  },
  "& .error":{
    marginInline: '14px',
    margintop: "3px",
    fontFamily: "Verdana",
    color: 'red',
    fontSize: '12px'
  }
});
// Customizable Area End