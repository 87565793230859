// Customizable Area Start
import React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Box,
  Button,
  Typography,
  Card,
  CardActions,
  CardMedia
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { SideImg, Logo } from "./assets";
import WelcomeNewController, { Props } from "./WelcomeNewController.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 500,
      fontFamily: "Verdana"
    },
    subtitle1: {
      margin: "20px 0px",
      fontFamily: "Verdana"
    }
  }
});
// Customizable Area End

export class WelcomeNew extends WelcomeNewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Box style={webStyle.WelcomePage} className="LoginMainBox">
          <Container>
            <Box sx={webStyle.CardsBox}>
              <Card
                style={{
                  width: "700px",
                  height: "800px",
                  maxHeight: "100%",
                  boxShadow: "-1px 2px 8px -1px rgba(0,0,0,0.75)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <div>
                  <Typography
                    variant="h5"
                    component="h2"
                    style={webStyle.Welcome}
                  >
                    Welcome to
                  </Typography>
                  <CardMedia
                    component="img"
                    alt="Contemplative Reptile"
                    style={webStyle.logo}
                    image={Logo}
                    title="Contemplative Reptile"
                  />
                  <CardActions
                    style={{
                      display: "flex",
                      gap: "20px",
                      flexDirection: "column",
                      marginTop: "30px"
                    }}
                  >
                    <Link to="/Login" style={{ textDecoration: "none" }}>
                      <Button
                        size="small"
                        variant="outlined"
                        style={webStyle.buttonGroup}
                        data-test-id="btnSignUp"
                      >
                        Login
                      </Button>
                    </Link>
                    <Link to="/SignUp" style={{ textDecoration: "none" }}>
                      <Button
                        size="small"
                        variant="outlined"
                        style={webStyle.buttonGroupSignUp}
                        data-test-id="btnSignUp"
                      >
                        Signup
                      </Button>
                    </Link>
                  </CardActions>
                </div>
              </Card>
            </Box>
          </Container>
        </Box>
      </ThemeProvider>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const webStyle = {
  WelcomePage: {
    backgroundImage: `url(${SideImg})`,
    backgroundSize: "100% 100%",
    height: "100%"
  } as const,
  Welcome: {
    fontSize: "25px",
    color: "#01548A",
    fontWeight: "bold",
    marginBottom: "20px",
    fontFamily: "Verdana"
  } as const,
  logo: {
    width: "50%",
    height: "150px",
    margin: "auto"
  },
  CardsBox: {
    //marginTop: "20px",
    justifyContent: "center",
    height: "100%",
    display: "flex",
    alignItems: "center",
    textAlign: "center"
  },
  buttonGroup: {
    paddingLeft: "42%",
    paddingRight: "42%",
    textTransform: "none",
    backgroundColor: "#01548A",
    color: "#fff",
    fontWeight: "bold",
    marginTop: "5%",
    marginBottom: "5%",
    width: "400px",
    height: "56px",

    "&:hover": { color: "red", background: "#01548A" }
  } as const,
  buttonGroupSignUp: {
    paddingLeft: "42%",
    paddingRight: "42%",
    textTransform: "none",
    backgroundColor: "#fff",
    fontWeight: "bold",
    marginRight: "8px",
    width: "400px",
    height: "56px",
    borderColor: "#01548A",
    textDecoration: "none",
    color: "#01548A",
    "&:hover": { color: "red", background: "#01548A" }
  } as const
};

export default WelcomeNew;
// Customizable Area End
