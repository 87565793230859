export const SendFill = require('../assets/send_fill.svg')
export const RightArrow = require("../assets/arrow-right.svg");
export const SendFillBlue = require('../assets/send_blue.svg')
export const Save = require('../assets/Save.svg')
export const Link = require('../assets/Link.svg')
export const Notification = require('../assets/Notification.svg')
export const MuteNotification = require('../assets/MuteNotification.svg');
export const Trash = require('../assets/trash.svg');
export const More = require('../assets/more.svg');
export const Report = require('../assets/Report.svg')
export const LocationSVG = require('../assets/LocationSVG.svg')
export const arrowleft = require('../assets/arrow-left.svg')
export const arrowsquaredown = require('../assets/arrow-square-down.svg')
export const messagetext = require('../assets/message-text.svg')
export const tickcirclewhite = require('../assets/tick-circle-white.svg')
export const tickcircle = require('../assets/tick-circle.svg')
export const editImg = require('../assets/editimg.svg')
export const Star = require('../assets/star.svg')
export const BlueStar = require('../assets/blue-star.svg')
export const clock = require('../assets/clock.svg')
export const smallLink = require('../assets/smallLink.svg')
export const Edit = require('../assets/edit.svg')
export const DotCalender = require('../assets/dotCalender.svg')
export const blankCalender = require('../assets/blankCalender.svg')
export const squreCalender = require('../assets/squreCalender.svg')