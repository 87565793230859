// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const UserIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" fill="none" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.59 22C20.59 18.13 16.74 15 12 15C7.26 15 3.41 18.13 3.41 22" fill="none" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
        </SvgIcon>
    );
}

export default UserIcon;
// Customizable Area End