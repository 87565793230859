import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid, FormControlLabel, Checkbox
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import { Logo, SideImage } from "./assets";
// Customizable Area End

import TermsAndConditionsController, {
  Props,
  configJSON,
} from "./TermsAndConditionsController";

export default class TermsAndConditions extends TermsAndConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Wrapper container>
          {this.state.isLoading && <Loader loading={this.state.isLoading} />}
          <Grid item xs={false} md={5} component={Box} sx={{ height: "100vh", display: { xs: 'none', md: 'block' } }}>
            <img src={SideImage} alt="side-panel" width="100%" height="100%" />
          </Grid>

          <Grid item xs={12} md={7} className="scrollWrapper">
            <Box component="section" sx={{ width: "85%", maxWidth: "500px", paddingY: '20px' }}>
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <img src={Logo} alt="logo-img" className="logo" />
                <Box component={'h1'} fontFamily={"Veradana"} textAlign={'center'} fontSize={"45px"} color={"#005487"} fontWeight={'bold'} m={0} mt={"40px"} mb={"20px"} data-test-id="titleText">{configJSON.titleText}</Box>
                <div dangerouslySetInnerHTML={{ __html: this.state.termsText }} />
                <Box display={'flex'} alignItems={'center'} className="checkbox" width={"100%"} my={"20px"}>
                  <FormControlLabel
                    data-test-id="btnCheck"
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.props?.agree}
                        onChange={this.props?.setAgree}
                        name="agree"
                      />
                    }
                    label="I accept to the Terms and Conditions."
                  />
                </Box>
                <Button variant="contained" disabled={!this.props.agree} data-test-id="accept-btn" type="submit" className={"publishbtnExpand"} onClick={this.props?.handleAccept}>{configJSON.acceptBtn}</Button>
              </Box>
            </Box>
          </Grid>
        </Wrapper>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Wrapper = styled(Grid)({
  background: '#fff',
  "& .logo": {
    width: "250px",
    boxShadow: "0 4px 8px 0 rgba(32, 32, 32, 0.2)",
    height: "250px",
    borderRadius: '50%',
  },
  "& .scrollWrapper": {
    display:'grid',
    placeItems: 'center',
    height: '100vh',
    overflowY: 'auto'
  },
  "& .checkbox": {
    "& .MuiFormControlLabel-label": {
      fontsize: '14px !important',
      color: '#808080'
    },
    "& .MuiFormControlLabel-root":{
      marginRight: "6px",
    }
  },
  "& .publishbtnExpand":{
    height: "56px",
    fontWeight: "bold",
    color: "#fff",
    fontSize: "16px",
    width: "100%",
    fontFamily: "Verdana",
    textTransform: "none",
    backgroundColor: "#005487",
    "&:hover": {
      backgroundColor: "#005487",
    },
  },
  "& .Mui-disabled":{
    backgroundColor: "#808080",
  },
  "& p":{
    fontFamily: "Verdana"
  }
})
// Customizable Area End
