export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Camera=require("../assets/camera.svg");
export const NoUser=require("../assets/user.svg");
export const Dropdown = require("../assets/sms.svg");
export const clock = require("../assets/Time.svg");
export const smallUser = require("../assets/smallUser.svg");
export const location = require("../assets/location-add.svg");
export const job =require("../assets/job.svg");
export const group =require("../assets/group.svg");
export const Gender =require("../assets/Gender.svg");
export const user2 = require("../assets/profile-2user.svg");
export const Pronouns = require("../assets/pronoun.svg");
export const translate = require("../assets/Translate.svg");
export const calendar = require("../assets/calendar.svg");
export const Edit =require("../assets/edit.svg");
export const Email =require("../assets/email.svg");
export const Trash =require("../assets/trash.svg");
export const Add = require("../assets/Add New.svg");
export const AddUser = require("../assets/AddUser.svg");
export const Link = require("../assets/Link.svg");
export const Global = require("../assets/global.svg");
export const PCard = require("../assets/personalcard.svg");
export const CrossLink = require("../assets/CrossLink.svg")
export const roundLocation = require("../assets/location.svg");
export const phone = require("../assets/phone.svg");