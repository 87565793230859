import { getStorageData } from "../../framework/src/Utilities";
import { Message } from "./../../framework/src/Message";
import MessageEnum, {
  getName,
} from "./../../framework/src/Messages/MessageEnum";
import { runEngine } from "./../../framework/src/RunEngine";

export default async function ApiCall(data:any) {
    
    const { contentType, method, endPoint, body, type } = data;
    let signupToken = await getStorageData("SignupSuccessToken");
    let loginToken = await getStorageData("LoginSuccessToken");
    const header = {
      "Content-Type": contentType,
      token: signupToken || loginToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body && type !== 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      :
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  
}
