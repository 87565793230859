import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  InputAdornment,
  Typography,
  Button,
  FormControl,
  TextField,
  Link
} from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import { SideImage, Logo } from "./assets";
import PhoneVerificationLoginFlowController, { Props } from "./PhoneVerificationLoginFlowController.web";
import { configJSON } from "./EmailAccountLoginController";
import Loader from "../../../components/src/Loader.web";

// Customizable Area Start
import {Phone}  from "./assets";
const PhoneVerificationLoginFlowStyles = (theme: Theme) =>
  createStyles({
    scrollWrapper: {
      display:'grid',
      placeItems: 'center',
      height: '100vh',
      overflowY: 'auto',
      background: '#fff',
    },
    pointer: {
      cursor: 'pointer',
    },
    LoginLogoImg: {
      width: "250px",
      height: "250px",
      borderRadius: '50%',
      boxShadow: "0 4px 8px 0 rgba(32, 32, 32, 0.2)",
    },
    inputfield: {
      "& .MuiOutlinedInput-input": {
        paddingBlock: "20px",
      }
    },
    publishBtn: {
      height: "56px",
      fontSize: "16px",
      fontWeight: "bold",
      width: "100%",
      color: "#fff",
      fontFamily: "Verdana",
      textTransform: "none",
      backgroundColor: "#005487",
      marginTop: "246px",
      "&:hover": {
        backgroundColor: "#005487",
      },
      "@media(max-width:600px)": {
        marginTop: "200px",
      },
    },
    verifybtn : {
      height: "56px",
      fontWeight: "bold",
      width: "100%",
      fontSize: "16px",
      color: "#fff",
      textTransform: "none",
      backgroundColor: "#005487",
      marginTop: "180px",
      fontFamily: "Verdana",
      "&:hover": {
        backgroundColor: "#005487",
      },
      "@media(max-width:600px)": {
        marginTop: "150px",
      },
    },
    redirect: {
      textDecoration: 'underline',
      color: '#005487',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    resend: {
      color: '#005487',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    otpField: {
      width:'60px',
      "& .MuiOutlinedInput-input": {
        paddingBlock: "20px",
      }
    },
    form: {
      margin: 0,
      width: '100%',
    }
  });
// Customizable Area End

// Customizable Area Start
export class PhoneVerificationLoginFlow extends PhoneVerificationLoginFlowController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return  (
      <>
       {this.state.isLoadingShow && <Loader loading={this.state.isLoadingShow}/>}
        <Grid container>
          <Grid item xs={false} md={5} component={Box} sx={{ height: "100vh", display: { xs: 'none', md: 'block' } }}>
            <img src={SideImage} width="100%" height="100%"  alt="side-panel" />
          </Grid>

          <Grid item xs={12} md={7} className={classes.scrollWrapper}>
            <Box component={'section'}  sx={{ paddingY: '20px', width: "85%", maxWidth: "500px" }}>
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'} >
                <Box mb={{ xs: "30px", md: '40px' }} display={'flex'} flexDirection={'column'} alignItems={'center'} >
                  <img src={Logo} alt="logo-img" className={classes.LoginLogoImg} />
                  <Box component={'h1'} fontFamily={"Veradana"} fontSize={"45px"} color={"#005487"} fontWeight={'bold'} m={0} my={{ xs: "30px", md: '40px' }}>Phone Verification</Box>
                  <Box component={'h2'} fontFamily={"Veradana"} fontSize={"20px"} color={"#808080"} fontWeight={'normal'} textAlign={'center'} m={0}>
                    {this.state.isOtpSent ? (
                      <>
                        OTP has been sent to your Phone Number.<br /> Please Verify.
                      </>
                    ) : (
                      <>
                        Enter your Phone Number to receive a <br /> One Time Password.
                      </>
                    )}
                  </Box>
                </Box>
                {this.state.isOtpSent ?
                  <form onSubmit={this.handleOtpSubmit} className={classes.form} data-test-id="verify-btn">
                    <Box display="flex" alignItems={"center"} justifyContent={'center'} gridGap={"20px"}>
                      {Array.from({ length: 4 }).map((_, index) => (
                        <FormControl key={index}>
                          <TextField
                            inputRef={this.textInputRefs[index]}
                            data-test-id={`txtOTPpin`}
                            size="medium"
                            placeholder=""
                            inputProps={{
                              pattern: "\\d*",
                              maxLength: 1
                            }}
                            onKeyDown={(e) => this.handleKeyDown(e, index)}
                            autoFocus={index === 0}
                            value={this.state.otpFormFields[index]}
                            onChange={(e) => this.handleOTPInputChange(e, index)}
                            className={classes.otpField}
                            type="number"
                            variant="outlined"
                          />
                        </FormControl>
                      ))}
                    </Box>

                    {this.state.isDisabled ? (
                      <Box component={'p'} fontFamily={"Veradana"} fontSize={"14px"} color={"red"} textAlign={'center'} fontWeight={'bold'} m={0} mt={'16px'}>
                        00:{this.state.otpTiming}
                      </Box>
                    ) : (

                      <Box component={'p'} fontFamily={"Veradana"} fontSize={"14px"} color={"#808080"} textAlign={'center'} m={0} mt={'16px'}>Don&#8217;t receive code? <span className={classes.resend} data-test-id="resend-id" onClick={this.rseendCode}>Resend.</span></Box>
                    )}
                    <Button variant="contained" type="submit" data-test-id="verify" className={classes.verifybtn}>Verify</Button>
                  </form>
                  :
                  <form onSubmit={this.handlePhoneNumberSubmit} className={classes.form} data-test-id="publish-btn">
                    <FormControl fullWidth>
                      <TextField
                        data-test-id="fullPhoneNumber"
                        variant="outlined"
                        size="medium"
                        placeholder="Phone Number"
                        className={classes.inputfield}
                        name="fullPhoneNumber"
                        type="number"
                        autoFocus={true}
                        error={Boolean(this.state.errorMessage)}
                        value={this.state.fullPhoneNumber}
                        onChange={this.handlePhoneNumberChange}
                        fullWidth
                        helperText={this.state.errorMessage}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" >
                              <img src={Phone} alt="email" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                    <Button variant="contained" type="submit"  className={classes.publishBtn}>Send OTP</Button>
                  </form>
                }
                {(!this.state.isOtpSent && !this.state.isSignUp) && <Box component={'p'} fontFamily={"Veradana"} fontSize={"17px"} color={"#808080"} m={0} mt={'30px'}><span className={classes.redirect} data-test-id="skip-id" onClick={this.handleSkipVerificationClick}>Skip!</span> verification for 30 days.</Box>}
              </Box>
            </Box>
          </Grid>
        </Grid>
                 
      </>
    );
  }
}

export default withStyles(PhoneVerificationLoginFlowStyles, {
  withTheme: true,
})(PhoneVerificationLoginFlow);
// Customizable Area End