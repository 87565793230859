export const imgAdd = require("../assets/ic_add.png");
export const imgCancel = require("../assets/ic_cancel.png");
export const imgGstin = require("../assets/ic_gstin.png");
export const imgMapIcon = require("../assets/ic_map_icon.png");
export const imgSuccessModal = require("../assets/success_modal_image.png");
export const backIcon = require("../assets/backIcon.png");
export const EditIcon=require("../assets/edit.svg");
export const ArrowDown=require("../assets/arrow-square-down.svg");
export const AddIcon=require("../assets/add.svg");
export const LinkIcon=require("../assets/Link.svg");
export const PersonalCard=require("../assets/personalcard.svg");
export const UploadIcon=require("../assets/export.svg");
export const Close=require("../assets/close-square.svg");
export const PdfIcon=require("../assets/PDFIcon.png")