// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import ApiCall from "../../../components/src/ApiCall.web";
import { toast } from "react-toastify";
import { sendAPIRequest } from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tab: boolean;
  dropDownDetails: any;
  openDropDown: boolean;
  selectedReason: string;
  showReason: string;
  eventID:any;
  postId: string;
  postError: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}




export default class ReportController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  reportpostapiApiCallId: any;
  reportPostId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    
    this.state = {
      tab: true,
      dropDownDetails: [
        {
          title: "Violence",
          active: false,
        },
        {
          title: "Harrasment",
          active: false,
        },
        {
          title: "False Information",
          active: false,
        },
        {
          title: "Spam",
          active: false,
        },
        {
          title: "None",
          active: false,
        },
      ],
      openDropDown: false,
      selectedReason: "",
      showReason: "",
      eventID:"",
      postId: "",
      postError: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    const postId = this.props.navigation.history.location.postId;
    if (postId) return this.setState({ postId: postId });
    this.setState({ eventID: this.props.navigation.history.location.EventId });
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors) {

        this.responseSucessCell(apiRequestCallId, responseJson)
      } else if (responseJson && responseJson.errors) {
        this.responseFailureCall(apiRequestCallId, responseJson)

      }
      if (responseJson && apiRequestCallId === this.reportPostId) return this.reportPostResponse(responseJson);
    }
  }


  responseSucessCell = async (apiRequestCallId: any, responseJson: any) => {

    if (apiRequestCallId === this.reportpostapiApiCallId) {
      this.reportSucessCallBack(responseJson);
    }

  }

  responseFailureCall = async (apiRequestCallId: any, responseJson: any) => {
    
    if (apiRequestCallId === this.reportpostapiApiCallId) {
      this.reportFailureCallBack(responseJson);
    }
  }

  reportapi = async () => {
    if (this.state.postId) return this.reportPost();
    let formdata = new FormData();
    formdata.append("event_id",this.state.eventID);
    formdata.append("report_reason",this.state.showReason);
    this.reportpostapiApiCallId = await ApiCall({
      method:"PUT",
      endPoint:configJSON.reportEventEndPOint,
      body: formdata,
      type: 'formData'
    });
  };

  reportPost = async () => {
    if (!this.state.showReason) return this.setState({ postError: "select reason" })
    const signupToken = await getStorageData('SignupSuccessToken');
    const loginToken = await getStorageData('LoginSuccessToken');
    let body = new FormData();
    body.append("post_id", this.state.postId);
    body.append("report_reason", this.state.showReason);
    this.reportPostId = sendAPIRequest(configJSON.reportPostEnd, {
      method: configJSON.PutAPiMethod,
      body: body,
      headers: {
        token: signupToken || loginToken
      }
    })
  }

  reportPostResponse = (responseJson: { data: [{ message: string }] }) => {
    if(responseJson.data){
      toast.success(responseJson.data[0].message);
      this.props.navigation.goBack();
    }
  }

  reportSucessCallBack = (res: any) => {
    console.log("@@@ ====== reportSucess success call back", res);
    if(res.data){
      this.setState({showReason:""})
      this.goBackreport()
      toast.success(res.data[0].message)
    }
    
  };
  reportFailureCallBack = (res: any) => {
    console.log("@@@ ====== reportSucess  Failure success call back", res);
  };

  handleDetail = (title: string, itemIndex: any) => {
    this.state.dropDownDetails.map((item: any, index: number) => {
      if (index === itemIndex) {
        item.active = !item.active;
        this.setState({
          dropDownDetails: this.state.dropDownDetails,
          selectedReason: title,
        });
      } else {
        item.active = false;
        this.setState({ dropDownDetails: this.state.dropDownDetails });
      }
    });
  };
  handleOpenDropDown = () => {
    this.setState({ openDropDown: !this.state.openDropDown, postError: "" });
  };
  handleDone = () => {
    this.setState({
      showReason: this.state.selectedReason,
      openDropDown: !this.state.openDropDown,
    });
  };

  goBackreport=()=>{
    this.props.navigation.goBack()
  }

  // Customizable Area End
}
