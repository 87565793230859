Object.defineProperty(exports, "__esModule", {
    value: true
  });
  
  // Customizable Area Start
  exports.validationApiContentType = "application/json";
  exports.validationApiMethodType = "GET";
  exports.exampleAPiEndPoint = "EXAMPLE_URL";
  exports.getAssessementMethod = "GET";
  exports.submitAssessementTestAPiMethod = "POST";
  exports.allAssessementTestApiContentType = "application/json";
  exports.getAllAssessementTestUrl = `bx_block_assessmenttest/assessments`;
  exports.apiSubmitAssessmentQuestions = `bx_block_assessmenttest/submit_que_answer`;
  exports.submitAssessementTestUrl = `bx_block_assessmenttest/submit_assessment?assessment_id=`;
  exports.textInputPlaceHolder = "Enter Text";
  exports.labelTitleText = "AssessmentTest";
  exports.labelBodyText = "AssessmentTest Body";
  exports.barc_assessment="BARC Assessment";
  exports.barc_api_end="bx_block_questionbank/quizzes/fetch_barc_questions";
  exports.barc_api_answer="bx_block_questionbank/account_answers/submit_barc_answer";
  exports.getApi="GET";
  exports.postApi="POST";
  exports.submitBtn="Submit";
  exports.anonymous="Anonymous";
  exports.test_type="Test Type";
    // Customizable Area End