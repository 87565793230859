import React from "react";
import {  createStyles, Theme,withStyles } from "@material-ui/core/styles";

import {
  Typography,
  Box,
  Grid,
  Card,
} from "@material-ui/core";
import UserProfileInformationSectionController, { Props } from "./UserProfileInformationSectionController.web";
import { CalendarIcon, EditIcon,UserIconOne, EthnicityIcon, GenderIcon, LanguageIcon, Mail, PhoneIcon, PronounsIcon, RaceIcon, SocialIcon, TimeIcon, Lock } from "./assets";

// Customizable Area Start
const UserProfileInformationSectionStyles = (theme: Theme) =>
  createStyles({
    root: {
        maxWidth: "100%",
        marginBottom: "16px",
        borderRadius: "12px",
        position: "relative",
        padding: "24px",
        border: "solid 1px #808080",
      },
    
      bottomBorder: {
        margin: "21.9px 0 20.1px",
        borderBottom: "solid 0.3px #808080;",
      },
      textlabel: {
        fontFamily: "Verdana",
        FontSize: "17px",
        fontWeight: "bolder",
        fontSretch: "normal",
        fontStyle: "normal",
        lineeHight: "normal",
        letterSpacing: "normal",
        color: "#000",
      },
      mutualImage: {
        height: "40px",
        width: "40px",
        borderRadius: "50%",
      },
      mutualImage2: {
        position: "absolute",
        top: "18px",
        left: "18px",
      },
      pointer: {
        cursor: "pointer"
      },
      linkColor: {
        color: "#005487",
        wordWrap: "break-word",
      }
  });
// Customizable Area End

// Customizable Area Start
export class UserProfileInformationSection extends UserProfileInformationSectionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderMutual = () => {
      const { classes, mutualConnectionData } = this.props;
      const moreThanOne = (mutualConnectionData?.mutual_connection_count ?? 0) > 1;
      return (<>
        {(mutualConnectionData) && <Box>
          <Box component="p" m={0} sx={{ fontFamily: "Verdana", fontSize: "22px", fontWeight: "bold" }} color="#005487">
            Highlights
          </Box>
  
          <Box display={"flex"} alignSelf={"center"} marginTop={"16px"} >
            {mutualConnectionData.mutual_connection_count > 0 && <Box
              marginRight={"12px"}
              width={moreThanOne ? "70px" : "60px"}
              height={moreThanOne ? "58px" : "42px"}
              position={"relative"}
              className={classes.pointer}
              data-test-id="mutual-count-image" onClick={this.handleMutualNavigation}
            >
              <img
                className={`${classes.mutualImage}`}
                src={mutualConnectionData.accounts_data[0].photo}
                alt="img1"
              />
              {moreThanOne && <img
                className={`${classes.mutualImage} ${classes.mutualImage2}`}
                src={mutualConnectionData.accounts_data[1].photo}
                alt="img2"
              />}
            </Box>}
  
            <Box display={"flex"} flexDirection={"column"}>
              <Box component="p" m={0} sx={{ fontSize: "14px", fontWeight: "bold", fontFamily: "Verdana" }}
                onClick={this.handleMutualNavigation} data-test-id="mutual-count-title"
                className={classes.pointer}
              >
                {mutualConnectionData.mutual_connection_count > 0 && `${mutualConnectionData.mutual_connection_count} Mutual connections`}
              </Box>
              <Box component="p" m={0} sx={{ fontSize: "12px", fontFamily: "Verdana" }} marginTop={"3px"} color="#808080">
                {mutualConnectionData.mutual_connection_message}
              </Box>
            </Box>
          </Box>
        </Box>}</>
      )
    }

  render() {
    const {classes, isOwnProfile, isCommunity} = this.props;
    const {email, full_phone_number, pronouns, dob,selected_communities, language, race,ethnicity,gender,timezone,social_media_urls,is_private} = this.props.data.attributes;
    return (
    <Box component={"section"} bgcolor={"#fff"} marginBottom={"16px"} sx={{border:"1px solid #808080",borderRadius: "12px"}}>
      {(!isOwnProfile && !isCommunity) && <Box component={"section"} p={"24px"} sx={{borderBottom:"1px solid #808080"}}>
          {this.renderMutual()} 
      </Box>}
      <Box component={"section"} p={"24px"} >
          <Box display={"flex"} justifyContent={"space-between"}>
          <Box component={"h3"} m={0} fontFamily={"Verdana"} data-testid="information-title" fontSize={"22px"} fontWeight={"bold"} color="#005487">{isCommunity ? "Community" : "User"} Information {!isCommunity && <Box component={"span"} fontWeight={"normal"} color="#808080" fontSize={"17px"}>{is_private?"• Private":"• Public"}</Box>}</Box>
          {isOwnProfile && <img src={EditIcon} className={classes.pointer} onClick={this.editDetails} alt="edit" data-testid="editDetails"/>}
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} mt={"20px"} alignItems={"start"}>
            <img src={Mail} alt="mail"/>
            <Box ml={"17px"}  width={"100%"}>
              <Box component={"label"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>E-mail ID</Box>
              <Box component={"p"} fontFamily={"Verdana"} data-testid="email" color="#808080" fontSize={"16px"} m={0} mt={"4px"} mb={"21px"} fontWeight={"normal"}>{email}</Box>
            </Box>
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} mt={"20px"} alignItems={"start"}>
            <img src={PhoneIcon} alt="phoneIcon"/>
            <Box ml={"17px"}  width={"100%"}>
              <Box component={"label"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Phone Number</Box>
              <Box component={"p"} fontFamily={"Verdana"} color="#808080" fontSize={"16px"} m={0} mt={"4px"} mb={"21px"} fontWeight={"normal"}>{full_phone_number}</Box>
            </Box>
          </Box>
          {!isCommunity && <>
          <Box display={"flex"} borderBottom={"1px solid #808080"} mt={"20px"} alignItems={"start"}>
            <img src={PronounsIcon} alt="pronun"/>
            <Box ml={"17px"}  width={"100%"}>
              <Box component={"label"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Pronouns</Box>
              <Box component={"p"} fontFamily={"Verdana"} color="#808080" fontSize={"16px"} m={0} mt={"4px"} mb={"21px"} fontWeight={"normal"}>{pronouns}</Box>
            </Box>
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} mt={"20px"} alignItems={"start"}>
            <img src={CalendarIcon} alt="calender"/>
            <Box ml={"17px"}  width={"100%"}>
              <Box component={"label"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>DOB(Date of Birth)</Box>
              <Box component={"p"} fontFamily={"Verdana"} color="#808080" fontSize={"16px"} m={0} mt={"4px"} mb={"21px"} fontWeight={"normal"}>{dob}</Box>
            </Box>
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} mt={"20px"} alignItems={"start"}>
            <img src={LanguageIcon} alt="language"/>
            <Box ml={"17px"}  width={"100%"}>
              <Box component={"label"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Language</Box>
              <Box component={"p"} fontFamily={"Verdana"} color="#808080" fontSize={"16px"} m={0} mt={"4px"} mb={"21px"} fontWeight={"normal"}>{language.join(', ')}</Box>
            </Box>
          </Box>
          <Box borderBottom={"1px solid #808080"} mt={"20px"}>
            <Box component={"label"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Demographics</Box>
            <Grid container component={Box} mt={"20px"}>
            <Grid component={Box} item  xs={4} lg={3}display={"flex"} mt={"16px"} sx={{alignItems:"start"}}>
            <img src={RaceIcon} alt="race"/>
            <Box ml={"17px"}  width={"100%"}>
              <Box component={"label"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Race</Box>
              <Box component={"p"} fontFamily={"Verdana"} color="#808080" fontSize={"16px"} m={0} mt={"4px"} mb={"21px"} fontWeight={"normal"}>{race}</Box>
            </Box>
            </Grid>
            <Grid component={Box} item  xs={4} lg={3}display={"flex"} mt={"16px"} sx={{alignItems:"start"}}>
            <img src={EthnicityIcon} alt="ethnicity"/>
            <Box ml={"17px"}  width={"100%"}>
              <Box component={"label"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Ethnicity</Box>
              <Box component={"p"} fontFamily={"Verdana"} color="#808080" fontSize={"16px"} m={0} mt={"4px"} mb={"21px"} fontWeight={"normal"}>{ethnicity}</Box>
            </Box>
            </Grid>
            <Grid component={Box} item  xs={4} lg={3}display={"flex"} mt={"16px"} sx={{alignItems:"start"}}>
            <img src={GenderIcon} alt="gender"/>
            <Box ml={"17px"}  width={"100%"}>
              <Box component={"label"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Gender</Box>
              <Box component={"p"} fontFamily={"Verdana"} color="#808080" fontSize={"16px"} m={0} mt={"4px"} mb={"21px"} fontWeight={"normal"}>{gender}</Box>
            </Box>
            </Grid>
            </Grid>
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} mt={"20px"} alignItems={"start"}>
            <img src={TimeIcon} alt="time"/>
            <Box ml={"17px"}  width={"100%"}>
              <Box component={"label"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Display Times in</Box>
              <Box component={"p"} fontFamily={"Verdana"} color="#808080" fontSize={"16px"} m={0} mt={"4px"} mb={"21px"} fontWeight={"normal"}>{timezone}</Box>
            </Box>
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} mt={"20px"} pb="24px" alignItems={"start"}>
            <img src={SocialIcon} alt="social"/>
            <Box ml={"17px"}  width={"100%"} mb={"4px"}>
              <Box component={"label"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Connect Social Media</Box>
              {social_media_urls.length >0 ? social_media_urls.map(item=> <Box fontFamily={"Verdana"} fontSize={"16px"} key={item.id} mt={"4px"}><a href={item.url} target="_blank"  className={classes.linkColor}>{item.url}</a></Box>): <Box component={"p"} fontFamily={"Verdana"} color="#808080" fontSize={"16px"} m={0} mt={"4px"} fontWeight={"normal"}>-</Box>}
            </Box>
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} mt={"20px"} pb="24px" alignItems={"start"}>
            <img src={UserIconOne} alt="UserIconOne"/>
            <Box ml={"17px"}  width={"100%"} mb={"4px"}>
              <Box component={"label"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Communities</Box>
              {(selected_communities && selected_communities.data.length >0 )? selected_communities.data.map(item=> <Box fontFamily={"Verdana"} color="#808080" fontSize={"16px"} key={item.id} className={classes.pointer} mt={"4px"} data-testid="community-id" onClick={()=>this.navigateCommunity(item.id)}>{item.attributes.name}</Box>): <Box component={"p"} fontFamily={"Verdana"} color="#808080" fontSize={"16px"} m={0} mt={"4px"} fontWeight={"normal"}>-</Box>}
            </Box>
          </Box>
          </>}
      </Box>
    </Box>
    );
  }
}

export default withStyles(UserProfileInformationSectionStyles, { withTheme: true })(UserProfileInformationSection);
// Customizable Area End