import firebase from "firebase";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAUUR8MTunIU1X3eDGk0tXVyO7um_IWUKU",
  authDomain: "faceweb-38a5e.firebaseapp.com",
  projectId: "faceweb-38a5e",
  storageBucket: "faceweb-38a5e.appspot.com",
  messagingSenderId: "221182537601",
  appId: "1:221182537601:web:57f1bf404b147f13aa0280",
  measurementId: "G-NQNE3K0DD3"
};

export const firebaseAPI = initializeApp(firebaseConfig);
const messaging = firebase.messaging();

export const getNotificationPermission = async () => {
  const permission = await Notification.requestPermission();

  if (permission === 'granted') {

    // Get FCM token
    return messaging.getToken({ vapidKey: 'BN0Nc0PKHlj1vLqRDc4NmmetGik1_6hO2EFEgMC2BC3-1LBJ3GVUDSH2NLUzPCq65KYaWqdAahsukMdzy7NvH38' }).then((currentToken) => {
      console.log("fcm token:",currentToken)
      if (currentToken) {
        // Save or send the token as needed
        return currentToken;
      } else {
        console.error('Failed to get FCM token.');
      }
    })
      .catch((error) => {
        console.error('Error getting FCM token:', error);
      });
  } else {
    console.log('Notification permission denied.');
  }
};


export const messageListener = () => {
  messaging.onMessage(function (payload) {
})
}
