import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Box,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from "@material-ui/core";
import UserProfileWallpaperSectionController, { Props } from "./UserProfileWallpaperSectionController.web";
import { EditIcon } from "./assets";

// Customizable Area Start
import { configJSON } from "./UserProfileWallpaperSectionController.web";
import { Lock } from "./assets";
import Loader from "../../../components/src/Loader.web";
import Avatar from "@material-ui/core/Avatar";
const UserProfilePageStyles = (theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "100%",
      marginBottom: "16px",
      borderRadius: "12px",
      border: "solid 1px #808080",
    },
    media: {
      height: 180,
      position: "relative",
    },
    cardContent: {
      position: "relative",
      padding: "24px"
    },
    boxLogo: {
      width: "160px",
      height: "160px",
      borderRadius: "50%",
    },
    boxLogoCommunity: {
      width: "160px",
      height: "160px",
    
    },
    contectText: {
        color: "#005487",
        textDecoration: "underline",
        cursor: "pointer"
    },
    logoCircle: {
      background: "#ffffff",
      borderRadius: "50%",
      padding: "5px",
    },
    logoSquare: {
      background: "#ffffff",

      padding: "5px",
    },
    editIcon: {
      background: "white",
      width: "32px",
      height: "32px",
      padding: "4.3px 6px 4px",
      borderRadius: "50%",
      position: "absolute",
      top: "20px",
      right: "20px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    buttonGroup: {
      columnGap:"20px",
      flexWrap: "wrap-reverse",
      "@media(max-width:600px)": {
        columnGap:"10px",
      },
      "@media(max-width:390px)": {
        columnGap:"8px",
      }
    },
    btn: {
      padding: "9px 35px",
      fontFamily: "Verdana",
      fontSize: "14px ",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "norma",
      letterSpacing: "normal",
      textAlign: "center",
      // marginRight: "20px",
      color: "#fff",
      cursor: "pointer",
      border: "none",
      backgroundColor: "#005487",
      borderRadius: '0',
      "@media(max-width:600px)": {
        padding: "9px 15px",
        fontSize: "12px",
      },
      "@media(max-width:380px)": {
        fontSize: "11px",
      }
    },
    btn1: {
      marginRight: "20px",
      color: "white",
      // "&:hover": {
      //   color: "#005487",
      // },
    },
    blueBackground: {
      background: "#005487",
      border: "solid 1px #005487",
      color: "#ffffff",
    },
    grayBackground: {
      backgroundColor: "#d9d9d9",
      cursor: "none"
    },
    darkGraytext: {
      color: "#808080"
    },
    blackText: {
      color: "#000000",
      padding: "9px 26px 9px",
      "@media(max-width:600px)": {
        padding: "9px 15px",
        fontSize: "12px",
      },
      "@media(max-width:390px)": {
        padding: "9px 15px",
        fontSize: "11px",
      }
    },
    mainWrapper:{
      overflow: "hidden"
    },
    backgroundImg: {
      width: "100%",
      height:"100%"
    },
    profilPic: {
      position: "absolute",
      height: "158px",
      width: "158px",
      border: "2px solid #fff",
      left: "24px",
      bottom:0,
      transform: "translateY(50%)",
    },
    communityPic:{
      position: "absolute",
      height: "158px",
      width: "158px",
      border: "2px solid #fff",
      bottom:0,
      borderRadius:"12px",
      left: "50%",
      transform: "translateX(-50%) translateY(50%)",
    },
    backgroudEdit:{
      position: "absolute",
      width:"32px",
      height:"32px",
      borderRadius:"50%",
      backgroundColor: "#fff",
      top:"20px",
      right:"20px",
      justifyContent:"center",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    },
    profileEdit:{
      position: "absolute",
      display:"flex",
      justifyContent:"center",
      alignItems: "center",
      width:"32px",
      height:"32px",
      borderRadius:"50%",
      backgroundColor: "#fff",
      top:"200px",
      cursor: "pointer",
      right:"20px",
    },
    inputImg:{
      opacity: 0,
      position: "absolute",
      width:"32px",
      height:"32px",
      top:"20px",
      cursor: "pointer",
      right:"20px"
    },
    imgEdit: {
      width:"20px",
      height: "20px"
    }
  });
// Customizable Area End

// Customizable Area Start
     
export class UserProfileWallpaperSection extends UserProfileWallpaperSectionController {
  constructor(props: Props) {
    super(props);
  }


  renderRequestSection = () => {
    const { is_followed_by_login_user, is_friend_request_sent, is_in_connection } = this.state.data.attributes;
    const { classes } = this.props
    const tempRequest = is_friend_request_sent ? "Request Sent" : "Add Friend";
    return (
      <Box mt="17px" display={"flex"} className={classes.buttonGroup}>
        <button
          onClick={this.sendFriendRequest}
          data-test-id={`add-friend-button`}
          className={`${classes.btn} ${is_in_connection || is_friend_request_sent ? classes.grayBackground : ""} ${is_friend_request_sent && classes.blackText} ${is_in_connection && classes.darkGrayText}`}>
          {
            is_in_connection ? "Friends" : tempRequest
          }
        </button>
        <button onClick={this.handleMessageClick} data-test-id={"message-button"} className={`${classes.btn} ${!is_in_connection && classes.grayBackground + ' ' + classes.darkGrayText}`}>Message</button>
        <button onClick={this.handleFollowUnfollow} data-test-id={"follow-button"} disabled={is_in_connection} className={`${classes.btn} ${is_in_connection ? classes.grayBackground + ' ' + classes.darkGrayText : ""}`}>{is_followed_by_login_user ? "Unfollow" : "Follow"}</button>
      </Box>

    )
  }

  renderDetails = () => {
    const { follower_count, friend_list_count, full_name, job_title, city, state, is_followed_by_login_user, description } = this.state.data.attributes;
    const { classes, isCommunity, isOwnProfile } = this.props
    return (
      <>
        <Box>
          <Box component={"h3"} fontWeight={"bold"} fontSize={"22px"} data-testid="name" fontFamily={"Verdana"} m={0}>{full_name}</Box>
          {!isCommunity && <>{(!this.state.is_private) ? <>
            <Box component={"h4"} fontWeight={"normal"} fontSize={"16px"} color="#808080" fontFamily={"Verdana"} m={0} mb={"9px"} mt={"7px"}>{job_title}</Box>
            <Box component={"h4"} fontWeight={"normal"} fontSize={"16px"} color="#808080" fontFamily={"Verdana"} m={0}>{state} • {city}</Box>
          </> :
            <Box component={"p"} fontWeight={"normal"} fontSize={"16px"} color="#808080" fontFamily={"Verdana"} m={0} mt={"8px"}>Add {full_name} as a friend to see the details he shared on his  profile.</Box>
          }</>}
            {isCommunity && <Box component={"h4"} fontWeight={"normal"} fontSize={"16px"} color="#808080" fontFamily={"Verdana"} m={0} mb={"9px"} mt={"7px"}>{description}</Box>}
        </Box>
        {!this.state.is_private && <>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mt={"11px"}>
            <Box component={"h5"} fontWeight={"normal"} m={0} color="#005487" fontSize={"14px"} className={classes.contectText} fontFamily={"Verdana"} onClick={this.navigateContactInfo} data-test-id="user-profile-contactinfo">Contact Info</Box>
            {!isCommunity && <Box component={"h5"} fontWeight={"normal"} m={0} color="#005487" fontSize={"14px"} className={classes.contectText} fontFamily={"Verdana"} onClick={this.navigateFriends} data-test-id="user-profile-friendlist">{friend_list_count} Friends</Box>}
            <Box component={"h5"} fontWeight={"normal"} m={0} color="#005487" fontSize={"14px"} className={classes.contectText} fontFamily={"Verdana"} onClick={this.navigateFollowers} data-test-id="user-profile-followerslist">{follower_count} Followers</Box>
            {(isCommunity && !isOwnProfile) && <button onClick={this.handleFollowUnfollow} data-test-id={"follow-button"} className={classes.btn}>{is_followed_by_login_user ? "Unfollow" : "Follow"}</button>}
          </Box>
        </>}
      </>
    )
  }

  renderImageSection = () => {
    const { profile_picture, background_image } = this.state.data.attributes
    const {classes, isCommunity,isOwnProfile} = this.props
    return(
      <Box height={"260px"}>
        <Box position={"relative"} sx={{ width: "100%", height: "180px" }}>
          {background_image && <img className={classes.backgroundImg} src={background_image} />}
          {isOwnProfile && <>
          <Box component={"span"}  className={classes.backgroudEdit}>
            <img src={EditIcon} className={classes.imgEdit} alt="EditIcon" />
          </Box>
            <input type="file" data-testid="backgroud-edit" accept="image/png, image/jpg, image/jpeg," className={classes.inputImg} onChange={this.backgroundUpdate} />
            </>}
          <Avatar src={profile_picture} className={isCommunity ? classes.communityPic : classes.profilPic}></Avatar>
          {isOwnProfile&&<Box component={"span"} data-testid="profile-edit" onClick={this.navigateEditPage} className={classes.profileEdit}><img src={EditIcon} alt="EditIcon"  className={classes.imgEdit}/></Box>}
        </Box>
      </Box>
    )
  }

  render() {
    const { classes, isOwnProfile, isCommunity, hideBorder } = this.props;
  
    return (<>
    {this.state.loading && <Loader loading={this.state.loading}/>}
      <Box component={"section"} marginBottom={"16px"} className={classes.mainWrapper} sx={{border: hideBorder ? "" : "1px solid #808080", borderRadius: "12px"}} bgcolor={"#fff"}>
        {this.renderImageSection()}
        <Box padding={"24px"}>
        {this.renderDetails()}
        {(!isOwnProfile && !isCommunity && !this.state.hide_Request_forcommunity) && this.renderRequestSection()}
        </Box>
      </Box>
     {this.state.is_private && <Box component={"section"} height={"480px"} marginBottom={"16px"} sx={{border: "1px solid #808080", borderRadius: "12px"}} bgcolor={"#fff"}>
        <Box p={"24px"} borderBottom={"1px solid #808080"} display={"flex"}>
          <Box sx={{borderRadius:"50%",border:"1px solid #808080",height:"39px",width:"39px"}} display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <img src={Lock} alt="alt"/>
          </Box>
          <Box ml={"16px"}>
          <Box component="h4" fontFamily={"Verdana"} fontWeight={"bold"} m={0} fontSize={"17px"}>
          {configJSON.private_title}
          </Box>
          <Box component={"p"} fontFamily={"Verdana"} color={"#808080"} m={0} mt={"8px"} fontSize={"16px"}>
          {configJSON.private_message}
          </Box>
          </Box>
        </Box>
      </Box>}
      </>);
  }
}

export default withStyles(UserProfilePageStyles, { withTheme: true })(UserProfileWallpaperSection);
// Customizable Area End