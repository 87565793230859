// Customizable Area Start
import React from "react";
import { TextField , InputAdornment } from '@material-ui/core';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CustomTextFieldController, { Props } from "./CustomTextFieldController.web";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
    createStyles({
        textField:{
            borderRadius: "12px",
            border: "solid 2px #f4f4f4",
            padding: "18px 0px",
            position:"relative"
        },
        textfieldInput:{
          fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
          fontWeight: "normal",
          paddingLeft: "5px",
        },
        errorBorder:{
          border: "1px solid red",
        },
        inputAdornment:{
          paddingLeft:"15px"
        },
        textFieldFont:{
            fontSize: "16px" 
        },
    })

// Customizable Area End

export class CustomTextField extends CustomTextFieldController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {

        // Customizable Area Start
        const { classes , fieldName, fieldError , fieldValue , fieldId , fieldType,  startAdornmentIcon , placeHolderText , isDisabled , helperText} = this.props
        return (
            <TextField
            id={fieldId}
            style={{width:"100%"}}
            placeholder={`${placeHolderText}`}
            name={`${fieldName}`}
            className={fieldError ? `${classes.textField} ${classes.errorBorder}` : `${classes.textField}`}
            disabled={isDisabled}
            type={fieldType}
            value={fieldValue}
            onChange={this.props.handleChange}
            error={fieldError}
            helperText={helperText}
            InputProps={{
                startAdornment: (
                    <InputAdornment className={classes.inputAdornment} position="start">
                    {startAdornmentIcon}
                    </InputAdornment>
                ),
                disableUnderline:true
            }}
            inputProps={{
                className:`${classes.textFieldInput} ${classes.textFieldFont}`
            }}
        />
        );
        // Customizable Area End
    }

}


// Customizable Area Start

export default withStyles(styles, { withTheme: true })(CustomTextField);
// Customizable Area End