import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/utils";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  Dataa?:any;
  communityFollow? : boolean;
  resetData?: () => void;
  communityFlag?: boolean;
  communityData?: unknown;
  followUpdatedCommunityTab?: (type: string, id: string, action: string) =>void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  postData: {
    [key: string]: any;
  };
  loading: boolean;
  postId: string;
  modal : {
    hyperLinkModal: boolean;
    videoModal: string;
    editModal: boolean;
    textModal: boolean;
  };
  info: {
    name:string;
    image: string;
    type: string;
    is_private: boolean;
  },
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SinglePostController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  singlePostApi: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      postData: {},
      loading: false,
      postId: "",
      modal : {
        hyperLinkModal: false,
        videoModal: '',
        editModal: false,
        textModal: false,
      },
      info: {
        name:"",
        image: '',
        is_private: false,
        type: '',
      },
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const digitsOnly = this.props.navigation.getParam("navigationBarTitleText").replace(
      /\D/g,
      ""
    );
    this.setState({ loading: true, postId: digitsOnly });
    this.singlePostDataChange(digitsOnly);
    const data = await getStorageData('profileData',true);
    if(data){
      this.setState({info: {name: data.data.attributes.full_name,image:data.data.attributes.profile_picture,type:data.data.type,is_private:data.data.attributes.is_private}})
    }
  }

  async receive(_from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId2 = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson1 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId2 && responseJson1) {
      if (apiRequestCallId2 === this.singlePostApi) {
        this.setState({ loading: false });
        if (!responseJson1.errors) {
          this.responseSinglePost(responseJson1);
        } else {
          for (let key in responseJson1.errors[0]) {
            toast.error(responseJson1.errors[0][key]);
            this.props.navigation.goBack()
            this.setState({postData:{}})
          }
        }
      }
    }
  }

  responseSinglePost = (responseJson: { data: { [key: string]: any } }) => {
    this.setState({ postData: responseJson.data });
  };

  singlePostDataChange = async (id: string) => {

    const signupToken1 = await getStorageData("SignupSuccessToken");
    const loginToken2 = await getStorageData("LoginSuccessToken");

    this.singlePostApi = sendAPIRequest(`${configJSON.postsEnd}${id}`, {
      method: configJSON.apiMethod,
      headers: {
        token: signupToken1 || loginToken2
      }
    });
  };

  editedPost=(id:string)=>{
    const isVideo = this.state.postData.attributes.post_type === 'Vimeo' || this.state.postData.attributes.post_type ==='YouTube';
    const editModal = this.state.postData.attributes.post_type === 'image';
    const textModal = this.state.postData.attributes.post_type === 'text';
    this.setState({modal: {hyperLinkModal: this.state.postData.attributes.post_type === 'url',textModal: textModal,videoModal : isVideo ?this.state.postData.attributes.post_type:'',editModal: editModal },postId: this.state.postData.id})
  }

  getPostList = () => {
    this.singlePostDataChange(this.state.postId);
    if(this.props.resetData){
      this.props.resetData();
    }
  }

  closeModal = () => {
    this.setState({ modal: { hyperLinkModal: false, videoModal: '', editModal: false, textModal: false } })
  }

  handleGetPostItemSrc = (item: { [key: string]: any }) => {
    let src: string = "";
    if (item.attributes) {
      if (item.attributes.images_and_videos.length > 0) {
        return item.attributes.images_and_videos;
      }
      if (item.attributes.thumbnail) {
        return item.attributes.thumbnail;
      }
      if (item.attributes.cover_image) {
        return item.attributes.cover_image;
      }
    }
    return src;
  };

  handleGetPostItemUserProfilePic = (item: { [key: string]: any }) => {
    if (item.attributes) {
      if (item.attributes.profile_picture) {
        return item.attributes.profile_picture;
      }
      if (item.attributes.community_profile) {
        return item.attributes.community_profile;
      }
    }
    return "";
  };
  // Customizable Area End
}
