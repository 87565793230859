// Customizable Area Start
import React from "react";
import {
  Box, Avatar, Grid,Button
} from "@material-ui/core";
import UserProfileInformationEditController, {
  Props, configJSON
} from "./UserProfileInformationEditController.web";
import { styled } from "@material-ui/core/styles";
import moment from "moment";
import { NoUser, Camera ,Gender,Trash,AddUser,phone,Link, Add, Edit, Pronouns,translate,calendar,location,clock,Email, smallUser, job, group, user2} from "./assets";
import AddSocialMediaLinkModal from "./AddSocialMediaLinkModal.web";
import Loader from "../../../components/src/Loader.web";
import CustomisableUserProfiles from "./CustomisableUserProfiles.web";
// Customizable Area End

export default class UserProfileInformationEdit extends UserProfileInformationEditController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { profile_picture, gender, state, city, country, dob, full_name , full_phone_number, ethnicity, pronouns, race, job_title,email, language , timezone, social_media_urls} = this.state.data
    // Customizable Area End
    return (
      // Customizable Area Start
      <MainWrapper component={"section"} display={"flex"}>
        {this.state.isLoading && <Loader loading={this.state.isLoading}/>}
        {this.state.socialModal&&<AddSocialMediaLinkModal data={this.state.selectedSocial} navigation={this.props.navigation} handleClose={this.closeSocialMoodal} id={"social-media"} open={this.state.socialModal}/>}
        {this.state.communityModal&&<CustomisableUserProfiles open={this.state.communityModal} navigation={this.props.navigation} handleClose={this.closeCommunityModal} id={"community-media"} type={"profile"}/>}
        <Box bgcolor={"#fff"} borderRadius={"12px"} p={"24px"} sx={{ width: { xs: "auto", md: "70%", lg: "60%" }, marginX: { xs: "32px", md: "auto" } }} my={"32px"}>
          <Box component={"h4"} color="#005487" fontFamily={"Verdana"} fontWeight={"bold"} fontSize={"22px"} m={0}>User Information</Box>
          <Box display={"flex"} alignItems={"center"} m={"20px"} justifyContent={"center"} >
            <Box display="flex" alignItems="center" justifyContent="center" position={"relative"} width="fit-content" sx={{
              width: "75px",
              height: "75px", border: "1px solid #c4c4c4", borderRadius: "50%"
            }} >
              {!profile_picture ? (<img src={NoUser} alt="no user" className="user-img" />) :
                <Avatar src={profile_picture} className="avatar-img" />
              }
              <Box position={"absolute"} bottom="0px" right="0px" bgcolor={"#fff"} width={"16px"} height="15px" borderRadius={"50%"} border={"1px solid #808080"} padding={"5px"}>
                <img src={Camera} alt="camera" className="camera-icon" />
                <input
                  accept="image/jpeg, image/jpg, image/png"
                  type="file"
                  data-test-id="input-img-id"
                  className="input-img"
                  onChange={this.uploadProfileImage}
                />
                <Box />
              </Box>
            </Box>
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} alignItems={"start"} mb="24px">
            <img src={smallUser} alt="smalluser" />
            <Box display={"flex"} flexDirection={"column"} width={"100%"} ml="17px">
              <Box component={"label"} display={"block"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Name</Box>
              <Box component="p" fontFamily={"Verdana"} fontSize={"16px"} m={0} mt={"9px"} mb={"24px"} color="#808080">{full_name}</Box>
            </Box>
            <img src={Edit} alt="Edit" className="pointer" onClick={this.navigateEditSection} />
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} alignItems={"start"} mb="24px">
            <img src={job} alt="job" />
            <Box display={"flex"} flexDirection={"column"} width={"100%"} ml="17px">
              <Box component={"label"} display={"block"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Job Title</Box>
              <Box component="p" fontFamily={"Verdana"} fontSize={"16px"} m={0} mt={"9px"} mb={"24px"} color="#808080">{job_title}</Box>
            </Box>
            <img src={Edit} alt="Edit" data-testid="edit-icon" className="pointer" onClick={this.navigateEditSection} />
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} alignItems={"start"} mb="24px">
            <img src={Email} alt="email" />
            <Box display={"flex"} flexDirection={"column"} width={"100%"} ml="17px">
              <Box component={"label"} display={"block"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>E-mail ID</Box>
              <Box component="p" fontFamily={"Verdana"} data-test-id="email" fontSize={"16px"} m={0} mt={"9px"} mb={"24px"} color="#808080">{email}</Box>
            </Box>
            <img src={Edit} alt="Edit" className="pointer" onClick={this.navigateEditSection} />
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} alignItems={"start"} mb="24px">
            <img src={phone} alt="phone" />
            <Box display={"flex"} flexDirection={"column"} width={"100%"} ml="17px">
              <Box component={"label"} display={"block"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Phone Number</Box>
              <Box component="p" fontFamily={"Verdana"} fontSize={"16px"} m={0} mt={"9px"} mb={"24px"} color="#808080">{full_phone_number}</Box>
            </Box>
            <img src={Edit} alt="Edit" className="pointer" onClick={this.navigateEditSection} />
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} alignItems={"start"} mb="24px">
            <img src={Pronouns} alt="Pronouns" />
            <Box display={"flex"} flexDirection={"column"} width={"100%"} ml="17px">
              <Box component={"label"} display={"block"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Pronouns</Box>
              <Box component="p" fontFamily={"Verdana"} fontSize={"16px"} m={0} mt={"9px"} mb={"24px"} color="#808080">{pronouns}</Box>
            </Box>
            <img src={Edit} alt="Edit" className="pointer" onClick={this.navigateEditSection} />
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} alignItems={"start"} mb="24px">
            <img src={calendar} alt="calendar" />
            <Box display={"flex"} flexDirection={"column"} width={"100%"} ml="17px">
              <Box component={"label"} display={"block"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>DOB(Date of Birth)</Box>
              <Box component="p" fontFamily={"Verdana"} fontSize={"16px"} m={0} mt={"9px"} mb={"24px"} color="#808080">{dob ? moment(dob, "MM/DD/YYYY").format("MMMM DD, YYYY"): '-'}</Box>
            </Box>
            <img src={Edit} alt="Edit" className="pointer" onClick={this.navigateEditSection} />
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} alignItems={"start"} mb="24px">
            <img src={translate} alt="translate" />
            <Box display={"flex"} flexDirection={"column"} width={"100%"} ml="17px">
              <Box component={"label"} display={"block"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Language</Box>
              <Box component="p" fontFamily={"Verdana"} fontSize={"16px"} m={0} mt={"9px"} mb={"24px"} color="#808080">{language.join(', ')}</Box>
            </Box>
            <img src={Edit} alt="Edit" className="pointer" onClick={this.navigateEditSection} />
          </Box>
          <Box borderBottom={"1px solid #808080"} mb="24px">
            <Box display={"flex"} justifyContent={"space-between"} mb="24px">
              <Box component={"label"} display={"block"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Demographics</Box>
              <img src={Edit} alt="Edit" className="pointer" onClick={this.navigateEditSection} />
            </Box>
            <Grid container>
              <Grid item xs={8} sm={4} md={3} component={Box} display={"flex"} sx={{ alignItems: "start" }}>
                <img src={group} alt="group" />
                <Box display={"flex"} flexDirection={"column"} width={"100%"} ml="17px">
                  <Box component={"label"} display={"block"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Race</Box>
                  <Box component="p" fontFamily={"Verdana"} fontSize={"16px"} m={0} mt={"9px"} mb={"24px"} color="#808080">{race}</Box>
                </Box>
              </Grid>
              <Grid item xs={8} sm={4} md={3} component={Box} display={"flex"} sx={{ alignItems: "start" }}>
                <img src={user2} alt="user" />
                <Box display={"flex"} flexDirection={"column"} width={"100%"} ml="17px">
                  <Box component={"label"} display={"block"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Ethnicity</Box>
                  <Box component="p" fontFamily={"Verdana"} fontSize={"16px"} m={0} mt={"9px"} mb={"24px"} color="#808080">{ethnicity}</Box>
                </Box>
              </Grid>
              <Grid item xs={8} sm={4} md={3} component={Box} display={"flex"} sx={{ alignItems: "start" }}>
                <img src={Gender} alt="gender" />
                <Box display={"flex"} flexDirection={"column"} width={"100%"} ml="17px">
                  <Box component={"label"} display={"block"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Gender</Box>
                  <Box component="p" fontFamily={"Verdana"} fontSize={"16px"} m={0} mt={"9px"} mb={"24px"} color="#808080">{gender}</Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} alignItems={"start"} mb="24px">
            <img src={location} alt="location" />
            <Box display={"flex"} flexDirection={"column"} width={"100%"} ml="17px">
              <Box component={"label"} display={"block"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>City</Box>
              <Box component="p" fontFamily={"Verdana"} fontSize={"16px"} m={0} mt={"9px"} mb={"24px"} color="#808080">{city}</Box>
            </Box>
            <img src={Edit} alt="Edit" className="pointer" onClick={this.navigateEditSection} />
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} alignItems={"start"} mb="24px">
            <img src={location} alt="location" />
            <Box display={"flex"} flexDirection={"column"} width={"100%"} ml="17px">
              <Box component={"label"} display={"block"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>State</Box>
              <Box component="p" fontFamily={"Verdana"} fontSize={"16px"} m={0} mt={"9px"} mb={"24px"} color="#808080">{state}</Box>
            </Box>
            <img src={Edit} alt="Edit" className="pointer" onClick={this.navigateEditSection} />
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} alignItems={"start"} mb="24px">
            <img src={location} alt="location" />
            <Box display={"flex"} flexDirection={"column"} width={"100%"} ml="17px">
              <Box component={"label"} display={"block"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Country</Box>
              <Box component="p" fontFamily={"Verdana"} fontSize={"16px"} m={0} mt={"9px"} mb={"24px"} color="#808080">{country}</Box>
            </Box>
            <img src={Edit} alt="Edit" className="pointer" onClick={this.navigateEditSection} />
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} alignItems={"start"} mb="24px">
            <img src={clock} alt="clock" />
            <Box display={"flex"} flexDirection={"column"} width={"100%"} ml="17px">
              <Box component={"label"} display={"block"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Display Times in</Box>
              <Box component="p" fontFamily={"Verdana"} fontSize={"16px"} m={0} mt={"9px"} mb={"24px"} color="#808080">{timezone}</Box>
            </Box>
            <img src={Edit} alt="Edit" className="pointer" onClick={this.navigateEditSection} />
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} alignItems={"start"} pb="24px">
            <img src={Link} alt="link" />
            <Box display={"flex"} flexDirection={"column"} width={"100%"} ml="17px">
              <Box component={"label"} display={"block"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Connect Social Media</Box>
              {social_media_urls.map(item=><Box key={item.id} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <Box component="p" fontFamily={"Verdana"} fontSize={"16px"} m={0} mt={"9px"} color="#808080">{item.url}</Box>
              <Box display={"flex"}><img src={Trash} alt="trash" className="edit-delete" data-testid="delete-social" onClick={()=>this.deleteSocial(item.id)}/><img src={Edit} className="edit-delete" alt="edit" data-testid="edit-social" onClick={()=>this.socialEdit(item.id)}/></Box>
              </Box>
              )}           
               </Box>
            <img src={Add} alt="Add" className="pointer" data-test-id="add-social" onClick={this.openSocialModal} />
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} alignItems={"start"} pb="24px" mt="20px">
            <img src={AddUser} alt="adduser" />
            <Box display={"flex"} flexDirection={"column"} width={"100%"} ml="17px">
              <Box component={"label"} display={"block"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Communities</Box>
              {this.state.selectedCommunity.map(item => <Box key={item.id} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Box component="p" fontFamily={"Verdana"} fontSize={"16px"} m={0} mt={"9px"} color="#808080">{item.name}</Box>
              </Box>
              )}
            </Box>
            <img src={Add} alt="Add" className="pointer" onClick={this.openCommunityModal} />
          </Box>
          <Box component="p" fontFamily={"Verdana"} data-test-id="org-message" textAlign={"center"} className="pointer" fontSize={"14px"} color="#005487" onClick={this.orgNavigation}>{configJSON.organization_request}</Box>
          <Box sx={{ width: { xs: "80%", md: "65%", lg: "60%" } }} margin={"auto"}>
            <Button className="done-btn" data-test-id="done-btn" onClick={this.doneBtnNavigation}>Done</Button>
          </Box>
        </Box>
      </MainWrapper>
      // Customizable Area End
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const MainWrapper = styled(Box)({
  "& .pointer": {
    cursor: "pointer"
  },
  "& .done-btn": {
    backgroundColor: "#005487",
    width:"100%",
    fontSize:"16px",
    fontWeight:"bold",
    color: "#fff",
    textTransform: "none",
    height:"56px",
    fontFamily: "Verdana"
  },
  "& .input-img": {
    right: "0px",
    position: "absolute",
    bottom: "0px",
    height: "70px",
    cursor: "pointer",
    width: "70px",
    opacity: 0
  },
  "& .user-img": {
    width: "80%",
    height: "80%"
  },
  "& .camera-icon": {
    height: "100%",
    width: "100%",
  },
  "& .avatar-img": {
    width: "75px",
    objectFit: "contain",
    height: "75px",
    borderRadius: "50%"
  },
  "& .edit-delete":{
    cursor: "pointer",
    width: "20px",
    height: "20px",
    marginLeft:"5px"
  }
})
// Customizable Area End
