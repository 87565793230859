import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { sendAPIRequest } from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
// Customizable Area Start
import { toast } from "react-toastify";
export interface ConversationApiType {
    chat_id: string,
    conversation_sid: string,
    group_icon: string | null,
    group_name: string,
    unique_name: string,
    chat_type: string,
    last_message: string,
    receipient_data: {
        data:
        {
            type: string,
            id: string,
            attributes: {
                receipient_name: string,
                twilio_identity: string,
                receipient_email: string,
                receipient_profile_pic: string
            }
        }[]
    },
    last_message_time: string,
    name: string,
    unread_messages_count: number,
    email: string,
    twilio_identity: string,
    notification_level: string,
    profile_pic: string,
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    id: string;
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    menuItems: { id: number, value: string, label: string }[];
    fieldValue: string;
    error: string;
    chatId: string
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ReportController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    reportConversationId: string = "";
    fetchConversationsId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            fieldValue: "",
            error: "",
            menuItems: [
                {
                    id: 1,
                    label: "Voilence",
                    value: "Voilence",
                },
                {
                    id: 2,
                    label: "Harassment",
                    value: "Harassment",
                },
                {
                    id: 3,
                    label: "False Information",
                    value: "False Information",
                },
                {
                    id: 4,
                    label: "Spam",
                    value: "Spam",
                },
                {
                    id: 5,
                    label: "none",
                    value: "none",
                },
            ],
            chatId: "",
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId1 = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if(apiRequestCallId1 === this.fetchConversationsId) return this.handleConversationResponse(responseJson)
            if ((apiRequestCallId1 === this.reportConversationId)&& responseJson) {
                this.setState({ loading: false });
                if(responseJson.data?.message){
                    toast.success(responseJson.data.message)
                }
                const msg = new Message(getName(MessageEnum.NavigationChatMessage));
                msg.addData(
                getName(MessageEnum.NavigationPropsMessage),
                this.props
                );
                this.send(msg)
            }
        }
    }

    async componentDidMount(): Promise<void> {
        super.componentDidMount();
        this.getChatInfo();
    }

    handleConversationResponse = (responseJson: {
        conversations: ConversationApiType
      }) => {
        this.setState({ loading: false, chatId: responseJson.conversations.chat_id })
      }

    getChatInfo = async () => {
        const signupToken = await getStorageData('SignupSuccessToken');
        const loginToken = await getStorageData('LoginSuccessToken')
        const sameHeader: { method: "GET", headers: { "Content-Type": string, token: string } } = {
          method: configJSON.getApiMethod,
          headers: {
              token: signupToken || loginToken,
            "Content-Type": configJSON.apiContentType,
          },
        }
        const sid = this.props.navigation.getParam("navigationBarTitleText")
        this.fetchConversationsId = sendAPIRequest(configJSON.fetchSingleConversation+sid, sameHeader);
        this.setState({loading:true});
    }

    renderValue = (selected: string) => {
        if (!selected || selected.length === 0) {
            return "Select";
        }
        return selected;
    };

    handleChangeDropdownValue = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = e.target;
        this.setState({ fieldValue: value, error: " " });
    };

    handleBack = () => {
        const sid = this.props.navigation.getParam("navigationBarTitleText")
        const msg1 = new Message(getName(MessageEnum.NavigationChatViewMessage));
        msg1.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );
        msg1.addData(
          getName(MessageEnum.UrlPathParamMessage),
          sid
        );
        this.send(msg1)
    };

    handleSubmit = async () => {
        if (!this.state.fieldValue) return this.setState({ error: "select reason" });
        this.setState({ loading: true });
        const loginToken = await getStorageData('LoginSuccessToken');
        const signupToken = await getStorageData('SignupSuccessToken');
        let body = new FormData();
        body.append("chat_id", this.state.chatId);
        body.append("report_reason", this.state.fieldValue);

        this.reportConversationId = sendAPIRequest(configJSON.reportConversation, {
            method: configJSON.postApiMethod,
            body,
            headers: {
                token: signupToken || loginToken
            }
        });
    };
    // Customizable Area End
}
