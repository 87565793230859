import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/utils";
// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  id: string;
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentTab: string;
  typedMessage: string;
  typedMessageError: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  postContactUsId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      currentTab: "help-1",
      typedMessage: "",
      typedMessageError: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.postContactUsId) {
         this.apiResponsContactUs(responseJson)
        }
      }
    }
  }

  apiResponsContactUs=(responseJson:{data: [
    {
        message: string
    }
],errors:[{[key:string]:string}]})=>{
    if (!responseJson.errors) {
      toast.success(responseJson.data[0].message);
      this.setState({
        typedMessage: ""
      });
    } else {
      for (let key in responseJson.errors[0]) {
        const temp = responseJson.errors[0];
        toast.error(temp[key]);
      }
    }
  }

  menuChanged = (e: string) => {
    this.setState({ currentTab: e });
  };

  handleChangeMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ typedMessage: e.target.value, typedMessageError: "" });
  };

  handleSubmitContactUs = async () => {
    if (!this.state.typedMessage) {
      return this.setState({
        typedMessageError: "message field cannot be empty."
      });
    }
    const signupToken1 = await getStorageData("SignupSuccessToken");
    const loginToken1 = await getStorageData("LoginSuccessToken");
    const body = {
      data: {
        attributes: {
          description: this.state.typedMessage
        }
      }
    };
    this.postContactUsId = sendAPIRequest(configJSON.contactApi, {
      method: configJSON.post,
      body: body,
      headers: {
        token: signupToken1 || loginToken1,
        "Content-Type": "application/json"
      }
    });
  };

  openTicket = () => {
    window.open(configJSON.ticket_link,'_blank')
  }

  // Customizable Area End
}
