import React from "react";
// Customizable Area Start
import "./Report.web.css";
import { Box } from '@material-ui/core';
import ReportController, { Props } from "./ReportController.web";
import { tickcircle,tickcirclewhite,messagetext,arrowsquaredown,arrowleft } from "./assets";
export const configJSON = require("./config");
// Customizable Area End


export default class Report extends ReportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDropDownContent = () => {
    return (
      <div className="reportDrop">
        <div className="reportMain-top">
          <img onClick={this.goBackreport}
            src={arrowleft}
            alt="left-arrow"
            className="reportDrop-left-arrow"
          />
          <h2 className="reportDrop-heading">Report</h2>
        </div>
        <div className="reportMain-bottom">
          <h3 className="reportMainBottom-heading">
          Report something that doesn’t look right.
          </h3>
          <p className="reportMainBottom-desc">
          {configJSON.reportContent}
          </p>
          <h4 className="reportMainBottom-reason-heading">
            {this.state.postId?configJSON.reportQuestion:"Why are you deactivaing your account"}
          </h4>
          <div
          className="dropDown-container"
            style={{
              
            }}
          >
            <div className="reportDrop-nav" onClick={this.handleOpenDropDown}>
              <div className="reportDrop-nav-left">
                <img
                  src={messagetext}
                  alt=""
                  className="reportDrop-nav-left-icon"
                />
                <p className="reportDrop-nav-left-text">
                  {this.state.showReason === ""
                    ? "Select"
                    : this.state.showReason}
                </p>
              </div>
              <div className="reportDrop-nav-right">
                <img src={arrowsquaredown} alt="" />
              </div>
            </div>
            {this.state.postError && <Box component="p" color="red">{this.state.postError}</Box>}
            <div className="reportReason-container">
              {this.state.openDropDown && (
                <div className="reportDropDetails">
                  {this.state.dropDownDetails.map(
                    (item: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className={
                            item.active
                              ? "reportDropDetails-item-active reportDropDetails-item"
                              : "reportDropDetails-item"
                          }
                          onClick={() => this.handleDetail(item.title, index)}
                        >
                          <p
                            className={
                              item.active === true
                                ? "reportDropDetails-item-heading-active"
                                : "reportDropDetails-item-heading"
                            }
                          >
                            {item.title}
                          </p>
                          {item.active ? (
                            <img src={tickcirclewhite} />
                          ) : (
                            <img src={tickcircle} alt="" />
                          )}
                        </div>
                      );
                    }
                  )}
                  <div className="reportDropDown-btn-container">
                    <button
                      className={
                        this.state.selectedReason === ""
                          ? "reportDropDown-btn-disable reportDropDown-btn"
                          : "reportDropDown-btn"
                      }
                      onClick={this.handleDone}
                      disabled={this.state.selectedReason === "" ? true : false}
                    >
                      Done
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="report-submit-btn-container" data-test-id="submit_button" onClick={()=>this.reportapi()}>
            <button className="report-submit-btn">Submit</button>
          </div>
        </div>
      </div>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    
    return (
      // Customizable Area Start
    //   <Box width={"100%"} className="main_event_box">
        
    //   </Box>
    <div className="report">
        {this.renderDropDownContent()}
        </div>
      // Customizable Area End
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "300px",
      borderRadius: "12px",
    },
  },
};

// Customizable Area End
