// Customizable Area Start
import React from "react";
import { Box, Button, CircularProgress } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import SwitchAccountController, { Props } from "./SwitchAccountController.web";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

  
  export default class SwitchAccount extends SwitchAccountController {
     constructor(props: Props) {
     super(props);
     // Customizable Area Start
     // Customizable Area End
  }
  // Customizable Area Start
   render() {
     return (
       <ModalWrpper
         open={this.props.open}
         onClose={this.props.handleClose}
         closeAfterTransition
         BackdropProps={{
           timeout: 200
         }}
         BackdropComponent={Backdrop}
       >
         <Fade in={this.props.open}>
           <Box sx={{ width: { xs: "85%", sm: "60%", md: "50%" }, maxWidth: "604px" }} className="fadeContainer" display="flex" alignItems="center" justifyContent="center" >
             <Box bgcolor="#fff" borderRadius="12px" width="100%">
               <Box sx={{ border: "1px solid black", margin: "20px", padding: "20px", borderRadius: "12px " }}>
                 <Box component="h3" data-testid="message-id" color="#005487" fontFamily={"Verdana"} fontWeight={"bold"} fontSize={"17px"} m={0}>
                   {this.rendertitle()}
                 </Box>
                 <Box component="p" color="#808080" fontFamily={"Verdana"} fontSize={"17px"} m={0} mt={"20px"}>
                   {this.state.community ? "If your email is associate with local admin account, then you can switch your community to account profile." : "If your email is associate with local admin account, then you can switch your account to community profile."}
                 </Box>
                 <Box sx={{ display: "flex", alignItems: "end", height: "340px" }}>
                  {this.state.loading?
                    <Box display={"flex"} margin={"auto"}>
                      <CircularProgress />
                    </Box>
                  :
                   <Box sx={{ flexDirection: { xs: "column", md: "row" }, width: "100%" }} alignItems="center" display="flex" justifyContent="space-around">
                     <Button
                       data-test-id="cancel-id"
                       type="button"
                       className="Publishbtn"
                       onClick={this.props.handleClose}
                     >Cancel</Button>
                     <Button
                       className="Publishbtn"
                       data-test-id="confirm-id"
                       type="submit"
                       onClick={this.handleSwitchToken}
                     >{"Switch"}</Button>
                   </Box>
   }
                 </Box>
               </Box>
             </Box>
           </Box>
         </Fade>
       </ModalWrpper>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const ModalWrpper = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  "& .Publishbtn": {
    border: "solid 1px #005487",
    height: "56px",
    maxWidth: "250px",
    fontWeight: "bold",
    color: "#01548a",
    width: "40%",
    backgroundColor: "#fff",
    textTransform: "none",
    marginTop: "8px",
    "&:active": {
      backgroundColor: "#01548a",
      color: "#fff"
    },
  },
});
// Customizable Area End
