import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    classes?: any;
    data: {
        attributes: {
            organization: {
                id: number,
                address: string,
                email: string,
                name: string,
                website: string,
                contact_number: string,
                created_at: string,
                updated_at: string,
                account_id: number,
            }|null,
            is_private:boolean;
        }
    };
    isOwnProfile: boolean;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    data: {}
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
// Customizable Area Start
export default class OrganizationProfileSectionController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiCreatePostCallId: any
    // Customizable Area End
    constructor(props: Props) {
        
        super(props);
        // Customizable Area Start
        
        this.state = {
            data:{}
        }
        // Customizable Area End
    }
 
       
    async receive(_from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start


        // Customizable Area End
    }

    editDetails = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "UserOrganizationInformation");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationScreenNameMessage),
      "view-profile"
    )
    this.send(message);
    }
}
// Customizable Area End