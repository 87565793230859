import React from "react";

import {
  Box,
  Typography,
  // Customizable Area Start
  Card,
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {  styled } from "@material-ui/core/styles";
import { checkListIcon, scoreCardIcon, arrowBack } from "./assets";

import Loader from "../../../components/src/Loader.web";
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Label, Tooltip } from 'recharts';
import CommunityAssessmentDashboardController, {
  Props, configJSON
} from "./CommunityAssessmentDashboardController";
import { handleInvalidData } from "../../../components/src/common";


const RootBox = styled(Grid)({
  position: "relative",
  borderRadius: "12px",
})

const CustomCard = styled('section')({
  borderRadius: "12px",
  marginBottom: "12px",
  backgroundColor: "#fff"
});

const Header = styled((Box))({
  borderBottom: "1px solid #808080",
  padding: "25px 0px 23px 24px",
  display: "flex",
  "& .pointer":{
    cursor: "pointer"
  }
});

const Heading = styled((Typography))({
  fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
  lineHeight: 1.1,
  marginRight: "9px",
  marginLeft: "9px",
  color: "#005487",
  letterSpacing: "-0.41px",
  fontWeight: "bold",
  fontSize: "20px",
})
const ChartHeading = styled((Typography))({
  fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
  lineHeight: 1.1,
  marginTop: "16px",
  letterSpacing: "-0.41px",
  fontWeight: "bold",
  fontSize: "20px",
  textAlign: 'center'
})

const MainBox = styled((Box))({
  paddingLeft: "24px",
  paddingRight: "24px",
  paddingBottom: "24px",
})
const ChartBox = styled((Box))({
  display: "flex",
  justifyContent: "center",
  marginTop: "16px",
  alignItems: "center",
  border: "1px solid #000",
  height: "630px",
  borderRadius: "12px",
  marginBottom: "40px",
  padding: "32px",
  boxSizing: "border-box",
  "@media(max-width:600px)":{
    padding: "16px",
  }
})
const InfoCard = styled((Card))({
  padding: "52px 0px 51px 0px",
  width: "48%",
  maxWidth:"395px",
  flexDirection: "column",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "16px",
  borderRadius: "12px",
  marginTop: "27px",
  boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
})
const InfoCards = styled((Box))({
  display: "flex",
  justifyContent: "space-between",
})

const InfoCardIcon = styled((Box))({
  textAlign: "center",
  "& > svg": {
    color: "#5C92B4",
    height: "70px",
    width: "80px",
  },
});
const InfoCardImg = styled("img")({
  "& > svg": {
    color: "#5C92B4",
    width: "80px",
    height: "80px",
  },
})
const InfoCardText = styled((Typography))({
  fontSize: "20px",
  textTransform: "capitalize",
  fontWeight: "bold",
})

const DateCard = styled(Box)({
  marginTop: "28px",
  minHeight: "350px",
  borderRadius: "12px",
  padding: "24px 50px 24px",
  boxSizing: "border-box",
})
const InfoScoreText = styled("p")({
  fontSize: "24px",
  textAlign: "center",
  margin: 0,
  color:"#005487",
  fontWeight: "bold",
})
const ComplateBox = styled(Box)({
  display: "flex",
  gap: "25px",
  flexDirection: "column",
})

const DateCardList = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "24px"
})

const DateCardColumn = styled(Box)({
  fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
  fontSize: "16px",
  fontWeight: "bold",
  color: "#808080",
})

const DateCardItem = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
})

const LocalAdminDateCard = styled(Card)({
  // minHeight: "317px",
  padding: "30px 0px 40px 0px",
  borderRadius: "12px",
  boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
})

const LocalAdminHeading = styled("h3")({
  margin: 0,
  textTransform: "capitalize",
  fontSize: "20px",
  textAlign: "center",
  fontWeight: "bold",
  fontFamily: "Verdana",
  marginBottom: "24px",
})
// Customizable Area End


export default class CommunityAssessmentDashboard extends CommunityAssessmentDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  CommonChart = () => {
    const chartData = this.state.chartData.length >0 ? this.state.chartData : [{ date: "0", avg_score: 0 }]
    
    return (
      
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={600}
            height={300}
            data={[...chartData]}
            margin={{ top: 0, right: 20, left: 40, bottom: 60 }}
          >
            <Line
              type="linear"
              dot={false}
              dataKey="avg_score"
              stroke="#005586"
              strokeWidth={4}
              connectNulls={true}
            />
            <XAxis
              interval="preserveEnd"
              dataKey="date"
              tickSize={20}
              padding={{ right: 20, left: 30 }}
              style={{
                fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                fontWeight: "bold",
                color: "#000",
                fill: "#000",
              }}
            >
              <Label
                value={this.isIndividualUserDashboard ? "Date of Assessment Test" : "Date of " + this.state.dashboardTitle }
                position="insideBottom"
                style={{
                  fontSize: "20px",
                  fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                  fontWeight: "bold",
                  color: "#005586",
                  fill: "#01548A",
                }}
                dy={60}
              />
            </XAxis>

            <YAxis
              dataKey="avg_score"
              stroke="#808080"
              padding={{ top: 30 }}
              tickSize={10}
              domain={[0, 'auto']}
              style={{
                fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                fontWeight: "bold",
                color: "#000",
                fill: "#000",
              }}
            >
              <Label
                value="Average Scoring"
                angle={-90}
                position="insideLeft"
                dx={-30}
                dy={100}
                style={{
                  fontSize: "20px",
                  fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                  fontWeight: "bold",
                  color: "#005586",
                  fill: "#01548A",
                }}
              />
            </YAxis>
            <Tooltip/>
          </LineChart>
        </ResponsiveContainer>
      
    )
  }

  // Customizable Area End


  render() {
    // Customizable Area Start
    return (
      
        <RootBox xs={12} >
          <Loader loading={this.state.isLoading} />
          <CustomCard >
            <Header >
              <img className="pointer" src={arrowBack} alt="back" data-test-id="back-icon" onClick={this.handleBackNavigation}/>
              <Heading > {handleInvalidData(this.state.dashboardTitle, '-')}</Heading>
            </Header>
            <Box>
              <MainBox >
                <ChartHeading>{this.isIndividualUserDashboard ? this.state.dashboardTitle +"'s scores" : this.state.dashboardTitle}</ChartHeading>
                <ChartBox >
                  {this.CommonChart()}
                </ChartBox>
              {this.isIndividualUserDashboard &&
                <>
                  <InfoCards>
                    <InfoCard>
                      <InfoCardIcon >
                        <InfoCardImg src={scoreCardIcon} alt={'score-icon'} />
                      </InfoCardIcon>
                      <InfoCardText align="center">
                        {configJSON.labelAverageScore}
                      </InfoCardText>
                      <InfoScoreText>
                        <span style={{ color: "#005487" }}>
                          {this.state.userDashboardData.data.avg_score ? this.state.userDashboardData.data.avg_score.substring(0, 4) : 0}
                        </span> / {this.state.userDashboardData.data.total_avg_score.toFixed(2)}
                      </InfoScoreText>
                    </InfoCard>
                    <InfoCard>
                      <InfoCardIcon >
                        <InfoCardImg src={checkListIcon} alt={'checklist-icon'} />
                      </InfoCardIcon>
                      <InfoCardText align="center">
                        {configJSON.labelNumberOfTests}
                      </InfoCardText>
                      <InfoScoreText>
                        <span>
                          {this.state.userDashboardData.data.attempt_quizzes}
                        </span>
                      </InfoScoreText>
                    </InfoCard>
                  </InfoCards>


                  <DateCard sx={{ boxShadow: this.state.userDashboardData.data.quiz_details.length > 0 ? "0 2px 4px 0 rgba(0, 0, 0, 0.25)" : "" }}>
                    <Box
                      component={"p"}
                      fontWeight={"bold"}
                      fontSize={"24px"}
                      fontFamily={"Verdana"}
                      margin={0}
                      textAlign="center"
                    >{configJSON.datesLable}</Box>
                    <Box>
                      <Box
                        component={"p"}
                        fontSize={"17px"}
                        margin={0}
                        fontWeight={"bold"}
                        mt={"20px"}
                        mb={"20px"}
                        fontFamily={"Verdana"}
                      >
                        {configJSON.labelCompleted}
                      </Box>
                      <ComplateBox>{

                        this.state.userDashboardData.data.quiz_details.map((e, i: number) => {
                          return (
                            <DateCardItem key={`key-${e.quiz_name}-${i + 1}`}>
                              <DateCardColumn
                                className={`Heading5 BoldFont LinkEmbedTitle`}
                              >
                                {e.quiz_name}
                              </DateCardColumn>
                              <DateCardColumn
                                className={`Heading5 BoldFont LinkEmbedTitle`}

                              >
                                {e.quiz_date}
                              </DateCardColumn>
                            </DateCardItem>
                          );
                        })
                      }
                      </ComplateBox>
                    </Box>
                  </DateCard>

                </>
              }
              {this.isCommunityDashboard && <Box>
                <LocalAdminDateCard>
                  <LocalAdminHeading >{this.state.dashboardTitle + " Dashboard"}</LocalAdminHeading>
                  <DateCardList sx={{ paddingX: { xs: "40px", lg: "80px" } }}>
                    <Grid component={Box} container>
                      <Grid item xs={4} component={Box} sx={{ fontSize: "17px", fontFamily: "Verdana", fontWeight: "bold", color: "#005487" }} m={0}>
                        {configJSON.usernameLabel}
                      </Grid>
                      <Grid item xs={4} component={Box} textAlign={"center"} sx={{ fontSize: "17px", fontFamily: "Verdana", fontWeight: "bold", color: "#005487" }} m={0}>
                        {configJSON.dateLabel}
                      </Grid>
                      <Grid item xs={4} component={Box} textAlign={"end"} sx={{ fontSize: "17px", fontFamily: "Verdana", fontWeight: "bold", color: "#005487" }} m={0}>
                        {configJSON.scoresLabel}
                      </Grid>
                    </Grid>
                    {this.state.communityData.map((data, i: number) => {
                      return (
                        <Grid component={Box} container key={`key-${data.score}-${i + 0}`}>
                          <Grid item xs={4} component={Box} sx={{ fontSize: "16px",fontFamily: "Verdana", fontWeight: "bold", color: "#808080" }} m={0}>
                            {data.name}
                          </Grid>
                          <Grid item xs={4} component={Box} textAlign={"center"} sx={{ fontSize: "16px", fontFamily: "Verdana", fontWeight: "bold", color: "#808080" }} m={0}>
                            {data.date}
                          </Grid>
                          <Grid item xs={4} component={Box} textAlign={"end"} sx={{ fontSize: "16px", fontFamily: "Verdana", fontWeight: "bold", color: "#808080" }} m={0}>
                            {data.score} / {data.total_score}
                          </Grid>
                        </Grid>
                      );
                    })}
                  </DateCardList>
                </LocalAdminDateCard>
              </Box >}
              </MainBox>
            </Box>

          </CustomCard>
        </RootBox >
      
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
