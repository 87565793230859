// Customizable Area Start
import React from "react";
import SinglePostController from "./SinglePostController.web";
import NewPostBlock from "../../postcreation/src/NewPostBlock.web";
import Loader from "../../../components/src/Loader.web";
import  CreatePostLinkModal  from "../../postcreation/src/CreatePostLinkModal.web";
import VideoTypePostStepTwoWeb from "../../postcreation/src/VideoTypePostStepTwo.web";
import CreatePostFinalModalWeb from "../../postcreation/src/CreatePostFinalModal.web";
import CreatePostMainModal from "../../postcreation/src/CreatePostMainModal.web";
// Customizable Area End

export default class SinglePost extends SinglePostController {
  // Customizable Area Start
  renderModals = () => {
    return(
      <>
        {this.state.modal.hyperLinkModal && <CreatePostLinkModal linkOpen={this.state.modal.hyperLinkModal} getItem={this.getPostList} postId={this.state.postId} navigation={this.props.navigation} id={''} handleClose={this.closeModal} info={this.state.info}/>}
                {this.state.modal.videoModal && <VideoTypePostStepTwoWeb open={Boolean(this.state.modal.videoModal)} type={this.state.modal.videoModal} getItem={this.getPostList} postId={this.state.postId} navigation={this.props.navigation} id={''} handleClose={this.closeModal} info={this.state.info}/>}
                {this.state.modal.editModal && <CreatePostFinalModalWeb finalPost={this.state.modal.editModal} getItem={this.getPostList} postId={this.state.postId} navigation={this.props.navigation} id={''} handleClose={this.closeModal} data={{ images_and_videos: [], description: '' }} selectedFile={null} info={this.state.info}/>}
                {this.state.modal.textModal && <CreatePostMainModal openModal={this.state.modal.textModal} navigation={this.props.navigation} postId={this.state.postId} getItem={this.getPostList} handleClose={this.closeModal} info={this.state.info}/>}
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const item = this.state.postData;
    const src = this.handleGetPostItemSrc(item);
    const profile = this.handleGetPostItemUserProfilePic(item);
    return (
  
      <>
        {this.state.loading && <Loader loading={this.state.loading} />}
        {this.renderModals()}
        {item.attributes && (
          <NewPostBlock
            key={item.id}
            data={item}
            navigation={this.props.navigation}
            videoTitle={item.attributes.video_title}
            getPostList={this.getPostList}
            postCards={{}}
            mediaPost={""}
            postComment={{}}
            id={item.id}
            profile={profile}
            fullName={
              item.attributes.full_name || item.attributes.community_name
            }
            createdAt={item.attributes.created_at}
            description={item.attributes.description}
            url={item.attributes.url}
            src={src}
            itemType={item.type}
            eventDescription={item.attributes.description}
            eventName={item.attributes.event_name}
            startTime={item.attributes.start_time}
            startDate={item.attributes.start_date}
            endTime={item.attributes.end_time}
            liked={item.like}
            eventType={item.attributes.event_type}
            PostId={item.attributes.id}
            postType={item.attributes.post_type || item.type}
            postCommented={item.commentOpen}
            postlikecount={item.attributes.post_like_count}
            messComment={{}}
            editPost={this.editedPost}
            followUpdatedCommunityTab={this.getPostList}
            followUpdatedList={this.getPostList}
            deletePost={this.getPostList}
          />
        )}


      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
