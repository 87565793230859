import React from "react";

// Customizable Area Start
import { Box, Grid, TextField ,Button } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import HelpController, { Props, configJSON } from "./HelpController.web";
import { clipboard, info, message, ticket, infoImg } from "./assets";
// Customizable Area End

export default class Chat extends HelpController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderContactUs = () => {
    return (
      <>
        <Box p={"24px"} borderBottom={"1px solid #f4f4f4"}>
          <Box
            component={"p"}
            m={0}
            color="#005487"
            fontSize={"20px"}
            fontWeight="bold"
          >
            {configJSON.contact_us}
          </Box>
        </Box>
        <Box
          p="20px"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          minHeight="470px"
        >
          <Box>
          <Box
            display="flex"
            flexDirection="column"
            bgcolor="#eeeeee"
            borderRadius="10px"
            justifyContent="space-between"
            p="15px"
            sx={{ minHeight: "160px" }}
          >
            <TextField
              className="message-field"
              placeholder={"Tell us how we can help ?"}
              data-test-id="message-id"
              type="text"
              size="medium"
              name="message"
              onChange={this.handleChangeMessage}
              value={this.state.typedMessage}
              InputProps={{
                disableUnderline: true
              }}
              multiline
            />
          </Box>
          <Box component="p" color="red">
            {this.state.typedMessageError}
          </Box>
          </Box>
          <Box component="p" color="#808080">
            {configJSON.contact_message}
          </Box>
        </Box>
        <Box borderTop="2px solid #f4f4f4" p="20px">
          <Box sx={{ width: { xs: "80%", md: "65%" }, margin: "auto" }}>
          <Button className="Publishbtn" data-test-id="submit-btn" onClick={this.handleSubmitContactUs}>{configJSON.done_btn}</Button>
          </Box>
        </Box>
      </>
    );
  };

  renderTerms = () => {
    return (
      <>
        <Box p={"24px"} borderBottom={"1px solid #f4f4f4"}>
          <Box
            component={"p"}
            m={0}
            color="#005487"
            fontSize={"20px"}
            fontWeight="bold"
          >
            {configJSON.termsTitle}
          </Box>
        </Box>
        <Box p="24px">
          <Box component={"p"}>
            1.By accessing this app you are agreeing to be bound by these
            website Terms and Conditions of use, all applicable laws and
            regulations, and agree that you are responsible for compliance with
            any applicable local laws. If you do not agree with any of these
            terms, you are prohibited from using or accessing this website. This
            materials contained in this website are protected by applicable
            copyright and trade mark law.
          </Box>
          <Box component={"p"}>
            2.By accessing this app you are agreeing to be bound by these
            website Terms and Conditions of use, all applicable laws and
            regulations, and agree that you are responsible for compliance with
            any applicable local laws. If you do not agree with any of these
            terms, you are prohibited from using or accessing this website. This
            materials contained in this website are protected by applicable
            copyright and trade mark law.
          </Box>
          <Box component={"p"}>
            3.By accessing this app you are agreeing to be bound by these
            website Terms and Conditions of use, all applicable laws and
            regulations, and agree that you are responsible for compliance with
            any applicable local laws. If you do not agree with any of these
            terms, you are prohibited from using or accessing this website. This
            materials contained in this website are protected by applicable
            copyright and trade mark law.
          </Box>
          <Box component={"p"}>
            4.By accessing this app you are agreeing to be bound by these
            website Terms and Conditions of use, all applicable laws and
            regulations, and agree that you are responsible for compliance with
            any applicable local laws. If you do not agree with any of these
            terms, you are prohibited from using or accessing this website. This
            materials contained in this website are protected by applicable
            copyright and trade mark law.
          </Box>
        </Box>
      </>
    );
  };

  renderWebsiteInfo = () => {
    return (
      <>
        <Box height="410px" borderRadius={"12px"} overflow={"hidden"}>
          <img src={infoImg} alt="image" className="img" />
        </Box>
      </>
    );
  };
  renderBody = () => {
    const { currentTab } = this.state;
    if (currentTab === "help-1") {
      return this.renderContactUs();
    }
    if (currentTab === "help-2") {
      return this.renderTerms();
    }
    if (currentTab === "help-3") {
      return this.renderWebsiteInfo();
    }
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainWrapper sx={{width:{xs: "100%" ,md: "100%", xl: "70%"}, paddingX: {xs:"32px",md:"100px",xl:"0px"},margin:{xs:0,xl:'auto'}, boxSizing: "border-box"}}>
        <Grid component={Box} container className="help-container" >
          <Grid item xs={12} md={3} component={Box}>
            <Box
              border="2px solid #808080"
              bgcolor="#f4f4f4"
              borderRadius="12px"
              fontFamily="Verdana, Geneva, Tahoma, sans-serif"
            >
              <Box
                component="p"
                sx={{ fontWeight: "bold", fontSize: "20px" }}
                p={"27px"}
                m={0}
                data-test-id="title-page"
              >
                {configJSON.help}
              </Box>
              <Box
                borderRadius="12px"
                bgcolor="#fff"
                p="20px"
                display="flex"
                alignItems="center"
                mt={"2px"}
                className={`pointer ${(this.state.currentTab==="help-1")&&"active-tab"}`}
                data-test-id="help-1"
                onClick={() => this.menuChanged("help-1")}
              >
                <img src={message} alt="message" />
                <Box
                  component="p"
                  sx={{ fontSize: "17px" }}
                  m={0}
                  color="#808080"
                  ml={"18px"}
                >
                  {configJSON.contact_us}
                </Box>
              </Box>
              <Box
                borderRadius="12px"
                bgcolor="#fff"
                p="20px"
                display="flex"
                alignItems="center"
                mt={"2px"}
                className={`pointer ${(this.state.currentTab==="help-2")&&"active-tab"}`}
                data-test-id="help-2"
                onClick={() => this.menuChanged("help-2")}
              >
                <img src={clipboard} alt="clipboard" />
                <Box
                  component="p"
                  sx={{ fontSize: "17px" }}
                  color="#808080"
                  m={0}
                  ml={"18px"}
                >
                  {configJSON.terms_policy}
                </Box>
              </Box>
              <Box
                borderRadius="12px"
                bgcolor="#fff"
                p="20px"
                display="flex"
                alignItems="center"
                mt={"2px"}
                className={`pointer ${(this.state.currentTab==="help-3")&&"active-tab"}`}
                data-test-id="help-3"
                onClick={() => this.menuChanged("help-3")}
              >
                <img src={info} alt="info" />
                <Box
                  sx={{ fontSize: "17px" }}
                  component="p"
                  color="#808080"
                  m={0}
                  ml={"18px"}
                >
                  {configJSON.web_info}
                </Box>
              </Box>
              <Box
                borderRadius="12px"
                bgcolor="#fff"
                p="20px"
                display="flex"
                alignItems="center"
                mt={"2px"}
                data-test-id="help-4"
              >
                <img src={ticket} alt="ticket" />
                <Box
                  component="p"
                  sx={{ fontSize: "17px" }}
                  color="#005487"
                  m={0}
                  className="pointer textDecoration"
                  ml={"18px"}
                  onClick={this.openTicket}
                  data-test-id = "ticket-id"
                >
                  {configJSON.ticket_system}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={9} component={Box}>
            <Box
              borderRadius="12px"
              bgcolor="#fff"
              fontFamily="Verdana, Geneva, Tahoma, sans-serif"
            >
              {this.renderBody()}
            </Box>
          </Grid>
        </Grid>
      </MainWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
  "& .help-container":{
    gridGap: "32px",
    "@media(min-width:960px)":{
      flexWrap: 'unset',
    }
  },
  "& .pointer": {
    cursor: "pointer"
  },
  "& .message-field":{
    width:"100%"
  },
  "& .img": {
    width: "100%",
    height: "100%"
  },
  "& .active-tab":{
    border:"1px solid #005487"
  },
  "& .Publishbtn": {
    height: "50px",
    backgroundColor: "#01548a",
    color: "#fff",
    border: "solid 1px #005487",
    width: "100%"
  },
  "& .textDecoration":{
    textDecoration: "underLine"
  },
});
// Customizable Area End
