import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IPreviewModalProps } from "./AchievementSectionController.web";
// Customizable Area Start

// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  onClose: () => void;
  open: boolean;
  previewData: IPreviewModalProps;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: {};
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
export default class CommonPreviewModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    /*istanbul ignore next */
    super(props);
    // Customizable Area Start
    /*istanbul ignore next */
    this.state = {
      data: {}
    };
    // Customizable Area End
  }

  /*istanbul ignore next */
  async receive(_from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    console.log(apiRequestCallId);
    // Customizable Area End
  }

  // Customizable Area Start

  isPdfFile = () => {
    return this.props.previewData.mimeType === "pdf";
  };

  isImageFile = () => {
    const mimeTypes = ["jpg", "jpeg", "png"];
    return mimeTypes.includes(String(this.props.previewData.mimeType));
  };
  isNotSupportedFile = () => {
    const supportedType = ["jpg", "jpeg", "png", "doc", "pdf", "docx"];
    return !supportedType.includes(String(this.props.previewData.mimeType));
  };
  isDocFile = () => {
    return ["doc", "docx"].includes(String(this.props.previewData.mimeType));
  };

  // Customizable Area End
}
// Customizable Area End
