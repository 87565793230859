// Customizable Area Start
import React from "react";
import {
  Box, Avatar, TextField, InputAdornment, Button } from "@material-ui/core";
import UserProfileInformationController, {
  Props,
} from "./UserProfileInformationController.web";
import { NoUser, Camera , Dropdown, Pronouns,translate,phone,calendar,location,clock, Gender, user2, group, smallUser, job, Email, } from "./assets";
import { styled } from "@material-ui/core/styles";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import CustomDatepicker from "../../../components/src/CustomDatepicker.web";
import CustomMultipleSelectDropdown from "../../../components/src/CustomMultipleSelectDropdown.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

export default class UserProfileInformation extends UserProfileInformationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {profile_picture,email,full_name,pronouns,full_phone_number,dob,job_title,language,race,ethnicity,gender,country,state,city,timezone} = this.state.data;
    const errors = this.state.errors;
    // Customizable Area End
    return (
      // Customizable Area Start
      <MainWrapper component={"section"} display={"flex"}>
        <Loader loading={this.state.isLoading} />
        <Box bgcolor={"#fff"} p={"24px"} borderRadius={"12px"} sx={{ width: { xs: "auto", md: "70%", lg: "60%" }, marginX: { xs: "32px", md: "auto" } }} my={"32px"}>
          <Box component={"h4"} color="#005487" fontFamily={"Verdana"} fontWeight={"bold"} fontSize={"22px"} m={0}>User Information</Box>
          <Box display={"flex"} m={"20px"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"}>
            <Box display="flex" alignItems="center" justifyContent="center" position={"relative"} width="fit-content" sx={{
              width: "75px",
              height: "75px", border: "1px solid #c4c4c4", borderRadius: "50%"
            }} >
              {!profile_picture ? (<img src={NoUser} className="user-img" alt="no user" />) :
                <Avatar src={profile_picture} className="avatar-img" />
              }
              <Box position={"absolute"} bottom="0px" right="0px" bgcolor={"#fff"} width={"16px"} height="15px" borderRadius={"50%"} border={"1px solid #808080"} padding={"5px"}>
                <img src={Camera} alt="camera" className="camera-icon" />
                <input
                  accept="image/jpeg, image/jpg, image/png"
                  type="file"
                  className="input-img"
                  data-test-id="input-img-id"
                  onChange={this.uploadProfileImage}
                />
                <Box />
              </Box>
            </Box>
            {errors.profile_picture && <Box component={"p"} role="error" color="red" fontFamily={"Verdana"} m={0} my={"6px"} fontSize={"14px"}>{errors.profile_picture}</Box>}
          </Box>
          <Box mb={"20px"}>
            <Box component={"label"} display="block" fontFamily={"Verdana"} mb={"12px"} color="#005487" fontWeight={"bold"} fontSize={"14px"}>Name*</Box>
            <TextField
              data-test-id="full_name"
              variant="outlined"
              size="medium"
              placeholder="Enter your Name"
              name="full_name"
              type="text"
              value={full_name}
              onChange={this.handleChangeField}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" >
                    <img src={smallUser} alt="smallUser" />
                  </InputAdornment>
                ),
              }}
            />
            {errors.full_name && <Box component={"p"} role="error" color="red" fontFamily={"Verdana"} m={0} my={"6px"} fontSize={"14px"}>{errors.full_name}</Box>}
          </Box>
          <Box mb={"20px"}>
            <Box component={"label"} display="block" fontFamily={"Verdana"} mb={"12px"} color="#005487" fontWeight={"bold"} fontSize={"14px"}>Job Title*</Box>
            <TextField
              data-test-id="job_title"
              variant="outlined"
              size="medium"
              placeholder="Enter your Job Title"
              name="job_title"
              type="text"
              value={job_title}
              onChange={this.handleChangeField}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" >
                    <img src={job} alt="job" />
                  </InputAdornment>
                ),
              }}
            />
            {errors.job_title && <Box component={"p"} role="error" color="red" fontFamily={"Verdana"} m={0} my={"6px"} fontSize={"14px"}>{errors.job_title}</Box>}
          </Box>
          <Box mb={"20px"}>
            <Box component={"label"} display="block" fontFamily={"Verdana"} mb={"12px"} color="#005487" fontWeight={"bold"} fontSize={"14px"}>E-mail ID*</Box>
            <TextField
              data-test-id="email"
              variant="outlined"
              size="medium"
              placeholder="Enter your E-mail ID"
              name="email"
              type="email"
              disabled
              value={email}
              onChange={this.handleChangeField}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" >
                    <img src={Email} alt="email" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mb={"20px"}>
            <Box component={"label"} display="block" fontFamily={"Verdana"} mb={"12px"} color="#005487" fontWeight={"bold"} fontSize={"14px"}>Phone Number</Box>
            <TextField
              data-test-id="full_phone_number"
              variant="outlined"
              size="medium"
              disabled
              placeholder="Enter your Phone Number"
              name="full_phone_number"
              type="number"
              value={full_phone_number}
              onChange={this.handleChangeField}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" >
                    <img src={phone} alt="phone" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mb={"20px"}>
            <Box component={"label"} display="block" fontFamily={"Verdana"} mb={"12px"} color="#005487" fontWeight={"bold"} fontSize={"14px"}>Pronouns</Box>
            <CustomSelectDropdown
              fieldId="pronouns"
              fieldValue={pronouns}
              fieldName="pronouns"
              handleChange={this.handleChangeDropdownValue}
              MenuProps={MenuProps}
              renderValue={""}
              fieldError={false}
              fieldMenuItems={this.state.pronounce_list}
              iconComponent={<img src={Dropdown} alt="dropdown" />}
              startAdornmentIcon={<img src={Pronouns} alt="pronouns" />}
              placeHolderText="Enter your Pronouns"
            />
          </Box>
          <Box mb={"20px"}>
            <Box component={"label"} display="block" fontFamily={"Verdana"} mb={"12px"} color="#005487" fontWeight={"bold"} fontSize={"14px"}>DOB</Box>
            <CalenderWrapper disableFutureDate placeHolderText={"Date of Birth"} format="MM-DD-YYYY" selectedDate={dob} handleChangeDate={this.handleDateChange} calendarIcon={<img src={calendar} alt="claender" />} openCalendarIcon={<img src={Dropdown} alt="dropdoen" />} />

          </Box>
          <Box mb={"20px"}>
            <Box component={"label"} display="block" fontFamily={"Verdana"} mb={"12px"} color="#005487" fontWeight={"bold"} fontSize={"14px"}>Language</Box>
            <CustomMultipleSelectDropdown
              fieldId="language"
              fieldValue={language}
              fieldName="language"
              handleChange={this.handleChangeDropdownValue}
              MenuProps={MenuProps}
              renderValue={''}
              fieldError={false}
              fieldMenuItems={this.state.language_list}
              iconComponent={<img src={Dropdown} alt="dropdown" />}
              startAdornmentIcon={<img src={translate} alt="translate" />}
              placeHolderText="Select your language"
            />
          </Box>
          <Box mb={"20px"}>
            <Box component={"label"} display="block" fontFamily={"Verdana"} mb={"12px"} color="#005487" fontWeight={"bold"} fontSize={"14px"}>Demographics*</Box>
            <Box display={"flex"} flexDirection={"column"} className="gap-8">
              <CustomSelectDropdown
                fieldId="race"
                fieldValue={race}
                fieldName="race"
                handleChange={this.handleChangeDropdownValue}
                MenuProps={MenuProps}
                renderValue={""}
                fieldError={false}
                fieldMenuItems={this.state.race_list}
                iconComponent={<img src={Dropdown} alt="dropdown" />}
                startAdornmentIcon={<img src={group} alt="group" />}
                placeHolderText="Race"
              />
              {errors.race && <Box component={"p"} role="error" color="red" fontFamily={"Verdana"} m={0} my={"6px"} fontSize={"14px"}>{errors.race}</Box>}
              <CustomSelectDropdown
                fieldId="ethnicity"
                fieldValue={ethnicity}
                fieldName="ethnicity"
                handleChange={this.handleChangeDropdownValue}
                MenuProps={MenuProps}
                renderValue={""}
                fieldError={false}
                fieldMenuItems={this.state.ethnicity_list}
                iconComponent={<img src={Dropdown} alt="dropdown" />}
                startAdornmentIcon={<img src={user2} alt="user2" />}
                placeHolderText="Ethnicity"
              />
              {errors.ethnicity && <Box component={"p"} role="error" color="red" fontFamily={"Verdana"} m={0} my={"6px"} fontSize={"14px"}>{errors.ethnicity}</Box>}
              <CustomSelectDropdown
                fieldId="gender"
                fieldValue={gender}
                fieldName="gender"
                handleChange={this.handleChangeDropdownValue}
                MenuProps={MenuProps}
                renderValue={""}
                fieldError={false}
                fieldMenuItems={this.state.gender_ist}
                iconComponent={<img src={Dropdown} alt="dropdown" />}
                startAdornmentIcon={<img src={Gender} alt="gender" />}
                placeHolderText="Gender"
              />
              {errors.gender && <Box component={"p"} role="error" color="red" fontFamily={"Verdana"} m={0} my={"6px"} fontSize={"14px"}>{errors.gender}</Box>}
            </Box>
          </Box>
          <Box mb={"20px"}>
            <Box component={"label"} display="block" fontFamily={"Verdana"} mb={"12px"} color="#005487" fontWeight={"bold"} fontSize={"14px"}>Country*</Box>
            <CustomSelectDropdown
              fieldId="country"
              fieldValue={country}
              fieldName="country"
              handleChange={this.handleChangeCountry}
              MenuProps={MenuProps}
              renderValue={""}
              fieldError={false}
              fieldMenuItems={this.state.country_list}
              iconComponent={<img src={Dropdown} alt="dropdown" />}
              startAdornmentIcon={<img src={location} alt="location" />}
              placeHolderText="Country"
            />
            {errors.country && <Box component={"p"} role="error" color="red" fontFamily={"Verdana"} m={0} my={"6px"} fontSize={"14px"}>{errors.country}</Box>}
          </Box>
          {country && <Box mb={"20px"}>
            <Box component={"label"} display="block" fontFamily={"Verdana"} mb={"12px"} color="#005487" fontWeight={"bold"} fontSize={"14px"}>State*</Box>
            <CustomSelectDropdown
              fieldId="state"
              fieldValue={state}
              fieldName="state"
              handleChange={this.handleChangeDropdownValue}
              MenuProps={MenuProps}
              renderValue={""}
              fieldError={false}
              fieldMenuItems={this.state.state_list}
              iconComponent={<img src={Dropdown} alt="dropdown" />}
              startAdornmentIcon={<img src={location} alt="location" />}
              placeHolderText="State"
            />
            {errors.state && <Box component={"p"} role="error" color="red" fontFamily={"Verdana"} m={0} my={"6px"} fontSize={"14px"}>{errors.state}</Box>}
          </Box>}
          <Box mb={"20px"}>
            <Box component={"label"} display="block" fontFamily={"Verdana"} mb={"12px"} color="#005487" fontWeight={"bold"} fontSize={"14px"}>City*</Box>
            <TextField
              data-test-id="city"
              variant="outlined"
              size="medium"
              placeholder="Enter your city"
              name="city"
              type="text"
              value={city}
              onChange={this.handleChangeField}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" >
                    <img src={location} alt="location" />
                  </InputAdornment>
                ),
              }}
            />
            {errors.city && <Box component={"p"} role="error" color="red" fontFamily={"Verdana"} m={0} my={"6px"} fontSize={"14px"}>{errors.city}</Box>}
          </Box>
          <Box mb={"20px"}>
            <Box component={"label"} display="block" fontFamily={"Verdana"} mb={"12px"} color="#005487" fontWeight={"bold"} fontSize={"14px"}>Display Times in*</Box>
            <CustomSelectDropdown
              fieldId="timezone"
              fieldValue={timezone}
              fieldName="timezone"
              handleChange={this.handleChangeDropdownValue}
              MenuProps={MenuProps}
              renderValue={""}
              fieldError={false}
              fieldMenuItems={[...this.timezoneList]}
              iconComponent={<img src={Dropdown} alt="dropdown" />}
              startAdornmentIcon={<img src={clock} alt="clock" />}
              placeHolderText="Timezone"
            />
            {errors.timezone && <Box component={"p"} role="error" color="red" fontFamily={"Verdana"} m={0} my={"6px"} fontSize={"14px"}>{errors.timezone}</Box>}

          </Box>
          <Box sx={{ width: { xs: "80%", md: "65%", lg: "60%" } }} margin={"auto"}>
            <Button className="done-btn" data-testid="done-btn" onClick={this.updateUserDetails}>Done</Button>
          </Box>
        </Box>
      </MainWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "300px",
      borderRadius:"12px"
    }
  },
};

const MainWrapper = styled(Box)({
  "& .pointer": {
    cursor: "pointer"
  },
  "& .gap-8":{
    gap:"8px"
  },
  "& .input-img": {
    position: "absolute",
    right: "0px",
    bottom: "0px",
    height: "70px",
    width: "70px",
    cursor: "pointer",
    opacity: 0
  },
  "& .user-img": {
    width: "80%",
    height: "80%"
  },
  "& .done-btn": {
    backgroundColor: "#005487",
    textTransform: "none",
    fontSize:"16px",
    width:"100%",
    fontWeight:"bold",
    color: "#fff",
    fontFamily: "Verdana",
    height:"56px",
  },
  "& .camera-icon": {
    width: "100%",
    height: "100%"
  },
  "& .avatar-img": {
    width: "75px",
    height: "75px",
    objectFit: "contain",
    borderRadius: "50%"
  },
  "& .CustomDatepicker":{
    "& .MuiInputAdornment-positionEnd":{
      marginRight: "15px"
    },
  } 
})

const CalenderWrapper=styled(CustomDatepicker)({
  "& .MuiInputAdornment-root":{
    marginLeft:0,
    marginRight:"16px",

  },
  "& .MuiButtonBase-root":{
    marginRight:"15px"
  }
})
// Customizable Area End
