import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { getSignupLoginTokens } from "../../../components/src/common";
// Customizable Area Start

// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean;
    mutualFriends: {name: string, id: string, profilePicture: string, is_blocked_by_community: boolean}[];
    anchorEl: null | HTMLElement;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class MutualConnectionListController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getMutualConnectionsApiCallId: string = "";
    userId: string = "";
    signupToken: string = "";
    loginToken: string = "";
    isOwnUser: boolean = false;
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);


        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),

            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ]
        this.state = {
            mutualFriends: [],
            isLoading: false,
            anchorEl: null
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
        // Customizable Area End
    }

    
    async receive(_from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );


        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        this.setState({
            isLoading: false
        })
        
        if(apiRequestCallId === this.getMutualConnectionsApiCallId){
            if(responseJson.accounts_data){
                this.setState({
                    mutualFriends: responseJson.accounts_data.map((account:{id:string,photo:string,name:string})=> ({...account, profilePicture: account.photo, is_blocked_by_community: false}))
                })
            }
        }
        // Customizable Area End
    }


    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const { signupToken, loginToken, profileData } = await getSignupLoginTokens();

        this.signupToken = signupToken;
        this.loginToken = loginToken;

        const userId = this.props.navigation.getParam("id");
        if (userId) {
            this.userId = userId;
            this.isOwnUser = profileData.id === userId
        }
        this.getMutualFriendsList();
    }

    getMutualFriendsList = () => {
        this.setState({
            isLoading:
                true
        });
        const header = {
            token: this.signupToken || this.loginToken,
            "Content-Type": configJSON.apiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getMutualConnectionsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getMutualConnectionApiEndpoint + `?user_id=${this.userId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMutualConnectionApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // Customizable Area End

}
