import React from "react";

// Customizable Area Start
import BroadcastController from "./BroadcastController.web";
import { styled } from "@material-ui/core/styles";
import { Typography, Box, Modal, InputAdornment, TextField, Avatar, Radio, OutlinedInput, CircularProgress, Button } from "@material-ui/core";
import { Add, CloseIcon, Dropdown, Edit } from "./assets";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
// Customizable Area End

export default class Broadcast extends BroadcastController {
    // Customizable Area Start
    defaultBody = () => {
        const backgroundBtn=this.state.radioValues.length <=0 ? "#808080":"#01548a";
        return (
            <Box sx={{ width: { xs: "80%", md: "50%", lg: "40%" }, borderRadius: "12px", maxWidth: "604px" }} bgcolor="#fff" className="main-body">
                <Box display="flex" justifyContent="space-between" alignItems="center" p="20px" color="#808084" sx={{ borderBottom: "1px solid #808080" }}>
                    <Typography variant="subtitle1" className="box-title">{"Broadcast Message"}</Typography>
                    <img src={CloseIcon} className="pointer" alt="CloseIcon" onClick={this.closeModel} />
                </Box>
                <Box p="20px" display={"flex"} flexDirection={"column"} className="gap-12">
                    <Box>
                        <Typography variant="subtitle1" className="label">Title</Typography>
                        <TextField
                            data-test-id="title-id"
                            variant="outlined"
                            size="medium"
                            placeholder={"Add title here"}
                            name="title"
                            type="text"
                            value={this.state.typedMessage.title}
                            onChange={this.handleChangeTextField}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" >
                                        <img src={Edit} alt="edit" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    {this.state.errors.title&&<Typography className="error" variant="body2" >{this.state.errors.title}</Typography>}
                    </Box>
                    <Box>
                        <Typography variant="subtitle1" className="label">Description</Typography>
                        <OutlinedInput
                            className="broad-textarea"
                            name="description"
                            data-test-id="description-id"
                            placeholder="Add Description here"
                            value={this.state.typedMessage.description}
                            onChange={this.handleChangeTextField}
                            fullWidth
                            startAdornment={<InputAdornment style={{ marginTop: "10px" }} position="start"><img src={Edit} alt="EditIcon" /></InputAdornment>}
                            rows={this.isMobile() ? 3 :5}
                            multiline
                            aria-describedby="outlined-weight-helper-text"
                            labelWidth={0}
                        />
                    {this.state.errors.description&&<Typography className="error" variant="body2" >{this.state.errors.description}</Typography>}
                    </Box>
                    <Box>
                        <Typography variant="subtitle1" className="label">Notification Type</Typography>
                        <CustomSelectDropdown
                            placeHolderText="Select Notification Type"
                            fieldId="notification-id"
                            fieldValue={this.state.notificationLabel}
                            fieldName="notification"
                            handleChange={this.handleChangeDropdownValue}
                            MenuProps={MenuProps}
                            renderValue={this.renderValue}
                            fieldError={false}
                            fieldMenuItems={this.state.menuItems}
                            iconComponent={
                                <img src={Dropdown} alt="Dropdown" />
                            }
                            startAdornmentIcon={
                                <img src={Add} alt="Add" />
                            }
                        />
                    {this.state.errors.notification&&<Typography className="error" variant="body2" >{this.state.errors.notification}</Typography>}
                    </Box>
                    <ListWrapper sx={{ height: { xs: "125px" } }}>
                        {this.state.error?
                              <Typography className="error" variant="body2" >{this.state.error}</Typography>
                        :
                        <>{
                            this.state.loading ? 
                        <Box display={"flex"} justifyContent={"center"}>
                            <CircularProgress />
                          </Box>
                        :(this.state.followerList).map((item: { id: string, attributes: { profile_picture: string, full_name: string } }) => {
                            return (<Box key={item.id} display="flex" justifyContent="space-between" sx={{ width: "95%", margin: "20px auto" }}>
                                <Box display="flex">
                                    <Avatar className="avatar" src={item.attributes.profile_picture}></Avatar>
                                    <Typography variant="body1" className="avatar-name">{item.attributes.full_name}</Typography>
                                </Box>
                                <Radio
                                    className="avatar-radio"
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'radio' }}
                                    checked={this.state.radioValues.includes(item.id)}
                                    onClick={() => this.handleRadioChange(item.id)}
                                    value={item.id}
                                    data-test-id="list-friend-id"
                                />
                            </Box>)
                        })
                    }</>}
                    </ListWrapper>
                </Box>

                <Box display="flex" justifyContent="space-evenly" p="23px" pt="15px" sx={{ borderTop: "1px solid #808080" }}>
                    <Button
                        data-test-id="cancel-id"
                        className="Publishbtn"
                        onClick={this.closeModel}
                    >Cancel</Button>
                    <Button
                        data-test-id="send-id"
                        type="submit"
                        className="Publishbtn"
                        disabled={this.state.radioValues.length<=0}
                        onClick={() => { this.changeConfirmation("second") }}
                    >Send</Button>
                </Box>
            </Box>
        )
    }

    confirmationBody = () => {
        return (
            <Box sx={{ width: { xs: "80%", sm: "70%", md: "50%", lg: "35%" }, maxWidth:"605px" }} bgcolor="#fff" borderRadius="12px">
                <div className="confirmation-container" >
                    {this.state.confirmation !== "third" ? <>
                        <Box sx={{ margin: "auto", height: "340px" }}>
                            <Box component={"p"} color="#005487" fontFamily="Verdana" fontSize={"17px"} fontWeight={"bold"}>
                                Broadcast Message
                            </Box>
                            <Box component="p" color="#808080" fontFamily="Verdana" fontSize={"17px"}>
                                Are you sure you want to broadcast this message?
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between" >
                            <Button
                                data-test-id="no-id"
                                className="publishBtn1"
                                onClick={this.closeModel}
                            >No</Button>
                            <Button
                                data-test-id="yes-id"
                                type="submit"
                                className="publishBtn1"
                                onClick={this.sendMessage}
                            >Yes</Button>
                        </Box>
                    </> : <>
                        <Box component={"p"} fontSize={"17px"} mb={"30px"} fontWeight={"bold"} display={"flex"} justifyContent={"center"} position={"relative"} alignItems={"center"} data-test-id="message-broadcaste">Broadcast Message Sent<img src={CloseIcon} alt="closeIcon" className="img-icon pointer" onClick={this.closeModel} /></Box>
                        <Box margin={"auto"} sx={{ width: "90%" }}>
                            <Button
                                data-test-id="submit-id"
                                type="submit"
                                className="submit-btn"
                                onClick={this.closeModel}
                            >Back to Home</Button>
                        </Box>
                    </>
                    }
                </div>
            </Box>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <>
                <ModalWrpper
                    open={this.props.open}
                    onClose={this.closeModel}
                    aria-labelledby="simple-modal-broadcast"
                    aria-describedby="simple-modal-description"
                >
                    <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: "100%", height: "100%" }}>
                        {!this.state.confirmation && this.defaultBody()}
                        {(this.state.confirmation === "second" || this.state.confirmation === "third") && this.confirmationBody()}
                    </Box>
                </ModalWrpper>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const ModalWrpper = styled(Modal)({
    "& .main-body":{
        maxHeight: "90%",
        overflowY: "auto"
    },
    "& .error":{
        color: "red",
    },
    "& .label": {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#005487",
        fontFamily: "Verdana"
    },
    "& .box-title": {
        fontSize: "20px",
        fontFamily: "Verdana",
        fontWeight: "bold",
    },
    "& .confirmation-container": {
        border: "1px solid black",
        margin: "20px",
        padding: "20px",
        borderRadius: "12px",
    },
    "& .gap-12": {
        gap: "12px",
    },
    "& .img-icon": {
        position: "absolute",
        right: "20px"
    },
    "& .pointer": {
        cursor: "pointer"
    },
    "& .broad-textarea": {
        alignItems: "start",
    },
    "& .Publishbtn": {
        height: "48px",
        border: "solid 1px #005487",
        fontWeight: "bold",
        width: "40%",
        maxWidth: "266px",
        color: "#01548a",
        textTransform: "none",
        backgroundColor: "#fff",
        marginTop: "8px",
        "&:active": {
            color: "#fff",
            backgroundColor: "#01548a",
        },
    },
    "& .Publishbtn1": {
        height: "56px",
        border: "solid 1px #005487",
        fontWeight: "bold",
        width: "40%",
        maxWidth: "250px",
        color: "#01548a",
        backgroundColor: "#fff",
        textTransform: "none",
        marginTop: "8px",
        "&:active": {
            backgroundColor: "#01548a",
            color: "#fff",
        },
    },
    "& .submit-btn": {
        height: "56px",
        maxWidth: "524px",
        width:"90%",
    }
});
const ListWrapper = styled(Box)({
    overflowY: "auto",
    "& .avatar-name": {
        paddingLeft: "10px"
    },
    "& .avatar-radio": {
        "& .MuiIconButton-label": {
            color: "#005487 !important"
        }
    },
});
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: "320px",
            borderRadius: "12px",
        },
    },
};
// Customizable Area End
