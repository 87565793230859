// Customizable Area Start
import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ConfirmationModalController, { Props } from "./ConfirmationModalController.web";
import CustomButton from "./CustomButton.web";
// Customizable Area End

// Customizable Area Start
 const ConfirmationStyles = (theme: Theme) => 
   createStyles({
     title: {
       [theme.breakpoints.up("sm")]: {
        display: "block",
       },
       display: "none",
    },
     menuIcon: {
       width: "24px",
       height: "24px",
       objectFit: "contain",
    },
    inputAdornment: {
        padding: "0px 8px",
    },
  } );
// Customizable Area End

    /*istanbul ignore next */
export class ConfirmationModal extends ConfirmationModalController {
     constructor(props: Props) {
     super(props);
     // Customizable Area Start
     // Customizable Area End
  }
  // Customizable Area Start
   render() {
     return (
       <Modal
         aria-labelledby="transition-modal-title"
         aria-describedby="transition-modal-description"
         className={"VideoPostModal VideoStepTwoModal"}
         open={this.props.open}
         onClose={this.props.onClose}
         closeAfterTransition
         BackdropComponent={Backdrop}
         BackdropProps={{
           timeout: 200,
         }}
       >
         <Fade in={this.props.open}>
           <Box style={{ height: "30rem" }} className="VideoStepTwoModalBox">
             <Grid container className={`VideoStepTwoModalMainGrid`}>
               <Grid item xs={12}>
                   <Typography variant="body1" style={webStyle.logout}>
                     {this.props.title}
                   </Typography>
             </Grid>

          <Grid item xs={12}>
                 
                <Typography variant="body1" style={webStyle.logOutSub}>
                      {this.props.description}
                </Typography>
                 
               </Grid>

               <Grid item xs={12} style={{ display: "flex" }}>
                 <CustomButton
                   btnStyle={webStyle.PublishbtnTwo}
                   btnText={"Yes"}
                   buttonId="user-profile-add-social-publish-btn"
                   type="submit"
                   handleButtonClick={this.props.handleSubmit}
                 />
                 <CustomButton
                   btnStyle={webStyle.PublishbtnTwo}
                   btnText={"No"}
                   buttonId="user-profile-add-social-publish-btn"
                   type="submit"
                   handleButtonClick={this.props.onClose}
                 />
               </Grid>
             </Grid>
           </Box>
         </Fade>
       </Modal>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

 const webStyle = {
   DoneBtn: {
     background: "#01548a",
     color: "#fff",
     width: "100%",
     marginTop: "10rem",
     borderRadius: "0px",
     padding: "18px",
     fontWeight: "bold",
  },
   logout: {
     width: "100%",
     height: "22px",
     margin: "7px 195px 19px 0",
     fontSize: "17px",
     fontWeight: "bold",
     fontStretch: "normal",
     fontStyle: "normal",
     lineHeight: "1.29",
     letterSpacing: "-0.41px",
     color: "#005487",
   } as const,
   logOutSub: {
     width: "460px",
     height: "42px",
     margin: "19px 64px 259px 0",
     fontSize: "17px",
     fontWeight: "normal",
     fontStretch: "normal",
     fontStyle: "normal",
     lineHeight: "normal",
     letterSpacing: "normal",
     color: "#808080",
      minWidth: "30rem",
   } as const,
    Publishbtn: {
      color: "#fff",
      width: "250px",
      height: "56px",
      margin: "0 24px 0 0",
      padding: "18px 109px 19px",
      border: "solid 1px #005487",
      backgroundColor: "#01548a",
   } as const,
   PublishbtnTwo: {
      color: "#01548a",
      width: "250px",
      height: "56px",
      margin: "0 24px 0 0",
      padding: "18px 109px 19px",
      border: "solid 1px #005487",
       backgroundColor: "#fff",
   } as const,
 };

export default withStyles(ConfirmationStyles, { withTheme: true })(ConfirmationModal);
// Customizable Area End
