import React from "react";

// Customizable Area Start
import Loader from "../../../components/src/Loader.web";
import ConfirmationModalWeb from "./ConfirmationModal.web";
import ChatViewController, { Props, configJSON } from "./ChatViewController";
import { styled } from "@material-ui/core/styles";
import {
  Divider,
  Box,
  Typography,
  Avatar,
  InputAdornment,
  TextField,
  Modal,
  Button,
  IconButton
} from "@material-ui/core";
import { Message as Tmessage } from 'twilio-chat';
import { More, Camera, Exit, BlueSend, Report, OffNotification, OnNotification, Delete, Close, Cloud, Plus} from "./assets";
// Customizable Area End


// Customizable Area Start
// Customizable Area End

export default class ChatView extends ChatViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderChats = () => {
    return (
      <>
        {this.state.channels.length > 0 && this.state.channels[0].messages.map((item, index: number) => {
          return (<React.Fragment key={item.index}>
            {index === 0 || this.formatDateLabel(item.dateCreated) !== this.formatDateLabel(this.state.channels[0].messages[index - 1].dateCreated) ? (
              <Box component="div" sx={{
                width: "fit-content",
                margin: "0 auto",
                borderRadius: "4px",
                fontSize: "12px",
                boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
              }} bgcolor="#f4f4f4" color="#808080" py="5px" px="10px">{this.formatDateLabel(item.dateCreated)}</Box>
            ) : null}
            <Box sx={{ width: "100%" }} display="flex" justifyContent={this.state.idName.some((it) => it.id === item.author) ? "flex-start" : "flex-end"}>
              <Box key={item.index} sx={{
                borderRadius: "8px", boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
                width: "fit-content",
                maxWidth: "85%",
                minWidth: "75px"
              }} p={"3px"} className="chat-item" m={2}>
                {this.renderSenderName(item)}
                {item.body ?
                  <>
                    <Box p="10px" fontSize="17px" className="word-break"
                      fontFamily="Verdana, Geneva, Tahoma, sans-serif"
                    >
                      <div className="dynamic-font" dangerouslySetInnerHTML={{ __html: this.replaceUrl(item.body) }}/>
                    </Box>
                    <Typography align="right" variant="body2" className="message-timestamp">{this.convertTo24HourFormat(item.dateCreated)}</Typography>
                  </>
                  :
                  <Box sx={{ width: { xs: "190px", md: "220px", xl: "250px" } }} position="relative">
                    <img className="img-chat" src={this.findUrlfromindex(item.index)} alt="chatimg" />
                    <Typography align="right" variant="body2" className="img-timestamp">{this.convertTo24HourFormat(item.dateCreated)}</Typography>
                  </Box>
                }
              </Box>
            </Box>
          </React.Fragment>)
        }
        )}

      </>
    )
  };

  renderSenderName = (message: Tmessage) => {
    const item = this.state.conversationData
    if (item.chat_type === "single_conversation") return null
    const value = this.state.idName.some((it) => it.id === message.author) ? this.state.idName.find(el => el.id === message.author)?.name : "You"
    return (
      <Typography variant="subtitle2" className="sender-name">{value}</Typography>
    )
  };

  renderHeaderAndOption = () => {
    const item = this.state.conversationData
    const profilePic = (item.chat_type === "single_conversation" ? item.receipient_data.data[0].attributes.receipient_profile_pic : item.group_icon) as string;
    const profileName = item.chat_type === "single_conversation" ? item.receipient_data.data[0].attributes.receipient_name : item.group_name;
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: "24px" }}>
        <Box display="flex" alignItems="center">
          <Avatar className="avatar" src={profilePic} />
          <Box>
            <Typography variant="body1" className="avatar-name">{profileName}</Typography>
            {item.chat_type !== "single_conversation" && <Typography variant="body1" className="avatar-group-names">{this.showGroupMembers()}</Typography>}
          </Box>
        </Box>
        <Box position="relative" className="pointer">
          <img src={More} className="pointer" data-test-id="option-id" onClick={this.handleMenuOpen} />
          <Box position="absolute" bgcolor="#fff" display={this.state.menuOpen ? "block" : "none"} sx={{
            width: "300px",
            right: 0,
            padding: "20px",
            borderRadius: "12px",
            zIndex: 2,
            boxShadow: "2px 4px 16px 4px rgba(0, 0, 0, 0.25)"
          }}>
             {item.chat_type === "group_conversation" && <Box display="flex" alignItems="center" className="pointer" data-test-id="add-member-id" onClick={this.handleAddMember}>
              { <img className="pointer" src={Plus} alt="plus"  /> }
              <Box component={"p"} sx={{ fontFamily: "Verdana", fontSize: "17px", paddingLeft: "10px" }} data-test-id="option-list" color="#808084">{configJSON.optionAddMember}</Box>
            </Box>}
            <Box display="flex" alignItems="center" className="pointer" data-test-id="notification-id" onClick={this.handleNotification}>
              {this.state.notificationLevel === "default" ? <img className="pointer" src={OffNotification} alt="OffNotification"  /> : <img className="pointer" src={OnNotification} alt="OnNotification" />}
              <Box component={"p"} sx={{ fontFamily: "Verdana", fontSize: "17px", paddingLeft: "10px" }} data-test-id="option-list" color="#808084">{this.state.notificationLevel === "default" ? configJSON.optionMute : configJSON.optionUnMute}</Box>
            </Box>
            <Box display="flex" alignItems="center" className="pointer" data-test-id="delete-id" onClick={() => this.handleOpenConfirmation("delete")}>
              <img className="pointer" src={Delete} alt="Delete" />
              <Box component={"p"} sx={{ fontFamily: "Verdana", fontSize: "17px", paddingLeft: "10px" }} color="#808084" data-test-id="option-list">{configJSON.optionDelete}</Box>
            </Box>
            {item.chat_type === "group_conversation" && <Box display="flex" alignItems="center" className="pointer" id="exit-id" onClick={() => this.handleOpenConfirmation("exit")}>
              <img className="pointer" src={Exit} alt="Exit"/>
              <Box component={"p"} sx={{ fontFamily: "Verdana", fontSize: "17px", paddingLeft: "10px" }} color="#808084" data-test-id="option-list">{configJSON.optionExit}</Box>
            </Box>}
            <Box display="flex" alignItems="center" className="pointer" data-test-id="report-id" onClick={() => this.handleReport()}>
              <img className="pointer" src={Report} alt="Report" />
              <Box component={"p"} sx={{ fontFamily: "Verdana", fontSize: "17px", paddingLeft: "10px" }} color="#808084" data-test-id="option-list">{configJSON.optionReport}</Box>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  };

  renderImgModal = () => {
    return(
      <ModalWrpper
        open={this.state.imgModel}
        onClose={this.handlecloseImg}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: "100%", height: "100%" }}>
          <Box sx={{ width: { xs: "80%", md: "50%", lg: "40%" }, borderRadius: "12px" }} bgcolor="#fff">
              <Box display="flex" justifyContent="space-between" alignItems="center" p="20px" color="#808084" sx={{ borderBottom: "1px solid #808080" }}>
                  <Typography variant="subtitle1" className="box-title">{"Select Image"}</Typography>
                  <img src={Close} alt="close" onClick={this.handlecloseImg} className="pointer"/>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ borderBottom: "1px solid #808080" }}>
                  <Box p="20px" sx={{ borderBottom: "1px solid #005487" }} color="#005487">{"Upload"}</Box>
              </Box>
              {this.state.imageUrl ?
                  <Box component="div" display="flex" alignItems="center" flexDirection="column" justifyContent="center" sx={{ height: "246px" }}>
                      <img src={this.state.imageUrl} alt="selectedimg" className="selected-img" />
                  </Box>
                  : <Box component="div" display="flex" alignItems="center" data-test-id="drop-id" flexDirection="column" justifyContent="center" sx={{ height: "246px" }}
                      onDrop={(e) => {
                          this.handleOnImportFile(e.dataTransfer.files);
                          e.preventDefault();
                      }}
                      onDragOver={e => e.preventDefault()}>
                      <input type='file' id="fileInput" data-test-id="fileInput" className='input-hide'
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              this.handleOnImportFile(e.target.files!);
                              e.preventDefault();
                          }}
                          accept="image/png, image/jpg, image/jpeg,"
                      />
                      <img src={Cloud} alt="Cloud" />
                      <Button
                          className={"uploadBtn"}
                          type="button"
                          data-test-id="user-add-btn"
                          onClick={this.handleOpenUploadBtn}
                      >Browse</Button>
                      <Typography variant="subtitle2" align="center" className="drag-text">Or drag a file here</Typography>
                  </Box>}
              <Box display="flex" justifyContent="flex-end" p="20px" sx={{ borderTop: "1px solid #808080" }}>
                  <Button
                      className={"Publishbtn"}
                      type="submit"
                      data-test-id="img-submit"
                      onClick={this.handleSubmit}
                  >Done</Button>
            </Box>
          </Box>
        </Box>
      </ModalWrpper>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
      {this.state.loading && <Loader loading={this.state.loading} />}
      {this.state.confirmationModel.open && <ConfirmationModalWeb type={this.state.confirmationModel.type} open={this.state.confirmationModel.open} handleClose={this.handleCloseConfirmation} handleAccept={this.handleAccept} id={""} navigation={this.props.navigation}/>}
      {this.renderImgModal()}
      <Wrapper sx={{ width: { xs: "90%", md: "100%" }, margin: "auto" }} bgcolor="white" boxSizing="border-box">
          {this.renderHeaderAndOption()}
          <Divider />
          <div className="chat-container" ref={this.scrollDiv}>
            {this.renderChats()}
          </div>
          <Box display="flex" alignItems="center" p="24px" className="gap-12">
            <Box display="flex" border="1px solid #eaeaea" alignItems="center" px="10px" justifyContent="space-between" sx={{ borderRadius: "50px", width: "100%", overflow: "hidden" }}>
              <TextField
                className="message-field"
                data-test-id="message-id"
                placeholder={configJSON.messagePlaceholder}
                size="small"
                type="text"
                name="message"
                value={this.state.typedMessage}
                onChange={this.handleChange}
                multiline
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <Box >
                      <InputAdornment position="end">
                        <img src={Camera} alt="camera" className="icon pointer" data-test-id="camera-id" onClick={this.openImgModel} />
                      </InputAdornment>
                    </Box>
                  )
                }}
              />

            </Box>
            <IconButton data-test-id="send-btn" onClick={this.messageSend} disabled={this.state.disabled}><img src={BlueSend} alt="BlueSend"/></IconButton>
          </Box>
        </Wrapper>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  borderRadius: "12px",
  "& .message-field": {
    borderRadius: "12px",
    padding: " 14px 10px",
    overflow: "auto",
    width: "100%"
  },
  "& .icon": {
    margin: "0 7px"
  },
  "& .avatar-name": {
    paddingLeft: "10px",
    fontSize: "17px",
    color: "#005487",
    fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
  },
  "& .avatar-group-names": {
    paddingLeft: "10px",
    color: "#808084",
    fontSize: "12px",
    fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
  },
  "& .chat-container": {
    overflowY: "auto",
    height: "550px",
    padding: "15px",
    "@media(max-width:960px)": {
      height: "500px",
    }
  },
  "& .pointer": {
    cursor: "pointer"
  },
  "& .icon-color": {
    color: "#005487"
  },
  "& .img-chat": {
    width: "100%",
    aspectRatio: "1/1",
    borderRadius: "8px",
  },
  "& .img-timestamp": {
    position: "absolute",
    right: "4px",
    bottom: "4px",
    color: "#fff",
  },
  "& .message-timestamp": {
    fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
    fontSize: "14px",
    color: "#808080",
  },
  "& .sender-name": {
    fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
    fontSize: "14px",
    paddingLeft: "5px",
    color: "#c2bc30"
  },
  "& .word-break": {
    wordBreak: "break-word",
  },
  "& .gap-12": {
    gap: "12px",
  }
});

const ModalWrpper = styled(Modal)({
  "& .input-hide": {
      display: "none",
  },
  "& .drag-text": {
      fontFamily: "Verdana",
      fontSize: "17px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      color: "#808084",
  },
  "& .pointer":{
    cursor: "pointer",
  },
  "& .selected-img": {
      objectFit: "cover",
      height: "90%",
      aspectRatio:"1/1",
  },
  "& .box-title":{
      fontSize:"20px",
      fontFamily:"Verdana",
  },
  "& .Publishbtn": {
      color: "#fff",
      width: "160px",
      height: "48px",
      margin: "23px 7px 0 38px",
      padding: "15px 56px 14px 57px",
      border: "solid 1px #005487",
      backgroundColor: "#01548a",
      marginTop: "8px"
  },
  "& .uploadBtn": {
      color: "#fff",
      width: "160px",
      height: "48px",
      margin: "15px",
      padding: "15px 56px 14px 57px",
      border: "solid 1px #005487",
      backgroundColor: "#01548a"
  },

});
// Customizable Area End
