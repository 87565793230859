import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");

import { BlockComponent } from "../../../framework/src/BlockComponent";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area Start
import { toast } from "react-toastify"
import { handleErrorArray } from "../../../components/src/common";

export interface IProfileDetails {
    activated: boolean;
    background_profile_pic:string;
    description:string;
    name:string;
    profile_pic:string;
    country_code: string;
    email: string;
    first_name: string | null;
    full_phone_number: string;
    last_name: string | null;
    full_name: string;
    job_title: string;
    phone_number: string;
    type: string;
    pronouns: string;
    language: string[] | null;
    race: string;
    ethnicity: string;
    gender: string;
    state: string;
    is_private: boolean;
    message:string;
    city: string;
    dob: string;
    timezone: string;
    is_local_admin: boolean;
    created_at: string;
    updated_at: string;
    device_id: string;
    unique_auth_id: string;
    organization: {
        id: number;
        address: string;
        email: string;
        name: string;
        website: string;
        contact_number: string;
        created_at: string;
        updated_at: string;
        account_id: number;
    } | null;
    social_media_urls: {
        account_id: number;
        created_at: string;
        id: number;
        updated_at: string;
        url: string;
    }[];
    facial_authentication_key: string | null;
    biometric_authentication_key: string | null;
    user_name: string | null;
    profile_picture: string;
    background_image: string | null;
    follower_count: number;
    posts: {
        data: {
            id: string;
            type: string;
            attributes: IActivity;
        }[];
    };
    achievements: {
        data: {
            id: string;
            type: string;
            attributes: IAchievements;
        }[];
    };
    is_Community_present: boolean;
    selected_communities: any;
    friend_list_count: number | null;
    is_in_connection: boolean;
    is_friend_request_sent: boolean;
    is_followed_by_login_user : boolean;
}

export interface IAchievements {
    issue_month: number | null;
    issue_year: number | null;
      title: string | null;
      organisation_name: string | null;
      expiration_year: number | null;
      expiration_month: number | null;
      ceu_gained: string | null;
      credential_url: string | null;
      description: string | null;
      is_bulk_upload: boolean;
      community_id: string | null;
      account_id: number;
      profile_picture: string;
      full_name: string;
      achievement_files: {
        data: {
          id: string;
          type: string;
          attributes: {
            title: string;
            description: string;
            achievement_images: {
              file_name: string;
              file_exetension: string;
              url: string;
            }[];
          };
        }[];
      };
      custom_form_file: string | null;
      custom_form_file_name: string | null;
      custom_form_file_extension: string | null;
}

export interface IActivity{
    id: number;
    name: string | null;
    description: string | null;
    url: string;
    account_id: number;
    community_id: string | null;
    post_type: string;
    created_at: string;
    updated_at: string;
    creating_time: string;
    thumbnail: string | null;
    images_and_videos: any[];
    media: any[];
    post_like_count: number | null;
    comments: {
        data: any[];
    };
    full_name: string;
    community_name: string | null;
    profile_picture: string;
    community_profile: any;
    community_account_id: any;
    is_liked: boolean;
    comments_count: number;
    is_followed: boolean;
    post_save_by_current_user: boolean;
    is_login_user_post: boolean;
    is_followed_community: boolean;
    video_title: string | null;
    video_thumbnail: string | null
}
// Customizable Area End


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    profileDetails: IProfileDetails | null;
    showChangeWallpaperModal: boolean;
    userId: string;
    loader:boolean;
    isLoading: boolean;
    communityFlag:any;
    communityDetails:any;
    communityData:any;
    isCommunityPresent:boolean;
    showFlag:boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}


     
export default class ViewProfileScreenController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getUserProfileDataApiCallId: string = "";
    changeWallpaperApiCallId: string = "";
    apiGetUserCommunityId:string="";
    removeAchievementApiCallId: string = "";
    loginToken: string = "";
    signupToken: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);

        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            // Customizable Area End
        ]
        this.state = {
            profileDetails: null,
            showChangeWallpaperModal: false,
            userId:"",
            loader:true,
            isLoading: false,
            communityFlag:"false",
            isCommunityPresent:false,
            communityData:{},
            showFlag:false,
           communityDetails:null
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)

    }

    async receive(_from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        let errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        
        this.setState({
            isLoading: false
        })
        if (apiRequestCallId === this.getUserProfileDataApiCallId) {
            if (responseJson && responseJson.data && responseJson.data.attributes) {
             this.setState({loader:false});   
                setStorageData("profileData", JSON.stringify({...responseJson.data.attributes, id: responseJson.data.id}));
                this.setState({
                    userId: responseJson.data.id,
                    profileDetails: responseJson.data.attributes
                })
                const { is_Community_present } = responseJson.data?.attributes;
                this.setState({  isCommunityPresent : is_Community_present || false} , () => {
                    if(this.state.isCommunityPresent){
                      this.handleGetUserCommunity()
                    }
                  })
            }
            else if (responseJson && responseJson.errors) {
                handleErrorArray(responseJson.errors)
            }
        }

        if(apiRequestCallId === this.apiGetUserCommunityId)
{
    if(!(responseJson.errors || responseJson.error))
    {


      this.setState({ communityDetails : responseJson.data, loader:false, isLoading:false})

    }
 
}
        if (apiRequestCallId === this.changeWallpaperApiCallId) {
            if (responseJson.data && responseJson.data.attributes) {
                toast.success("Background Image Updated Successfully");
                this.getUserProfileData();
                this.setState({
                    showChangeWallpaperModal: false
                })
            }
        }

        if(apiRequestCallId === this.removeAchievementApiCallId){
            if(responseJson && responseJson.data){
                toast.success(responseJson.data[0].message);
                this.getUserProfileData();
            }else if (responseJson 
                && responseJson.errors) {
                handleErrorArray(responseJson.errors)
            }
        }
        // Customizable Area End
    }


    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        const { signupToken, loginToken } = await this.getTokens();

        this.signupToken = signupToken;
        this.loginToken = loginToken;
        await this.getUserProfileData();

        this.setState({communityFlag:localStorage.getItem("community")})
  
        if( localStorage.getItem("communityData")!="")
        {
         let parsedData=localStorage.getItem("communityData")
         if(parsedData!=null)
         {
           this.setState({communityData:JSON.parse(parsedData)})
         }
        
        }

    }

    getTokens = async () => {
        let signupToken = await getStorageData('SignupSuccessToken');
        let loginToken = await getStorageData('LoginSuccessToken');
        let userData = await getStorageData("LoggedInUserData", true);

        return { signupToken, loginToken, userData };
    }

    handleGetUserCommunity= async() =>
    {
     let loginToken = await getStorageData('LoginSuccessToken'); 
     let signupToken = await getStorageData('SignupSuccessToken');
      const header =  {
token: signupToken || loginToken,    "Content-Type": configJSON.apiContentType,
   };
 const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
 );
  this.apiGetUserCommunityId = requestMessage.messageId;
   requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getUserCommunity
  );
 
     requestMessage.addData(
   getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
 );
 requestMessage.addData(
 
 getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.getApiMethod
     );
 runEngine.sendMessage(requestMessage.id, requestMessage);
   }
    getUserProfileData = async () => {
        this.showLoading()
        const header = {
            token: this.signupToken || this.loginToken,
            "Content-Type": configJSON.apiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getUserProfileDataApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.state.communityFlag=="false"?configJSON.getUserProfileDataApiEndPoint:"get_community"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getUserProfileDataApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    showLoading = () =>{
        this.setState({
            loader: true
        })
    }

    setEditWallpaperModal = (isShow: boolean) => {
        this.setState({
            showChangeWallpaperModal: isShow
        })
    }

    handleChangeWallpaperSubmit = (image: any) => {
        this.changeWallpaperApiCall(image)
    }

    changeWallpaperApiCall = (image: any) => {
        this.showLoading();
        const header = {
            token: this.signupToken || this.loginToken,
        };

        const formData = new FormData();
if(this.state.communityFlag=="false")
{
    formData.append("account[background_image]", image);
}
else{
    formData.append("background_profile_pic", image);
}

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.changeWallpaperApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.changeWallpaperApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        if( this.state.communityFlag=="false")
        {
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.changeWallpaperApiMethod
            );
        }
        else{
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
              `communities/${this.state.communityData?.id}`
            );
        }

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleNavigation = (page: string) => {
        if(page==="FollowersList" && this.state.communityFlag=="true")
        {
            let communityId= `community-${this.state.communityData?.id}`
            this.props.navigation.navigate(page, {id: communityId});
            return false;
        }
      else  if(page === "ContactInfo"
        || page === "FollowersList"
        || page === "FriendsList"){
            this.props.navigation.navigate(page, {id: this.state.communityFlag=="true"?this.state.communityData?.id: this.state.userId});
            return false;
        } else if(
            page === "UserOrganizationInformation" || page === "UserProfileInformation"
        ){
            this.props.navigation.navigate(page, {pastNavigation: "view-profile"});
            return false;
        }
        this.props.navigation.navigate(page);
    }
    handleNavigateCommunity=()=>{
        const msg: Message = new Message(
            getName(MessageEnum.NavigationCreateCommunityMessage)
          );
          msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          this.send(msg);
    }
    removeAchievement = (param: string) => {
        this.showLoading()
        const header = {
            token: this.signupToken || this.loginToken,
            "Content-Type": configJSON.apiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.removeAchievementApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.removeAchievementApiEndpoint + param
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.removeAchievementApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}



