import React from "react";

// Customizable Area Start
import {
  Divider,
  Box,
  Typography,
  Avatar
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { EditIcon, MuteNotification } from "./assets";
import ChatController, { Props, configJSON } from "./ChatControllerNew.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End


export default class ChatNew extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderChatList = () => {
    return (
      <ConversationWrapper boxSizing="border-box" sx={{ width: { xs: "90%", md: "100%" }, height: { xs: "550px", md: "680px" }, margin: { xs: "auto", md: 0 }, padding:"1px" }} bgcolor="white">
        <Box display="flex" alignItems="center" justifyContent="space-between" padding="20px">
          <Typography variant="h5" className="title">{configJSON.chatName}</Typography>
          <img src={EditIcon} alt="edit" className="pointer" data-test-id="transfer-id" onClick={this.transferData} />
        </Box>
        <Divider className="divider" />
        {this.state.conversationData && this.state.conversationData.length > 0 && this.state.conversationData.map(item => {
          const profilePic = (item.chat_type === "single_conversation" ? item.receipient_data.data[0]?.attributes.receipient_profile_pic : item.group_icon) as string;
          const profileName = item.chat_type === "single_conversation" ? item.receipient_data.data[0]?.attributes.receipient_name : item.group_name;
          return <Box onClick={() => this.handleOpenChat(item)} data-test-id="perticular-chat" key={item.conversation_sid} className="pointer" display="flex" justifyContent="space-between" sx={{ width: "100%", padding: "10px 20px", boxSizing: "border-box", margin: "20px auto", boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)", borderRadius:"12px" }}>
            <Box display="flex">
              <Avatar className="avatar" src={profilePic}></Avatar>
              <Box display="flex" flexDirection="column">
                <Typography variant="body1" className="avatar-name">{profileName}</Typography>
                <Typography variant="body2" className="message">{(item.last_message).substring(0, 50)}</Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-end" justifyContent="space-around">
              {item.last_message_time && <Typography variant="body2" className="time">{this.formatChatListDate(item.last_message_time)}</Typography>}
              <Box display={"flex"} className="gap-8">
              {item.notification_level==="muted" && <img src={MuteNotification} alt="mutenotification"/>}
              {item.unread_messages_count > 0 && <Typography variant="body2" className="count" align="center">{item.unread_messages_count}</Typography>}
              </Box>
            </Box>
          </Box>
        })}
      </ConversationWrapper>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        {this.state.loading && <Loader loading={this.state.loading} />}
        {this.renderChatList()}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ConversationWrapper = styled(Box)({
  overflowY: "auto",
  borderRadius:"12px",
  "& .title": {
    fontSize: "20px",
    fontFamily: "Verdana",
    fontWeight: "bold",
    color: "#005487"
  },
  "& .avatar-name": {
    paddingLeft: "10px"
  },
  "& .message": {
    fontSize: "14px",
    fontFamily: "Verdana",
    color: "#808080",
    paddingLeft: "10px"
  },
  "& .pointer": {
    cursor: "pointer"
  },
  "& .time": {
    fontSize: "13px",
    color: "#005487",
  },
  "& .count": {
    backgroundColor: "#005487",
    color: "#fff",
    borderRadius: "50%",
    fontSize: "11px",
    padding: "3px",
    minWidth: "12px"
  },
  "& .gap-8":{
    gap:"8px"
  }
});
// Customizable Area End
