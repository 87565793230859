// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

import { IBlock } from "../../../framework/src/IBlock";

import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify"
import { PostUser } from "./assets";
import ApiCall from "../../../components/src/ApiCall.web";
import { isLocalAdmin } from "../../../components/src/common";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

export interface IEvent {
  id: string;
  type: string;
  attributes: {
    id: number;
    event_type: string;
    event_name: string;
    event_url: string;
    timezone: string;
    description: string;
    start_date: string;
    end_date: string;
    street: string;
    city: string;
    state: string;
    zipcode: string;
    creating_time: string;
    start_time: string;
    end_time: string;
    cover_image: string;
    is_interested: boolean;
    interested_count: number;
    is_login_user_event: boolean;
    created_at: string;
    full_name: string | null;
    community_name: string;
    profile_picture: string | null;
    community_profile: string;
    community_account_id: number;
    interest_event_count: {
      account_images: {photo:string|null}[];
      count: number | null;
    }[];
  }
}

interface IPost {
  id: string;
    type: string;
    attributes: {
        id: number;
        name: string | null;
        description: string;
        url: string | null;
        account_id: number | null;
        community_id: number | null;
        post_type: string;
        created_at: string;
        updated_at: string;
        creating_time: string;
        thumbnail: string | null;
        images_and_videos: string[]; // This should be defined as needed
        post_like_count: number | null;
        full_name: string | null;
        community_name: string | null;
        profile_picture: string | null;
        community_profile: string | null;
        community_account_id: number | null;
        is_liked: boolean;
        comments_count: number;
        is_followed: boolean;
        post_save_by_current_user: boolean;
        is_login_user_post: boolean;
        is_followed_community: boolean;
        is_followed_by_login_user: boolean;
        video_title: string | null;
        post_shared_by: string | null;
        shared_account_profile: string | null;
        community_shared_by: string | null;
        shared_community_profile: string | null;
        like_by: string | null;
        shared_at: string;
        sharing_time: string;
        comment_by: string | null;
        shared_item_id: string | null;
        notification_turn_on: boolean;
        video_thumbnail: string | null;
    };
}
// Customizable Area End

export interface Props {
   // Customizable Area Start
  navigation: any;
  id: string;
  filter?: boolean;
  resetData: ()=>void;
  classes: any
  // Customizable Area End
}

interface S {
    // Customizable Area Start
  postList: (IPost | IEvent)[];
  loading: boolean
  commentOpen:boolean
  selectedPostId:number
  profileImageSrc:string
  followerCount:number
  is_Interested:boolean;
  postCount:number,
  newPostListCount:number,
  communityFlag:any
  communityData:any,
  tabbed:string,
  postLoad:boolean,
  pathRoute:boolean,
  tabValue: number,
  filter: string,
  modal : {
    hyperLinkModal: boolean;
    videoModal: string;
    editModal: boolean;
    textModal: boolean;
  };
  info: {
    name:string;
    image: string;
    is_private: boolean;
    type: string;
  },
  postId: string;
  newPost: boolean;
  // Customizable Area End
}


interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
 // Customizable Area Start

  // Customizable Area End
  
export default class LandingPageNewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  listPostId: string = '';
  apiCreatePostLikeId:string ="";
  singlePostId: string = '';
  apiGetUserDataId:string = "";
  apiGetUserPostId: string = '';
  interestedSingleApiCallId:string = '';
  // Customizable Area End

    constructor(props: Props) {
      super(props);
            // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),

    ]
      // Customizable Area End

    this.state = {
       // Customizable Area Start
      postList: [],
      loading: false,
      postLoad:false,
      commentOpen:false,
      selectedPostId:0,
      pathRoute:false,
      tabbed:"Home",
      profileImageSrc:"",
      followerCount:0,
      newPostListCount:0,
      postCount:0,
      communityFlag:"false",
      info: {
        name:'',
        image: '',
        is_private: false,
        type: 'account',
      },
      communityData:{},
      is_Interested:false,
      tabValue: 0,
      filter: '', // for community tab filter panel
      modal : {
        hyperLinkModal: false,
        videoModal: '',
        editModal: false,
        textModal: false,
      },
      postId: '',
      newPost: false,
        // Customizable Area End

    };
    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
     // Customizable Area End
  }

  async receive(from: string, message: Message) {
        // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

        switch(apiRequestCallId){
          case this.listPostId:
            {
             this.handleGetPostList(responseJson)
            }
          break;
        case this.interestedSingleApiCallId:
            {
             this.interestedEventSucessCallBack(responseJson)
            }
          break;
        case this.singlePostId:
          {
            this.responseSinglePost(responseJson)
          }
          break;
        case this.apiGetUserDataId:
          {
            this.responseProfileData(responseJson)
          }
          break;
        default:return;
        }
       
    }
    
    // Customizable Area End
  }
  // Customizable Area Start
  editedPost=(id:string)=>{
    const type: IPost = this.state.postList.find((item: { id: string; }) =>item.id===id) as IPost
    const isVideo = type.attributes.post_type === 'Vimeo' || type.attributes.post_type ==='YouTube';
    const editModal = type.attributes.post_type === 'image';
    const textModal = type.attributes.post_type === 'text';
    this.setState({modal: {hyperLinkModal: type.attributes.post_type === 'url',textModal: textModal,videoModal : isVideo ?type.attributes.post_type:'',editModal: editModal },postId: type.id})
  }
  async componentDidMount() {
    super.componentDidMount();
    this.setState({loading:true})
    this.apiGetUserDetails()
    await this.getPostList();
  }

  apiGetUserDetails = async () => {
    let signupToken = await getStorageData('SignupSuccessToken')
    let loginToken = await getStorageData('LoginSuccessToken')
    const isLA =await isLocalAdmin()
    const header = {
      token: signupToken ?? loginToken
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiGetUserDataId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        isLA ? configJSON.getCommunityProfileDataApiEndPoint : configJSON.getUserProfileDataApiEndPoint
        );

     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.getApiMethode
     );
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
     );

     runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  responseProfileData = (response: {data:{attributes: {name: string,profile_pic:string,profile_picture:string,full_name:string,is_private:boolean}}}) => {
    if(response.data){
      if(response.data.attributes.name){
        this.setState({info: {name:response.data.attributes.name,image:response.data.attributes.profile_pic,is_private:false,type: 'community'}})
      }else {
        this.setState({info: {name:response.data.attributes.full_name,image:response.data.attributes.profile_picture,is_private:response.data.attributes.is_private,type:'account'}})
      }
    }
  }

  // for my community tab post list
  handleMycommunityTabApi = async (filter: string) => {
    let signupToken = await getStorageData('SignupSuccessToken')
    let loginToken = await getStorageData('LoginSuccessToken')
    let Page_size= Math.ceil(this.state.postList.length/10) + 1;
    const header = {
      token: signupToken || loginToken,
      "Content-Type": "application/JSON",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.listPostId = requestMessage.messageId;
      const param = filter ? `?filter_type=${filter}&page=${Page_size}&per_page=10` : `?page=${Page_size}&per_page=10`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.community_data + param
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethode
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleInterestedEvent =(item : any)=>{ 
    this.setState({ is_Interested: !this.state.is_Interested ? true : false }, () => {
      this.interestedsingleEvent(item)
   }) 
  }
  eventDetailNavigation = (item:any)=>{
  localStorage.setItem('EventId', item.attributes.id)
  this.props.navigation.navigate('CalenderEventDetails',{id: item.attributes.id})
  }

  interestedsingleEvent = async (item:any) => {
    let formdata = new FormData();
    formdata.append("event_id",item);
    formdata.append("is_interested", JSON.stringify(this.state.is_Interested));

    this.interestedSingleApiCallId = await ApiCall({
      method: "POST",
      endPoint: `/bx_block_events/events/event_make_interested`,
      body: formdata,
      type: 'formData'
    });
  };

  interestedEventSucessCallBack = (res: any) => {
    if(res?.data){
      // this.getApiRequest()
      toast.success(res.data[0].message)
    }
  };
  
  handleGetPostItemSrc = (item: any) => {
    let src: unknown = null;
    if (item && item.attributes && item.attributes.images_and_videos) {
      src = item.attributes.images_and_videos;
    }
    if (item && item.attributes && item.attributes.thumbnail) {
      src = item.attributes.thumbnail;
    }
    if (item && item.attributes && item.attributes.cover_image) {
      src = item.attributes.cover_image;
    }
    return src;
  }  

  handleGetPostItemUserProfilePic = (item : any) =>{
    let profile :  any =  PostUser;

   if (item&& item.attributes&&item.attributes.profile_picture) {
     profile =item.attributes.profile_picture}

 
     if (item&& item.attributes&&item.attributes.community_profile) {
      profile =item.attributes.community_profile
    }

    
 

      
    return profile
  }

 handleGetPostList = async (responseJson: {data:IPost[]}) => {
  this.setState({loading: false})
  if( responseJson  && responseJson.data){
    this.setState({ postList:[...this.state.postList,...responseJson.data],newPostListCount:responseJson.data.length});
  }else{
    this.setState({newPostListCount: 0});
  } 
}


  getPostList = async() => {
   if(this.props.filter) return this.handleMycommunityTabApi(this.state.filter);

   let Page_Limit=10;

   let Page_size= Math.ceil(this.state.postList.length/Page_Limit) + 1;

    let signupToken = await getStorageData('SignupSuccessToken')
    let loginToken = await getStorageData('LoginSuccessToken')
 
    const header = {
      token: signupToken || loginToken,
      "Content-Type": "application/JSON",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.listPostId = requestMessage.messageId;

 
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/posts/posts?page=${Page_size}&per_page=${Page_Limit}`
      );
 

   

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

 
  
deletePost=(id:string)=>{
  const updatedList = this.state.postList.filter(item => (item.type === 'event' || item.id !== id) )
  this.setState({postList: updatedList})
  this.props.resetData();
}
  
followUpdateList = (type: string, id: string | number, action: string) => {
  const updatedList = this.state.postList.map((item): IPost | IEvent => {
      if ("attributes" in item && "account_id" in item.attributes && type === "account" && item.attributes.account_id === id) {
          const updatedAttributes = { ...item.attributes, is_followed_by_login_user: action === "follow" };
          return { ...item, attributes: updatedAttributes } as IPost;
      } else if ("attributes" in item && "community_id" in item.attributes && item.attributes.community_id === id) {
          const updatedAttributes = { ...item.attributes, is_followed_by_login_user: action === "follow" };
          return { ...item, attributes: updatedAttributes } as IPost;
      } else {
          return item;
      }
  });
  this.setState({ postList: updatedList });
};

 a11yProps(index: number) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}
handleChangeTab = (_event: React.ChangeEvent<{}>, newValue: number) => {
  const stateUpdates: {[key: number]: string} = {
    0: '',
    1: 'photos',
    2: 'videos',
    3: 'events',
    4: 'urls',
  };
  
  this.setState({ tabValue: newValue,filter: stateUpdates[newValue],postList:[]});
  this.handleMycommunityTabApi(stateUpdates[newValue])
};

navigateToEvents = (id: string|number) => {
  this.props.navigation.navigate("CalendarEventDetails",{id:id})
}

  getItem = async (id: string, type: string) => {
    const newItem = type === 'new' || type === 'event';
    this.setState({ loading: true, newPost: newItem })
    const loginToken2 = await getStorageData('LoginSuccessToken')
    const signupToken1 = await getStorageData('SignupSuccessToken')
    const header = {
      token: signupToken1 || loginToken2
    };
    const getAlonePost = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getAlonePost.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      type==="event" ? configJSON.events + id : configJSON.post + id
    );

    this.singlePostId = getAlonePost.messageId;

    getAlonePost.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethode
    );

    getAlonePost.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(getAlonePost.id, getAlonePost);
  }

  closeModal = () => {
    this.setState({ modal: { hyperLinkModal: false, videoModal: '', editModal: false, textModal: false } })
  }

  responseSinglePost = (responseJson: { data: IPost }) => {
    this.setState({ loading: false });
    if (responseJson.data) {
      if(this.state.newPost){
        const updatedPostList = [responseJson.data,...this.state.postList];
       this.setState({ postList: updatedPostList });
        this.props.resetData();
      }else {
      const updatedPostList: (IPost | IEvent)[] = this.state.postList.map((item: IPost | IEvent) => {
        if (item.id === responseJson.data.id && responseJson.data.type === 'post') {
          const updatedItem: IPost = {
            ...item,
            attributes: {
              ...item.attributes,
              description: responseJson.data.attributes.description,
              url: responseJson.data.attributes.url,
              post_type: responseJson.data.attributes.post_type,
              thumbnail: responseJson.data.attributes.thumbnail,
              images_and_videos: responseJson.data.attributes.images_and_videos,
              post_like_count: responseJson.data.attributes.post_like_count,
              comments_count: responseJson.data.attributes.comments_count,
              is_liked: responseJson.data.attributes.is_liked,
              post_save_by_current_user: responseJson.data.attributes.post_save_by_current_user,
              video_title: responseJson.data.attributes.video_title,
              notification_turn_on: responseJson.data.attributes.notification_turn_on,
              video_thumbnail: responseJson.data.attributes.video_thumbnail,
              account_id: responseJson.data.attributes.account_id,
              community_id: responseJson.data.attributes.community_id
            }
          } as IPost;
          return updatedItem;
        } else {
          return item;
        }
      });
      this.setState({ postList: updatedPostList });
    }
    }
  }

 // Customizable Area End
}
