import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
// Customizable Area Start
import { toast } from "react-toastify";
import ApiCall from "../../../components/src/ApiCall.web";
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
export interface ResponseEventDetails {
  attributes: {
    id: number,
    event_type: string,
    event_name: string,
    event_url: string,
    timezone: string,
    description: string,
    start_date: string,
    end_date: string,
    community_name: null| string,
    street: null | string,
    city: string,
    state: string,
    zipcode: string,
    creating_time: string,
    start_time: string,
    end_time: string,
    cover_image: string,
    is_interested: boolean,
    is_saved: boolean,
    notification_turn_off: boolean,
    full_name: string|null,
    is_login_user_event: boolean,
  };
}
export interface EventDetailsError {
  errors: [{ token: string }]
}
export interface SavePostResponse {
  data: [
    {
      message: string
    }
]
}
export interface SavePostError{
  errors: [ { message: string }]
}

export interface InterestedEventResponse {
  data: [
    {
        message: string
    }
]
}

export interface InterestedErrorResponse {
  errors: [
    {
        message: string
    }
]
}

export interface NotificationONOFFResponse {
  data: [
    {
        message: string
    }
]
}

export interface NotificationONOFFError {
  errors: [
    {
        message: string
    }
]
}

export interface ShareEventResponse {
  data: [
    {
        message: string
    }
]
}

export interface ShareEventError {
  errors: [
    {
        token: string
    }
]
}

export interface CopyLInkResponse {
  data: [
    {
        message: string
    }
]
}
export interface CopyLinkError {
  errors: [
    {
        token: string
    }
]

}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: HTMLElement | null;
  token: string;
  allDetails: ResponseEventDetails,
  anchorEl2: HTMLElement | null;
  Interested: boolean;
  ID: string;
  savePost: boolean;
  is_Notification: boolean;
  toggle_Notification: string;
  loading:boolean;
  editEvent: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class CalenderEventsDetailsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getEventDetailApiCallId: string = "";
  EventsAPICallId: string = "";
  deleteEventApiId: string = "";
  interestedEventApiCallId: string = "";
  saveEventpostApiCallId: string = "";
  eventNotificationApiCallId: string = "";
  sharePostApiCallId: string = "";
  copyAPICallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      allDetails: {
        attributes: {
          id: NaN,
          event_type: '',
          event_name: '',
          event_url: '',
          timezone: '',
          description: '',
          start_date: '',
          end_date: '',
          street: null,
          city: '',
          state: '',
          zipcode: '',
          creating_time: '',
          start_time: '',
          community_name: '',
          end_time: '',
          cover_image: '',
          is_interested: false,
          is_saved: false,
          notification_turn_off: false,
          full_name: '',
          is_login_user_event: false,
      }},
      token: "",
      anchorEl: null,
      anchorEl2: null,
      Interested: false,
      ID: "",
      savePost: false,
      is_Notification: false,
      toggle_Notification: "",
      loading:false,
      editEvent: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.setState({ ID: this.props.navigation.getParam("id") },()=>this.getApiRequest())
    
  }

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null, anchorEl2: null });
  };
  handleClickMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      this.setState({ anchorEl2: event.currentTarget });
    }
  };

  handleListKeyDown(event: { key: string }) {
    if (event.key === 'Tab') {
      this.setState({ anchorEl: null, anchorEl2: null });
    }
  }

  handleinterested = () => {
    this.setState({ Interested: !this.state.Interested })
    this.interestedEventpostapi()
  }

  navigateToReport = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Report");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationScreenNameMessage),
      this.state.ID
    );
    this.send(message);
    this.setState({ anchorEl: null, anchorEl2: null });
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (responseJson && !responseJson.errors) {
        if(apiRequestCallId === this.deleteEventApiId) return this.deleteApiResponse(responseJson)
        this.responseSucessCell(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.responseFailureCall(apiRequestCallId, responseJson);
      }
    }
  }

  responseSucessCell = async (apiRequestCallId: string, responseJson: {data:ResponseEventDetails} & SavePostResponse & InterestedEventResponse & NotificationONOFFResponse & ShareEventResponse & CopyLInkResponse)  => {
    if (apiRequestCallId === this.EventsAPICallId) {
      this.calenderEventsSucessDetailsCallBack(responseJson)
    }
    if (apiRequestCallId === this.interestedEventApiCallId) {
      this.interestedEventsSucessCallBack(responseJson)
    }
    if (apiRequestCallId === this.saveEventpostApiCallId) {
      this.saveEventsPostSucessCallBack(responseJson)
    }
    if (apiRequestCallId === this.eventNotificationApiCallId) {
      this.eventsNotificationSucessCallBack(responseJson)
    }
    if (apiRequestCallId === this.sharePostApiCallId) {
      this.sharePostEventSucessCallBack(responseJson)
    }
    if (apiRequestCallId === this.copyAPICallId) {
      this.copyLinkEventSucessCallBack(responseJson)
    }
  };

  responseFailureCall = async (apiRequestCallId: string, responseJson: EventDetailsError & SavePostError & InterestedErrorResponse & NotificationONOFFError & ShareEventError & CopyLinkError) => {
    if (apiRequestCallId === this.EventsAPICallId) {
      this.calenderEventsDetailsFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.interestedEventApiCallId) {
      this.interestedEventsFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.saveEventpostApiCallId) {
      this.saveEventsPostFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.eventNotificationApiCallId) {
      this.evenstNotificationFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.sharePostApiCallId) {
      this.sharePostEventFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.copyAPICallId) {
      this.copyLinkEventFailureCallBack(responseJson);
    }
  };

  getApiRequest = async () => {
    this.setState({loading:true})
    this.EventsAPICallId = await ApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_events/events/${this.state.ID}`,
    });
  };

  calenderEventsSucessDetailsCallBack = (res: {data: ResponseEventDetails}) => {
    const geteventData = res.data.attributes
    
    this.setState({ allDetails: res.data, Interested: geteventData.is_interested, savePost: geteventData.is_saved, is_Notification: geteventData.notification_turn_off ,loading:false})
  };

  calenderEventsDetailsFailureCallBack = (res: EventDetailsError) => {
    this.setState({loading:false})
    toast.success(res.errors[0].token)
  };

  interestedEventpostapi = async () => {
    let formdata = new FormData();
    formdata.append("event_id", this.state.ID);
    formdata.append("is_interested", JSON.stringify(!this.state.Interested));
    this.interestedEventApiCallId = await ApiCall({
      method: "POST",
      endPoint: `/bx_block_events/events/event_make_interested`,
      body: formdata,
      type: 'formData'
    });
  };

  interestedEventsSucessCallBack = (res: InterestedEventResponse) => {
    if (res?.data) {
      this.getApiRequest()
      toast.success(res.data[0].message)
    }
  };

  interestedEventsFailureCallBack = (res: InterestedErrorResponse) => {
    toast.error(res.errors[0].message)
  };

  saveEventpostapi = async () => {
    let formdata = new FormData();
    formdata.append("event_id", this.state.ID);
    formdata.append("is_saved", JSON.stringify(!this.state.savePost));
    this.saveEventpostApiCallId = await ApiCall({
      method: "POST",
      endPoint: `/bx_block_events/events/save_event`,
      body: formdata,
      type: 'formData'
    });
  };

  saveEventsPostSucessCallBack = (res: SavePostResponse) => {
    if (res?.data) {
      this.getApiRequest()
      toast.success(res.data[0].message)
    }
  };

  saveEventsPostFailureCallBack = (res: SavePostError) => {
    toast.error(res.errors[0].message)
  };

  handalEditPost = () => {
    this.setState({editEvent: true});
  };

  handleCloseEventModal = () => {
    this.setState({editEvent: false});
    this.getApiRequest()
  }

  eventNotificationONOFF = async () => {
    let formdata = new FormData();
    formdata.append("event_id", this.state.ID);
    this.eventNotificationApiCallId = await ApiCall({
      method: "POST",
      endPoint: `/bx_block_events/events/turn_${this.state.toggle_Notification}_event_notification`,
      body: formdata,
      type: 'formData'
    });
  };

  eventsNotificationSucessCallBack = (res: NotificationONOFFResponse) => {
    if (res.data) {
      this.getApiRequest()
      toast.success(res.data[0].message)
    }
  };

  evenstNotificationFailureCallBack = (res: NotificationONOFFError) => {
    toast.error(res.errors[0].message)
  };

  sharePostApiCall = async () => {
    let formdata = new FormData();
    formdata.append("event_id", this.state.ID);
    this.sharePostApiCallId = await ApiCall({
      method: "POST",
      endPoint: `/bx_block_events/events/share_event`,
      body: formdata,
      type: 'formData'
    });
  };

  sharePostEventSucessCallBack = (res: ShareEventResponse) => {
    if (res.data) {
      this.getApiRequest()
      toast.success(res.data[0].message)
    }
  };

  sharePostEventFailureCallBack = (res: ShareEventError) => {
    toast.error(res.errors[0].token)
  };

  copyLink = async () => {
    this.copyAPICallId = await ApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/copy_post_link?id=${this.state.ID}&action_url=CalendarEventDetails`,
    });
  };

  copyLinkEventSucessCallBack = (res: CopyLInkResponse) => {
    if (res.data) {
      navigator.clipboard.writeText(res.data[0].message);
      this.setState({ anchorEl: null, anchorEl2: null });
      toast.success("Copied!")
      this.getApiRequest()
    }
  };

  copyLinkEventFailureCallBack = (res: CopyLinkError) => {
    toast.error(res.errors[0].token)
  };

  handleNotification = () => {
    if (this.state.is_Notification) {
      this.setState({ toggle_Notification: "off", anchorEl: null, anchorEl2: null }, () => this.eventNotificationONOFF())
    } else {
      this.setState({ toggle_Notification: "on", anchorEl: null, anchorEl2: null }, () => this.eventNotificationONOFF())
    }
  };

  handleSharePost = () => {
    this.sharePostApiCall()
    this.setState({ anchorEl: null, anchorEl2: null });
  };

  deleteEvent = async () => {
    this.setState({loading:true})
    let signupToken = await getStorageData('SignupSuccessToken');
    let loginToken = await getStorageData('LoginSuccessToken');
    const header = {
      token: signupToken ?? loginToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteEventApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.delete_event + `/${this.state.ID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteApiResponse = (responseJson:{data:{message:string}[]}) => {
    this.setState({loading:false})
    if(responseJson.data?.length>0){
      toast.success(responseJson.data[0].message)
      this.props.navigation.goBack();
    }
  }
  // Customizable Area End
}

