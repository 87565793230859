// Customizable Area Start
import { BlockComponent } from "../../framework/src/BlockComponent";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
import React from "react";
import moment from 'moment';
// Customizable Area End

// Customizable Area Start
export const locale = moment.locale('en');
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  format:string;
  error?: boolean;
  className?: string;
  selectedDate:any;
  disableFutureDate:boolean;
  disablePast?:boolean;
  handleChangeDate:any;
  calendarIcon:any;
  openCalendarIcon:any;
  placeHolderText:string;
  minDate?:string
  // Customizable Area End
}

interface S {
    // Customizable Area Start
  loading: boolean;
  openDialog:boolean;
  anchorEl:any;
  // Customizable Area End
}


interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomDatepickerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
 
  // Customizable Area End
    constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),

    ]

    this.state = {
      loading: false,
      openDialog:false,
      anchorEl:null
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
   
    // Customizable Area End
  }

 // Customizable Area Start
 renderDay = (date:any, selectedDate:any, dayInCurrentMonth:any, dayComponent:any) => {
  const momentDate = moment(date);
  const day = momentDate.format('ddd');
  return React.cloneElement(dayComponent, { label: day });
};
  
  handleTextFieldClick = (event:any) => {
  this.setState({ openDialog : true , anchorEl : event.currentTarget });
  };

  handleDialogClose = () => {
    this.setState({ openDialog : false , anchorEl : null });
  };

  handleSelectDate = (e : any) => {
    this.props.handleChangeDate(e)
    this.handleDialogClose()
  }

 // Customizable Area End
}