import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { isLocalAdmin } from "../../../components/src/common";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    classes?: any;
    data: {
        id: string,
        attributes: {
          friend_list_count: number,
          follower_count: number,
          background_image: string | null,
          profile_picture: string,
          full_name: string,
          email: string,
          is_friend_request_sent: boolean,
          is_already_request_available: boolean,
          is_in_connection: boolean,
          is_followed_by_login_user: boolean,
          city: string,
          state: string,
          job_title: string,
          description?: string,
        }
      };
    is_private: boolean;
    isCommunity:boolean;
    isOwnProfile: boolean;
    hideBorder?: boolean;
    handleAddFriend?: () => void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    data: {
      id: string,
      attributes: {
        friend_list_count: number,
        follower_count: number,
        profile_picture: string,
        background_image: string | null,
        full_name: string,
        email: string,
        is_already_request_available: boolean,
        is_friend_request_sent: boolean,
        is_in_connection: boolean,
        is_followed_by_login_user: boolean,
        job_title: string,
        city: string,
        state: string,
        description?: string,
      }
    };
    loading: boolean;
    is_private: boolean;
    hide_Request_forcommunity: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
// Customizable Area Start
export default class UserProfileWallpaperSectionController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getUserSIDId: string = "";
    followUnfollowUserId: string ="";
    updateUserId: string = "";
    sendFriendRequestApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        
        this.state = {
          data: {
            id: "",
            attributes: {
              friend_list_count: 0,
              follower_count: 0,
              background_image: null,
              profile_picture: "",
              full_name: "",
              email: "",
              is_friend_request_sent: false,
              is_already_request_available: false,
              is_in_connection: false,
              is_followed_by_login_user: false,
              city: '',
              job_title: '',
              state: '',
              description: '',
            }
          },
            loading: false,
            is_private: false,
            hide_Request_forcommunity: false,
        }

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
          ];
      
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    
    async receive(_from: string, message: Message) {
                  runEngine.debugLog("Message Recived", message);
        // Customizable Area Start

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
        this.setState({loading:false})
      if (responseJson) {
        if (apiRequestCallId === this.getUserSIDId) {
          this.handleMessageresponse(responseJson)
        }
      }
      if(responseJson && responseJson.data){
        if (apiRequestCallId === this.followUnfollowUserId) {
            this.followUnfollowResponse(responseJson)
        }
        if (apiRequestCallId === this.updateUserId) {
            this.setState(prevState => ({
              data: {
                ...prevState.data,
                attributes: {
                  ...prevState.data.attributes,
                  background_image: responseJson.data.attributes.background_image ?? responseJson.data.attributes.background_profile_pic
                }
              }
            }));
        }
        if (apiRequestCallId === this.sendFriendRequestApiCallId) {
            toast.success(responseJson.message);
            this.setState(prevState => ({
              data: {
                ...prevState.data,
                attributes: {
                  ...prevState.data.attributes,
                  is_friend_request_sent: true
                }
              }
            }));
        }
      }
        // Customizable Area End
 }

    async componentDidMount(){
      super.componentDidMount();
      const isLA = await isLocalAdmin();
      this.setState({data:this.props.data,is_private:this.props.is_private,hide_Request_forcommunity:isLA})
    }

 componentDidUpdate(prevProps: Readonly<Props>): void {
  if(this.props.data !== prevProps.data){
    this.setState({data:this.props.data})
  }
  if(this.props.is_private !== prevProps.is_private){
    this.setState({is_private:this.props.is_private})
  }
 }

 handleMessageresponse = (responseJson:{conversations:{conversation_sid:string}[]}) => {
  if (responseJson.conversations) {
    const msg = new Message(getName(MessageEnum.NavigationChatViewMessage));
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    )
    msg.addData(
      getName(MessageEnum.UrlPathParamMessage),
      responseJson.conversations[0].conversation_sid
    )
    this.send(msg)
  }
 }

  handleMessageClick = async () => {
    this.setState({ loading: true })
    if (this.props.data.attributes.is_in_connection) {
      const getUserSID = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      getUserSID.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.privateConversation
      );

      getUserSID.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.post
      );
      let body = new FormData();
      body.append("ids[account_id][]", (this.props.data.id).toString())
      getUserSID.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
      const signupToken = await getStorageData('SignupSuccessToken');
      const loginToken = await getStorageData('LoginSuccessToken');
      const header = {
        token: signupToken || loginToken,
      }
      getUserSID.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      this.getUserSIDId = getUserSID.messageId;
      runEngine.sendMessage(getUserSID.id, getUserSID);
    }
  }

  handleFollowUnfollow = async () => {
    this.setState({ loading: true });
    if (this.state.data.attributes.is_followed_by_login_user) {
      return this.removefollower()
    }
    const loginToken = await getStorageData('LoginSuccessToken');
    const signupToken = await getStorageData('SignupSuccessToken');
    const followUnfollowUser = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    followUnfollowUser.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.create_follower
    );

    followUnfollowUser.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.post
    );
    let body = new FormData();
    if(this.props.isCommunity){
      body.append("community_id", (this.props.data.id).toString())
    } else {
      body.append("account_id", (this.props.data.id).toString())
    }
    followUnfollowUser.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    const header = {
      token: signupToken || loginToken,
    }
    followUnfollowUser.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.followUnfollowUserId = followUnfollowUser.messageId;
    runEngine.sendMessage(followUnfollowUser.id, followUnfollowUser);
  }

  removefollower = async () => {
    const loginToken = await getStorageData('LoginSuccessToken');
    const signupToken = await getStorageData('SignupSuccessToken');
    const removefollowerApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const param = this.props.isCommunity ? `?community_id=${this.props.data.id}` : `?unfollow_type=post_unfollow&account_id=${this.props.data.id}`;
    removefollowerApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.remove_follower + param
    );

    removefollowerApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.removeAchievementApiMethod
    );

    const header = {
      token: signupToken || loginToken,
    }

    removefollowerApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.followUnfollowUserId = removefollowerApi.messageId;
    runEngine.sendMessage(removefollowerApi.id, removefollowerApi);
  }

  followUnfollowResponse = (responseJson : {data:{message:string}}) => {
    if(responseJson.data.message){
      toast.success(responseJson.data.message);
      this.setState(prevState => ({
        data: {
          ...prevState.data,
          attributes: {
            ...prevState.data.attributes,
            is_followed_by_login_user: !prevState.data.attributes.is_followed_by_login_user
          }
        }
      }));
    }
  }

  sendFriendRequest = async () => {
    if(!this.state.data.attributes.is_friend_request_sent || !this.state.data.attributes.is_in_connection){
    this.setState({ loading: true })
    const loginToken = await getStorageData('LoginSuccessToken');
    const signupToken = await getStorageData('SignupSuccessToken');
    const data = {
      data: {
        account_id: this.props.data.id,
        request_text: ""
      }
    }
    const header = {
      token: signupToken || loginToken,
      "Content-Type": configJSON.apiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendFriendRequestApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendRequestApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.post
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data),
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  backgroundUpdate = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const formdata = new FormData();
      this.props.isCommunity ? formdata.append("background_profile_pic", e.target.files[0]) : formdata.append("account[background_image]", e.target.files[0]);
      this.setState({ loading: true })
      const loginToken = await getStorageData('LoginSuccessToken');
      const signupToken = await getStorageData('SignupSuccessToken');
      const updateUserApi = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      const param = this.props.isCommunity ? configJSON.getOtherCommunityApiEndpoint + `/${this.props.data.id}` : configJSON.updateUser;
      updateUserApi.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        param
      );

      updateUserApi.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );

      updateUserApi.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.changeWallpaperApiMethod
      );

      const header = {
        token: signupToken || loginToken,
      };

      updateUserApi.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      this.updateUserId = updateUserApi.messageId;
      runEngine.sendMessage(updateUserApi.id, updateUserApi);
    }
  }

  navigateEditPage = () => {
    if (this.props.isCommunity) {
      const message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData(getName(MessageEnum.NavigationTargetMessage), "LinkedAccounts")

      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      message.addData(
        getName(MessageEnum.NavigationScreenNameMessage),
        "creation"
      )
      this.send(message);
    } else {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "UserProfileInformation");

      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(
        getName(MessageEnum.NavigationScreenNameMessage),
        "view-profile"
      );
      this.send(message);
    }
  }

  navigateFriends = () =>{
    this.props.navigation.navigate("FriendsList",{id:this.props.data.id})
  }

  navigateContactInfo = () =>{
    this.props.isCommunity ? this.props.navigation.navigate("ContactInfo",{id:`${this.props.data.id}-community`}) : this.props.navigation.navigate("ContactInfo",{id:this.props.data.id})
  }

  navigateFollowers = () => {
    this.props.isCommunity ? this.props.navigation.navigate("FollowersList",{id:`community-${this.props.data.id}`}) : this.props.navigation.navigate("FollowersList",{id:this.props.data.id})
  }
}
// Customizable Area End