import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { sendAPIRequest } from "../../../components/src/utils";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { Client, Message as Tmessage } from 'twilio-chat';
// Customizable Area Start
export interface ConversationApiType {
    conversation_sid: string,
    chat_id: string,
    group_icon: string | null,
    group_name: string,
    unique_name: string,
    chat_type: string,
    last_message: string,
    receipient_data: {
        data:
        {
            id: string,
            attributes: {
                receipient_name: string,
                twilio_identity: string,
                receipient_email: string,
                receipient_profile_pic: string
            },
            type: string,
        }[]
    },
    last_message_time: string,
    name: string,
    unread_messages_count: number,
    notification_level: string,
    twilio_identity: string,
    profile_pic: string,
    email: string,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    conversationData: ConversationApiType[];
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ChatController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    fetchToken: string = "";
    fetchConversationsId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            conversationData: [],
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            switch (apiRequestCallId) {
                case this.fetchToken:
                    {
                        this.handleTokenResponse(responseJson)
                    }
                    break;
                case this.fetchConversationsId:
                    {
                        this.handleConversationResponse(responseJson)
                    }
                    break;
                default: return;
            }
        }
    }

    handleTokenResponse = (responseJson: { data: { token: string } }) => {
        responseJson.data && localStorage.setItem("chatToken", responseJson.data.token);
    }

    handleConversationResponse = (responseJson: {
        conversations: ConversationApiType[]
    }) => {
        this.setState({ loading: false, conversationData: responseJson.conversations })
    }

    async componentDidMount() {
        super.componentDidMount();       
        this.setState({ loading: true })
        const signupToken = await getStorageData('SignupSuccessToken');
        const loginToken = await getStorageData('LoginSuccessToken');
        const sameHeader: { method: "GET", headers: { "Content-Type": string, token: string } } = {
            method: configJSON.getApiMethod,
            headers: {
                "Content-Type": configJSON.apiContentType,
                token: signupToken || loginToken
            }
        }
        this.fetchToken = sendAPIRequest(configJSON.generateToken, sameHeader);
        this.fetchConversationsId = sendAPIRequest(configJSON.fetchConversations, sameHeader);
        if (!localStorage.getItem("chatToken")) return
        this.handleChats();
    }

    handleChats = () => {
        const client = new Client(localStorage.getItem("chatToken") as string);
        client.on('stateChanged', (state) => {
            if (state === 'initialized') {
                client.on("messageAdded", (message: Tmessage) => {
                    const { body, dateCreated } = message;
                    const channelSid = message.channel.sid;
                    const last = body ??  "Media";
                    const temp = this.state.conversationData.map(item => {
                        if (item.conversation_sid === channelSid) {
                            return { ...item, unread_messages_count: item.unread_messages_count + 1, last_message: last, last_message_time: String(dateCreated) }
                        } else {
                            return item
                        }
                    });
                    this.setState({ conversationData: temp });
                });
            }
        });

    }

    transferData = () => {
        const msg = new Message(getName(MessageEnum.NavigationCreateChatMessage));
        msg.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );
        msg.addData(
            getName(MessageEnum.UrlPathParamMessage),
            ""
        );
        this.send(msg)
    };

    handleOpenChat = (item: ConversationApiType) => {
        const msg1 = new Message(getName(MessageEnum.NavigationChatViewMessage));
        msg1.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );
        msg1.addData(
          getName(MessageEnum.UrlPathParamMessage),
          item.conversation_sid
        );
        this.send(msg1)
    }

     formatChatListDate=(dateString: string): string=> {
        const date = new Date(dateString);
        const currentDate = new Date();
        const yesterday = new Date();
        yesterday.setDate(currentDate.getDate() - 1);
      
        // Check if the date is today
        if (date.toDateString() === currentDate.toDateString()) {
          const hours = date.getHours().toString().padStart(2, '0');
          const minutes = date.getMinutes().toString().padStart(2, '0');
          return `${hours}:${minutes}`;
        }
      
        // Check if the date is yesterday
        if (date.toDateString() === yesterday.toDateString()) {
          return 'Yesterday';
        }
      
        // Format the date as 'Month day, year'
        const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', year: 'numeric' };
        return date.toLocaleDateString(undefined, options);
      }
    // Customizable Area End
}
