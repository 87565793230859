import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");

import { BlockComponent } from "../../../framework/src/BlockComponent";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area Start
import { isLocalAdmin } from "../../../components/src/common";
import { toast } from "react-toastify";
interface IUser {
    id: string;
    type: string;
    attributes: {
        description?: string; // for community only
        activated: boolean;
        country_code: string;
        email: string;
        full_name: string;
        full_phone_number: string | number;
        job_title: string;
        phone_number: string;
        type: string;
        pronouns: string;
        language: string[];
        race: string;
        ethnicity: string;
        gender: string;
        state: string;
        is_private: boolean;
        city: string;
        country: string; // Type depends on your country object structure
        dob: string;
        timezone: string;
        is_local_admin: boolean;
        created_at: string;
        organization: {
            id: number,
            address: string,
            email: string,
            name: string,
            website: string,
            contact_number: string,
            created_at: string,
            updated_at: string,
            account_id: number,
        } | null;
        updated_at: string;
        device_id: string | null;
        profile_picture: string;
        background_image: string | null;
        follower_count: number;
        is_Community_present: boolean;
        social_media_urls: {
            account_id: number;
            created_at: string;
            id: number;
            updated_at: string;
            url: string;
        }[];
        selected_communities: { data: { id: string, attributes: { profile_pic: string, name: string } }[] } | null;
        achievements: {
            data: {
                id: string;
                type: string;
                attributes: IAchievements;
            }[];
        };
        friend_list_count: number;
        is_in_connection: boolean;
        is_friend_request_sent: boolean;
        is_already_request_available: boolean;
        is_biometric_or_facial_available: boolean;
        is_followed_by_login_user: boolean;
        total_post_count: number;
    }
}
export interface IAchievements {
    issue_month: number | null;
    issue_year: number | null;
    organisation_name: string | null;
      title: string | null;
      expiration_year: number | null;
      ceu_gained: string | null;
      expiration_month: number | null;
      credential_url: string | null;
      is_bulk_upload: boolean;
      description: string | null;
      account_id: number;
      community_id: string | null;
      profile_picture: string;
      full_name: string;
      achievement_files: {
        data: {
            type: string;
          id: string;
          attributes: {
            title: string;
            description: string;
            achievement_images: {
                file_exetension: string;
                file_name: string;
              url: string;
            }[];
          };
        }[];
      };
      custom_form_file_extension: string | null;
      custom_form_file: string | null;
      custom_form_file_name: string | null;
}
// Customizable Area End


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    mutualConnectionData: IMutualConnectionData;
    showLoading: boolean;
    data: IUser;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export interface IMutualConnectionData{
    mutual_connection_count: number;
    mutual_connection_message: string;
    accounts_data: {
        id: number;
        name: string;
        photo: string;
    }[]
}


export default class ViewOtherUserProfileController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getUserProfileDetailsApiCallId: string = "";
    getMutualConnectionsApiCallId: string = "";
    sendFriendRequestApiCallId: string = "";
    removeAchievementApiCallId: string = "";
    loginToken: string = "";
    signupToken: string = "";
    userId: string = "";
    isCommunity: boolean =false;
    isOwnProfile: boolean= false;
    apiGetComunityId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);

        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            // Customizable Area End
        ]
        this.state = {
            showLoading: false,
            mutualConnectionData: {
                mutual_connection_count: 0,
                mutual_connection_message: "",
                accounts_data:[]
            },
            data: {
                id: "",
                type: "",
                attributes: {
                    description: '',
                    activated: false,
                    country_code: "",
                    email: "",
                    full_phone_number: "",
                    full_name: "",
                    job_title: "",
                    phone_number: "",
                    type: "",
                    pronouns: "",
                    language: [],
                    race: "",
                    ethnicity: "",
                    gender: "",
                    state: "",
                    is_private: false,
                    city: "",
                    country: "",
                    dob: "",
                    organization: null,
                    timezone: "",
                    is_local_admin: false,
                    created_at: "",
                    updated_at: "",
                    device_id: null,
                    profile_picture: "",
                    background_image: null,
                    follower_count: 0,
                    is_Community_present: false,
                    selected_communities: null,
                    friend_list_count: 0,
                    is_in_connection: false,
                    is_friend_request_sent: false,
                    is_already_request_available: false,
                    is_biometric_or_facial_available: false,
                    is_followed_by_login_user:false,
                    total_post_count: 0,
                    achievements: {data:[]},
                    social_media_urls: [],
                }
            }
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)

    }

    async receive(_from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
    
        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId === this.getUserProfileDetailsApiCallId) {
            this.setState({showLoading: false})
            if (responseJson?.data?.attributes) {
                this.setState({ data: responseJson.data })
            }else if(responseJson.data.is_private){
                const {id,is_private,profile_picture,background_image,name,is_friend_request_sent} = responseJson.data
                this.setState({
                    data:{...this.state.data,id:id,attributes:{...this.state.data.attributes,is_private,profile_picture,background_image,is_friend_request_sent,full_name:name}}
                })
            }
        }

        if(apiRequestCallId === this.getMutualConnectionsApiCallId){
            if(responseJson && responseJson.mutual_connection_message){
                this.setState({
                    mutualConnectionData:{
                        mutual_connection_count: responseJson.mutual_connection_count,
                        mutual_connection_message: responseJson.mutual_connection_message,
                        accounts_data: responseJson.accounts_data
                    }
                })
            }
        }

        if(apiRequestCallId === this.getMutualConnectionsApiCallId){
            if(responseJson && responseJson.mutual_connection_message){
                this.setState({
                    mutualConnectionData:{
                        mutual_connection_count: responseJson.mutual_connection_count,
                        mutual_connection_message: responseJson.mutual_connection_message,
                        accounts_data: responseJson.accounts_data
                    }
                })
            }
        }

        if(apiRequestCallId === this.apiGetComunityId){
            if(responseJson && responseJson.data){
                this.getCommunityResponse(responseJson)
            }
        }

        if(apiRequestCallId === this.removeAchievementApiCallId){
            this.achivenmentDeleteResponse(responseJson)
        }
        // Customizable Area End
    }


    // Customizable Area Start
    getCommunityResponse = (responseJson: {
        data: {
        id: string
        type: string,
        attributes: {
            name: string,
            email: string,
            phone_number: number,
            website: string,
            description: string,
            account_id: number,
            is_notification_available: boolean,
            profile_pic: string,
            background_profile_pic: null | string,
            post_count: number,
            follower_count: number,
            is_followed_by_user: boolean,
            achievements:{
                data: {
                    id: string;
                    type: string;
                    attributes: IAchievements;
                }[];
            }
        }
    }}) => {
        this.setState({showLoading:false})
        if(responseJson.data){
            const { email, name, phone_number,description, profile_pic, background_profile_pic, post_count, follower_count, is_followed_by_user, achievements } = responseJson.data.attributes
            this.setState({data:{...responseJson.data,attributes:{...this.state.data.attributes,email,total_post_count:post_count,follower_count,profile_picture:profile_pic,background_image:background_profile_pic,is_followed_by_login_user:is_followed_by_user,full_name:name,full_phone_number:phone_number,description:description,achievements:achievements}}})
        }
    }

    achivenmentDeleteResponse = (responseJson: { data: {message: string}[]}) => {
        if(responseJson && responseJson.data){
            toast.success(responseJson.data[0].message);
            this.handleUserIdChange();
        }
    }

    async componentDidMount() {
        super.componentDidMount();
        const { signupToken, loginToken } = await this.getTokens();
        this.signupToken = signupToken;
        this.loginToken = loginToken;
        this.handleUserIdChange();
    }

    handleUserIdChange = async () => {
        const param = this.props.navigation.getParam("id");
        const { profileData } = await this.getTokens();
        if (!param) {
            const isLA = await isLocalAdmin();
            this.isCommunity = isLA;
            this.isOwnProfile = true;
            this.userId="";
            if (isLA) {
                this.GetCommunityInfo();
            } else {
                this.isCommunity = false;
                this.getUserInfo();
            }
            return;
        }
        
        if (param.toLowerCase().includes("community")) {
            this.isOwnProfile = profileData.id === param.split('-')[0];
            this.userId = param;
            this.isCommunity = true;
            this.GetCommunityInfo();
        } else {
            this.isOwnProfile = profileData.id === param;
            this.userId = param;
            this.isCommunity = false;
            this.getUserInfo();
            this.getMutualConnections();
        }
        this.setState({
            data: {
                id: "",
                type: "",
                attributes: {
                    activated: false,
                    country_code: "",
                    full_phone_number: "",
                    email: "",
                    job_title: "",
                    full_name: "",
                    phone_number: "",
                    type: "",
                    language: [],
                    pronouns: "",
                    race: "",
                    gender: "",
                    ethnicity: "",
                    state: "",
                    is_private: false,
                    country: "",
                    city: "",
                    dob: "",
                    organization: null,
                    timezone: "",
                    is_local_admin: false,
                    updated_at: "",
                    created_at: "",
                    device_id: null,
                    profile_picture: "",
                    background_image: null,
                    is_Community_present: false,
                    follower_count: 0,
                    selected_communities: null,
                    friend_list_count: 0,
                    is_friend_request_sent: false,
                    is_in_connection: false,
                    is_already_request_available: false,
                    is_biometric_or_facial_available: false,
                    total_post_count: 0,
                    is_followed_by_login_user: false,
                    social_media_urls: [],
                    achievements: { data: [] },
                }
            }, mutualConnectionData: {
                mutual_connection_message: "",
                mutual_connection_count: 0,
                accounts_data: []
            }
        });
    };
    

    componentDidUpdate(): void {
        const newUserId = this.props.navigation.getParam("id") ?? ""
        if (this.userId !== newUserId) {
            this.userId=newUserId;
            this.handleUserIdChange();// calls api and change userid
        }
    }

    getTokens = async () => {
        let signupToken = await getStorageData('SignupSuccessToken');
        let loginToken = await getStorageData('LoginSuccessToken');
        let profileData = await getStorageData('profileData',true);
        return { signupToken, loginToken, profileData };
    }

    getUserInfo = () => {
        this.setState({showLoading:true})
        const header = {
            token: this.signupToken || this.loginToken,
            "Content-Type": configJSON.apiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getUserProfileDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.userId ? configJSON.accountsAPiEndPointWeb + `/${this.userId}?is_post_invisible=true` : configJSON.getUserProfileDataApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getOtherProfileApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    GetCommunityInfo = () => {
        this.setState({showLoading:true})
        const header = {
            token: this.signupToken || this.loginToken,
            "Content-Type": configJSON.apiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiGetComunityId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.userId ? configJSON.getOtherCommunityApiEndpoint + `/${this.userId.split('-')[0]}?is_post_invisible=true` : configJSON.get_community + '?is_post_invisible=true'
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getOtherProfileApiMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getMutualConnections = () => {
        if(!this.isOwnProfile){
        const header = {
            token: this.signupToken || this.loginToken,
            "Content-Type": configJSON.apiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getMutualConnectionsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getMutualConnectionsApiEndpoint + `?user_id=${this.userId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMutualConnectionsApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }

    removeAchievement = (param: string) => {
        this.setState({showLoading:true})
        const header = {
            token: this.signupToken || this.loginToken,
            "Content-Type": configJSON.apiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.removeAchievementApiEndpoint + param
            );
            
        this.removeAchievementApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.removeAchievementApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}