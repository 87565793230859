import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start
import { handleErrorArray } from "../../../components/src/common";
import { getStorageData } from "../../../framework/src/Utilities";

export interface IUserDashboardData {
  data: {
    name: string,
    avg_score: string,
    attempt_quizzes: number,
    total_avg_score: number,
    quiz_details: {
      quiz_name: string,
      quiz_date: string,
      quiz_attempt: number
    }[],
    upcoming_test: {
      quiz_name: string,
      quiz_date: string
    }[]
  }
}

export interface IChartData{
  date: string,
  avg_score: string
}

export interface ICommunityData{
  name: string;
  date: string;
  score: number;
  total_score: number;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  chartTitle: string;
  userDashboardData: IUserDashboardData;
  dashboardTitle: string;
  chartData: IChartData[];
  communityData: ICommunityData[];
  // Customizable Area End
}

interface SS {
  id: any;

  // Customizable Area Start
  // Customizable Area End
}

export default class CommunityAssessmentDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  isCommunityDashboard: boolean = false;
  isIndividualUserDashboard: boolean = false;
  id: string = '';
  getUserDashboardDataApiCallId: string = "";
  getCommunityDashboardDatApiCallId: string = "";
  getAssessmentDetailsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);



    this.state = {
      isLoading: true,
      chartTitle: "",
      communityData: [],
      chartData: [
        
      ],
      userDashboardData: {
        data: {
          "name": "",
          "avg_score": "",
          "attempt_quizzes": 0,
          "total_avg_score": 0,
          "quiz_details": [
            
          ],
          "upcoming_test": [
            
          ]
        }
      },
      dashboardTitle: ''
    };

    // Customizable Area End

  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    this.setState({
      isLoading: false
    })

    switch(apiRequestCallId){
      case this.getUserDashboardDataApiCallId:
        this.handleUserDashaboardData(responseJson);
        break;
      case this.getCommunityDashboardDatApiCallId:
        this.handleCommunityDashboardData(responseJson);
        break;
      case this.getAssessmentDetailsApiCallId:
        this.handleGetAssessmentDetailsData(responseJson);
        break;
    }
    // Customizable Area End
  }





  // Customizable Area Start

  async componentDidMount() {
    const pathParam = String(this.props.navigation.getParam("navigationBarTitleText"));
    const splittedParams = pathParam.split("-");
    if (pathParam.includes("community")) {
      this.isCommunityDashboard = true;
      this.getAssessmentScoreForCommunity();
      this.getAssessmentDetails();
    } else {
      this.isIndividualUserDashboard = true;
      this.getAssessmentScoreForUser();
    }
    this.id = splittedParams[1]

  }

  getAssessmentScoreForUser = async () => {
    const loginToken = await getStorageData('LoginSuccessToken');
    const signupToken = await getStorageData('SignupSuccessToken');
    const headers = {
      token: signupToken || loginToken
    };

    const getUserData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getUserData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTestScoreBasedOnAccountIdApiEndpoint + `?account_id=${this.id}`
    );

    getUserData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAssessmentTestListAdminApiMethod
    );


    getUserData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    this.getUserDashboardDataApiCallId = getUserData.messageId;
    runEngine.sendMessage(getUserData.id, getUserData);
  }

  getAssessmentScoreForCommunity = async () => {

    const signupToken = await getStorageData('SignupSuccessToken');
    const loginToken = await getStorageData('LoginSuccessToken');
    const headers = {
      token: signupToken || loginToken
    };

    const getAssessmentData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAssessmentData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTestScoreBasedOnAssessmentApiEndpoint + `quiz_id=${this.id}`
    );

    getAssessmentData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAssessmentTestListAdminApiMethod
    );


    getAssessmentData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    this.getCommunityDashboardDatApiCallId = getAssessmentData.messageId;
    runEngine.sendMessage(getAssessmentData.id, getAssessmentData);
  }

  getAssessmentDetails = async () => {

    const signupToken = await getStorageData('SignupSuccessToken');
    const loginToken = await getStorageData('LoginSuccessToken');
    const headers = {
      token: loginToken || signupToken
    };

    const getAssessmentDetails = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAssessmentDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAssessmentTestUserApiMethod
    );

    getAssessmentDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getQuizEnd + `${this.id}`
    );


    getAssessmentDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    this.getAssessmentDetailsApiCallId = getAssessmentDetails.messageId;
    runEngine.sendMessage(getAssessmentDetails.id, getAssessmentDetails);
  }

  handleUserDashaboardData = (responseJson: {data:{
    name:string,
    chart_data:{avg_score:string,date:string}[],
    avg_score:string,
    attempt_quizzes:number,
    total_avg_score:number,
    quiz_details:{quiz_name:string,quiz_date:string,quiz_attempt:number}[],
    upcoming_test:{quiz_name: string,quiz_date:string}[]
  }}) => {
    if (responseJson) {
      this.setState({
        userDashboardData: responseJson,
        dashboardTitle: responseJson.data.name,
        chartData: responseJson.data.chart_data
      })
    }
  }

  handleCommunityDashboardData = (responseJson: {data:{chart_data: {date: string,
    avg_score: string}[],top_score:{name:string,date:string,score:number,total_score:number}[]}}) => {
    if (responseJson && responseJson.data) {
      this.setState({
        chartData: responseJson.data.chart_data,
        communityData: responseJson.data.top_score
      })
    }
  }

  handleGetAssessmentDetailsData = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        dashboardTitle: responseJson.data.quize_details.data.attributes.title
      })
    }
  }

  handleBackNavigation = () => {
    this.props.navigation.goBack();
  }
  // Customizable Area End
}
