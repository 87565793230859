Object.defineProperty(exports, "__esModule", {
  value: true,
});

//APi Methods
exports.apiMethod = "GET";
exports.apiContentType = "application/json";
exports.apiEndPoint = "activity_feed/activity_feeds";
exports.apiContentType = "application/json";

// Customizable Area Start
exports.loadLabel = "Load more...";
exports.headerLabel = "Recent Activity";
exports.resendLabel = "Resend.";
exports.resendCode= "Didn’t receive code?";
exports.done = "Done";
exports.contentTypeApiAddDetail = "application/json";
exports.apiMethodTypeAddDetail = "POST";
exports.accountsAPiEndPointWeb ="accounts";
exports.SignUpAPI = "accounts";
exports.apiMethodTypeLoginDetail = "GET";
exports.loginAPiEndPoint = "logins";
exports.PhoneVerficationEndPointWeb = "accounts/send_otps";
exports.ForgotPasswordPhoneVerficationEndPointWeb ="bx_block_forgot_password/otps"
exports.phoneOTPEndpointWeb = "accounts/sms_confirmations";
exports.ForgotPasswordphoneOTPEndpointWeb = "bx_block_forgot_password/otp_confirmations"
exports.EmailRequired = "Eamil is required"
exports.PasswordRequired = "Password is required";
exports.passwordValidationErrorMessage = "Password should contain minimum 8 characters, 1 uppercase, 1 number, 1 special character"
exports.getUserProfileDataApiEndPoint = "get_user?is_post_invisible=true";
exports.getAchivenmentListEnd= "bx_block_achievements/achievements/";
exports.getUserProfileDataApiMethod = "GET";
exports.updateUser = "update_user";
exports.changeWallpaperApiMethod = "PUT";
exports.noActivityFoundText= "No Activity Found";
exports.showAllText = "Show All";
exports.noAchievementsAvailableText = "No Achievement Available";
exports.getOtherProfileApiEndpoint = "accounts";
exports.getOtherCommunityApiEndpoint = "communities";
exports.get_community="get_community";
exports.getOtherProfileApiMethod =  "GET";
exports.getMutualConnectionsApiEndpoint = "request_management/requests/mutual_connections";
exports.getMutualConnectionsApiMethod = "GET";

exports.contact_us="Contact us";
exports.contact_message="We will respond to you in Faces and Voices chat.";
exports.termsTitle="Terms & Conditions";
exports.help="Help";
exports.done_btn="Done";
exports.terms_policy="Terms and Condition Policy";
exports.web_info="Website Info";
exports.ticket_system="Technical Support";
exports.ticket_link="https://fvrhub.org/rdp-support/";
exports.contactApi="bx_block_contact_us/contacts";
exports.post="POST";
exports.sendRequestApiEndpoint = "request_management/requests";
exports.removeAchievementApiEndpoint = "bx_block_achievements/achievements";
exports.removeAchievementApiMethod = "DELETE";
exports.postsEnd="posts/posts/";
exports.savePostEndPoint=`/get_saved_post`
exports.saveText = "Saved"
exports.privateConversation="bx_block_chat9/chats/create_conversation";
exports.highlights = "Highlights";
exports.issue_date="Issued Date";
exports.save_post_error = "No Saved Post Found";
exports.create_follower="create_followers";
exports.remove_follower="remove_follower";
exports.connect_social_media="Connect Social Media";
exports.website="Website";
exports.emailId="E-mail ID";
exports.phoneNo="Phone Number";
exports.contact_info="Contact Information";
exports.getactivityEnd="user_activity";
exports.activity="Activity";
exports.private_title="This account is private";
exports.private_message="This account is private, so only friends can see what he share  on his profile.";
// Customizable Area End
