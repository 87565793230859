// Customizable Area Start
import React from "react";
import Fade from '@material-ui/core/Fade';
import Card from '@material-ui/core/Card';
import { Typography, Box, Grid, Button , FormHelperText } from '@material-ui/core';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import ChangeWallpaperModalController,{ Props } from "./ChangeWallpaperModalController.web";
import { CloseIcon } from "./assets";
// Customizable Area End

// Customizable Area Start
const ChangeWallpaperStyles = (theme: Theme) =>
    createStyles({
        title: {
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
            display: 'none',
        },
        imageUploadsTabs: {
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid grey",
            width:"100%",
            paddingTop:"12px",
        },
        line: {
            color: "lightgrey",
            fontSize: "40px",

        },
        imageUploadsTab: {
            display: "flex",
            justifyContent: "center",
            alignItems:"center",
        },
        Icons: {
            justifyContent: "space-around",
            display: "flex",
        },
        iconBtn: {
            '&:hover': {
                borderRadius: "0px"
            },
        },
        
        modal: {
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
        },
        avatar:{
            objectFit:"contain",
            width:"50px",
            height:"50px",
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: "12px",
        },
        dropzone: {
            display: "flex",
            alignItems: "center",
            height: "100%",
            justifyContent: "center",
        },
        postModal: {
            boxShadow: "none",
            width:"100%",
            borderRadius: "12px",
        },
        PostIcons: {
            display: "flex",
            justifyContent: "space-between"
        },
        description: {
            outline: "none",
            borderRadius: "6px",
            backgroundColor: "#e6e3e3"
        },
        postUserDescription: {
            borderTop: "2px solid lightgrey",
            borderBottom: "2px solid lightgrey",
            padding: "10px 0px"
        },
        topDiv:{
            padding:"18px 22px 8px 22px"
        },
        cursor:{
            cursor:"pointer"
        },
        dropText:{
            paddingTop:"5px"
        },
        doneBtnGrid:{
            padding:"8px 16px 10px 16px",
            display:"flex",
            justifyContent:"flex-end",
            alignItems:"center"
        }
    })
// Customizable Area End
/*istanbul ignore next */

// Customizable Area Start
export class ChangeWallpaperModal extends ChangeWallpaperModalController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const { classes, openModal } = this.props
        return (
            <Modal
                aria-describedby="transition-modal-description"
                aria-labelledby="transition-modal-title"
                open={openModal}
                className={`CreatePostModals ${classes.modal}`}
                onClose={this.handleModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 200,
                }}
                onDragEnd={this.handleDragEnd}
                onDrop={this.handleDragEnd}
            >
                <Fade in={openModal}>
                    <Box 
                    className={`${classes.paper} CreatePostModalMainBox`}>
                        <Card 
                        className={classes.postModal}>
                            <Grid 
                            container direction="column">
                                <Grid 
                                className={classes.topDiv} item xs={12}>
                                    <Box 
                                        display="flex" justifyContent="space-between" mb={2}>
                                        <Typography 
                                            variant="body2" className={`Heading4 BoldFont FontColor5`}>Select Image</Typography>
                                        <img 
                                        data-test-id="change-wallpaper-close-icon" className="CursorPointer" onClick={this.handleModalClose} src={CloseIcon} alt="close-icon"/>
                                    </Box>
                                </Grid>
                                <Grid 
                                    item xs={12} className={classes.postUserDescription}>
                                    <Grid 
                                        container className={classes.imageUploadsTabs}>
                                        <Grid 
                                            style={this.state.imageLoad === "upload" ? webPage.imageBtn : { border: "none" }} className={`${classes.cursor} ${classes.imageUploadsTab}`} item xs={4} onClick={() => this.handleChangeTab("upload")} >
                                        <Typography 
                                            className={this.state.imageLoad === "upload" ? "Heading5 PrimaryColor2 ImageUploadTabBtn" : "Heading5 FontColor2 ImageUploadTabBtn"} component="span">Upload</Typography>
                                        </Grid>
                                    </Grid>
                                    <Box style={{ height: "250px" }}>
                                        {
                                            this.state.imageLoad === 'upload' ? (
                                                <Box className={classes.dropzone}
                                                    onDrop={this.handleDrop}
                                                    onDragOver={this.handleDragOver}
                                                >
                                                    {this.state.uploadImage === "" ? (
                                                        <Box 
                                                        display="flex" flexDirection="column" alignItems="center" gridGap="5px">
                                                            <Button variant="contained"
                                                                component="label"
                                                                style={webPage.Postbtn}
                                                            >
                                                                Browser
                                                                <input accept="image/png, image/jpg, image/jpeg,"
                                                                    type="file"
                                                                    hidden
                                                                    onChange={this.ReaderImg}
                                                                />
                                                            </Button>
                                                            <Typography 
                                                                component="span" className={`FontColor5 Heading4 ${classes.dropText}`} >{this.state.drop}</Typography>
                                                        </Box>
                                                    ) : (
                                                        <Box 
                                                            width="100%" p={2}>
                                                            <Grid 
                                                                item>
                                                                <img 
                                                                    src={URL.createObjectURL(this.state.uploadImage)} style={{ width: '100%', height: "225px" }} />
                                                            </Grid>
                                                        </Box>
                                                    )}

                                                </Box>
                                            ) : (
                                                <>
                                                    <Typography 
                                                        component="span" className="Heading6" >Coming Soon</Typography>
                                                </>
                                            )
                                        }
                                    </Box>
                                    {this.state.uploadImageError && 
                                    <Box pl={"5px"} >
                                        <FormHelperText>{this.state.uploadImageErrorMsg}</FormHelperText>
                                    </Box>
                                    }
                                </Grid>
                                <Grid item xs={12} className={classes.doneBtnGrid} >
                                        <Button variant="contained" disabled={this.state.imageLoad !== "upload"} onClick={this.handleUploadImage} style={this.state.imageLoad !== "upload" ?  webPage.PostbtnOpacity : webPage.Postbtn}>Done</Button>
                                </Grid>
                            </Grid>
                        </Card>
                    </Box>
                </Fade>
            </Modal>
        )
    }
    // Customizable Area End
}

const webPage = {
    
    imageBtn: {
        borderRadius: "0px",
        borderBottom: "1px solid #01548a",
    },
    IconColor: {
        color: "#5B7389"
    },
    PostbtnOpacity: {
        background: "#01548a",
        color: "#fff",
        borderRadius:"0px",
        width:"165px",
        padding:"8px 0px",
        opacity:"0.3"
    },
    Postbtn: {
        color: "#fff",
        borderRadius:"0px",
        width:"165px",
        padding:"8px 0px",
        background: "#01548a",
    },
    
    Publishbtn: {
        background: "#01548a",
        color: "#fff",
        width: "100%"
    },
    label: {
        color: "#01548a",
        fontSize: "14px",
        fontWeight: "bold",
        marginBottom: "10px"
    } as const
}


export default withStyles(ChangeWallpaperStyles, { withTheme: true })(ChangeWallpaperModal);
// Customizable Area End