Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Assessment Test";
exports.labelBodyText = "AssessmentTest Body";
exports.labelViewTest = "View Test";
exports.labelPendingTest = "Pending Test";
exports.labelCompletedTest = "Completed Test";
exports.labelBarcDashboard = "BARC Dashboard";
exports.labelUserList = "Users List";
exports.labelIndivisualLabel = "Individual user";

exports.btnExampleTitle = "CLICK ME";
exports.getAssessmentTestListAdminApiEndpoint = "bx_block_questionbank/quizzes";
exports.getAssessmentTestListUserApiEndpoint = 'bx_block_questionbank/account_answers/get_quizzes'
exports.getAssessmentTestListAdminApiMethod = "GET";
exports.getAssessmentTestListUserApiMethod = "GET";
exports.labelAverageScore = "Average Score";
exports.labelNumberOfTests = "No. of Test Taken";
exports.labelCompleted = "Completed";
exports.labelUpcoming = "Upcoming";
exports.labelTakeANewTest = "Take a New Test";
exports.getBarcDataApiEndpoint = 'bx_block_questionbank/account_answers/barc_quiz_details'
exports.getBarcDataApiMethod = 'GET'
exports.getAssessmentDataApiMethod = 'GET'
exports.getAssessmentDataApiEndpoint = 'bx_block_questionbank/account_answers/get_quiz_detail'
exports.getAssessmentTestUserEndpoint = "bx_block_questionbank/quizzes/get_community_quiz_users"
exports.getAssessmentTestUserApiMethod = "GET"
exports.getAdminDashboardDataApiMethod = "GET"
exports.getAdminDashboardDataApiEndpoint =  "bx_block_questionbank/quizzes/fetch_dashboard_details"

exports.barc_assessment="BARC Assessment";
exports.barc_api_end="bx_block_questionbank/quizzes/fetch_barc_questions";
exports.getApi="GET";
exports.postApi="POST";
exports.getQuizEnd="bx_block_questionbank/quizzes/show_quiz?quiz_id=";
exports.answerEnd="bx_block_questionbank/account_answers";
exports.test_type="Test Type";
exports.anonymus="Anonymous";
exports.getAssessmentTestListApiEndpoint = "bx_block_questionbank/account_answers/get_quizzes";
exports.getAssessmentTestListApiMethod = "GET";
exports.noCompletedQuizesLabel = "No completed quizzes found.";
exports.getTestScoreBasedOnAccountIdApiEndpoint = "bx_block_questionbank/quizzes/fetch_barc_account_score_details"
exports.getTestScoreBasedOnAssessmentApiEndpoint = "bx_block_questionbank/quizzes/fetch_quiz_score_details?"

exports.datesLabel = 'Dates'
exports.dateLabel = 'Date'
exports.usernameLabel = 'Username'
exports.scoresLabel = 'Scores'
exports.getAttemptedQuizzDataApiEndpoint = "bx_block_questionbank/quizzes/attempted_quiz";
exports.datesLable = 'Dates'
exports.btn_submit = "Submit";
exports.pendingQuiz_label = "No pending quizes found.";
// Customizable Area End