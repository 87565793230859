import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  testList: {pending_quizes: {quiz_id:number,title:string}[],completed_quizes: {quiz_id:number,title:string}[]};
  isLocalAdmin: boolean;
  isLoading: boolean;
  showDropDown: boolean;
  dropDownList: { title: string, id: string }[];
  selectedMenu: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AssessmentTestViewListingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  signupToken: string = "";
  loginToken: string = "";
  getTestListApiCallId: string = "";
  getAttemptedTestListApiCallId: string = "";
  isCommunity: boolean = false
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      testList: {
        pending_quizes: [
        ],
        completed_quizes: [
        ]
      },
      isLocalAdmin: true,
      isLoading: true,
      showDropDown: false,
      dropDownList: [],
      selectedMenu: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    this.setState({
      isLoading: false
    });

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );


    if (apiRequestCallId === this.getTestListApiCallId) {
      this.handleGetAllAssessmentAdminResponse(responseJson);
    } else if(apiRequestCallId === this.getAttemptedTestListApiCallId){
      this.handleGetAttemptedTestResponse(responseJson);
    }
    // Customizable Area End
  }


  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const param = this.props.navigation.getParam("navigationBarTitleText");
    if (param === "community") {
      this.isCommunity = true;
      this.setState({
        dropDownList: [
          {
            title: "My Community",
            id: "community"
          },
          {
            title: "Individual User",
            id: "INDUser"
          }
        ],
        selectedMenu: 'community'
      })
      this.getAttemptedQuizzList();
      return;
    }
    this.getListOfTestView()
  }

  async getListOfTestView() {
    this.setState({
      isLoading: true
    })

    if (this.state.isLocalAdmin) {
      const loginToken = await getStorageData('LoginSuccessToken'); const signupToken = await getStorageData('SignupSuccessToken');
      const headers = {
        token: signupToken || loginToken
      };

      const getAllAssessment = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      getAllAssessment.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getAssessmentTestListAdminApiEndpoint
      );

      getAllAssessment.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAssessmentTestListAdminApiMethod
      );


      getAllAssessment.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      this.getTestListApiCallId = getAllAssessment.messageId;
      runEngine.sendMessage(getAllAssessment.id, getAllAssessment);

    }
  }

  async getAttemptedQuizzList() {
    this.setState({isLoading: true});
    const signupToken = await getStorageData('SignupSuccessToken'); const loginToken = await getStorageData('LoginSuccessToken'); 

      const getAttemptedTest = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      getAttemptedTest.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getAttemptedQuizzDataApiEndpoint
      );

      getAttemptedTest.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAssessmentTestListAdminApiMethod
      );


      getAttemptedTest.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
          token: signupToken || loginToken
        })
      );

      this.getAttemptedTestListApiCallId = getAttemptedTest.messageId;
      runEngine.sendMessage(getAttemptedTest.id, getAttemptedTest);
  }

  handleGetAllAssessmentAdminResponse(responseJson: {data:{completed_quizes:[{quiz_id:number,title:string}],pending_quizes:[{quiz_id:number,title:string}]}}) {
    if (responseJson && responseJson.data) {
      this.setState({
        testList: responseJson?.data
      })
    }
  }

  handleGetAttemptedTestResponse(responseJson: {quiz_details:{quiz_id:number,title:string}[]}){
    if (responseJson && responseJson.quiz_details) {
      this.setState({
        testList: {pending_quizes: [], completed_quizes: responseJson.quiz_details}
      })
    }
  }

  handleAssessmentClick = (id: number) => {
    if(this.isCommunity){
      const communityDashboardNavigation = new Message(getName(MessageEnum.NavigationCommunityDashboardMessage));
      communityDashboardNavigation.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      communityDashboardNavigation.addData(
        getName(MessageEnum.UrlPathParamMessage),
        `community-${id}`
      );
      this.send(communityDashboardNavigation)
    } else {
      const viewTestNavigation = new Message(getName(MessageEnum.NavigationAssessmentTestMessage));
      viewTestNavigation.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      viewTestNavigation.addData(
        getName(MessageEnum.UrlPathParamMessage),
        `view-${id}`
      );
      this.send(viewTestNavigation)
    }
    
  }


  handleDropdownChange = (data: {id: string, title: string}) => {
    if (data.id === 'INDUser') {
      const IndPage = new Message(getName(MessageEnum.NavigationAssessmentTestUserListMessage));
      IndPage.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(IndPage)
    }
  }

  handlePendingAssessmentClick = (data: number) => {
    const pendingNavigation = new Message(getName(MessageEnum.NavigationQuestionBankCreateMessage));
    pendingNavigation.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    pendingNavigation.addData(
      getName(MessageEnum.UrlPathParamMessage),
      data
    );
    this.send(pendingNavigation);
  }

  handleBackNavigation = () => {
    this.props.navigation.navigate("AssessmentDashboard");
  }
  // Customizable Area End
}
