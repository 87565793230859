import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";

// Customizable Area Start
import {
  Typography,
  Box,
  Grid,
  Card,
  Button,
  Tooltip,
} from "@material-ui/core";
import AchievementSectionController, {
  Props,
} from "./AchievementSectionController.web";
import { AddIcon, deleteIcon, EditIcon } from "./assets";
import { configJSON } from "./AchievementSectionController.web";
import { ConfirmationModal } from "../../../components/src/ConfirmationModal.web";
import { CommonPreviewModal } from "./CommonPreviewModal.web";

const AchievementSectionStyles = (theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "100%",
      marginBottom: "16px",
      borderRadius: "12px",
      padding: "24px",
      position: "relative",
      border: "solid 1px #808080",
    },
    bottomBorder: {
      margin: "21.9px 0 20.1px",
      borderBottom: "solid 0.3px #808080;",
    },
    textlabel: {
      fontFamily: "Verdana",
      FontSize: "20x",
      fontWeight: "bolder",
      fontSretch: "normal",
      fontStyle: "normal",
      lineeHight: "normal",
      letterSpacing: "normal",
      color: "#000",
    },
    smallerText: {
      fontFamily: "Verdana",
      FontSize: "20x",
      fontWeight: "bolder",
      fontSretch: "normal",
      fontStyle: "normal",
      lineeHight: "normal",
      letterSpacing: "normal",
      color: "#000",
    },
    btn: {
      background: "transparent",
      fontFamily: "Verdana",
      fontSize: "20px",
      fontWight: "normal",
      fontSretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textTransform: "unset",
      textAlign: "center",
      color: "#005487",
    },
    editIcon: {
      background: "white",
      width: "32px",
      height: "32px",
      padding: "4.3px 6px 4px",
      borderRadius: "50%",
      position: "absolute",
      top: "20px",
      right: "20px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    deleteIcon: {
      cursor: "pointer",
      marginLeft: "10px",
    },
    pointer: {
      cursor: "pointer",
    },
    tabImage: {
      cursor: "pointer",
      border: "2px solid #005487",
      height: "fit-content",
      borderRadius: "5px",
      "& img": {
        borderRadius: "4px",
        padding: "18px",
      },
    },
    topHeading: {
      borderBottom: "1px solid #808080",
      paddingBottom: "24px",
      paddingTop: "24px",
      paddingLeft: "24px",
      fontSize: "18px",
      color: "#005487",
      backgroundCOlor: "#fff",
      borderTopLeftRadius: "20px",
      borderTopRightRadius: "20px",
      backgroundColor: "#fff",
      border: "1px solid #808080",
    },
  });

// Customizable Area End
export class AchievementSection extends AchievementSectionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  RenderCard = () => {
    const { isOwnProfile, classes, navigateCommunity } = this.props;
    return (
      <>
        {this.state.pathRoute && (
          <Box
            style={
              isOwnProfile ? { opacity: 1 } : { opacity: 0 } // Add opacity style when isOwnProfile and setEditModalState are true
            }
            className={classes.editIcon}
          >
            {isOwnProfile && (
              <img
                data-test-id="edit-icon"
                src={EditIcon}
                alt="edit-icon"
                onClick={navigateCommunity}
                style={{ cursor: "pointer" }}
              />
            )}
          </Box>
        )}
      </>
    );
  };

  RenderCardOne = () => {
    return (
      <>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box display={"flex"}>
            <Typography
              className={`Heading3 BoldFont FontColor6 TextCapitalize `}
              gutterBottom
              variant="body2"
              component="p"
              style={{ color: "#005487" }}
            >
              {!this.state.pathRoute ? " Achievements" : "About Us"}
            </Typography>
          </Box>
        </Box>
      </>
    );
  };

  Paragraph = (index: number) => {
    const { classes, data } = this.props;
    const transformedData = this.getTransformedData(data);
    const { is_bulk_upload, description } = transformedData[index].attributes;

    let content = "";
    if (is_bulk_upload) {
      if (description) {
        if (description.length > 40) {
          content = description.slice(0, 30) + "...";
        } else {
          content = description;
        }
      }
    }

    return (
      <Tooltip title={description ? description : ""}>
        <Typography
          gutterBottom
          variant="body2"
          component="p"
          className={`Heading4 BoldFont FontColor2 ${classes.smallerText}`}
          style={{ marginTop: "9PX" }}
        >
          {content}
        </Typography>
      </Tooltip>
    );
  };

  subRenderAbc = () => {
    const { classes, data, isOwnProfile } = this.props;

    const transformedData = this.getTransformedData(data);
    return (
      <>
        {transformedData.length > 0 ? (
          transformedData.map((achievement, index: number) => (
            <Box
              display={"flex"}
              key={achievement.id}
              className={classes.bottomBorder}
              pb="20px"
            >
              <Box
                onClick={() =>
                  this.handlePreviewClick({
                    url: achievement.attributes.custom_form_file,
                    mimeType: achievement.attributes.custom_form_file_extension,
                  })
                }
                className={classes.tabImage}
              >
                {this.returnAchievementLogo(
                  achievement.attributes.custom_form_file_extension
                )}
              </Box>
              <Box
                pl="17px"
                display="flex"
                flexDirection={"column"}
                justifyContent="space-around"
                flexGrow="1"
                flexWrap="wrap"
              >
                <Box
                  component={"p"}
                  m={0}
                  sx={{
                    fontSize: "20px",
                    fontFamily: "Verdana",
                    fontWeight: "bold",
                  }}
                  color="#000000"
                >
                  {achievement.attributes.title}
                </Box>
                <Box
                  component={"p"}
                  m={0}
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Verdana",
                    fontWeight: "bold",
                  }}
                  color="#808080"
                >
                  {!achievement.attributes.is_bulk_upload
                    ? achievement.attributes.organisation_name
                    : this.Paragraph(index)}
                </Box>

                {!achievement.attributes.is_bulk_upload && (
                  <Box
                    component={"p"}
                    m={0}
                    sx={{ fontSize: "16px", fontFamily: "Verdana" }}
                    color="#808080"
                  >
                    {configJSON.issue_date} -{" "}
                    {this.convertNumericMonthtoString(
                      achievement.attributes.issue_month
                    )}{" "}
                    {achievement.attributes.issue_year} •{" "}
                    {achievement.attributes.expiration_month &&
                    achievement.attributes.expiration_year
                      ? `${this.convertNumericMonthtoString(
                          achievement.attributes.expiration_month
                        )} ${achievement.attributes.expiration_year}`
                      : "No Expiration Date"}
                  </Box>
                )}
              </Box>
              {isOwnProfile && (
                <Box>
                  <img
                    src={EditIcon}
                    className={classes.deleteIcon}
                    onClick={() =>
                      this.handleEdit(
                        achievement.id,
                        achievement.attributes.curruntId
                      )
                    }
                    alt="achievement-edit-icon"
                    data-test-id="achievement-edit-icon"
                    height="24px"
                  />
                  <img
                    src={deleteIcon}
                    className={classes.deleteIcon}
                    onClick={() =>
                      this.handleRemoveAchievementClick(
                        achievement.id,
                        achievement.attributes.curruntId
                      )
                    }
                    alt="achievement-delete-icon"
                    data-test-id="achievement-delete-icon"
                    height="24px"
                  />
                </Box>
              )}
            </Box>
          ))
        ) : (
          <Typography data-test-id={"no-achievement"}>
            {configJSON.noAchievementsAvailableText}
          </Typography>
        )}
      </>
    );
  };
  renderAbc = () => {
    const { classes, isMainListing, isOwnProfile } = this.props;
    return (
      <>
        {!this.state.pathRoute ? (
          <>
            <Box pt="3px" style={isMainListing ? { minHeight: "511px" } : {}}>
              {this.subRenderAbc()}
            </Box>
            <CommonPreviewModal
              open={this.state.showPreviewModal}
              onClose={this.handlePreviewModalClose}
              previewData={this.state.previewData}
            />
            <ConfirmationModal
              open={this.state.showDeleteModal}
              onClose={this.handleRemoveModalClose}
              title={"Delete Achievement"}
              description={"Are you sure you want to delete this achievement?"}
              handleSubmit={this.handleRemoveAchievementConfirmation}
            />
            {isOwnProfile && (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={isMainListing ? "center" : "flex-start"}
              >
                {!this.state.pathRoute && (
                  <Button
                    startIcon={
                      <img
                        height={24}
                        width={24}
                        src={AddIcon}
                        alt="edit-icon"
                      />
                    }
                    data-test-id="add-achievement-btn"
                    onClick={() => this.handleNavigation()}
                    className={classes.btn}
                  >
                    Add Achievements
                  </Button>
                )}
              </Box>
            )}
          </>
        ) : (
          <Typography data-test-id={"no-achievement"}>
            {this.state.communityData?.attributes?.description}
          </Typography>
        )}
      </>
    );
  };
  render() {
    const { classes, isMainListing } = this.props;

    return (
      <>
        <Box component={"section"}>
          {isMainListing && (
            <Typography
              className={`${classes.topHeading} Heading3 BoldFont TextCapitalize `}
            >
              Achievements
            </Typography>
          )}
          <Card
            style={
              isMainListing
                ? {
                    borderRadius: "0",
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }
                : {}
            }
            className={`${classes.root} `}
          >
            {this.RenderCard()}
            {this.RenderCardOne()}

            {this.renderAbc()}
          </Card>
        </Box>
      </>
    );
  }
}

export default withStyles(AchievementSectionStyles, { withTheme: true })(
  AchievementSection
);
// Customizable Area End
