import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";

// Customizable Area Start
import { Box, Grid, Card } from "@material-ui/core";
import ContactInfoController, {
  Props,
  configJSON,
} from "./ContactInfoController.web";
import { CloseIcon, Mail, PhoneIcon, WebsiteIcon } from "./assets";
import Loader from "../../../components/src/Loader.web";

const contactInfoStyles = (theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "100%",
      marginBottom: "16px",
      borderRadius: "12px",
      position: "relative",
      padding: "24px",
      wordBreak: "break-word",
    },

    bottomBorder: {
      borderBottom: "solid 1px #808080",
    },
    textlabel: {
      fontFamily: "Verdana",
      FontSize: "17px",
      fontWeight: "bolder",
      fontSretch: "normal",
      fontStyle: "normal",
      lineeHight: "normal",
      letterSpacing: "normal",
      color: "#000",
    },
    textcolor: {
      color: "#005487",
    },
    linkColor: {
      color: "#005487",
    },
    pointer: {
      cursor: "pointer",
    },
  });
// Customizable Area End

// Customizable Area Start

export class ContactInfo extends ContactInfoController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid item xs={10} md={8} style={{ margin: "auto" }}>
        <Loader loading={this.state.isLoading} />
        <Card className={`${classes.root}`}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box
              component="p"
              m={0}
              sx={{
                fontFamily: "Verdana",
                fontSize: "22px",
                fontWeight: "bold",
              }}
              color="#005487"
            >
              {configJSON.contact_info}
            </Box>

            <Box
              onClick={this.handleCloseClick}
              data-testid="close-icon"
              className={classes.pointer}
            >
              <img src={CloseIcon} />
            </Box>
          </Box>

          <Box>
            <Box
              display={"flex"}
              pt={"24px"}
              pb={"24px"}
              className={classes.bottomBorder}
            >
              <Box>
                <img src={PhoneIcon} />
              </Box>
              <Box ml={"17px"}>
                <Box
                  component="p"
                  m={0}
                  sx={{
                    fontFamily: "Verdana",
                    fontSize: "17px",
                    fontWeight: "bold",
                  }}
                >
                  {configJSON.phoneNo}
                </Box>
                <Box
                  data-testid="phone-no"
                  component="p"
                  m={0}
                  sx={{ fontFamily: "Verdana", fontSize: "16px" }}
                  mt={"8px"}
                  color={"#808080"}
                >
                  {this.state.contactInfo.phoneNo}
                </Box>
              </Box>
            </Box>
            <Box
              display={"flex"}
              pt={"41px"}
              pb={"24px"}
              className={classes.bottomBorder}
            >
              <Box>
                <img src={Mail} />
              </Box>
              <Box ml={"17px"}>
                <Box
                  component="p"
                  m={0}
                  sx={{
                    fontFamily: "Verdana",
                    fontSize: "17px",
                    fontWeight: "bold",
                  }}
                >
                  {configJSON.emailId}
                </Box>
                <Box
                  component="p"
                  m={0}
                  sx={{ fontFamily: "Verdana", fontSize: "16px" }}
                  mt={"8px"}
                  color={"#808080"}
                >
                  <a
                    data-testid="email-id"
                    href={`mailto:${this.state.contactInfo.email}`}
                    className={classes.linkColor}
                  >
                    {this.state.contactInfo.email}
                  </a>
                </Box>
              </Box>
            </Box>
            <Box
              display={"flex"}
              className={classes.bottomBorder}
              pt={"41px"}
              pb={"24px"}
            >
              <Box>
                <img src={WebsiteIcon} />
              </Box>
              <Box ml={"17px"}>
                <Box
                  component="p"
                  m={0}
                  sx={{
                    fontFamily: "Verdana",
                    fontSize: "17px",
                    fontWeight: "bold",
                  }}
                >
                  {configJSON.website}
                </Box>
                <Box
                  data-testid="website-info"
                  component="p"
                  m={0}
                  sx={{ fontFamily: "Verdana", fontSize: "16px" }}
                  mt={"8px"}
                  color={"#005487"}
                >
                  {this.state.contactInfo.website ? (
                    <a
                      href={this.state.contactInfo.website}
                      target="_blank"
                      className={classes.linkColor}
                    >
                      {this.state.contactInfo.website}
                    </a>
                  ) : (
                    "-"
                  )}
                </Box>
              </Box>
            </Box>
            {this.type !== "community" && (
              <Box
                display={"flex"}
                className={classes.bottomBorder}
                pt={"41px"}
                pb={"24px"}
              >
                <Box>
                  <img src={WebsiteIcon} />
                </Box>
                <Box ml="17px">
                  <Box
                    component="p"
                    m={0}
                    sx={{
                      fontFamily: "Verdana",
                      fontSize: "17px",
                      fontWeight: "bold",
                    }}
                  >
                    {configJSON.connect_social_media}
                  </Box>
                  <Box>
                    {this.state.contactInfo.socialMediaUrls.length > 0 ? (
                      this.state.contactInfo.socialMediaUrls.map((urlObj) => (
                        <Box my={"7px"} key={urlObj}>
                          <a
                            href={urlObj}
                            target="_blank"
                            className={classes.linkColor}
                          >
                            {urlObj}
                          </a>
                        </Box>
                      ))
                    ) : (
                      <Box component={"p"}> - </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Card>
      </Grid>
    );
  }
}

export default withStyles(contactInfoStyles, { withTheme: true })(ContactInfo);
// Customizable Area End
