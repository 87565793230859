import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";

// Customizable Area Start
import { Box, Button, CircularProgress } from "@material-ui/core";
import ActivitySectionController, {
  configJSON,
  Props,
} from "./ActivitySectionController.web";
import { Play } from "./assets";

const ActivitySectionStyles = (theme: Theme) =>
  createStyles({
    pointer: {
      cursor: "pointer",
    },
    playIcon: {
      top: "50%",
      width: "22px",
      height: "22px",
      left: "50%",
      transform: "translate(-50%,-50%)",
    },
    linkColor: {
      color: "#005487",
      fontFamily: "Verdana",
      fontSize: "17px",
    },
    showmoreBtn: {
      color: "#005487",
      fontFamily: "Verdana",
    },
  });
// Customizable Area End

// Customizable Area Start
export class ActivitySection extends ActivitySectionController {
  constructor(props: Props) {
    super(props);
  }

  renderDescription = (item: { [key: string]: string | null }) => {
    const { description, id } = item;
    const { readMorendex } = this.state;

    if (!description) {
      return null;
    }

    const truncatedDescription = description.slice(0, 50);
    const shouldDisplayReadMore =
      description.length > 50 && readMorendex !== id;
      
      const isReadMoreActive = readMorendex === id;
      const trunckDes=`${truncatedDescription}${shouldDisplayReadMore ? "..." : ""}`
      const displayText = isReadMoreActive ? description
      : trunckDes;
    return (
      <Box
        component="p"
        m={0}
        mt="15px"
        color="#808080"
        fontSize="20px"
        fontFamily="Verdana"
      >
        {displayText}
        {shouldDisplayReadMore && (
          <Box
            component="span"
            fontSize={"17px"}
            data-testid="read-more"
            onClick={(e: React.MouseEvent<HTMLElement>) =>
              this.readMoreFn(e, id)
            }
          >
            {" "}
            Read more
          </Box>
        )}
      </Box>
    );
  };

  renderHeader = (item: { [key: string]: string | null }) => {
    const time = item.sharing_time;
    let text: string | null;
    let name: string | null;
    if (item.comment_by) {
      text = "commented on this";
      name = item.comment_by;
    } else if (item.like_by) {
      name = item.like_by;
      text = "liked this post";
    } else if (item.post_shared_by) {
      name = item.post_shared_by;
      text = "shared this post";
    } else {
      name = item.full_name ?? item.community_name;
      text = "posted this";
    }
    return (
      <Box
        component="p"
        fontFamily={"verdana"}
        fontSize={"17px"}
        color="#808080"
        m={0}
      >
        <Box component={"span"} fontWeight={"bold"}>
          {name}
        </Box>{" "}
        {text} • {time}
      </Box>
    );
  };

  renderTextPost = (item: { [key: string]: string | null }) => {
    return (
      <>
        {this.renderDescription(item)}
        {item.thumbnail && (
          <Box sx={{ width: "200px", height: "133px" }} mt={"20px"}>
            <img
              src={item.thumbnail}
              alt="post"
              width={"100%"}
              height={"100%"}
            />
          </Box>
        )}
      </>
    );
  };

  renderVideoPost = (item: { [key: string]: string | null }) => {
    const { classes } = this.props;
    return (
      <>
        {this.renderDescription(item)}
        {item.video_thumbnail && (
          <Box display={"flex"} mt={"20px"}>
            <Box position={"relative"} sx={{ width: "200px", height: "133px" }}>
              <Box
                component="span"
                position={"absolute"}
                className={classes.playIcon}
              >
                <img src={Play} alt="play" width="100%" height="100%" />
              </Box>
              <img
                src={item.video_thumbnail}
                alt="post"
                width={"100%"}
                height={"100%"}
              />
            </Box>
            <Box color="#808080" ml={"16px"}>
              {item.video_title && (
                <Box
                  component={"p"}
                  m={0}
                  mb={"16px"}
                  fontFamily={"Verdana"}
                  fontSize={"20px"}
                  fontWeight={"bold"}
                >
                  {item.video_title.length > 35
                    ? item.video_title.slice(0, 35) + "..."
                    : item.video_title}
                </Box>
              )}
              <Box component="span" fontFamily={"Verdana"} fontSize={"14px"}>
                {this.returnVideoName(item.post_type)}
              </Box>
            </Box>
          </Box>
        )}
      </>
    );
  };

  renderUrlPost = (item: { [key: string]: string | null }) => {
    const { classes } = this.props;
    return (
      <>
        {this.renderDescription(item)}
        {item.thumbnail && (
          <Box mt={"20px"} display={"flex"}>
            <Box sx={{ width: "200px", height: "133px", marginRight: "16px" }}>
              <img
                src={item.thumbnail}
                alt="post"
                width={"100%"}
                height={"100%"}
              />
            </Box>
            {item.url && (
              <Box>
                <a
                  href={item.url}
                  onClick={(e) => e.stopPropagation()}
                  target="_blank"
                  className={classes.linkColor}
                >
                  {item.url}
                </a>
              </Box>
            )}
          </Box>
        )}
      </>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <Box
        component="section"
        sx={{ padding: "24px" }}
        mb={"16px"}
        bgcolor={"#fff"}
        border="1px solid #808080"
        borderRadius={"12px"}
      >
        <Box
          component="h4"
          m={0}
          fontSize="22px"
          fontFamily={"Verdana"}
          fontWeight={"bold"}
          color="#005487"
        >
          {configJSON.activity}
        </Box>
        {this.state.data.map((item) => {
          return (
            <Box
              sx={{ paddingY: "20px" }}
              data-testid="list-item"
              key={item.id}
              borderBottom={"1px solid #808080"}
            >
              {this.renderHeader(item.attributes)}
              <Box
                onClick={() => this.navigateofPost(item.id)}
                className={classes.pointer}
                data-testid="list-content"
              >
                {(() => {
                  switch (item.attributes.post_type) {
                    case "text":
                    case "image":
                      return this.renderTextPost(item.attributes);
                    case "Vimeo":
                    case "YouTube":
                      return this.renderVideoPost(item.attributes);
                    case "url":
                      return this.renderUrlPost(item.attributes);
                    default:
                      return null;
                  }
                })()}
              </Box>
            </Box>
          );
        })}
        {!this.state.loading && this.state.data.length < 1 && (
          <Box component="p" fontSize={"17px"} m={"5px"} fontFamily={"Verdana"}>
            No activity found
          </Box>
        )}
        {this.state.loading && (
          <Box
            display={"flex"}
            justifyContent={"center"}
            m={0}
            mt={"15px"}
            color="#808080"
          >
            <CircularProgress size={40} />
          </Box>
        )}
        {this.state.has_more && !this.state.loading && (
          <Box display={"flex"} justifyContent={"center"} mb={"-15px"}>
            <Button
              className={classes.showmoreBtn}
              onClick={this.showMoreList}
              data-testid="show-more-btn"
            >
              Show more
            </Button>
          </Box>
        )}
      </Box>
    );
  }
}

export default withStyles(ActivitySectionStyles, { withTheme: true })(
  ActivitySection
);
// Customizable Area End
