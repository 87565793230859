import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
// Customizable Area Start
import {
    InputBase,
    Button, Box, Modal, Menu, Typography, MenuItem, IconButton} from "@material-ui/core";

import SearchListModalController, { Props, configJSON } from "./SearchListModalController.web";
import Loader from "../../../components/src/Loader.web";
import { CloseIcon, blue_close,more } from "./assets";


const searchListModalStyles = (theme: Theme) =>
    createStyles({
        paper: {
          position: "absolute",
          top: 0,
          width: "100%",
          maxWidth: "962px",
          backgroundColor: "transparent",
          //   border: "2px solid #000",
          padding: theme.spacing(2, 4, 3),
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          
         
        },
        input: {
          //   flex: 1,
          backgroundColor: "#F4F4F4",
          width: "100%",
          height: "40px",
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingTop: "9px",
          paddingBottom: "9px",
          borderRadius: "10px",
          color: "#808080",
          fontSize: "17px",
          fontFamily: "Verdana",
          lineHeight: "22px",
          letterSpacing: "-0.41px",
        },
        
        card: {
          borderRadius: "12px",
          backgroundColor: "white"
        },
        listContainer: {
          maxHeight: '230px',
          minHeight: "120px",
          overflowY: "auto",
          margin: 0,
          gap: "24px",
          padding:0,
          display: "flex",
          flexDirection: "column",
        },
        listItem: {
          listStyle: "none",
          display: "flex",
          paddingInline: "24px",
          justifyContent: "space-between",
          alignItems: "flex-start"
        },
        listImage: {
          width:"50px",
          height: "50px",
          cursor: "pointer",
          borderRadius: "50%"
        },
        pointer:{
          cursor: "pointer"
        },
        showallbtn: {
          color: "#005487",
          fontFamily: "Verdana",
          fontSize: " 17px",
          textTransform: "initial",
        }
        
      })
// Customizable Area End

// Customizable Area Start
export class SearchListModal extends SearchListModalController {
    constructor(props: Props) {
        super(props);
    }

  renderMenus = (friend: {
    name: string;
    id: string;
    profilePicture: string;
    searchableId?: string
  }) => {
    const { classes } = this.props
    return (
      this.props.isOwnProfile ? <>
        <IconButton
          id="demo-positioned-button"
          aria-controls={this.state.anchorEl ? "demo-positioned-menu" : undefined}
          aria-expanded={this.state.anchorEl ? "true" : undefined}
          aria-haspopup="true"
          onClick={(e) => this.handleMenuOpen(e, friend.id)}
        >
          <img src={more} alt="more" />
        </IconButton>
        <Menu
          id={`list-menu-${friend.id}`}
          key={`list-menu-${friend.id}`}
          aria-labelledby="demo-positioned-button"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.menuItem)}
          onClose={this.handleCloseEnchor}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          PaperProps={{
            style: { borderRadius: '12px' }
          }}
        >
          <MenuItem
            key={`list-menu-item-${friend.id}`}
            onClick={this.removeFriendFollower}
            data-testid="menu-item"
          >
            <Box
              justifyContent={"space-between"}
              display={"flex"}
              marginRight={"8rem"}
              padding={"7px"}
            >
              <Box marginRight={"17px"}><img src={blue_close} alt="close" /></Box>
              <Typography className={classes.text}>
                Remove
              </Typography>
            </Box>
          </MenuItem>
        </Menu>
      </> : null
    )
  }

    render() {
        const { classes, showSearchModal, friendListRef } = this.props;

        const list = this.searchList();
        return (
            <Modal
              style={{ position: "absolute" ,height:"1100px"}}
              container={friendListRef.current}
              open={showSearchModal}
              onClose={this.handleCloseModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              disablePortal
            >
              
          <Box style={this.getModalStyle()} className={classes.paper}>
              <Loader loading={this.state.isLoading} />
              <InputBase
              className={classes.input}
              data-testid="input-base"
              inputProps={{ "aria-label": "Search Friends" }}
              value={this.state.searchTerm}
              onChange={this.handleSearchTermChange}
              />
              <Box className={classes.card} sx={{padding: "20px 0 20px 0"}}>
              {this.state.showRecentSearch && <Box component="p" mx={"24px"} mb={"20px"} sx={{fontWeight: "bold",fontSize: "17px", fontFamily:"Verdana"}} color="#005487">Recent</Box>}
              <Box component="ul" className={classes.listContainer} >
                  {list && list.filter((_, index: number) => (this.state.showAllEnabled) || (!this.state.showAllEnabled && index < 3)).map((friend, i: number) => {
                    return (
                      <Box component={"li"}
                        key={friend.id}
                        className={classes.listItem}
                        data-test-id="list-item"
                      >
                        <Box display={"flex"}>
                        <img src={friend.profilePicture} className={classes.listImage} alt="profile" data-test-id="item-img" onClick={()=>this.handleSearchItemClick(friend.id)}/>
                        <Box component={"p"} m={0} ml={"12px"} className={classes.pointer} sx={{fontFamily:"Verdana", fontSize:"17px", fontWeight:"bold"}} data-test-id="item-name">{friend.name}</Box>
                        </Box>
                        {this.state.showRecentSearch ?
                        <img
                          onClick={() => this.removeRecentSearch(friend?.searchableId || "")}
                          src={CloseIcon}
                          className={classes.pointer}
                          alt="close"
                          data-test-id="close-id"
                          /> : this.renderMenus(friend)
                        }
                      </Box>
                    );
                  })}
                  {list.length<1 && <Box component="p" m={"20px"}>No Data Found</Box>}
              </Box>
                {list && list.length >= 4 && !this.state.showAllEnabled && <Box mt={"20px"} display={"flex"} justifyContent={"center"}>
                  <Button className={classes.showallbtn} data-testid="show-all-btn" onClick={this.handleShowAll}>{configJSON.show_all}</Button>
                </Box>}
              </Box>
          </Box>
      </Modal>
        );
    }
}

export default withStyles(searchListModalStyles, { withTheme: true })(SearchListModal);
// Customizable Area End