// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import ApiCall from "../../../components/src/ApiCall.web";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  ID:any;
  collectionName:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateCollectionController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  createCollectionApiCallId: any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
        ID:"",
        collectionName:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
  }

  createCollectionSucessCallBack = (res: any) => {
    console.log("@@@ ====== createCollection success call back", res);
    
    
  };
  createCollectionFailureCallBack = (res: any) => {
    console.log("@@@ ====== createCollection  Failure success call back", res);
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors) {
        this.responseSucessCell(apiRequestCallId, responseJson)
      } else if (responseJson && responseJson.errors) {
        this.responseFailureCall(apiRequestCallId, responseJson)
      }
    }
  }


  responseSucessCell = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.createCollectionApiCallId) {
      this.createCollectionSucessCallBack(responseJson);
    }

  }

  responseFailureCall = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.createCollectionApiCallId) {
      this.createCollectionFailureCallBack(responseJson);
    }

  }


  createCollectionpostapi = async () => {
    let formdata = new FormData();
    formdata.append("post_collection[name]", this.state.collectionName);

    this.createCollectionApiCallId = await ApiCall({
      method: "POST",
      endPoint: `/bx_block_posts/post_collections`,
      body: formdata,
      type: 'formData'
    });
  };

 submitButton=()=>{
  this.createCollectionpostapi()
  this.setState({collectionName:""})
  this.props.navigation.goBack()
 }

  // Customizable Area End

}
