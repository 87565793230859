// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { handleErrorArray } from "../../../components/src/common";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
const urlRegex = /^https?:\/\/(www\.)?[\w.-]+\.[a-z]{2,}(\/\S*)?$/;
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes: any;
    linkOpen: boolean;
    getItem: (id:string, type: string) => void;
    postId: string;
    handleClose: ()=> void;
    description?: string;
    info: {
        type: string;
        name: string;
        is_private: boolean;
        image: string;
      }
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean;
    selectedFile: File | null;
    data: {
        thumbnail: string;
        description: string;
        url: string;
    }
    error: {
        url: string;
        thumbnail: string;
        description: string;
    };
    postId: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CreatePostLinkModalController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    singlePostApi: string = '';
    createPostApiId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isLoading: false,
            selectedFile: null,
            data: {
                thumbnail: '',
                description: '',
                url: '',
            },
            error: {
                url: '',
                thumbnail: '',
                description: ''
            },
            postId: '',
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson) {
                this.setState({ isLoading: false });
                if (apiRequestCallId === this.createPostApiId) {
                    if (responseJson.data) {
                    this.handleCreateEditResponse(responseJson)
                    } else {
                        handleErrorArray(responseJson.errors)
                    }
                } if (apiRequestCallId === this.singlePostApi) {
                    this.handleResponseSinglePost(responseJson)
                }
            }

        }
    }

    handleCreateEditResponse = (responseJson: {data:{id:string},meta: {message:string}}) => {
            const isNew = !this.state.postId ? 'new' : 'edit';
            toast.success(responseJson.meta.message)
            this.props.getItem(responseJson.data.id, isNew)
            this.props.handleClose()
    }

    handleResponseSinglePost = (responseJson: {data:{attributes: { thumbnail: string,
        description: string,
        url: string,}}}) => {
        if (responseJson.data) {
            this.setState({
                data: {
                    ...responseJson.data.attributes,
                }
            });
        }
    }
    async componentDidMount(){
        super.componentDidMount();
        if(this.props.description){
            this.setState({data: {...this.state.data,description: this.props.description}});
        }
        if(this.props.postId){
            this.getSinglePost(this.props.postId);
            this.setState({postId: this.props.postId})
        }
    }

    handleOnImportFile = (list: FileList) => {
        if (list.length > 1) return this.setState({ error: { ...this.state.error, thumbnail: 'Select only one image' } })
        let extension = list[0].name.split('.').pop() as string

        if (!["png", "jpg", "jpeg"].includes(extension)) return

        this.setState({
            selectedFile: list[0],
            data:{...this.state.data,thumbnail: URL.createObjectURL(list[0])}
        })
    }

    handleOpenUploadBtn = () => {
        document.getElementById("fileInput")?.click()
    }

    handleChangeUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({data:{...this.state.data, [e.target.name]: e.target.value}, error: { ...this.state.error,  [e.target.name]: '' } })
    }

    handlePublish = async () => {
        let error = {
            url: '',
            description: ''
        }
        const valid = urlRegex.test(this.state.data.url)
        if (!this.state.data.url || !valid) {
            error.url = "Enter valid URL link"
        }
        if (!this.state.data.description) {
            error.description = "Please enter post description"
        }
        const hasError = Object.values(error).some((value) => value !== '');
        if (hasError) {
            return this.setState({ error: {...this.state.error,...error} })
        }
        this.setState({ isLoading: true });
        let signupToken = await getStorageData("SignupSuccessToken");
        let loginToken = await getStorageData("LoginSuccessToken");
        const header = {
            token: signupToken || loginToken,
        };
        const formData = new FormData();
        formData.append("post[url]", this.state.data.url);
        this.state.selectedFile && formData.append("post[thumbnail]", this.state.selectedFile);
        formData.append("post[description]", this.state.data.description);
        formData.append("post[post_type]", 'url');


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.createPostApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.state.postId ? configJSON.editPost + this.state.postId : configJSON.postsEnd
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            this.state.postId ? configJSON.putApiMethode : configJSON.PostAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSinglePost = async (id: string) => {
        this.setState({isLoading: true})
        const loginToken2 = await getStorageData('LoginSuccessToken');
        const signupToken1 = await getStorageData('SignupSuccessToken');
        const header = {
            token: signupToken1 || loginToken2
        };
        const getAlonePost = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.singlePostApi = getAlonePost.messageId;

        getAlonePost.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postsEnd + id
        );

        getAlonePost.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        getAlonePost.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.get
        );

        runEngine.sendMessage(getAlonePost.id, getAlonePost);
    }
    // Customizable Area End
}