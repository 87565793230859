import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/utils";
// Customizable Area Start
import React from "react";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
interface ILikeUser {
    id: number;
    name: string;
    type: string;
    is_login_user: boolean;
    if_followed: boolean;
    profile_pic: string;
}
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    data: any;
    refreshData: () => void;//allow to update post
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    mainCommentMessage: string;
    openChildrenId: string;
    showAll: boolean;
    openImgModel: boolean;
    error: string;
    imageUrl: string;
    file: File | null;
    likeModal: boolean;
    postLikeUserList: ILikeUser[];
    loadingpostLike: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CommentPostController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    sendCommentApi: string = "";
    postLikeUserListId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            mainCommentMessage: "",
            openChildrenId: "",
            showAll: false,
            openImgModel: false,
            error: "",
            imageUrl: "",
            file: null,
            likeModal: false,
            postLikeUserList: [],
            loadingpostLike: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId1 = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson1 = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            
            if (apiRequestCallId1 && responseJson1) {
                this.setState({loadingpostLike: false})
                if (!responseJson1.errors) {
                    if (apiRequestCallId1 === this.sendCommentApi) return this.mainCommentResponse()
                    if (apiRequestCallId1 === this.postLikeUserListId) return this.postLikeUserListResponse(responseJson1)
                }
            }
        }
    }

    mainCommentResponse = async () => {
        this.setState({ mainCommentMessage: "", openChildrenId: "" });
        this.props.refreshData()
    }

    handleChangeMainComment = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ mainCommentMessage: e.target.value })
    }

    handleShowAll = () => {
        this.setState({ showAll: !this.state.showAll })
    }
   

    showComment = () => {
        const data = this.props.data
        if (data?.attributes) {
            return data.attributes.comments_count > 0 ? `${data.attributes.comments_count} comments` : ""
        }
    }

    sendComment = async () => {
        if (!this.state.mainCommentMessage) return
        const signupToken = await getStorageData('SignupSuccessToken');
        const loginToken = await getStorageData('LoginSuccessToken');
        let body = new FormData();
        body.append("post_id", this.props.data.id);
        body.append("comment", this.state.mainCommentMessage);

        this.sendCommentApi = sendAPIRequest(configJSON.createCommentEnd, {
            method: configJSON.PostAPiMethod,
            body,
            headers: {
                token: signupToken || loginToken
            }
        });
    }

    openModel = () => {
        this.setState({ openImgModel: true });
    }

    setOpenChildrenId = (id: string) => {
        const temp = this.state.openChildrenId === id ? "" : id;
        this.setState({
            openChildrenId: temp,
        });
    };

    handleClose = () => {
        this.setState({ openImgModel: false, imageUrl: "" });
    };

    handleCloseImgModel = async () => {
        this.handleClose();
        const { file } = this.state;
        if (file) {
            const loginToken = await getStorageData('LoginSuccessToken');
            const signupToken = await getStorageData('SignupSuccessToken');
            let body = new FormData();
            body.append("post_id", this.props.data.id);
            body.append("comment_media[]", file);
            body.append("comment", this.state.mainCommentMessage);

            this.sendCommentApi = sendAPIRequest(configJSON.createCommentEnd,
                {
                    method: configJSON.PostAPiMethod,
                    body,
                    headers: { token: signupToken || loginToken }
                });
        }
    }

    handleOnImportFile = (fileData: FileList) => {
        if (fileData.length > 1) return this.setState({ error: "select only one image" });
        let extension = fileData[0].name.split('.').pop() as string;

        if (!["png", "jpg", "jpeg"].includes(extension)) return this.setState({ error: "image formate not supported" });

        this.setState({
            imageUrl: URL.createObjectURL(fileData[0]),
            file: fileData[0]
        })

    };

    handleOpenUploadBtn = () => {
        document.getElementById("fileInput")?.click()
    }

    handleOpenLike = () => {
        this.setState({likeModal: true, loadingpostLike: true})
        this.getLikedUserList();
    }

    handleCloseLike = () => {
        this.setState({likeModal: false})
    }

    getLikedUserList = async() => {
        const signupToken = await getStorageData('SignupSuccessToken');
        const loginToken = await getStorageData('LoginSuccessToken');
        const header = {
            token: signupToken || loginToken
          };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.postLikeUserListId = requestMessage.messageId;
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postLikeUserListEnd+this.props.data.id
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.get
          );
          runEngine.sendMessage(requestMessage.id, requestMessage); 
    }

    postLikeUserListResponse = (response:ILikeUser[]) => {
        if(response.length > 0){
            this.setState({postLikeUserList: response})
        }
    }

    userRedirection = (id: number, typ: string) =>{
        if(typ.toLowerCase().includes("community")) return this.props.navigation.navigate("OtherUserProfile",{id:`${id}-Community`})
        this.props.navigation.navigate("OtherUserProfile",{id:id})
    }
    // Customizable Area End
}