import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/utils";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from "react-toastify";
import { isLocalAdmin } from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  usernameError: boolean;
  usernameErrorMessage: string;
  isLocalAdminFlag: boolean;
  timezone: any;
  timezoneError: any;
  timezoneErrorMessage: any;
  timezoneList: any;
  selectedSetting: any;
  userName: any;
  password: any;
  AssessmentCheck:boolean;
  AssessmentScore:boolean;
  AssessmentAlert:boolean;
  passwordError: any;
  passwordErrorMessage: any;
  firstName: any;
  firstnameError: any;
  firstnameErrorMessage: any;
  lastName: any;
  lastnameError: any;
  lastnameErrorMessage: any;
  openLogout: any;
  public_post: any;
  AssessmentContent:boolean;
  private_post: any;
  user_name: any;
  email: any;
  profile_picture: any;
  title: any;
  current_password:any;
  change_password:any;
  AssesmentRemainder:boolean;
  reenter_password:any;
  current_password_Error:any;
  current_password_ErrorMessage:any;
  change_password_Error:any;
  showPass:any;
  showPassOne:any;
  showPassTwo:any;
  change_password_ErrorMessage:any;
  spinner:boolean;
  reenter_password_Error:any;
  reenter_password_ErrorMessage:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SettingsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
   createUserId: any;
   DeactivateUserId: any;
   createPublicId: any;
   createPasswordChangeId:any;
   createPrivateId: any;
   createFullnameId: any;
   sendNotificationApi:any;
   apiGetUserData: any;
  // Customizable Area End

   constructor(props: Props) {
     super(props);
     this.receive = this.receive.bind(this);

     // Customizable Area Start
     this.subScribedMessages = [
       getName(MessageEnum.AccoutLoginSuccess),
       // Customizable Area Start
       getName(MessageEnum.RestAPIResponceMessage),
       getName(MessageEnum.NavigationPayLoadMessage),
       getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

     this.state = {
       txtInputValue: "",
       txtSavedValue: "A",
       enableField: false,
      // Customizable Area Start
       userName: "",
       usernameError: false,
       isLocalAdminFlag:false,
       spinner:true,
       usernameErrorMessage: "",
       timezone: "",
       timezoneError: false,
       timezoneErrorMessage: "",
       current_password:"",
       change_password:"",
       reenter_password:"",
       showPass:false,
       showPassOne:false,
       showPassTwo:false,
       current_password_Error:"",
       AssessmentCheck:false,
       AssessmentContent:false,
       AssessmentScore:false,
       AssessmentAlert:false,
       current_password_ErrorMessage:"",
       change_password_Error:"",
       change_password_ErrorMessage:"",
       reenter_password_Error:"",
       AssesmentRemainder:false,
       reenter_password_ErrorMessage:"",
       timezoneList: [
        {
           id: 1,
           label: "Privacy concerns",
           value: "Privacy concerns",
        },
        {
           id: 2,
           label: "Just need a break",
           value: "Just need a break",
        },
        {
           id: 3,
           label: "Trouble getting in started",
           value: "Trouble getting in started",
        },
        {
            id: 4,
            label: "Create a second account",
            value: "Create a second account",
        },
        {
           id: 5,
           label: "Concerned about my data",
           value: "Concerned about my data",
        },
      ],
        selectedSetting: "profile",
        firstName: "",
        firstnameError: false,
        firstnameErrorMessage: "",
        lastName: "",
        lastnameError: false,
        lastnameErrorMessage: "",
        password: "",
        passwordError: "",
        passwordErrorMessage: "",
        openLogout: false,
        public_post: "public",
         private_post: "",
         user_name: "",
         email: "",
        profile_picture: "",
        title: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

   async receive(from: string, message: Message) {
     runEngine.debugLog("Message Recived", message);

     if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
       let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
         "Change Value",
         "From: " + this.state.txtSavedValue + " To: " + value
      );

       this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

     const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
     );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
     let responseJson = message.getData(
       getName(MessageEnum.RestAPIResponceSuccessMessage)
     );

        this.apiReicieveCall(apiRequestCallId,responseJson,errorReponse);

     if (apiRequestCallId === this.createPublicId) {
      if (!responseJson.errors) {
        toast.success("Privacy Updated Successfully to Public");
        localStorage.setItem("privatePost","false")
        console.log("myresponse.........", responseJson);
      } else {
        this.parseApiErrorResponse(errorReponse);
      }
     }

     if (apiRequestCallId === this.createPrivateId) {
       if (!responseJson.errors) {
         toast.success("Privacy Updated Successfully to Private");
         localStorage.setItem("privatePost","true")
         console.log("myresponse.........", responseJson);
       }  else {
         this.parseApiErrorResponse(errorReponse);
      }
    }

     if (apiRequestCallId === this.createFullnameId) {
       if (!responseJson.errors) {
         toast.success("Full name Updated Successfully");
         this.setState({ firstName: this.state.firstName, lastName: this.state.lastName });
         console.log("myresponse.........", responseJson);
       } else {
         this.parseApiErrorResponse(errorReponse);
      }
    }

    // Customizable Area End
  }

   txtInputWebProps = {
     onChangeText: (text: string) => {
       this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
     ...this.txtInputWebProps,
      autoCompleteType: "email",
     keyboardType: "email-address",
  };
   txtInputProps = this.isPlatformWeb()
     ? this.txtInputWebProps
     : this.txtInputMobileProps;
    
   btnShowHideProps = {
     onPress: () => {
        this.setState({ enableField: !this.state.enableField });
       this.txtInputProps.secureTextEntry = !this.state.enableField;
       this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
         ? imgPasswordVisible
         : imgPasswordInVisible;
    },
  };

   btnShowHideImageProps = {
     source: this.txtInputProps.secureTextEntry
       ? imgPasswordVisible
       : imgPasswordInVisible,
   };

   btnExampleProps = {
     onPress: () => this.doButtonPressed(),
   };

   doButtonPressed() {
     let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
     msg.addData(
       getName(MessageEnum.AuthTokenDataMessage),
       this.state.txtInputValue
     );
     this.send(msg);
  }

  // web events
   setInputValue = (text: string) => {
     this.setState({ txtInputValue: text });
  };

   setEnableField = () => {
     this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
 
   async componentDidMount() {
  
     this.handleGetUserData();
     if( localStorage.getItem("profileData")!="")
     {
      let parsedData=localStorage.getItem("profileData")
      if(parsedData!=null)
      {
        let parseData=JSON.parse(parsedData)
       {parseData?.is_private?this.setState({private_post:"private",public_post:""}):
       this.setState({public_post:"public",private_post:""})}
      }
     
     }
   }

   subApiReceiveCall = async (apiRequestCallId:any,responseJson:any,errorReponse:any) =>{

    if (apiRequestCallId === this.apiGetUserData) {
      if (!responseJson.errors) {
  
        this.setState({
         email: responseJson?.data?.attributes?.email,
         profile_picture: responseJson?.data?.attributes?.profile_picture,
         user_name:  await isLocalAdmin()?responseJson?.data?.attributes?.name:responseJson?.data?.attributes?.full_name,
         userName: await isLocalAdmin()?responseJson?.data?.attributes?.name:responseJson?.data?.attributes?.full_name,
         firstName: responseJson?.data?.attributes?.first_name,
         lastName: responseJson?.data?.attributes?.last_name,
         spinner:false,
        
       });
     } 
  }
  if (apiRequestCallId === this.createPasswordChangeId) {
    if (!responseJson.error ) {
      toast.success("Password Updated Successfully");
      this.setState({
         current_password:"",
         reenter_password:"",
         change_password:""
     });
   } else {
     this.parseApiErrorResponse(errorReponse);
     toast.error(responseJson?.error[0]?.message);
     this.setState({
      current_password:"",
      reenter_password:"",
      change_password:""
  });
  }
}
   }

 apiReicieveCall=async (apiRequestCallId:any,responseJson:any,errorReponse:any)=>{
   if (apiRequestCallId === this.createUserId) {
     if (!responseJson.errors) {
       toast.success("User Updated Successfully");
        this.setState({ user_name: this.state.userName });
        console.log("myresponse.........", responseJson);
    } 
  }

this.subApiReceiveCall(apiRequestCallId,responseJson,errorReponse)
if(apiRequestCallId=== this.sendNotificationApi)
{
  if(!responseJson.error)
  {
    toast.success("Notification setting changed")
  }
}


   if (apiRequestCallId === this.DeactivateUserId) {
     if (!responseJson.errors) {
       toast.success("User Deactivated Successfully");
       localStorage.clear();
       this.props.navigation.navigate("LogIn")
       this.setState({ timezone: "", password: "" });
       
       console.log("myresponse.........", responseJson);
     } else {
      toast.error("Invalid Password");
       this.parseApiErrorResponse(errorReponse);
    }
  }
}

   handleUsernameChange = (event: any) => {
      
       this.setState({
         userName: event?.target?.value,
         usernameError: false,
         usernameErrorMessage: "",
    });
  };

  EyeChange=()=>{
    this.setState({showPass:!this.state.showPass})
  }
  EyeChangeOne=()=>{
    this.setState({showPassOne:!this.state.showPassOne})
  }
  EyeChangeTwo=()=>{
    this.setState({showPassTwo:!this.state.showPassTwo})
  }
   handleFirstnameChange = (event: any) => {
     this.setState({
       firstName: event?.target?.value,
       firstnameError: false,
       firstnameErrorMessage: "",
     });
   };
   handlePasswordChange = (event: any) => {
       this.setState({
       password: event?.target?.value,
       passwordError: false,
        passwordErrorMessage: "",
    });
  };
   handlelastnameChange = (event: any) => {
     this.setState({
       lastName: event?.target?.value,
       lastnameError: false,
       lastnameErrorMessage: "",
    });
  };
  handleChangeDropdownValue = (e: any) => {
     console.log(e.target.value, "DropDown");
     const { value } = e.target;
    let fieldValue: any = value;
 
    if (fieldValue) {
      this.setState({
        timezone: fieldValue,
        timezoneError: false,
      });
    }
  };
 
  renderValueTimezone = (selected: any) => {
     console.log(selected, "Selected--SS");
     if (!selected || selected.length === 0) {
       return "Select";
     }
    return selected;
  };
handleAssessmentToggle=async ()=>{
this.setState({AssessmentCheck:!this.state.AssessmentCheck,AssessmentAlert:!this.state.AssessmentCheck,AssessmentScore:!this.state.AssessmentCheck,AssesmentRemainder:!this.state.AssessmentCheck,AssessmentContent:!this.state.AssessmentCheck});
   this.handleAPICall();
}
handleAssessmentScoreToggle=()=>{
  this.setState({AssessmentScore:!this.state.AssessmentScore});
this.handleAPICall();
  }
  handleAssessmentRemainderToggle=()=>{
    this.setState({AssesmentRemainder:!this.state.AssesmentRemainder})
    this.handleAPICall();
  }
  handleAssessmentAlertToggle=()=>{
    this.setState({AssessmentAlert:!this.state.AssessmentAlert});
    this.handleAPICall();
  
    }
    handleContentAlertToggle=()=>{
      this.setState({AssessmentContent:!this.state.AssessmentContent});
    this.handleAPICall();
      }

      handleAPICall=async ()=>{
        const signupTokn = await getStorageData('SignupSuccessToken');
const loginTokn = await getStorageData('LoginSuccessToken');
const attrs = {
  "assessment_notification": this.state.AssessmentCheck,
  "assessment_score": this.state.AssessmentScore,
  "assessment_alerts": this.state.AssessmentAlert,
  "appointment_reminder":this.state.AssesmentRemainder,
  "content_notification": this.state.AssessmentContent
};

const data = {
  attributes: attrs,
};

const httpBody = {
  data: data,
};


this.sendNotificationApi = sendAPIRequest("update_notification_setting", {
  method: "PUT",
  body: httpBody,
  headers: {
    token: signupTokn || loginTokn
  }
})
      }
  logOutoption = () => {

    this.setState({ openLogout: true, title: "logOut", selectedSetting:"logout" });
  };

  deactivateOption = () => {
    this.setState({
      selectedSetting: "deactivate",
      timezone: "",
      timezoneError: false,
      timezoneErrorMessage: "",
      password: "",
      passwordError: false,
      passwordErrorMessage: "",
    });
  };

  supportOption = () => {
    this.setState({ selectedSetting: "support" });
  this.props.navigation.navigate("Help")
  };

  privacyOption = () => {
    this.setState({ selectedSetting: "privacy" });
  };

  notificationOption = () => {
    this.setState({ selectedSetting: "notification" });
  };

  profileOption = () => {
    this.setState({ selectedSetting: "profile" });
  };
  PasswordOption = () => {
    this.setState({ selectedSetting: "password" });
  }

  handleDeactivateModal = () => {
    if (this.state.timezone === "") {
      this.setState({
        timezoneError: true,
        timezoneErrorMessage: "Please Select Option",
      });
    }
    if (this.state.password === "") {
      this.setState({
        passwordError: true,
        passwordErrorMessage: configJSON.textPwdErr,
      });
    }
   
    if(this.state.timezone!=="" && this.state.password!=="")
   { this.setState({ openLogout: true, title: "Deactivate" });}
  };

  changePassword = () => {
    this.setState({ selectedSetting: "password" });
  };

  handleCurrentPasswordChange=(event:any)=>{
 
  
  this.setState({
    current_password: event?.target?.value,
    current_password_Error:false,
    current_password_ErrorMessage:""
});


  }

  navigateForgotPassword=()=>{
      this.props.navigation.navigate("ForgotPasswordPhoneVerification")
  }

  handleForgotPasswordChange=(event:any)=>{
      
  this.setState({
    change_password: event?.target?.value,
    change_password_Error:false,
    change_password_ErrorMessage:""
});


  }
  handleConfirmPasswordChange=(event:any)=>{
  
  this.setState({
    reenter_password: event?.target?.value,
    reenter_password_Error:false,
    reenter_password_ErrorMessage:""

});

  }


  openUsernameTab = () => {
    this.setState({
      selectedSetting: "Username",
      usernameError: false,
      usernameErrorMessage: "",
    });
  };
 
  openFullnameTab = () => {
    this.setState({
      selectedSetting: "Fullname",
      firstName: this.state.firstName,
      firstnameError: false,
      firstnameErrorMessage: "",
      lastnameError: false,
      lastName: this.state.lastName,
      lastnameErrorMessage: "",
    });
  };

  handleBack = () => {
    this.setState({ selectedSetting: "profile" });
  };
  
  handleCloseModal = () => {
    this.setState({ openLogout: false });
  };

    
  handleLogout = () => {
    localStorage.setItem("LoginSuccessToken", "");
    localStorage.setItem("SignupSuccessToken", "");
    localStorage.setItem("LoggedInUserData","")
    this.props.navigation.navigate("LogIn");
  };
  
  handleUsernameSubmit = async (e: any) => {
    localStorage.setItem("userNameSetting","true");
    if (this.state.userName === "") {
      this.setState({
        usernameError: true,
        usernameErrorMessage: "Please Enter Username",
      });
    } else {
      e.preventDefault();

      const token = await localStorage.getItem("LoginSuccessToken");

      const header = {
        token: token,
        "Content-Type": "application/JSON",
      };

      const attrs = {
        name: this.state.userName,
      };

      const data = {
        attributes: attrs,
      };

      const httpBody = {
        data: data,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

       this.createUserId = requestMessage.messageId;

       requestMessage.addData(
         getName(MessageEnum.RestAPIResponceEndPointMessage),
         "bx_block_settings/name_setting"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
      console.log("message", requestMessage);
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  handleDeactivateSubmit = async (e: any) => {
    if (this.state.timezone === "") {
      this.setState({
        timezoneError: true,
        timezoneErrorMessage: "Please Select Option",
      });
    }
    if (this.state.password === "") {
      this.setState({
        passwordError: true,
        passwordErrorMessage: configJSON.textPwdErr,
      });
    }

    if (this.state.timezone !== "" && this.state.password !== "") {
      e.preventDefault();

      const token = await localStorage.getItem("LoginSuccessToken");

      const header = {
        token: token,
      };

      const formData = new FormData();

      formData.append("password", this.state.password);
      formData.append("deactivation_reason", this.state.timezone);

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.DeactivateUserId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "/deactivate_account"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
      console.log("message", requestMessage);
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  handleFullnameSubmit = async (e: any) => {
    if (this.state.firstName === "") {
      this.setState({
        firstnameError: true,
        firstnameErrorMessage: "Please Enter First name",
      });
    }
    if (this.state.lastName === "") {
      this.setState({
        lastnameError: true,
        lastnameErrorMessage: "Please Enter Last name",
      });
    }

    if (this.state.firstName !== "" && this.state.lastName !== "") {
      e.preventDefault();
      const token = await localStorage.getItem("LoginSuccessToken");

      const header = {
        token: token,
        "Content-Type": "application/JSON",
      };

      const attrs = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
      };

      const data = {
        //   type: "email_account",
        attributes: attrs,
      };

      const httpBody = {
        data: data,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createFullnameId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_settings/account_name_setting"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
      console.log("message", requestMessage);
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

      handleChangePasswordSubmit = async (e: any) => {
        if (this.state.current_password === "") {
          this.setState({
            current_password_Error: true,
            current_password_ErrorMessage: "Please Enter Current Password",
          });
        }
        if (this.state.change_password === "") {
          this.setState({
            change_password_Error: true,
            change_password_ErrorMessage: "Please Enter New Password",
          });
        }
        if (this.state.reenter_password === "") {
          this.setState({
            reenter_password_Error: true,
            reenter_password_ErrorMessage: "Please Enter Confirm password",
          });
        }
        if(this.state.change_password!==this.state.reenter_password)
        {
          this.setState({
            reenter_password_Error: true,
            reenter_password_ErrorMessage: "New and Confirm Password not matching",
          });
        }
    
        if (this.state.change_password !== "" && this.state.current_password !== "" && this.state.reenter_password!== "") {
          e.preventDefault();
          const token = await localStorage.getItem("LoginSuccessToken");
    
          const header = {
            token: token,
            "Content-Type": "application/JSON",
          };
    
    
        
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
    
          this.createPasswordChangeId = requestMessage.messageId;
    
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `change_password?current_password=${this.state.current_password}&new_password=${this.state.change_password}&confirm_password=${this.state.reenter_password}`
          );
    
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
       
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "PUT"
          );
          console.log("message", requestMessage);
          runEngine.sendMessage(requestMessage.id, requestMessage);
          return true;
        }
      };

  privateSubmit = async (e: any) => {
    e.preventDefault();
    this.setState({ public_post: "", private_post: "private" });
    const token = await localStorage.getItem("LoginSuccessToken");

    const header = {
      token: token,
      "Content-Type": "application/JSON",
    };

    const attrs = {
      is_private: true,
    };

    const data = {
      //   type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createPrivateId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_settings/public_private_account_setting"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    console.log("message", requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
  publicSubmit = async (e: any) => {
    e.preventDefault();

    this.setState({ public_post: "public", private_post: "" });
    const token = await localStorage.getItem("LoginSuccessToken");

    const header = {
      token: token,
      "Content-Type": "application/JSON",
    };

    const attrs = {
      is_public: true,
    };

    const data = {
      //   type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createPublicId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_settings/public_private_account_setting"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    console.log("message", requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetUserData = async () => {
    this.setState({isLocalAdminFlag: await isLocalAdmin()});
    let signupToken = await getStorageData("SignupSuccessToken");
    let loginToken = await getStorageData("LoginSuccessToken");

    const header = {
      token: signupToken || loginToken,
      "Content-Type": configJSON.apiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetUserData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     await isLocalAdmin()?"get_community":"get_user"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
