export const insertPhoto = require("../assets/insert_photo.png");
export const dropdown = require("../assets/sms.svg");
export const message = require("../assets/message-question.svg");
export const EditIcon=require("../assets/edit-2.svg");
export const Edit=require("../assets/edit.svg");
export const Send=require("../assets/send.svg");
export const Camera=require("../assets/camera.svg");
export const NoUser=require("../assets/user.svg");
export const Close=require("../assets/close-square.svg");
export const BlueClose=require("../assets/blue-close.svg");
export const BlueSend=require("../assets/BlueSend.svg");
export const MuteNotification=require("../assets/MuteNotification.svg");
export const Exit=require("../assets/Sign Out.svg");
export const Report=require("../assets/blue-close.svg");
export const OffNotification=require("../assets/MuteNotificationBlue.svg");
export const OnNotification=require("../assets/notification.svg");
export const Delete=require("../assets/trash.svg");
export const More=require("../assets/more.svg");
export const Cloud=require("../assets/folder-cloud.svg");
export const Plus=require("../assets/plus.svg");
export const SearchIcon = require("../assets/search_icon.svg");