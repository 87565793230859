// Customizable Area Start
import React from "react";
import {
  Box ,Button
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import OrganizationProfileEditController, {
  Props,
} from "./OrganizationProfileEditController.web";
import { Global ,PCard, Edit,roundLocation,Email, phone} from "./assets";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

export default class OrganizationProfileEdit extends OrganizationProfileEditController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { contact_number, email, name, address, website } = this.state.data
    // Customizable Area End
    return (
      // Customizable Area Start
      <MainWrapper component={"section"} display={"flex"}>
        <Loader loading={this.state.isLoading}/>
        <Box bgcolor={"#fff"} borderRadius={"12px"} p={"24px"} sx={{ width: { xs: "auto", md: "70%", lg: "60%" }, marginX: { xs: "32px", md: "auto" } }} my={"32px"}>
          <Box component={"h4"} color="#005487" fontFamily={"Verdana"} fontWeight={"bold"} fontSize={"22px"} m={0} mb={"24px"}>Organization Information</Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} alignItems={"start"} mb="24px">
            <img src={roundLocation} alt="location" />
            <Box display={"flex"} flexDirection={"column"} width={"100%"} ml="17px">
              <Box component={"label"} display={"block"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Address</Box>
              <Box component="p" fontFamily={"Verdana"} fontSize={"16px"} m={0} mt={"9px"} mb={"24px"} color="#808080">{address}</Box>
            </Box>
            {!this.orgId && <img src={Edit} alt="Edit" className="pointer" data-testid="edit-icon" onClick={this.navigateEditSection} />}
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} alignItems={"start"} mb="24px">
            <img src={Email} alt="email" />
            <Box display={"flex"} flexDirection={"column"} width={"100%"} ml="17px">
              <Box component={"label"} display={"block"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>E-mail</Box>
              <Box component="p" fontFamily={"Verdana"} fontSize={"16px"} m={0} mt={"9px"} mb={"24px"} color="#808080">{email}</Box>
            </Box>
            {!this.orgId && <img src={Edit} alt="Edit" className="pointer" onClick={this.navigateEditSection} />}
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} alignItems={"start"} mb="24px">
            <img src={PCard} alt="card" />
            <Box display={"flex"} flexDirection={"column"} width={"100%"} ml="17px">
              <Box component={"label"} display={"block"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Name</Box>
              <Box component="p" fontFamily={"Verdana"} fontSize={"16px"} m={0} mt={"9px"} mb={"24px"} color="#808080" data-testid="org-name">{name}</Box>
            </Box>
            {!this.orgId && <img src={Edit} alt="Edit" className="pointer" onClick={this.navigateEditSection} />}
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} alignItems={"start"} mb="24px">
            <img src={Global} alt="Global" />
            <Box display={"flex"} flexDirection={"column"} width={"100%"} ml="17px">
              <Box component={"label"} display={"block"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Website</Box>
              <Box component="p" fontFamily={"Verdana"} fontSize={"16px"} m={0} mt={"9px"} mb={"24px"} color="#808080">{website}</Box>
            </Box>
            {!this.orgId && <img src={Edit} alt="Edit" className="pointer" onClick={this.navigateEditSection} />}
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} alignItems={"start"} mb="24px">
            <img src={phone} alt="phone" />
            <Box display={"flex"} flexDirection={"column"} width={"100%"} ml="17px">
              <Box component={"label"} display={"block"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Primary Contact Info</Box>
              <Box component="p" fontFamily={"Verdana"} fontSize={"16px"} m={0} mt={"9px"} mb={"24px"} color="#808080">{contact_number}</Box>
            </Box>
            {!this.orgId && <img src={Edit} alt="Edit" className="pointer" onClick={this.navigateEditSection} />}
          </Box>
          <Box sx={{ width: { xs: "80%", md: "65%", lg: "60%" } }} margin={"auto"}>
            <Button className="done-btn" data-testid="done-btn" onClick={this.navigateDoneBtn}>Done</Button>
          </Box>
        </Box>
      </MainWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
  "& .pointer": {
    cursor: "pointer",
  },
  "& .done-btn": {
    backgroundColor: "#005487",
    width:"100%",
    fontWeight:"bold",
    fontSize:"16px",
    color: "#fff",
    height:"56px",
    fontFamily: "Verdana"
  },
})
// Customizable Area End
