import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { handleErrorArray } from "../../../components/src/common";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  data: {
    id: number;
    address: string;
    email: string;
    name: string;
    website: string;
    contact_number: string;
  };
  errors:{
    address: string;
    email: string;
    website: string;
    name: string;
    contact_number: string;
  };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UserOrganizationInformationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCreateEditOrgId:string = "";
  apiGetOrganizationId:string = "";
  type: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    
    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      data: {
        address: '',
        id: NaN,
        email: '',
        name: '',
        website: '',
        contact_number: '',
      },
      errors:{
        address: '',
        email: '',
        website: '',
        name: '',
        contact_number: '',
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(responseJson && apiRequestCallId===this.apiGetOrganizationId){
      this.setState({isLoading:false})
      this.getOrganizationDetailsResponse(responseJson)
    }
    if(responseJson && apiRequestCallId===this.apiCreateEditOrgId){
      this.setState({isLoading:false})
      this.createEditOrgResponse(responseJson)
    }
  }

  getOrganizationDetailsResponse = (responseJson: {
    id: number;
    address: string;
    email: string;
    name: string;
    website: string;
    contact_number: string;
    account_id: number;
    error:{message:string}[]
  }) => {
    if (responseJson.id) {
      this.type="edit"
      this.setState({ data: responseJson })
    }else{
      this.type="create"
    }
  }

  createEditOrgResponse = (responseJson:{
    id: number;
    address: string;
    email: string;
    name: string;
    website: string;
    contact_number: string;
    account_id: number;
    error:{message:string[]}[],
    organization:{
    id: number;
    address: string;
    email: string;
    name: string;
    website: string;
    contact_number: string;
    account_id: number;
    }
  }) => {
    if(responseJson.id){
      this.setState({data:responseJson})
      toast.success("Organization succesfully created")
      this.redirectionApi()
    }else if(responseJson.organization){
      this.setState({data:responseJson.organization})
      toast.success("Organization succesfully updated")
      this.redirectionApi()
    }else{
      handleErrorArray(responseJson.error)
    }
  }

  redirectionApi = () => {
    const param = this.props.navigation.getParam("navigationBarTitleText") ?? "";
    if (param.includes('profile')) {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "UserOrganizationInformationEdit");

      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(
        getName(MessageEnum.NavigationScreenNameMessage),
        "view-profile"
      );
      this.send(message);
    } else {
      this.props.navigation.navigate("UserOrganizationInformationEdit")
    }
  }
  
  async componentDidMount(): Promise<void> {
    super.componentDidMount();    
    this.setState({isLoading:true})
    this.getOrgDetails();
  }

  getOrgDetails = async () => {
    const signupToken = await getStorageData('SignupSuccessToken');
    const loginToken = await getStorageData('LoginSuccessToken');
    const header = {
      token: signupToken || loginToken,
      "Content-Type": configJSON.apiContentType,
    };

    const getOrgRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetOrganizationId = getOrgRequest.messageId;
    getOrgRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.get_organization
    );
    getOrgRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getOrgRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(getOrgRequest.id, getOrgRequest);
  }

  handleChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [name]: ''
      },
      data: {
        ...prevState.data,
        [name]: value
      },
    }));
  }

  checkRequiredfield = () => {
    let errors = {
      contact_number: '',
      address: '',
      email: '',
      website: '',
      name: '',
    };
    const { contact_number, address, email, website, name } = this.state.data
    if (!contact_number) {
        errors.contact_number = "Contact info is required";
    }

    if (!name) {
        errors.name = "Name is required";
    }

    if (!email) {
        errors.email = "Email is required";
    }

    if (!address) {
        errors.address = "Address are required";
    }

    if (!website) {
        errors.website = "Website is required";
    }

    const hasErrors = Object.values(errors).some(value => value !== '');
    this.setState({errors: errors});
    return hasErrors
  }

  handleSubmit = async () => {
    const hasErrors = this.checkRequiredfield();
    if(hasErrors) return;
    this.setState({isLoading:true})
    const body = new FormData();
    body.append("address",this.state.data.address);
    body.append("website",this.state.data.website);
    body.append("email",this.state.data.email);
    body.append("contact_number",this.state.data.contact_number);
    body.append("name",this.state.data.name);

    const loginToken = await getStorageData('LoginSuccessToken');
    const signupToken = await getStorageData('SignupSuccessToken');
    const header = {
      token: signupToken || loginToken,
      "Content-Type": configJSON.apiContentType,
    }

    const orgCreateEdit = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCreateEditOrgId = orgCreateEdit.messageId;
    orgCreateEdit.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.type === "edit" ? configJSON.get_organization + `/${this.state.data.id}` : configJSON.get_organization
    );
    orgCreateEdit.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    orgCreateEdit.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    orgCreateEdit.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.type === "edit" ? configJSON.putApiMethod : configJSON.postApiMethod
    );
    runEngine.sendMessage(orgCreateEdit.id, orgCreateEdit);
  }
  // Customizable Area End
}
