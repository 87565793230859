// Customizable Area Start
import React from "react";
import { IconButton, Box, Button,ClickAwayListener,FormHelperText, Popper, Grow, MenuItem, Paper, MenuList } from '@material-ui/core';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import CreatePostMainModalController, { Props } from "./CreatePostMainModalController.web";
import { CloseIcon, Gallery, arrowDown, calenderPost, globalIcon, smallLink, video, vimeoIcon, youtubeIcon } from "./assets";
import CreateEventStepOneModal from "./CreateEventStepOneModal.web";
import VideoTypePostStepTwoWeb from "./VideoTypePostStepTwo.web";
import CreatePostLinkModal from "./CreatePostLinkModal.web";
import  CreatePostImageModal  from "./CreatePostImageModal.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

// Customizable Area Start
const Poststyles = (theme: Theme) =>
    createStyles({
        heightResponsive: {
            overflowY: "auto",
            maxHeight: "85%",
        },
        inputFieldTextArea: {
            backgroundColor: "#f4f4f4",
            borderRadius: "12px",
            "& .MuiOutlinedInput-notchedOutline": {
                border: 0
            }
        },
        profilePic: {
            height: "50px",
            width: "50px",
            borderRadius: "50%"
        },
        communityPic: {
            height: "50px",
            width: "50px",
            borderRadius: "3px"
        },
        pointer:{
            cursor: 'pointer',
        },
        iconBtn: {
            padding: 0
        },
        Publishbtn: {
            height: "48px",
            fontFamily: "Verdana",
            fontSize: "16px",
            fontWeight: "bold",
            color: "#fff",
            backgroundColor: "#005487",
            width: "160px",
            textTransform: "none",
            "&:hover": {
                backgroundColor: "#005487"
            }
        },
        modalButton: {
            boxShadow: "2px 4px 16px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "12px",
        },
        videoIconItem: {
            width: "24px"
        },
        poperDiv: {
            zIndex: 2000
        },
        menuItem: {
            color: "#808080",
            "&:hover": {
                color: "#fff",
                backgroundColor: "#005487",
            }
        },
        error: {
            color: 'red',
            fontSize: "12px",
            fontFamily: "Verdana",
        },
        modal: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    })
// Customizable Area End


export class CreatePostMainModal extends CreatePostMainModalController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={this.props.openModal}
                className={this.props.classes.modal}
                closeAfterTransition
                onClose={this.props.handleClose}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 200,
                }}
            >
                <Fade in={this.props.openModal}>
                        <Box sx={{ width: { xs: "80%", md: "70%", lg: "50%" }, maxWidth: "604px" }} bgcolor={"#fff"} borderRadius="12px" className={this.props.classes.heightResponsive}>
                        {this.state.isLoading && <Loader loading={this.state.isLoading} />}
                            <Box display={"flex"} justifyContent={"space-between"} p={"24px"} borderBottom={"1px solid #808080"}>
                                <Box component={"h4"} fontFamily={"Verdana"} fontSize={"20px"} fontWeight={"bold"} color={"#808080"} m={0} data-test-id="title">
                                    Create Post
                                </Box>
                                <img src={CloseIcon} alt="close" className={this.props.classes.pointer} onClick={this.props.handleClose}/>
                            </Box>
                            <Box p={"20px 24px"} >
                                <Box display="flex" mb={'16px'}>
                                    <Avatar src={this.props.info.image} className={this.props.info.type === 'community' ? this.props.classes.communityPic : this.props.classes.profilePic} />
                                    <Box ml="12px">

                                        <Box component={"p"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"} m={0}>{this.props.info.name}</Box>
                                        <Box component={"p"} fontFamily={"Verdana"} fontSize={"14px"} m={0}mt={"3px"} display={"flex"} alignItems={"center"} gridGap={"5px"}><img src={globalIcon}/> {this.props.info.is_private ? "Private" : "Public"} <img src={arrowDown}/></Box>
                                    </Box>

                                </Box>
                                <TextField
                                    data-test-id="description-id"
                                    placeholder="Create a post"
                                    type="text"
                                    minRows={8}
                                    variant="outlined"
                                    name="description"
                                    error={Boolean(this.state.error.description)}
                                    onChange={this.handleChangeDescrription}
                                    value={this.state.description}
                                    multiline
                                    fullWidth
                                    className={this.props.classes.inputFieldTextArea}
                                />
                                    {this.state.error.description && (
                                        <FormHelperText className={this.props.classes.error}>
                                            {this.state.error.description}
                                        </FormHelperText>
                                    )}
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems={"center"} borderTop={"1px solid #808080"} p={"20px"}>
                                <Box display="flex" alignItems={"center"} gridGap={"20px"}>
                                    <IconButton className={this.props.classes.iconBtn} data-test-id="img-btn" onClick={this.handleOpenPhoto}><img src={Gallery} alt="Gallery" /></IconButton>
                                    <IconButton className={this.props.classes.iconBtn} data-test-id="video-btn" aria-controls={Boolean(this.state.anchorEl) ? 'menu-list-grow' : ''}
                                onClick={this.handleVideoOpen}
                                aria-haspopup="true"
                                    ><img src={video} alt="video" /></IconButton>
                                    <IconButton className={this.props.classes.iconBtn} data-test-id="event-btn" onClick={this.openEvent}><img src={calenderPost} alt="calenderPost"  /></IconButton>
                                    <IconButton className={this.props.classes.iconBtn} data-test-id="link-btn" onClick={this.openHyperLink}><img src={smallLink} alt="smallLink" /></IconButton>
                                    <Popper
                                className={this.props.classes.poperDiv}
                                open={Boolean(this.state.anchorEl)}
                                anchorEl={this.state.anchorEl}
                                role={undefined}
                                transition
                                placement={'bottom-start'}
                                data-test-id="poper-id">
                                {({ TransitionProps }) => (
                                    <Grow
                                        {...TransitionProps}
                                    >
                                        <Paper className={this.props.classes.modalButton}>
                                            <ClickAwayListener onClickAway={this.handleCloseEnchor} >
                                                <MenuList autoFocusItem={Boolean(this.state.anchorEl)} id="menu-list-grow">
                                                    <MenuItem onClick={this.handleOpenYTVideo} data-test-id="option-item" className={this.props.classes.menuItem}>
                                                        <Box display={"flex"} alignItems={"center"} p={"17px"} py={"11px"}>
                                                            <img src={youtubeIcon} alt="Link" className={this.props.classes.videoIconItem} />
                                                            <Box component={"p"} m={0} ml={"16px"} fontFamily={"Verdana"} fontSize={"17px"}>YouTube Embed</Box>
                                                        </Box>
                                                    </MenuItem>
                                                    <MenuItem onClick={this.handleOpenVimeoVideo} data-test-id="option-item" className={this.props.classes.menuItem}>
                                                        <Box display={"flex"} alignItems={"center"} p={"17px"} py={"11px"}>
                                                            <img src={vimeoIcon} alt="SendFillBlue" className={this.props.classes.videoIconItem} />
                                                            <Box component={"p"} m={0} ml={"16px"} fontFamily={"Verdana"} data-testid="text-share" fontSize={"17px"}>Vimeo Embed</Box>
                                                        </Box>
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                                </Box>
                                <Button variant="contained" type="submit" data-test-id="publish-btn" onClick={this.handlePublish} className={this.props.classes.Publishbtn}>Publish</Button>
                            </Box>
                        </Box>
                </Fade>
            </Modal>
            {this.state.imageModal && <CreatePostImageModal info={this.props.info} openModal={this.state.imageModal} postId={this.props.postId} description={this.state.description} navigation={this.props.navigation} getItem={this.props.getItem} closeModal={this.closeModal}/>}
            {this.state.hyperLinkModal && <CreatePostLinkModal info={this.props.info} linkOpen={this.state.hyperLinkModal} getItem={this.props.getItem} postId={this.props.postId} navigation={this.props.navigation} id={''} handleClose={this.closeModal} description={this.state.description}/>}
            {this.state.eventModal && <CreateEventStepOneModal info={this.props.info} eventId={''} getItem={this.props.getItem} navigation={this.props.navigation} id={''} openModal={this.state.eventModal} handleClose={this.closeModal}/>}
            {this.state.videoModal && <VideoTypePostStepTwoWeb info={this.props.info} open={this.state.videoModal} type={this.state.post_type} getItem={this.props.getItem} postId={this.props.postId} description={this.state.description} navigation={this.props.navigation} id={''} handleClose={this.closeModal}/>}
            </>
            // Customizable Area End
        )
    }
}

// Customizable Area Start

export default withStyles(Poststyles, { withTheme: true })(CreatePostMainModal);
// Customizable Area End