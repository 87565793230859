import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { getSignupLoginTokens, handleErrorArray } from "../../../components/src/common";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { isLocalAdmin } from "../../../components/src/common";
import { toast } from "react-toastify"
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes?: any;
    list: {
        id: string;
        name: string; 
        profilePicture: string;
        is_blocked_by_community: boolean;
    }[];
    handleRemoveFriend?: (id: string) => void;
    listType: "MUTUAL" | "FRIENDS" | "FOLLOWERS" | "COMMUNITY";
    handleRemoveFollower?: (id: string) => void;
    setShowSearch?:()=>void;
    resetData?: (type: boolean) => void; 
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isShowAllEnabled: boolean;
    anchorEl: { [key: string]: HTMLElement | null };
    anchorId: null| string;
    isLoading: boolean;
    block: {open: boolean, id: string| number, type: string};
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CommonListingController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    isOwnUser: boolean = false;
    getUserSIDId: string = "";
    apiBlockUnblockId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);


        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),

            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ]
        this.state = {
            isShowAllEnabled: false,
            anchorEl: {},
            anchorId: null,
            isLoading: false,
            block: {open: false, id:'', type: ''},
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
        // Customizable Area End
    }

    async receive(_from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        this.setState({isLoading: false})
        if (responseJson && apiRequestCallId === this.getUserSIDId) {
            if (responseJson.conversations) {
                const msg = new Message(getName(MessageEnum.NavigationChatViewMessage));
                msg.addData(
                    getName(MessageEnum.NavigationPropsMessage),
                    this.props
                )
                msg.addData(
                    getName(MessageEnum.UrlPathParamMessage),
                    responseJson.conversations[0].conversation_sid
                )
                this.send(msg)
            }
        }if(responseJson && this.apiBlockUnblockId===apiRequestCallId){
            if(responseJson.data){
                toast.success(responseJson.data[0].message)
                this.props?.resetData?.(true)
            }else if(responseJson.errors){
                handleErrorArray(responseJson.errors)
            }
        }
        // Customizable Area End
    }


    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();

        const  profileData  = await getStorageData("profileData",true);
        const userId = await this.props.navigation.getParam("id") ?? "";
        const isLA = await isLocalAdmin();
        if (userId) {
            if(isLA){
                this.isOwnUser = profileData.id === userId.split('-')[1]
            }else{
                this.isOwnUser = profileData.id === userId
            }
        }
    }

    setShowAllStatus = (show:boolean) => {
        this.setState({
            isShowAllEnabled: show
        });
    }

    handleEnchorClick = (event: React.MouseEvent<HTMLElement>, id: string) => {
        this.setState({
            anchorEl: {
                ...this.state.anchorEl,
                [id]: event.currentTarget
            }
        });
    };

    handleCloseEnchor = (id: string) => {
        this.setState({
            anchorEl: {
                ...this.state.anchorEl,
                [id]: null
            }
        });
    };

    handleMenuClick = (id: string) => {
        if(this.props.listType==="FRIENDS" && this.props.handleRemoveFriend && id){
            this.props.handleRemoveFriend(id);
        } 
        else if((this.props.listType==="FOLLOWERS" || this.props.listType === "COMMUNITY") && this.props.handleRemoveFollower && id){
            this.props.handleRemoveFollower(id);  
        } 
        this.handleCloseEnchor(id)
    }

    noDataFoundMessage = () => {
        switch(this.props.listType){
            case "FOLLOWERS":
                return "No Followers Found";
            case "FRIENDS":
                return "No Friends Found";
            case "MUTUAL":
                return "No Mutual Connections Found";
            case "COMMUNITY":
                return "No Members Found";
        }
    }

    headingText = () => {
        switch(this.props.listType){
            case "FOLLOWERS":
                return "Followers";
            case "COMMUNITY":
                return "Community Members";
            case "FRIENDS":
                return "Friends";
            case "MUTUAL":
                return "Mutual Friends";
        }
    }

    handleProfileOpen= (id: string) => {
        this.props.navigation.navigate("OtherUserProfile", {id});
    }

    handleMutualBtn = async (id: string) => {
        this.setState({isLoading: true})
        let body = new FormData();
        body.append("ids[account_id][]", (id).toString())
        const signupToken = await getStorageData('SignupSuccessToken');
        const loginToken = await getStorageData('LoginSuccessToken');
        const getUserSID = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        getUserSID.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.privateConversation
        )

        this.getUserSIDId = getUserSID.messageId;

        getUserSID.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.createSendRequestApiMethod
        )

        getUserSID.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        )

        const header = {
            token: signupToken || loginToken,
        }
        getUserSID.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        runEngine.sendMessage(getUserSID.id, getUserSID);

    }

    handleBlockUnblock = async() => {
        this.setState({isLoading: true})
        let body = new FormData();
        body.append("account_id", (this.state.block.id).toString())
        const signupToken = await getStorageData('SignupSuccessToken');
        const loginToken = await getStorageData('LoginSuccessToken');
        const blockUnblockApi = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        blockUnblockApi.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.state.block.type === "Unblock" ? configJSON.unblock_account : configJSON.block_account_for_community
        )

        this.apiBlockUnblockId = blockUnblockApi.messageId;

        blockUnblockApi.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            this.state.block.type === "Unblock" ? configJSON.deleteRequestApiMethod  : configJSON.exampleAPiMethod
        )

        blockUnblockApi.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        )

        const header = {
            token: signupToken || loginToken,
        }
        blockUnblockApi.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        runEngine.sendMessage(blockUnblockApi.id, blockUnblockApi);
        this.handleCloseBlockModal()
    }

    handleCloseBlockModal = () => {
        this.setState({block:{ open: false, id: '', type: '' }})
    }

    handleOpenBlockModal = (id: string) => {
        const blocked = this.props.list.find(item=>item.id===id)?.is_blocked_by_community
        this.setState({block:{ open: true, id: id, type: blocked ?  "Unblock" : "Block" }})
        this.handleCloseEnchor(id)
    }
    // Customizable Area End

}
