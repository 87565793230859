import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IMutualConnectionData } from "./ViewOtherUserProfileController.web";
// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    classes?: any;
    data: {
        id: string;
        attributes: {
            email: string;
            dob: string;
            full_phone_number: string|number;
            pronouns: string;
            race: string;
            ethnicity: string;
            gender: string;
            timezone: string;
            language: string[];
            is_private:boolean;
            social_media_urls: {
                account_id: number;
                created_at: string;
                id: number;
                updated_at: string;
                url: string;
            }[];
            selected_communities: {data:{id: string, attributes: { profile_pic: string, name: string }}[]}|null
        }
    };
    isOwnProfile: boolean;
    isCommunity: boolean;
    mutualConnectionData?: IMutualConnectionData
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    data: {}
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
// Customizable Area Start
export default class UserProfileInformationSectionController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiCreatePostCallId: any
    // Customizable Area End
    constructor(props: Props) {
        
        super(props);
        // Customizable Area Start
        
        this.state = {
            data:{}
        }
        // Customizable Area End
    }
 
       
    async receive(_from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start


        // Customizable Area End
    }

    handleMutualNavigation = () => {
        this.props.navigation.navigate("MutualConnectionList",{id:this.props.data.id})
    }

    editDetails = () => {
        if (this.props.isCommunity) {
            const msg = new Message(getName(MessageEnum.NavigationCreateCommunityMessage));
            msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(msg);
        } else {
            const msg = new Message(getName(MessageEnum.NavigationMessage));
            msg.addData(getName(MessageEnum.NavigationTargetMessage), "UserProfileInformation");

            msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            msg.addData(
                getName(MessageEnum.NavigationScreenNameMessage),
                "view-profile"
            );
            this.send(msg);
        }
    }

    navigateCommunity = (id: string) => {
        this.props.navigation.navigate("OtherUserProfile",{id:`${id}-community`})
    }
}
// Customizable Area End