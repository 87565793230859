// Customizable Area Start
import React from "react";
import { Box, Avatar, Button, MenuItem, ClickAwayListener, Popper, Grow, MenuList, Paper } from '@material-ui/core';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import CreatePostNewController, { Props, configJSON } from "./CreatePostNewController.web";
import { Gallery, calenderPost, smallLink, video, vimeoIcon, youtubeIcon } from "./assets";
import CreateEventStepOneModal from "./CreateEventStepOneModal.web";
import CreatePostLinkModal from "./CreatePostLinkModal.web";
import VideoTypePostStepTwoWeb from "./VideoTypePostStepTwo.web";
import  CreatePostImageModal  from "./CreatePostImageModal.web";
import CreatePostMainModal from "./CreatePostMainModal.web";
// Customizable Area End

// Customizable Area Start
const Poststyles = (theme: Theme) =>
    createStyles({
        create: {
            height: "30px",
            padding: "10px",
            width: "100%",
            marginLeft: "12px",
            border: 'none', "& fieldset": { border: 'none' },
            borderRadius: "24px",
            background: "#F4F4F4",
        },
        navUser: {
            width: "48px",
            height: "48px"
        },
        navCommunity: {
            width: "48px",
            height: "48px",
            borderRadius: "3px"
        },
        postAction: {
            fontFamily: "Verdana",
            fontSize: "17px",
            color: "#808080",
            textTransform: "none",
            padding: 0,
            "@media(max-width:390px)": {
                fontSize:"15px",
            }
        },
        modalButton: {
            boxShadow: "2px 4px 16px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "12px",
        },
        poperDiv: {
            zIndex: 2
        },
        videoIconItem: {
            width: "24px"
        },
        menuItem: {
            color: "#808080",
            "&:hover": {
                backgroundColor: "#005487",
                color: "#fff"
            }
        }
    })
// Customizable Area End


export class CreatePostNew extends CreatePostNewController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes } = this.props
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box width={"100%"}>
                <Box bgcolor={"#fff"} p={"24px"} borderRadius={"12px"}>
                    <Box display="flex" alignItems="center" >
                        <Avatar src={this.props.info.image} className={this.props.info.type === 'account' ? classes.navUser : classes.navCommunity} />
                        <TextField
                            onClick={this.handleOpenMainModal}
                            className={classes.create}
                            data-test-id="txtInputPost"
                            placeholder={configJSON.create_a_post}
                            type="text"
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                    </Box>
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        mt="17px"
                        gridRowGap={"16px"}
                    >
                        <Box sx={{ width: { xs: "50%", sm: "auto" }, marginRight: { xs: "0", sm: "40px", md: "40px", lg: "80px" } }}> {/* Adjust margin based on screen size */}
                            <Button className={classes.postAction} data-test-id="photo-id" onClick={this.handleOpenPhoto} startIcon={<img src={Gallery}></img>}>
                                Photo
                            </Button>
                        </Box>
                        <Box sx={{ width: { xs: "50%", sm: "auto" }, marginRight: { xs: "0", sm: "40px", md: "40px", lg: "80px" } }}>
                            <Button
                                className={classes.postAction}
                                data-test-id="video-id"
                                onClick={this.handleVideoOpen}
                                startIcon={<img src={video}></img>}
                                aria-controls={Boolean(this.state.anchorEl) ? 'menu-list-grow' : ''}
                                aria-haspopup="true"
                            >
                                Video
                            </Button>
                            <Popper
                                className={classes.poperDiv}
                                open={Boolean(this.state.anchorEl)}
                                anchorEl={this.state.anchorEl}
                                role={undefined}
                                transition
                                disablePortal
                                placement={'bottom-start'}
                                data-test-id="poper-id">
                                {({ TransitionProps }) => (
                                    <Grow
                                        {...TransitionProps}
                                    >
                                        <Paper className={classes.modalButton}>
                                            <ClickAwayListener onClickAway={this.handleCloseEnchor} >
                                                <MenuList autoFocusItem={Boolean(this.state.anchorEl)} id="menu-list-grow">
                                                    <MenuItem onClick={this.handleOpenYTVideo} data-test-id="yt-item" className={classes.menuItem}>
                                                        <Box display={"flex"} alignItems={"center"} p={"17px"} py={"11px"}>
                                                            <img src={youtubeIcon} alt="Link" className={classes.videoIconItem} />
                                                            <Box component={"p"} m={0} ml={"16px"} fontFamily={"Verdana"} fontSize={"17px"}>YouTube Embed</Box>
                                                        </Box>
                                                    </MenuItem>
                                                    <MenuItem onClick={this.handleOpenVimeoVideo} data-test-id="vimio-item" className={classes.menuItem}>
                                                        <Box display={"flex"} alignItems={"center"} p={"17px"} py={"11px"}>
                                                            <img src={vimeoIcon} alt="SendFillBlue" className={classes.videoIconItem} />
                                                            <Box component={"p"} m={0} ml={"16px"} fontFamily={"Verdana"} data-testid="text-share" fontSize={"17px"}>Vimeo Embed</Box>
                                                        </Box>
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </Box>
                        <Box sx={{ width: { xs: "50%", sm: "auto" }, marginRight: { xs: "0", sm: "40px", md: "40px", lg: "80px" } }}>
                            <Button className={classes.postAction} data-test-id="event-id" onClick={this.handleEventOpen} startIcon={<img src={calenderPost}></img>}>
                                Create Event
                            </Button>
                        </Box>
                        <Box sx={{ width: { xs: "50%", sm: "auto" }, marginRight: { xs: "0", sm: "40px", md: "40px", lg: "80px" } }}>
                            <Button className={classes.postAction} data-test-id="url-id" onClick={this.handleLinkOpen} startIcon={<img src={smallLink}></img>}>
                                URL
                            </Button>
                        </Box>
                    </Box>
                </Box>
                {this.state.eventModal && <CreateEventStepOneModal eventId={''} getItem={this.props.getItem} navigation={this.props.navigation} id={''} openModal={this.state.eventModal} handleClose={this.closeModal} info={this.props.info}/>}
                {this.state.hyperLinkModal && <CreatePostLinkModal linkOpen={this.state.hyperLinkModal} getItem={this.props.getItem} postId={''} navigation={this.props.navigation} id={''} handleClose={this.closeModal} info={this.props.info}/>}
                {this.state.videoModal && <VideoTypePostStepTwoWeb open={this.state.videoModal} type={this.state.post_type} getItem={this.props.getItem} postId={''} navigation={this.props.navigation} id={''} handleClose={this.closeModal} info={this.props.info}/>}
                {this.state.imageModal && <CreatePostImageModal postId="" openModal={this.state.imageModal} description={''} navigation={this.props.navigation} getItem={this.props.getItem} closeModal={this.closeModal} info={this.props.info}/>}
                {this.state.mainModal && <CreatePostMainModal openModal={this.state.mainModal} navigation={this.props.navigation} getItem={this.props.getItem} handleClose={this.closeModal} postId={''} info={this.props.info}/>}
            </Box>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
export default withStyles(Poststyles, { withTheme: true })(CreatePostNew);
// Customizable Area End