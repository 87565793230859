import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify"
// Customizable Area Start
import { handleErrorArray } from "../../../components/src/common";


export interface IFriendRequestsList{
    id: string;
    type: string;
    attributes:{
        "sender_id": number | string;
        "account_id": number;
        "status": null | string;
        "created_at": string;
        "updated_at": string;
        "community_id": null | string;
        "full_name": string;
        "profile_picture": string | null;
        "mutual_friends_count": {
            mutual_count: number;
            files: {
                photo: string | null;
            }[]
        }[];
        "requested_at": string;
        "email": string;
        "phone_number": string;
    }
}
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean;
    friendsRequestList: IFriendRequestsList[];
    friendsRequestCount: number;
    shouldShowAllBtn: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class FriendsRequestListController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    signupToken: string = "";
    loginToken: string = "";
    getFriendsRequestListApiCallId: string = "";
    updateFriendRequestApiCallId: string = "";
    getRecommandationListId: string ="";
    sendFriendRequestApiCallId: string ="";
    removeRecommendationApiCallId: string = "";
    type: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);


        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),

            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ]
        this.state = {
            isLoading: false,
            friendsRequestList: [],
            friendsRequestCount: 0,
            shouldShowAllBtn: true
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
        // Customizable Area End
    }

    async receive(_from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );


        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );


        if(apiRequestCallId === this.getFriendsRequestListApiCallId){
        this.setLoader(false)
            if(responseJson.data){
                this.setState({
                    friendsRequestList: responseJson.data,
                    friendsRequestCount: responseJson.total_requests_count
                })
            } else if(responseJson.length === 0 && !responseJson.data){
                this.setState({
                    friendsRequestCount: 0,
                    friendsRequestList: []
                })
            }
        }
        if(apiRequestCallId === this.getRecommandationListId){
        this.setLoader(false)
            if(responseJson.data){
                const temp = responseJson.data.map((_:{id:string,attributes:{full_name: string,profile_picture:string,mutual_connection_data:{mutual_count:number,files:{photo:string}[]}[]}})=>{
                    return(
                        {
                            id: _.id,
                            type:"Recommand",
                            attributes: {
                                sender_id: _.id,
                                account_id: "",
                                status: null,
                                created_at: "",
                                updated_at: "",
                                community_id: "",
                                full_name: _.attributes.full_name,
                                profile_picture: _.attributes.profile_picture,
                                mutual_friends_count: _.attributes.mutual_connection_data,
                                requested_at: "",
                                email: "",
                                phone_number: "",
                            }
                        }

                    )
                })
                this.setState({
                    friendsRequestList: temp,
                    friendsRequestCount: responseJson.data.length
                })
            } else {
                this.setState({
                    friendsRequestCount: 0,
                    friendsRequestList: []
                })
            }
        }
        if(apiRequestCallId === this.updateFriendRequestApiCallId){
        this.setLoader(false)
            if(responseJson.errors){
                toast.error(responseJson.errors)
            } else if(responseJson.message){
                toast.success(responseJson.message);
                this.getFriendsRequestList();
            }
        }
        if(apiRequestCallId === this.sendFriendRequestApiCallId){
            this.setLoader(false)
            this.responseFriendRequest(responseJson)
            
        }
        if(apiRequestCallId === this.removeRecommendationApiCallId){
            this.setLoader(false)
            this.responseRemoveRecommendation(responseJson)
        }
        // Customizable Area End
    }


    // Customizable Area Start
    responseRemoveRecommendation = (responseJson:{message:string}) => {
        if(responseJson && responseJson.message){
                toast.success(responseJson.message);
                this.getRecomnndedList();
        }
    }

    responseFriendRequest = (responseJson: {message:string,errors: {[key: string]: string}[]}) => {
        if(responseJson && responseJson.message){
                toast.success(responseJson.message);
                this.getRecomnndedList();
            }
            else if (responseJson && responseJson.errors) {
                handleErrorArray(responseJson.errors);
            }
    }

    async componentDidMount() {
        super.componentDidMount();
        const { signupToken, loginToken } = await this.getSignupLoginTokens();

        this.signupToken = signupToken;
        this.loginToken = loginToken;
        const recommendation = this.props.navigation.getParam("navigationBarTitleText")
        if(recommendation === "Recommandation"){
            this.type= "Recommandation";
            this.getRecomnndedList();
        } else {
            this.getFriendsRequestList();
        }
    }

    getSignupLoginTokens = async () => {
        let signupToken = await getStorageData('SignupSuccessToken');
        let loginToken = await getStorageData('LoginSuccessToken');
        let profileData = await getStorageData("profileData", true);

        return { signupToken, loginToken, profileData };
    }

    setLoader = (isTrue: boolean) => {
        this.setState({
            isLoading: isTrue
        });
    }

    getFriendsRequestList = () => {
        this.setLoader(true);
        const header = {
            token: this.signupToken || this.loginToken,
            "Content-Type": configJSON.apiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getFriendsRequestListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getFriendsRequestListApiEndpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getFriendsRequestListApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getRecomnndedList = () => {
        this.setLoader(true)
        const header = {
            token: this.signupToken || this.loginToken,
            "Content-Type": configJSON.apiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getRecommandationListId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.recommanded_accountsEnd
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getFriendsRequestListApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    updateFriendRequest = (isAccept: boolean, id: string) => {
        this.setLoader(true);
        const httpBody = {
            "data": {
              "status": isAccept ? "Accepted" : "Rejected"  
            },
        };

        const header = {
            token: this.signupToken || this.loginToken,
            "Content-Type": configJSON.exampleApiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateFriendRequestApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updateFriendRequestApiEndpoint + `/${id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody),
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.updateFriendsRequestApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleCloseClick = () => {
        this.props.navigation.goBack();
    }

    hideShowAllButton = () => {
        this.setState({
            shouldShowAllBtn: false
        })
    }

    handleFriendClick = (id: number| string) => {
        this.props.navigation.navigate("OtherUserProfile", {id});
    }

    sendFriendRequest = async (id: string | number) => {
        this.setLoader(true)
        const signupToken = await getStorageData('SignupSuccessToken');
        const loginToken = await getStorageData('LoginSuccessToken');

        const header = {
            "Content-Type": 'application/json',
            token: signupToken || loginToken
        }
        const body = {
            data: {
                "account_id": id.toString(),
                "request_text": ""
            }
        }
        const requestMessage1 = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage1.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createSendRequestApiEndpoint,
        );
        requestMessage1.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage1.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        requestMessage1.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        this.sendFriendRequestApiCallId = requestMessage1.messageId;
        runEngine.sendMessage(requestMessage1.id, requestMessage1);

    }

    removeRecommendation = async (id: string) => {
        this.setLoader(true);
        
        const formData = new FormData();
        formData.append("recommended_account_id", id);
        const  { signupToken, loginToken } = await this.getSignupLoginTokens();
        const header = {
            token: signupToken || loginToken
        };
        const removeRecommandation = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.removeRecommendationApiCallId = removeRecommandation.messageId;
        removeRecommandation.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.removeRecommendationApiEndpoint
        );
    
        removeRecommandation.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
    
        removeRecommandation.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.createSendRequestApiMethod
        );
    
        removeRecommandation.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData,
        );
    
        runEngine.sendMessage(removeRecommandation.id, removeRecommandation);
      }
    // Customizable Area End

}
