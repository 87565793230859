import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/utils";
import { Client } from 'twilio-chat';
// Customizable Area Start
import { isLocalAdmin } from "../../../components/src/common";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    id: string;
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    selectedFriends: { id: string, profile_picture: string, full_name: string }[];
    filterdFriends: { id: string, profile_picture: string, full_name: string }[];
    friendListApi: { id: string, profile_picture: string, full_name: string }[];
    followedCommunity: { id: string, profile_picture: string, full_name: string }[];
    radioValues: string[];
    formValue: string;
    type: string;
    model: boolean;
    group: {
        error: string,
        name: string,
    },
    sendError: string;
    typedMessage: string;
    cameraVisible: boolean;
    profileImageSrc: string;
    profileImg: File | null;
    imgModel: boolean;
    imgModelFile: string;
    buffer: Buffer | string;
    file: File | null;
    friendError: string;
    addMemberModal: boolean;
    addMemberPage: boolean;
    existingMember: string[];
    isLA: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CreateChatController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    oneToOneConversationId: string = "";
    groupConversationId: string = "";
    friendsListId: string = "";
    followersListId: string = "";
    getCommunityId: string ="";
    fetchConversationsId: string = "";
    addMemberId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            friendListApi: [],
            loading: false,
            selectedFriends: [],
            filterdFriends: [],
            radioValues: [],
            followedCommunity:[],
            formValue: "",
            type: "",
            model: false,
            typedMessage: "",
            group: {
                error: "",
                name: ""
            },
            sendError: "",
            cameraVisible: true,
            profileImageSrc: "",
            profileImg: null,
            imgModel: false,
            imgModelFile: "",
            buffer: "",
            file: null,
            friendError: "",
            addMemberModal: false,
            addMemberPage: false,
            existingMember: [],
            isLA: false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId1 = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson1 = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            switch (apiRequestCallId1) {
                case this.oneToOneConversationId:
                    {
                        this.handleOneToOneResponse(responseJson1)
                    }
                    break;
                case this.groupConversationId:
                    {
                        this.handleGroupResponse(responseJson1)
                    }
                    break;
                case this.friendsListId:
                    {
                        this.handleFriendsListResponse(responseJson1)
                    }
                    break;
                case this.followersListId:
                    {
                        this.handleFollowerListResponse(responseJson1)
                    }
                    break;
                case this.getCommunityId:
                    {
                        this.handleCommunityResponse(responseJson1)
                    }
                    break;
                case this.fetchConversationsId:
                    {
                        this.handleConversationResponse(responseJson1)
                    }
                    break;
                case this.addMemberId:
                    {
                        this.handleAddMemberResponse(responseJson1)
                    }
                    break;
                default: return;
            }
        }
    }

    handleOneToOneResponse = async (responseJson: { conversations: [{ conversation_sid: string }] }) => {

        if (this.state.imgModelFile) {
            await Promise.all(responseJson.conversations.map(async (item) => {
                await this.handleSendImage(item.conversation_sid);
            }))
        } else {
            const token = localStorage.getItem("chatToken") ?? "";
            const client = new Client(token);
            await Promise.all(responseJson.conversations.map(async (item) => {
                const channel = await client.getChannelBySid(item.conversation_sid);
                const message = await channel.sendMessage(String(this.state.typedMessage).trim());
                channel.updateLastConsumedMessageIndex(message);
            }));
        }
        this.setState({ loading: false });
        const msg = new Message(getName(MessageEnum.NavigationChatMessage));
        msg.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );
        this.send(msg)
    };

    handleGroupResponse = async (responseJson: { data: { conversation_sid: string, group_name: string, group_icon: string | null, participant: { data: [{ attributes: { receipient_name: string, receipient_email: string, twilio_identity: string } }] } } }) => {
        const { conversation_sid } = responseJson.data;
        
        if (this.state.imgModelFile) {
            await this.handleSendImage(conversation_sid)
        } else {
            const token = localStorage.getItem("chatToken") ?? "";
            const client = new Client(token);
            const channel = await client.getChannelBySid(conversation_sid);
            const message = await channel.sendMessage(String(this.state.typedMessage).trim());
            channel.updateLastConsumedMessageIndex(message);
        }
        this.setState({ loading: false });
        const msg = new Message(getName(MessageEnum.NavigationChatViewMessage));
        msg.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );
        msg.addData(
          getName(MessageEnum.UrlPathParamMessage),
          conversation_sid
        );
        this.send(msg)
    }

    handleFriendsListResponse = (responseJson: { data: { id: string, attributes: { profile_picture: string, full_name: string } }[] }) => {
        this.setState({ loading: false})
        if (responseJson.data) {
            const temp2 = responseJson.data.map(item => ({
                id: item.id,
                profile_picture: item.attributes.profile_picture,
                full_name: item.attributes.full_name,
            }));

            this.setState({ friendListApi:[...this.state.friendListApi, ...temp2 ] });
        }
    }

    handleFollowerListResponse = (responseJson: {data:{id:string,attributes:{ profile_picture: string, full_name: string }}[]}) => {
        this.setState({ loading: false})
        if (responseJson.data) {
            const temp2 = responseJson.data.map(item => ({
                profile_picture: item.attributes.profile_picture,
                id: item.id,
                full_name: item.attributes.full_name,
            }));

            this.setState({ friendListApi: temp2 })
        }
    }

    handleCommunityResponse =  (responseJson: { data: { id: string, attributes: { selected_communities: { data: { id: string, attributes: { profile_pic: string, name: string } }[] } } } }) => {
        if(responseJson.data){
            const temp = responseJson.data.attributes.selected_communities.data.map(item=>(
                {
                    full_name: item.attributes.name,
                    id: item.id,
                    profile_picture: item.attributes.profile_pic
                }
            ))

            this.setState({ friendListApi: [...this.state.friendListApi, ...temp], followedCommunity: temp})
        }
    }

    handleConversationResponse = (responseJson: {
        conversations: { receipient_data: { data: { attributes: {receipient_id:string} }[] } }
    }) => {
        if (responseJson?.conversations?.receipient_data?.data)
            this.setState({
                existingMember: responseJson.conversations.receipient_data.data.map(item => String(item.attributes.receipient_id))
            });
    }

    handleAddMemberResponse = (response: { data: { message: string } }) => {
        toast.success(response.data.message)
        const msg = new Message(getName(MessageEnum.NavigationChatViewMessage));
        msg.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );
        msg.addData(
          getName(MessageEnum.UrlPathParamMessage),
            this.props.navigation.getParam("navigationBarTitleText").split('-')[1]
          );
        this.send(msg)
    }

    async componentDidMount() {
        super.componentDidMount();
        const isLA = await isLocalAdmin();
        const param = this.props.navigation.getParam("navigationBarTitleText");
        if(param.includes("add")){
            this.setState({addMemberPage: true})
            this.getCurruntGroupMember();
        }
        if(isLA){
            this.getFollowersApi();
            this.setState({friendError: "No Member Found For Chat",isLA: true})
        }else{
            this.getFriendsApi();
            if(!param.includes("add")){
                this.getCommunityApi(); //use for get followed community details
            }
            this.setState({friendError: configJSON.friendError})
        }
        this.setState({loading: true})
    }

    getFriendsApi = async () => {
        const signupTokn = await getStorageData('SignupSuccessToken');
        const loginTokn = await getStorageData('LoginSuccessToken');
        this.friendsListId = sendAPIRequest(configJSON.friendList, {
            method: configJSON.getApiMethod,
            headers: {
                "Content-Type": configJSON.apiContentType,
                token: signupTokn || loginTokn
            }
        });
    }

    getFollowersApi = async () => {
        const signupTokin = await getStorageData('SignupSuccessToken');
        const loginTokin = await getStorageData('LoginSuccessToken');
        this.followersListId = sendAPIRequest(configJSON.followerList, {
            method: configJSON.getApiMethod,
            headers: {
                "Content-Type": configJSON.apiContentType,
                token: signupTokin || loginTokin
            }
        });
    }

    getCommunityApi = async () => {
        const loginTokin = await getStorageData('LoginSuccessToken');
        const signupTokin = await getStorageData('SignupSuccessToken');
        this.getCommunityId = sendAPIRequest(configJSON.getUser, {
            method: configJSON.getApiMethod,
            headers:{
                "Content-Type": configJSON.apiContentType,
                token: signupTokin || loginTokin
            } 
        });
    }

    getCurruntGroupMember = async () => {
    const signupToken = await getStorageData('SignupSuccessToken');
    const loginToken = await getStorageData('LoginSuccessToken');
    const sameHeader: { method: "GET", headers: { "Content-Type": string, token: string } } = {
      method: configJSON.getApiMethod,
      headers: {
        "Content-Type": configJSON.apiContentType,
        token: signupToken || loginToken
      }
    }
    const sid = this.props.navigation.getParam("navigationBarTitleText").split('-')[1]
    this.fetchConversationsId = sendAPIRequest(configJSON.fetchSingleConversation+sid, sameHeader);
    }

    handleRadioChange = (e: string) => {
        const selectedValue = e;
        this.setState((prevState) => {
            let updatedRadioValues: string[] = [];

            if (prevState.radioValues.includes(selectedValue)) {
                // If the selected value is already in the radioValues, remove it
                updatedRadioValues = prevState.radioValues.filter(
                    (value) => value !== selectedValue
                );
            } else {
                // Otherwise, add the selected value to the radioValues
                updatedRadioValues = [...prevState.radioValues, selectedValue];
            }

            const selectedFriends = prevState.friendListApi.filter((item) =>
                updatedRadioValues.includes(item.id)
            );

            return {
                radioValues: updatedRadioValues,
                selectedFriends: selectedFriends,
            };
        });
    };

    handleBack = () => {
        this.props.navigation.goBack();
    };

    openCameraModal = () => {
        this.setState({ imgModel: true })
    };

    handleChangeToName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const temp = (this.state.friendListApi).filter(item => ((item.full_name).toLowerCase()).includes((e.target.value).toLowerCase()));
        this.setState({ formValue: e.target.value, filterdFriends: temp, sendError: "" });
    }

    handleChangeGroupName = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ group: { ...this.state.group, name: e.target.value, error: "" } });
    }

    handleChangeMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") return this.setState({ typedMessage: e.target.value, sendError: "", cameraVisible: true })
        this.setState({ typedMessage: e.target.value, sendError: "", cameraVisible: false })
    }

    handleCallCreateGroup = async () => {
        if (!this.state.group.name) return this.setState({ group: { ...this.state.group, error: "enter group name" } });
        if (!this.state.profileImg) return this.setState({ group: { ...this.state.group, error: "select group image" } });
        this.setState({ loading: true })
        const signupToken = await getStorageData('SignupSuccessToken');
        const loginToken = await getStorageData('LoginSuccessToken');
        let body = new FormData();
        body.append("group_name", this.state.group.name);
        body.append("friendly_name", this.state.group.name);
        body.append("group_chat_profile", this.state.profileImg);
        this.state.selectedFriends.forEach(item => {
            const isFollowedCommunity = this.state.followedCommunity.some(community => community.id === item.id);
            
            if (isFollowedCommunity) {
                body.append("ids[community_id][]", item.id.toString());
            } else {
                body.append("ids[account_id][]", item.id.toString());
            }
        });
        this.groupConversationId = sendAPIRequest(configJSON.createGroup, {
            method: configJSON.postApiMethod,
            body: body,
            headers: {
                token: signupToken || loginToken
            }
        });
    }

    openModel = () => {
        if (this.state.selectedFriends.length < 1) return this.setState({ sendError: "select person for chat" })
        if (this.state.imgModelFile) {

            if (this.state.type === "group") return this.handleCallCreateGroup();
        } else {

            if (!this.state.typedMessage) return this.setState({ sendError: "type a message first." })
            if (this.state.type === "group") return this.handleCallCreateGroup();
        }
        this.setState({ model: true });
    };

    handleOpenClosePrivate=()=>{
        this.handleOpenClose("private");
    }

    handleOpenCloseGroup=()=>{
        this.handleOpenClose("group");
    }

    handleOpenClose = async (type: string) => {
        if (type === "group") {
            this.setState({ model: false, type: "group" });
        } else if (type === "private") {
            this.setState({ model: false, type: "private" });
            this.setState({ loading: true })
            const signupToken = await getStorageData('SignupSuccessToken');
            const loginToken = await getStorageData('LoginSuccessToken');
            let body = new FormData();
            
            this.state.selectedFriends.forEach(item => {
                const isFollowedCommunity = this.state.followedCommunity.some(community => community.id === item.id);
                
                if (isFollowedCommunity) {
                    body.append("ids[community_id][]", item.id.toString());
                } else {
                    body.append("ids[account_id][]", item.id.toString());
                }
            });
            this.oneToOneConversationId = sendAPIRequest(configJSON.privateConversation, {
                method: configJSON.postApiMethod,
                body: body,
                headers: {
                    token: signupToken || loginToken
                }
            });
        } else {
            this.setState({ model: false, type: "" })
        }
    };

    uploadProfileImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ group: { ...this.state.group, error: "" } });
        if (e.target.files && e.target.files.length > 0) {
            this.setState({ profileImageSrc: window.URL.createObjectURL(e.target.files[0]), profileImg: e.target.files[0] });
        }
    };

    handleCloseImgModeal = async (file?: File, buffer?: Buffer) => {

        if (!file) return this.setState({ imgModel: false, imgModelFile: "", sendError: "" });
        buffer && this.setState({ imgModel: false, imgModelFile: window.URL.createObjectURL(file), buffer: buffer });
    };

    handlecloseImg = () =>{
        this.setState({ imgModel: false, imgModelFile: "" });
      };

      handleOpenUploadBtn = () => {
        document.getElementById("fileInput")?.click()
    }
  
    handleSubmit = async() => {  
      if (!this.state.imgModelFile) return this.handlecloseImg()
  
      if( this.state.file ){
            const fileBlob = await this.getFileBlob(this.state.file);
            // Convert the Blob object to a Buffer
            const reader = new FileReader()
            reader.readAsArrayBuffer(fileBlob);
  
            reader.onloadend = () => {
              const buffer = Buffer.from(reader.result as ArrayBuffer)
              this.state.file && this.handleCloseImgModeal(this.state.file,buffer);
            };
        }
    }
  
    getFileBlob = (file: File): Promise<Blob> => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => {
            const blob = new Blob([reader.result as ArrayBuffer], { type: file.type });
            resolve(blob)
          };
          reader.onerror = reject;
          reader.readAsArrayBuffer(file)
        });
    };

    removeImage = () => {
        this.setState({ imgModelFile: "", buffer: "" })
    };
    handleSendImage = async (id: string) => {
        const token = localStorage.getItem("chatToken") ?? "";
        const client = new Client(token);
        const channel = await client.getChannelBySid(id)
        //  Send the message with the converted buffer
        channel.sendMessage({
            contentType: "image/jpeg",
            media: this.state.buffer ? this.state.buffer : ""
        });
    };

    handleOnImportFile = (fileData: FileList) => {
        if (fileData.length > 1) return
        let extension = fileData[0].name.split('.').pop() as string
    
        if (!["png", "jpg", "jpeg"].includes(extension)) return
    
        this.setState({file: fileData[0],
            imgModelFile: URL.createObjectURL(fileData[0])
        })
      };

    openAddMemberModal = () => {
        this.setState({ addMemberModal: true })
    }

    closeAddMemberModal = () => {
        this.setState({ addMemberModal: false })
    }

    getModalStyle = () => {
        const top = "110px";
        const left = 50;

        return {
            top: `${top}`,
            left: `${left}%`,
            transform: `translateX(-${left}%)`,
        };
    }

    handleChangeAddMemberName = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ formValue: e.target.value })
        const { existingMember, friendListApi } = this.state;

        const filteredList = friendListApi.filter(
          item => !existingMember.includes(item.id) && item.full_name.includes(e.target.value)
        );       
        this.setState({ filterdFriends: filteredList })
    }

    addMemberInGroup = async () => {
        if (this.state.selectedFriends.length < 1) {
            return toast.warning("Select user first!")
        }
        const signupToken = await getStorageData('SignupSuccessToken');
        const loginToken = await getStorageData('LoginSuccessToken');
        let body = new FormData();
        body.append("conversation_sid", this.props.navigation.getParam("navigationBarTitleText").split('-')[1])
        this.state.selectedFriends.forEach(item => body.append("ids[account_id][]", (item.id).toString()))

        this.addMemberId = sendAPIRequest(configJSON.addMemberEnd, {
            method: configJSON.postApiMethod,
            body: body,
            headers: {
                token: signupToken || loginToken
            }
        });
        this.setState({ loading: true })
    }
    // Customizable Area End
}
