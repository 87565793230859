// Customizable Area Start
import React from "react";
import { Box, Button, FormControl, InputAdornment, FormHelperText } from '@material-ui/core';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import CreatePostLinkModalController, { Props } from "./CreatePostLinkModalController.web";
import { CameraIcon, blueLink, editcopy } from "./assets";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

// Customizable Area Start
const Poststyles = (theme: Theme) =>
    createStyles({
        inputHide: {
            display: "none"
        },
        heightResponsive: {
            maxHeight: "85%",
            overflowY: "auto",
        },
        modal: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        Publishbtn: {
            height: "56px",
            fontFamily: "Verdana",
            fontSize: "17px",
            fontWeight: "bold",
            color: "#fff",
            backgroundColor: "#005487",
            width: "100%",
            textTransform: "none",
            "&:hover": {
                backgroundColor: "#005487"
            }
        },
        inputField: {
            "& .MuiOutlinedInput-input": {
                paddingBlock: "20px",
            }
        },
        inputFieldTextArea: {
            "& .MuiInputBase-root": {
                alignItems: "start",
            },
            "& .MuiInputAdornment-root": {
                marginTop: "10px"
            }
        },
        label: {
            marginBottom: "8px",
            color: "#005487",
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: "Verdana",
        },
        error: {
            color: 'red',
            fontSize: "12px",
            fontFamily: "Verdana",
        },
        thumbnail: {
            width: "100%",
            height: "100%",
            borderRadius: "12px"
        },
        pointer: {
            cursor: "pointer"
        }
    })
// Customizable Area End

export class CreatePostLinkModal extends CreatePostLinkModalController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <Modal
                aria-labelledby="transition-modal-title"
                className={this.props.classes.modal}
                aria-describedby="transition-modal-description"
                onClose={this.props.handleClose}
                open={this.props.linkOpen}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 200,
                }}
            >
                <Fade in={this.props.linkOpen}>
                        <Box sx={{ width: { xs: "80%", md: "70%", lg: "50%" }, maxWidth: "604px" }} bgcolor={"#fff"} borderRadius="12px" className={this.props.classes.heightResponsive}>
                    {this.state.isLoading && <Loader loading={this.state.isLoading}/>}
                            <Box sx={{ border: "1px solid black", margin: "20px", padding: "20px", borderRadius: "12px", gridGap: { xs: "15px", md: "20px" } }} display={"flex"} flexDirection={"column"} >

                                <Box component={"h4"} m={0} sx={{ fontFamily: "Verdana", fontSize: "17px", fontWeight: 'bold' }}>
                                    Create a Post
                                </Box>

                                <FormControl >
                                    <Box component={"label"} className={this.props.classes.label}>URL</Box>
                                    <TextField
                                        placeholder="Add or paste URL here"
                                        name="url"
                                        className={this.props.classes.inputField}
                                        type="url"
                                        data-test-id="url-field"
                                        value={this.state.data.url}
                                        onChange={this.handleChangeUrl}
                                        fullWidth
                                        variant="outlined"
                                        error={Boolean(this.state.error.url)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start" >
                                                    <img src={blueLink} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {this.state.error.url && (
                                        <FormHelperText className={this.props.classes.error}>
                                            {this.state.error.url}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl >
                                    <Box component={"label"} className={this.props.classes.label}>Thumbnail</Box>

                                    <Box component="div" className={this.props.classes.pointer} display="flex" data-test-id="drop-id" flexDirection="column" alignItems="center" justifyContent="center" borderRadius={"12px"} bgcolor={"#f4f4f4"} sx={{ height: "160px" }} gridGap={"7px"}
                                        onDrop={(e) => {
                                            this.handleOnImportFile(e.dataTransfer.files)
                                            e.preventDefault();
                                        }}
                                        onClick={this.handleOpenUploadBtn}
                                        onDragOver={e => e.preventDefault()}>
                                        <input type='file' data-test-id="fileInput" id="fileInput" className={this.props.classes.inputHide}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleOnImportFile(e.target.files!)
                                                e.preventDefault();
                                            }}
                                            accept="image/png, image/jpeg, image/jpg,"
                                        />
                                        {this.state.data.thumbnail ?
                                            <img src={this.state.data.thumbnail} className={this.props.classes.thumbnail} alt="thhumbnail" />
                                            : <>
                                                <img src={CameraIcon} alt="camera" />
                                                <Box component={"p"} fontFamily={"Verdana"} fontWeight={"bold"} color="#808080" fontSize={"17px"} m={0} textAlign={"center"}>Upload Thumbnail Image</Box>
                                                <Box component={"p"} fontFamily={"Verdana"} fontWeight={"normal"} color="#808080" fontSize={"17px"} m={0} textAlign={"center"}>Upload cover image minimum width <br />400 pixel (16:9).</Box>
                                            </>}
                                    </Box>
                                    {this.state.error.thumbnail && (
                                        <FormHelperText className={this.props.classes.error}>
                                            {this.state.error.thumbnail}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl>
                                    <Box component={"label"} className={this.props.classes.label}>Description</Box>
                                    <TextField
                                        data-test-id="description"
                                        placeholder="Add post description here"
                                        type="text"
                                        minRows={4}
                                        error={Boolean(this.state.error.description)}
                                        name="description"
                                        variant="outlined"
                                        value={this.state.data.description}
                                        onChange={this.handleChangeUrl}
                                        multiline
                                        fullWidth
                                        className={this.props.classes.inputFieldTextArea}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment className={this.props.classes.inputAdornment} position="start">
                                                    <img src={editcopy} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {this.state.error.description && (
                                        <FormHelperText className={this.props.classes.error}>
                                            {this.state.error.description}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <Box width="100%" display={"flex"} justifyContent={"center"}>
                                    <Button data-test-id="publish-btn" variant="contained" type="submit" onClick={this.handlePublish} className={this.props.classes.Publishbtn}>Publish</Button>
                                </Box>
                            </Box>
                        </Box>
                </Fade>
            </Modal>
            // Customizable Area End
        )
    }
}

// Customizable Area Start

export default withStyles(Poststyles, { withTheme: true })(CreatePostLinkModal);
// Customizable Area End