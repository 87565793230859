export const imgBell = require("../assets/bell.png");
export const note = require("../assets/note.svg");
export const eventReminder = require("../assets/eventReminder.svg");
export const calender = require("../assets/calender.svg");
export const message = require("../assets/message.svg");
export const people = require("../assets/people.svg");
export const medal = require("../assets/medal.svg");
export const user = require("../assets/user.svg");
export const more = require("../assets/more.svg")

