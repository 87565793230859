import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import { toast } from "react-toastify";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { handleErrorArray } from "../../../components/src/common";
const validPasswordRegex = RegExp(
  /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}\[\]|\\:;"'<,>.?/~`])(?!.*\s).{8,}$/
);
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  emailToken:string;
  errors: {
    pass: string;
    pass_confirmation: string;
  };
  data: {
    pass: string;
    pass_confirmation: string;
  };
  loading: boolean;
  icon:{
    first: boolean;
    second: boolean
  };
  // Customizable Area End
}

interface SS {
    // Customizable Area Start
  id: any;

  // Customizable Area End
}

export default class ResetPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  createAccountApiPasswordId: string = '';
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.state = {
      emailToken:'',
      errors: {
        pass: '',
        pass_confirmation: '',
      },
      loading: false,
      icon:{
        first: false,
        second: false
      },
      data: {
        pass: '',
        pass_confirmation: '',
      },
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const params: URLSearchParams = (new URL(document.location.href)).searchParams;
    let token: string | null = params.get("token");
    if (token) {
      return this.setState({ emailToken: token });
    }

    const forgetToken = await getStorageData("forgetToken");
    if (forgetToken) {
      this.setState({
        emailToken: forgetToken,
      });
    }
  };

  handleFieldChange=(e: React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({data:{...this.state.data,[e.target.name]:e.target.value}})
    if(e.target.name === 'pass'){
      const same = this.state.data.pass_confirmation === e.target.value ? '' : this.state.errors.pass_confirmation;
      const valid  = !validPasswordRegex.test(e.target.value) ? 'Password should contain minimum 8 characters, 1 uppercase, 1 number, 1 special character' : ''
      this.setState({errors:{...this.state.errors,pass:valid,pass_confirmation: same}})
    }
    if(e.target.name === 'pass_confirmation'){
      const same = this.state.data.pass !== e.target.value ? "Passwords did not match": '';
      this.setState({errors:{...this.state.errors,pass_confirmation:same}})
    }
  }

  eyeChangeConfPass=()=>{
    this.setState({icon:{...this.state.icon,second:!this.state.icon.second}})
  }
  eyeIconPass=()=>{
    this.setState({icon:{...this.state.icon,first:!this.state.icon.first}})
  }
  checkRequiredfield = () => {
    let errors = {
      agreeError: '',
      email: '',
      pass: '',
      pass_confirmation: '',
    };
    const passArray = ["P", "a", "s", "s", "w", "o", "r", "d"];

    const { pass, pass_confirmation } = this.state.data
 
    if (!pass && !validPasswordRegex.test(pass)) {
        errors.pass = configJSON.please_enter_valid_pass + passArray.map(char => char).join('');
    }

    if(pass !== pass_confirmation){
      errors.pass_confirmation = passArray.map(char => char).join('') + configJSON.pass_did_not_match;
    }

    if (!pass_confirmation) {
        errors.pass_confirmation =configJSON.please_enter_confirm_pass + passArray.map(char => char).join('');
    }

    const hasErrors = Object.values(errors).some(value => value !== '');
    this.setState({errors: errors});
    return hasErrors
  }

  handleLogin = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationLogInWebMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  async receive(_from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(apiRequestCallId === this.createAccountApiPasswordId){
        this.setState({loading:false})
        if(responseJson.errors){
          handleErrorArray(responseJson.errors)
        }
        if(responseJson.length> 0 ){
          toast.success(responseJson[0].data.message)
          removeStorageData('forgetToken')
          this.handleLogin()
        }
      }
  }
 

  handlePasswordSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const hasErrors = this.checkRequiredfield();
    if (hasErrors) return
    this.setState({ loading: true });
      const header = {
        "Content-Type": configJSON.forgotPasswordAPiContentType,
      };
      const data = {
        data: {
          token:this.state.emailToken,
          new_password: this.state.data.pass,
          password_confirmation: this.state.data.pass_confirmation,
        },
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createAccountApiPasswordId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.changePass
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
