// Customizable Area Start
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { handleErrorArray } from "../../../components/src/common";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes: any;
    finalPost: boolean;
    postId: string;
    handleClose: ()=>void;
    getItem: (id:string, type: string) => void;
    data: {
        description: string;
        images_and_videos: string[];
    },
    selectedFile: File | null;
    restrict?: boolean;
    info: {
        name: string;
        is_private: boolean;
        image: string;
        type: string;
      },
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean;
    postType: string;
    data: {
        images_and_videos : string[];
        description: string;
    },
    error: {
        images_and_videos : string;
        description: string;
    },
    selectedFile: File | null;
    postId: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
export default class CreatePostFinalModalController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    createPostApiId: string = '';
    singlePostApi: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isLoading: false,
            postType: "",
            data: {
                images_and_videos : [],
                description: ''
            },
            error: {
                images_and_videos : '',
                description: ''
            },
            selectedFile: null,
            postId: ''
            // Customizable Area End

        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount(){
        super.componentDidMount();
        if(this.props.restrict){
          return  this.setState({
                data: this.props.data,
                selectedFile: this.props.selectedFile,
                postId: this.props.postId
            })
        }
        if(this.props.postId){
            this.setState({postId: this.props.postId})
            return this.singlePost(this.props.postId);
        }
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson) {
                this.setState({ isLoading: false });
                if (apiRequestCallId === this.createPostApiId) {
                    if (responseJson.data) {
                       this.handleCreateEditResponse(responseJson);
                    } else {
                        handleErrorArray(responseJson.errors)
                    }
                } if (apiRequestCallId === this.singlePostApi) {
                    this.handleResponseSinglePost(responseJson);
                }
            }
        }
    }
      
    handleCreateEditResponse = (responseJson: { data: { id: string }, meta: { message: string } }) => {
        toast.success(responseJson.meta.message)
        const isNew = !this.state.postId ? 'new' : 'edit';
        this.props.getItem(responseJson.data.id, isNew)
        this.props.handleClose()
    }

    handleResponseSinglePost = (responseJson: {
        data: {
            attributes: {
                images_and_videos : string[];
                description: string;
            }
        }
    }) => {
        if (responseJson.data) {
            this.setState({
                data: {
                    ...responseJson.data.attributes,
                }
            });
        }
    }

    handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({data:{...this.state.data,description:e.target.value},  error: { description: '', images_and_videos: '' }})
    }

    handleOnImportFile = (list: FileList) => {
        if (list.length > 1) return this.setState({ error: { ...this.state.error, images_and_videos: 'Select only one image' } })
        let extension = list[0].name.split('.').pop() as string

        if (!["png", "jpg", "jpeg"].includes(extension)) return

        this.setState({
            selectedFile: list[0],
            data:{...this.state.data,images_and_videos: [URL.createObjectURL(list[0])]}
        })
    }

    handleOpenUploadBtn = () => {
        document.getElementById("fileInput")?.click()
    }

    handlePublish = async () => {
        if(!this.state.data.description && this.state.data.images_and_videos.length === 0){
            return this.setState({error: {...this.state.error, description: 'Enter description atleast for post'}})
        }
        this.setState({ isLoading: true });
        let signupToken = await getStorageData("SignupSuccessToken");
        let loginToken = await getStorageData("LoginSuccessToken");
        const header = {
            token: signupToken || loginToken,
        };
        const formData = new FormData();
        this.state.selectedFile && formData.append("post[images][]", this.state.selectedFile);
        formData.append("post[description]", this.state.data.description);
        formData.append("post[post_type]", this.state.data.images_and_videos.length === 0 ? 'text' : 'image');

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        this.createPostApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.state.postId ? configJSON.editPost + this.state.postId : configJSON.postsEnd
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            this.state.postId ? configJSON.putApiMethode : configJSON.PostAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    singlePost = async (id: string) => {
        this.setState({ isLoading: true })
        const loginToken2 = await getStorageData('LoginSuccessToken');
        const signupToken1 = await getStorageData('SignupSuccessToken');
        const header = {
            token: signupToken1 || loginToken2
        };
        const getAlonePost = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );


        getAlonePost.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        getAlonePost.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.get
        );
        getAlonePost.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postsEnd + id
        );

        this.singlePostApi = getAlonePost.messageId;


        runEngine.sendMessage(getAlonePost.id, getAlonePost);
    }
    // Customizable Area End
}