import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { createRef } from 'react';

// Customizable Area Start
import { AnswerSummitI, QuestionAnswerAttributesI, AnswerI, Question, ApiCallInterface } from '../IAssessment'
import { Message } from "../../../../framework/src/Message";
import { FlatList,   } from "react-native";
import StorageProvider from "../../../../framework/src/StorageProvider";
import { getStorageData } from "../../../../framework/src/Utilities";
import { toast } from "react-toastify";
// Customizable Area End

const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}


interface S {
  // Customizable Area Start
  questionsList?: Question[];
  currentQuestionIndex: number;
  answerQuestions: AnswerSummitI | {};
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isAssessmentSubmitted: boolean;
  answerList: QuestionAnswerAttributesI[];
  letters: string[];
  seconds: number;
  descriptiveValue: string | undefined;
  test_type: boolean; //for annonymus test
  options: { label: string, value: string, smallLabel: string }[];
  questions: { question: string, questionId: number, answer: number }[];
  error:string;
  webLoader: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  
  // Customizable Area End
}



export default class AssessmentQuestionsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  private readonly errorTitle = "Error";
  flatListRef = createRef<FlatList<Question>>();
  interval: number | undefined;
  apiGetAssessmentQuestions: string = "";
  apiSubmitAssessmentQuestions: string = "";
  apiSubmittedAssessmentTest: string = "";
  getBarcQuestionApi: string = "";
  getBarcAnswerApi: string = "";
  // Customizable Area End    




  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


    this.state = {
      questionsList: [],
      currentQuestionIndex: 0,
      isError: false,
      isLoading: true,
      isSuccess: false,
      answerList: [],
      answerQuestions: {},
      seconds: 0,
      isAssessmentSubmitted: false,
      letters: 'abcdefghijklmnopqrstuvwxyz'.split(''),
      descriptiveValue:'',
      test_type: false,
      webLoader: false,
      options: [
        { label: "Strongly Disagree", value: "1", smallLabel: "SD" },
        { label: "Disagree", value: "2", smallLabel: "D" },
        { label: "Somewhat Disagree", value: "3", smallLabel: "SWD" },
        { label: "Somewhat Agree", value: "4" , smallLabel: "SWA"},
        { label: "Agree", value: "5" , smallLabel: "A"},
        { label: "Strongly Agree", value: "6", smallLabel: "SA" }
      ],
      questions: [],
      error:"",
    };

     // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.setState({webLoader:false});
      if (apiRequestCallId === this.getBarcQuestionApi && responseJson) return this.getBarcApiResponse(responseJson)
      if (apiRequestCallId === this.getBarcAnswerApi && responseJson) return this.barcAnswerApiResponse(responseJson)
      this.handleApiStatement(apiRequestCallId, responseJson, errorReponse)
    }
    // Customizable Area End
  }


   // Customizable Area Start

  async componentDidMount() {
    this.getAsssessmentQuestionAPI()
    this.getBarcQuestionAPI()
  }

  async componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>,) {
    if (this.state.seconds !== prevState.seconds) {
      if (this.state.seconds === 0) return this.submitAsssessmentQuestionAPI()
      
    }
  }
  
handleSelectedAnswer = (type: { answer?: AnswerI, index?: number, questionId?: string | number, text?: string }): void => {
    
  }

  handleDescriptionValue(){
    const { currentQuestionIndex, questionsList } = this.state;
    const currentQuestion: Question | undefined = questionsList && questionsList[currentQuestionIndex]


    if(currentQuestion?.attributes?.question_type === 'descriptive'){
      if(currentQuestion.description_text === undefined){
        this.setState({
          descriptiveValue:''
        })
      }else {
        this.setState({
          descriptiveValue:currentQuestion.description_text
        })
      }
     
    }

    
  }


  // handle next question
  handleNext() {
    const { questionsList, currentQuestionIndex } = this.state;
    if (questionsList?.length !== currentQuestionIndex + 1) {
      this.setState({
        currentQuestionIndex: currentQuestionIndex + 1
      },
      () => {
        this.handleDescriptionValue()
      }
      
      )

    }
   
    
  }

  // handle next question
  handlePrevious() {
    
    const { currentQuestionIndex } = this.state;
    if (currentQuestionIndex !== 0)  {
      this.setState({
        currentQuestionIndex: currentQuestionIndex - 1
      }, ()=> {
        this.handleDescriptionValue()
      })
    }
    
   
  }

  // get answered question
  getAnsweredQuestionConstant() {
    const { currentQuestionIndex, questionsList } = this.state;
    const prevDisable = currentQuestionIndex == 0
    const nextDiable = questionsList?.length === currentQuestionIndex + 1
    const totalQuestions = questionsList?.length

    let list = this.state.questionsList?.filter((objData: Question) => {
      return objData?.SelectedAnswerIndex !== undefined || objData?.description_text !== undefined
    })
    let AnsweredQuestionCount = list?.length
    return {
      prevDisable,
      nextDiable,
      totalQuestions,
      AnsweredQuestionCount
    }
  }

  // API  Respones


  handleApiStatement(apiRequestCallId: string, responseJson:{data:object, errors:object}, errorReponse: object) {
    if (responseJson && responseJson.data) {
      if (apiRequestCallId === this.apiGetAssessmentQuestions) {
        this.getAsssessmentQuestionSuccesscallBack(responseJson.data);
      } else if (apiRequestCallId === this.apiSubmitAssessmentQuestions) {
        this.submitAsssessmentQuestionSuccesscallBack();
      } else if (apiRequestCallId === this.apiSubmittedAssessmentTest) {
        this.submitAssessmentTestApiSuccesscallBack()
      }
    } else if (responseJson && responseJson.errors) {
      if (apiRequestCallId === this.apiGetAssessmentQuestions) {
        this.getAsssessmentQuestionFailureCallBack();
      } else if (apiRequestCallId === this.apiSubmitAssessmentQuestions) {
        this.submitAsssessmentQuestionFailureCallBack();
      } else if (apiRequestCallId === this.apiSubmittedAssessmentTest) {
        this.submitAssessmentTestApiFailureCallBack()
      }
    } else if (errorReponse) {
      this.setState({
        isLoading: false,
      })
    }
  }


  apiCallAssessmentQuestions = async (data: ApiCallInterface) => {
    const { contentType, method, endPoint, body } = data;
    let token = await StorageProvider.get("USER_TOKEN");
    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    // apiCallAssessmentQuestions

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };


  //  get questions for mobile devices



  //  Refetch Functions

  onReFetch() {
    this.setState({
      isLoading: true,
      isError: false,
    }, () => {
      this.getAsssessmentQuestionAPI()
    }

    )
  }

  

  // get assessment question for 

  getAsssessmentQuestionAPI = async () => {
    this.apiGetAssessmentQuestions = await this.apiCallAssessmentQuestions({
      contentType: configJSON.allAssessementTestApiContentType,
      method: configJSON.getAssessementMethod,
      endPoint: `${configJSON.getAllAssessementTestUrl}/${this.getId()}`,
    });
  };

  getAsssessmentQuestionSuccesscallBack = async (data: Question) => {

    if (data.attributes?.is_completed === true) {
      this.setState({
        isLoading: false,
        isError: false,
      }, () => {
        this.props.navigation.navigate('AssessmentResult', {id: data?.id })
      })
    } else {
      this.setState({
        isLoading: false,
        isError: false,
        isSuccess: true,
        questionsList: data.attributes?.questions?.data, 
        seconds: data.attributes?.duration ? data.attributes?.duration * 60 : 0
      },
        () => {
          this.handleDescriptionValue()
          this.startTimer()
        }
      )
    }


  };

  getAsssessmentQuestionFailureCallBack = () => {
    this.setState({
      isLoading: false,
      isError: true,
      isSuccess: false
    })
  };

  // submit api request
  submitAsssessmentQuestionAPI = async () => {
    this.setState({
      isLoading: true,
      isSuccess: false,
    })

    let body = {
      assessment_id: this.getId(),
      question_answer_attributes: this.state.answerList
    }
    this.apiSubmitAssessmentQuestions = await this.apiCallAssessmentQuestions({
      contentType: configJSON.allAssessementTestApiContentType,
      method: configJSON.submitAssessementTestAPiMethod,
      endPoint: configJSON.apiSubmitAssessmentQuestions,
      body,
    });
  };

  getId() {
    return this.props.navigation.getParam('id');
  }


  submitAsssessmentQuestionSuccesscallBack = async () => {
    this.setState({
      isLoading: false,
      isError: false,
      isSuccess: true,

    }, () => {
      this.submitAssessmentApi()
    })
  };

  submitAsssessmentQuestionFailureCallBack = () => {
    this.showAlert('Error', 'something wrong please try again ')
    this.setState({
      isLoading: false,
      isSuccess: true
    })

  };


  submitAssessmentApi = async () => {
    this.apiSubmittedAssessmentTest = await this.apiCallAssessmentQuestions({
      contentType: configJSON.allAssessementTestApiContentType,
      method: configJSON.submitAssessementTestAPiMethod,
      endPoint: `${configJSON.submitAssessementTestUrl}${this.getId()}`,
    });
  };


  submitAssessmentTestApiSuccesscallBack = async () => {
    this.setState({
      isAssessmentSubmitted: true
    })
  };

  viewResult() {
    this.setState({
      isAssessmentSubmitted: false
    }, () => {
      this.props.navigation.navigate('AssessmentResult', { id: this.getId() })
    })
  }

  submitAssessmentTestApiFailureCallBack = async () => {
    this.showAlert('Error', 'something wrong please try again ')
    this.setState({
      isLoading: false,
    })
  };
  startTimer() {
    this.interval = setInterval(() => {
      this.setState(prevState => ({ seconds: prevState.seconds - 1 }));
    }, 1000) as unknown as number
  }


  timerState() {
    const remainingMinutes = Math.floor(this.state.seconds / 60);
    const remainingSeconds = this.state.seconds % 60;
    const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
    return {
      remainingMinutes,
      remainingSeconds,
      formattedSeconds
    }
  }

  getBarcQuestionAPI = async () =>{
    this.setState({webLoader:true});
    const loginToken = await getStorageData('LoginSuccessToken');
    const signupToken = await getStorageData('SignupSuccessToken');
    const headers  = {
      "Content-Type": configJSON.allAssessementTestApiContentType,
      token: signupToken || loginToken
    }
    const requestApiMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestApiMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApi
    );
    requestApiMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.barc_api_end
    );

    requestApiMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    this.getBarcQuestionApi = requestApiMessage.messageId;
    runEngine.sendMessage(requestApiMessage.id, requestApiMessage);
  }

  getBarcApiResponse = (responseJson: { barc_questions: [{ id: number, description: string, barc_assesment_quiz_id: number }] }) => {
    const questions = responseJson.barc_questions.map((item) => {
      return {
        question: item.description, questionId: item.id, answer: NaN
      }
    })
    this.setState({ questions: questions })
  }

  barcAnswerApiResponse = (responseJson: {data: {score: number, total_marks: number}}) => {
    if(responseJson.data){
      toast.success("Test submitted");
      const message = new Message(getName(MessageEnum.NavigationMessage));
       message.addData(getName(MessageEnum.NavigationTargetMessage), "AssessmentDashboard");

      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

      this.send(message);
    }
  }

  handleAnnonymus = () => {
    this.setState({ test_type: !this.state.test_type })
  }


  selectAnswer = (questionId: number, answer: number) => {
    const updatedQuestions = this.state.questions.map((question) => {
      if (question.questionId === questionId) {
        // Update the answer for this question
        return {
          ...question,
          answer: answer, // Set the answer to your desired value
        };
      }
      // Keep other questions unchanged
      return question;
    });
    this.setState({ questions: [...updatedQuestions],error:"" })
  }

  submitAnswers = async () => {
    const err= this.state.questions.filter(item=> {if(!item.answer)return item})
    if(err.length>0){
      return this.setState({error:"All questions are required."})
    }else{
      const answers = this.state.questions.map(item=> {
        return {
          question_id: item.questionId,
          answer: item.answer
        }
      });
      const body = {
        data: {
          attributes: {
              answers_data: answers,
              test_anonymous: this.state.test_type,
          }
        }
      }
      this.setState({webLoader:true});
      const login_token = await getStorageData('LoginSuccessToken');
      const signup_token = await getStorageData('SignupSuccessToken');
      const header  = {
        "Content-Type": configJSON.allAssessementTestApiContentType,
        token: signup_token ?? login_token
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApi
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.barc_api_answer
      );
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      this.getBarcAnswerApi = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  // Customizable Area End
}

