import React from "react";
// Customizable Area Start
import "./CreateCollection.web.css";
import { Box } from "@material-ui/core";
import CreateCollectionController, { Props } from "./CreateCollectionController.web";
import {leftarrow,closeSquare,editIcon} from  "./assets"
// Customizable Area End


export default class CreateCollection extends CreateCollectionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCreateCollectionTop = () => {
    return (
      <div className="createCollectionTop">
        <div className="createCollectionTop-left">
          <img
            src={leftarrow}
            alt=""
            className="createCollectionTop-left-arrow" 
            onClick={()=>this.props.navigation.goBack()}
          />
          <p className="createCollectionTop-left-heading">Create Collection</p>
        </div>
        <div className="createCollectionTop-right" onClick={()=>this.props.navigation.goBack()}>
          <img
            src={closeSquare}
            alt=""
            className="createCollectionTop-right-crossIcon"
          />
        </div>
      </div>
    ); 
  };
  renderCreateCollectionBottom = () => {
    return (
      <div className="createCollectionBottom">
        <div className="createCollectionBottom-searchContainer">
          <label className="createCollectionBottom-searchContainer-label">
            Group Name
          </label>
          <div className="createCollectionBottom-searchContainer-inputContainer">
            <img src={editIcon} alt="" className="edit-icon"/>
            <input
              type="text"
              className="createCollectionBottom-searchContainer-input"
              placeholder="Enter your collection name"
              value={this.state.collectionName}
              onChange={(e)=>this.setState({collectionName:e.target.value})}
            />
          </div>
        </div>
        <div className="createCollectionBottom-btnContainer">
          <button className="createCollectionBottom-btn" onClick={this.submitButton}>Done</button>
        </div>
      </div>
    );
  };
  renderCreateCollectionLayout = () => {
    return (
      <div >
        {this.renderCreateCollectionTop()}
        {this.renderCreateCollectionBottom()}
      </div>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    
    return (
      // Customizable Area Start
      <Box width={"100%"} className="createCollection">
      {this.renderCreateCollectionLayout()}
    </Box>

      // Customizable Area End
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "300px",
      borderRadius: "12px",
    },
  },
};

// Customizable Area End
