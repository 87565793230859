// Customizable Area Start
import React from "react";
import { Box , InputAdornment } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CustomDatepickerController, { Props , locale } from "./CustomDatepickerController.web";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
    createStyles({
        textField: {
            borderRadius: "12px",
            border: "solid 2px #f4f4f4",
            padding: "12px 0px",
            width:"100%"
        },
        errorBorder: {
            border: "1px solid red",
        },
        inputAdornment: {
            paddingLeft: "15px"
        },
        inputAdornmentEnd: {
            paddingRight: "15px"
        },
        paddingRight: {
                "& .MuiIconButton-edgeStart":{
                    marginRight: "15px",
                }
        }
    })

// Customizable Area End

export class CustomDatepicker extends CustomDatepickerController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {

        // Customizable Area Start
        const {classes, disableFutureDate, disablePast ,selectedDate, format , calendarIcon , openCalendarIcon , placeHolderText, minDate } = this.props
        return (
            <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
                <Box>
                    <KeyboardDatePicker
                        className={`${classes.textField} CustomDatepicker ${this.props.error ? classes.errorBorder :'' } ${this.props.className ? classes.paddingRight : ''}`}
                        disableFuture={disableFutureDate}
                        disablePast={disablePast}
                        variant="inline"
                        format={format}                      
                        id="date-picker-inline"
                        minDate={minDate}
                        readOnly
                        value={selectedDate}
                        onChange={this.handleSelectDate}
                        placeholder={placeHolderText}
                        InputProps={{
                            disableUnderline: true, // Remove underline
                            startAdornment: (
                                <InputAdornment className={classes.inputAdornment} position="start">
                                  {calendarIcon}
                                </InputAdornment>
                              ),
                            onClick: this.handleTextFieldClick, 
                          }}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                            edge: 'start',
                            disableFocusRipple:true,
                            disableTouchRipple:true,
                            onClick: this.handleTextFieldClick,
                          }}
                        PopoverProps={{
                            anchorEl:this.state.anchorEl,
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                            },
                           className:"CalendarPopover"
                        }}
                        renderDay={this.renderDay}
                        keyboardIcon={openCalendarIcon}
                        open={this.state.openDialog}
                        onClose={this.handleDialogClose}
                    />
                </Box>
            </MuiPickersUtilsProvider>
        );
        // Customizable Area End
    }

}


// Customizable Area Start

export default withStyles(styles, { withTheme: true })(CustomDatepicker);
// Customizable Area End