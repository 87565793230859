import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { Typography, Box, Grid, Card, MenuItem, Menu, Button} from "@material-ui/core";
// Customizable Area Start
import Divider from "@material-ui/core/Divider";
import FollowersListController, { configJSON, Props } from "./FollowersListController.web";
import  CommonListing  from "./CommonListing.web";
import Loader from "../../../components/src/Loader.web";
import SearchListModal from "./SearchListModal.web";
import styled  from "@material-ui/core/styles/styled";
// Customizable Area End

// Customizable Area Start
export default class FollowersList extends FollowersListController {
    constructor(props: Props) {
        super(props);
    }


    render() {

        return (
            <Wrapper component={"section"} className={"outerMAin"}  >
                <div ref={this.followerListRef} id="Follower_List">
                <Loader loading={this.state.isLoading}/>
                <Card className={"root"}>
                    <Box padding={"25px"}>
                        <Box component={"p"} m={0} color={"#005487"} data-testid="title-id" sx={{fontFamily:"Verdana", fontSize:"20px", fontWeight:"bold"}}>{this.commun === "FOLLOWERS"?configJSON.follower_list:configJSON.community_members}</Box>
                    </Box>
                    <Divider />
                    {this.state.showSearchModal && <SearchListModal
                        showSearchModal={this.state.showSearchModal}
                        friendListRef={this.followerListRef}
                        handleClose={this.closeSearchModal}
                        userId={this.userId} 
                        isCommunity={this.commun === "COMMUNITY"}
                        type={"FOLLOWER"}
                        isOwnProfile={this.isOwnUser}
                        navigation={this.props.navigation}
                      />}
                    <CommonListing resetData={this.getFollowersList} setShowSearch={this.showSearchModal} listType={this.commun} navigation={this.props.navigation} list={this.state.followersList} handleRemoveFollower={this.removeFollower} />
                </Card>
                </div>
            </Wrapper>
        );
    }
}

const Wrapper = styled(Box)({
    position: "relative",
    "& .root": {
        maxWidth: "100%",
        borderRadius: "12px",
    },
    "& .outerMAin": {
        overflow: "unset"
    }
});
// Customizable Area End