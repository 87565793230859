import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Avatar, TextField
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import { Camera, Edit, Email, NoUser, Phone, Website, greyEdit, smallUser } from "./assets";
import UserProfileWallpaperSection  from "../../ActivityFeed/src/UserProfileWallpaperSection.web";
import { LandingScreenNew } from "../../landingpage/src/LandingScreenNew.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import LinkedAccountsController, {
  Props,
  configJSON,
} from "./LinkedAccountsController";

export default class LinkedAccounts extends LinkedAccountsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <PageWrapper>
        <Loader loading={this.state.showLoading} />
        {this.state.creation ?
          <MainWrapper component={"section"} display={"flex"}>
            <Box bgcolor={"#fff"} borderRadius={"12px"} p={"24px"} sx={{ width: { xs: "auto", md: "70%", lg: "60%" }, marginX: { xs: "32px", md: "auto" } }} my={"32px"}>
              <Box component={"h4"} color="#005487" fontFamily={"Verdana"} fontWeight={"bold"} fontSize={"22px"} m={0}>Community Information</Box>
              <Box display={"flex"} alignItems={"center"} m={"20px"} justifyContent={"center"} flexDirection={"column"} >
                <Box display="flex" alignItems="center" justifyContent="center" position={"relative"} width="fit-content" sx={{
                  width: "75px",
                  height: "75px", border: "1px solid #c4c4c4", borderRadius: "12px"
                }} >
                  {!this.state.information.profile_pic ? (<img src={NoUser} alt="no user" className="user-img" />) :
                    <Avatar src={this.state.information.profile_pic} className="avatar-img" />
                  }
                  <Box position={"absolute"} bottom="-5px" right="-5px" bgcolor={"#fff"} width={"16px"} height="15px" borderRadius={"50%"} border={"1px solid #808080"} padding={"4px"}>
                    <img src={Camera} alt="camera" className="camera-icon" />
                    <input
                      accept="image/jpeg, image/jpg, image/png"
                      type="file"
                      data-test-id="input-img-id"
                      className="input-img"
                      onChange={this.uploadProfileImage}
                    />
                    <Box />
                  </Box>
                </Box>
                {this.state.errors.profile_pic && <Box component={"p"} role="error" color="red" fontFamily={"Verdana"} m={0} my={"6px"} fontSize={"14px"}>{this.state.errors.profile_pic}</Box>}
              </Box>
              <Box mb={"20px"}>
                <Box component={"label"} display="block" fontFamily={"Verdana"} mb={"12px"} color="#005487" fontWeight={"bold"} fontSize={"14px"}>Community Name*</Box>
                <TextField
                  data-test-id="full_name"
                  variant="outlined"
                  size="medium"
                  placeholder="Enter your Community Name"
                  name="name"
                  type="text"
                  value={this.state.information.name}
                  onChange={this.handleChangeField}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" >
                        <img src={smallUser} alt="smallUser" />
                      </InputAdornment>
                    ),
                  }}
                />
                {this.state.errors.name && <Box component={"p"} role="error" color="red" fontFamily={"Verdana"} m={0} my={"6px"} fontSize={"14px"}>{this.state.errors.name}</Box>}
              </Box>
              <Box mb={"20px"}>
                <Box component={"label"} display="block" fontFamily={"Verdana"} mb={"12px"} color="#005487" fontWeight={"bold"} fontSize={"14px"}>Community Website*</Box>
                <TextField
                  data-test-id="website"
                  variant="outlined"
                  size="medium"
                  placeholder="Enter Community Website"
                  name="website"
                  type="text"
                  value={this.state.information.website}
                  onChange={this.handleChangeField}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" >
                        <img src={Website} alt="website" />
                      </InputAdornment>
                    ),
                  }}
                />
                {this.state.errors.website && <Box component={"p"} role="error" color="red" fontFamily={"Verdana"} m={0} my={"6px"} fontSize={"14px"}>{this.state.errors.website}</Box>}
              </Box>
              <Box mb={"20px"}>
                <Box component={"label"} display="block" fontFamily={"Verdana"} mb={"12px"} color="#005487" fontWeight={"bold"} fontSize={"14px"}>Community E-mail ID*</Box>
                <TextField
                  data-test-id="email"
                  variant="outlined"
                  size="medium"
                  placeholder="Enter your Community E-mail ID"
                  name="email"
                  type="email"
                  disabled={Boolean(this.state.information.id)}
                  value={this.state.information.email}
                  onChange={this.handleChangeField}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" >
                        <img src={Email} alt="email" />
                      </InputAdornment>
                    ),
                  }}
                />
                {this.state.errors.email && <Box component={"p"} role="error" color="red" fontFamily={"Verdana"} m={0} my={"6px"} fontSize={"14px"}>{this.state.errors.email}</Box>}
              </Box>
              <Box mb={"20px"}>
                <Box component={"label"} display="block" fontFamily={"Verdana"} mb={"12px"} color="#005487" fontWeight={"bold"} fontSize={"14px"}>Phone Number</Box>
                <TextField
                  data-test-id="phone_number"
                  variant="outlined"
                  size="medium"
                  placeholder="Enter your Phone Number"
                  name="phone_number"
                  type="number"
                  value={this.state.information.phone_number}
                  onChange={this.handleChangeField}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" >
                        <img src={Phone} alt="Phone" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box mb={"20px"}>
                <Box component={"label"} display="block" fontFamily={"Verdana"} mb={"12px"} color="#005487" fontWeight={"bold"} fontSize={"14px"}>Community Description</Box>
                <TextField
                  data-test-id="description"
                  variant="outlined"
                  size="medium"
                  placeholder="Add Community Description here"
                  name="description"
                  type="text"
                  value={this.state.information.description}
                  onChange={this.handleChangeField}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" >
                        <img src={Edit} alt="edit" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box sx={{ width: { xs: "80%", md: "65%", lg: "60%" } }} margin={"auto"}>
                <Button className="done-btn" data-testid="done-btn" type="submit" onClick={this.updateUserDetails}>Done</Button>
              </Box>
            </Box>
          </MainWrapper>
          :
          <>
            <UserProfileWallpaperSection id={"wallpaper"} data={{
              id: this.state.information.id,
              attributes: {
                friend_list_count: 0,
                follower_count: this.state.information.follower_count,
                background_image: this.state.information.background_profile_pic,
                profile_picture: this.state.information.profile_pic,
                full_name: this.state.information.name,
                email: this.state.information.email,
                is_friend_request_sent: false,
                is_already_request_available: false,
                is_in_connection: false,
                is_followed_by_login_user: this.state.information.is_followed_by_user,
                city: "",
                state: "",
                job_title: "",
                description: "Community Information"
              }
            }} hideBorder={true} is_private={false} navigation={this.props.navigation} isCommunity={true} isOwnProfile={true} />
            <Box component={"section"} p={"24px"} borderRadius={"12px"} bgcolor={"#fff"} mb="16px">
              <Box display={"flex"} mb={"11px"} justifyContent={"space-between"}                                                                                      >
                <Box component={"h3"} m={0} fontFamily={"Verdana"} fontSize={"22px"} color={"#005487"} fontWeight={"bold"}>
                  About us
                </Box>
                <img src={greyEdit} className="pointer" alt="greyEdit" onClick={this.editRedirection} />
              </Box>
              <Box component={"p"} fontFamily={"Verdana"} fontSize={"16px"} m={0} data-test-id="description">
                {this.state.information.description}
              </Box>
            </Box>
         
            {this.state.information.id && <Box component={"section"}>
              <LandingScreenNew navigation={this.props.navigation}
                id={"landing-list"}
                filter={true}
                resetData={this.props.resetData}
                classes={""} />
            </Box>}
          </>}
      </PageWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
  "& .pointer": {
    cursor: "pointer"
  },
  "& .done-btn": {
    backgroundColor: "#005487",
    width:"100%",
    fontSize:"16px",
    fontWeight:"bold",
    color: "#fff",
    textTransform: "none",
    height:"56px",
    fontFamily: "Verdana"
  },
  "& .input-img": {
    right: "0px",
    position: "absolute",
    bottom: "0px",
    height: "70px",
    cursor: "pointer",
    width: "70px",
    opacity: 0
  },
  "& .user-img": {
    width: "80%",
    height: "80%"
  },
  "& .camera-icon": {
    height: "100%",
    width: "100%",
  },
  "& .avatar-img": {
    width: "75px",
    objectFit: "contain",
    height: "75px",
    borderRadius: "12px"
  }
})

const PageWrapper = styled(Box)({
  "& .pointer":{
    cursor: "pointer"
  }
})
// Customizable Area End
