import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { handleErrorArray } from "../../../components/src/common";
import { toast } from "react-toastify";

// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;

    classes?: any;
    showSearchModal: boolean;
    handleClose: () =>  void;
    friendListRef: any;
    userId: string;
    isCommunity: boolean;
    type: "FOLLOWER" | "FRIEND";
    isOwnProfile: boolean;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    showAllEnabled: boolean;
    searchTerm: string;
    showRecentSearch: boolean;
    searchList: {name: string, id: string, profilePicture: string}[];
    recentSearchList: {name: string, id: string, profilePicture: string,searchableId:string}[];
    isLoading: boolean;
    menuItem: string|number;
    anchorEl: null | HTMLElement;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SearchListModalController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    searchFriendListApiCallId: string = "";
    getRecentSearchApiCallId: string = "";
    removeRecentSearchApiCallId: string = "";
    addRecentSearchApiCallId: string = "";
    removeFriendFollowerId: string = "";
    userId: string ="";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);


        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),

            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ]
        
        this.state = {
            showAllEnabled: false,
            searchTerm: "",
            showRecentSearch: true,
            searchList: [],
            recentSearchList: [],
            isLoading: false,
            menuItem: '',
            anchorEl: null,
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
        // Customizable Area End
    }

    async receive(_from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        this.setState({isLoading: false})
        
        if (apiRequestCallId === this.searchFriendListApiCallId) {
            if (responseJson?.data) {
                this.setState({
                    searchList: responseJson.data.map((data: { id: string, attributes: { profile_picture: string, full_name: string } }) => ({
                        id: data.id,
                        name: data.attributes.full_name,
                        profilePicture: data.attributes.profile_picture,
                    }))
                })
            } else {
                this.setState({
                    searchList: []
                })
            }
        }
        else if (apiRequestCallId === this.getRecentSearchApiCallId) {
            if (responseJson?.data) {
                this.setState({
                    recentSearchList: responseJson.data.map((data: { id: string, attributes: { searchable_id: string, account_or_community: { data: { attributes: { profile_picture: string, full_name: string } } } } }) => ({
                        id: data.attributes.searchable_id,
                        searchableId: data.id,
                        name: data.attributes.account_or_community.data.attributes.full_name,
                        profilePicture: data.attributes.account_or_community.data.attributes.profile_picture
                    })
                    )
                })
            }else{
                this.setState({recentSearchList:[]})
            }
        }
        else if (apiRequestCallId === this.removeRecentSearchApiCallId) {
            if (responseJson?.message) {
                toast.success(responseJson.message);
                this.getRecentSearchList();
            } else if (responseJson?.errors) {
                handleErrorArray(responseJson.errors)
            }
        }else if(apiRequestCallId === this.removeFriendFollowerId){
            this.responseRemovedFriendFollower(responseJson)
        }
        // Customizable Area End
    }


    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.userId = this.props.userId
        this.getRecentSearchList();

    }

    handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchText = event.target.value;
        this.setState({
            searchTerm: searchText,
            showRecentSearch: searchText.length === 0
        })
        if(searchText.length > 0){
            this.serchTextList(searchText)
        }
    }

    searchList = (): { name: string; id: string; profilePicture: string; searchableId?: string }[] => {
        if (this.state.showRecentSearch) {
            return this.state.recentSearchList;
        }
        return this.state.searchList;
    };

    handleShowAll = () => {
        this.setState({
            showAllEnabled: true
        })
    }
    getModalStyle = () =>  {
        const top = "110px";
        const left = 50;
      
        return {
          top: `${top}`,
          left: `${left}%`,
          transform: `translateX(-${left}%)`,
        };
    }

    handleCloseModal = () => {
        this.setState({
            showAllEnabled: false,
            searchTerm: "",
            showRecentSearch: true
        })
        this.props.handleClose();
    }

    getSignupLoginTokens = async () => {
        let signupToken = await getStorageData('SignupSuccessToken');
        let loginToken = await getStorageData('LoginSuccessToken');

        return { signupToken, loginToken };
    }

    serchTextList = async (searchTerm: string) => {
        const { signupToken, loginToken } = await this.getSignupLoginTokens();
        this.setState({isLoading: true})
        const header = {
            token: signupToken || loginToken,
            "Content-Type": configJSON.apiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.searchFriendListApiCallId = requestMessage.messageId;
        const communityParam = this.props.userId ? configJSON.getFollowersListApiEndpoint + `?type=AccountBlock::Community&followed_id=${this.userId}&search=${searchTerm}` : configJSON.getFollowersListApiEndpoint + `?type=AccountBlock::Community&search=${searchTerm}`
        const followerParam = configJSON.getFollowersListApiEndpoint + `?type=AccountBlock::Account&followed_id=${this.userId}&search=${searchTerm}`
        const friendParam = configJSON.searchFriendListApiEndpoint + `?account_id=${this.userId}&search=${searchTerm}`
        const friendOrFollower = this.props.type === "FRIEND" ? friendParam : followerParam
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.props.isCommunity ? communityParam : friendOrFollower
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getFriendsListApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getRecentSearchList = async () => {
        this.setState({isLoading: true})
        const { signupToken, loginToken } = await this.getSignupLoginTokens();

        const header = {
            token: signupToken || loginToken,
            "Content-Type": configJSON.apiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getRecentSearchApiCallId = requestMessage.messageId;
        
        const param = this.props.type==="FRIEND"?"friend":"follower"
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getRecentSearchListApiEndpoint + param
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getRecentSearchListApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleSearchItemClick =async (searchId: string) => {
        this.setState({isLoading: true})

        const { signupToken, loginToken } = await this.getSignupLoginTokens();
        const header = {
            token: signupToken || loginToken,
        };

        const formData = new FormData();
        formData.append("search_type", this.props.type==="FRIEND" ? "friend" : "follower");
        formData.append("searchable_id", searchId);
        formData.append("searchable_type", "AccountBlock::Account");
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.addRecentSearchApiCallId = requestMessage.messageId;
        
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addRecentSearchApiEndpoint  
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.addRecentSearchApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        this.props.navigation.navigate("OtherUserProfile", {id: searchId})
    }

    removeRecentSearch = async(id: string) => {
        this.setState({isLoading: true})
        const { signupToken, loginToken } = await this.getSignupLoginTokens();
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: signupToken || loginToken,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.removeRecentSearchApiCallId = requestMessage.messageId;
        
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.removeRecentSearchApiEndpoint + `/${id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.removeRecentSearchApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    removeFriendFollower = async () => {
        this.setState({isLoading:true});
        const { signupToken, loginToken } = await this.getSignupLoginTokens();
        const header = {
            token: signupToken || loginToken,
            "Content-Type": configJSON.apiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.removeFriendFollowerId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.props.type === "FOLLOWER" ? configJSON.removeFollowerApiEndpoint + `?account_id=${this.state.menuItem}` : configJSON.removeFriendApiEndpoint + `?account_id=${this.state.menuItem}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.removeFollowerApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        this.handleCloseEnchor()
    }

    responseRemovedFriendFollower = (responseJson:{data:{message:string}}) => {
        this.setState({isLoading:false})
        if(responseJson.data?.message){
            this.serchTextList(this.state.searchTerm)
            toast.success(responseJson.data.message)
        }
    }

    handleMenuOpen = (e:React.MouseEvent<HTMLElement>,id:string|number) => {
        this.setState({menuItem:id,anchorEl:e.currentTarget})
    }

    handleCloseEnchor = () => {
        this.setState({
            anchorEl: null,
            menuItem: ''
        });
    }
    // Customizable Area End

}
