import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import { toast } from "react-toastify"
import { getSignupLoginTokens } from "../../../components/src/common";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
export interface IAchievenment{
    id:string,
      type:string,
      attributes:{
        title: string | null;
        issue_month: number | null;
        organisation_name: string | null;
        issue_year: number | null;
        expiration_year: number | null;
        expiration_month: number | null;
        ceu_gained: string | null;
        credential_url: string | null;
        description: string | null;
        is_bulk_upload: boolean;
        account_id: number;
        full_name: string;
        community_id: string | null;
        profile_picture: string;
        achievement_files: {
          data: {
            type: string;
            id: string;
            attributes: {
              title: string;
              description: string;
              achievement_images: {
                  url: string;
                  file_exetension: string;
                  file_name: string,
              }[];
            };
          }[];
        };
        custom_form_file_extension: string | null;
        custom_form_file: string | null;
        custom_form_file_name: string | null;
    }
}
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes?: any;
    // Customizable Area End
}

// Customizable Area End
interface S {
    // Customizable Area Start
    isLoading: boolean;
    achievementList: IAchievenment[]
    // Customizable Area End
}



interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
// Customizable Area Start
export default class AchievementListingController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    signupToken: string = "";
    loginToken: string = "";
    getAchivenmentListId: string = "";
    removeAchivenmentId: string = ""
    // Customizable Area End
    constructor(props: Props) {
        
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            // Customizable Area End
        ]
        // Customizable Area Start
        
        this.state = {
            isLoading: false,
            achievementList: []
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
        // Customizable Area End
}
 
       
    async receive(_from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        

         let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        
        this.setState({
            isLoading: false
        })

        switch(apiRequestCallId){
            case this.getAchivenmentListId:
                this.handlegetAchivenmentListResponse(responseJson);
                break;
            case this.removeAchivenmentId:
                this.deleteAchivenmentResponse(responseJson)
                break;
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        const { signupToken, loginToken } = await  getSignupLoginTokens();

        this.signupToken = signupToken;
        this.loginToken = loginToken;
        
        this.getAchivenmentList();
    }

    
    getAchivenmentList = () => {
        this.setState({isLoading: true})
        const header = {
            token: this.signupToken || this.loginToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        
        //adding payload for endpoint
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAchivenmentListEnd
        );

        //adding payload for api header
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        //adding payload for api method
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getUserProfileDataApiMethod
        );
        this.getAchivenmentListId = requestMessage.messageId

        // send to run engine
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    handleRemoveAchievement = (param: string) => {
        
        this.setState({isLoading:true})
        const header = {
            token: this.signupToken || this.loginToken , 
            "Content-Type": configJSON.apiContentType,
        };

        // create new message 
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.removeAchivenmentId = requestMessage.messageId;

        // adding payload for endpoint
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.removeAchievementApiEndpoint + param
        );

        // adding payload for header
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

         // adding payload for api method
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.removeAchievementApiMethod
        );

        // send message to run engine 
        runEngine.sendMessage(requestMessage.id, requestMessage);        
    }
    
   handlegetAchivenmentListResponse = (responseJson:{data:IAchievenment[]}) => {
    if(responseJson&& responseJson.data){
        return this.setState({
            achievementList : responseJson.data
        })
    }else{
        this.setState({achievementList: []})
    }
   }

   deleteAchivenmentResponse = (responseJson : {data:{message:string}[]})=>{
    if(responseJson && responseJson.data){
        toast.success(responseJson.data[0].message);
        this.getAchivenmentList();
    }
   }


    // Customizable Area End

}
// Customizable Area End