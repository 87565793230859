// Customizable Area Start
import React from "react";
import { Box, Typography } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CommonPreviewModalController, {
  Props
} from "./CommonPreviewModalController";
import { CloseIcon } from "./assets";
// Customizable Area End

export class CommonPreviewModal extends CommonPreviewModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    return (
      <Modal
        className={"VideoPostModal VideoStepTwoModal"}
        open={this.props.open}
        onClose={this.props.onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200
        }}
      >
        <Fade in={this.props.open}>
          <Box
            style={{ height: "30rem", padding: "30px" }}
            className="VideoStepTwoModalBox"
          >
            <Box style={{ ...webStyle.centerBox, ...webStyle.fullscreen }}>
              {this.isPdfFile() && (
                <iframe
                  style={webStyle.fullscreen}
                  src={`${this.props.previewData.url}&page=hsn#toolbar=0`}
                />
              )}
              {this.isImageFile() && (
                <img
                  style={webStyle.fullscreen}
                  src={String(this.props.previewData.url)}
                  alt="..."
                />
              )}
              {this.isNotSupportedFile() && (
                <Typography>This file type is not supported.</Typography>
              )}
              {this.isDocFile() && (
                <iframe
                  sandbox="allow-scripts allow-same-origin"
                  style={webStyle.fullscreen}
                  src={`https://docs.google.com/viewer?url=${
                    this.props.previewData.url
                  }&embedded=true&controls=0`}
                />
              )}
              <img
                src={CloseIcon}
                onClick={this.props.onClose}
                style={webStyle.closeIcon}
              />
            </Box>
          </Box>
        </Fade>
      </Modal>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

const webStyle: any = {
  closeIcon: {
    position: "absolute",
    top: "3px",
    right: "3px",
    padding: "3px",
    cursor: "pointer"
  },
  fullscreen: {
    width: "100%",
    height: "100%"
  },
  centerBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
};
export default CommonPreviewModal;
// Customizable Area End
