import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  testList: any;
  isLoading: boolean;
  isModalOpen:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AssessmentTestListingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllAssessmentTestsUserApiCallId: string = "";
  fullFormsList: string[] = [
    "SD - Strongly Disagree",
    "D - Disagree",
    "SWD - Somewhat Disagree",
    "SWA - Somewhat Agree",
    "A - Agree",
    "SA - Strongly Agree" 
  ]
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      testList: [],
      isLoading: false,
      isModalOpen: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleGotItClick = this.handleGotItClick.bind(this)
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    this.setState({
      isLoading: false
    });

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getAllAssessmentTestsUserApiCallId) {
      this.handleGetAllAssessmentUserResponse(responseJson);
    }

    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    
      this.setState({
        testList: [{
          title: 'BARC Test',
          id: 'barc'
        }]
      })
    this.getListOfAssesment();
  }
  async getListOfAssesment() {
    this.setState({
      isLoading: true
    })
    const loginToken = await getStorageData('LoginSuccessToken');
    const signupToken = await getStorageData('SignupSuccessToken');

      const headers = {
        token: signupToken || loginToken
      };

      const getUserAllAssessment = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      getUserAllAssessment.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getAssessmentTestListUserApiEndpoint
      );

      getUserAllAssessment.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAssessmentTestListUserApiMethod
      );


      getUserAllAssessment.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      this.getAllAssessmentTestsUserApiCallId = getUserAllAssessment.messageId;
      runEngine.sendMessage(getUserAllAssessment.id, getUserAllAssessment);
  }

  handleTestClick = (test: any) => {
    if (test.title === "BARC Test") {
      this.setState({
        isModalOpen: true
      })
      return
    }
    const msg1 = new Message(getName(MessageEnum.NavigationAssessmentTestMessage));
      msg1.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      msg1.addData(
        getName(MessageEnum.UrlPathParamMessage),
        test.id
      );
      this.send(msg1)
    // Navigate user
  }

  handleGetAllAssessmentUserResponse(responseJson: any) {
    if (responseJson && responseJson?.data) {
      this.setState({
        testList: [...this.state.testList , ...responseJson.data.map((res: any) => ({ id: res.id, title: res.attributes.title }))]
      })
    }
  }

  handleClose = (bool: boolean) => {
    this.setState({ isModalOpen: bool })
  }

  handleGotItClick(){
    const bacrNavigationMessage = new Message(getName(MessageEnum.NavigationBarcAssessmentMessage));
    bacrNavigationMessage.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(bacrNavigationMessage)
  }
  // Customizable Area End
}
