// Customizable Area Start
import React from "react";
import {
  Box, InputAdornment, TextField, Button } from "@material-ui/core";
import UserOrganizationInformationController, {
  Props,
} from "./UserOrganizationInformationController.web";
import Loader from "../../../components/src/Loader.web";
import { styled } from "@material-ui/core/styles";
import { Global ,PCard,roundLocation,Email, phone} from "./assets";
// Customizable Area End

export default class UserOrganizationInformation extends UserOrganizationInformationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {address, email,contact_number, name,website } = this.state.data;
    const errors = this.state.errors;
    // Customizable Area End
    return (
      // Customizable Area Start
      <MainWrapper component={"section"} display={"flex"}>
        <Loader loading={this.state.isLoading} />
        <Box bgcolor={"#fff"} p={"24px"} borderRadius={"12px"} sx={{ width: { xs: "auto", md: "70%", lg: "60%" }, marginX: { xs: "32px", md: "auto" } }} my={"32px"}>
          <Box component={"h4"} color="#005487" fontFamily={"Verdana"} fontWeight={"bold"} fontSize={"22px"} m={0}>Organization Information</Box>
          <Box my={"20px"}>
            <Box component={"label"} display="block" fontFamily={"Verdana"} mb={"12px"} color="#005487" fontWeight={"bold"} fontSize={"14px"}>Address*</Box>
            <TextField
              data-testid="address"
              variant="outlined"
              size="medium"
              placeholder="Enter Organization Address"
              name="address"
              type="text"
              value={address}
              onChange={this.handleChangeField}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" >
                    <img src={roundLocation} alt="location" />
                  </InputAdornment>
                ),
              }}
            />
            {errors.address && <Box component={"p"} role="error" color="red" fontFamily={"Verdana"} m={0} my={"6px"} fontSize={"14px"}>{errors.address}</Box>}
          </Box>
          <Box mb={"20px"}>
            <Box component={"label"} display="block" fontFamily={"Verdana"} mb={"12px"} color="#005487" fontWeight={"bold"} fontSize={"14px"}>E-mail*</Box>
            <TextField
              data-testid="email"
              variant="outlined"
              size="medium"
              placeholder="Enter Organization E-mail"
              name="email"
              type="text"
              value={email}
              onChange={this.handleChangeField}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" >
                    <img src={Email} alt="email" />
                  </InputAdornment>
                ),
              }}
            />
            {errors.email && <Box component={"p"} role="error" color="red" fontFamily={"Verdana"} m={0} my={"6px"} fontSize={"14px"}>{errors.email}</Box>}
          </Box>
          <Box mb={"20px"}>
            <Box component={"label"} display="block" fontFamily={"Verdana"} mb={"12px"} color="#005487" fontWeight={"bold"} fontSize={"14px"}>Name*</Box>
            <TextField
              data-testid="name"
              variant="outlined"
              size="medium"
              placeholder="Enter Organization Name"
              name="name"
              type="text"
              value={name}
              onChange={this.handleChangeField}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" >
                    <img src={PCard} alt="card" />
                  </InputAdornment>
                ),
              }}
            />
            {errors.name && <Box component={"p"} role="error" color="red" fontFamily={"Verdana"} m={0} my={"6px"} fontSize={"14px"}>{errors.name}</Box>}
          </Box>
          <Box mb={"20px"}>
            <Box component={"label"} display="block" fontFamily={"Verdana"} mb={"12px"} color="#005487" fontWeight={"bold"} fontSize={"14px"}>Website*</Box>
            <TextField
              data-testid="website"
              variant="outlined"
              size="medium"
              placeholder="Enter Organization Website"
              name="website"
              type="text"
              value={website}
              onChange={this.handleChangeField}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" >
                    <img src={Global} alt="Global" />
                  </InputAdornment>
                ),
              }}
            />
            {errors.website && <Box component={"p"} role="error" color="red" fontFamily={"Verdana"} m={0} my={"6px"} fontSize={"14px"}>{errors.website}</Box>}
          </Box>
          <Box mb={"20px"}>
            <Box component={"label"} display="block" fontFamily={"Verdana"} mb={"12px"} color="#005487" fontWeight={"bold"} fontSize={"14px"}>Primary Contact Info*</Box>
            <TextField
              data-testid="contact_number"
              variant="outlined"
              size="medium"
              placeholder="Enter Organization Contact Info"
              name="contact_number"
              type="number"
              value={contact_number}
              onChange={this.handleChangeField}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" >
                    <img src={phone} alt="phone" />
                  </InputAdornment>
                ),
              }}
            />
            {errors.contact_number && <Box component={"p"} role="error" color="red" fontFamily={"Verdana"} m={0} my={"6px"} fontSize={"14px"}>{errors.contact_number}</Box>}
          </Box>
          <Box sx={{ width: { xs: "80%", md: "65%", lg: "60%" } }} margin={"auto"}>
            <Button className="done-btn" type="submit" data-testid="done-btn" onClick={this.handleSubmit}>Done</Button>
          </Box>
        </Box>
      </MainWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
  "& .pointer": {
    cursor: "pointer"
  },
  "& .done-btn": {
    width:"100%",
    backgroundColor: "#005487",
    fontWeight:"bold",
    color: "#fff",
    fontSize:"16px",
    height:"56px",
    fontFamily: "Verdana",
  },
});
// Customizable Area End
