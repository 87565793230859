// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  InputAdornment,
  Grid,
  FormControl,
  TextField
} from "@material-ui/core";
import ResetPasswordController, { Props } from "./ResetPasswordController.web";
import { SideImage, Logo,  Lock, Eye, Hideeye } from "./assets";
import { styled } from "@material-ui/core/styles";
export const configJSON = require("./config");
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export default class ResetPassword extends ResetPasswordController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  render() {
    return (
      <>
        {this.state.loading && <Loader loading={this.state.loading} />}
        <Wrapper container>
          <Grid item xs={false} md={5} component={Box} sx={{  display: { xs: 'none', md: 'block' }, height: "100vh", }}>
            <img src={SideImage} width="100%" alt="side-panel"  height="100%" />
          </Grid>

          <Grid  xs={12} md={7} item className={"scrollWrapper"}>
            <Box component={'section'} sx={{ width: "85%", maxWidth: "500px", paddingY: '20px', }}>
              <Box display={'flex'} alignItems={'center'} flexDirection={'column'}  >
                <Box mb={{ xs: "40px", md: '50px' }} display={'flex'} flexDirection={'column'} alignItems={'center'} >
                  <img alt="logo-img"  src={Logo} className={"LoginLogoImg"} />
                  <Box component={'h1'} fontFamily={"Veradana"} fontSize={"45px"} color={"#005487"} fontWeight={'bold'} m={0} my={{ xs: "30px", md: '40px' }}>New Password</Box>
                </Box>
                <form data-test-id="publish-btn" className="form" onSubmit={this.handlePasswordSubmit}>
              <Box display={"flex"} flexDirection={'column'} alignItems={'center'} width={'100%'} gridGap={'24px'}>
                <FormControl fullWidth>
                  <TextField
                    data-test-id="txtInputPassword"
                    variant="outlined"
                    size="medium"
                    placeholder="New Password"
                    name="pass"
                    type={this.state.icon.first ? "text" : "password"}
                    error={Boolean(this.state.errors.pass)}
                    value={this.state.data.pass}
                    onChange={this.handleFieldChange}
                    fullWidth
                    helperText={this.state.errors.pass}
                    className="inputfield"
                    autoFocus={true}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" >
                          <img src={Lock} alt="Lock" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start" >
                          <img className="pointer" data-test-id="eye-icon" alt="eye" src={this.state.icon.first ? Eye : Hideeye}  onClick={this.eyeIconPass} />
                        </InputAdornment>
                      )
                    }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    data-test-id="password_confirmation"
                    variant="outlined"
                    size="medium"
                    placeholder="Confirm Password"
                    name="pass_confirmation"
                    type={this.state.icon.second ? "text" : "password"}
                    error={Boolean(this.state.errors.pass_confirmation)}
                    value={this.state.data.pass_confirmation}
                    onChange={this.handleFieldChange}
                    fullWidth
                    helperText={this.state.errors.pass_confirmation}
                    className="inputfield"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" >
                          <img src={Lock} alt="Lock" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start" >
                          <img  data-test-id="eye-icon" className="pointer" src={this.state.icon.second ? Eye : Hideeye} alt="eye" onClick={this.eyeChangeConfPass} />
                        </InputAdornment>
                      )
                    }}
                  />
                </FormControl>
              </Box>
              <Box display="flex" width={'100%'}>
                <Button variant="contained" type="submit" className={"publishbtnExpand"}>Submit</Button>
              </Box>
              </form>
              </Box>
            </Box>
          </Grid>
        </Wrapper>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const Wrapper = styled(Grid)({
  "& .scrollWrapper": {
    placeItems: 'center',
    display:'grid',
    height: '100vh',
    background: '#fff',
    overflowY: 'auto',
  },
  "& .rightSection": {
    display: 'flex',
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  "&. pointer": {
    cursor: 'pointer',
  },
  "& .LoginLogoImg": {
    width: "250px",
    height: "250px",
    boxShadow: "0 4px 8px 0 rgba(32, 32, 32, 0.2)",
    borderRadius: '50%',
  },
  "& .inputfield": {
    "& .MuiOutlinedInput-input": {
      paddingBlock: "20px"
    }
  },
  "& .publishbtnExpand": {
    height: "56px",
    fontSize: "16px",
    width: "100%",
    fontWeight: "bold",
    color: "#fff",
    textTransform: "none",
    fontFamily: "Verdana",
    backgroundColor: "#005487",
    marginTop: "150px",
    "&:hover": {
      backgroundColor: "#005487",
    },
    "@media(max-width:600px)": {
      marginTop: "200px"
    },
  },
  "& .form": {
    width: '100%',
    margin: 0,
  }
})
// Customizable Area End
