import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import { toast } from "react-toastify";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
const regexUrl1 = /^(https?:\/\/)?[\w-]+(\.[\w-]+)+([/?].*)?$/;
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    open: boolean;
    handleClose: () => void;
    data:{
        id:number;
        url:string;
        type:string;
    }
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    link: string;
    error: string;
    id: number;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
export default class AddSocialMediaLinkModalController extends BlockComponent<
Props,
S,
SS
> {
    // Customizable Area Start
    addLinkId: string = '';
    deleteApiId: string = '';
    // Customizable Area End
    
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            // Customizable Area End
        ];
        this.state = {
            // Customizable Area Start
            link:'',
            id: NaN,
            error: ''
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        let responseJsons = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJsons) {
            if (apiRequestCallId === this.addLinkId) {
                if (responseJsons.data) {
                    this.handleClose()
                    if (this.props.data.url) {
                        toast.success("Link updated sucessfully")
                    } else {
                        toast.success("Link added sucessfully")
                    }
                }
            } else if (apiRequestCallId === this.deleteApiId) {
                if (responseJsons.data) {
                    this.handleClose()
                    toast.success("Link deleted sucessfully")
                }
            }
        }
    }

    handleClose = () => {
        this.props.handleClose()
    }

    async componentDidMount(): Promise<void> {
        super.componentDidMount();
        this.setState({ id: this.props.data.id, link: this.props.data.url })
    }

    handleChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ link: e.target.value })
    }

    handleSaveLink = async () => {
        const isValidUrl = this.state.link.match(regexUrl1);
        if (!isValidUrl) {
            return toast.warning("enter valid link")
        } else {
            let body = new FormData();
            body.append("url", this.state.link)
            const signupToken = await getStorageData('SignupSuccessToken')
            const loginToken = await getStorageData('LoginSuccessToken')
            const header = {
                token: signupToken || loginToken,
                "Content-Type": configJSON.apiContentType,
            }

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            )
            this.addLinkId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                this.props.data.url ? configJSON.social_media_urls + `/${this.props.data.id}` : configJSON.social_media_urls
            )
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            )
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            )
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                this.props.data.url ? configJSON.putApiMethod : configJSON.postApiMethod
            )
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }

    handleDeleteLink = async () => {
        const signupToken = await getStorageData('SignupSuccessToken')
        const loginToken = await getStorageData('LoginSuccessToken')
        const header = {
            token: signupToken || loginToken,
            "Content-Type": configJSON.apiContentType,
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        this.deleteApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.social_media_urls + `/${this.props.data.id}`
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethod
        )
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}