import { IBlock } from "../../../framework/src/IBlock";
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
// Customizable Area Start
import { DateObject } from "react-multi-date-picker";
export const configJSON = require("./config");
import moment from "moment";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

// Customizable Area Start
export interface EventData {
  id: string,
  message:string,
  attributes: {
     id: number,
     cover_image: string,
      event_typ: string,
      event_name: string,
      event_url: string,
      community_name: string|null,
      description: string,
      start_date: string,
      end_date: string,
      full_name: string | null,
      start_time:string,
      end_time: string,
      interest_event_count: [
        {
          account_images: [
            { photo: string },
          ],
          count: null
        }
      ],
  };
}

export interface Interasred{
    photo: string | undefined;
}
// Customizable Area End
export interface Props {
  navigation: any;
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  arrayHolder: Array<EventData>;
  selectAppBar: string;
  selectedDate: Date ;
  createEvent: boolean;
  highLightedDate: Date[];
  open: boolean;
  loading: boolean;
  message: string;
  // Customizable Area End
}

interface SS {
 // Customizable Area Start
 // Customizable Area End
}
export default class CalenderEventsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getEventApiCallId: string = "";
  bgColor: string[] = ["#c2bc30","#d93f49", "#fbad18"];
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      arrayHolder: [],
      createEvent: false,
      selectAppBar: "daily",
      selectedDate: new Date(),
      highLightedDate: [],
      open: false,
      loading: false,
      message: 'No events scheduled for the selected day',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  NavigateToCalendarDetails = async (e: string) => {
    this.props.navigation.navigate('CalendarEventDetails',{id:e})
  }

  handleNavigate = () => {
    this.setState({createEvent: true});
  }

  closeModal = () => {
    this.setState({createEvent: false});
  }

  handleheader = (e: string) => {
    this.setState({ selectAppBar: e })
    this.getListRequest(this.state.selectedDate)
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getListRequest(new Date())
  }

  handleChangeCalender = (selectedDate: DateObject | DateObject[] | null) => {
    let clickedDate: Date = new Date();

    if (selectedDate instanceof DateObject) {
      // Single date selected
      clickedDate = new Date(selectedDate.valueOf());
    } else if (selectedDate instanceof Array) {
      // Multiple dates selected, extract the first date from the array
      clickedDate = new Date(selectedDate[selectedDate.length - 1].valueOf());
    }

    if (clickedDate) {
      this.setState({ selectedDate: clickedDate });
      this.getListRequest(clickedDate)
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.getEventApiCallId) {
        this.setState({ loading: false });
        if (responseJson && !responseJson.errors) {
          this.getListRequestSucessCallBack(responseJson)
        }
      }
    }
  }

  onMonthChange=(selectedDate:DateObject)=>{
    if(this.state.selectAppBar === "monthly"){
      const clickedDate: Date = new Date(selectedDate.valueOf());
      this.setState({ selectedDate: clickedDate });
      this.getListRequest(clickedDate)
    }
  }

  getListRequest = async (e: Date) => {
    this.setState({ loading: true })
    let signupToken = await getStorageData('SignupSuccessToken');
    let loginToken = await getStorageData('LoginSuccessToken');
    const header = {
      token: signupToken ?? loginToken
    };
    const param = `?events=${this.state.selectAppBar}&event_date=${moment(this.state.selectedDate).format('DD/MM/YYYY')}`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEventApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.eventsEnd + param
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getListRequestSucessCallBack = (response: { data: EventData[], message: string }) => {
    if (response.data) {
        const { selectAppBar, selectedDate } = this.state;
        const highlightedDates = this.processData(response.data, selectAppBar, selectedDate);
        
        this.setState({
            highLightedDate: highlightedDates,
            arrayHolder: response.data,
            message: ''
        });
    } else if (response.message) {
      this.setState({
        arrayHolder: [],
        message: response.message,
        highLightedDate: [this.state.selectedDate]
      }); 
    }
  };

  processData = (data: EventData[], selectAppBar: string, selectedDate: Date) => {
    const highlightedDates: Date[] = [];
    const date = moment(selectedDate);

    if (selectAppBar === 'daily') {
        highlightedDates.push(date.toDate());
    } else if (selectAppBar === 'weekly' || selectAppBar === 'monthly') {
        const startOfRange = date.clone().startOf(selectAppBar === 'weekly' ? 'week' : 'month');
        const endOfRange = date.clone().endOf(selectAppBar === 'weekly' ? 'week' : 'month');

        data.forEach(item => {
            const startDate = moment(item.attributes.start_date);
            const endDate = moment(item.attributes.end_date);

            if (startDate.isBefore(endOfRange) && endDate.isAfter(startOfRange)) {
                this.handleDateRange(startDate, endDate, startOfRange, endOfRange, highlightedDates);
            }
        });
    }
    
    return highlightedDates;
  };

  handleDateRange = (startDate: moment.Moment, endDate: moment.Moment, startOfRange: moment.Moment, endOfRange: moment.Moment, highlightedDates: Date[]) => {
    const start = moment.max(startDate, startOfRange);
    const end = moment.min(endDate, endOfRange);

    const current = start.clone();
    while (current.isSameOrBefore(end, 'day')) {
        highlightedDates.push(current.toDate());
        current.add(1, 'day');
    }
  };
  // Customizable Area End

  // Customizable Area Start
  // Customizable Area End
}

