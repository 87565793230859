import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface Datum {
  id: any;
  type: string;
  attributes: Attributes;
}
interface Attributes {
  id: any;
  name?: any;
  profile_pic?: any;
  follower_count?: any;
  background_profile_pic?: any;
  community_post:any;
  description: string;
  url?: any;
  account_id?: any;
  community_id: number;
  post_type: string;
  created_at: string;
  updated_at: string;
  creating_time: string;
  thumbnail: string;
  images_and_videos: string[];
  media: any[];
  post_like_count?: any;
  comments: any;
  full_name?: any;
  community_name: string;
  profile_picture?: any;
  community_profile: string;
  community_account_id: number;
  is_liked: boolean;
  comments_count: number;
  is_followed: boolean;
  post_save_by_current_user: boolean;
  is_login_user_post: boolean;
  is_followed_community: boolean;
  is_followed_by_login_user: boolean;
  video_title?: any;
  post_shared_by?: any;
  shared_account_profile?: any;
  community_shared_by?: any;
  shared_community_profile?: any;
  like_by?: any;
  shared_at: string;
  comment_by?: any;
  shared_item_id?: any;

  notification_turn_on: boolean;
  video_thumbnail?: any;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  communityData: Datum[] | any;
  loading: boolean;
  expandDesc: boolean;
  followBtn: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CommunityListingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  communityListingtApiCallId: string = "";
  communityFollowApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      communityData: null,
      loading: true,
      expandDesc: false,
      followBtn: false,

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.communityListingtApiCallId) {
      if (responseJson && responseJson.data) {
        this.setState({
          communityData: responseJson?.data,
          loading: false,
          followBtn: responseJson?.data?.attributes?.is_followed_by_login_user,
        });


      }
    }
    if (apiRequestCallId === this.communityFollowApiCallId) {
      if (responseJson && responseJson.data) {
        toast.success("Community Followed Successfully!");
        this.setState({ followBtn: !this.state.followBtn });
      
      } else if (responseJson?.errors) {
        toast.error(responseJson?.errors[0]?.message);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.communityListing();
  }

  handleFollowSubmit = async (communityId: any) => {
    let signupToken = await getStorageData("SignupSuccessToken");
    let loginToken = await getStorageData("LoginSuccessToken");
    const header = {
      token: signupToken || loginToken,
      "Content-Type": configJSON.apiContentType,
    };
    const formData = new FormData();
    {
      !this.state.followBtn && formData.append("community_id", communityId);
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.communityFollowApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.followBtn
        ? `unfollow_community?community_id=${communityId}`
        : "create_followers"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    {
      !this.state.followBtn &&
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
        );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.followBtn ? "DELETE" : "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleExpandDescription = () => {
    this.setState({ expandDesc: true });
  };

  followUpdatedCommunityTab=(type: string, id: string, action: string) => {

    const upadtedList = this.state.communityData.map((item: any) => {
  
   if (item?.id === id.toString()) {
  
        return {
          ...item, attributes: { ...item.attributes, is_followed_by_user: action === "follow" ? true : false }
        }
      } else {
        return item
      }
    })
 
    this.setState({ communityData: upadtedList })
  }
  communityListing = async () => {
    let signupToken = await getStorageData("SignupSuccessToken");
    let loginToken = await getStorageData("LoginSuccessToken");
    const header = {
      token: signupToken || loginToken,
      "Content-Type": configJSON.apiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.communityListingtApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "community_post"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
