Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TextDetector";
exports.labelBodyText = "TextDetector Body";

exports.btnExampleTitle = "CLICK ME";

exports.requestApiContentType = "application/json";
exports.getReceivedRequestApiEndpoint = "request_management/requests/received";
exports.getReceivedRequestApiMethod = "GET";

exports.getSendRequestApiEndpoint = "request_management/requests/sent";
exports.getSendRequestApiMethod = "GET";

exports.getAllReceivedRequestApiEndpoint = "request_management/requests";
exports.getAllReceivedRequestApiMethod = "GET";

exports.createSendRequestApiEndpoint = "request_management/requests";
exports.createSendRequestApiMethod = "POST";

exports.emptyGroupIdAlert = "Please select group id";
exports.emptyRejectReasonAlert = "Please enter reason";
exports.acceptedMsgText = "Request has been accepted.";
exports.rejectedMsgText = "Request has been rejected.";
exports.deletedMsgText = "Request has been deleted.";
exports.requestSentSuccessMsg = "Request has been sent."
exports.requestUpdateSuccessMsg = "Request has been updated."

exports.updateRequestApiEndpointStart = "request_management/requests/";
exports.updateRequestApiEndpointEnd = "/review";
exports.updateRequestApiMethod= "PUT";

exports.deleteRequestApiEndpoint = "request_management/requests/";
exports.deleteRequestApiMethod= "DELETE";

exports.getGroupsApiEndpoint = "account_groups/groups";
exports.getGroupsApiMethod= "GET";

exports.getSentRequestApiEndpoint = "request_management/requests/sent";
exports.getSentRequestApiMethod= "GET";

exports.updateRequestTextApiEndpoint = "request_management/requests/";
exports.updateRequestTextApiMethod= "PUT";


exports.titleText = "Request Management";
exports.sendRequestBtnLabel = "Send Request";
exports.receiveRequestText = "Received Requests";
exports.nameLabelText = "Name";
exports.requestLabelText = "Request Text";
exports.rejectReasonLabelText = "Reject Reason";
exports.statusLabelText = "Status";
exports.actionLabelText = "Action";
exports.acceptBtnLabel = "Accept";
exports.rejectBtnLabel = "Reject";
exports.deleteBtnLabel = "Delete";
exports.sendRequestsText = "Sent Requests";
exports.updateBtnLabel = "Update";
exports.viewBtnLabel = "View";
exports.requestIdLabel = "Request ID";
exports.sendRequestDialogTitle = "Send Request";
exports.updateRequestDialogTitle = "Update Request";
exports.rejectRequestDialogTitle = "Request reject";
exports.cancelBtnLabel = "Cancel";
exports.sendBtnLabel = "Send";
exports.rejectReasonInputLabel = "Please enter reason";
exports.requestTextInputLabel = "Please enter request text";
exports.selectGroupPlaceholder = "Please select group";
exports.rejectTextFieldIsRequired = "Please enter reject reason";
exports.loginAlertMessage = "Please login first.";
exports.filterInputLabel = "Enter request id to filter";
exports.getFollowersListApiEndpoint = "user_followers_list";
exports.getFollowersListApiMethod = "GET";
exports.getFriendsListApiEndpoint = "friend_list";
exports.getFriendsListApiMethod = "GET";
exports.removeFollowerApiEndpoint ="remove_follower";
exports.removeFollowerApiMethod = "DELETE";
exports.updateFriendRequestApiEndpoint = "request_management/requests";
exports.updateFriendsRequestApiMethod = "PUT";
exports.getFriendsRequestListApiEndpoint = "request_management/requests/receive";
exports.getFriendsRequestListApiMethod = "GET";
exports.getMutualConnectionApiEndpoint = "request_management/requests/mutual_connections";
exports.getMutualConnectionApiMethod = "GET";
exports.removeFriendApiEndpoint ="remove_friend";
exports.removeFriendApiMethod = "DELETE";
exports.searchFriendListApiEndpoint = "friend_list";
exports.searchFriendListApiMethod = "GET";
exports.getRecentSearchListApiEndpoint = "get_recent_search?search_type=";
exports.getRecentSearchListApiMethod = "GET";
exports.addRecentSearchApiEndpoint = "add_recent_search";
exports.addRecentSearchApiMethod = "POST";
exports.removeRecentSearchApiEndpoint = "remove_search";
exports.removeRecentSearchApiMethod = "DELETE";
exports.friend_list="Friends List";
exports.follower_list="Followers List";
exports.request_management="Request Management";
exports.friend_request="Friend Request";
exports.show_all="Show all";
exports.mutual_connections="Mutual Connections";
exports.privateConversation = "bx_block_chat9/chats/create_conversation";
exports.community_members="Community Members";
exports.recommanded_accountsEnd="recommanded_accounts";
exports.removeRecommendationApiEndpoint = "remove_from_recommended";
exports.recommonded_for_you="Recommonded for you";
exports.block_account_for_community="block_account_for_community";
exports.unblock_account="unblock_account";
// Customizable Area End