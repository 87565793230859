import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

import { AssessmentItemInterface } from '../IAssessment'


// Customizable Area Start
import { getSignupLoginTokens, handleErrorArray, isLocalAdmin } from "../../../../components/src/common";

export interface BarcData {
  data: {
    chartData: {
      date: string,
      score: number
    }[],
    attemptedData: {
      title: string,
      date: string
    }[],
    no_of_test_taken: number,
    recent_score: number,
    total_score: number,
    avg_score: number,
    no_of_questions: number
  }
}
export interface AdminBarcData {
  data: {
    "barc_score": {
      "name": string;
      "score": number;
      "date": string;
      "total_score":number
    }[],
    "overall_average_score": {
        "name": string,
        "score": string,
        "total_avg_score": number
      }[]
  }
}

export interface AssessmentTestList {
  title: string,
  id: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  itemContent: AssessmentItemInterface,
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  dashboardData: any;
  showDropDown: boolean;
  assessmentTestList: AssessmentTestList[];
  showLoading: boolean;
  barcData: BarcData['data']
  adminBarcData:AdminBarcData['data'];
  selectedMenu: {
    id: string;
    lable: string;
  };
  selectedAssessment: string;
  // Customizable Area End
}

interface SS {
  id: any;

  // Customizable Area Start
  // Customizable Area End
}

export default class AssessmentResultController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAssessmentListApiCallId: string = "";
  getBarcDataApiCallId: string = "";
  getAssessMentApiCallId: string = "";
  getAdminDashboardDataApiCallId: string = '';
  loginToken: string = '';
  signupToken: string = '';
  isLocalAdmin: boolean = false;
  accountId: string = '';
  quizId: string = '';
  apiGetAssessmentResult: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);



    this.state = {
      isLoading: true,
      isError: false,
      isSuccess: false,
      itemContent: {},
      dashboardData: {},
      showDropDown: false,
      assessmentTestList: [{
        title: "BARC Dashboard",
        id: "barc"
      }],
      showLoading: false,
      barcData: {
        chartData: [],
        attemptedData: [
          { date: '', title: '' }
        ],
        avg_score: 0,
        no_of_test_taken: 0,
        recent_score: 0,
        total_score: 0,
        no_of_questions: 0,
      },
      adminBarcData: {
        barc_score: [],
        overall_average_score: []
      },
      selectedMenu: { id: '', lable: '' },
      selectedAssessment: 'BARC Dashboard'

    };

    // Customizable Area End

  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    this.setState({
      showLoading: false
    })
    
    
    switch (apiRequestCallId) {
      case this.getAssessmentListApiCallId:
        this.handleGetAssessmentTestResponse(responseJson);
        break;
      case this.getBarcDataApiCallId:
        this.handleGetBarcResponse(responseJson)
        break
      case this.getAssessMentApiCallId:
        this.handleAssessmentTestChangeResponse(responseJson)
        break;
      case this.getAdminDashboardDataApiCallId:
        this.handleAdminBarcDataResponse(responseJson)
        break;
    }
    // Customizable Area End
  }


  // Customizable Area Start

  startLoading = () => {
    this.setState({
      showLoading: true
    })
  }

  async componentDidMount() {
    const { signupToken, loginToken } = await getSignupLoginTokens();
    const isAdmin = await isLocalAdmin()
    this.signupToken = signupToken;
    this.loginToken = loginToken;
    this.isLocalAdmin = isAdmin;  
    this.handleParamChange();
  }
  

  handleParamChange = async () => {
    this.isLocalAdmin = await isLocalAdmin();
    if (this.isLocalAdmin) {
      this.setState({
        assessmentTestList: [{
          title: "My Community",
          id: "community"
        },
        {
          title: "Individual User",
          id: "INDUser"
        }
        ],
        selectedMenu: { id: '', lable: "" }
      })
      this.getAdminBarcData()
      

    } else {
      this.getAssessmentTestList();
      this.getUserBarcData()
      this.setState({
        selectedMenu: { id: 'barc', lable: "BARC Dashboard" }
      })
    }
  }

  //Get Assessmment list api call
  getAssessmentTestList() {
    this.startLoading();
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.signupToken || this.loginToken
    };

    const getAssessmentList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssessmentListApiCallId = getAssessmentList.messageId;

    getAssessmentList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAssessmentTestListApiEndpoint
    );

    getAssessmentList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAssessmentList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAssessmentTestListApiMethod
    );
    runEngine.sendMessage(getAssessmentList.id, getAssessmentList);
  }

  //getUserBarcdata api call
  getUserBarcData() {
    this.startLoading();
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.signupToken || this.loginToken
    };

    const getBarcData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBarcDataApiCallId = getBarcData.messageId;

    getBarcData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBarcDataApiEndpoint
    );

    getBarcData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getBarcData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBarcDataApiMethod
    );
    runEngine.sendMessage(getBarcData.id, getBarcData);
  }

  //getAdminbarc data api call
  getAdminBarcData() {
    this.startLoading();

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.signupToken || this.loginToken
    };

    const getAdminData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAdminDashboardDataApiCallId = getAdminData.messageId;
    getAdminData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAdminDashboardDataApiEndpoint
    );

    getAdminData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAdminData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAdminDashboardDataApiMethod
    );
    runEngine.sendMessage(getAdminData.id, getAdminData);
  }

  //handlegetassessment api call response
  handleGetAssessmentTestResponse(responseJson: {data: {id:string,attributes:{title:string}}[]}) {

    if (responseJson && responseJson.data) {
      this.setState({
        assessmentTestList: [...this.state.assessmentTestList, ...responseJson.data.map((res) => ({ id: res.id, title: res.attributes.title }))]
      })

    }
  }

  //handlegetbrac api call response
  handleGetBarcResponse(responseJson: {data:{chartData:{date:string,score:number}[],attemptedData:{title:string,date:string}[],no_of_test_taken:number,avg_score:number,recent_score:number,total_score:number,no_of_questions:number}}) {
  
    if (responseJson?.data?.chartData) {
      this.setState({
        barcData: { ...responseJson.data }
      })
    }
  }

  handleAssessmentChange(id: string, title: string) {
    if (this.state.selectedMenu.id !== id) {
      this.setState({
        showDropDown: false,
        selectedMenu: { id: id, lable: title },
        selectedAssessment: title
      })
      if (this.isLocalAdmin) {
        if (id === "INDUser") {
          const individualUserNavigation = new Message(getName(MessageEnum.NavigationAssessmentTestUserListMessage));
          individualUserNavigation.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
          );
          individualUserNavigation.addData(
            getName(MessageEnum.UrlPathParamMessage),
            'individualView'
          );
          this.send(individualUserNavigation)
        } else {
          const msg = new Message(getName(MessageEnum.NavigationAssessmentTestViewListMessage));
          msg.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
          );
          msg.addData(
            getName(MessageEnum.UrlPathParamMessage),
            id
          );
          this.send(msg);
        }
      } else {
        if (id === 'barc') {
          this.getUserBarcData()
          return;
        }
        this.startLoading();

        const headers = {
          "Content-Type": configJSON.validationApiContentType,
          token: this.signupToken || this.loginToken
        };

        const getAssessMentData = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAssessMentApiCallId = getAssessMentData.messageId;
    
        getAssessMentData.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getAssessmentDataApiEndpoint + `?quiz_id=${id}`
        );

        getAssessMentData.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
        getAssessMentData.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getAssessmentDataApiMethod
        );
        runEngine.sendMessage(getAssessMentData.id, getAssessMentData);
      }
    }
  }

  handleAssessmentTestChangeResponse(responseJson: {data:{chartData:{score:number,date:string}[],attemptedData:{title:string,date:string}[],
    no_of_test_taken:number,recent_score:number,total_score:number,name:string,avg_score:number,no_of_questions:number}}) {
    if (responseJson && responseJson.data) {
      this.setState({
        barcData: { ...responseJson.data }
      })
    } else {
      this.setState({
        barcData: {
          attemptedData: [],
          chartData: [],
          no_of_test_taken: 0,
          recent_score: 0,
          total_score: 0,
          avg_score: 0,
          no_of_questions: 0,
        }
      })
    }
  }

  
  navigateUser(page: string) {
    if (page === 'View') {
      const msg = new Message(getName(MessageEnum.NavigationAssessmentTestViewListMessage));
      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(msg)
    } else if (page === 'AssessmentTest') {
      const msg = new Message(getName(MessageEnum.NavigationQuestionBankCreateMessage));
      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(msg);
    } else if(page === "AssessmentTestListing"){
      const msg = new Message(getName(MessageEnum.NavigationAssessmentTestListMessage));
      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(msg);
    }
  }

  formatDate(inputDate: string): string {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: '2-digit' };
    const date = new Date(inputDate);
    return date.toLocaleDateString('en-US', options);
  }


  handleAdminBarcDataResponse(responseJson: AdminBarcData) {
    if(responseJson?.data){
      this.setState({
        adminBarcData: responseJson.data
      })
    }
  }
   
  // Customizable Area End
}
