Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings";
exports.labelBodyText = "Settings Body";
exports.textPwdErr="please Enter Password"
exports.btnExampleTitle = "CLICK ME";
exports.yesBtn="Yes";
exports.noBtn="No";
exports.tokenDeregisterId="update_device_token";
exports.putMethod="PUT";
// Customizable Area End