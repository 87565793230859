// Customizable Area Start
import React from "react";
import BackIcon from "./SvgIcon/BackIcon.web";
import {
  Grid,
  Card,
  CardMedia,
  CardHeader,
  CardActions,
  Box,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import CustomButton from "../../../components/src/CustomButton.web";
import moment from "moment";
import { AvatarGroup } from "@material-ui/lab";
import Loader from "../../../components/src/Loader.web";
import Avatar from "@material-ui/core/Avatar";
import { Like, SendFill, CommentFill } from "./assets";
import InfiniteScroll from "react-infinite-scroll-component";

import ReactPlayer from "react-player";

import FilteritemsNewController, {
  Props,
} from "./FilterItemsNewController.web";

// Customizable Area End
export default class FilteritemsNew extends FilteritemsNewController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }

  filterSubRender = () => {
    return (
      <>
        {this.state.filterItem === "photos" && (
          <InfiniteScroll
            style={{ overflow: "hidden" }}
            dataLength={this.state?.renderFilter?.length}
            next={() => {
              this.FilterItemFunction(false);
            }}
            hasMore={this.state.newPostListCount != 0}
            loader={
              <div style={{ marginLeft: "25rem" }}>
                <CircularProgress />
              </div>
            }
          >
            <Grid
              container
              spacing={2}
              className="LoginMainBox"
              style={{
                backgroundColor: "white",
                padding: "11px",
                borderRadius: "12px",
                width: "94%",
              }}
            >
              <Grid item xs={12} style={{ display: "flex" }}>
                <div onClick={this.handleBack} data-test-id="handle-back">
                  <BackIcon
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke={"#005487"}
                    strokeWidth="1.5"
                    fill={"none"}
                  />
                </div>
                <Typography variant="body1" style={webPage.HeadingText}>
                  Photos
                </Typography>
              </Grid>

              {this.state?.renderFilter?.map((item: any) => (
                <Grid
                  key={item?.attributes?.account_id}
                  item
                  xs={3}
                  style={{ borderRadius: "12px", marginTop: "20px" }}
                >
                  <>
                    <Card
                      onClick={() => this.navigatePosts(item)}
                      data-test-id="navigated-post"
                    >
                      <CardMedia
                        style={{ height: "200px" }}
                        image={item?.attributes?.images_and_videos[0]}
                        title="Image"
                      />
                    </Card>
                  </>

                  <Typography variant="body2" style={webPage.imageUserText}>
                    By {item?.attributes?.full_name}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </InfiniteScroll>
        )}
        {this.state.filterItem === "videos" && (
          <InfiniteScroll
            style={{ overflow: "hidden" }}
            dataLength={this.state?.renderFilter?.length}
            next={() => {
              this.FilterItemFunction(false);
            }}
            hasMore={this.state.newPostListCount != 0}
            loader={
              <div style={{ marginLeft: "25rem" }}>
                <CircularProgress />
              </div>
            }
          >
            <Grid
              container
              spacing={2}
              className="LoginMainBox"
              style={{
                padding: "11px",
                backgroundColor: "white",

                width: "94%",
                borderRadius: "12px",
              }}
            >
              <Grid style={{ display: "flex" }} item xs={12}>
                <div onClick={this.handleBack}>
                  <BackIcon
                    width="24"
                    height="24"
                    stroke={"#005487"}
                    viewBox="0 0 24 24"
                    fill={"none"}
                    strokeWidth="1.5"
                  />
                </div>

                <Typography variant="body1" style={webPage.HeadingText}>
                  Videos
                </Typography>
              </Grid>

              {this.state?.renderFilter?.map((item: any) => (
                <>
                  <div
                    style={{ display: "flex" }}
                    onClick={() => {
                      this.navigatePosts(item);
                    }}
                    data-test-id="navigated-post"
                  >
                    <Typography style={webPage.videoTextBold} variant="body2">
                      {item?.attributes?.full_name}
                      <span style={webPage.videoText}>
                        posted this{" "}
                        <Typography
                          component={"span"}
                          style={webPage.greyDot}
                        />{" "}
                        {item?.attributes?.created_at}
                      </span>
                    </Typography>
                  </div>

                  <br />

                  <div
                    onClick={() => {
                      this.navigatePosts(item);
                    }}
                    data-test-id="navigated-post"
                  >
                    <Typography style={webPage.FilterTitle} variant="body2">
                      {item?.attributes?.video_title}
                    </Typography>
                    <Typography style={webPage.hashText} variant="body2">
                      #post #videos #xyz #title #posts #url #youtube #vimeo
                    </Typography>
                  </div>
                  <Grid
                    container
                    spacing={2}
                    className="LoginMainBox"
                    onClick={() => {
                      this.navigatePosts(item);
                    }}
                    data-test-id="navigated-post"
                  >
                    <Grid item xs={4} style={{ borderRadius: "12px" }}>
                      <ReactPlayer
                        style={{ height: "100%" }}
                        className="react-player__preview"
                        url={item?.attributes?.url}
                        controls={true}
                        playing={true}
                        width="100%"
                        height="100%"
                        light={true}
                      />
                    </Grid>
                    <Grid style={{ borderRadius: "12px" }} item xs={8}>
                      <Typography style={webPage.TitleHeading} variant="body2">
                        {item?.attributes?.video_title}
                      </Typography>
                      <a
                        style={{ textDecoration: "none" }}
                        target="_blank"
                        href={item?.attributes?.url}
                      >
                        <Typography style={webPage.YtHeading} variant="body2">
                          {item?.attributes?.post_type === "Vimeo"
                            ? "VIMEO.COM"
                            : "YOUTUBE.COM"}
                        </Typography>
                      </a>
                    </Grid>
                  </Grid>
                  <hr style={webPage.horizontal} />
                </>
              ))}
            </Grid>
          </InfiniteScroll>
        )}
      </>
    );
  };
  filterItemUrls = () => {
    return (
      <>
        {this.state.filterItem === "urls" && (
          <InfiniteScroll
            style={{ overflow: "hidden" }}
            dataLength={this.state?.renderFilter?.length}
            next={() => {
              this.FilterItemFunction(false);
            }}
            hasMore={this.state.newPostListCount != 0}
            loader={
              <div style={{ marginLeft: "25rem" }}>
                <CircularProgress />
              </div>
            }
          >
            <Grid
              container
              spacing={2}
              className="LoginMainBox"
              style={{
                padding: "11px",

                width: "94%",
                backgroundColor: "white",
                borderRadius: "12px",
              }}
            >
              <Grid item xs={12} style={{ display: "flex" }}>
                <div onClick={this.handleBack}>
                  <BackIcon
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    stroke={"#005487"}
                    strokeWidth="1.5"
                    fill={"none"}
                  />
                </div>

                <Typography variant="body1" style={webPage.HeadingText}>
                  URL's
                </Typography>
              </Grid>

              {this.state?.renderFilter?.map((item: any) => (
                <>
                  <div
                    style={{ display: "flex" }}
                    onClick={() => {
                      this.navigatePosts(item);
                    }}
                    data-test-id="navigated-post"
                  >
                    <Typography variant="body2" style={webPage.videoTextBold}>
                      {item?.attributes?.full_name}
                      <span style={webPage.videoText}>
                        posted this{" "}
                        <Typography
                          component={"span"}
                          style={webPage.greyDot}
                        />{" "}
                        {item?.attributes?.created_at}
                      </span>
                    </Typography>
                  </div>
                  <br />
                  <div
                    onClick={() => {
                      this.navigatePosts(item);
                    }}
                    data-test-id="navigated-post"
                  >
                    <Typography variant="body2" style={webPage.FilterTitle}>
                      {item?.attributes?.description}
                    </Typography>
                    <Typography variant="body2" style={webPage.hashText}>
                      #post #videos #xyz #title #posts #url #youtube #vimeo
                    </Typography>
                  </div>
                  <Grid container spacing={2} className="LoginMainBox">
                    <Grid item xs={4} style={{ borderRadius: "12px" }}>
                      {item?.attributes?.url.includes("youtube") ||
                      item?.attributes?.url.includes("vimeo") ? (
                        <ReactPlayer
                          style={{ height: "100%" }}
                          className="react-player__preview"
                          url={item?.attributes?.url}
                          playing={true}
                          controls={true}
                          width="100%"
                          height="100%"
                          light={true}
                        />
                      ) : (
                        <img
                          style={{ width: "14.5rem", height: "7.5rem" }}
                          src={item?.attributes?.thumbnail}
                        />
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      style={{ borderRadius: "12px" }}
                      onClick={() => {
                        this.navigatePosts(item);
                      }}
                      data-test-id="navigated-post"
                    >
                      <Typography variant="body2" style={webPage.TitleHeading}>
                        {item?.attributes?.description}
                      </Typography>
                      <Typography variant="body2" style={webPage.YtHeadingUrl}>
                        <a
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#005487",
                          }}
                          href={item?.attributes?.url}
                        >
                          {item?.attributes?.url}
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                  <hr style={webPage.horizontal} />
                </>
              ))}
            </Grid>
          </InfiniteScroll>
        )}
      </>
    );
  };
  render() {
    return (
      <>
        {this.state.loading ? (
          <Loader loading={this.state.loading} />
        ) : (
          <>
            {this.filterSubRender()}
            {this.filterItemUrls()}

            {this.state.filterItem === "events" && (
              <InfiniteScroll
                style={{ overflow: "hidden" }}
                dataLength={this.state?.renderFilter?.length}
                next={() => {
                  this.FilterItemFunction(false);
                }}
                hasMore={this.state.newPostListCount != 0}
                loader={
                  <div style={{ marginLeft: "25rem" }}>
                    <CircularProgress />
                  </div>
                }
              >
                <Grid
                  spacing={2}
                  container
                  className="LoginMainBox"
                  style={{
                    padding: "11px",
                    borderRadius: "12px",
                    backgroundColor: "white",
                    width: "94%",
                  }}
                >
                  <Grid item xs={12} style={{ display: "flex" }}>
                    <div onClick={this.handleBack}>
                      <BackIcon
                        width="24"
                        height="24"
                        stroke={"#005487"}
                        viewBox="0 0 24 24"
                        fill={"none"}
                        strokeWidth="1.5"
                      />
                    </div>

                    <Typography variant="body1" style={webPage.HeadingText}>
                      Events
                    </Typography>
                  </Grid>
                  {console.log(this.state.renderFilter, "fjignirg")}
                  {this.state?.renderFilter?.map((item: any) => (
                    <Box
                      boxShadow={3}
                      marginBottom={"20px"}
                      style={webPage.boxContainer}
                      borderRadius={"15px"}
                      data-test-id="Navigeate-to-details"
                    >
                      <Grid container item sm={12} style={webPage.boxgrid}>
                        <Grid
                          container
                          item
                          sm={5}
                          style={{
                            backgroundImage: `url${item?.attributes.cover_image}`,
                            backgroundRepeat: "no-repeat",
                          }}
                          onClick={() => {
                            this.navigateEvent(item);
                          }}
                        >
                          <Grid
                            item
                            style={{
                              background: `linear-gradient(90deg, rgba(242,242,246,0.09567577030812324) 25%, rgba(217,217,217,0.9304096638655462) 70%, rgba(255,255,255,1) 100%),url("${item?.attributes?.cover_image}")`,
                              width: "100%",
                              backgroundSize: "cover",
                              borderRadius: "15px 0px 0px 15px",
                            }}
                          />
                        </Grid>
                        <Grid
                          container
                          item
                          sm={7}
                          style={webPage.shadowproperties}
                        >
                          <Grid
                            item
                            style={{ width: "50%" }}
                            onClick={() => {
                              this.navigateEvent(item);
                            }}
                          >
                            <Typography
                              variant="h6"
                              style={{ fontWeight: 600 }}
                            >
                              {item?.attributes?.event_name}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              style={{ color: "grey" }}
                            >
                              {item?.attributes?.description}
                            </Typography>
                            {item?.attributes?.interest_event_count[0]
                              ?.account_images?.length !== 0 && (
                              <AvatarGroup
                                spacing="small"
                                style={webPage.avatarGroup}
                              >
                                {item?.attributes?.interest_event_count[0]?.account_images?.map(
                                  (img: any) => {
                                    return (
                                      <Avatar
                                        alt="Remy Sharp"
                                        style={webPage.avtarImg}
                                        src={
                                          img.photo === null ? "" : img.photo
                                        }
                                      />
                                    );
                                  }
                                )}
                                {item?.attributes?.interest_event_count[0]
                                  ?.count !== null ? (
                                  <Avatar style={webPage.avtarImg2}>
                                    +
                                    {
                                      item?.attributes?.interest_event_count[0]
                                        ?.count
                                    }
                                  </Avatar>
                                ) : (
                                  ""
                                )}
                              </AvatarGroup>
                            )}
                          </Grid>
                          <Grid item style={webPage.boxContainerdateMonth}>
                            <Typography
                              variant="subtitle2"
                              align="right"
                              style={{ paddingBottom: "0" }}
                              className="HeaderText"
                              onClick={() => {
                                this.navigateEvent(item);
                              }}
                            >
                              {moment(
                                new Date(item?.attributes?.creating_time)
                              ).format("DD")}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              align="right"
                              style={{ paddingTop: "0" }}
                              className="HeaderText"
                              onClick={() => {
                                this.navigateEvent(item);
                              }}
                            >
                              {moment(
                                new Date(item?.attributes?.creating_time)
                              ).format("MMMM")}
                            </Typography>

                            <CustomButton
                              btnStyle={
                                item?.attributes?.is_interested
                                  ? webPage.Publishbtn
                                  : webPage.InterestBtn
                              }
                              btnText={"Interested"}
                              buttonId="user-profile-add-social-publish-btn"
                              type="submit"
                              handleButtonClick={() => {
                                this.handleInterested(item?.id);
                              }}
                              data-test-id={"interested"}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                </Grid>
              </InfiniteScroll>
            )}
          </>
        )}
      </>
    );
  }
  // Customizable Area End
}


// Customizable Area Start
const webPage = {
  avatarGroup: {
    marginTop: "8px",
    marginLeft: "15px",
  },
  avtarImg: {
    marginLeft: "-15px",
    border: "1px solid white",
    width: 30,
    height: 30,
  },
  Publishbtn: {
    background: "#01548a",
    color: "#fff",
    width: "95%",
    borderRadius: "0px",
    padding: "18px",
    margin: "9px",
  },
  InterestBtn: {
    background: "#fff",
    color: "#01548a",
    width: "95%",
    borderRadius: "0px",
    padding: "18px",
    margin: "9px",
    borderColor: "#01548a",
  },

  avtarImg2: {
    marginLeft: "-15px",
    border: "1px solid white",
    backgroundColor: "#005487",
  },

  imageUserText: {
    width: "159px",
    height: "21px",
    fontSize: "17px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "start",
    color: "#fff",
    marginTop: "calc(0.5 * -87px)",
    marginLeft: "7px",
  } as const,

  videoText: {
    width: " 211px",
    height: "21px",
    marginLeft: "10px",
    fontSize: "17px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",

    color: "#808080",
  } as const,
  shadowproperties: {
    // boxShadow: "-50px 0px 50px 40px rgba(255,255,255,1)",
    backgroundColor: "white",
    justifyContent: "space-between",
    padding: "10px",
  } as const,
  horizontal: {
    width: "829px",
    height: "1px",
    margin: "20.7px 0 18px 2.5px",
    border: " solid 0.3px #808080",
    backgroundColor: "#808080",
  } as const,
  greyDot: {
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    backgroundColor: "#d9d9d9",
    display: "inline-block",
    margin: "0px 5px",
  } as const,
  cardActionIcon: {
    marginTop: "9px",
    marginRight: "9px",
    width: "20px",
    // cursor: "pointer"
  } as const,

  videoTextBold: {
    width: " 211px",
    height: "21px",
    minWidth: "45rem",
    margin: "29px 4px 15px 2.5px",
    fontSize: "17px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",

    color: "#808080",
  } as const,
  FilterTitle: {
    width: "auto",
    height: "auto",
    margin: "1px 4px 15px 2.5px",
    fontSize: "17px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    minWidth: "7rem",
    maxWidth: "32rem",
    color: "#808080",
  } as const,
  hashText: {
    width: "594px",
    height: "17px",
    margin: "23px 237.5px 20px 0",

    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",

    color: "#005487",
  } as const,
  boxContainer: {
    // backgroundImage: `url("https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg")`,
    backgroundRepeat: "no-repeat",
    cursor: "pointer",
    width: "100%",
  } as const,
  boxgrid: {
    display: "flex",
    justifyContent: "flex-end",
  } as const,
  HeadingText: {
    width: "75px",
    height: "22px",
    margin: "0 57.5px 27px 25.5px",

    fontSize: "20px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.1",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#005487",
  } as const,
  HeadingTextPost: {
    width: "75px",
    height: "22px",
    margin: "20px 57.5px 27px 25.5px",

    fontSize: "20px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.1",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#005487",
  } as const,

  TitleHeading: {
    width: "auto",
    height: "auto",
    margin: "0px 478px 16px 5px",
    fontSize: "20px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    minWidth: "19rem",
    maxWidth: "28rem",
    minHeight: "5rem",
    color: "#808080",
  } as const,
  YtHeading: {
    margin: "75px 0 0 10px",

    color: "#808080",
  } as const,
  YtHeadingUrl: {
    margin: "75px 0 0 10px",

    color: "#005487",
  } as const,
  boxContainerdateMonth: {
    backgroundColor: "white",
    width: "40%",
    margin: "10px 0px",
  } as const,
  imgStyle: {
    width: "20px",
    cursor: "pointer",
  } as const,
};
// Customizable Area End