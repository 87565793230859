Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "QuestionBank";
exports.labelBodyText = "QuestionBank Body";

exports.btnExampleTitle = "CLICK ME";
exports.apiContentType = 'application/json';
exports.PostApi="POST";
exports.GetApi="GET";
exports.deleteApi="DELETE";
exports.creatTestEnd="bx_block_questionbank/quizzes";
exports.getAllTestEnd="bx_block_questionbank/quizzes";
exports.updateTestEnd="bx_block_questionbank/quizzes/update_quiz";
exports.getQuizEnd="bx_block_questionbank/quizzes/show_quiz?quiz_id=";
exports.deleteEnd="bx_block_questionbank/quizzes/delete_question";
exports.complateTest="Completed Test";
exports.pendingTest="Pending Test";
exports.complateError="No complated test found";
exports.pendingError="No pending test found";
exports.viewTest="View Test";
exports.testTitle="Test Title*";
exports.create_test="Create a Test";
exports.testType="Test Type";
exports.anonymous="Anonymous";
exports.required="Required";
exports.question="Question";
exports.addQuestionPlaceholder="Add your Question here";
exports.doneBtn="Done";
exports.addBtn="Add More";
exports.draftBtn="Draft";
exports.submitBtn="Submit";
exports.addOption="Add Option";
exports.cancelBtn="Cancel";
exports.deleteBtn="Delete";
exports.deleteQuestion="Delete Question";
exports.deleteDescription="Are you sure you want to delete";
exports.selectedQuestion="selected question?";
// Customizable Area End