Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "scheduling";
exports.labelBodyText = "scheduling Body";
exports.btnExampleTitle = "CLICK ME";
exports.getApiMethodType = "GET";
exports.applicationJsonApiContentType = "application/json";
exports.serviceProviderAPiEndPoint = "scheduling/service_provider_schedulings/get_sp_details";
exports.calendaeEvent = "bx_block_events/events?events=daily&event_date=";
exports.eventsEnd = "bx_block_events/events"
exports.reportContent= "If you don’t want to add any reason for reporting, you can choose none option.";
exports.reportEventEndPOint='/bx_block_events/events/report_event'
exports.reportPostEnd="report_post";
exports.reportQuestion="Why are you reporting this post?";
exports.reportEventquestion="Why are you reporting this event?";
exports.share = "Share";
exports.hosted = "hosted by";
exports.PutAPiMethod ="PUT";
exports.reportPostEnd="report_post";
exports.delete_event = "bx_block_events/events";
exports.deleteApi= "DELETE";
exports.daily="Daily";
exports.weekly="Weekly";
exports.monthly="Monthly";
// Customizable Area End