// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
import { handleErrorArray } from "../../../components/src/common";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes: any;
    openModal: boolean;
    handleClose: ()=>void;
    getItem: (id:string,type: string)=>void;
    postId: string;
    info: {
        is_private: boolean;
        type: string;
        image: string;
        name: string;
      },
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean;
    description: string;
    eventModal: boolean;
    videoModal: boolean;
    imageModal: boolean;
    hyperLinkModal: boolean;
    anchorEl: HTMLElement | null;
    post_type: string;
    post_id: string;
    error: {
        description: string;
    };
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CreatePostMainModalController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    singlePostApi: string = '';
    createPostApiId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            // Customizable Area End
        ];
        this.state = {
            // Customizable Area Start
            isLoading: false,
            description: '',
            post_id: '',
            eventModal: false,
            videoModal: false,
            imageModal: false,
            hyperLinkModal: false,
            anchorEl: null,
            post_type: '',
            error:{
                description: ''
            },
            // Customizable Area End

        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount(){
        super.componentDidMount();
        if(this.props.postId){
            this.setState({post_id: this.props.postId})
            this.singlePost(this.props.postId)
        }
    }
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId1 = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson1 = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson1) {
                this.setState({ isLoading: false });
                if (apiRequestCallId1 === this.createPostApiId) {
                    if (responseJson1.data) {
                       this.handleCreateEditResponse(responseJson1)
                    } else {
                        handleErrorArray(responseJson1.errors)
                    }
                } if (apiRequestCallId1 === this.singlePostApi) {
                    this.handleResponseSinglePost(responseJson1);
                }
            }

        }
    }

    handleCreateEditResponse = (responseJson: { data: { id: string }, meta: { message: string } }) => {
        toast.success(responseJson.meta.message)
        const isNew = !this.state.post_id ? 'new' : 'edit';
        this.props.getItem(responseJson.data.id, isNew)
        this.props.handleClose()
    }

    handleResponseSinglePost = (responseJson1: {
        data: {
            attributes: {
                description: string;
            }
        }
    }) => {
        if (responseJson1.data) {
            this.setState({
                description: responseJson1.data.attributes.description
            });
        }
    }
      
    handleChangeDescrription = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ description: e.target.value })
    }

    handleVideoOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget })
      }
      handleCloseEnchor = () => {
        this.setState({ anchorEl: null })
      }
      handleOpenPhoto = () => {
        this.setState({imageModal: true})
      }
      handleOpenYTVideo = () => {
        this.setState({videoModal: true, post_type: 'YouTube', anchorEl: null});
      }
      handleOpenVimeoVideo = () => {
        this.setState({videoModal: true, post_type: 'Vimeo', anchorEl: null});
      }
      openHyperLink = () => {
        this.setState({hyperLinkModal: true})
      }
    openEvent = () => {
        this.setState({
            eventModal: true
        })
    }
    closeModal = () => {
        this.setState({description: ''})
        this.props.handleClose();
    }
    
    handlePublish = async () => {
        if(!this.state.description){
            return this.setState({error: { description: 'Enter description atleast for post'}})
        }
        this.setState({ isLoading: true });
        let signupToken = await getStorageData("SignupSuccessToken");
        let loginToken = await getStorageData("LoginSuccessToken");
        const header = {
            token: signupToken || loginToken,
        };
        const formData = new FormData();
        formData.append("post[description]", this.state.description);
        formData.append("post[post_type]", 'text');

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        this.createPostApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.state.post_id ? configJSON.editPost + this.state.post_id : configJSON.postsEnd
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            this.state.post_id ? configJSON.putApiMethode : configJSON.PostAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    singlePost = async (id: string) => {
        this.setState({ isLoading: true })
        const signupToken1 = await getStorageData('SignupSuccessToken');
        const loginToken2 = await getStorageData('LoginSuccessToken');
        const header = {
            token: signupToken1 || loginToken2
        };
        const getAlonePost = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        getAlonePost.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postsEnd + id
        );

        getAlonePost.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.get
        );

        getAlonePost.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        this.singlePostApi = getAlonePost.messageId;

        runEngine.sendMessage(getAlonePost.id, getAlonePost);
    }
    // Customizable Area End
}
