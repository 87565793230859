// Customizable Area Start
import React from "react";
import { Box, Avatar, InputAdornment, TextField, Typography, Modal, Button } from '@material-ui/core';
import SingleCommentListController, { Comment } from "./SingleCommentListController.web";
import { styled } from "@material-ui/core/styles";
import { simpleLike, smallFillLike, chat1, DarkSend, SmallCamera, CloudIcon, CloseIcon } from "./assets";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
// Customizable Area End

// Customizable Area Start

// Customizable Area End
export class SingleCommentList extends SingleCommentListController {
    // Customizable Area Start

    renderSingleComment = (comment: Comment,hasParent:boolean = this.props.hasParent) => {
        return (
            
                <CommentWrapper pl={hasParent? "0px":"40px"}>
                    <Box display={"flex"}>
                    <Avatar src={comment.attributes.profile_picture} />
                    <Box display="flex" flexDirection="column" ml={"12px"} width="100%">
                        <Box  flexDirection="column" display="flex" py={"8px"} px={"12px"} bgcolor="#f4f4f4" borderRadius="0 12px 12px 12px">
                            <Box component={"p"} fontSize="14px" m={0}  fontWeight="bold">{comment.attributes.full_name}</Box>
                            <div className="dynamic-font" dangerouslySetInnerHTML={{ __html: this.replaceUrl(comment.attributes.body) }}></div>
                            {comment.attributes.comment_media.length > 0 && <Box pt={"8px"}>
                                <img style={{ width: "200px", height: "200px", borderRadius: "10px" }} src={comment.attributes.comment_media[0].file} alt="comment" />
                            </Box>}
                        </Box>
                        <Box display={"flex"} fontSize={"13px"}  alignItems={"center"} justifyContent={"space-between"}>
                            <Box component={"p"} display={"flex"} alignItems={"center"} className="gap" m={0} mt="5px">{this.showLike(comment)}</Box>
                            <Box component={"p"} mt="5px"  m={0} className="pointer" onClick={()=>this.expandComment(comment.id,hasParent)}>{this.showComment(comment)}</Box>
                        </Box>
                        <Box fontSize={"13px"} display={"flex"} alignItems={"center"} py={"5px"}  className="gap">
                            <Box component={"p"} m={0}>{this.commentTime(comment.attributes.created_at)}</Box>
                            <FiberManualRecordIcon style={{ width: "6px" }} />
                            {comment.attributes.comment_liked_by_login_user ? <img src={smallFillLike} alt="smallFillLike" className="pointer" onClick={()=>this.handleLikeComment(comment.id)} data-test-id="like-action"/> : <img src={simpleLike} alt="simpleLike" className="pointer" data-test-id="like-action" onClick={()=>this.handleLikeComment(comment.id)} />}
                            <img src={chat1} className="pointer" alt="chat1" data-test-id="expand-id" onClick={()=>this.expandComment(comment.id,hasParent)} />
                        </Box>
                    </Box>
                    </Box>
                    {!hasParent&&this.state.expandId===comment.id&&this.renderSubCommentField()}
                </CommentWrapper>
            
        )
    }

    renderHideShow = () => {
        const text = this.state.showAll ? "Hide comments" : "View all comments";
        return (
            <Box component={"p"} data-test-id="hide-show-id" onClick={this.handleShowAll} className="pointer" fontFamily="Verdana" fontSize={"12px"} pl={"40px"}>{text}</Box>
        )
    }

    renderNestedComments = (comment: Comment) => {
        return (
            <Box display={"flex"} boxSizing={"border-box"} flexDirection={"column"} width="100%" key={`commentid-${comment.id}`} >
                {this.renderSingleComment(comment)}
                {this.props.openChildrenId === comment.id &&
                <>
                    {comment.attributes.child_comment.data.length > 0 &&
                    comment.attributes.child_comment.data.map((item: Comment, i: number) => {
                        if (!this.state.showAll && i > 0) {
                            return null;
                        }
                        return (
                            <div key={item?.id}>
                                {(i === 0 && comment.attributes.child_comment.data.length > 1) && this.renderHideShow()}
                                {this.renderSingleComment(item, false)}
                            </div>
                        );
                    })}
                    {(!this.state.expandId && this.state.parentFiledOpen) && this.renderSubCommentField()}
                </>
                }
            </Box>
        );
    };
    
    renderSubCommentField = () => {
        return (
            <FieldWrapper borderTop="1px solid #808080" py="15px" px="24px" display="flex" alignItems="center" sx={{ width: "100%", boxSizing: "border-box" }}>
                <Box display="flex" bgcolor="#eeeeee" alignItems="center" justifyContent="space-between" sx={{ borderRadius: "50px", width: "100%", overflow: "hidden" }}>
                    <TextField
                        placeholder="Add comment"
                        size="small"
                        type="text"
                        name="comment"
                        data-test-id="comment-id"
                        style={{
                            borderRadius: "12px",
                            backgroundColor: "#eee",
                            padding: "8px 10px",
                            color: "#808084",
                            width: "100%"
                        }}
                        value={this.state.subCommentMessage}
                        onChange={this.handleChangeSubComment}
                        multiline
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <Box style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                                    <InputAdornment position="end">
                                        <img src={SmallCamera} alt="SmallCamera" className="pointer" onClick={() => this.setState({ openImgModel: true })} data-test-id="camera-icon-id" />
                                    </InputAdornment>
                                </Box>
                            ),
                        }}
                    />
                </Box>
                <Box data-test-id="send-id" className="pointer" component="div" sx={{ marginLeft: "10px" }} display="flex" justifyContent="center" alignItems="center" onClick={this.sendSubComment}>
                    <img src={DarkSend} alt="DarkSend" />
                </Box>
            </FieldWrapper>
        )
    }

    renderModal = () => {
        return(
            <ModalWrpper
                onClose={this.handleClose}
                open={this.state.openImgModel}
                aria-labelledby="image-modal"
                aria-describedby="image-modal-description"
            >
                <Box  alignItems="center" display="flex" justifyContent="center" sx={{ width: "100%", height: "100%" }}>
                    <Box sx={{ width: { xs: "80%", md: "50%", lg: "40%" }, borderRadius: "12px" }} bgcolor="#fff">
                        <Box display="flex" justifyContent="space-between" alignItems="center"  color="#808084" p="20px" sx={{ borderBottom: "1px solid #808080" }}>
                            <Typography variant="subtitle1" className="box-title">{"Select Image"}</Typography>
                            <img src={CloseIcon} alt="CloseIcon" className="pointer" onClick={this.handleClose}/>
                        </Box>
                        <Box  justifyContent="space-between" display="flex" alignItems="center" sx={{ borderBottom: "1px solid #808080" }}>
                            <Box p="20px" sx={{ borderBottom: "1px solid #005487" }} color="#005487">{"Upload"}</Box>
                        </Box>
                        {this.state.imageUrl ?
                            <Box component="div" display="flex" alignItems="center" justifyContent="center" flexDirection="column" sx={{ height: "246px" }}>
                                <img src={this.state.imageUrl} alt="selectedimg" className="selected-img" />
                            </Box>
                            : <Box component="div" display="flex" data-test-id="drop-id" alignItems="center" flexDirection="column" justifyContent="center" sx={{ height: "246px" }}
                                onDrop={(e) => {
                                    this.handleOnImportFile(e.dataTransfer.files);
                                    e.preventDefault();
                                }}
                                onDragOver={e => e.preventDefault()}>
                                <input type='file' id="fileInput" className='input-hide' data-test-id="fileInput" 
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.handleOnImportFile(e.target.files!);
                                        e.preventDefault();
                                    }}
                                    accept="image/jpg, image/png, image/jpeg,"
                                />
                                <img src={CloudIcon} alt="CloudIco" />
                                <Button
                                    className={"uploadBtn"}
                                    type="button"
                                    data-test-id="user-add-btn"
                                    onClick={this.handleOpenUploadBtn}
                                >Browse</Button>
                                <Typography align="center" variant="subtitle2" className="drag-text">Or drag a file here</Typography>
                            {this.state.error && <Box component="p" color="red">{this.state.error}</Box>}
                            </Box>}
                        <Box display="flex" p="20px" justifyContent="flex-end"  sx={{ borderTop: "1px solid #808080" }}>
                            <Button
                                data-test-id="user-publish-btn"
                                className={"Publishbtn"}
                                onClick={this.handleCloseImgModel}
                                type="submit"
                            >Done</Button>
                        </Box>
                    </Box>
                </Box>
            </ModalWrpper>
        )
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        return (<>
            {this.renderModal()}
            {this.renderNestedComments(this.props.data)}
        </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start

const CommentWrapper = styled(Box)({
    "& .gap": {
        gap: "8px"
    },
    "& .pointer": {
        cursor: "pointer"
    },
    "& .dynamic-font": {
        margin: 0,
        marginTop: "7px",
        fontSize: "14px"
    }
});
const FieldWrapper = styled(Box)({
    "& .pointer": {
        cursor: "pointer"
    }
});
const ModalWrpper = styled(Modal)({
    "& .input-hide": {
        display: "none",
    },
    "& .drag-text": {
        fontSize: "17px",
        fontWeight: "normal",
        fontFamily: "Verdana",
        fontStyle: "normal",
        color: "#808084",
        fontStretch: "normal",
    },
    "& .selected-img": {
        objectFit: "cover",
        height: "90%",
        aspectRatio:"1/1",
    },
    "& .box-title":{
        fontFamily:"Verdana",
        fontSize:"20px",
    },
    "& .Publishbtn": {
        width: "160px",
        height: "48px",
        color: "#fff",
        padding: "15px 56px 14px 57px",
        border: "solid 1px #005487",
        margin: "23px 7px 0 38px",
        marginTop: "8px",
        backgroundColor: "#01548a",
    },
    "& .uploadBtn": {
        color: "#fff",
        width: "160px",
        height: "48px",
        margin: "15px",
        border: "solid 1px #005487",
        backgroundColor: "#01548a",
        padding: "15px 56px 14px 57px",
    },
});
export default SingleCommentList;
// Customizable Area End