import { BlockComponent } from "../../framework/src/BlockComponent";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
// Customizable Area Start

// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes?: any;
    onClose: any;
    open: any;
    handleSubmit: any;
    title: any;
    description: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    data: {}
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
// Customizable Area Start
export default class ConfirmationModalController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiCreatePostCallId: any
    // Customizable Area End
    constructor(props: Props) {
        /*istanbul ignore next */
        super(props);
        // Customizable Area Start
        /*istanbul ignore next */
        this.state = {
            data:{}
        }
        // Customizable Area End
    }
 
       /*istanbul ignore next */
    async receive(_from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        console.log("apiRequestCallId", apiRequestCallId)

        // Customizable Area End
    }

}
// Customizable Area End