// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import ApiCall from "../../../components/src/ApiCall.web";

// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  data: any;
  arrayHolder: any;
  filterItem: any;
  renderFilter: any;
  loading: any;
  filteredUser: string;
  currentRoute: any;
  Interested: boolean;
  ID: string;
  Fav: string;
  newPostListCount: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FilteritemsNewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductApiCallId: any;
  interestedEventApiCallId: any;
  interestedSingleApiCallId: any;
  listFilterId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      data: "",
      arrayHolder: ["GOPAL"],
      filterItem: "",
      currentRoute: window.location.href,
      renderFilter: [],
      filteredUser: "",
      loading: true,
      newPostListCount: [],
      Interested: false,
      ID: "",
      Fav: "",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.interestedSingleApiCallId) {
      if (responseJson?.data) {
        const updatedList = [...this.state.renderFilter];
        const index = updatedList.findIndex((abc) => abc.id === this.state.Fav);

        const removedElement = updatedList[index];

        // Step 3: Modify the inner object
        removedElement.attributes.is_interested = !removedElement.attributes
          .is_interested;

        this.setState({ renderFilter: updatedList });
        // this.getApiRequest()
        toast.success(responseJson.data[0].message);
      } else {
        toast.error(responseJson.errors[0].message);
      }
    }
    if (apiRequestCallId === this.listFilterId) {
      if (!responseJson?.error) {
        this.setState({
          renderFilter: [...this.state.renderFilter, ...responseJson?.data],
          loading: false,
          newPostListCount: responseJson?.data,
        });

        console.log(responseJson?.data);
        if (responseJson?.data?.length == 0) {
          toast.error("No Result Found");
        }
        localStorage.setItem("filterUser", "");
      } else {
        this.setState({ renderFilter: [] });
        toast.error(responseJson?.error[0].message);
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start

  componentDidMount = async () => {
    console.log(window.location.href, "---121212---");
    this.FilterItemFunction(false);
    localStorage.setItem("filterUser", "");
    this.setState({ filterItem: this.props.navigation.getParam("id") ?? "" });
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapShot?: SS | undefined
  ): void {
    // Check if the route has changed
    const prevValue = this.props.navigation.getParam("id");
    const newValue = prevProps.navigation.getParam("id");
    if (prevValue !== newValue) {
      this.setState({ filterItem: prevValue, loading: true, renderFilter: [] });
      this.FilterItemFunction(true);
    }
  }

  handleBack = () => {
    this.props.navigation.navigate("LandingScreen");
  };

  interestedEventpostapi = async () => {
    let formdata = new FormData();
    formdata.append("event_id", this.state.ID);
    formdata.append("is_interested", JSON.stringify(this.state.Interested));
    this.interestedEventApiCallId = await ApiCall({
      method: "POST",
      endPoint: `/bx_block_events/events/event_make_interested`,
      body: formdata,
      type: "formData",
    });
  };
  interestedsingleEvent = async (item: any) => {
    this.setState({ Fav: item });

    let formdata = new FormData();
    formdata.append("event_id", item);
    formdata.append("is_interested", JSON.stringify(this.state.Interested));

    this.interestedSingleApiCallId = await ApiCall({
      method: "POST",
      endPoint: `/bx_block_events/events/event_make_interested`,
      body: formdata,
      type: "formData",
    });
  };
  handleInterested = (id: any) => {
    this.setState({ Interested: !this.state.Interested }, () => {
      this.interestedsingleEvent(id);
    });
  };

  navigatePosts = (items: any) => {
    this.props.navigation.navigate("SinglePost", { id: items?.id });
    const msg1 = new Message(getName(MessageEnum.NavigationPostMessage));
    msg1.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg1.addData(getName(MessageEnum.UrlPathParamMessage), items?.id);
    this.send(msg1);
  };

  navigateEvent = (items: any) => {
    this.props.navigation.navigate("CalendarEventDetails", { id: items?.id });
  };
  FilterItemFunction = async (flag: boolean) => {
    let Page_Limit = 10;

    let Page_size = flag
      ? 1
      : Math.ceil(this.state.renderFilter.length / Page_Limit) + 1;

    let signupToken = await localStorage.getItem("SignupSuccessToken");
    let loginToken = await localStorage.getItem("LoginSuccessToken");

    const header = {
      token: signupToken || loginToken,
    };

    const filteredUser = localStorage.getItem("filterUser")
      ? localStorage.getItem("filterUser")
      : "";
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.listFilterId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_filter_items/filtering/add_filter_on_search?filter_type=${this.props.navigation.getParam(
        "id"
      )}&search=${filteredUser}&page=${Page_size}&per_page=${Page_Limit}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    console.log(requestMessage, "1212121---1212121");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
