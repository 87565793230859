// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import {Modal, Box, Button, FormHelperText} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CreatePostImageModalController, { Props } from "./CreatePostImageModalController.web";
import { CloseIcon, CloudIcon } from "./assets";
import CreatePostFinalModalWeb from "./CreatePostFinalModal.web";
// Customizable Area End

// Customizable Area Start
const Poststyles: any = (theme: Theme) =>
    createStyles({
        avatar: {
            width: "50px",
            height: "50px",
            objectFit: "contain"
        },
        pointer: {
            cursor: "pointer"
        },
        uploadBtn: {
            color: "#fff",
            height: "48px",
            width: "160px",
            padding: "15px 56px 14px 57px",
            margin: "15px",
            border: "solid 1px #005487",
            backgroundColor: "#01548a",
            textTransform: "none",
            fontWeight: 'bold',
            "&:hover": {
                backgroundColor: "#005487",
            },
        },
        inputHide: {
            display: "none"
        },
        PublishbtnExpand: {
            fontWeight: "bold",
            color: "#fff",
            fontFamily: "Verdana",
            backgroundColor: "#005487",
            textTransform: "none",
            fontSize: "16px",
            width: "160px",
            height: "48px",
            "&:hover": {
                backgroundColor: "#005487",
            },
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        heightResponsive: {
            maxHeight: "85%",
            overflowY: "auto",
        },
    })
// Customizable Area End

export class CreatePostImageModal extends CreatePostImageModalController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={this.props.openModal}
                closeAfterTransition
                className={this.props.classes.modal}
                BackdropComponent={Backdrop}
                onClose={this.props.closeModal}
                BackdropProps={{
                    timeout: 200,
                }}
            >
                <Fade in={this.props.openModal}>
                        <Box bgcolor={"#fff"} sx={{ width: { xs: "80%", md: "70%", lg: "50%" }, maxWidth: "604px" }} borderRadius="12px" className={this.props.classes.heightResponsive}>
                            <Box display={"flex"} justifyContent={"space-between"} borderBottom={"1px solid #808080"} p={"24px"} >
                                <Box component={"h4"} fontSize={"20px"} fontFamily={"Verdana"} m={0} fontWeight={"bold"} color={"#808080"} data-test-id="title">
                                    Select Image
                                </Box>
                                <img src={CloseIcon} alt="close" className={this.props.classes.pointer} onClick={this.props.closeModal} />
                            </Box>
                            <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ borderBottom: "1px solid #808080", width: "100%" }}>
                                <Box py="20px" sx={{ borderBottom: "1px solid #005487" }} textAlign={"center"} color="#005487" width={"170px"}>{"Upload"}</Box>
                            </Box>
                            <Box component="div" display="flex" data-test-id="drop-id" flexDirection="column" alignItems="center" justifyContent="center" sx={{ height: "246px" }}
                                onDrop={(e) => {
                                    this.handleOnImportFile(e.dataTransfer.files)
                                    e.preventDefault();
                                }}
                                onDragOver={e => e.preventDefault()}>
                                <input type='file' data-test-id="fileInput" id="fileInput" className={this.props.classes.inputHide}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.handleOnImportFile(e.target.files!)
                                        e.preventDefault();
                                    }}
                                    accept="image/png, image/jpeg, image/jpg,"
                                />
                                <img src={CloudIcon} alt="Cloud" />
                                <Button
                                    type="button"
                                    data-test-id="upload-btn"
                                    className={this.props.classes.uploadBtn}
                                    onClick={this.handleOpenUploadBtn}
                                >Browse</Button>
                                <Box component="p" fontSize={"17px"} m={0} color="#808080" textAlign="center" data-test-id="img-text">Or drag a file here</Box>
                            </Box>
                            {this.state.error.images_and_videos && (
                                <FormHelperText className={this.props.classes.error}>
                                    {this.state.error.images_and_videos}
                                </FormHelperText>
                            )}
                            <Box display="flex" justifyContent="end" alignItems={"end"} mt={"20px"} borderTop={"1px solid #808080"} p={"23px"}>
                                <Button variant="contained" type="submit" data-test-id="publish-btn" onClick={this.handleDone} className={this.props.classes.PublishbtnExpand}>Done</Button>
                            </Box>
                        </Box>
                </Fade>
            </Modal>
            {this.state.editModal && <CreatePostFinalModalWeb restrict data={this.state.data} selectedFile={this.state.selectedFile} finalPost={this.state.editModal} getItem={this.props.getItem} postId={this.props.postId} navigation={this.props.navigation} id={''} handleClose={this.closeModal} info={this.props.info}/>}
            </>
            // Customizable Area End
        )
    }
}

// Customizable Area Start

export default withStyles(Poststyles, { withTheme: true })(CreatePostImageModal);
// Customizable Area End