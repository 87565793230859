import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    uploadedImage?:any;
    id?: string;
    classes: any;
    openModal: boolean;
    postId: string;
    closeModal: ()=>void;
    description: string;
    getItem: (id: string, type: string) => void;
    info: {
        name: string;
        type: string;
        is_private: boolean;
        image: string;
      },
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean;
    data: {
        images_and_videos : string[];
        description: string;
    },
    error: {
        images_and_videos : string;
    },
    selectedFile: File | null;
    editModal: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
export default class CreatePostImageModalController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            // Customizable Area End
        ];
        this.state = {
            // Customizable Area Start
            isLoading: false,
            data: {
                images_and_videos : [],
                description: ''
            },
            selectedFile: null,
            error: {
                images_and_videos : '',
            },
            editModal: false,
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount(){
        super.componentDidMount();
        this.setState({
            data: {...this.state.data,description:this.props.description}
        })
    }

    handleOnImportFile = (list: FileList) => {
        if (list.length > 1) return this.setState({ error: { ...this.state.error, images_and_videos: 'Select only one image' } })
        let extension = list[0].name.split('.').pop() as string;
        if (!["png", "jpg", "jpeg"].includes(extension)) return

        this.setState({
            selectedFile: list[0],
            data:{...this.state.data,images_and_videos: [URL.createObjectURL(list[0])]},
            editModal: true,
        })
    }

    handleOpenUploadBtn = () => {
        document.getElementById("fileInput")?.click()
    }
    handleDone = () => {
        if(this.state.data.images_and_videos.length === 0){
            return this.setState({error: {images_and_videos: 'Select post image'}})
        }
    }
    closeModal = () => {
        this.setState({editModal: false})
        this.props.closeModal();
    }
    // Customizable Area End
}
