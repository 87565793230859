import React from "react";
// Customizable Area Start
import { Box } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import "./MyEvent.web.css";
import { iconsrightarrow, calendar, iconsstar,iconsleftarrow, colorstar,blankProfile } from "./assets";
import moment from "moment";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import MyEventController, { Props } from "./MyEventController.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End


export default class MyEvent extends MyEventController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  commonEventHeader = () => {
    return (<>
      <div className="MyEvents">
        <div className="myEvents-main">
          <div className="myEvent-right-section">
            <div>
              <div className="myEvent-right-section-heading-section">
                <div className="heading-section" >
                  <img data-test-id="eventGoBack" src={iconsleftarrow} alt="" onClick={()=>this.eventGoBack()}/>
                  <h2>{!this.state.interest_Event ? "My Events" : "Interested Event"}</h2>
                </div>
                {
                  <img data-test-id="handlestarButton" onClick={this.handleStar_button} src={!this.state.interest_Event ? iconsstar : colorstar} alt="" className="star-img" />}

              </div>
              <div className="myEvent-tab-nav">
                <div
                data-test-id= "handle _upcomming_button"
                  className={
                    this.state.tab
                      ? " myevent-tab-item-active"
                      : "myevent-tab-item"
                  }
                  onClick={this.handleUpcommingEvent}
                >
                  <h3
                    className={
                      this.state.tab
                        ? " myevent-tab-item-heading-active"
                        : "myevent-tab-item-heading"
                    }
                  >
                    Upcoming Events
                  </h3>
                </div>
                <div
                data-test-id= "handle_past_button"
                  className={
                    this.state.tab
                      ? "myevent-tab-item"
                      : " myevent-tab-item-active"
                  }
                  onClick={this.handlePastEvent}
                >
                  <h3
                    className={
                      this.state.tab
                        ? "myevent-tab-item-heading"
                        : "myevent-tab-item-heading-active"
                    }
                  >
                    Past Events
                  </h3>
                </div>
              </div>
              <div className="myevents-showAllEvents">
                {this.renderMyEventsCard()}
              </div>
            </div>

            <div className="create-new-event-container" onClick={this.handleNavigate} data-test-id="userlandindScreen">
              <img src={calendar} alt="" />
              <h5>Create an event</h5>
              <img src={iconsrightarrow} alt="" />
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }
  
  renderMyEventsCard = () => {
    return (<>
      {this.state.upCommingEvent.map((item: any, index: number) => {
        const start_date = item?.attributes?.start_date;
        const start_time = item?.attributes?.start_time;
        return (
          <div key={index}>
            <div className="myevents-card" data-testid="single-event" onClick={()=>this.eventNavigation(item?.id)}>
              <div className="myevents-card-img-container">
                <img src={item.attributes?.cover_image} alt="image" />
                <div className="shadow-box"></div>
              </div>
              <div className="myevents-card-details-container">
                <div className="card-details-top-section">
                  <div className="eventName-publication-container">
                    <h1>{item.attributes?.event_name}</h1>
                    <h3>{item.attributes?.account.data?.attributes.full_name}</h3>
                  </div>
                  <div className="eventDate">
                    <h5 className="date">{moment(new Date(item.attributes.start_date)).format("DD")}</h5>
                    <h5 className="month">{moment(new Date(item.attributes.start_date)).format("MMMM")}</h5>
                  </div>
                </div>
                <div className="card-details-bottom-section">
                
                  <div className="eventCard-photos-container">
                    {item?.attributes.interest_event_count[0].account_images.map((img: any,index:number) => {
                    return(
                      <>
                      <span className="circle-item" key={index}>
                        <img src={img.photo=== null? blankProfile:img.photo} alt="" />
                      </span>
                      </>
                      )
                    })}
                    {item?.attributes.interest_event_count[0].count!==null?
                    <span className="circle-item last-item">+{item?.attributes.interest_event_count[0].count}</span>:""}
                  </div>
                  <div
                    className=
                    {this.getEventTimeClass(start_date,start_time)}
                  >


                    <span className="time">{moment(item.attributes?.start_time, "HH:mm:ss").format('hh:mm A')} - {moment(item.attributes?.end_time, "HH:mm:ss").format('hh:mm A')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )


      })
      }
    </>
    );
  };
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      // Customizable Area Start
      <Box width={"100%"} className="main_event_box">
        {this.state.loading && <Loader loading={this.state.loading} />}
        {this.commonEventHeader()}
      </Box>
      // Customizable Area End
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "300px",
      borderRadius: "12px",
    },
  },
};

// Customizable Area End
