// Customizable Area Start
import React from "react";
import {  Box, Fade, Modal, Backdrop, TextField, InputAdornment, Button } from '@material-ui/core';
import AddSocialMediaLinkModalController, { Props } from "./AddSocialMediaLinkModalController.web";
import { styled } from "@material-ui/core/styles";
import { CrossLink } from "./assets";
// Customizable Area End

export default class AddSocialMediaLinkModal extends AddSocialMediaLinkModalController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <SocialModal
                open={this.props.open}
                closeAfterTransition
                onClose={this.handleClose}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 200
                }}
            >
                <Fade in={Boolean(this.props.open)}>
                    <Box sx={{ width: { xs: "75%", sm: "60%", md: "50%", lg: "35%" } }} className="fadeContainer" display="flex" alignItems="center" justifyContent="center" >
                        <Box bgcolor="#fff" borderRadius="12px" width="100%">
                            <Box sx={{ border: "1px solid black", margin: "20px", padding: "20px", borderRadius: "12px " }}>
                                {this.props.data.type === "delete" ?
                                    <>
                                        <Box component="p" color="#005487" fontFamily={"Verdana"} fontWeight={"bold"} fontSize={"17px"} mt={0}>
                                            Delete Social Media Link
                                        </Box>
                                        <Box sx={{ height: "385px" }} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                                            <Box component="p" color="#808080" fontFamily={"Verdana"} fontSize={"17px"} m={0}>
                                                Are you sure you want to delete Social Media Link?
                                            </Box>
                                            <Box sx={{ flexDirection: { xs: "column", md: "row" } }} alignItems="center" display="flex" justifyContent="space-around">
                                                <Button
                                                    type="submit"
                                                    data-test-id="yes-id"
                                                    className="Publishbtn"
                                                    onClick={this.handleDeleteLink}
                                                >Yes</Button>
                                                <Button
                                                    className="Publishbtn"
                                                    data-test-id="no-id"
                                                    type="button"
                                                    onClick={this.handleClose}
                                                >No</Button>
                                            </Box>
                                        </Box>
                                    </> :
                                    <><Box component="p" color="#005487" fontFamily={"Verdana"} fontWeight={"bold"} fontSize={"14px"} mt={0}>
                                        Social Media Account URL
                                    </Box>
                                        <Box sx={{ height: "400px" }} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                                            <Box>
                                                <TextField
                                                    data-testid="link"
                                                    variant="outlined"
                                                    size="medium"
                                                    placeholder="Add or paste URL here"
                                                    name="link"
                                                    type="text"
                                                    value={this.state.link}
                                                    onChange={this.handleChangeField}
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start" >
                                                                <img src={CrossLink} alt="link" />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Box>
                                            <Box sx={{ width: "100%" }}>
                                                <Button className="done-btn" data-testid="done-btn" onClick={this.handleSaveLink}>Done</Button>
                                            </Box>
                                        </Box>
                                    </>}
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </SocialModal>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
const SocialModal = styled(Modal)({
    "& .fadeContainer": {
        backgroundColor: "transparent",
        margin: "auto",
        display: "flex",
        transform: "translate(-50%, -50%)",
        left: "50%",
        top: "50%",
        position: "absolute",
    },
    "& .done-btn": {
        backgroundColor: "#005487",
        width:"100%",
        fontWeight:"bold",
        fontSize:"16px",
        color: "#fff",
        fontFamily: "Verdana",
        height:"56px",
        textTransform: "none",
      },
      "& .Publishbtn": {
        height: "50px",
        textTransform: "none",
        color: "#01548a",
        border: "solid 1px #005487",
        width: "40%",
        marginTop: "8px",
        backgroundColor: "#fff",
        fontWeight: "bold",
        "&:active": {
            backgroundColor: "#01548a",
            color: "#fff",
        },
    },
})
// Customizable Area End