// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const CheckboxCheckIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" />
            <path d="M8.29066 12.4652L6.0831 10.2577C5.83499 10.0095 5.43419 10.0095 5.18608 10.2577C4.93797 10.5058 4.93797 10.9066 5.18608 11.1547L7.84533 13.8139C8.09344 14.062 8.49423 14.062 8.74235 13.8139L15.4732 7.0831C15.7213 6.83499 15.7213 6.43419 15.4732 6.18608C15.225 5.93797 14.8243 5.93797 14.5761 6.18608L8.29066 12.4652Z" />
        </SvgIcon>
    );
}

export default CheckboxCheckIcon;
// Customizable Area End