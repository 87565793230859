import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import { setStorageData , getStorageData } from "../../../framework/src/Utilities"
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    onComplete: (otp: string) => void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    full_phone_number: any,
   
    show: boolean,
    otp: string[];
    error: boolean;
    FieldError: any;
    errorMsg: string,
    otpError: string,
    otpTimer: any;
    isDisabled:boolean;

    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

/*istanbul ignore next */
export default class PhoneVerificationController extends BlockComponent<
    Props,
    S,
    SS
> {
// Customizable Area Start
    createAccountApiPhoneId: any
    apiVerifyotpCallId: any
// Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.verifyOtp = this.verifyOtp.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            // Customizable Area End
        ]
        this.state = {
            full_phone_number: "",
            errorMsg: "",
            show: false,
            otp: ['', '', '', ''],
            error: false,
            FieldError: "",
            otpError:"",
            otpTimer:60,
            isDisabled:false,
    

        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
        // Customizable Area End
    }


// Customizable Area Start

    handleChange = (e: any) => {
        const regex = /^[0-9\b]+$/;

       
        this.setState({errorMsg: ""})
        this.setState({FieldError:""})
        if (e.target.value === "" || regex.test(e.target.value)) {

            this.setState({ full_phone_number: e.target.value })
        }
    };


    handleOTPChange = (e: any, index: number) => {
        this.setState({otpError:"",FieldError:""})
        const otp = [...this.state.otp];
        otp[index] = e.target.value;
        this.setState({ otp });
        const inputs = document.querySelectorAll('input[type="number"]');

        if (e.target.value.length === 1) {
            const nextInput = inputs[index + 1];
            if (nextInput) {
             (nextInput as HTMLElement).focus();
            }}

    };

    handleKeydown=(e:any, ind:any)=>{
        const inputs = document.querySelectorAll('input[type="number"]');
        if (e.target.value.length === 0 && e.key==="Backspace") {
            const nextInput = inputs[ind - 1];
            if (nextInput) {
             (nextInput as HTMLElement).focus();
            }}
    }

    async receive(_from: string, message: Message) {
        // Customizable Area Start
        console.log("mobo")
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        let errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );


        if (apiRequestCallId === this.createAccountApiPhoneId) {
            if (!responseJson.errors) {
                this.startTimer();
                console.log("myresponse.........", responseJson)
                this.setState({ show: true,errorMsg:"",FieldError:""})
                setStorageData("mobiletoken", responseJson.meta.token)


            } else {
                this.parseApiErrorResponse(errorReponse);

                this.setState({ FieldError: responseJson.errors[0]?.phone || responseJson.errors[0]?.full_phone_number })
            }
        }

        if (apiRequestCallId === this.apiVerifyotpCallId) {
            if (!responseJson.errors) {
                this.props.navigation.navigate('UserProfileInformation');
            } else {
                this.setState({ FieldError: responseJson.errors[0]?.message || responseJson.errors[0]?.pin })
            }
        }

        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const otpAuthTkn = message.getData(
                getName(MessageEnum.AuthTokenDataMessage)
            );
            if (otpAuthTkn && otpAuthTkn.length > 0) {

                runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
            }
        }


        // Customizable Area End
    }

    startTimer = () => {
        // Disable the button to prevent multiple clicks
             this.setState({ isDisabled: true });
   let timerInterval = setInterval(() => {
             this.setState((prevState) => ({  otpTimer: prevState.otpTimer - 1,}));
 if (this.state.otpTimer < 0) {
               this.setState({
              otpTimer: 60,
              isDisabled: false   });
  clearInterval(timerInterval);
          }
        }, 1000);
      };
       
  handleSubmit = (e: any) => {
              e.preventDefault();
  this.setState({ otpTimer: 60})
if(!this.state.full_phone_number)
   {
 this.setState({errorMsg: "Please Enter Phone number"})
      }
if(this.state.full_phone_number)
{
    const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
    };
 const attrs = {
        full_phone_number: this.state.full_phone_number
    };
 const data = {
        //   type: "email_account",
        attributes: attrs,
    };
const httpBody = {
        data: data,
    };
const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
  );
this.createAccountApiPhoneId = requestMessage.messageId;
requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.PhoneVerficationEndPointWeb,

    );
requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header),);
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody),

    );
requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail,);console.log("message", requestMessage)
runEngine.sendMessage(requestMessage.id, requestMessage);
return true;
}

    }

    verifyOtp = async (e: any) => {
   
        e.preventDefault();
        let signupToken = await getStorageData('SignupSuccessToken');
        let mobileToken = await getStorageData('mobiletoken');

if(this.state.otp[0]==='' || this.state.otp[1]==='' || this.state.otp[2]==='' || this.state.otp[3]==='')
{
    this.setState({FieldError:""})
    this.setState({otpError:"Enter 4 digit OTP"})
}

   if(this.state.otp[0]!=='' && this.state.otp[1]!=='' && this.state.otp[2]!=='' && this.state.otp[3]!==''){ 
    
    this.setState({otpError:""})
        const header = {
            "Content-Type": "application/json",
            "token": signupToken || mobileToken
        };
        const httpBody = {
            token: mobileToken,
            pin: this.state.otp.join('')
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiVerifyotpCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.phoneOTPEndpointWeb
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            // configJSON.loginAPiMethod,
            "POST"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;}
    }

// Customizable Area End


}