import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { handleErrorArray } from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  resetData: ()=>void;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  showLoading: boolean;
  information: {
    name:string,
    description : string,
    id: string,
    email: string,
    phone_number: number,
    website: string,
    profile_pic: string,
    background_profile_pic: string | null,
    follower_count: number,
    is_followed_by_user: boolean
  };
  errors: {
    name:string,
    email: string,
    website: string,
    profile_pic: string,
  };
  selectedFile: File | null;
  creation: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LinkedAccountsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetComunityId: string = '';
  apiCommunityUpdate: string = '';
  apiCreateCommunityId: string = '';
  apiSwitchTokenId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      showLoading: false,
      information: {
        name:'',
        description : '',
        id: '',
        email: '',
        phone_number: NaN,
        website: '',
        profile_pic: '',
        background_profile_pic: null,
        follower_count: 0,
        is_followed_by_user: false
      },
      errors: {
        name:'',
        email: '',
        website: '',
        profile_pic: '',
      },
      creation: false,
      selectedFile: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId === this.apiGetComunityId) {
      if (responseJson) {
        this.getCommunityResponse(responseJson)
      }
    }
    if(apiRequestCallId === this.apiCommunityUpdate) {
       responseJson && this.communityUpdateResponse(responseJson)
    }
    if(apiRequestCallId === this.apiCreateCommunityId) {
      responseJson && this.communityCreateResponse(responseJson)
    }
    if(apiRequestCallId === this.apiSwitchTokenId) {
      responseJson && this.switchTokenResponse(responseJson)
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    const creation = await this.props.navigation.getParam("navigationBarTitleText");
    if (creation === "creation") {
      this.setState({creation:true})
      this.GetCommunityInfo();
    }else{
      this.GetCommunityInfo();
    }
  }

  GetCommunityInfo = async () => {
    this.setState({ showLoading: true })
    let signupToken = await getStorageData('SignupSuccessToken');
    let loginToken = await getStorageData('LoginSuccessToken');
    const header = {
      token: signupToken || loginToken,
      "Content-Type": configJSON.apiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetComunityId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.get_community + '?is_post_invisible=true'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCommunityResponse = (responseJson: {
    data: {
      id: string
      type: string,
      attributes: {
        name: string,
        email: string,
        phone_number: number,
        website: string,
        description: string,
        account_id: number,
        is_notification_available: boolean,
        profile_pic: string,
        background_profile_pic: null | string,
        post_count: number,
        follower_count: number,
        is_followed_by_user: boolean
      }
    }
  }) => {
    this.setState({showLoading:false})
    if(responseJson.data){
      const { name, email, phone_number, website, description, profile_pic, background_profile_pic, follower_count, is_followed_by_user } = responseJson.data.attributes;
      this.setState({ showLoading: false, information: { id: responseJson.data.id, name, email, website, phone_number, description, profile_pic, background_profile_pic, follower_count, is_followed_by_user } });
    }
  }

  communityCreateResponse = (responseJson: {
    data: {
      id: string
      type: string,
    },error: {message:string[]}[]
  }) => {
    this.setState({showLoading:false})
      if(responseJson.data){
        this.switchToken()
      }else if (responseJson.error) {
        handleErrorArray(responseJson.error)
      }
    }

  switchTokenResponse = (responseJson: { token: string, token_type: string }) => {
    this.setState({showLoading:false})
    if (responseJson.token) {
      const isCommunity = responseJson.token_type === "account" ? "false" : "true";
      localStorage.setItem("LoginSuccessToken", responseJson.token)
      localStorage.setItem("community", isCommunity);
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "LinkedAccounts");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
      this.setState({creation:false});
      this.GetCommunityInfo();
      this.props.resetData();
    }
  }

  editRedirection = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "LinkedAccounts");

      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(
        getName(MessageEnum.NavigationScreenNameMessage),
        "creation"
      );
      this.send(message);
      this.setState({creation: true})
  }
 
  uploadProfileImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const tempFile = e.target.files[0];
      this.setState(prev => ({
        ...prev,
        information: {
          ...prev.information,
          profile_pic: window.URL.createObjectURL(tempFile)
        },
        errors: {
          ...prev.errors,
          profile_pic: ''
        },
        selectedFile: tempFile
      }));
    }
  }
  handleChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      information: {
        ...prevState.information,
        [name]: value
      },
      errors: {
        ...prevState.errors,
        [name]: ''
      }
    }));
  }
  checkRequiredfield = () => {
    let errors = {
      profile_pic: '',
      name: '',
      website: '',
      email: '',
    };
    const { profile_pic, name, email, website } = this.state.information
    if (!profile_pic) {
      errors.profile_pic = "Profile picture is required";
    }

    if (!name) {
      errors.name = "Name is required";
    }

    if (!email) {
      errors.email = "Email is required";
    }

    if (!website) {
      errors.website = "WebSite is required";
    }


    const hasErrors = Object.values(errors).some(value => value !== '');
    this.setState({ errors: errors });
    return hasErrors
  }
  updateUserDetails = async () => {
    if(!this.state.information.id) return this.createCommunityDetails()
    const hasErrors = this.checkRequiredfield();
    if (hasErrors) return;
    this.setState({ showLoading: true });
    let body = new FormData();
    body.append("name", this.state.information.name);
    body.append("email", this.state.information.email);
    body.append("website", this.state.information.website);
    body.append("description", this.state.information.description);
    body.append("phone_number", String(this.state.information.phone_number));
    { this.state.selectedFile && body.append("profile_pic", this.state.selectedFile) }

    const signupToken1 = await getStorageData('SignupSuccessToken');
    const loginToken = await getStorageData('LoginSuccessToken')
    const header = {
      token: signupToken1 || loginToken,
      "Content-Type": configJSON.apiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.apiCommunityUpdate = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateEnd + this.state.information.id
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  createCommunityDetails = async () => {
    const hasErrors = this.checkRequiredfield();
    if (hasErrors) return;
    this.setState({ showLoading: true });
    let body = new FormData();
    body.append("website", this.state.information.website);
    body.append("name", this.state.information.name);
    body.append("email", this.state.information.email);
    body.append("phone_number", String(this.state.information.phone_number));
    body.append("description", this.state.information.description);
    { this.state.selectedFile && body.append("profile_pic", this.state.selectedFile) }

    const loginToken = await getStorageData('LoginSuccessToken')
    const signupToken1 = await getStorageData('SignupSuccessToken');
    const header = {
      token: signupToken1 || loginToken,
      "Content-Type": configJSON.apiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.apiCreateCommunityId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createCommunityEnd
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethode
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  switchToken = async() => {
    this.setState({ showLoading: true });

    const loginToken = await getStorageData('LoginSuccessToken')
    const signupToken1 = await getStorageData('SignupSuccessToken');
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: signupToken1 || loginToken,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.apiSwitchTokenId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.switchAccountEnd
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  communityUpdateResponse = (responseJson: {
    data: {
      id: string
      type: string,
      attributes: {
        email: string,
        name: string,
        website: string,
        phone_number: number,
        description: string,
        is_notification_available: boolean,
        account_id: number,
        profile_pic: string,
        background_profile_pic: null | string,
        post_count: number,
        is_followed_by_user: boolean,
        follower_count: number,
      }
    }, meta: { message: string }, error: { message: string[] }[]
  }) => {
    this.setState({showLoading:false})
    if (responseJson.data) {
      const { name, email, phone_number, website, description, profile_pic, background_profile_pic, follower_count, is_followed_by_user } = responseJson.data.attributes;
      toast.success(responseJson.meta.message);
      this.setState({creation: false,information: { id: responseJson.data.id, name, email, website, phone_number, description, profile_pic, background_profile_pic, follower_count, is_followed_by_user } });
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "LinkedAccounts");

      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    } else if (responseJson.error) {
      handleErrorArray(responseJson.error)
    }
  }
  // Customizable Area End
}
