export const  SideImage = require('../assets/SignUpSideImg.png');
export const Logo  = require('../assets/Logo.png');
export const Chat = require('../assets/chat.png')
export const Calender = require('../assets/calender_fill.png')
export const Camera = require('../assets/camera.png')
export const Community = require('../assets/community.png')
export const Like = require('../assets/like.png')
export const LikeFill = require('../assets/like_fill.png')
export const Link = require('../assets/link.png')
export const LinkFill = require('../assets/link_fill.png')
export const More = require('../assets/more.png')
export const Notification = require('../assets/notification.png')
export const HomeFill = require('../assets/home_fill.png')
export const PostUser = require('../assets/post_user.png')
export const Send = require('../assets/send.png')
export const SendFill = require('../assets/send_fill.png')
export const User = require('../assets/user.png')
export const Post = require('../assets/post.png')
export const Home = require('../assets/home.png')
export const CommentFill = require('../assets/comment_fill.png')
export const eagel = require('../assets/eagel.png')
export const HeaderFVLogo = require("../assets/Faces_and_Voices_Header_logo.png")