// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

interface IUser {
  id: string;
  type: string;
  attributes: {
    activated: boolean;
    country_code: string;
    email: string;
    first_name: string;
    full_phone_number: string;
    last_name: string;
    full_name: string;
    job_title: string;
    phone_number: string;
    type: string;
    pronouns: string;
    language: string[];
    race: string;
    ethnicity: string;
    gender: string;
    state: string;
    is_private: boolean;
    city: string;
    country: string; // Type depends on your country object structure
    dob: string;
    timezone: string;
    is_local_admin: boolean;
    created_at: string;
    updated_at: string;
    device_id: string | null;
    profile_picture: string;
    background_image: string | null;
    follower_count: number;
    is_Community_present: boolean;
    selected_communities: { data: { id: string, attributes: { profile_pic: string, name: string } }[] };
    friend_list_count: number;
    is_in_connection: boolean;
    is_friend_request_sent: boolean;
    is_already_request_available: boolean;
    is_biometric_or_facial_available: boolean;
    total_post_count: number;
    social_media_urls: {id:number,url:string}[];
  }
}
// Customizable Area End



export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: {
    full_phone_number:string;
    gender:string;
    job_title:string;
    race:string;
    ethnicity:string;
    full_name:string;
    state:string;
    profile_picture:string;
    dob:string;
    email:string;
    language: string[];
    timezone:string;
    country:string;
    city:string;
    pronouns: string;
    social_media_urls: {id:number,url:string}[];
  },
  socialModal: boolean;
  isLoading:  boolean;
  communityModal: boolean;
  selectedSocial:{
    id:number;
    url:string;
    type:string;
  };
  selectedCommunity:{profile_pic:string,name:string,id:string}[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UserProfileInformationEditController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserDetailsId: string = '';
  updateUserId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    
    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      data :{
        full_phone_number:'',
        gender: '',
        job_title: '',
        race: '',
        ethnicity: '',
        full_name: '',
        state: '',
        profile_picture: '',
        dob: '',
        email: '',
        language: [],
        timezone: '',
        country: '',
        city: '',
        pronouns: '',
        social_media_urls: []
      },
      selectedSocial:{
        id:NaN,
        url:'',
        type:''
      },
      communityModal: false,
      socialModal: false,
      selectedCommunity: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
     // Customizable Area Start
     // Customizable Area End

  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson) {
      if (apiRequestCallId === this.updateUserId) {
        this.getUserDetailsResponse(responseJson);
        if (responseJson.data) {
          toast.success("User updated successfully.");
        }
      } else if (apiRequestCallId === this.apiGetUserDetailsId) {
        this.getUserDetailsResponse(responseJson);
      }
    }
  }

  getUserDetailsResponse = (responseJson:{data:IUser}) => {
    this.setState({isLoading:false})
    if(responseJson.data){
      const selected_communities = responseJson.data.attributes.selected_communities.data;
      const tempList = selected_communities.map(item => {
        return { profile_pic: item.attributes.profile_pic, name: item.attributes.name, id: item.id }
      })
      this.setState({data: responseJson.data.attributes,selectedCommunity:[...tempList]})
    }
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    this.setState({isLoading:true})
    this.getUserDetails();
  }

  getUserDetails = async () => {
    const signupToken = await getStorageData('SignupSuccessToken');
    const loginToken = await getStorageData('LoginSuccessToken');
    const header = {
      token: signupToken || loginToken,
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetUserDetailsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.get_user
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  uploadProfileImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const formdata = new FormData();
      formdata.append("account[profile_pic]", e.target.files[0]);
      this.setState({isLoading : true })
      const loginToken = await getStorageData('LoginSuccessToken');
      const signupToken = await getStorageData('SignupSuccessToken');
      const updateUserApi = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      updateUserApi.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.update_user
      );

      updateUserApi.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );

      updateUserApi.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethod
      );

      const header = {
        token: signupToken || loginToken,
      };

      updateUserApi.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      this.updateUserId = updateUserApi.messageId;
      runEngine.sendMessage(updateUserApi.id, updateUserApi);
    }
  }

  navigateEditSection= () => {
    this.props.navigation.goBack()
  }

  openCommunityModal = () => {
    this.setState({communityModal:true});
  }

  closeCommunityModal = () => {
    this.getUserDetails()
    this.setState({communityModal:false});
  }
  openSocialModal = () => {
    this.setState({socialModal:true,selectedSocial:{id:NaN,url:'',type:''}});
  }
  closeSocialMoodal = () => {
    this.getUserDetails()
    this.setState({socialModal:false,selectedSocial:{id:NaN,url:'',type:''}});
  }
  socialEdit = (id:number) => {
    const selected = this.state.data.social_media_urls.find(item=>item.id===id)?.url
    this.setState({socialModal:true,selectedSocial:{id:id,url:String(selected),type:'edit'}});
  }
  deleteSocial = (id:number) => {
    const selected = this.state.data.social_media_urls.find(item=>item.id===id)?.url;
    this.setState({socialModal:true,selectedSocial:{id:id,url:String(selected),type:'delete'}});
  }
  doneBtnNavigation = () => {
    const param = this.props.navigation.getParam("navigationBarTitleText") ?? "";
    if(param && param.includes("profile")){
      this.props.navigation.navigate("OtherUserProfile")
    }else{
      this.props.navigation.navigate("LandingScreen")
    }
  }

  orgNavigation = () => {
    const param = this.props.navigation.getParam("navigationBarTitleText") ?? "";
    if(param && param.includes("profile")){
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "UserOrganizationInformation");
  
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(
        getName(MessageEnum.NavigationScreenNameMessage),
        "view-profile"
      )
      this.send(message);
    }else{
      this.props.navigation.navigate("UserOrganizationInformation")
    }
    
  }
  // Customizable Area End
}

