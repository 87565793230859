Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
const urlConfig = require("../../framework/src/config");
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.switchTitle="Switch to Personal Account";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.textInputPlaceHolder = "Enter Text";
exports.getUserEndPoint = "get_user";
exports.get_community = "get_community";
exports.getRecentSearchesApiMethodType = "GET";
exports.getRecentSearchesApiEndPoint = "bx_block_elasticsearch/get_search_history";
exports.noRecentSearchesMessage = "No Recent Searches Found";
exports.getSearchResultApiMethodType = "GET";
exports.getSearchResultApiEndPoint = "bx_block_elasticsearch/search";
exports.noSearchResultsMessage = " No Search Results Found";
exports.deleteRecentSearchApiEndPoint = "bx_block_elasticsearch/remove_recent_history";
exports.deleteRecentSearchApiMethodType = "DELETE";
exports.getUserProfileDataApiEndPoint = "get_user?is_post_invisible=true";
exports.getUserProfileDataApiMethod = "GET";
exports.viewProfileTitle = "View Profile";
exports.accountTitle = "Account";
exports.switchAccountTitle = "Switch Account";
exports.myEventsTitle = "My Events";
exports.savePostTitle = "Save Post";
exports.settingsTitle = "Settings";
exports.helpTitle = "Help";
exports.broadcastMessageTitle= "Broadcast Message";
exports.logoutTitle = "logOut";
exports.createRecentSearchApiEndpoint = "bx_block_elasticsearch/recently_data_add";
exports.createRecentSearchApiMethod = "POST";

exports.baseURL = urlConfig.baseURL;

exports.messagePlaceholder="Type message...";
exports.optionMute="Mute notifications";
exports.optionUnMute="Unmute notifications";
exports.optionDelete="Delete chat";
exports.optionExit="Exit group";
exports.optionReport="Report";
exports.getRequestsListApiEndpoint="request_management/requests/receive";
exports.getTokenSwitchAPIEndpoint= "bx_block_chat9/switch_accounts";
exports.getRequestsListApiMethod = "GET";
exports.exampleApiContentType = "application/json";
exports.updateRequestApiEndpoint = "request_management/requests";
exports.updateRequestApiMethod = "PUT";
exports.getFriendsListApiEndpoint = "friend_list";
exports.getApiMethod = "GET";
exports.getRecommendedAccountsApiEndpoint = "recommanded_accounts";
exports.getRecommendedAcountsApiMethod = "GET";
exports.post="POST";
exports.sendRequestApiEndpoint = "request_management/requests";
exports.removeRecommendationApiEndpoint = "remove_from_recommended";
exports.removeRecommendationApiMethod = "POST";
exports.upcomingEventEndpoint = "bx_block_events/events/fetch_own_event?event_time=upcoming_event";
exports.communitiesEndpoint = "/communities";
exports.show_all="Show all";
exports.show_less="Show less";
exports.my_community="My Community";
// Customizable Area End