import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Modal, Fade, Avatar, CircularProgress, Backdrop
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
// Customizable Area End

import CustomisableUserProfilesController, {
  Props,
  configJSON,
} from "./CustomisableUserProfilesController";

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <CommunityModal
        open={Boolean(this.props.open)}
        onClose={this.handleClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 200
        }}
        BackdropComponent={Backdrop}
      >
        <Fade in={Boolean(this.props.open)}>
          <Box sx={{ width: { xs: "85%", sm: "60%", md: "50%", lg: "35%" }, maxWidth: "604px" }} className="fadeContainer" display="flex" alignItems="center" justifyContent="center" >
            <Box bgcolor="#fff" borderRadius="12px" width="100%">
              <Box sx={{ border: "1px solid black", margin: "20px", padding: "20px", borderRadius: "12px " }}>
                <Box component="p" color="#005487" fontFamily={"Verdana"} fontWeight={"bold"} fontSize={"20px"} mt={0}>
                  {configJSON.community}
                </Box>
                <Box sx={{ margin: "auto", height: "340px" }} className="gap-12">
                  {this.state.isLoading ?
                    <Box margin={"auto"}> <CircularProgress /></Box>
                    : this.state.data.map(item => {
                      return (
                        <Box key={item.name} display="flex" justifyContent={"space-between"}>
                          <Box display={"flex"} alignItems={"start"} data-test-id="community-item">
                            <Avatar className="avatar" src={item.profile_pic} data-test-id="like-profile-img" onClick={()=>this.handleNavigation(item.id)}/>
                            <Box component="p" className="word" fontFamily={"Verdana"} fontWeight={"bold"} fontSize={"17px"} m={0} ml={"12px"}>{item.name}</Box>
                          </Box>
                          <Button className={"follow-unfollow-btn"} onClick={()=>this.followUnfollow(item.id)} data-testid="follow-btn">{item.followed? "Unfollow" : "Follow"}</Button>
                        </Box>
                      )
                    }
                    )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </CommunityModal>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CommunityModal = styled(Modal)({
  "& .fadeContainer": {
      backgroundColor: "transparent",
      margin: "auto",
      display: "flex",
      transform: "translate(-50%, -50%)",
      left: "50%",
      top: "50%",
      position: "absolute",
  },
  "& .gap-12": {
      gap: "12px",
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
  },
  "& .avatar": {
      cursor: "pointer",
  },
  "& .follow-unfollow-btn":{
    color: "#005487",
    fontFamily: "Verdana",
    textTransform: "none",
    padding: 0,
  },
  "& .word":{
    wordBreak: "break-all"
  }
});
// Customizable Area End
