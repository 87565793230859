import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import { setStorageData } from "../../../framework/src/Utilities";
import { getNotificationPermission } from "../../../components/src/firebase";
import { handleErrorArray } from "../../../components/src/common";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
export const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  classes: any;

  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  email: string;
  password: string;
  checkedRememberMe: boolean;
  emailError: string;
  passwordError: string;
  isLoading: boolean;
  showPass: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
  // Customizable Area Start

  // Customizable Area End
export default class NewLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.state = {
      email: "",
      password: "",
      checkedRememberMe: false,
      emailError: "",
      passwordError: "",
      isLoading: false,
      showPass: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    localStorage.clear();
    const email = this.getCookie('email');
    const password = this.getCookie('password');
    if (email && password) {
      this.setState({email,password,checkedRememberMe:true})
    }
  }

  setCookie = (name: string,value: string,exDays: number) => {
    const date = new Date();
    date.setTime(date.getTime() + (exDays*24*60*60*1000));
    let expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }

  getCookie = (fieldName: string): string => {
    const name = fieldName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookies = decodedCookie.split(';');
  
    for (const cookie of cookies) {
      let temp = cookie.trim();
      if (temp.indexOf(name) === 0) {
        return temp.substring(name.length);
      }
    }
  
    return "";
  };  

  handleSignUp = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "SignIn");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    
    this.send(message);
  };
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === "email") {
      this.setState({
        emailError: "",
        email: value
      });
    }
    if (name === "password") {
      this.setState({
        passwordError: "",
        password: value
      });
    }
  };

  handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ checkedRememberMe: event.target.checked });

    if(!event.target.checked){
      const characters = ['p', 'a', 's', 's', 'w', 'o', 'r', 'd'];
      const passLop = characters.join('');
      document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = `${passLop}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
  };


  async receive(_from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.apiEmailLoginCallId) {
        this.setState({isLoading: false})
        if (!responseJson.errors) {
          setStorageData("LoginSuccessToken", responseJson.meta.token);
          if (responseJson.meta.can_skip_verfication) {
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingScreen")
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message)
          } else {
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), "PhoneVerificationLoginFlow");
        
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            
            this.send(message); 
          }
        } else {
          handleErrorArray(responseJson.errors)
        }
      }
    }
  }
  handleForgotPassNavigation = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ForgotPasswordPhoneVerification");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    
    this.send(message);
  };
  eyeIconChange = () => {
    this.setState({ showPass: !this.state.showPass });
  };

  checkValidation = () => {
    const { email, password } = this.state;

    let hasError = false;
    if (!email || !validEmailRegex.test(this.state.email)) {
      this.setState({
        emailError: configJSON.errorEmailNotValid,
      });
      hasError = true
    }
    if (!password) {
      this.setState({
        passwordError: configJSON.PasswordRequired,
      });
      hasError = true
    }
    return hasError;
  }


  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const hasError = this.checkValidation();
    if(hasError) return
    if (this.state.checkedRememberMe) {
      this.setCookie('email',this.state.email,30)
      this.setCookie('password',this.state.password,30)
    }
   
    this.setState({ isLoading: true });
    const deviceId = await getNotificationPermission();

      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
      };
      const attrs = {
        email: this.state.email,
        password: this.state.password,
        device_id: deviceId ?? "",
      };

      const data = {
        type: "email_account",
        attributes: attrs,
      };

      const httpBody = {
        data: data,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiEmailLoginCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.loginRegisterAPIEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}