// Customizable Area Start
import React from "react";
import { Typography, Box, Grid, FormControl, InputAdornment, Button, FormHelperText, OutlinedInput, Card } from '@material-ui/core';
import AchievementsController, { Props } from "./AchievementsController.web";
import { EditIcon, LinkIcon, PersonalCard, AddIcon, ArrowDown, UploadIcon, Close, PdfIcon } from "./assets";
import { styled } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import Checkbox from '@material-ui/core/Checkbox';
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import Loader from "../../../components/src/Loader.web";
export const configJSON = require("./config");

// Customizable Area End

// Customizable Area Start

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: "300px",
            borderRadius: "12px"
        }
    },
};

// Customizable Area End

export default class Achievements extends AchievementsController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    // Customizable Area Start
    NavBarHeaders = () => {
        return (
            <>
                <Box style={webPage.imageUploads} >
                    <Button data-test-id="BulkUpload" fullWidth onClick={() => this.handleChangeTab("BulkUpload")} style={this.state.imageLoad === "BulkUpload" ? webPage.imageBtn : webPage.bordernone}>{configJSON.bulkUpload}</Button>
                    <Button data-test-id="CustomForm" fullWidth onClick={() => this.handleChangeTab("CustomForm")} style={this.state.imageLoad === "CustomForm" ? webPage.imageBtn : webPage.bordernone}>{configJSON.customUpload}</Button>
                </Box>
            </>
        )
    }

    FormHelperTextComponent = (error: boolean, errorMessage: string) => {
        return (
            <>
                {error && (
                    <FormHelperText>
                        {errorMessage}
                    </FormHelperText>
                )}

            </>
        )
    }

    FormImageUpload = (props: { state: { fileSelect: File | string, title: string, description: string, error: boolean, errorMsg: string, errorImgMsg: string, errordescMsg: string, extantion: string, fileName: string }, index: number }) => {
        return (
            props.state.fileSelect === "" ?
                <Box
                    style={{...webPage.dropzone}}
                >
                    <Box display="flex" flexDirection="column" alignItems="center" width={"100%"}  gridGap="5px">
                        <Button
                            disableRipple
                            component="label"
                            style={{...webPage.Postbtn}}
                        >
                            <div style={{ display: "flex",  alignItems: "center", flexDirection: "column",margin: "0px 0px", width: "100%" }}>
                                <div>
                                    <img src={UploadIcon} alt="UploadIcon" />
                                </div>
                                <div style={{ textAlign: "center",width: "100%" }}>
                                    {configJSON.choose_file}
                                    <input
                                        type="file"
                                        accept=".jpg, .jpeg, .png, .pdf"
                                        data-test-id="image-upload"
                                        name="getimage"
                                        hidden
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateFieldChanged(props.index, e)}
                                    />
                                </div>
                            </div>
                        </Button>
                        {props.state.error && (
                            <FormHelperText>
                                {props.state.errorImgMsg}
                            </FormHelperText>
                        )}
                    </Box>
                </Box>
                : (
                    <Grid item style={{ display: "flex", justifyContent: "center", alignItems: "center", margin:"auto", height:"100%" }} >
                        {
                            
                                <Box sx={{ width: "150px", maxHeight: "150px", marginX:"10px" , display:"flex", position:"relative", justifyContent:"center", alignItems: "center" }}>
                                    <RemoveIcon data-test-id="remove-file" onClick={()=>this.removeSelectedFile("bulk",props.index)}/>
                                    {this.displayNameImage(props.state.fileSelect,props.state.extantion,props.state.fileName)}
                                    
                                </Box>
                           
                        }
                    </Grid>
                )
        )
    };

    displayNameImage = (file: File | string, extantion?: string, name?: string) =>{
        if(file instanceof File){
            if((file.type).includes("image")) return <img style={webPage.imgSize} src={URL.createObjectURL(file)} alt="img"/>
            return <Box display={"flex"} alignItems={"center"} flexDirection={"column"}><img src={PdfIcon} alt="pdf" style={webPage.pdf}/><WordWrap component={"p"} >{file.name}</WordWrap></Box>
        }else {
            if(extantion === "pdf") return <Box display={"flex"} alignItems={"center"}  flexDirection={"column"}><img src={PdfIcon} alt="pdf"  style={webPage.pdf}/><WordWrap component={"p"} >{name}</WordWrap></Box>
            return <img style={webPage.imgSize} src={file} alt="img"/>
        }
    }

    renderBulkUpload = () => {
        return (
            <>
                <Box paddingX={"20px"}>
                    {
                        this.state.BulkData.map((data, index: number) => {
                            return (
                                <Box key={`${index + 0}`} mt={3} style={webPage.cards}>
                                    <Grid container direction="row" spacing={0}>
                                        <Grid item sm={3}>
                                            {this.FormImageUpload({ state: data, index })}
                                        </Grid>
                                        <Grid item sm={9} style={{ margin: "0px" }}>
                                            <FormControl fullWidth className={'CustomTextFieldForstyle'}>
                                                <OutlinedInput
                                                    style={webPage.margin}
                                                    id="outlined-adornment-weight"
                                                    placeholder="Add Title here"
                                                    name="title"
                                                    value={data.title}
                                                    data-test-id="txttitle"
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateFieldChanged(index, e)}
                                                    aria-describedby="outlined-weight-helper-text"
                                                    fullWidth
                                                    labelWidth={0}
                                                    startAdornment={<InputAdornment position="start">
                                                        <img src={EditIcon} alt="EditIcon"/>
                                                    </InputAdornment>}
                                                    endAdornment={<InputAdornment position="end">
                                                        {index > 0 && <img src={Close} alt="delete" style={webPage.pointer} data-test-id="delete-id" onClick={()=>this.handleRemoveBulk(index)}/>}
                                                    </InputAdornment>}
                                                />
                                                
                                                {this.FormHelperTextComponent(!data.title, data.errorMsg)}
                                            </FormControl>
                                            <FormControl fullWidth className={'CustomTextFieldForstyle'}>
                                                <OutlinedInput
                                                    style={webPage.margins}
                                                    id=""
                                                    name="description"
                                                    placeholder="Add Description here"
                                                    value={data?.description}
                                                    data-test-id="desctest"
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateFieldChanged(index, e)}
                                                    fullWidth
                                                    startAdornment={<InputAdornment style={{ marginTop: "10px" }} position="start"><img src={EditIcon} alt="EditIcon" /></InputAdornment>}
                                                    rows={5}
                                                    multiline
                                                    aria-describedby="outlined-weight-helper-text"
                                                    labelWidth={0}
                                                /> {data.errordescMsg ?
                                                    this.FormHelperTextComponent(!data.description, data.errordescMsg)
                                                    : ""}</FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )
                        })

                    }

                    <Box  style={{ ...webPage.bottombutton}} >
                        <Button data-test-id="add-id" onClick={this.handleAddBulk} startIcon={<img src={AddIcon} alt="AddIcon"/>} title="Add More" style={{ color: "#005487", fontWeight: 400 }} >{configJSON.addMore}</Button>
                    </Box>

                </Box>
                <Box textAlign={"center"} padding={"20px"} borderTop="1px solid #808080">

                    <Button
                        style={webPage.Publishbtn1}
                        data-test-id="bulk-btn"
                        onClick={this.postBulkUpload}
                    >{configJSON.Next}</Button>  
                </Box>
            </>
        )
    }
    // Customizable Area End


    render() {

        // Customizable Area Start
        return (

            <Card >
                {this.state.isLoading && <Loader loading={this.state.isLoading}/>}
                <Box sx={{ borderBottom: "1px solid #808080", paddingX: "20px",paddingY: "10px" }}>
                    <Typography variant="h6" className="HeaderText" >{configJSON.achievements}</Typography>
                </Box>
                <Grid container component={Box} sx={{ margin: "0px", paddingX: "20px" }}   >
                    <Grid container component={Box} sx={{ display: "flex", textAlign: "center", justifyContent: "space-between", marginBottom: "3%", paddingTop: "12px", width: "100%" }} >
                        {this.NavBarHeaders()}
                    </Grid>
                </Grid>
                <Box>
                    {
                        this.state.imageLoad === "BulkUpload" ?
                            this.renderBulkUpload() : ""
                    }
                </Box>
                {
                    this.state.imageLoad === 'CustomForm' ? (
                        <Box>
                            <Grid container item xs={12} component={Box} sx={{ padding: "20px" }}>
                                <Grid item xs={3} />
                                <Grid xs={6}  item style={webPage.cards}>
                                    {!this.state.custom_form_image ? <Box style={webPage.dropzone} >
                                        <Box display="flex"  flexDirection="column" alignItems="center" gridGap="5px" width={"100%"}>
                                            <Button
                                                component="label"
                                                disableRipple
                                                style={webPage.Postbtn}
                                            >
                                                <div style={{ flexDirection: "column", alignItems: "center", display: "flex", margin: "0px 0px", width: "100%" }}>
                                                    <div>
                                                        <img alt="UploadIcon" src={UploadIcon} />
                                                    </div>
                                                    <div style={{ width: "100%", textAlign: "center" }}>
                                                        {configJSON.choose_file}
                                                        <input
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateFieldChanged(8, e)}
                                                            type="file"
                                                            accept=".jpg, .jpeg, .png, .pdf"
                                                            data-test-id="image-upload"
                                                            hidden
                                                            name="custom_form_imageError"
                                                        />
                                                    </div>
                                                </div>

                                            </Button>


                                            {this.state.custom_form_imageError && (
                                                <FormHelperText>
                                                    {this.state.custom_form_imageError}
                                                </FormHelperText>
                                            )}

                                        </Box>
                                    </Box> : <Grid item style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "10px" }} >
                                        {
                                          
                                                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} position="relative" sx={{ width: { xs: "100px", md: "130px" }, maxHeight: { xs: "100px", md: "130px" } }}>
                                                    <RemoveIcon data-test-id="remove-file" onClick={()=>this.removeSelectedFile("custom",0)}/>
                                                   {this.displayNameImage(this.state.custom_form_image,this.state.extantion,this.state.fileName)}
                                                </Box>
                                            
                                        }
                                    </Grid>}

                                </Grid>
                                <Grid item xs={3} />
                                <Grid item xs={12}>
                                    <FormControl style={webPage.urlPostGridFormControl} className={`CustomTextFieldFormControl`}>
                                        <Typography component={"span"} className={`Heading6 BoldFont PrimaryColor2 `} style={webPage.label}>{configJSON.title}</Typography>                                        
                                        <OutlinedInput
                                            placeholder="Enter your Achievement Title"
                                            onChange={this.handleChange}
                                            name="title"
                                            data-test-id="customformtitle"
                                            value={this.state.title}
                                            error={this.state.titleError}
                                            style={{ fontWeight: 500 }}
                                            type="text"
                                            startAdornment={<InputAdornment position="start"><img src={EditIcon} alt="EditIcon" /></InputAdornment>}
                                        />
                                        {this.state.titleError && (
                                            <FormHelperText>
                                                {this.state.tittelErrorMsg}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl style={webPage.urlPostGridFormControl} className={`CustomTextFieldForstyle`} >
                                        <Typography component={"span"} className={`Heading6 BoldFont PrimaryColor2 `} style={webPage.label}>{configJSON.organization}</Typography>
                                        <OutlinedInput
                                            placeholder="Enter your Organization name"
                                            name="organisation_name"
                                            data-test-id="organisation_name"
                                            type="text"
                                            error={this.state.OrgError}
                                            value={this.state.organisation_name}
                                            style={{ fontWeight: 500 }}
                                            onChange={this.handleChange}
                                            fullWidth
                                            startAdornment={<InputAdornment position="start"><img src={PersonalCard} /> </InputAdornment>}
                                        />
                                        {this.state.OrgError && (
                                            <FormHelperText>
                                                {this.state.OrgErrorMsg}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                                    <Checkbox
                                        checked={this.state.Experiationstatus === true}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        style={{ color: "#30749d" }}
                                        onChange={this.handleToggle}
                                    /> <Typography color="textSecondary">{configJSON.expire}</Typography>
                                </Grid>

                                <Grid item xs={12} style={webPage.eventStyle}>
                                    <FormControl style={webPage.urlPostGridFormControl}>
                                        <Typography component={"span"} style={{ ...webPage.label }} className={`Heading6 BoldFont PrimaryColor2 `}>{configJSON.Issuing_Date}</Typography>
                                        <Grid container item sm={12} spacing={2} style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Grid style={{ width: "48%", marginLeft: "1%" }} >

                                                <CustomSelectDropdown
                                                    fieldValue={this.state.issue_month}
                                                    fieldId={""}
                                                    handleChange={this.handleChangeDropdownValue}
                                                    fieldName="issue_month"
                                                    renderValue={this.renderValuestartMonth}
                                                    MenuProps={MenuProps}
                                                    fieldError={this.state.startMontherror}
                                                    fieldMenuItems={this.state.startMonth}
                                                    startAdornmentIcon={""}
                                                    placeHolderText="Months"
                                                    iconComponent={<img src={ArrowDown} alt="ArrowDown" />}


                                                />
                                                {this.FormHelperTextComponent(this.state.startMontherror, this.state.IssueMonthErrorMsg)}

                                            </Grid>
                                            <Grid style={{ width: "48%", marginLeft: "1%" }}>
                                                <CustomSelectDropdown
                                                    fieldValue={this.state.issue_year}
                                                    fieldId="user-profile-IssueYear"
                                                    handleChange={this.handleChangeDropdownValue}
                                                    fieldMenuItems={this.state.IssueYearList}
                                                    fieldName="issue_year"
                                                    renderValue={this.renderValueIssueYear}
                                                    fieldError={this.state.IssueyearError}
                                                    MenuProps={MenuProps}
                                                    iconComponent={<img src={ArrowDown} alt="ArrowDown" />}
                                                    placeHolderText="Year"
                                                    startAdornmentIcon={""}
                                                />
                                                {this.FormHelperTextComponent(this.state.IssueyearError, this.state.IssueYearErrorMsg)}
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </Grid>
                                {
                                    this.state.Experiationstatus !== true ?
                                            <Grid item xs={12}>
                                                <FormControl style={webPage.urlPostGridFormControl} className={`CustomTextFieldForstyle`}>
                                                    <Typography component={"span"} style={{ ...webPage.label }} className={`Heading6 BoldFont PrimaryColor2 `}>{configJSON.Expiration_Date}</Typography>
                                                    <Grid container item sm={12} spacing={2} style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <Grid style={{ width: "48%", marginLeft: "1%" }} >
                                                            <CustomSelectDropdown
                                                                fieldValue={this.state.expiration_month}
                                                                fieldId={""}
                                                                fieldName="expiration_month"
                                                                handleChange={this.handleChangeDropdownValue}
                                                                renderValue={this.renderValuestartMonth}
                                                                MenuProps={MenuProps}
                                                                fieldError={this.state.expiration_month_error}
                                                                fieldMenuItems={this.state.startMonth}
                                                                startAdornmentIcon={""}
                                                                iconComponent={<img src={ArrowDown} alt="ArrowDown" />}
                                                                placeHolderText="Months"
                                                            />
                                                            {this.state.expiration_month_error && (
                                                                <FormHelperText>
                                                                    {this.state.expmontherrormsg}
                                                                </FormHelperText>
                                                            )}
                                                        </Grid>
                                                        <Grid style={{ width: "48%", marginLeft: "1%" }}>
                                                            <CustomSelectDropdown
                                                                fieldId="user-profile-IssueYear"
                                                                fieldName="expiration_year"
                                                                fieldValue={this.state.expiration_year}
                                                                MenuProps={MenuProps}
                                                                handleChange={this.handleChangeDropdownValue}
                                                                fieldError={this.state.expiration_year_error}
                                                                renderValue={this.renderValueIssueYear}
                                                                iconComponent={<img src={ArrowDown} alt="ArrowDown" />}
                                                                startAdornmentIcon={""}
                                                                fieldMenuItems={this.state.IssueYearList}
                                                                placeHolderText="Year"

                                                            />
                                                            {this.state.expiration_year_error && (
                                                                <FormHelperText>
                                                                    {this.state.expyearerrormsg}
                                                                </FormHelperText>
                                                            )}
                                                        </Grid>
                                                    </Grid>

                                                </FormControl>
                                            </Grid>
                                         : ""
                                }

                                <Grid item xs={12}>
                                    <FormControl style={webPage.urlPostGridFormControl} className={'CustomTextFieldForstyle'}>
                                        <Typography component={"span"} className={`Heading6 BoldFont PrimaryColor2 `} style={webPage.label}>{configJSON.ceu}</Typography>
                                        <OutlinedInput
                                            placeholder="Enter CEU's gained from training"
                                            name="ceu_gained"
                                            data-test-id="ceu_gained"
                                            type="text"
                                            error={this.state.CeuGainedError}
                                            value={this.state.ceu_gained}
                                            style={{ fontWeight: 500 }}
                                            onChange={this.handleChange}
                                            startAdornment={<InputAdornment position="start"><img src={EditIcon} alt="EditIcon" /> </InputAdornment>}
                                        />
                                        {this.state.CeuGainedError && (
                                            <FormHelperText>
                                                {this.state.CeuGainedErrorMsg}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl style={webPage.urlPostGridFormControl} className={'CustomTextFieldForstyle'}>
                                        <Typography component={"span"} className={`Heading6 BoldFont PrimaryColor2 `} style={webPage.label}>{configJSON.description}</Typography>
                                        <OutlinedInput
                                            placeholder="Add Description here"
                                            name="description"
                                            data-test-id="description"
                                            type="text"
                                            error={this.state.descriptionError}
                                            value={this.state.description}
                                            style={{ fontWeight: 500 }}
                                            onChange={this.handleChange}
                                            startAdornment={<InputAdornment position="start"><img src={EditIcon} alt="EditIcon" /> </InputAdornment>}
                                        />
                                        {this.state.descriptionError && (
                                            <FormHelperText>
                                                {this.state.descriptionErrorMsg}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl style={webPage.urlPostGridFormControl} className={'CustomTextFieldForstyle'}>
                                        <Typography component={"span"} className={`Heading6 BoldFont PrimaryColor2 `} style={webPage.label}>{configJSON.cred}</Typography>
                                        <OutlinedInput
                                            placeholder="Add Credential URL"
                                            error={this.state.credentialUrlError}
                                            name="credential_url"
                                            data-test-id="credential_url"
                                            onChange={this.handleChange}
                                            value={this.state.credential_url}
                                            style={{ fontWeight: 500 }}
                                            type="url"
                                            startAdornment={<InputAdornment position="start"><img src={LinkIcon} alt="LinkIcon" /> </InputAdornment>}
                                        />
                                        {this.state.credentialUrlError && (
                                            <FormHelperText>
                                                {this.state.credentialUrlErrorMessage}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Box textAlign={"center"} p="20px" borderTop="1px solid #808080">
                                <Button
                                    style={webPage.Publishbtn1}
                                    data-test-id="custom-btn"
                                    onClick={this.postCustomForm}
                                >{configJSON.Next}</Button>
                            </Box>
                        </Box>
                    ) : ""
                }
            </Card>
        );

        // Customizable Area End
    }
}
// Customizable Area Start
export const RemoveIcon = styled(ClearIcon)({
    position: "absolute",
    top: -5,
    right: -5,
    fill: "#01548a",
    backgroundColor: "#fff",
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    cursor: "pointer"
})

export const WordWrap = styled(Box)({
    hyphens: "auto",
    margin: 0,
    textAlign: "center"
})
export const webPage = {
    pointer: {
        cursor: "pointer"
    },
    pdf:{
        width:"40px"
    },
    imgSize: {
        maxWidth:"120px",
        maxHeight:"100px",
    },
    Publishbtn1: {
        color: "#fff",
        height: "50px",
        width: "70%",
        margin: "auto",
        border: "solid 1px #005487",
        backgroundColor: "#01548a",
    },
    imageUploads: {
        width: "100%",
        justifyContent: "space-between",
        display: "flex",
        color: "lightgrey"
    } as const,
    margin: {
        margin: "0px",
        borderRadius: "10px",
        backgroundColor: "fff"
    },
    margins: {
        margin: "0px",
        alignItems: "flex-start",
        backgroundColor: "fff",
        borderRadius: "10px"
    },
    InputField: {
        marginTop: "10px"
    },
    eventStyle: {
        width: "100%",
    },
    cards: {
        backgroundColor: "#6f6d6d1f",
        border: "1px solid #6f6d6d1f",
        borderRadius: "10px"
    },
    IconColor: {
        color: "#5B7389"
    },
    imageBtn: {
        borderBottom: "2px solid #01548a",
        cursor: "pointer",
        borderRadius: "0px",
        fontWeight: 500,
        color: "#01548a",
    } as const,
    bordernone: {
        fontWeight: 500,
        border: 0,
        color: "#a9aeb1"
    } as const,
    Postbtn: {
        borderRadius: "0px",
        color: "grey",
        padding: "40px 0px",
        width: "100%",
    },
    Publishbtn: {
        background: "#01548a",
        color: "#fff",
        width: "100%"
    },
    imageUploadsTabs: {
        textAlign: "center",
        display: "flex",
        marginBottom: "3%",
        justifyContent: "space-between",
        paddingTop: "12px",
    } as const,
    dropzone: {
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
    },
    bottombutton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
    }, addbutton: {
        color: "#005487",
        fontSize: "14px",
        fontWeight: 400,
    },
    urlPostGridFormControl: {
        marginTop: "2%",
        width: "100%",

    } as const,
    urlPostGrid: {
        marginBottom: "20px",
    } as React.CSSProperties,
    label: {
        fontSize: "14px",
        color: "#01548a",
        marginBottom: "10px",
        fontWeight: "bold",
    } as const
}
// Customizable Area End
