import React from "react";
import {
  Box,
  Button,
  // Customizable Area Start
  Divider, Switch, Stepper, Step, StepLabel, StepConnector
  // Customizable Area End
} from "@material-ui/core";


// Customizable Area Start
import { Edit } from "../assets";
import { styled } from "@material-ui/core/styles";
import { StepIconProps } from '@material-ui/core/StepIcon';
import Loader from "../../../../components/src/Loader.web";
const configJSON = require("./config");
// Customizable Area End

import AssessmentQuestionsController, {
  Props,
} from "./AssessmentQuestionsController";

export default class AssessmentQuestions extends AssessmentQuestionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderAnswer = (questionId: number) => {
    const answer = this.state.questions.find(el => el.questionId === questionId)?.answer;
    const StepComponent = (props: StepIconProps) => {
      const { completed } = props;
      return (
        <>
          {completed ? <div style={{ backgroundColor: "#005487", width:"12px", height:"12px", borderRadius:"50%" }}></div> : <div style={{ backgroundColor: "#cbcbcb", width:"12px", height:"12px", borderRadius:"50%" }} />}
        </>
      );
    };

    return (
      <>
       <Box sx={{width: "100%" }} className="questions">
       <ProgressBarContainer activeStep={answer?answer+1:0} alternativeLabel connector={<Connector />}>
        <Step>
        </Step>
        {this.state.options.map((label, index) => (
          <Step key={label.smallLabel} data-test-id={`${label.smallLabel}-step`} onClick={() => this.selectAnswer(questionId, index+1)}>
          <StepLabel StepIconComponent={StepComponent}>{label.smallLabel}</StepLabel>
          </Step>
        ))}
        <Step>
        </Step>
      </ProgressBarContainer>
      </Box>
      </>
    );
  }
  // Customizable Area End


  render() {
    // Customizable Area Start
    return (
      <>
        {this.state.webLoader && <Loader loading={this.state.webLoader} />}
      <Wrapper display="flex" flexDirection="column" sx={{ width: { xs: "90%", md: "100%" }, margin: "auto", }} bgcolor="white">
        <Box display="flex" alignItems="center" justifyContent="space-between" padding="20px">
          <Box component="p" sx={{ fontSize: "20px", fontWeight: "bold" }} m={0} color="#005487" className="title-text">{configJSON.barc_assessment}</Box>
        </Box>
        <Divider className="divider" />
        <Box padding={"24px"} borderRadius={"12px"} bgcolor={"#fff"} >
          <Box className="gap10" bgcolor={"#6f6d6d1f"} borderRadius={"12px"} display={"flex"} flexDirection={"column"} p={"10px"} >
            <Box bgcolor={"#fff"} p={"24px"} borderRadius={"12px"}>
              <Box component={"p"} fontSize={"16px"} fontWeight={"bold"} m={0}>{configJSON.test_type}</Box>
              <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Box component={"p"} fontSize={"16px"} m={0} color="#808080">{configJSON.anonymous}</Box>
                <SWITCH checked={this.state.test_type} data-test-id="annonnymus-id" onChange={this.handleAnnonymus} />
              </Box>
            </Box>
            {
              [...this.state.questions].map((data, index: number) => {
                return (
                  <Box bgcolor={"#fff"} key={`${index + 1}`} p={"24px"} borderRadius={"12px"}>
                    <Box >
                      <Box component={"p"} fontSize={"16px"} my={"8px"} fontWeight={"bold"} className="barc-question">{index + 1}.{data.question}*</Box>
                      {this.renderAnswer(data.questionId)}
                    </Box>
                  </Box>
                )
              })
            }
          </Box>
        </Box>
          {this.state.error && <Box component="p" color="red" fontFamily="Verdana" fontSize="14px" m={0}>{this.state.error}</Box>}
        <Divider className="divider" />
        <Box display="flex" alignItems="center" justifyContent="center" padding="20px">
          <Button
            variant="contained"
            className="submit-btn"
            data-test-id="submit-id"
            onClick={this.submitAnswers}
          >
            {configJSON.submitBtn}
          </Button>
        </Box>
      </Wrapper>
    </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  borderRadius: "12px",
  fontFamily: "Verdana",
  "& .pointer": {
    cursor: "pointer"
  },
  "& .gap10": {
    gap: "10px",
  },
  "& .submit-btn": {
    width: "70%",
    maxWidth: "556px",
    color: "#fff",
    height: "56px",
    fontWeight: "bold",
    backgroundColor: "#005487",
    fontFamily: "Verdana"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "2px solid #f4f4f4"
  },
  "& .MuiOutlinedInput-root:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid black"
    },
  }
});

const ProgressBarContainer = styled(Stepper)({
  padding: "24px 0",
  "& .MuiStepLabel-alternativeLabel": {
    display: "flex",
    alignItems: "center",
    height:"24px",
    color: "#cbcbcb",
    fontSize: "12px",
    fontWeight:"bold",
    cursor: "pointer"
  },
  "& .MuiStepLabel-completed":{
    color: "#005487"
  },
  "& .MuiStepLabel-label":{
    display: "flex",
    justifyContent: "center",
    wordBreak: "break-all"
  },
  "& .MuiStepConnector-line":{
    border: "1px solid #cbcbcb"
  },
  "& .MuiStepConnector-completed":{
    "& .MuiStepConnector-line":{
      border: "1px solid #005487"
    }
  }
});

const Connector = styled(StepConnector)({
  marginLeft: '-14px',
  marginRight: '-14px', 
});

const SWITCH = styled(Switch)({
  height: "16px",
  padding: 0,
  width: "24px",
  margin: 0,
  overflow: "unset",
  "& .Mui-checked": {
    color: "#005487",
    left: "-12px"
  },
  "& .MuiSwitch-thumb": {
    height: "9px",
    width: "9px",
    borderRadius: "50%",
    margin: "2px",
    border: "1px solid #000000",
  },
  "& .MuiButtonBase-root": {
    padding: 0,
    margin: 0,
  },
  "& .MuiSwitch-track": {
    height: "14px",
    borderRadius: "9px",
    opacity: "0.65",
    border: "1px solid #000000",
    backgroundColor: "transparent"
  },
  "& .Mui-checked + .MuiSwitch-track": {
    backgroundColor: "transparent",
    border: "1px solid #005487",
  },
  "& .Mui-checked .MuiSwitch-thumb": {
    border: "1px solid #005487",
  }
});
// Customizable Area End
