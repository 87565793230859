import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Divider, TextField, Switch, FormControl, RadioGroup, FormControlLabel, Radio
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import AssessmentTestController, {
  Props,
  configJSON,
} from "./AssessmentTestController";

class AssessmentTest extends AssessmentTestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderRadio = (questionId: string, index: number) => {
    const currunt = this.state.questions.find(item=>item.id===questionId);
    return (
      <RadioWrapper sx={{ padding: "5px 10px" }}>
        <FormControl component="fieldset">
          <RadioGroup >
          {currunt?.options.map((item)=>{
            return(
              <FormControlLabel key={item.id} value={item.id} disabled={this.state.disable} control={<Radio size="small" data-test-id="tnf-id" onChange={(e)=>this.radioChange(e,questionId,index)}/>} label={item.label==="f"?"False":"True"} />
            )})
          }
          </RadioGroup>
        </FormControl>
      </RadioWrapper>
    )
  }

  renderField = (questionId: string, index: number) => {
    const answer = this.state.answers.find(item=>item.questionId===questionId);
    return (
      <TextField
        disabled={this.state.disable}
        variant="outlined"
        size="medium"
        placeholder={"Type your Short or Long answer here"}
        name={`anonymous`}
        type="text"
        data-test-id="free_text-id"
        value={answer?.answer}
        onChange={(e: React.ChangeEvent<HTMLInputElement>)=>this.handleChangeFreeText(e,questionId,index)}
        fullWidth
        InputProps={{
          style: {
            marginTop: "16px"
          },
        }}
      />
    )
  }

  renderMcq = (questionId: string, index: number) => {
    const currunt = this.state.questions.find(item=>item.id===questionId);
    return (<>
      
      <RadioWrapper sx={{ padding: "5px 10px" }}>
      <FormControl component="fieldset">
        <RadioGroup >
        {currunt?.options.map((item)=>{
         return(
          <FormControlLabel key={item.id} disabled={this.state.disable} value={item.id} control={<Radio size="small" data-test-id="option-id" onChange={(e)=>this.radioChange(e,questionId,index)}/>} label={item.label} />
          )
        })}
        </RadioGroup>
      </FormControl>
    </RadioWrapper>
     
    </>
    )
  }

  renderAnswer = (type: string, questionId: string,index: number) => {
    if (type === "free_text") {
      return this.renderField(questionId,index);
    } else if (type === "MCQ") {
      return this.renderMcq(questionId,index);
    } else if (type === "TnF") {
      return this.renderRadio(questionId,index);
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {this.state.loading && <Loader loading={this.state.loading} />}
      <Wrapper display="flex" flexDirection="column" bgcolor="white" sx={{ width: { xs: "90%", md: "100%" }, margin: "auto", }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" padding="20px">
            <Box component="p" sx={{ fontSize: "20px", fontWeight: "bold" }} m={0} color="#005487" className="title-text">{this.state.testName}</Box>
          </Box>
          <Divider className="divider" />
          <Box borderRadius={"12px"} bgcolor={"#fff"} padding={"24px"}>
          <Box className="gap10" bgcolor={"#6f6d6d1f"} borderRadius={"12px"} display={"flex"} flexDirection={"column"} p={"10px"} >
             {!this.state.disable &&  <Box bgcolor={"#fff"} borderRadius={"12px"} p={"24px"}>
                <Box component={"p"} fontSize={"16px"} fontWeight={"bold"} m={0}>{configJSON.test_type}</Box>
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                  <Box component={"p"} fontSize={"16px"} color="#808080" m={0}>{configJSON.anonymus}</Box>
                  <SWITCH checked={this.state.test_type} onChange={this.handleAnnonymus} disabled={this.state.disable}/>
                </Box>
              </Box>}
            {this.state.questions.length > 0 && this.state.questions.map((data, index: number) => {
              return(
                <Box bgcolor={"#fff"} key={`${index + 1}`} p={"24px"} borderRadius={"12px"}>
                  <Box component={"p"} fontSize={"16px"} my={"8px"} className="quetion-render" fontWeight={"bold"}>{index + 1}. {data.question}{(data.required) && "*"}</Box>
                  {this.renderAnswer(data.type,data.id,index)}
                  {this.state.required.includes(index) && <Box component="p" color="red" fontFamily="Verdana" fontSize="14px" m={0}>{"This queistion is required"}</Box>}
                </Box>
              )
            })}
            </Box>
          <Box display="flex" alignItems="center" justifyContent="center" padding="20px">
            <Button
              variant="contained"
              data-test-id="submit-id"
              className="submit-btn"
              disabled={this.state.disable}
              onClick={this.handleSubmit}
            >
              {configJSON.btn_submit}
            </Button>
            </Box>
          </Box>
        </Wrapper>
        </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  borderRadius: "12px",
  fontFamily: "Verdana",
  "& .pointer": {
    cursor: "pointer",
  },
  "& .gap10": {
    gap: "10px"
  },
  "& .submit-btn": {
    width: "70%",
    color: "#fff",
    maxWidth: "556px",
    height:"56px",
    fontFamily: "Verdana",
    fontWeight: "bold",
    backgroundColor: "#005487",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "2px solid #f4f4f4",
  },
  "& .MuiOutlinedInput-root:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid black",
    },
  }
});

const RadioWrapper = styled(Box)({
  "& .MuiButtonBase-root": {
    padding: "3px !important"
  },
  "& .MuiRadio-colorSecondary.Mui-checked":{
    color: "#005487"
  }
});

const SWITCH = styled(Switch)({
  width: "24px",
  height: "16px",
  margin: 0,
  padding: 0,
  overflow: "unset",
  "& .Mui-checked": {
    left: "-12px",
    color: "#005487",
  },
  "& .MuiSwitch-thumb": {
    width: "9px",
    height: "9px",
    border: "1px solid #000000",
    borderRadius: "50%",
    margin: "2px"
  },
  "& .MuiButtonBase-root": {
    margin: 0,
    padding: 0,
  },
  "& .MuiSwitch-track": {
    height: "14px",
    opacity: "0.65",
    border: "1px solid #000000",
    borderRadius: "9px",
    backgroundColor: "transparent",
  },
  "& .Mui-checked + .MuiSwitch-track": {
    border: "1px solid #005487",
    backgroundColor: "transparent",
  },
  "& .Mui-checked .MuiSwitch-thumb": {
    border: "1px solid #005487"
  }
});
export default AssessmentTest
// Customizable Area End
