export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const SideImage = require("../assets/SignUpSideImg.png");
export const SideImg = require("../assets/SignUpSideImage.png");
export const Logo = require("../assets/Logo.png");
export const Camera = require("../assets/camera.png");
export const Community = require("../assets/community.png");
export const UserIcon = require("../assets/userIcon.svg");
export const UserIconOne = require("../assets/UserIconOne.png");
export const Link = require("../assets/link.png");
export const LinkFill = require("../assets/link_fill.png");
export const More = require("../assets/more.png");
export const Notification = require("../assets/notification.png");
export const PostUser = require("../assets/post_user.png");
export const Send = require("../assets/send.png");
export const SendFill = require("../assets/send_fill.png");
export const User = require("../assets/user.png");
export const Post = require("../assets/post.png");
export const Home = require("../assets/home.png");
export const CommentFill = require("../assets/comment_fill.png");
export const Mail = require("../assets/mail.svg");
export const Lock = require("../assets/lock.svg");
export const EditIcon = require("../assets/edit.svg");
export const PhoneIcon = require("../assets/phone.svg");
export const PronounsIcon = require("../assets/pronounce.svg");
export const CalendarIcon = require("../assets/calendar.svg");
export const LanguageIcon = require("../assets/language.svg");
export const RaceIcon = require("../assets/race.svg");
export const EthnicityIcon = require("../assets/ethnicity.svg");
export const GenderIcon = require("../assets/gender.svg");
export const TimeIcon = require("../assets/time.svg");
export const NameIcon = require("../assets/namecard.svg");
export const WebsiteIcon = require("../assets/website.svg");
export const AddIcon = require("../assets/add.svg");
export const SocialIcon = require("../assets/social.svg");
export const LocationIcon = require("../assets/location.svg");
export const CloseIcon = require("../assets/close.svg");
export const PngFile = require("../assets/png-file-format.png")
export const leftarrow= require("../assets/left-arrow.svg")
export const Play = require("../assets/play.png")
export const editIcon = require("../assets/editIcon.svg")
export const closeSquare = require("../assets/close_square.svg")
export const info = require("../assets/info-circle.svg");
export const message = require("../assets/messages.svg");
export const ticket = require("../assets/Ticket.svg");
export const clipboard = require("../assets/clipboard-text.svg");
export const infoImg = require("../assets/infoImg.png");
export const pngExtension = require("../assets/png_extension.svg");
export const pdfExtension = require("../assets/pdf_extension.svg");
export const jpgExtension = require("../assets/jpg_extension.svg");
export const jpegExtension = require("../assets/jpeg_extension.svg");
export const deleteIcon = require("../assets/delete_icon.svg");
export const wordExtension = require("../assets/word_extension.svg");
export const LinkSvg = require("../assets/Link.svg");
export const GallerySlash = require("../assets/gallery-slash.svg");