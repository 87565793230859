import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
/*istanbul ignore next */

export default class WelcomeNewController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let userData = await localStorage.getItem("LoggedInUserData");

    if (userData) {
      this.props.navigation.navigate("LandingScreen");
    }
  }

  handleChange = () => {
    // this.props.navigation.history.push("/Login")
    this.props.navigation.navigate("Login");
  };
  // Customizable Area End
}
