import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/utils";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    menuItems: { id: number, value: string, label: string }[];
    testName: { value: string, error: string, edit: boolean };
    questions: { question: string, type: string, is_score_required: boolean, required: boolean, complated: boolean, id: string, options: {option_text:string,score:number}[] }[];
    error: { question: string, type: string, id: number };
    submitError: string;
    testId: string;
    isComplated: boolean;
    deleteIds: string[];
    loading: boolean;
    deletePopup: number;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CreateQuestionController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    createTestApi: string = "";
    getQuizApi: string = "";
    deleteQuestionApi: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading:false,
            menuItems: [
                {
                    id: 1,
                    label: "MCQ",
                    value: "MCQ",
                },
                {
                    id: 2,
                    label: "True or False",
                    value: "TnF",
                },
                {
                    id: 3,
                    label: "Free Text",
                    value: "free_text",
                },
            ],
            testName: { value: "", error: "", edit: true },
            questions: [{ question: "", type: "", required: false,is_score_required: false, complated: false, id: "", options: [{option_text:"",score:NaN}, {option_text:"",score:NaN}] }],
            error: { question: "", type: "", id: NaN },
            submitError: "",
            testId: "",
            isComplated: false,
            deleteIds: [],
            deletePopup: NaN,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
                this.setState({ loading: false})
                if (apiRequestCallId == this.createTestApi) return this.createTestApiApiResponse(responseJson)
                if (apiRequestCallId == this.getQuizApi) return this.getQuizApiResponse(responseJson)
            }
        }
    }

    createTestApiApiResponse = (responseJson:{ data?: { id: string }[] , errors?: { message: string }[] }) => {
        if (responseJson.data) {
            if (this.state.testId) {
                toast.success("Test updated successfully")
            } else {
                toast.success("Test created successfully")
            }
            const message = new Message(getName(MessageEnum.NavigationAssessmentTestViewListMessage));

            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

            this.send(message);
        } else {
            toast.error(responseJson.errors?.[0].message)
        }
    }

    getQuizApiResponse = (responseJson: {
        data: {
            quize_details: {
                data: {
                    id: string;
                    type: string;
                    attributes: {
                        title: string;
                        test_type: boolean;
                        test_type_description: string;
                        created_at: string;
                        updated_at: string;
                        quiz_questions: {
                            data: {
                                id: string;
                                type: string;
                                attributes: {
                                    question: string;
                                    is_score_required: boolean;
                                    question_format: string;
                                    is_required: boolean;
                                    created_at: string;
                                    updated_at: string;
                                    quiz_question_options: {
                                        data: {
                                            id: string;
                                            type: string;
                                            attributes: {
                                                option_text: string;
                                                question_id: string;
                                                created_at: string;
                                                score: number;
                                                updated_at: string;
                                            };
                                        }[];
                                    };
                                };
                            }[];
                        };
                    };
                };
            };
        };
    }
    ) => {
        if (responseJson.data) {
            const val = responseJson.data.quize_details.data.attributes;
            const existingQuestion = val.quiz_questions.data.map(item => {
                const que = item.attributes;
                const options = que.quiz_question_options.data.map((item) => ({option_text: item.attributes.option_text,score:item.attributes.score}))
                return {
                    id: item.id,
                    question: que.question,
                    type: que.question_format,
                    required: que.is_required,
                    complated: true,
                    is_score_required: que.is_score_required,
                    options: options
                }
            })
            this.setState({ questions: [...existingQuestion], testName: { value: val.title, error: "", edit: true } })
        }
    }

    async componentDidMount() {
        super.componentDidMount()
        const id = this.props.navigation.getParam("navigationBarTitleText");
        if (id &&id!=="undefined") {
            const loginToken = await getStorageData('LoginSuccessToken');
            const signupToken = await getStorageData('SignupSuccessToken');
            this.getQuizApi = sendAPIRequest(configJSON.getQuizEnd + id, {
                method: configJSON.GetApi,
                headers: {
                    "Content-Type": configJSON.apiContentType,
                    token: signupToken || loginToken
                }
            });
            this.setState({ testId: id, loading: true })
        }
    }

    renderValue = (selected: string) => {
        if (!selected || selected.length === 0) {
            return "Select";
        }
        return selected;
    };

    //answer type of selected question
    handleChangeDropdownValue = (e: React.ChangeEvent<HTMLSelectElement>, index: number) => {
        const { value } = e.target;
        const newData = [...this.state.questions];
        newData[index].type = value;
        this.state.error.id === index && this.setState({ error: { ...this.state.error, type: "" }, deletePopup: NaN });
        this.setState({ questions: [...newData], deletePopup: NaN })
    };

    //change question 
    handleChangeQuestion = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = e.target;
        const newData = [...this.state.questions];
        newData[index].question = value;
        this.state.error.id === index && this.setState({ error: { ...this.state.error, question: ""}, deletePopup: NaN });
        this.setState({ questions: [...newData], deletePopup: NaN })
    }

    //change test name
    handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ testName: { value: e.target.value, error: "", edit: true}, deletePopup: NaN })
    }

    //add new question
    handleAddQuestion = () => {
        const temp = { question: "", complated: false, is_score_required: false, type: "", required: false, id: "", options: [{option_text:"",score:NaN}, {option_text:"",score:NaN}] };
        this.setState({ questions: [...this.state.questions, temp], submitError: "", deletePopup: NaN })
    }

    //delete question
    handleDeleteQuestion = (index: number, questionId: string) => {
        if (this.state.questions.length === 1) {
            // Ensure there is always at least one element
            const temp = { question: "", type: "",is_score_required: false, required: false, complated: false, id: "", options: [{option_text:"",score:NaN},{option_text:"",score:NaN}] };
            this.setState({ questions: [temp], deleteIds: [...this.state.deleteIds, questionId], deletePopup: NaN })
        } else {
            const newData = [...this.state.questions];
            newData.splice(index, 1);
            this.setState({ questions: [...newData], deleteIds: [...this.state.deleteIds, questionId], deletePopup: NaN })
        }
    };

    // open  delete pop up
    handleDeleteOpen = (index: number) => {
        this.setState({deletePopup: index})
    }

    // close delete popup
    handleDeleteCancel = () => {
        this.setState({deletePopup: NaN})
    }

    //change required status of question
    handleRequired = (index: number) => {
        const newData = [...this.state.questions];
        newData[index].required = !newData[index].required;
        this.setState({ questions: [...newData], deletePopup: NaN })
    }

    //copy existing question
    handleCopy = (index: number) => {
        const newData = { ...this.state.questions[index] };
        this.setState({ questions: [...this.state.questions, newData], deletePopup: NaN })
    }

    //check individual question
    complateQuestion = (index: number) => {
        const { question, type } = { ...this.state.questions[index] };
        const error = { question: "", type: "", id: index }
        if (!question) error.question = "Enter Question";
        if (!type) error.type = "Add answer details";
        this.setState({ error: error, deletePopup: NaN });
        if (type === "MCQ") {
            return this.checkMcqValidation(index);
        } else if (question && type) {
            const newData = [...this.state.questions];
            newData[index].complated = true;
            this.setState({ questions: [...newData], submitError: "", deletePopup: NaN })
        }
    }

    //for set score toggal on/off
    handleScoreSwitch = (index:number) =>{
        const newData = [...this.state.questions];
        newData[index].is_score_required = !newData[index].is_score_required
        this.setState({questions: [...newData]})
    }

    checkMcqValidation = (index: number) => {
        const { question, type, options, is_score_required } = { ...this.state.questions[index] };
        let error = { question: "", type: "", id: index }
        options.forEach(element => {
            if (!element.option_text){
                error.type = "Add answer details";
            } else if (is_score_required && (element.score < 0 || isNaN(element.score))) {
                error.type = "Add score details";
            }
        });
        
        this.setState({ error: { ...this.state.error, type: error.type, id: index } });
        if (question && type && !error.type) {
            const newData = [...this.state.questions]
            newData[index].complated = true;
            this.setState({ questions: [...newData], submitError: "", deletePopup: NaN });
        }
    }

    //add option of MCQ
    handleAddOption = (index: number) => {
        const newData = [...this.state.questions]
        if (newData[index].options.length === 6) return
        newData[index].options = [...newData[index].options, {option_text:"",score:NaN}];
        this.setState({ questions: [...newData], deletePopup: NaN })
    }

    //change option of MCQ
    handleChangeOption = (e: React.ChangeEvent<HTMLInputElement>, index: number, quetionId: number) => {
        const { value } = e.target;
        const newData = [...this.state.questions];
        newData[quetionId].options[index].option_text = value;
        this.state.error.id === quetionId && this.setState({ error: { ...this.state.error, type: "" }, deletePopup: NaN });
        this.setState({ questions: [...newData], deletePopup: NaN })
    }

    //change score of option
    handleChangeScore = (e: React.ChangeEvent<HTMLInputElement>, index: number, quetionId: number) => {
        const { value } = e.target;
        const parsedValue = parseFloat(value); // Convert the value to a number
        const newData = [...this.state.questions];
        if (!isNaN(parsedValue)) {
            newData[quetionId].options[index].score = parsedValue;
        } else {
            newData[quetionId].options[index].score = NaN;
        }
        this.state.error.id === quetionId && this.setState({ error: { ...this.state.error, type: "" }, deletePopup: NaN });
        this.setState({ questions: [...newData], deletePopup: NaN })
    }

    //Edit question
    handleEdit = (index: number) => {
        const newData = [...this.state.questions]
        newData[index].complated = false;
        this.setState({ questions: [...newData], deletePopup: NaN })
    }

    apiBody = () => {
        const questionsList = this.state.questions.map((item) => {
            return ({
                question_id: item.id,
                question_format: item.type,
                question: item.question,
                is_required: item.required,
                is_score_required: item.is_score_required,
                ...(item.type !== "free_text" ? { question_options_attributes: item.type === "MCQ" ? item.options.map(op => { return { option_text: op.option_text, score: op.score } }) : [{ "option_text": false }, { "option_text": true }] } : {}),
            })
        })
        if (this.state.testId) {
            const data = {
                data: {
                    quiz_id: this.state.testId,
                    title: this.state.testName.value,
                    questions_attributes: [...questionsList]
                }
            }
            return data;
        } else {
            const data = {
                data: {
                    title: this.state.testName.value,
                    questions_attributes: [...questionsList]
                }
            };
            return data
        }
    }

    //submit test
    handleSubmit = async () => {
        if (!this.state.testName.value) {
            return this.setState({ testName: { ...this.state.testName, error: "enter test name" } });
        }
        const temp = this.state.questions.filter(item => !item.complated)
        if (temp.length > 0) return this.setState({ submitError: "Complete all questions first" });

        const body = this.apiBody();
        const apiEnd = this.state.testId ? configJSON.updateTestEnd : configJSON.creatTestEnd;
        const signupToken = await getStorageData('SignupSuccessToken');
        const loginToken = await getStorageData('LoginSuccessToken');
        this.createTestApi = sendAPIRequest(apiEnd, {
            method: configJSON.PostApi,
            body: body,
            headers: {
                "Content-Type": configJSON.apiContentType,
                token: signupToken || loginToken
            }
        });
        this.setState({ loading: true, deletePopup: NaN})
        this.handleDeleteFromApi();
    }

    //create draft test
    handleDraft = async () => {
        const temp = this.state.questions.filter(item => !item.complated)
        if (!this.state.testName.value) {
           return this.setState({ testName: { ...this.state.testName, error: "enter test name" } })
        }
        if (temp.length > 0) return this.setState({ submitError: "complate above quetions first" });
        const tempBody = this.apiBody();
        const body = { data: { ...tempBody.data, draft: true } };
        const signupTokn = await getStorageData('SignupSuccessToken');
        const loginTokn = await getStorageData('LoginSuccessToken');
        const apiEnd = this.state.testId ? configJSON.updateTestEnd : configJSON.creatTestEnd;
        this.createTestApi = sendAPIRequest(apiEnd, {
            method: configJSON.PostApi,
            body: body,
            headers: {
                "Content-Type": configJSON.apiContentType,
                token: signupTokn || loginTokn
            }
        });
        this.setState({ loading: true, deletePopup: NaN});
        this.handleDeleteFromApi();

    }

    //delete quetion fromdataase at time of edit
    handleDeleteFromApi = async() => {
        if (this.state.deleteIds.length > 0 && this.state.testId) {
        const signupTokn = await getStorageData('SignupSuccessToken')
        const loginTokn = await getStorageData('LoginSuccessToken');
            this.state.deleteIds.forEach(id => {
                this.deleteQuestionApi = sendAPIRequest(configJSON.deleteEnd, {
                    method: configJSON.deleteApi,
                    body: {
                        "question_id": id
                    },
                    headers: {
                        "Content-Type": configJSON.apiContentType,
                        token: signupTokn || loginTokn
                    }
                });
            });
        }
    }
    // Customizable Area End
}
