// Customizable Area Start
import React from "react";
import { createStyles, Theme, styled } from "@material-ui/core/styles";
import CommunityListingController, {
  Props,
} from "./CommunityListingController.web";
import { Typography, Box, Grid, Button } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import SinglePost from "../../ActivityFeed/src/SinglePost.web";
import { Add } from "./assets";

import Loader from "../../../components/src/Loader.web";
// Customizable Area End

// Customizable Area Start

const Poststyles = (theme: Theme) =>
  createStyles({
    title: {
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
      display: "none",
    },
    descriptionEllipsis: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    descriptionFull: {
      display: "block",
      wordWrap: "break-word",
    },
    cursorPointer: {
      cursor: "pointer",
    },
    urlLink: {
      textDecoration: "underline",
      display: "block",
      wordWrap: "break-word",
    },
    anchorLink: {
      textDecoration: "none",
      color: "#808080",
      marginLeft: "1rem",
    },
  });
// Customizable Area End
export class CommunityListing extends CommunityListingController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area End
  render() {
    // Customizable Area Start

    return (
      <>
        {this.state.loading ? (
          <Loader loading={this.state.loading} />
        ) : (
          <>
            {this.state.communityData?.map(
              (datas: any) =>
                datas?.attributes?.community_post?.data && (
                  <SinglePost
                    followUpdatedCommunityTab={this.followUpdatedCommunityTab}
                    id=""
                    navigation={"fd"}
                    Dataa={datas.attributes?.community_post?.data}
                    communityFlag={true}
                    communityFollow={datas.attributes?.is_followed_by_user}
                    communityData={this.state.communityData}
                  />
                )
            )}
          </>
        )}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webPage = {
  upcomming: {
    display: "flex",
    justifyContent: "space-around",
    bgcolor: "#fff",
    alignItems: "center",
    borderRadius: "12px",
    background: "#fff",
    padding: "10px",
    marginBottom: "3px",
  },
  cardMedia: {
    height: 0,
    paddingTop: "100%", // Maintain a 1:1 aspect ratio (change this value to adjust the aspect ratio)
  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },
  imgStyle: {
    width: "20px",
    cursor: "pointer",
  },
  Publishbtn: {
    background: "#01548a",
    color: "#fff",
    width: "95%",
    borderRadius: "0px",
    padding: "18px",
    margin: "9px",
  },
  InterestBtn: {
    background: "#fff",
    color: "#01548a",
    width: "95%",
    borderRadius: "0px",
    padding: "18px",
    margin: "9px",
    borderColor: "#01548a",
  },
  cardActionIcon: {
    marginTop: "9px",
    marginRight: "9px",
    width: "20px",
    // cursor: "pointer"
  },
  commentStyle: {
    background: "#F4F4F4",
    padding: "10px",
    borderRadius: "10px",
  },
  CommentuserimgStyle: {
    width: "40%",
  },
  commentMsg: {
    borderTop: "1px solid #e4e4e4",
    marginTop: "10px",
  },
  messageField: {
    background: "#e4e4e4",
    borderRadius: "20px",
    width: "100%",
  },
  greyDot: {
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    backgroundColor: "#d9d9d9",
    display: "inline-block",
    margin: "0px 5px",
  },
  mediaPost: {
    maxWidth: "100%",
    height: "300px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
};

const PointerBox = styled(Box)({
  "& .pointer": {
    cursor: "pointer",
  },
});
export default CommunityListing;
// Customizable Area End
