// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  InputAdornment,
  Grid,
  FormControl,
  TextField,
} from "@material-ui/core";
import ForgotPasswordPhoneVerificationController, {
  Props
} from "./ForgotPasswordPhoneVerificationController.web";
import { SideImage, Logo, Mail, Phone } from "./assets";
import { styled } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";

// Customizable Area End

// Customizable Area Start
// Customizable Area End
  
export default class ForgotPasswordPhoneVerification extends ForgotPasswordPhoneVerificationController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {this.state.loading && <Loader loading={this.state.loading} />}
        <Wrapper container>
          <Grid item xs={false} md={5} component={Box} sx={{ height: "100vh", display: { xs: 'none', md: 'block' } }}>
            <img src={SideImage} width="100%" height="100%" alt="side-panel" />
          </Grid>

          <Grid item xs={12} md={7} className={"scrollWrapper"}>
            <Box component={'section'} sx={{ width: "85%", maxWidth: "500px", paddingY: '20px', }}>
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'} >
                <Box mb={{ xs: "50px", md: '65px' }} display={'flex'} flexDirection={'column'} alignItems={'center'} >
                  <img src={Logo} alt="logo-img" className={"LoginLogoImg"} />
                  <Box component={'h1'} fontFamily={"Veradana"} fontSize={"45px"} color={"#005487"} fontWeight={'bold'} m={0} my={{ xs: "30px", md: '40px' }}>Forgot Password</Box>
                  <Box component={'h2'} fontFamily={"Veradana"} fontSize={"20px"} color={"#808080"} fontWeight={'normal'} textAlign={'center'} m={0}>
                    {this.state.type === 'email' ? (
                      <>
                        Enter your E-mail ID to Reset password.
                      </>
                    ) : (
                      <>
                        Enter your Phone Number to receive a <br /> One Time Password.
                      </>
                    )}
                  </Box>
                </Box>
                {this.state.type === 'email' &&
                  <form onSubmit={this.handleEmailSubmit} className={"form"} data-test-id="publish-btn">
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        data-test-id="email"
                        size="medium"
                        placeholder="Email ID"
                        className={"inputfield"}
                        name="email"
                        type="email"
                        autoFocus={true}
                        error={Boolean(this.state.errorMessage)}
                        onChange={this.handleChangeEmail}
                        value={this.state.email}
                        fullWidth
                        helperText={this.state.errorMessage}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" >
                              <img src={Mail} alt="email" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                    <Box component={'p'} fontFamily={"Veradana"} fontSize={"17px"} m={0} mt={'10px'} textAlign={'end'} className="redirectEmail" onClick={this.changeType} data-test-id="change-type">Reset Password using Phone?</Box>
                    <Button variant="contained" type="submit" className={"publishBtn"}>Submit</Button>
                  </form>
                }

                {this.state.type === 'phone' &&
                  <>{this.state.isOtpSent ?
                    <form onSubmit={this.handleOtpSubmit} className={"form"} data-test-id="verify-btn">
                      <Box display="flex" justifyContent={'center'}  alignItems={"center"} gridGap={"20px"}>
                        {Array.from({ length: 4 }).map((_, index) => (
                          <FormControl key={index}>
                            <TextField
                              inputRef={this.textInputRefs[index]}
                              data-test-id={`txtOTPpin`}
                              placeholder=""
                              size="medium"
                              inputProps={{
                                maxLength: 1,
                                pattern: "\\d*",
                              }}
                              onKeyDown={(e) => this.handleKeyDown(e, index)}
                              autoFocus={index === 0}
                              onChange={(e) => this.handleOTPInputChange(e, index)}
                              value={this.state.otpFormFields[index]}
                              className={"otpField"}
                              variant="outlined"
                              type="number"
                            />
                          </FormControl>
                        ))}
                      </Box>

                      {this.state.isDisabled ? (
                        <Box component={'p'} fontFamily={"Veradana"} fontSize={"14px"} color={"red"} textAlign={'center'} fontWeight={'bold'} m={0} mt={'16px'}>
                          00:{this.state.otpTimer}
                        </Box>
                      ) : (

                        <Box component={'p'} fontFamily={"Veradana"} fontSize={"14px"} color={"#808080"} textAlign={'center'} m={0} mt={'16px'}>Don&#8217;t receive code? <span className={"resend"} data-test-id="resend-id" onClick={this.resendOtp}>Resend.</span></Box>
                      )}
                      <Button variant="contained" type="submit" data-test-id="verify" className={"publishBtn"}>Verify</Button>
                    </form>
                    :
                    <form onSubmit={this.handlePhoneNumberSubmit} className={"form"} data-test-id="publish-btn">
                      <FormControl fullWidth>
                        <TextField
                          variant="outlined"
                          data-test-id="fullPhoneNumber"
                          size="medium"
                          placeholder="Phone Number"
                          className={"inputfield"}
                          name="fullPhoneNumber"
                          type="number"
                          autoFocus={true}
                          error={Boolean(this.state.errorMessage)}
                          onChange={this.handlePhoneNumberChange}
                          value={this.state.fullPhoneNumber}
                          fullWidth
                          helperText={this.state.errorMessage}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" >
                                <img src={Phone} alt="phone" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                      <Box component={'p'} fontFamily={"Veradana"} fontSize={"17px"} m={0} mt={'10px'} textAlign={'end'} className="redirectEmail" onClick={this.changeType} data-test-id="change-type">Reset Password using Email?</Box>
                      <Button variant="contained" type="submit" className={"publishBtn"}>Submit</Button>
                    </form>
                  }
                  </>}
                <Box component={'p'} fontFamily={"Veradana"} fontSize={"17px"} color={"#808080"} fontWeight={'bold'} m={0} mt={'30px'}>Go back to <span className="redirect" data-test-id="redirect-id" onClick={this.handleLogin}>LogIn?</span></Box>
              </Box>
            </Box>
          </Grid>
        </Wrapper>
      </>
      // Customizable Area End

    );
  }}

// Customizable Area Start
const Wrapper = styled(Grid)({
  "& .scrollWrapper": {
    display:'grid',
    placeItems: 'center',
    height: '100vh',
    overflowY: 'auto',
    background: '#fff',
  },
  "& .rightSection": {
    display: 'flex',
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  "&. pointer": {
    cursor: 'pointer',
  },
  "& .LoginLogoImg": {
    width: "250px",
    height: "250px",
    borderRadius: '50%',
    boxShadow: "0 4px 8px 0 rgba(32, 32, 32, 0.2)",
  },
  "& .inputfield": {
    "& .MuiOutlinedInput-input": {
      paddingBlock: "20px",
    }
  },
  "& .publishBtn": {
    height: "56px",
    fontSize: "16px",
    fontWeight: "bold",
    width: "100%",
    color: "#fff",
    fontFamily: "Verdana",
    textTransform: "none",
    backgroundColor: "#005487",
    marginTop: "246px",
    "&:hover": {
      backgroundColor: "#005487",
    },
    "@media(max-width:600px)": {
      marginTop: "200px",
    },
  },
  "& .redirect": {
    textDecoration: 'underline',
    color: '#005487',
    fontWeight: 'bold',
    textUnderlineOffset: '5px',
    cursor: 'pointer',
  },
  "& .redirectEmail": {
    textDecoration: 'underline',
    color: '#005487',
    textUnderlineOffset: '5px',
    cursor: 'pointer',
  },
  "& .resend": {
    color: '#005487',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  "& .otpField": {
    width:'60px',
    "& .MuiOutlinedInput-input": {
      paddingBlock: "20px",
    }
  },
  "& .form": {
    width: '100%',
    margin: 0
  }
})
// Customizable Area End
