import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { setStorageData } from "../../../framework/src/Utilities"
import { getNotificationPermission } from "../../../components/src/firebase";
import { handleErrorArray } from "../../../components/src/common";
// Customizable Area End
// Customizable Area Start
export const configJSON = require("./config");
export const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
export const validPasswordRegex= RegExp(/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}\[\]|\\:;"'<,>.?/~`])(?!.*\s).{8,}$/);
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  agree: boolean,
  errors: {
    email: string;
    password: string;
    password_confirmation: string;
    agreeError: string;
  };
  data: {
    email: string;
    password: string;
    password_confirmation: string;
  };
  icon:{
    first: boolean;
    second: boolean
  };
  modalVisible:boolean,
  isLoading: boolean,
// Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}


export default class SignInNewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createAccountApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
     
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
   
    ]

    this.state = {
      agree: false,
      errors: {
        email: '',
        password: '',
        password_confirmation: '',
        agreeError: ''
      },
      icon:{
        first: false,
        second: false
      },
      data: {
        email: '',
        password: '',
        password_confirmation: '',
      },
      modalVisible: false,
      isLoading: false,
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    // Customizable Area End
  }
  // Customizable Area Start
  
  handleFieldChange=(e: React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({data:{...this.state.data,[e.target.name]:e.target.value}})
    if(e.target.name === 'password'){
      const same = this.state.data.password_confirmation === e.target.value ? '' : this.state.errors.password_confirmation;
      const valid  = !validPasswordRegex.test(e.target.value) ? 'Password should contain minimum 8 characters, 1 uppercase, 1 number, 1 special character' : ''
      this.setState({errors:{...this.state.errors,password:valid,password_confirmation: same}})
    }
    if(e.target.name === 'password_confirmation'){
      const same = this.state.data.password !== e.target.value ? "Passwords did not match": '';
      this.setState({errors:{...this.state.errors,password_confirmation:same}})
    }
    if(e.target.name==='email'){
      this.setState({errors:{...this.state.errors,email:''}})
    }
  }
  eyeChangeConfPass=()=>{
    this.setState({icon:{...this.state.icon,second:!this.state.icon.second}})
  }
  eyeIconPass=()=>{
    this.setState({icon:{...this.state.icon,first:!this.state.icon.first}})
  }
  handleLogin=()=>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LogIn");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    
    this.send(message);
    }
    

  setAgree = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ agree: event.target.checked,errors:{...this.state.errors,agreeError:''}});
  };

handleOpen=()=>{
  this.setState({modalVisible: true});
}

  handleAcceptBtn = () => {
       this.setState({modalVisible:false})
  }


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.createAccountApiCallId) {
        if (!responseJson.errors) {
          setStorageData("SignupSuccessToken", responseJson.meta.token)
          const message = new Message(getName(MessageEnum.NavigationMessage));
          message.addData(getName(MessageEnum.NavigationTargetMessage), "PhoneVerificationLoginFlow");
          message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
          message.addData(
            getName(MessageEnum.NavigationScreenNameMessage),
            "issignup"
          )
          this.send(message);
        } else {
          handleErrorArray(responseJson.errors)
        }
      }
    }

  }
  checkRequiredfield = () => {
    let errors = {
      agreeError: '',
      email: '',
      password: '',
      password_confirmation: '',
    };
    const passArray = ["P", "a", "s", "s", "w", "o", "r", "d"];

    const { password, password_confirmation, email } = this.state.data
    if (!email || !validEmailRegex.test(email)) {
        errors.email = "Please Enter Valid Email";
    }

    if (!password && !validPasswordRegex.test(password)) {
        errors.password = configJSON.please_enter_valid_password + passArray.map(char => char).join('');
    }

    if(password !== password_confirmation){
      errors.password_confirmation = passArray.map(char => char).join('') + configJSON.passwords_did_not_match;
    }

    if (!password_confirmation) {
        errors.password_confirmation =configJSON.please_enter_confirm_password + passArray.map(char => char).join('');
    }

    if(!this.state.agree){
      errors.agreeError = "Please Accept Terms and Conditions"
    }

    const hasErrors = Object.values(errors).some(value => value !== '');
    this.setState({errors: errors});
    return hasErrors
  }
  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const hasErrors = this.checkRequiredfield();
    if (hasErrors) return
    this.setState({ isLoading: true });

    const deviceId = await getNotificationPermission();

    const attrs = {
      email: this.state.data.email,
      password: this.state.data.password,
      password_confirmation: this.state.data.password_confirmation,
      device_id: deviceId ?? ""
    };

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createAccountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPointWeb,

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody),

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail,

    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
 // Customizable Area End

}