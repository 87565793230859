import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  MenuItem,
  Menu,
  Link
} from "@material-ui/core";
import { styled } from '@material-ui/core/styles';
import { note, eventReminder, calender, user, people, more, medal, message } from "./assets";
import { ResponseNotification, } from "./NotificationsController";
import moment from "moment";
import Loader from "../../../components/src/Loader.web";
import IconButton from "@material-ui/core/IconButton";
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMenu = (id: string) => {
    const open = Boolean(this.state.anchorEl);
    return (
      <MenuContainer id={`list-menu-box`} key={`list-menu-box-${id}`}>
        <IconButton
          id="demo-positioned-button"
          aria-controls={this.state.anchorEl ? "demo-positioned-menu" : undefined}
          aria-haspopup="true"
          className="icon-btn"
          aria-expanded={this.state.anchorEl ? "true" : undefined}
          onClick={(e) => this.handleEnchorClick(e,id)}
        >
          <img src={more} alt="more" />
        </IconButton>
        <Menu
          id={`list-menu-${id}`}
          key={`list-menu-${id}`}
          aria-labelledby="demo-positioned-button"
          anchorEl={this.state.anchorEl}
          open={open}
          onClose={this.handleCloseEnchor}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            style: { borderRadius: '12px' }
          }}
        >
          <MenuItem key={`list-menu-item-${id}`} onClick={this.handleMenuClick}>
            <Box
              padding={"7px"}
              display={"flex"}
              marginRight={"8rem"}
            >
              <Box component="p" fontFamily={"Verdana"} fontWeight={"bold"} fontSize={"17px"} m={0}>
                {configJSON.remove}
              </Box>
            </Box>
          </MenuItem>
        </Menu>
      </MenuContainer>
    )
}

  renderPostCreationNotification = (item: ResponseNotification) => {
    return (
      <Box style={webStyle.mainContainer}  >
        <Box style={webStyle.mainBox}>
          <Box style={webStyle.notificationIcon}>
            <img src={!item.attributes.notificable?.community_id ? user : people} alt="cardImg" />
          </Box>
          <Box width={"100%"}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box width={"100%"} onClick={() => this.handlePostRedirection(item.attributes.notificable?.id)} data-test-id="post-id">
                <Typography style={webStyle.notificationTitle}>
                  {item.attributes.headings}
                </Typography>
                <Typography style={webStyle.notificationDescription}>
                  <strong style={webStyle.notificationTitle}>{item.attributes.name}</strong> {item.attributes.contents}
                </Typography>
              </Box>
              {this.renderMenu(item.id)}
            </Box>
            <Box style={webStyle.timeBox}>
              <Typography style={webStyle.schedualTime}>
                {moment(new Date(item.attributes.created_at)).format("DD MMM  YYYY, hh:mm a")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

    )
  }

  renderNewAssessmentTest = (item: ResponseNotification) => {
    return (

      <Box style={webStyle.mainContainer}>
        <Box style={webStyle.mainBox}>
          <Box style={webStyle.notificationIcon}>
            <img src={note} alt="cardImg" />
          </Box>
          <Box width={"100%"}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography style={{...webStyle.notificationTitle}}>
                {item.attributes.headings}
              </Typography>
              {this.renderMenu(item.id)}
            </Box>
            <Typography style={{...webStyle.notificationDescription}}>
              {item.attributes.contents}
            </Typography>
            <Typography style={webStyle.deadlineTime}>
              {configJSON.deadline}: {item.attributes.deadline}
            </Typography>
            <Box style={webStyle.mainButton}>
              <Button style={webStyle.button1} data-test-id="newAssessmentTestDeleted-id" onClick={() => this.deleteNotification(item.attributes.id)}>
                <Typography>{configJSON.Ignore}</Typography>
              </Button>
              <Button style={webStyle.button2} data-test-id="newAssessmentTest-id" onClick={() => this.assessmentTest(item.attributes.notificable?.id)}>
                <Typography>{configJSON.takeTest}</Typography>
              </Button>
            </Box>
            <Box style={webStyle.timeBox}>
              <Typography style={webStyle.schedualTime}>
                {moment(new Date(item.attributes.created_at)).format("DD MMM  YYYY, hh:mm a")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

    );
  };

  renderAssessmentTestReminder = (item: ResponseNotification) => {
    return (
      
        <Box style={webStyle.mainContainer}>
          <Box style={webStyle.mainBox}>
            <Box style={webStyle.notificationIcon}>
              <img src={note} alt="cardImg" />
            </Box>
            <Box width={"100%"}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography style={webStyle.notificationTitle}>
                {item.attributes.headings}
              </Typography>
              {this.renderMenu(item.id)}
              </Box>
              <Typography style={webStyle.notificationDescription}>
                {configJSON.assessmentTasx}{item?.attributes.contents}
              </Typography>
            </Box>
            <Box style={webStyle.timeBox}>
              <Typography style={webStyle.schedualTime}>
                {moment(new Date(item.attributes.created_at)).format("DD MMM  YYYY, hh:mm a")}
              </Typography>
            </Box>
          </Box>
        </Box>
      
    );
  };

  renderPendingAssessmentTest = (item: ResponseNotification) => {
    return (

      <Box style={webStyle.mainContainer}>
        <Box style={webStyle.mainBox}>
          <Box style={webStyle.notificationIcon}>
            <img src={note} alt="cardImg" />
          </Box>
          <Box width={"100%"}>
            <Box justifyContent={"space-between"} display={"flex"}>
              <Typography style={webStyle.notificationTitle}>
                {item.attributes.headings}
              </Typography>
              {this.renderMenu(item.id)}
            </Box>
            <Box style={webStyle.mainButton}>
              <Button style={webStyle.button1} data-test-id="assessmentTestDelite" onClick={() => this.deleteNotification(item.attributes.id)}>
                <Typography>{configJSON.snooze}</Typography>
              </Button>
              <Button style={webStyle.button2} data-test-id="assessmentTest" onClick={() => this.assessmentTest(item.attributes.notificable?.id)}>
                <Typography>{configJSON.takeTest}</Typography>
              </Button>
            </Box>
            <Box style={webStyle.timeBox}>
              <Typography style={webStyle.schedualTime}>
                {moment(new Date(item.attributes.created_at)).format("DD MMM YYYY, hh:mm a")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

    );
  };

  renderEventReminder = (item: ResponseNotification) => {
    return (
      
        <Box style={webStyle.mainContainer} onClick={()=>this.navigateEvent(item.attributes.notificable?.id)} data-test-id="event-navigation">
          <Box style={webStyle.mainBox}>
            <Box style={webStyle.notificationIcon}>
              <img src={eventReminder} alt="cardImg" />
            </Box>
            <Box width={"100%"}>
              <Box display={"flex"} justifyContent={"space-between"}>
              <Typography style={webStyle.notificationTitle}>
                {item.attributes.headings}
              </Typography>
              {this.renderMenu(item.id)}
              </Box>
              <Typography style={webStyle.notificationDescription}>
                {item.attributes.contents} {moment(new Date(String(item.attributes.notificable?.start_time))).format("hh:mm a")}...
              </Typography>
              <Box style={webStyle.timeBox}>
                <Typography style={webStyle.schedualTime}>
                  {moment(new Date(item.attributes.created_at)).format("DD MMM  YYYY, hh:mm a")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      
    )
  }

  renderEventReminderStart = (item: ResponseNotification) => {
    return (
      
        <Box style={webStyle.mainContainer}>
          <Box style={webStyle.mainBox}>
            <Box style={webStyle.notificationIcon}>
              <img src={calender} alt="cardImg" />
            </Box>
            <Box width={"100%"}>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography style={webStyle.notificationTitle}>
                  {item.attributes.headings}
                </Typography>
                {this.renderMenu(item.id)}
              </Box>
              <Typography style={webStyle.notificationDescription}>
                {item.attributes.contents} {moment(new Date(String(item.attributes.notificable?.start_time))).format("hh:mm a")}
              </Typography>
              <Typography style={webStyle.notificationDescription}>
                {configJSON.joinHear} <Box component="span" style={webStyle.pointer} color="#0075a9" data-test-id="event-navigation" onClick={()=>this.navigateEvent(item.attributes.notificable?.id)}>here</Box>
              </Typography>
              <Box style={webStyle.timeBox}>
                <Typography style={webStyle.schedualTime}>
                  {moment(new Date(item.attributes.created_at)).format("DD MMM  YYYY, hh:mm a")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      
    )
  }

  renderScoreUpdated = (item: ResponseNotification) => {
    return (<>
        {(item.attributes.app_url && item.attributes.app_url.toLowerCase().includes('chat')) ? 
          <Box style={webStyle.mainContainer} >
          <Box style={webStyle.mainBox}  >
            <Box style={webStyle.notificationIcon}>
              <img src={message} alt="message" />
            </Box>
            <Box width={"100%"}>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box width={"100%"} onClick={() => this.handleChatOpen(item.attributes.app_url)} data-test-id="chat-id">
                  <Typography style={webStyle.notificationTitle}>
                    Chat notification
                  </Typography>
                  <Typography style={webStyle.notificationDescription}>
                    <strong style={webStyle.notificationTitle} >
                      {item.attributes.name}</strong>
                       {item.attributes.contents}
                  </Typography>
                </Box>
                {this.renderMenu(item.id) }
              </Box>
              <Box style={webStyle.timeBox} component={'div'}>
                <Typography style={webStyle.schedualTime}>
                  {moment(new Date(item.attributes.created_at)).format("DD MMM  YYYY, hh:mm a")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        :
        <Box style={webStyle.mainContainer}>
          <Box style={webStyle.mainBox}>
            <Box style={{...webStyle.notificationIcon}}>
              <img src={note} alt="cardImg" />
            </Box>
            <Box width={"100%"}>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography style={webStyle.notificationTitle}>
                  {item.attributes.headings}
                </Typography>
                {this.renderMenu(item.id)}
              </Box>
              <Typography style={webStyle.notificationDescription}>
                {item.attributes.contents}
              </Typography>
              <Typography style={webStyle.notificationDescription}>
                {configJSON.scoreyouLike}
              </Typography>
              <Box style={webStyle.mainButton}>
                <Button style={webStyle.button1} data-test-id="scoreUpdate_delete" onClick={() => this.deleteNotification(item.attributes.id)}>
                  <Typography>{configJSON.Ignore}</Typography>
                </Button>
                <Button style={webStyle.button2} data-test-id="scoreUpdate_navigate" onClick={()=>this.scoreUpdate(item.attributes.id)}>
                  <Typography>{configJSON.retake}</Typography>
                </Button>
              </Box>
              <Box style={webStyle.timeBox}>
                <Typography style={webStyle.schedualTime}>
                  {moment(new Date(item.attributes.created_at)).format("DD MMM  YYYY, hh:mm a")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>}
        </>
    )
  }

  renderAssessmentCompletionRates = (item: ResponseNotification) => {
    return (
      <Box style={webStyle.mainContainer}>
        <Box style={webStyle.mainBox}>
          <Box style={webStyle.notificationIcon}>
            <img alt="cardImg" src={note}/>
          </Box>
          <Box width={"100%"}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography style={webStyle.notificationTitle}>
                {item.attributes.headings}
              </Typography>
              {this.renderMenu(item.id)}
            </Box>
            <Box component="p" sx={{ fontSize: "14px", fontFamily: "Verdana" }}>
              {configJSON.completedAssessment}:<strong style={webStyle.linkColor}>{item.attributes.completed_assessment}</strong>
            </Box>
            <Typography style={webStyle.testRate}>
              {configJSON.pendingAssessment}:<strong style={webStyle.linkColor}>{item.attributes.incompleted_assessment}</strong>
            </Typography>
            <Typography style={webStyle.testRate}>
              <strong style={webStyle.linkColor}>{item.attributes.percentage}</strong> {configJSON.percentageAssessment}
            </Typography>
            <Box style={webStyle.timeBox}>
              <Typography style={webStyle.schedualTime}>
                {moment(new Date(item.attributes.created_at)).format("DD MMM  YYYY, hh:mm a")}
              </Typography>
            </Box>
          </Box>
      </Box>
    </Box>
    )
  }

  renderLikeCommentNotification = (item: ResponseNotification) => {
    return (

      <Box style={webStyle.mainContainer} >
        <Box style={webStyle.mainBox}>
          <Box style={webStyle.notificationIcon}>
            <img src={item.attributes.notificable?.community_id ? people : user} alt="cardImg" />
          </Box>
          <Box width={"100%"}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box data-test-id="post-id" width={"100%"} onClick={() => this.handleLikeCommentRedirection(item.id)}>
                <Typography style={webStyle.notificationTitle}>
                  {item.attributes.headings}
                </Typography>
                <Typography style={webStyle.notificationDescriptinjknjkon}>
                  {item.attributes.contents}
                </Typography>
              </Box>
              {this.renderMenu(item.id)}
            </Box>
            <Box style={webStyle.timeBox}>
              <Typography style={webStyle.schedualTime}>
                {moment(new Date(item.attributes.created_at)).format("DD MMM  YYYY, hh:mm a")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

    )
  }

  renderAchievementNotificaton = (item: ResponseNotification) => {
    return (
      <Box style={webStyle.mainContainer}>
        <Box style={{...webStyle.mainBox}}>
          <Box style={webStyle.notificationIcon}>
            <img alt="cardImg" src={medal} />
          </Box>
          <Box width={"100%"}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography style={webStyle.notificationTitle}>
                {item.attributes.headings}
              </Typography>
              {this.renderMenu(item.id)}
            </Box>
            <Typography style={webStyle.testRate2}>
              {item.attributes.contents}
            </Typography>
            <Box style={webStyle.mainButton}>
              <Button style={webStyle.buttonDelete} data-test-id="achievemenDeleted" onClick={() => this.deleteNotification(item.attributes.id)}>
                <Typography>{configJSON.Ignore}</Typography>
              </Button>
              <Button style={webStyle.button2}>
                <Link href={String(item.attributes.document)} target="_blank" style={webStyle.checkTextButton}>{configJSON.check}</Link>
              </Button>
            </Box>
            <Box style={webStyle.timeBox}>
              <Typography style={webStyle.schedualTime1}>
                {moment(new Date(item.attributes.created_at)).format("DD MMM  YYYY, hh:mm a")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
  
  renderNotificationContainer = () => {
    return (
      <NotificationContainer >
        {this.state.notification_list.map((item: ResponseNotification) => {
          return (
            <NotificationCard className={item.attributes.is_read ? "" : "blue-container"} key={item.id} data-test-id="read-id" onClick={()=>this.handleReadNotification(item.id)}>
              {(() => {
                switch (item.attributes.content_type) {
                  case "other user event":
                    return this.renderEventReminder(item); // other users's interested event
  
                  case "own event":
                  case "community event":
                    return this.renderEventReminderStart(item); // for own event reminder
  
                  case "community post":
                  case "user post":
                    return this.renderPostCreationNotification(item); // for post creation
  
                  case "comment post":
                  case "like post":
                    return this.renderLikeCommentNotification(item);// for like and comment on post

                  case "user achievement":
                    return this.renderAchievementNotificaton(item); // for achievement
  
                  case "assessment test reminder":
                    return this.renderAssessmentTestReminder(item); // for 30 days test reminder
  
                  case "pending assessment":
                    return this.renderPendingAssessmentTest(item); // for pending assessment
  
                  case "score":
                    return this.renderScoreUpdated(item); // for low score
  
                  case "new assessment":
                    return this.renderNewAssessmentTest(item); // for new assessment created

                  case "community notification":
                    return this.renderAssessmentCompletionRates(item);// for community, user's test completetion rate

                  default:
                    return null;
                }
              })()}
            </NotificationCard>
          );
        })}
      </NotificationContainer>
    );
  };
  

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={webStyle.NotificationContainer}>
        {this.state.loading && <Loader loading={this.state.loading} />}
        <Box component="p" m={0} p={"24px"} sx={{fontFamily:"Verdana", fontWeight: "bold", fontSize: "20px"}} color="#005487" >
          {configJSON.notificationText}
        </Box>
        {this.renderNotificationContainer()}
        {this.state.has_more && <Box display={"flex"} justifyContent={"center"}>
          <Button style={webStyle.showmoreBtn} onClick={this.showMoreList} data-testid="show-more-btn">Show more</Button>
        </Box>}
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const NotificationCard = styled(Box)({
});

const NotificationContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "column",
  "& .blue-container": {
    backgroundColor: "#e6f5ff",
  },
});

const webStyle: Record<string, React.CSSProperties> = {
  
  NotificationContainer: {
    backgroundColor: "white",
    borderRadius: "12px",
    marginBottom:"10px"
  },

  showmoreBtn: {
    color: "#005487",
    fontFamily: "Verdana"
  },

  notificationIcon: {
    height: "50px",
    width: "50px",
    borderRadius: "50%",
    backgroundColor: "#0075a9",
    justifyContent: 'center',
    alignItems: 'center',
    display: "flex",
    marginLeft: "0px"
  },

  notificationTitle: {
    fontSize: "14px",
    fontFamily: "Verdana",
    fontWeight: "bold",
    textTransform: "capitalize",
    lineHeight: "22px",
  },

  userNameColor: {
    fontSize: "14px",
    fontFamily: "Verdana",
    fontWeight: "bold",
    textTransform: "capitalize",
    lineHeight: "22px",
    marginBottom: "5px",
  },

  notificationDescription: {
    fontSize: "14px",
    lineHeight: "22px"
  },

  testRate: {
    fontSize: "14px",
    lineHeight: "22px",
    marginTop: "11px",
    fontFamily: "Verdana"
  },

  testRate2: {
    fontSize: "14px",
    lineHeight: "22px",
    fontFamily: "Verdana"
  },

  deadlineTime: {
    fontSize: "15px",
    fontWeight: "bold",
    color: "#005487",
    marginBottom: "10px",
    lineHeight: "22px",
  },

  button1: {
    display: "flex",
    justifyContent: "center",
    width: "100px",
    alignItems: "center",
    height: "30px",
    color: "#005487",
    border: "1px solid #005487",
  },

  buttonDelete: {
    display: "flex",
    justifyContent: "center",
    width: "100px",
    alignItems: "center",
    height: "30px",
    color: "#005487",
    border: "1px solid #005487",
  },

  mainButton: {
    display: "flex",
    width: "13rem",
    justifyContent: "space-between",
    marginTop: "12px",
  },

  trueBtn: {
    display: "flex",
    justifyContent: "center",
    width: "100px",
    alignItems: "center",
    height: "30px",
    color: "#005487",
    border: "1px solid #005487",
  }, 

  falseBtn: {
    display: "flex",
    justifyContent: "center",
    width: "100px",
    alignItems: "center",
    height: "30px",
    color: "white",
    backgroundColor: "#005487",
  },

  button2: {
    display: "flex",
    justifyContent: "center",
    width: "100px",
    alignItems: "center",
    height: "30px",
    color: "white",
    backgroundColor: "#005487",
  },

  timeBox: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },

  schedualTime: {
    fontSize: "12px",
    color: "#808080",
    marginTop: "5px",
  },

  schedualTime1: {
    fontSize: "12px",
    color: "#808080",
    marginTop: "5px",
  },

  mainContainer: {
    padding: "12px 24px"
  },

  mainBox: {
    display: "flex",
    gap:"12px"
  },

  eventHereColor: {
    color: "#0075a9"
  },

  linkColor: {
    color: "#0075a9"
  },

  checkTextButton: {
    color: "white",
    textDecoration: "none"
  },

  pointer:{
    cursor: "pointer"
  },
};

const MenuContainer = styled(Box)({
  cursor: "pointer",
  "& .icon-btn":{
    padding: 0
  }
})
// Customizable Area End
