import React from "react";

// Customizable Area Start
import {
  Divider,
  TextField,
  Typography,
  Box,
  Avatar,
  InputAdornment,
  Radio,
  Modal,
  Button,
  Grid,
  InputBase
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Edit, Camera, NoUser, Close, BlueClose, BlueSend, Cloud, SearchIcon } from "./assets";
import CreateChatController, { Props, configJSON } from "./CreateChatController.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End


export default class Chat extends CreateChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderFriendIteration = (arr: { id: string, profile_picture: string, full_name: string }[]) => {
    return (
      <>{(this.state.formValue && arr.length < 1) ?
        <Typography className="friend-error">{configJSON.userError}</Typography>
        :
        arr.map(item => {
          return (
            <Box key={item.id} display="flex" justifyContent="space-between" data-test-id="list-item" sx={{ width: "95%", margin: "20px auto" }}>
              <Box display="flex">
                <Avatar className="avatar" src={item.profile_picture}></Avatar>
                <Typography variant="body1" className="avatar-name">{item.full_name}</Typography>
              </Box>
              <Radio
                checked={this.state.radioValues.includes(item.id)}
                onClick={() => this.handleRadioChange(item.id)}
                className="avatar-radio"
                data-test-id="list-friend-id"
                value={item.id}
                color="default"
                name="radio-button-demo"
              />
            </Box>
          )
        })
      } </>
    )
  };

  renderFriends = () => {
    const { addMemberPage, friendListApi, filterdFriends, selectedFriends, formValue, friendError, type } = this.state;
    let listContent;
    const temp = this.state.friendListApi.filter(item => !this.state.existingMember.includes(item.id))// filterd existing member
    const mainList = addMemberPage ? temp : selectedFriends

    if (friendListApi && friendListApi.length > 0) {
      if (formValue) {
        listContent = this.renderFriendIteration(filterdFriends);
      } else {
        listContent = this.renderFriendIteration(mainList);
      }
    } else {
      listContent = <Typography className="friend-error">{friendError}</Typography>;
    }

    return (
      <ListWrapper sx={type === "group" ? { height: "275px" } : { height: "450px" }}>
        {listContent}
      </ListWrapper>
    );

  };

  renderGroup = () => {
    return (<>
      <Typography variant="subtitle1" className="to">{configJSON.groupProfile}</Typography>
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"}>
        <Box display="flex" alignItems="center" justifyContent="center" position={"relative"} width="fit-content" sx={{
          width: "75px",
          height: "75px", border: "1px solid #c4c4c4", borderRadius: "50%"
        }} >
          {!this.state.profileImageSrc ? (<img src={NoUser} className="user-img" alt="no user" />) :
            <Avatar src={this.state.profileImageSrc} className="avatar-img" />
          }
          <Box position={"absolute"} bottom="0px" right="0px" bgcolor={"#fff"} width={"16px"} height="15px" borderRadius={"50%"} border={"1px solid #808080"} padding={"5px"}>
            <img src={Camera} alt="camera" className="camera-icon" />
            <input
              accept="image/png, image/jpg, image/jpeg,"
              type="file"
              className="input-img"
              data-test-id="input-img-id"
              onChange={this.uploadProfileImage}
            />
          </Box>
        </Box>
        {this.state.group.error.includes("image") && <Typography className="error" variant="body2" >{this.state.group.error}</Typography>}
      </Box>
      <Typography variant="subtitle1" className="to">{configJSON.groupName}</Typography>
      <TextField
        data-test-id="group-name-id"
        variant="outlined"
        size="medium"
        placeholder={configJSON.groupPlaceholder}
        name="group-name"
        type="text"
        value={this.state.group.name}
        onChange={this.handleChangeGroupName}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" >
              <img src={Edit} alt="edit" />
            </InputAdornment>
          ),
        }}
      />
      {this.state.group.error.includes("name")&&<Typography className="error" variant="body2" >{this.state.group.error}</Typography>}
    </>)
  }

  renderModal = () => {
    return (
      <ModalConfirmation
        open={this.state.model}
        onClose={() => this.handleOpenClose("else")}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: "100%", height: "100%" }}>
          <Box sx={{ width: { xs: "80%", md: "50%", lg: "40%", xl: "35%" }, borderRadius: "12px" }} bgcolor="#fff">
            <Box sx={{ border: "1px solid black", margin: "20px", padding: "20px", borderRadius: "12px" }}>
              <Box sx={{ margin: "auto" }}>
                <Typography variant="h5" style={webStyle.modalTitle}>
                  {configJSON.send_a_message}
                </Typography>
                <Typography variant="subtitle1" style={webStyle.modalSubTitle}>
                 {configJSON.instruction}
                </Typography>
              </Box>

              <ListWrapper sx={{ height: { xs: "300px", sm: "350px" } }}>
                {this.state.selectedFriends.map(item => {
                  return (<Box key={item.id} display="flex" justifyContent="space-between" sx={{ width: "95%", margin: "20px auto" }}>
                    <Box display="flex">
                      <Avatar className="avatar" src={item.profile_picture}></Avatar>
                      <Typography variant="body1" className="avatar-name">{item.full_name}</Typography>
                    </Box>
                    <Radio
                      checked
                      className="avatar-radio"
                      name="radio-button-demo"
                      inputProps={{ 'aria-label': 'D' }}
                    />
                  </Box>)
                })}
              </ListWrapper>

              <Box sx={{ flexDirection: { xs: "column", md: "row" }, margin: "20px auto 0" }} alignItems="center" display="flex" justifyContent="space-around">
                <Button
                  type="submit"
                  className= "Publishbtn"
                  data-test-id= "private-id"
                  onClick={this.handleOpenClosePrivate}
                >{configJSON.send_private}</Button>
                <Button
                  className= "PublishbtnTwo"
                  data-test-id="group-id"
                  type="submit"
                  onClick={this.handleOpenCloseGroup}
                >{configJSON.create_group}</Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </ModalConfirmation>
    )
  }

  renderImgModal = () => {
    return(
      <ModalWrpper
        open={this.state.imgModel}
        onClose={this.handlecloseImg}
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
      >
        <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: "100%", height: "100%" }}>
          <Box sx={{ width: { xs: "80%", md: "50%", lg: "40%" }, borderRadius: "12px" }} bgcolor="#fff">
              <Box display="flex" alignItems="center" justifyContent="space-between"  p="20px" color="#808084" sx={{ borderBottom: "1px solid #808080" }}>
                  <Typography variant="subtitle1" className="box-title">{"Select Image"}</Typography>
                  <img src={Close} alt="close" onClick={this.handlecloseImg} className="pointer"/>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="space-between"  sx={{ borderBottom: "1px solid #808080" }}>
                  <Box p="20px" sx={{ borderBottom: "1px solid #005487" }} color="#005487">{"Upload"}</Box>
              </Box>
              {this.state.imgModelFile ?
                  <Box component="div" display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ height: "246px" }}>
                      <img src={this.state.imgModelFile} alt="selectedimg" className="selected-img" />
                  </Box>
                  : <Box component="div" display="flex" data-test-id="drop-id" flexDirection="column" alignItems="center"  justifyContent="center" sx={{ height: "246px" }}
                      onDrop={(e) => {
                          this.handleOnImportFile(e.dataTransfer.files)
                          e.preventDefault();
                      }}
                      onDragOver={e => e.preventDefault()}>
                      <input type='file'  data-test-id="fileInput"  id="fileInput" className='input-hide'
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              this.handleOnImportFile(e.target.files!)
                              e.preventDefault();
                          }}
                          accept="image/png, image/jpeg, image/jpg,"
                      />
                      <img src={Cloud} alt="Cloud" />
                      <Button
                          type="button"
                          className={"uploadBtn"}
                          onClick={this.handleOpenUploadBtn}
                      >{configJSON.browseBtn}</Button>
                      <Typography variant="subtitle2" align="center" className="drag-text">{configJSON.dragSentence}</Typography>
                  </Box>}
              <Box display="flex" justifyContent="flex-end" p="20px" sx={{ borderTop: "1px solid #808080" }}>
                  <Button
                      type="submit"
                      data-test-id= "img-submit"
                      className={"Publishbtn"}
                      onClick={this.handleSubmit}
                  >{configJSON.doneBtn}</Button>
            </Box>
          </Box>
        </Box>
      </ModalWrpper>
    )
  }
  
  renderAddFiend = () => {
    return (
      <AddFriendSection>
        <Grid item xs={12} component={Box} sx={{ position: "relative" }}>

          <AddFriendModal
            style={{ position: "absolute", height: "1100px" }}
            open={this.state.addMemberModal}
            onClose={this.closeAddMemberModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            disablePortal
          >

            <Box style={this.getModalStyle()} className={"paper"}>
              <StyledInputBase
                data-test-id="name-id"
                placeholder="Search.."
                inputProps={{ "aria-label": "Search Friends" }}
                value={this.state.formValue}
                onChange={this.handleChangeAddMemberName}
              />
              <Box className={"card"} sx={{ padding: "20px 0 20px 0" }}>
                <Box component="ul" className={"listContainer"} >
                  {this.renderFriends()}
                </Box>
              </Box>
            </Box>
          </AddFriendModal>
          <Box bgcolor={"white"}>
            <Box padding={"24px"} display={"flex"} justifyContent={"space-between"}>
              <Box component={"p"} m={0} color={"#005487"} data-testid="title-id" sx={{ fontFamily: "Verdana", fontSize: "20px", fontWeight: "bold" }}>{configJSON.select_members}</Box>
              <img
                alt="search"
                className="pointer"
                data-test-id="search-icon-id"
                src={SearchIcon}
                onClick={this.openAddMemberModal}
              />
            </Box>
            <Divider />
            <Box sx={{ padding: "24px" }}>
              <Box padding={"8px"} bgcolor={"#f4f4f4"}>
                <Box padding={"24px"} bgcolor={"white"}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    m={0}
                    mb={"20px"}
                    component="p" sx={{ fontFamily: "Verdana", fontSize: "20px", fontWeight: "bold" }} color="#005487"
                  >{this.state.isLA? configJSON.Followers : configJSON.Friend }</Box>
                  {this.renderFriends()}
                  <Box display={"flex"} justifyContent={"center"} pt="20px">
                    <Button className="add-member-btn" data-test-id="add-member-btn" onClick={this.addMemberInGroup}>Done</Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </AddFriendSection>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        {this.renderModal()}
        {this.state.loading && <Loader loading={this.state.loading} />}
        {this.renderImgModal()}
        {this.state.addMemberPage ? this.renderAddFiend() :
        <Wrapper display="flex" flexDirection="column" justifyContent="space-between" sx={{ width: { xs: "90%", md: "90%" }, margin: "auto", padding: "24px" }} bgcolor="white">
          <Box>
            {this.state.type === "group" ?
              <Box display="flex" justifyContent="space-between" alignItems="center"><Typography variant="h5" className="title">{configJSON.new_group}</Typography>
                <img src={Close} alt="close" onClick={this.handleBack} data-test-id="back-id" className="pointer" /></Box>
              : <Typography variant="h5" className="title">{configJSON.send_a_message}</Typography>
            }<Divider className="divider" />

            {this.state.type === "group" && this.renderGroup()
            }
            <Typography variant="subtitle1" className="to">{configJSON.toName}</Typography>
            <TextField
              data-test-id="name-id"
              variant="outlined"
              size="medium"
              placeholder={configJSON.toPlaceholder}
              name="name"
              type="text"
              value={this.state.formValue}
              onChange={this.handleChangeToName}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" >
                    <img src={Edit} alt="edit" />
                  </InputAdornment>
                ),
              }}
            />
            {this.renderFriends()}
          </Box>
          <Box>
            <Typography className="error" variant="body2" >{this.state.sendError}</Typography>
            <Box display="flex" alignItems="center" className="gap-12" mt="20px" sx={{ width: "100%" }}>
              <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ borderRadius: "50px", width: "100%", overflow: "hidden", border: "1px solid #eaeaea" }}>
                <TextField
                  className="message-field"
                  data-test-id="message-id"
                  placeholder={configJSON.messagePlaceholder}
                  size="small"
                  type="text"
                  name="message"
                  value={this.state.typedMessage}
                  onChange={this.handleChangeMessage}
                  multiline
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <Box >
                        <InputAdornment position="end">
                          {this.state.cameraVisible && <img src={Camera} className="icon pointer" data-test-id="camera-id" alt="camera" onClick={()=>this.openCameraModal()} />}
                        </InputAdornment>
                      </Box>
                    )
                  }}
                />
              </Box>
              <Box data-test-id="send-id" className="pointer" component="div" onClick={this.openModel}><img src={BlueSend} alt="send" /></Box>
            </Box>
            {this.state.imgModelFile && <Box position="relative" marginTop="8px" width="fit-content">
              <img src={this.state.imgModelFile} alt="selected" className="img-message" />
              <img src={BlueClose} alt="close" className="remove-img" data-test-id= "remove-img-id" onClick={()=>this.removeImage()} />
            </Box>}
          </Box>
        </Wrapper>
        }
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const AddFriendModal = styled(Modal)({
  "& .paper": {
    position: "absolute",
    top: 0,
    width: "100%",
    maxWidth: "962px",
    backgroundColor: "transparent",
    // padding: theme => theme.spacing(2, 4, 3),
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  "& .card": {
    backgroundColor: "#fff",
    borderRadius: "12px"
  },
  "& .listContainer": {
    maxHeight: '230px',
    minHeight: "120px",
    overflowY: "auto",
    margin: 0,
    gap: "24px",
    padding: 0,
    display: "flex",
    flexDirection: "column",
  }
})

const AddFriendSection = styled(Grid)({
  "& .gap18":{
    gap: "18px"
  },
  "& .add-member-btn":{
    color: "#fff",
    backgroundColor: "#005487",
    fontSize:"16px",
    fontWeight: "bold",
    width:"70%"
  },
  "& .friend-error": {
    color: "red",
    padding: "10px"
  },
  "& .pointer": {
    cursor: "pointer"
  }
})

const StyledInputBase = styled(InputBase)({
  backgroundColor: "#F4F4F4",
  width: "100%",
  height: "40px",
  paddingLeft: "16px",
  paddingRight: "16px",
  paddingTop: "9px",
  paddingBottom: "9px",
  borderRadius: "10px",
  color: "#808080",
  fontSize: "17px",
  fontFamily: "Verdana",
  lineHeight: "22px",
  letterSpacing: "-0.41px",
});
const Wrapper = styled(Box)({
  borderRadius: "12px",
  "& .title": {
    fontSize: "20px",
    fontFamily: "Verdana",
    fontWeight: "bold",
    color: "#005487"
  },
  "& .message-field": {
    borderRadius: "12px",
    padding: " 13px 10px",
    overflow: "auto",
    color: "#808084",
    width: "100%"
  },
  "& .icon": {
    margin: "0 7px"
  },
  "& .divider": {
    margin: "24px 0"
  },
  "& .to": {
    color: "#005487",
    fontFamily: "Verdana",
    fontWeight: "bold",
  },
  "& .friend-error": {
    color: "red",
    padding: "10px"
  },
  "& .error": {
    color: "red",
  },
  "& .pointer": {
    cursor: "pointer"
  },
  "& .camera-icon": {
    width: "100%",
    height: "100%"
  },
  "& .user-img": {
    width: "80%",
    height: "80%"
  },
  "& .avatar-img": {
    width: "75px",
    height: "75px",
    objectFit: "contain",
    borderRadius: "50%"
  },
  "& .input-img": {
    position: "absolute",
    right: "0px",
    bottom: "0px",
    width: "70px",
    height: "70px",
    cursor: "pointer",
    opacity: 0
  },
  "& .img-message": {
    width: "70px",
    height: "70px",
    borderRadius: "12px"
  },
  "& .remove-img": {
    width: "20px",
    position: "absolute",
    cursor: "pointer",
    right: "-3px",
    top: "-4px",
    backgroundColor: "#fff",
    borderRadius: "5px"
  },
  "& .gap-12": {
    gap: "12px"
  }
});

const ModalWrpper = styled(Modal)({
  "& .input-hide": {
      display: "none"
  },
  "& .pointer":{
      cursor: "pointer"
  },
  "& .drag-text": {
      fontSize: "17px",
      fontFamily: "Verdana",
      fontWeight: "normal",
      fontStretch: "normal",
      color: "#808084",
      fontStyle: "normal",
  },
  "& .selected-img": {
      objectFit: "cover",
      aspectRatio:"1/1",
      height: "90%",
  },
  "& .box-title":{
    fontFamily:"Verdana",
      fontSize:"20px",
  },
  "& .Publishbtn": {
      width: "160px",
      color: "#fff",
      height: "48px",
      padding: "15px 56px 14px 57px",
      margin: "23px 7px 0 38px",
      border: "solid 1px #005487",
      marginTop: "8px",
      backgroundColor: "#01548a",
  },
  "& .uploadBtn": {
      color: "#fff",
      height: "48px",
      width: "160px",
      padding: "15px 56px 14px 57px",
      margin: "15px",
      border: "solid 1px #005487",
      backgroundColor: "#01548a"
  },

});

const ListWrapper = styled(Box)({
  overflowY: "auto",
  "& .avatar-name": {
    paddingLeft: "10px"
  },
  "& .avatar-radio": {
    "& .MuiIconButton-label": {
      color: "#005487 !important"
    }
  }
});

const ModalConfirmation = styled(Modal)({
  "& .Publishbtn": {
    color: "#01548a",
    border: "solid 1px #005487",
    height: "50px",
    backgroundColor: "#fff",
    fontWeight: "bold",
    width: "40%",
    textTransform: "none",
    marginTop: "8px",
    "&:active": {
      color: "#fff",
      backgroundColor: "#01548a",
    },
},
"& .PublishbtnTwo": {
    height: "50px",
    textTransform: "none",
    color: "#01548a",
    border: "solid 1px #005487",
    width: "40%",
    marginTop: "8px",
    backgroundColor: "#fff",
    fontWeight: "bold",
    "&:active": {
        backgroundColor: "#01548a",
        color: "#fff",
    },
},
})

const webStyle = {
  modalTitle: {
    fontWeight: "bold",
    fontSize: "17px ",
    color: "#005487",
    fontFamily: "Verdana"
  } as const,
  modalSubTitle: {
    fontSize: "17px",
    color: "#808080",
    fontFamily: "Verdana",
    margin: "20px 0 20px"
  } as const,
};
// Customizable Area End
