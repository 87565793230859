// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import React from "react";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
const regexUrl1 = /^(https?:\/\/)?[\w-]+(\.[\w-]+)+([/?].*)?$/;

export interface IAchievenment{
  id:string,
    type:string,
    attributes:{
      issue_month: number | null;
      title: string | null;
      issue_year: number | null;
      expiration_year:number | null;
      organisation_name: string | null;
      expiration_month: number | null;
      credential_url: string | null;
      description: string | null;
      ceu_gained: string | null;
      is_bulk_upload: boolean;
      account_id:number;
      profile_picture: string;
      full_name: string;
      community_id: string | null;
      achievement_files: {
        data: {
          type: string;
          id: string;
          attributes: {
            description:string;
            title: string;
            achievement_images: {
                url: string;
                file_exetension: string;
                file_name:string,
            }[];
          };
        }[];
      };
      custom_form_file_extension: string | null;
      custom_form_file:string | null;
      custom_form_file_name:string | null;
  }
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  title: string,
  titleError: boolean;
  tittelErrorMsg: string;
  uploadImageError: boolean;
  uploadImageErrorMsg: string;
  imageLoad: string;
  startMonth: { id: number, label: string, value: string }[],
  organisation_name: string,
  OrgError: boolean;
  OrgErrorMsg: string;
  startMontherror: boolean;
  issue_month: string;
  expiration_month_error: boolean;
  expiration_year_error: boolean;
  expiration_year: string;
  issue_year: string;
  IssueyearError: boolean;
  IssueYearErrorMsg: string;
  IssueMonthErrorMsg: string;
  IssueYearList: { label: string, value: string }[];
  expiration_month: string;
  ceu_gained: string;
  CeuGainedError: boolean,
  CeuGainedErrorMsg: string,
  credential_url: string;
  credentialUrlErrorMessage: string;
  credentialUrlError: boolean;
  BulkData: { fileSelect: File | string, title: string, description: string, error: boolean, errorMsg: string, errorImgMsg: string, errordescMsg: string,updateId: string,extantion:string,fileName: string, [key: string]: string | boolean | File }[];
  description: string;
  descriptionError: boolean;
  descriptionErrorMsg: string;
  load: boolean;
  Experiationstatus: boolean,
  expyearerrormsg: string,
  expmontherrormsg: string,
  response: boolean;
  custom_form_imageError: string;
  custom_form_image: File | string;
  editId: string;
  extantion: string;
  fileName: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AchievementsController extends BlockComponent<
  Props,
  S,
  SS
> {
  createAchievenmentId: string = ""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    // Customizable Area Start
    this.state = {
      isLoading: false,
      editId: "",
      custom_form_imageError: "",
      custom_form_image: "",
      title: "",
      titleError: false,
      tittelErrorMsg: "",
      uploadImageErrorMsg: "",
      uploadImageError: false,
      imageLoad: "BulkUpload",
      credentialUrlError: false,
      BulkData: [{ fileSelect: "", title: "", description: "", error: false, errorMsg: "", errorImgMsg: "", errordescMsg: "",extantion:"", updateId: "",fileName: "" }],
      credentialUrlErrorMessage: "",
      organisation_name: "",
      expiration_month: "",
      credential_url: "",
      startMonth: [{ id: 1, label: "January", value: "January" },
      { id: 2, label: "February", value: "February" },
      { id: 3, label: "March", value: "March" },
      { id: 4, label: "April", value: "April" },
      { id: 5, label: "May", value: "May" },
      { id: 6, label: "June", value: "June" },
      { id: 7, label: "July", value: "July" },
      { id: 8, label: "August", value: "August" },
      { id: 9, label: "Septmember", value: "Septmember" },
      { id: 10, label: "October", value: "October" },
      { id: 11, label: "November", value: "November" },
      { id: 12, label: "December", value: "December" },
      ],
      issue_month: "",
      IssueMonthErrorMsg: "",
      startMontherror: false,
      IssueYearErrorMsg: "",
      IssueyearError: false,
      IssueYearList: [],
      expmontherrormsg: "",
      expiration_month_error: false,
      issue_year: "",
      expiration_year: "",
      expiration_year_error: false,
      expyearerrormsg: "",
      ceu_gained: "",
      OrgErrorMsg: "",
      OrgError: false,
      CeuGainedError: false,
      CeuGainedErrorMsg: "",
      description: "",
      descriptionError: false,
      descriptionErrorMsg: "",
      load: false,
      Experiationstatus: false,
      extantion: "",
      fileName: "",
      response: false,
    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  getSpecificAchievenmentId: string = "";

  handlestartandendmonth = () => {
    let IssueMonth = this.state.startMonth.find((data) => data.value === this.state.issue_month)?.id as number;
    let ExpMonth = this.state.startMonth.find((data) => data.value === this.state.expiration_month)?.id as number;
    if ((this.state.issue_year === this.state.expiration_year) && (this.state.issue_month !== "")) {
      if (IssueMonth > ExpMonth) {
        this.setState({
          expiration_month_error: true,
          load: true,
          expmontherrormsg: "Expiration month not to be smaller than issue month"
        });
      } else {
        this.setState({
          expiration_month_error: false,
          load: true,
          expmontherrormsg: ""
        });
      }
    }

    if (this.state.Experiationstatus === false) {

      if (this.state.expiration_year === "") {
        this.setState({
          expiration_year_error: true,
          load: true,
          expyearerrormsg: "Please select the exp year"
        });
      }

      if (this.state.expiration_month === "") {
        this.setState({
          expiration_month_error: true,
          load: true,
          expmontherrormsg: "Please select the exp month"
        });
      }

    }
  }

  validateForm = () => {
    let isError = false;

    if (this.state.custom_form_image === "") {
      this.setState({
        load: true,
        custom_form_imageError: "Please select file",
      });
      isError = true;

    }

    if (this.state.description === "") {
      this.setState({
        descriptionError: true,
        load: true,
        descriptionErrorMsg: "Please enter description",
      });
      isError = true;

    }

    if (this.state.ceu_gained === "") {
      this.setState({
        CeuGainedError: true,
        load: true,
        CeuGainedErrorMsg: "Please enter the details of CEU_Gained"

      })
      isError = true;

    }
    if (this.state.title === "") {
      this.setState({
        titleError: true,
        load: true,
        tittelErrorMsg: "Please enter the title",
      });
      isError = true;
    }
    if (this.state.organisation_name === "") {
      this.setState({
        OrgError: true,
        load: true,
        OrgErrorMsg: "Please enter the organization Name"
      })
      isError = true;

    }
    if (!this.state.credential_url.match(regexUrl1)) {
      this.setState({
        credentialUrlError: true,
        load: true,
        credentialUrlErrorMessage: "Please enter valid url"
      })
      isError = true;
    }
    if (this.state.issue_year === "") {
      this.setState({
        IssueyearError: true,
        load: true,
        IssueYearErrorMsg: "Please select the year"
      })
      isError = true;
    }
    if (this.state.issue_month === "") {
      this.setState({
        startMontherror: true,
        load: true,
        IssueMonthErrorMsg: "Please select the month"
      })
      isError = true;

    }

    this.handlestartandendmonth()

    if ((this.state.issue_year > this.state.expiration_year) && this.state.expiration_year !== "") {
      if (this.state.expiration_year !== "") {
        this.setState({
          expiration_year_error: true,

          load: true,
          expyearerrormsg: "Expiration year must not be smaller than issue year"
        })
        isError = true;

      } else {
        this.setState({
          expiration_year_error: false,
          load: true,
          expyearerrormsg: ""
        })
      }


    }
    return isError;
  }
  Bulkvalidation = () => {
    const updatedBulkData = this.state.BulkData.map((data) => {
      let updatedData = { ...data };
      if (!data.fileSelect) {
        updatedData.error = true;
        updatedData.errorImgMsg = "Please Select Image";
      }


      if (data.description === "") {
        updatedData.error = true;
        updatedData.errordescMsg = "Please fill the description field";
      }

      if (data.title === "") {
        updatedData.error = true;
        updatedData.errorMsg = "Please fill the title field";
      }

      return updatedData;
    });

    this.setState({ BulkData: [...updatedBulkData] });
    return updatedBulkData;
  };

  updateFieldChanged = async (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    let newArr = [...this.state.BulkData];
    if (e.target.name === "custom_form_imageError") {
      if (e.target.files && e.target.files.length > 0) {
        return this.setState({ custom_form_image: e.target.files[0], custom_form_imageError: "" });
      }
    }
    if (e.target.name === "getimage") {
      if (e.target.files && e.target.files.length > 0) {
        newArr[index]["fileSelect"] = e.target.files[0];
      }
    } else {
      newArr[index][e.target?.name] = e.target.value;
    }

    this.setState({ BulkData: newArr });

    this.state.BulkData.forEach((data) => {
      if (data.title !== "" && data.description !== "" && data.fileSelect !== "") {
        data.error = false;
        data.errorMsg = "";
        data.errorImgMsg = "";
        data.errordescMsg = "";
      }
    });
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    let name = e.target.name;
    if (name === "title") {
      this.setState({
        load: false,
        titleError: false,
        tittelErrorMsg: "",
      })
    }
    if (name === "organisation_name") {
      this.setState({
        OrgError: false,
        OrgErrorMsg: "",
        load: false,
      })
    }
    if (name === "ceu_gained") {
      this.setState({
        CeuGainedErrorMsg: "",
        CeuGainedError: false,
        load: false,
      });
    }
    if (name === 'credential_url') {
      this.setState({
        credentialUrlError: false,
        credentialUrlErrorMessage: "",
        load: false,

      });
    }
    if (name === 'description') {
      this.setState({
        load: false,
        descriptionError: false,
        descriptionErrorMsg: "",

      });
    }
    this.setState((prevalue) => {
      return {
        ...prevalue,
        [name]: value
      }
    });
  }
  handleToggle = () => {
    if (this.state.Experiationstatus === false) {
      this.setState({
        expiration_year: "",
        Experiationstatus: true,
        expiration_month_error: false,
        expiration_month: "",
        expiration_year_error: false
      })
    } else (
      this.setState({ Experiationstatus: false })
    )
  }
  handleAddBulk = () => {
    let data = {
      fileSelect: "",
      title: "",
      description: "",
      error: false,
      errorMsg: "",
      errorImgMsg: "",
      errordescMsg: "",
      updateId: "",
      extantion: "",
      fileName: ""
    }
    const tempData = this.Bulkvalidation();
    let check = tempData.map((data: { error: boolean }) => data.error)

    if (check.includes(true)) {
      this.Bulkvalidation()

    } else {
      this.setState({
        BulkData: [...this.state.BulkData, data]
      })
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDropList()
    const id = this.props.navigation.getParam("navigationBarTitleText");
    
    if(id &&id!=="undefined"){
      this.setState({isLoading : true, editId: id});
      const loginToken = await getStorageData('LoginSuccessToken');
      const signupToken = await getStorageData('SignupSuccessToken');
      const header = {
        token: signupToken ?? loginToken
      }
      const requestMessag = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      requestMessag.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.specificAchievenment+id.split('-')[0],
      );
      requestMessag.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessag.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType,
      );
      this.getSpecificAchievenmentId = requestMessag.messageId;
      runEngine.sendMessage(requestMessag.id, requestMessag);
    }
  }

  getDropList = () => {
    const thisYear = (new Date()).getFullYear();
    const a: number[] = [];
    const b = [];
    const maxOffset = 15;
    const minOffset = 0;
    for (let i = minOffset; i <= maxOffset; i++) {
      const futureyear = thisYear + i;
      const lastyear = thisYear - i;
      a.push(lastyear);
      b.push(futureyear)
    }
    const fullyear = a.concat(b.filter((item) => a.indexOf(item) < 0)).sort((a, b) => {
      return a - b;
    });
    fullyear.forEach((data: number) => {
      this.state.IssueYearList.push({ label: data.toString(), value: data.toString() });
    });
  };

  renderValuestartMonth = (selected: string) => {
    if (!selected || selected.length === 0) {
      return "Month";
    }
    return selected;
  };

  renderValueIssueYear = (selected: string) => {
    if (!selected || selected.length === 0) {
      return "Year";
    }
    return selected;
  };

  handleChangeDropdownValue = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    let fieldValue: string = value;
    if (fieldValue) {
      if (name === "issue_month") {
        this.setState({
          startMontherror: false,
          IssueMonthErrorMsg: ""
        });
      }
      if (name === "issue_year") {
        this.setState({
          IssueyearError: false,
          IssueYearErrorMsg: ""
        });
      }
      if (name === "expiration_month") {
        this.setState({
          expiration_month_error: false,
          expmontherrormsg: ""
        });
      }
      if (name === "expiration_year") {
        this.setState({
          expiration_year_error: false,
          expyearerrormsg: ""
        });
      }
      if (this.state.issue_year < this.state.expiration_year) {
        this.setState({
          IssueyearError: false,
          IssueYearErrorMsg: ""
        });
      }
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };

  handleChangeTab = (tab: string) => {
    this.setState({ imageLoad: tab, editId: "" })
    if (tab === "CustomForm") {
      this.setState({
        BulkData: [{
          fileSelect: "",updateId: "",fileName: "", title: "", description: "", error: false, errorMsg: "", errorImgMsg: "", errordescMsg: "", extantion: ""
        }]
      });
    }
    if (tab === "BulkUpload") {
      this.setState({
        descriptionError: false,
        titleError: false,
        IssueyearError: false,
        expiration_year: "",
        fileName:"",
        CeuGainedError: false,
        startMontherror: false,
        OrgError: false,
        credentialUrlError: false,
        credentialUrlErrorMessage: "",
        load: false,
        expiration_month_error: false,
        expiration_year_error: false,
        title: "",
        ceu_gained: "",
        description: "",
        issue_year: "",
        extantion: "",
        issue_month: "",
        expiration_month: "",
        credential_url: "",
        organisation_name: "",
        custom_form_image: "",
        custom_form_imageError: "",
        Experiationstatus: false,
      });
    }
  }

  async receive(_from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId && responseJson) {
      if(apiRequestCallId === this.getSpecificAchievenmentId) return this.getSingleAchivenmentResponse(responseJson)
      if (apiRequestCallId === this.createAchievenmentId) {

        if (!responseJson.errors) {
          this.achivenmentResponse();
        } else {
          for (let key in responseJson.errors[0]) {
            toast.error(responseJson.errors[0]?.[key])
          }
          this.setState({
            response: false
          })
        }
      }
    }
  }

  achivenmentResponse = () => {
    this.setState({
      response: true
    });
    toast.success(this.state.editId ? "sucessfully modified" : "sucessfully added");
    this.navigateToListing();
    this.setState({
      title: "",
      isLoading: false,
      editId: "",
      organisation_name: "",
      description: "",
      issue_year: "",
      issue_month: "",
      expiration_year: "",
      ceu_gained: "",
      credential_url: "",
      expiration_month: "",
      IssueyearError: false,
      IssueYearErrorMsg: "",
      Experiationstatus: false,
      expiration_month_error: false,
      expmontherrormsg: "",
      expiration_year_error: false,
      startMontherror: false,
      expyearerrormsg: "",
      BulkData: [{ fileSelect: "", updateId: "", title: "", fileName: "", description: "", error: false, errorMsg: "", errorImgMsg: "", errordescMsg: "", extantion: "" }],
      IssueMonthErrorMsg: "",
      custom_form_image: "",
      custom_form_imageError: "",
      extantion: "",
      fileName: "",
    })
  }

  navigateToListing = () =>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "AchievementListing");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
   
    this.send(message);
  }

  getSingleAchivenmentResponse = (response:{data:IAchievenment}) => {
    this.setState({isLoading: false})
    if(response?.data){
    const {is_bulk_upload} = response.data.attributes
    if(is_bulk_upload){
      const subId = this.state.editId.split('-')[1]
      const temp =  response.data.attributes.achievement_files.data.filter(_=>_.id === subId).map(item=> {
        return ({fileSelect: item.attributes.achievement_images[0].url,
        title: item.attributes.title,
        description: item.attributes.description,
        updateId: item.id,
        error:false,
        errorImgMsg: "",
        errorMsg: "",
        extantion: item.attributes.achievement_images[0].file_exetension,
        errordescMsg: "",
        fileName: item.attributes.achievement_images[0].file_name
      })})
      this.setState({
        BulkData: temp
      })
    }else {
      const {title, description, ceu_gained, credential_url,expiration_month, expiration_year, organisation_name, issue_month, issue_year, custom_form_file, custom_form_file_name, custom_form_file_extension} = response.data.attributes
      this.setState({
        title: title!,
        organisation_name: organisation_name!,
        issue_month: this.state.startMonth.find(item=>item.id===issue_month)?.value!,
        issue_year: String(issue_year),
        expiration_month:this.state.startMonth.find(item=>item.id===expiration_month)?.value ?? "",
        expiration_year: expiration_year ? String(expiration_year): "",
        custom_form_image: custom_form_file!,
        credential_url: credential_url!,
        ceu_gained: ceu_gained!,
        Experiationstatus: !expiration_year,
        description: description!,
        imageLoad: "CustomForm",
        fileName: custom_form_file_name!,
        extantion: custom_form_file_extension!
      })
    }
  }
  }

  postBulkUpload = async () => {
    const temp = this.Bulkvalidation()
    let check = temp.map((data) => data.error)
    if (check.includes(true)) {
      return this.Bulkvalidation();
    } else {
      const loginToken = await getStorageData('LoginSuccessToken');
      const signupToken = await getStorageData('SignupSuccessToken');
      const header = {
        token: signupToken ?? loginToken
      }
      this.setState({isLoading: true})
      let formData = new FormData();
      this.state.BulkData.forEach((item, index: number) => {
        item.updateId && formData.append(`achievement[achievement_files_attributes][${index}][id]`,item.updateId);
        formData.append(`achievement[achievement_files_attributes][${index}][title]`, item.title);
        formData.append(`achievement[achievement_files_attributes][${index}][description]`, item.description);
        item.fileSelect instanceof File && formData.append(`achievement[achievement_files_attributes][${index}][achievement_docs][]`, item.fileSelect);
      });
      const requestMessage1 = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      requestMessage1.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        this.state.editId? configJSON.postAchievements+this.state.editId.split('-')[0] : configJSON.postAchievements,
      );
      requestMessage1.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      requestMessage1.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage1.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        this.state.editId ? configJSON.sellerDetailsAPIMethodPUT : configJSON.exampleAPiMethod,
      );
      this.createAchievenmentId = requestMessage1.messageId;
      runEngine.sendMessage(requestMessage1.id, requestMessage1);

      if (this.state.response === true) {
        this.setState({
          BulkData: [
            { fileSelect: "",fileName: "", title: "", updateId: "", description: "", error: false, errorMsg: "", errorImgMsg: "",extantion:"", errordescMsg: "" }
          ],
        })
      }
    }
  }

  postCustomForm = async () => {
    const isError = this.validateForm();
    if (isError) return;
    let IssueMonth = this.state.startMonth.find((data) => data.value === this.state.issue_month)?.id ?? "";
    let ExpMonth = this.state.startMonth.find((data) => data.value === this.state.expiration_month)?.id ?? "";

    this.callingApicustom(String(IssueMonth), String(ExpMonth));
  }

  callingApicustom = async (issueMonth: string, expMonth: string) => {
    const loginToken = await getStorageData('LoginSuccessToken');
    const signupToken = await getStorageData('SignupSuccessToken')
    const header = {
      "token": signupToken || loginToken
    };
    const formData = new FormData();
    this.setState({isLoading: true})
    formData.append("achievement[organisation_name]", this.state.organisation_name);
    formData.append("achievement[title]", this.state.title);
    formData.append("achievement[issue_month]", issueMonth);
    formData.append("achievement[issue_year]", this.state.issue_year);
    !this.state.Experiationstatus && formData.append("achievement[expiration_year]", this.state.expiration_year);
    !this.state.Experiationstatus && formData.append("achievement[expiration_month]", expMonth);
    this.state.custom_form_image instanceof File && formData.append("achievement[custom_form_image]", this.state.custom_form_image);
    formData.append("achievement[description]", this.state.description);
    formData.append("achievement[ceu_gained]", this.state.ceu_gained);
    formData.append("achievement[credential_url]", this.state.credential_url);

    const requestMessage2 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage2.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.editId? configJSON.postAchievements+this.state.editId :configJSON.postAchievements,
    );
    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.editId ? configJSON.sellerDetailsAPIMethodPUT : configJSON.exampleAPiMethod,
    );
    this.createAchievenmentId = requestMessage2.messageId;
    runEngine.sendMessage(requestMessage2.id, requestMessage2);
  }

  removeSelectedFile = (type:string, index:number) => {
    if(type==="bulk"){
      const updatedBulkData = [...this.state.BulkData];
        updatedBulkData[index] = {...updatedBulkData[index],fileSelect: "",extantion:"",fileName: ""};
        this.setState({ BulkData: updatedBulkData });
    }else{
      this.setState({ custom_form_image: "", fileName: "", extantion: ""});
    }
  }

  handleRemoveBulk = (index: number) => {
    const updatedBulkData = [...this.state.BulkData];
  
    // Remove the item at the specified index
    updatedBulkData.splice(index, 1);
  
    // Update the state with the modified array
    this.setState({ BulkData: updatedBulkData });
  }
  
  // Customizable Area End
}