import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  test_type: boolean; //for annonymus test
  loading: boolean;
  testId: string;
  testName: string;
  questions: { question: string, type: string, required: boolean, complated: boolean, id: string, options: {label:string,id:string}[] }[];
  answers: { questionId: string, answer: string, required: boolean, type: string}[];
  required: number[];
  disable: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AssessmentTestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getQuizApi: string = "";
  submitAnswerApi: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      test_type: false,
      loading: false,
      testId: "",
      testName: "",
      questions: [{ question: "", type: "", required: false, complated: false, id: "", options: [] }],
      answers: [],
      required: [],
      disable: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
          this.setState({ loading: false});
          if (apiRequestCallId === this.getQuizApi) return this.getQuizApiResponse(responseJson);
          if (apiRequestCallId === this.submitAnswerApi) return this.submitApiResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  
  handleAnnonymus = () => {
    this.setState({ test_type: !this.state.test_type })
  }

  async componentDidMount() {
    super.componentDidMount()
    const pathParam = this.props.navigation.getParam("navigationBarTitleText");
    let id = pathParam.replace(/\D/g, "");
    if (id) {
      const loginToken = await getStorageData('LoginSuccessToken');
      const signupToken = await getStorageData('SignupSuccessToken');
      const headers =  {
        'Content-Type': configJSON.exampleApiContentType,
        token: signupToken || loginToken
        }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      )
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getQuizEnd + id
      )
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApi
      )
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        headers
      )
      this.getQuizApi = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage)
      this.setState({ testId: id, loading: true, disable: pathParam.includes("view") });
    }
  }

  getQuizApiResponse = (responseJson: {
    data: {
        quize_details: {
            data: {
                type: string;
                id: string;
                attributes: {
                    test_type: boolean;
                    title: string;
                    updated_at: string;
                    created_at: string;
                    quiz_questions: {
                        data: {
                            id: string;
                            type: string;
                            attributes: {
                                question: string;
                                is_required: boolean;
                                question_format: string;
                                updated_at: string;
                                created_at: string;
                                quiz_question_options: {
                                    data: {
                                        type: string;
                                        id: string;
                                        attributes: {
                                            option_text: string;
                                            question_id: string;
                                            updated_at: string;
                                            created_at: string;
                                        };
                                    }[];
                                };
                            };
                        }[];
                    };
                };
            };
        };
    };
  }) => {
      if (responseJson.data) {
          const val = responseJson.data.quize_details.data.attributes;
          const existingQuestion = val.quiz_questions.data.map(item => {
              const que = item.attributes;
              return {
                  id: item.id,
                  question: que.question,
                  type: que.question_format,
                  required: que.is_required,
                  complated: true,
                  options: que.quiz_question_options.data.map((item) => {
                    return {label: item.attributes.option_text, id: item.id}
                  })
              }
          })
          const answer = existingQuestion.map(item=>{return{questionId: item.id, required: item.required, answer: "", type: item.type}})
          this.setState({ questions: [...existingQuestion], testName: val.title,answers: answer })
      }
  }

  submitApiResponse = (responseJson:{ data?: { message: string }[] , errors?: { message: string }[] }) => {
    if(responseJson.data){
      toast.success(responseJson.data[0].message)
      const message = new Message(getName(MessageEnum.NavigationMessage));
       message.addData(getName(MessageEnum.NavigationTargetMessage), "AssessmentDashboard");

      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

      this.send(message);
    } else {
      toast.error(responseJson.errors?.[0].message)
    }
  }

  radioChange = (e:React.ChangeEvent<HTMLInputElement>,questionId: string,index: number) => {
    const updatedAnswers = this.state.answers.map(item => {
      if (item.questionId === questionId) {
          // Update the answer for the specific question
          return {
              ...item,
              answer: e.target.value
          };
      }
      return item
    });
    this.setState({ answers: updatedAnswers,required: this.state.required.filter(item=>item !== index) });
  }

  handleChangeFreeText = (e:React.ChangeEvent<HTMLInputElement>,questionId: string,index: number) =>{
    const updatedAnswers = this.state.answers.map(item => {
      if (item.questionId === questionId) {
          return {
              ...item,
              answer: e.target.value
          };
      }
      return item;
    });
    this.setState({ answers: updatedAnswers, required: this.state.required.filter(item=>item !== index)});
  }

  handleSubmit = async () => {
    const requiredIndexes = this.state.answers
    .filter(item => (item.required === true && !item.answer)) // Filter objects with required set to true
    .map(item => this.state.answers.indexOf(item)); // Map filtered objects to their indexes
    this.setState({required:[...requiredIndexes]});
    if(requiredIndexes.length>0) return
    const answers = this.state.answers.map(item=>{
      return {
        question_id: item.questionId,
        ...(item.type === "free_text" ? { text_answer: item.answer } : { question_option_id: item.answer })
      }
    })
    const body = {
      data: {
          attributes: {
              answers_data: [...answers],
              quiz_id: this.state.testId,
              test_anonymous: this.state.test_type,
          }
      }
    }
    const loginToken = await getStorageData('LoginSuccessToken');
    const signupToken = await getStorageData('SignupSuccessToken');
    const headers =  {
      'Content-Type': configJSON.exampleApiContentType,
      token: signupToken || loginToken
      }
    this.setState({loading: true});
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.answerEnd
    )
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApi
    )
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)	
    )
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    )
    this.submitAnswerApi = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  // Customizable Area End
}
