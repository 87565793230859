import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import React from 'react';
import { getStorageData } from "../../../framework/src/Utilities";
import { blueLike, simpleLike } from "./assets";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
export const POST_TYPES = {
    Link: "url",
    Video: "video",
    TextPost: "text",
    Image: "image"
}

interface ILikeUser {
    id: number;
    type: string;
    name: string;
    if_followed: boolean;
    is_login_user: boolean;
    profile_pic: string;
}
// Customizable Area End

export interface Props {
    // Customizable Area Start
    key:any;
    navigation?: any;
    handleInterestedEvent?:any;
    eventDetailNavigation?:any;
    history?:any;
    id?: any;
    classes: any;
    postCards: any;
    mediaPost: any;
    postComment: any;
    profile: any;
    fullName: string;
    description: string;
    createdAt: string;
    url: string;
    src: any;
    liked: any;
    postType: string;
    PostId: any;
    postCommented: any;
    postlikecount: number;
    itemType: any;
    eventName: any;
    flag?: boolean;
    communityFollow?:boolean;
    eventDescription: any;
    startDate: any;
    endDate?:any;
    startTime: any;
    endTime: any;
    eventType: any;
    interestedBtnHighlight?:any
    // commentMessage:any
    messComment: any;
    videoTitle: any;
    data: any;
    getPostList:()=>void;
    editPost:(id:string)=>void;
    followUpdatedList: (type:string,id:string,action:string)=>void;
    followUpdatedCommunityTab: (type:string, id:string, action:string) => void;
    deletePost: (id:string)=>void
    dataItem?:any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean;
    expandDesc: boolean;
    startTime: any;
    endTime: any;
    videoTypeState: string;
    vimeoTitle: string;
    youTubeTitle: string;
    openComments: boolean;
    communityFlag:any;
    postData: { [key: string]: any };
    likeListModal: boolean;
    loadingpostLike: boolean;
    postLikeUserList: ILikeUser[];
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class NewPostBlockController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    singlePostApi: string = "";
    postLikeUserListId: string = "";
    likeUnlikeApi: string = ""
    apiCreatePostCallId: any
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ]
        this.state = {
            // Customizable Area Start
            isLoading: false,
            expandDesc: false,
            startTime: "",
            endTime: "",
            videoTypeState: "",
            vimeoTitle: "",
            youTubeTitle: "",
            openComments: false,
            postData: {},
            communityFlag:"false",
            likeListModal: false,
            loadingpostLike: false,
            postLikeUserList: [],
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    }
    // Customizable Area Start

    async componentDidMount() {

        localStorage.setItem("userProfileInfo","false")
         this.setState({communityFlag: localStorage.getItem("community")});

        this.setState({
            postData: this.props.data
        });
        if (Number(this.props.startTime?.substring(0, 2)) >= 12) {
            let num = Number(this.props.startTime?.substring(0, 2)) - 12
            this.setState({ startTime: `${num}:00PM` })
        }
        else {

            let num = Number(this.props.startTime?.substring(0, 2))
            this.setState({ startTime: `${num}:00AM` })
        }
        if (Number(this.props.endTime?.substring(0, 2)) >= 12) {
            let num = Number(this.props.endTime?.substring(0, 2)) - 12
            this.setState({ endTime: `${num}:00PM` })
        }
        else {

            let num = Number(this.props.endTime?.substring(0, 2))
            this.setState({ endTime: `${num}:00AM` })
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if(prevProps.data!==this.props.data){
            this.setState({postData: this.props.data});
        }
    }

    handleExpandDescription = () => {
        this.setState({ expandDesc: true })
    }

    handleOpenLink = (url: string) => {
        window.open(url, "_blank")
    }

    async receive(_from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);


        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        const errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        if (apiRequestCallId && responseJson) {
            this.setState({loadingpostLike: false})
            if (apiRequestCallId === this.singlePostApi) {
                if (!responseJson.errors) {
                    this.responseSinglePost(responseJson)
                } else {
                    this.parseApiErrorResponse(responseJson);
                }
                this.parseApiCatchErrorResponse(errorReponse);
            }
            if (apiRequestCallId === this.likeUnlikeApi) {
                if (!responseJson.errors) {

                    this.handleLikeResponse()
                }
            }
            if(apiRequestCallId === this.postLikeUserListId) return this.postLikeUserListResponse(responseJson)
        }
    }

    handleLikeResponse = () => {
        this.singlePostDataChange();
    }

    openComments = () => {
        if(this.state.postData.type === "event") return
        this.setState({ openComments: !this.state.openComments })
    }

    responseSinglePost = (responseJson: any) => {
        this.setState({ postData: responseJson.data });
    }

    showLike = () => {
        if (this.state.postData?.attributes) {
            const data = this.state.postData
            const likedByCurrentUser = data.attributes.is_liked;
            const countLike = data.attributes.post_like_count ? data.attributes.post_like_count : 0;
            if (likedByCurrentUser) {
                if (countLike > 1) {
                    return <><img src={blueLike} alt="blueLike" />You and {countLike - 1} other</>
                } else {
                    return <><img src={blueLike} alt="blueLike" />You</>
                }
            } else if (countLike>0){
                return <><img src={simpleLike} alt="simpleLike" />{countLike} like</>
            }
        }
    }

    showComment = () => {
        if(this.state.postData.type === "event") return ""
        if (this.state.postData?.attributes) {
            return this.state.postData.attributes?.comments_count > 0 ? `${this.state.postData.attributes.comments_count} comments` : ""
        }
    }

    handleLikeAction = async () => {
        
        const signupToken1 = await getStorageData('SignupSuccessToken');
        const loginToken = await getStorageData('LoginSuccessToken');
        const body = new FormData();
        body.append("post_id", this.state.postData.id)
        const header = {
            token: signupToken1 || loginToken
          };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.likeUnlikeApi = requestMessage.messageId;
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.like_unlike_post
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PostAPiMethod
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    singlePostDataChange = async () => {
        const signupToken1 = await getStorageData('SignupSuccessToken');
        const loginToken2 = await getStorageData('LoginSuccessToken');
        const header = {
            token: signupToken1 || loginToken2
        };
        const getAlonePost = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.singlePostApi = getAlonePost.messageId;

        getAlonePost.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postsEnd + this.state.postData.id
        );

        getAlonePost.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        getAlonePost.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.get
        );
        runEngine.sendMessage(getAlonePost.id, getAlonePost);
    }

    replaceUrl = (decscription: string) => {
        let urlRegex = /(https?:\/\/[^\s]+)/g;
        return decscription.replace(urlRegex, function(url) {
            return `<a href="${url}">${url}</a>`;
        });
    }
    handleInterestedEvent =()=>{
        this.props.handleInterestedEvent(this.props.dataItem.id)
    }
    eventDetailNavigation =(postId:number)=>{
    
        this.props.navigation.navigate("CalendarEventDetails",{id: postId})

    }

    handleOpenLikeCount = () => {
        this.setState({likeListModal: true, loadingpostLike: true})
        this.getLikedUserList();
    }

    handleCloseLikeCount = () => {
        this.setState({likeListModal: false})
    }

    getLikedUserList = async() => {
        const signupToken = await getStorageData('SignupSuccessToken');
        const loginToken = await getStorageData('LoginSuccessToken');
        const header = {
            token: signupToken || loginToken
          };
        const requestMessageLikeList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.postLikeUserListId = requestMessageLikeList.messageId;
      
          requestMessageLikeList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postLikeUserListEnd+this.state.postData.id
          );
      
          requestMessageLikeList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
      
          requestMessageLikeList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.get
          );
          runEngine.sendMessage(requestMessageLikeList.id, requestMessageLikeList); 
    }

    postLikeUserListResponse = (response:ILikeUser[]) => {
        if(response.length > 0){
            this.setState({postLikeUserList: response})
        }
    }

    userRedirection = (id: number,type:string) =>{
        if(type.toLowerCase().includes("community")) return this.props.navigation.navigate("OtherUserProfile",{id:`${id}-Community`})
        this.props.navigation.navigate("OtherUserProfile",{id:id})
    }
    // Customizable Area End

}
