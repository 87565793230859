// Customizable Area Start
import React from "react";
import { Button  } from '@material-ui/core';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import CustomButtonController, { Props } from "./CustomButtonController.web";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
    createStyles({
        buttonCss:{
          fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
          textTransform:"capitalize"
        },
      PublishbtnTwo: {
            color: "#01548a",
            height: "50px",
            width: "40%",
            border: "solid 1px #005487",
            backgroundColor: "#fff",
            marginTop: "8px",
            fontWeight: "bold",
            "&:active": {
                backgroundColor: "#01548a",
                color: "#fff"
            },
        },
    })

// Customizable Area End

export class CustomButton extends CustomButtonController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {

        // Customizable Area Start
        const { classes , btnText, btnStyle , buttonId , type } = this.props
        return (
            <Button
                variant="contained"
                type={type}
                disabled={this.props.disabled}
                id={buttonId}
                className={this.props.btnCss? classes.PublishbtnTwo : classes.buttonCss}
                onClick={this.props.handleButtonClick} 
                style={btnStyle}>
                    {btnText}
            </Button>
        );
        // Customizable Area End
    }

}


// Customizable Area Start

export default withStyles(styles, { withTheme: true })(CustomButton);
// Customizable Area End