// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: {
    id: number;
    address: string;
    email: string;
    name: string;
    website: string;
    contact_number: string;
    created_at: string;
    updated_at: string;
    account_id: number;
  },
  isLoading:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OrganizationProfileEditController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetOrganizationId: string = '';
  orgId: string = '';
  // Customizable Area End
    
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data:{
        id: NaN,
        address: '',
        email: '',
        name: '',
        website: '',
        contact_number: '',
        created_at: '',
        updated_at: '',
        account_id: NaN,
      },
      isLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(responseJson && apiRequestCallId===this.apiGetOrganizationId){
      this.setState({isLoading:false})
      this.getOrganizationDetailsResponse(responseJson)
    }

  }

  getOrganizationDetailsResponse = (responseJson: {
    id: number;
    address: string;
    email: string;
    name: string;
    website: string;
    contact_number: string;
    created_at: string;
    updated_at: string;
    account_id: number;
  }) => {
    if (responseJson.id) {
      this.setState({ data: responseJson })
    }
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    const param = this.props.navigation.getParam("navigationBarTitleText") ?? "";
    
    this.setState({isLoading:true})
    if(param && param!=="undefined"){
      if(param.includes("profile")){// for view-profile-id and search-organization-id
        this.orgId = ""
      }else {
        this.orgId = param
      }
    }

    this.getOrgDetails();
  }

  getOrgDetails = async () => {
    const signupToken = await getStorageData('SignupSuccessToken');
    const loginToken = await getStorageData('LoginSuccessToken');
    const header = {
      token: signupToken || loginToken,
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetOrganizationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.get_organization + `/${this.orgId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigateEditSection = () => {
    this.props.navigation.goBack();
  }

  navigateDoneBtn = () => {
    const param = this.props.navigation.getParam("navigationBarTitleText")??'';
    if(param.includes('profile')){// for edit part
      this.props.navigation.navigate("OtherUserProfile",{id:this.orgId});
    }else if(param){// for elastic search
      this.props.navigation.goBack()
    } else {// after signup
      this.props.navigation.navigate("LandingScreen");
    }
  }
  // Customizable Area End
}
