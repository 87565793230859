// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  getItem: (id: string, type: string) => void;
  info: {
    name: string;
    is_private: boolean;
    type: string;
    image: string;
  }
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  anchorEl: HTMLElement | null;
  eventModal: boolean;
  hyperLinkModal: boolean;
  videoModal: boolean;
  imageModal: boolean;
  mainModal: boolean;
  post_type: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class CreatePostNewController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area En
    ];
    this.state = {
      // Customizable Area Start
      isLoading: false,
      anchorEl: null,
      eventModal: false,
      hyperLinkModal: false,
      videoModal: false,
      imageModal: false,
      mainModal: false,
      post_type: '', // for video modal
      // Customizable Area End

    };


    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount(){
    super.componentDidMount();
  }
  handleOpenMainModal = () => {
    this.setState({ mainModal: true })
  }
  handleCloseEnchor = () => {
    this.setState({ anchorEl: null })
  }
  handleVideoOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget })
  }
  handleOpenPhoto = () => {
    this.setState({imageModal: true})
  }
  handleOpenYTVideo = () => {
    this.setState({videoModal: true, post_type: 'YouTube'})
  }
  handleOpenVimeoVideo = () => {
    this.setState({videoModal: true, post_type: 'Vimeo'})
  }
  handleEventOpen = () => { 
    this.setState({eventModal: true})
  }
  handleLinkOpen = () => { 
    this.setState({hyperLinkModal: true})
  }
  closeModal = () => {
    this.setState({eventModal: false,mainModal: false, hyperLinkModal: false, videoModal: false, imageModal: false})
  }
  // Customizable Area End
}

