import React from "react";

import {
  Box,
  Typography,
  // Customizable Area Start
  Card,
  Grid,
  MenuItem,
  Modal,
  Fade,
  Backdrop
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createStyles, Theme, withStyles, createTheme, ThemeProvider } from "@material-ui/core/styles";
import { checkListIcon, rightCircleArrowIcon } from "./assets";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


const AssessmentTestListingStyles = (theme: Theme) => createStyles({
  root: {
    borderRadius: "12px",
    position: "relative",
    marginBottom: "12px",
    overflow: "hidden",
  },
  card: {
    borderRadius: "12px",
  },
  list: {
    overflowY: "auto",
    maxHeight: "800px",
    minHeight: "420px",
    padding:"24px",
    '&::-webkit-scrollbar': {
        width: '2px',
        height: '2px',
        background: 'gray',
    }
  },
  header: {
    padding: "25px 0px 23px 24px",
    borderBottom: "1px solid #808080",
    display: "flex",
  },

  heading: {
    marginRight: "9px",
    color: "#005487",
    lineHeight: 1.1,
    letterSpacing: "-0.41px",
    fontSize: "20px",
    fontWeight: "bold",
    fontFamily: "Verdana",
  },

  infoCardIcon: {
    width: "35px",
    height: "35px",
  },

  dropDownIcon: {
    color: "#73777A",
    border: "1px solid #73777A",
    borderRadius: "7px",
    height: "17px",
    width: "16px",
  },

  dropdownList: {
    borderRadius: "12px",
    position: "absolute",
    padding: "20px",
    top: "56px",
    marginLeft: "auto",
    boxShadow: "2px 4px 16px 4px rgba(0, 0, 0, 0.25)",
    left: "50%",
    transform: "translateX(-50%)",
    minWidth: "480px",
  },

  dropdownListItem: {
    borderRadius: "12px",
    height: '88px',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
    padding: "0px 25px",
    margin: "0px 4px",
    cursor: "pointer",
    "&:hover":{
        backgroundColor: "#fff",
        border: "1px solid #005487",
    }
  },

  innerCardItem: {
    display: "flex",
    alignItems: "center",
  },

  rightIcon: {
    width: "22px",
    height: "22px",
    "& > svg": {
      width: "22px",
      color: "#000",
      height: "22px",
    },
  },


  modalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  modalRoot: {
    width: "604px",
    backgroundColor: "#fff",
    borderRadius: "12px",
    boxSizing: "border-box",
    padding: "24px"
  },
  modalHeading: {
    textAlign: "center",
    fontSize: "17px",
    lineHeight: "22px",
    letterSpacing: "-0.41px",
    textTransform: "uppercase",
    fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
    color: "#005487",
    fontWeight: "bold",
    marginBottom: "20px"
  },
  modalList: {
    marginBottom: "20px"
  },
  modalListItem: {
    padding: "19px 20px 19px 20px"
  },
  modalListItemText: {
    textAlign: "center",
    fontSize: "17px",
    lineHeight: "22px",
    letterSpacing: "-0.41px",
    textTransform: "uppercase",
    fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
    color: "#808080",
    fontWeight: "bold"

  },
  modalButtonContainer: {
    cursor: 'pointer'
  },
  modalButton: {
    width: "100%",
    paddingTop: "13.1px",
    paddingBottom: "13.1px",
    fontSize: "16px",
    backgroundColor: "#015486",
    color: "#fff",
    lineHeight: 1.31,
    textTransform: "uppercase",
    fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
    fontWeight: "bold",
    border: "0px"
  },
  scrollableBox: {
    padding: 0,
    flexDirection: "column",
    display: "flex",
    margin: "0px 0px 4px",
    gap: "20px",
  }
})


// Customizable Area End

import AssessmentTestListingController, {
  Props,
  configJSON,
} from "./AssessmentTestListingController";
import { handleInvalidData } from "../../../components/src/common";
import Loader from "../../../components/src/Loader.web";

export class AssessmentTestListing extends AssessmentTestListingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Loader loading={this.state.isLoading} />
        <Grid className={this.props.classes.root}>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={this.props.classes.modalContainer}
            open={this.state.isModalOpen}
            onClose={() => this.handleClose(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 200,
            }}
          >
            <Fade in={this.state.isModalOpen}>
              <Box className={this.props.classes.modalRoot} >
                <Typography className={this.props.classes.modalHeading}>MCQ's Shortforms</Typography>
                <Box className={this.props.classes.modalList}>
                  {this.fullFormsList.map((data: string,index:number) => {
                    return <MenuItem key={`assessmentTest-${index}`} className={this.props.classes.modalListItem}>
                      <Typography className={this.props.classes.modalListItemText}>{data}</Typography>
                    </MenuItem>
                  })}
                </Box>
                <Box className={this.props.classes.modalButtonContainer}>
                  <button style={{cursor: "pointer"}} onClick={this.handleGotItClick}  className={this.props.classes.modalButton}>Got it!</button>
                </Box>
              </Box>
            </Fade>
          </Modal>
          <Card className={this.props.classes.card}>
            <Box className={this.props.classes.header}>
              <Typography className={this.props.classes.heading}>{configJSON.labelTitleText}</Typography>
              
            </Box>
            <Box className={this.props.classes.list}>
              {this.state.testList.length > 0 ?
              <Box component={'ul'} className={this.props.classes.scrollableBox}>
                {this.state.testList?.map((e: any, i: number) => {
                  return (
                    <MenuItem data-test-id='menu-list' onClick={() => this.handleTestClick(e)} key={`assessmentTest-${i}`} className={this.props.classes.dropdownListItem}>
                      <Box className={this.props.classes.innerCardItem}>
                        <img src={checkListIcon} className={this.props.classes.infoCardIcon} />
                        <Box component={"p"} fontFamily={"Verdana"} fontWeight={"bold"} fontSize={"20px"} m={0} ml={"24px"}>{e.title}</Box>
                      </Box>
                        <img src={rightCircleArrowIcon} alt="arrow-icon"/>
                    </MenuItem>
                  );
                })} 
                
              </Box>
                :
                <Typography
                  className={`Heading3 BoldFont FontColor6`}
                  gutterBottom
                  variant="body2"
                  component="p"
                >No test found.</Typography>
              }

            </Box>
          </Card>
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(AssessmentTestListingStyles)(AssessmentTestListing);

// Customizable Area End
