import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { Typography, Box, MenuItem, Menu, Button } from "@material-ui/core";
// Customizable Area Start
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CommonListingController, { configJSON, Props } from "./CommonListingController.web";
import { SearchIcon, more, blue_close, slash } from "./assets";
import Loader from "../../../components/src/Loader.web";
import styled from "@material-ui/core/styles/styled";

// Customizable Area End

// Customizable Area Start
export default class CommonListing extends CommonListingController {
    constructor(props: Props) {
        super(props);
    }

    rendershowAll = () => {
        const { list } = this.props;
        return (<>
            {!this.state.isShowAllEnabled && list.length > 5 && <Box mt={"20px"} display={"flex"} justifyContent={"center"}>
                <Button className={"showallbtn"} data-testid="show-all-btn" onClick={() => this.setShowAllStatus(true)}>{configJSON.show_all}</Button>
            </Box>}
        </>)
    }

    renderModal = () => {
        return (
            <ModalWrpper
                open={this.state.block.open}
                onClose={this.handleCloseBlockModal}
                closeAfterTransition
                BackdropProps={{
                    timeout: 200
                }}
                BackdropComponent={Backdrop}
            >
                <Fade in={this.state.block.open}>
                    <Box sx={{ width: { xs: "85%", sm: "60%", md: "50%", lg: "35%" }, maxWidth: "604px" }} className="fadeContainer" display="flex" alignItems="center" justifyContent="center" >
                        <Box bgcolor="#fff" borderRadius="12px" width="100%">
                            <Box sx={{ border: "1px solid black", margin: "20px", padding: "20px", borderRadius: "12px " }}>
                                <Box component="h3" color="#005487" fontFamily={"Verdana"} fontWeight={"bold"} fontSize={"20px"} m={0}>
                                    {this.state.block.type} Account
                                </Box>
                                <Box component="p" color="#808080" fontFamily={"Verdana"} fontWeight={"bold"} fontSize={"17px"} m={0} mt={"20px"}>
                                    Are you sure you want to {this.state.block.type} selected account?
                                </Box>
                                <Box sx={{ display: "flex", alignItems:"end", height: "340px" }}>
                                    <Box sx={{ flexDirection: { xs: "column", md: "row" }, width:"100%" }} alignItems="center" display="flex" justifyContent="space-around">
                                        <Button
                                            type="button"
                                            className="Publishbtn"
                                            data-test-id="cancel-id"
                                            onClick={this.handleCloseBlockModal}
                                        >Cancel</Button>
                                        <Button
                                            className="Publishbtn"
                                            data-test-id="confirm-id"
                                            type="submit"
                                            onClick={this.handleBlockUnblock}
                                        >{this.state.block.type}</Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </ModalWrpper>
        )
    }

    render() {
        const { list, listType } = this.props;

        return (
            <Wrapper sx={{padding: "24px"}}>
                {this.renderModal()}
                <Loader loading={this.state.isLoading} />
                <Box padding={"8px"} bgcolor={"#f4f4f4"}>
                    <Box padding={"24px"} bgcolor={"white"}>
                        <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            mb={"20px"}
                        >
                            <Box component="p" m={0} sx={{fontFamily: "Verdana", fontSize:"20px", fontWeight: "bold"}} color="#005487" >
                                {this.headingText()}
                            </Box>
                            {(listType === "FRIENDS" || listType === "FOLLOWERS" || listType === "COMMUNITY") && <img
                               className= {"pointer"}
                                src={SearchIcon}
                                onClick={this.props.setShowSearch}
                            />}

                        </Box>
                        <Box display={"flex"} flexDirection={"column"} className={"gap18"}>
                            {
                                list.length === 0 ?
                                    <p>{this.noDataFoundMessage()}</p> :
                                    list.filter((_, index: number) => this.state.isShowAllEnabled ? true : index < 5).map((details) => (
                                        <Box
                                            key={details.id}
                                            display={"flex"}
                                            justifyContent={"space-between"}
                                            data-testid="list-item"
                                        >
                                            <Box display={"flex"}>
                                                <img
                                                    src={details.profilePicture}
                                                    alt="profile"
                                                    className={"cardUserImage"}
                                                    onClick={()=>this.handleProfileOpen(details.id)}
                                                />
                                                <Box component="p" m={0} ml={"12px"} sx={{fontWeight: "bold", fontFamily: "Verdana", fontSize:"17px"}}>
                                                    {details.name}
                                                </Box>
                                            </Box>
                                            {this.isOwnUser && <Box id={`list-menu-box-${details.id}`} key={`list-menu-box-${details.id}`} style={{ cursor: "pointer" }}>
                                                <IconButton
                                                    id={`demo-positioned-button-${details.id}`}
                                                    aria-controls={`list-menu-${details.id}`}
                                                    aria-haspopup="true"
                                                    data-testid="menu-button"
                                                    aria-expanded={Boolean(this.state.anchorEl[details.id])}
                                                    onClick={(e) => this.handleEnchorClick(e, details.id)}
                                                >
                                                    <img src={more} alt="more"/>
                                                </IconButton>
                                                <Menu
                                                    id={`list-menu-${details.id}`}
                                                    key={`list-menu-${details.id}`}
                                                    aria-labelledby={`demo-positioned-button-${details.id}`}
                                                    anchorEl={this.state.anchorEl[details.id]}
                                                    open={Boolean(this.state.anchorEl[details.id])}
                                                    onClose={() => this.handleCloseEnchor(details.id)}
                                                    anchorOrigin={{
                                                        vertical: "top",
                                                        horizontal: "left",
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "right",
                                                    }}
                                                    PaperProps={{
                                                        style: { borderRadius: '12px' }
                                                    }}
                                                >
                                                   {listType === "COMMUNITY" && <MenuItem
                                                        key={`list-menu-item-0-${details.id}`}
                                                        data-testid="block-menu"
                                                        onClick={()=>this.handleOpenBlockModal(details.id)}
                                                    >
                                                        <Box
                                                            padding={"7px"}
                                                            display={"flex"}
                                                            justifyContent={"space-between"}
                                                            marginRight={"8rem"}
                                                        >
                                                            <Box marginRight={"17px"}><img src={slash} alt="slash"/></Box>
                                                            <Typography className={"text"} data-testid="block-text">
                                                                {details.is_blocked_by_community ? "Unblock" : "Block"}
                                                            </Typography>
                                                        </Box>
                                                    </MenuItem>}
                                                    <MenuItem
                                                        key={`list-menu-item-1-${details.id}`}
                                                        data-test-id="remove-item"
                                                        onClick={()=>this.handleMenuClick(details.id)}
                                                    >
                                                        <Box

                                                            padding={"7px"}
                                                            display={"flex"}
                                                            justifyContent={"space-between"}
                                                            marginRight={"8rem"}
                                                        >
                                                            <Box marginRight={"17px"}><img src={blue_close} alt="close"/></Box>
                                                            <Typography className={"text"}>
                                                                Remove
                                                            </Typography>
                                                        </Box>
                                                    </MenuItem>
                                                </Menu>
                                            </Box>}
                                            {this.props.listType === "MUTUAL" && 
                                                    <Button className={"mutual"} data-testid="mutual-btn" onClick={()=>this.handleMutualBtn(details.id)}>
                                                        Message
                                                    </Button>
                                            }
                                        </Box>
                                    ))
                            }

                        </Box>
                        {this.rendershowAll()}
                    </Box>
                </Box>
            </Wrapper>
        );
    }
}
const ModalWrpper = styled(Modal)({
    display:"flex",
    justifyContent: "center",
    "& .Publishbtn": {
        color: "#01548a",
        border: "solid 1px #005487",
        height: "50px",
        backgroundColor: "#fff",
        fontWeight: "bold",
        width: "40%",
        textTransform: "none",
        marginTop: "8px",
        "&:active": {
          color: "#fff",
          backgroundColor: "#01548a",
        },
    },
});

const Wrapper = styled(Box)({
    "& .pointer":{
        cursor: "pointer"
    },
    "& .gap18":{
        gap: "18px"
    },
    "& .showallbtn":{
        color: "#005487",
        fontFamily: "Verdana",
        fontSize: " 17px",
        textTransform: "initial",
    },
    "& .mutual":{
        height: "32px",
        width:"132px",
        color: "#fff",
        backgroundColor: "#005487",
        fontSize: "14px !important",
        "&:hover":{
            color: "#fff",
            backgroundColor: "#005487",
        }
    },
    "& .text":{
        fontFamily: "Verdana",
            fontSize: "17px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "1.29",
            letterSpacing: "-0.41px",
            color: " #808080",
    },
    "& .cardUserImage":{
        height: "50px",
        width: "50px",
        borderRadius: "50%",
        cursor: "pointer"
    }
});
// Customizable Area End