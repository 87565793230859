import React from "react";
// Customizable Area Start
import {
  Box, Divider, TextField, InputAdornment, Switch, Button, RadioGroup, FormControl, FormControlLabel, Radio
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import CreateQuestionController, { configJSON } from "./CreateQuestionController.web";
import { BlueAdd, Trash, Copy, Edit, Dropdown, Pencile } from "./assets";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import Loader from "../../../components/src/Loader.web";
import Scoring from "../../Scoring/src/Scoring.web";
// Customizable Area End


export default class CreateQuestion extends CreateQuestionController {
  // Customizable Area Start
  renderRadio = (questionId: number) => {
    const currunt ={...this.state.questions[questionId]};

    return (<>
      {currunt.complated ?
        <RadioWrapper sx={{ padding: "0px 10px" }}>
          <FormControl component="fieldset">
            <RadioGroup >
              <FormControlLabel value="true" disabled control={<Radio size="small" />} label="True" />
              <FormControlLabel value="false" disabled control={<Radio size="small" />} label="False" />
            </RadioGroup>
          </FormControl>
        </RadioWrapper>
        :
        <Box>
          <Box display={"flex"} sx={{ margin: "17px 0 0 0" }} justifyContent={"space-between"}>
            <Box component={"p"} fontSize={"17px"} m={0} fontWeight={"bold"}>Answer</Box>
            {currunt.is_score_required && <Box component={"p"} fontSize={"17px"} m={0} fontWeight={"bold"}>Score</Box>}
          </Box>
          <Box className="gap-14" display={"flex"} alignItems={"center"}>
            <TextField
              variant="outlined"
              size="medium"
              placeholder={'True'}
              name={"true"}
              type="text"
              value={"True"}
              disabled={true}
              className="option-field"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" >
                    <img src={BlueAdd} alt="BlueAdd" />
                  </InputAdornment>
                ),
              }}
            />
            {currunt.is_score_required && <Scoring navigation={this.props.navigation} id={"score"} disabled={true} value={1} />}
          </Box>
          <Box className="gap-14" display={"flex"} alignItems={"center"}>
            <TextField
              variant="outlined"
              size="medium"
              placeholder={'False'}
              name={"false"}
              type="text"
              value={"False"}
              disabled={true}
              className="option-field"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" >
                    <img src={BlueAdd} alt="BlueAdd" />
                  </InputAdornment>
                ),
              }}
            />
            {currunt.is_score_required && <Scoring navigation={this.props.navigation} id={"score"} disabled={true} value={0} />}
          </Box>
        </Box>}
    </>
    )
  }

  renderField = (questionId: number) => {
    const currunt ={...this.state.questions[questionId]};
    return (
      <TextField
        disabled={true}
        variant="outlined"
        size="medium"
        placeholder={"Type your Short or Long answer here"}
        name={`anonymous`}
        type="text"
        fullWidth
        InputProps={{
          style: {
            marginTop: currunt.complated ? "" : "16px",
          },
        }}
      />
    )
  }

  renderMcq = (questionId: number) => {
    const currunt ={...this.state.questions[questionId]};
    return (<>
      {currunt.complated ?
      <RadioWrapper sx={{ padding: "0px 10px" }}>
      <FormControl component="fieldset">
        <RadioGroup >
        {currunt.options.map((item)=>{
         return(
          <FormControlLabel key={item.option_text} value={item.option_text} disabled control={<Radio size="small" />} label={item.option_text} />
          )
        })}
        </RadioGroup>
      </FormControl>
    </RadioWrapper>
      :<Box>
      <Box display={"flex"} mt={"17px"} justifyContent={"space-between"}>
      <Box component={"p"}  fontSize={"17px"} m={0} fontWeight={"bold"}>Answer</Box>
      {currunt.is_score_required && <Box component={"p"}  fontSize={"17px"} m={0} fontWeight={"bold"}>Score</Box>}
      </Box>
      {[...currunt.options].map((data, index) => {
        return (
          <Box key={`${index + 0}`} className="gap-14" display={"flex"} alignItems={"center"}>
        <TextField
          data-test-id="option-id"
          variant="outlined"
          size="medium"
          placeholder={`Add Option ${index+1} here`}
          name={`option${index + 1}`}
          type="text"
          value={data.option_text}
          className="option-field"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChangeOption(e, index, questionId)}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" >
                <img src={BlueAdd} alt="BlueAdd" />
              </InputAdornment>
            ),
          }}
        />
        {currunt.is_score_required && <Scoring navigation={this.props.navigation} id={"score"} onChange={this.handleChangeScore} questionId={questionId} optionId={index} value={data.score}/>}
        </Box>
        )
      })}
      <Box display="flex" justifyContent="flex-end">
        <Button
          className="add-btn"
          data-test-id="option-add-id"
          onClick={() => this.handleAddOption(questionId)}
          startIcon={<img src={BlueAdd} alt="BlueAdd" />}
        >
          {configJSON.addOption}
        </Button>
      </Box></Box>}
    </>
    )
  }

  renderAnswer = (type: string, index: number) => {
    if (type === "free_text") {
      return this.renderField(index);
    } else if (type === "MCQ") {
      return this.renderMcq(index);
    } else if (type === "TnF") {
      return this.renderRadio(index);
    } else {
      return (
        <>
          <Box component={"p"} fontSize={"16px"} m={0} mt={"15px"} mb={"3px"} fontWeight={"bold"}>Answer</Box>
          <CustomSelectDropdown
            placeHolderText="Select Answer Format"
            fieldId="answer-select"
            data-test-id="select-id"
            fieldValue={type}
            fieldName="answerType"
            handleChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.handleChangeDropdownValue(e, index)}
            MenuProps={MenuProps}
            renderValue={this.renderValue}
            fieldMenuItems={this.state.menuItems}
            fieldError={false}
            iconComponent={
              <img src={Dropdown} alt="Dropdown" />
            }
            startAdornmentIcon={
              <img src={BlueAdd} alt="BlueAdd" />
            }
          />
        </>
      )
    }
  }

  renderDeletPopup = (index:number,id:string) => {
    return (
      <Box position={"relative"}>
      <img src={Trash} alt="Trash" className="pointer" data-test-id="trash-id"  onClick={()=>this.handleDeleteOpen(index)} />
      {this.state.deletePopup === index ? <Box component="div" p={"25px"} position="absolute" className="z-index" display="flex" alignItems={"center"} flexDirection={"column"} sx={{width: {xs: "auto", sm:"280px"}, borderRadius: "12px",right:{xs:"-90px",sm:"-120px",md:"-10px"},top:"35px",boxShadow: "2px 4px 16px 4px rgba(0, 0, 0, 0.25)"}} bgcolor={"#fff"}>
        <Box component="p" fontFamily={"Verdana"} fontSize={"17px"} color={"#005487"} m={0} fontWeight={"bold"}>{configJSON.deleteQuestion}</Box>
        <Box component="p"textAlign={"center"} fontFamily={"Verdana"} marginTop="17px" marginBottom="35px">{configJSON.deleteDescription}<br/>{configJSON.selectedQuestion}</Box>
        <Box display="flex" justifyContent={"space-evenly"} width={"100%"}>
        <Button variant="contained" className="cancel-btn" data-test-id="cancel-id"  onClick={this.handleDeleteCancel}>
          {configJSON.cancelBtn}
        </Button>
        <Button variant="contained" className="delete-btn" data-test-id="delete-id" onClick={() => this.handleDeleteQuestion(index,id)}>
          {configJSON.deleteBtn}
        </Button>
        </Box>
      </Box>: null}
      </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {this.state.loading && <Loader loading={this.state.loading} />}
        <Wrapper display="flex" flexDirection="column" sx={{ width: { xs: "90%", md: "100%" }, margin: "auto", }} bgcolor="white">
          <Box display="flex" alignItems="center" justifyContent="space-between" padding="20px">
            <Box component="p" sx={{ fontSize: "20px", fontWeight: "bold" }} m={0} color="#005487">{configJSON.create_test}</Box>
          </Box>
          <Divider className="divider" />
          <Box padding={"24px"} borderRadius={"12px"} bgcolor={"#fff"} >
            <Box className="gap10" bgcolor={"#6f6d6d1f"} borderRadius={"12px"} display={"flex"} flexDirection={"column"} p={"10px"} >

              <Box bgcolor={"#fff"} p={"24px"} borderRadius={"12px"}>
                <Box component={"p"} fontSize={"16px"} fontWeight={"bold"} m={0}>{configJSON.testTitle}</Box>
                <TextField
                  data-test-id="name-id"
                  variant="outlined"
                  size="medium"
                  placeholder={"Add Test Title here"}
                  name={`name`}
                  type="text"
                  className="hover"
                  value={this.state.testName.value}
                  onChange={this.handleChangeName}
                  fullWidth
                  InputProps={{
                    style: {
                      marginTop: "16px"
                    },
                    startAdornment: (
                      <InputAdornment position="start" >
                        <img src={Edit} alt="Edit" />
                      </InputAdornment>
                    ),
                  }}
                />
            {this.state.testName.error && <Box component="p" color="red" fontFamily="Verdana" fontSize="14px" m={0}>{this.state.testName.error}</Box>}
              </Box>

              {
                [...this.state.questions].map((data, index: number) => {
                  return (
                    <Box bgcolor={"#fff"} key={`${index + 1}`} p={"24px"} borderRadius={"12px"}>
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Box component={"p"} fontSize={"16px"} my={"8px"} fontWeight={"bold"} className="question-label">Question {index + 1}{(data.complated && data.required) && "*"}</Box>
                        <Box display={"flex"} alignItems={"center"} flexWrap={"wrap"}>
                          <Box display={"flex"} className="gap10">
                            {data.complated && <img src={Pencile} alt="Pencile" className="pointer" data-test-id="Pencile-id" onClick={() => this.handleEdit(index)} />}
                            <img src={Copy} alt="Copy" className="pointer" data-test-id="copy-id" onClick={() => this.handleCopy(index)} />
                            {this.renderDeletPopup(index,data.id)}
                          </Box>
                          {!data.complated &&
                            <><Box component={"p"} m={0} mx={"7px"}>|</Box>
                              <Box component={"p"} m={0} fontSize={"16px"} mr={"3px"}>{configJSON.required}</Box>
                              <SWITCH checked={data.required} data-test-id="switch-id" onChange={() => this.handleRequired(index)} />
                            </>}
                        </Box>
                      </Box>
                      {data.complated ?
                        <Box component={"p"} fontSize={"16px"} mt={"20px"} mb={"17px"} className="quetion-render">{index+1}.{data.question}{data.required && "*"}</Box>
                        : <>
                          <Box component={"p"} fontSize={"16px"} m={0} mb={"3px"} mt={"15px"} fontWeight={"bold"}>{configJSON.question}</Box>
                          <TextField
                            data-test-id="question-id"
                            variant="outlined"
                            size="medium"
                            placeholder={configJSON.addQuestionPlaceholder}
                            name={`question${index + 1}`}
                            type="text"
                            value={data.question}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChangeQuestion(e, index)}
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start" >
                                  <img src={BlueAdd} alt="BlueAdd" />
                                </InputAdornment>
                              ),
                            }}
                          /></>}
                      {this.state.error.id === index && <Box component="p" color="red" fontFamily="Verdana" fontSize="14px" m={0}>{this.state.error.question}</Box>}
                      {this.renderAnswer(data.type, index)}
                      {this.state.error.id === index && <Box component="p" color="red" fontFamily="Verdana" fontSize="14px" m={0}>{this.state.error.type}</Box>}
                      {!data.complated && <Box mt={"24px"} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                       {(data.type && data.type!=="free_text")? <Box display={"flex"} alignItems={"end"}>
                          <Box component={"label"} fontFamily={"Verdana"} fontSize={"16px"} mr={"2px"}>Scoring</Box>
                          <SWITCH checked={data.is_score_required} data-test-id="score-id" onChange={() => this.handleScoreSwitch(index)} />
                        </Box>: <div/>}
                        <Button
                          variant="contained"
                          className="done-btn"
                          data-test-id="done-btn-id"
                          onClick={() => this.complateQuestion(index)}
                        >
                          {configJSON.doneBtn}
                        </Button>
                      </Box>}
                    </Box>
                  )
                })

              }
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Button
                className="add-btn"
                data-test-id="add-more-question"
                onClick={this.handleAddQuestion}
                startIcon={<img src={BlueAdd} alt="BlueAdd" />}
              >
                {configJSON.addBtn}
              </Button>
            </Box>
            {this.state.submitError && <Box component="p" color="red" fontFamily="Verdana" fontSize="14px" m={0}>{this.state.submitError}</Box>}
          </Box>
          <Divider className="divider" />
          <Box display="flex" alignItems="center" justifyContent="space-around" padding="20px">
          <Button
              variant="contained"
              className="submit-btn"
              data-test-id="draft-id"
              onClick={this.handleDraft}
            >
              {configJSON.draftBtn}
            </Button>
            <Button
              variant="contained"
              className="submit-btn"
              data-test-id="submit-id"
              onClick={this.handleSubmit}
            >
              {configJSON.submitBtn}
            </Button>
          </Box>
        </Wrapper>

      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  borderRadius: "12px",
  fontFamily: "Verdana",
  "& .pointer": {
    cursor: "pointer"
  },
  "& .gap10": {
    gap: "10px",
  },
  "& .gap-14":{
    gap: "14px"
  },
  "& .add-btn": {
    color: "#005487",
    marginTop: "14px",
    fontWeight: "normal !important",
  },
  "& .done-btn": {
    padding: "8px 40px !important",
    fontFamily: "Verdana",
    backgroundColor: "#005487",
    color: "#fff",
    height: "56px"
  },
  "& .submit-btn": {
    width: "30%",
    color: "#fff",
    maxWidth: "300px",
    backgroundColor: "#005487",
    fontFamily: "Verdana",
    height: "56px"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #808080"
  },
  "& .MuiOutlinedInput-root:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid black"
    },
  },
  "& .option-field":{
    margin:"3px 0"
  },
  "& .z-index": {
    zIndex: 1,
  },
  "& .cancel-btn":{
    width: "40%",
    color: "#005487",
    maxWidth: "156px",
    border:"1px solid",
    backgroundColor: "#fff",
    borderColor: "#005487",
    fontFamily: "Verdana"
  },
  "& .delete-btn":{
    width: "40%",
    color: "#fff",
    maxWidth: "156px",
    backgroundColor: "#005487",
    fontFamily: "Verdana"
  }
});

const RadioWrapper = styled(Box)({
  "& .MuiButtonBase-root": {
    padding: "3px !important",
  },
  "& .MuiIconButton-label": {
    width:"14px",
    height: "14px"
  },
  "& .MuiFormControlLabel-label":{
    fontSize: "12px",
    alignItems: "normal",
  },
  "& .MuiFormControlLabel-root":{
    margin: "2px"
  }
});

const SWITCH = styled(Switch)({
  height: "16px",
  width: "24px",
  padding: 0,
  margin: 0,
  overflow: "unset",
  "& .Mui-checked": {
    color: "#005487",
    left: "-12px",
  },
  "& .MuiSwitch-thumb": {
    width: "9px",
    height: "9px",
    borderRadius: "50%",
    border: "1px solid #000000",
    margin: "2px"
  },
  "& .MuiButtonBase-root": {
    padding: 0,
    margin: 0,
  },
  "& .MuiSwitch-track": {
    height: "14px",
    opacity: "0.65",
    borderRadius: "9px",
    border: "1px solid #000000",
    backgroundColor: "transparent",
  },
  "& .Mui-checked + .MuiSwitch-track": {
    border: "1px solid #005487",
    backgroundColor: "transparent",
  },
  "& .Mui-checked .MuiSwitch-thumb": {
    border: "1px solid #005487",
  }
});
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "320px",
      borderRadius: "12px",
    },
  },
};
// Customizable Area End
