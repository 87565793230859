import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { RefObject } from "react";
import { Keyboard, ScrollView } from "react-native";
import DocumentPicker from "react-native-document-picker";
import {
  InputProps
} from "@material-ui/core";
import { Client, Message as Tmessage } from 'twilio-chat';
import { sendAPIRequest } from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChatData {
  id: string;
  attributes: {
    id: number;
    name: string;
    is_notification_mute: boolean;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: IMessage[];
  };
  relationships: { accounts: { data: { id: string; type: string }[] } };
}

export interface IMessage {
  id: string;
  type: "chat_message";
  attributes: {
    id: number;
    message: string;
    account_id: number;
    chat_id: number;
    created_at: string;
    updated_at: string;
    is_mark_read: boolean;
    attachments: { id: number, url: string }[] | null;
  };
}

export interface ConversationApiType {
  chat_id: string,
  conversation_sid: string,
  last_message: string,
  group_name: string,
  group_icon: string | null,
  unique_name: string,
  chat_type: string,
  receipient_data: {
      data:
      {
          id: string,
          type: string,
          attributes: {
            receipient_email: string,
            twilio_identity: string,
            receipient_name: string,
              receipient_profile_pic: string
          }
      }[]
  },
  last_message_time: string,
  twilio_identity: string,
  name: string,
  email: string,
  profile_pic: string,
  notification_level: string,
  unread_messages_count: number,
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  type?: string;
  open?: boolean;
  handleClose?: () => void;
  handleAccept?: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  chatId: number;
  message: string;
  accountIdInput: string;
  accountId: number;
  chatData: IChatData | null;
  isVisibleModal: boolean;
  isVisiblePreviewModal: boolean;
  imageUrl: string;
  docRes: unknown;
  keyboardHeight: number;
  muted: boolean | null;
  loading: boolean;
  confirmationModel: {
    open: boolean,
    type: string
  };
  notificationLevel: string;
  typedMessage: string;
  channels: { sid: string, messages: Tmessage[] }[];
  imgModel: boolean;
  idName: { id: string, name: string }[];
  imgMessage: { id: number, url: string }[];
  menuOpen: boolean;
  conversationData: ConversationApiType;
  error: string;
  file : File | null;
  disabled: boolean; // for send btn disable
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatViewController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatApiCallId: string = "";
  addUserToChatApiCallId: string = "";
  leaveChatApiCallId: string = "";
  sendMessageApiCallId: string = "";
  toggleMuteApiCallId: string = "";
  updateReadMessageApiCallId: string = "";
  refreshChatInterval: unknown;
  //(this.isPlatformWeb() ? number: ReturnType<typeof setInterval>)
  //((this.isPlatformWeb()) ? (number) : (NodeJS.Timer));
  scrollViewRef: React.RefObject<ScrollView>;
  fileInputRef: React.RefObject<InputProps & { click: Function }>;
  deleteConversationId: string = "";
  exitConversationId: string = "";
  notificationchangeApi: string = "";
  fetchConversationsId: string = "";
  fetchToken: string = "";
  client: Client;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      chatId: 3,
      message: "",
      accountId: -1,
      accountIdInput: "",
      chatData: null,
      isVisibleModal: false,
      isVisiblePreviewModal: false,
      imageUrl: "",
      docRes: null,
      keyboardHeight: 0,
      muted: null,
      loading: false,
      confirmationModel: {
        open: false,
        type: ""
      },
      notificationLevel: "default",
      typedMessage: "",
      channels: [],
      imgModel: false,
      idName: [],
      imgMessage: [],
      menuOpen: false,
      conversationData: {
        chat_id: "",
        conversation_sid: "",
        group_name: "",
        group_icon: null,
        chat_type: "",
        unique_name: "",
        last_message: "",
        receipient_data: {
          data: [
            {
              id: "",
              type: "",
              attributes: {
                receipient_name: "",
                receipient_email: "",
                twilio_identity: "",
                receipient_profile_pic: "",
              },
            },
          ],
        },
        last_message_time: "",
        unread_messages_count: 0,
        name: "",
        email: "",
        twilio_identity: "",
        profile_pic: "",
        notification_level: "",
      },
      error: "",
      file: null,
      disabled: false,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.scrollViewRef = React.createRef();
    this.fileInputRef = React.createRef();
    this.client = new Client(localStorage.getItem("chatToken") ?? "token"); // Initialize the client here
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    Keyboard.addListener("keyboardDidShow", this._keyboardDidShow.bind(this));
    Keyboard.addListener("keyboardDidHide", this._keyboardDidHide.bind(this));
    const conversation_sid = this.props.navigation.getParam("navigationBarTitleText")
    const token = localStorage.getItem("chatToken") ?? "";
    this.getChatInfo();
    if(token){
    this.client =  new Client(token);
    await this.connectionBuild();
    this.messageAdded(this.client, conversation_sid)
    }else{
      const signupToken = await getStorageData('SignupSuccessToken');
        const loginToken = await getStorageData('LoginSuccessToken');
        const sameHeader: { method: "GET", headers: { "Content-Type": string, token: string } } = {
            method: configJSON.getApiMethod,
            headers: {
                "Content-Type": configJSON.apiContentType,
                token: signupToken || loginToken
            }
        }
        this.fetchToken = sendAPIRequest(configJSON.generateToken, sameHeader);
    }
  }

  async componentWillUnmount() {
    clearInterval(this.refreshChatInterval as number);
    this.client.removeAllListeners('messageAdded')
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  isStringNullOrBlank = (string: string) => {
    return !string || string.length === 0;
  };

  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  hidePreviewModal = () => {
    this.setState({ isVisiblePreviewModal: false, imageUrl: '', docRes: null });
  };

  handleAccountIdInputChange = (accountIdInput: string) => {
    this.setState({ accountIdInput });
  };

  handleMessageChange = (message: string) => {
    this.setState({ message });
  };

  handleSendMessage = () => {
    this.sendChatMessage(this.state.chatId, this.state.message, this.state.imageUrl);
    this.setState({ message: "", imageUrl: "", isVisiblePreviewModal: false });
  };

  getChatDetails = (token: string, chatId: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showChatApiEndPoint}/${chatId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addUserToChat = (accountIdInput: string, chatId: number) => {
    if (!accountIdInput || this.isStringNullOrBlank(accountIdInput)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const bodyData = {
        accounts_id: [Number(accountIdInput)],
        chat_id: Number(chatId),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addUserToChatApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addUserToChatApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  leaveChatRoom = (chatId: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const bodyData = {
      chat_id: parseInt(chatId + "", 10),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.leaveChatApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.leaveChatApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendChatMessage = async (chatId: number, message: string, imageUrl?: string) => {
    const header = {
      token: this.state.token,
    };
    const formData = new FormData();
    formData.append("message[message]", message);
    if (imageUrl) {
      formData.append("message[attachments][]", (this.state.docRes as Blob));
      this.setState({ docRes: null })
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sendMessageApiEndPoint}/${chatId}/messages`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changeNotificationStatus = () => {
    const { muted } = this.state;
    if (muted === null) {
      this.setState({ muted: true });
    } else {
      this.setState({ muted: !muted });
    }
    this.toggleMute();
  };

  toggleMute = () => {
    const { chatId, muted } = this.state;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const bodyData = {
      chat: { muted: !muted },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.toggleMuteApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateChatApiEndPoint}/${chatId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateReadMessages = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const bodyData = {
      chat_id: this.state.chatId,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateReadMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateReadMessageApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  openFile = async () => {
    try {
      const response = await DocumentPicker.pickSingle({
        type: [
          DocumentPicker.types.images,
        ],
      });

      if (response) {
        this.setState({ imageUrl: response.uri, isVisiblePreviewModal: true, docRes: response })
      }
    } catch (error) {
      if (DocumentPicker.isCancel(error)) {
        runEngine.debugLog("Message Recived", "User Canceled Picker");
      } else {
        runEngine.debugLog("Message Recived", error);
      }
    }
  };

  inputAccountIdProps = {
    onChangeText: (text: string) => {
      this.setState({ accountIdInput: text });
    },
  };

  inputMessageProps = {
    onChangeText: (text: string) => {
      this.setState({ message: text });
    },
  };

  btnAddAccountProps = {
    onPress: () =>
      this.addUserToChat(this.state.accountIdInput, this.state.chatId),
  };

  btnCloseModalProps = {
    onPress: () => this.hideModal(),
  };

  btnShowAddModalProps = {
    onPress: () => this.showModal(),
  };

  btnLeaveChatProps = {
    onPress: () => this.leaveChatRoom(this.state.chatId),
  };

  btnSendMessageProps = {
    onPress: () => {
      this.sendChatMessage(this.state.chatId, this.state.message, this.state.imageUrl);
      this.setState({ message: "", imageUrl: "", isVisiblePreviewModal: false });
    },
  };

  btnClosePreviewModal = {
    onPress: () => this.hidePreviewModal(),
  }

  btnMuteProps = {
    onPress: () => this.changeNotificationStatus(),
  };

  btnInsertPhotoProps = {
    onPress: () => this.openFile(),
  };

  _keyboardDidShow = (event: { endCoordinates: { height: number } }) => {
    this.setState({ keyboardHeight: event.endCoordinates.height });
  };

  _keyboardDidHide = (event: { endCoordinates: { height: number } }) => {
    this.setState({ keyboardHeight: 0 });
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token: string = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      runEngine.debugLog("TOKEN", token);
      
      this.manageData(message)
      
      if (token) this.setState({ token });
    }
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const chatData = message.getData(
        getName(MessageEnum.SessionResponseData)
      );
      const { chatId } = chatData;
      this.setState({ chatId }, () =>
        this.getChatDetails(this.state.token, chatId)
      );
    }

    const restApiDataCondition: boolean =
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id;
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId === this.getChatApiCallId &&
      responseJson && responseJson.data
    ) {
      const chatData = responseJson.data;
      const { muted } = chatData.attributes.accounts_chats.find(
        (item: { attributes: { account_id: number } }) =>
          item.attributes.account_id === this.state.accountId
      ).attributes;
      this.setState({
        chatData,
        muted,
      });
      this.updateReadMessages();
    }
    if (restApiDataCondition) {
      this.restApiDataConditionResponse(apiRequestCallId)
  }
    if (apiRequestCallId && responseJson) return this.webApiResponse(message)
  }

  manageData = (message: Message) =>{
    const data = message.getData(getName(MessageEnum.SessionResponseData))
      if(data){
    const messageData = JSON.parse(
      data
    );
    if(messageData?.meta){
      const accountId: number = messageData.meta.id;
      this.setState({ accountId });
    }
  }
  }

  restApiDataConditionResponse = (apiRequestCallId: string) => {
    if(apiRequestCallId === this.sendMessageApiCallId)
    {
      this.getChatDetails(this.state.token, this.state.chatId);
    }
    if (
      apiRequestCallId === this.addUserToChatApiCallId
    ) {
      this.hideModal();
      this.getChatDetails(this.state.token, this.state.chatId);
    }
    if (apiRequestCallId === this.leaveChatApiCallId) {
      this.props.navigation.goBack();
    }
  }

  scrollDiv: RefObject<HTMLDivElement> = React.createRef();

  webApiResponse = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson){
      if(apiRequestCallId === this.fetchConversationsId) return this.handleConversationResponse(responseJson)
      if(apiRequestCallId === this.deleteConversationId) return this.deleteConversationResponse(responseJson)
      if(apiRequestCallId === this.notificationchangeApi) return this.notificationResponse(responseJson)
      if(apiRequestCallId === this.exitConversationId) return this.ExitConversationResponse(responseJson)
      if(apiRequestCallId === this.fetchToken) return this.handleTokenResponse(responseJson)
    }
  }

  handleTokenResponse = async (responseJson: { data: { token: string } }) => {
   if(responseJson.data) { localStorage.setItem("chatToken", responseJson.data.token);
    this.client = new Client(responseJson.data.token);
    await this.connectionBuild();
    const conversation_sid = this.props.navigation.getParam("navigationBarTitleText")
    this.messageAdded(this.client, conversation_sid)
  }
  }

  handleConversationResponse = (responseJson: {
    conversations: ConversationApiType
  }) => {
    this.setState({ loading: false, conversationData: responseJson.conversations })
    this.setState({
      idName: responseJson.conversations.receipient_data.data.map((item: { attributes: { twilio_identity: string, receipient_name: string } }) => {
        return {
          id: item.attributes.twilio_identity,
          name: item.attributes.receipient_name
        }
      })
    });
  }

  notificationResponse = (responseJson: { data: { message: string } }) => {
    if (responseJson.data.message) {
      toast.success("notification chaged");
    }
  }

  deleteConversationResponse = (responseJson: { data: [{ message: string }] }) => {
    this.setState({ loading: false, menuOpen:false });
    const rep=responseJson.data[0].message;
    if(rep){
      toast.success(rep)
    }
    const msg = new Message(getName(MessageEnum.NavigationChatMessage));
        msg.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );
        this.send(msg)
  }

  ExitConversationResponse = (responseJson1: { data: [{ message: string }] }) => {
    this.setState({ loading: false, menuOpen: false });
    const rep=responseJson1.data[0].message;
    if(rep){
      toast.success(rep);
    }
    const msg1 = new Message(getName(MessageEnum.NavigationChatMessage));
        msg1.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );
        this.send(msg1)
  }

  getChatInfo = async () => {
    const signupToken = await getStorageData('SignupSuccessToken');
    const loginToken = await getStorageData('LoginSuccessToken');
    const sameHeader: { method: "GET", headers: { "Content-Type": string, token: string } } = {
      method: configJSON.getApiMethod,
      headers: {
        "Content-Type": configJSON.apiContentType,
        token: signupToken || loginToken
      }
    }
    const sid = this.props.navigation.getParam("navigationBarTitleText")
    this.fetchConversationsId = sendAPIRequest(configJSON.fetchSingleConversation+sid, sameHeader);
    this.setState({loading:true});
  }

  deleteConversation = async () => {
    this.setState({ loading: true, confirmationModel: { type: "", open: false } })
    const signupToken = await getStorageData('SignupSuccessToken');
    const loginToken = await getStorageData('LoginSuccessToken');
    this.deleteConversationId = sendAPIRequest(`${configJSON.deleteConversation}${this.state.conversationData.conversation_sid}`, {
      method: configJSON.deleteApiMethod,
      headers: {
        token: signupToken || loginToken
      }
    });
  };

  exitConverstaion = async () => {
    this.setState({ loading: true, confirmationModel: { type: "", open: false } });
    const signupToken = await getStorageData('SignupSuccessToken');
    const loginToken = await getStorageData('LoginSuccessToken');
    let body = new FormData();
    body.append("conversation_sid", this.state.conversationData.conversation_sid);

    this.exitConversationId = sendAPIRequest(configJSON.exitConversation, {
      method: configJSON.deleteApiMethod,
      body,
      headers: {
        token: signupToken || loginToken
      }
    });
  };

  changeNotification = (lev?: string) => {
    if (lev) return this.setState({ notificationLevel: lev });
    const level = this.state.notificationLevel === "default" ? "muted" : "default";
    this.setState({ notificationLevel: level });
    this.handleNotificationApi(level);
  }

  handleNotificationApi = async (level: string) => {
    const signupToke = await getStorageData('SignupSuccessToken');
    const loginToke = await getStorageData('LoginSuccessToken');
    let body = new FormData();
    body.append("notification_level", level);
    body.append("conversation_sid", this.state.conversationData.conversation_sid);
    this.notificationchangeApi = sendAPIRequest(configJSON.notificationEnd, {
      method: configJSON.postApiMethod,
      body,
      headers: {
        token: signupToke || loginToke
      }
    });
  }

  handleReport = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ReportChat");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationScreenNameMessage),
      this.state.conversationData.conversation_sid
    );
    this.send(message);
  };

  handleAddMember = () => {
    const msg = new Message(getName(MessageEnum.NavigationCreateChatMessage));
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    msg.addData(
      getName(MessageEnum.UrlPathParamMessage),
      `add-${this.state.conversationData.conversation_sid}`
    );
    this.send(msg)
  }

  handleOpenConfirmation = (type: string) => {
    this.setState({ confirmationModel: { type: type, open: true } });
  };

  handleCloseConfirmation = () => {
    this.setState({ confirmationModel: { type: "", open: false } });
  };

  handleAccept = () => {
    if (this.state.confirmationModel.type === "exit") return this.exitConverstaion();

    if (this.state.confirmationModel.type === "delete") return this.deleteConversation();
  };

  connectionBuild = async () => {
    const token = localStorage.getItem("chatToken") ?? "";
    this.client =  new Client(token);
    const channel = await this.client.getChannelBySid(this.props.navigation.getParam("navigationBarTitleText"));
    const newMessages = await channel.getMessages();
    newMessages.items.forEach(element => {
      this.mediaMessageAdded(element)
    });

    this.setState((prevState) => {
      const updatedChannels = [...prevState.channels];
      const channelIndex = updatedChannels.findIndex((ch) => ch.sid === channel.sid);

      if (channelIndex === -1) {
        updatedChannels.push({
          sid: channel.sid,
          messages: newMessages.items,
        });
      } else {
        updatedChannels[channelIndex].messages = newMessages.items;
      }

      return {
        channels: updatedChannels,
      };
    });

    this.scrollDivFn();

    const lastConsumedMessageIndex = newMessages.items.length > 0 ?newMessages.items.length -1 :newMessages.items.length;
    channel.updateLastConsumedMessageIndex(lastConsumedMessageIndex)
  }

  messageAdded = (client: Client, conversation_sid: string) => {
    client.on("messageAdded", (message: Tmessage) => {
      const { channel } = message;
      const channelSid = message.channel.sid;

      this.mediaMessageAdded(message);
      // Update the state with the incoming message for the corresponding channel
      if (channel.sid === conversation_sid) {
        const updatedChannels = this.state.channels.map((channel) => {
          if (channel.sid === channelSid) {
            return {
              ...channel,
              messages: [...channel.messages, message]
            };
          }
          return channel;
        });

        this.setState({ channels: updatedChannels })
        this.scrollDivFn();
        channel.updateLastConsumedMessageIndex(message.index);
      }
    });
  };

  mediaMessageAdded = (element: Tmessage) => {
    if (element.media && !element.body) {
      element.media.getContentTemporaryUrl().then(res => {
        this.setState({ imgMessage: [...this.state.imgMessage, { id: element.index, url: res }] })
        this.scrollDivFn();
      })
    }
  };

  findUrlfromindex = (index: number) => {
    const data = this.state.imgMessage.find(el => el.id === index)?.url
    this.scrollDivFn();
    return data as string;
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ typedMessage: e.target.value })
  }

  scrollDivFn = () => {
    if (this.scrollDiv?.current) {
      const scrollHeight = this.scrollDiv.current.scrollHeight;
      const height = this.scrollDiv.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      this.scrollDiv.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  }

  handleMenuOpen = () => { this.setState({ menuOpen: !this.state.menuOpen }) };

  handleNotification = () => {
    this.setState({ menuOpen: false });
    this.changeNotification()
  }

  messageSend = async () => {
    if (!this.state.typedMessage ) return
    this.setState({disabled: true})
    const token = localStorage.getItem("chatToken") ?? "";
    const client = new Client(token)
    const channel = await client.getChannelBySid(this.state.conversationData.conversation_sid)
    const message = await channel.sendMessage(String(this.state.typedMessage).trim())

    channel.updateLastConsumedMessageIndex(message);

    this.scrollDivFn();
    this.setState({ typedMessage: "",disabled: false });
  };

  handleCloseImgModeal = async (file?: File, buffer?: Buffer) => {
    if (!file) return this.setState({ imgModel: false });

    const token = localStorage.getItem("chatToken") ?? "";
    const client = new Client(token);
    const channel = await client.getChannelBySid(this.state.conversationData.conversation_sid);
    this.setState({ imgModel: false });
    // Send the message with the converted buffer
    channel.sendMessage({
      contentType: "image/jpeg",
      media: buffer ?? "",
    });
  };

  handlecloseImg = () =>{
    this.setState({ imgModel: false, imageUrl: "" });
  }

  showGroupMembers = () => {
    if (this.state.idName.length === 1) return `You and ${this.state.idName[0].name}`
    return this.state.idName.length > 0 ? `You, ${this.state.idName[0].name} and ${this.state.idName.length - 1} others` : "You"
  };

  convertTo24HourFormat = (date: string | Date): string => {
    const dateInput = new Date(date);
    const hours = dateInput.getHours();
    const minutes = dateInput.getMinutes();
  
    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  
    return `${formattedHours}:${formattedMinutes}`;
  }

  formatDateLabel = (dateString: string | Date): string => {
    const date = new Date(dateString);
    const today = new Date();
  
    if (date.toDateString() === today.toDateString()) {
      return 'Today';
    }
  
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
  
    if (date.toDateString() === yesterday.toDateString()) {
      return 'Yesterday';
    }
  
    return date.toLocaleDateString();
  };

  dummyData = () =>{
    let d = new Date();
    d.setDate(d.getDate() - 1);
    const message = {
      body: "https://facesvoices.page.link/s69n",
      dateCreated: new Date(),
      author: "shami",
      index:1
    } as unknown as Tmessage
    const message2= {
      body: "",
      dateCreated: d,
      author: "shami",
      index:1
    } as unknown as Tmessage
    this.setState({
      channels: [{sid: "CHd543331aa5344a9db3380d2f21cddf36",messages:[message,message2]}]
    })
  }

  handleOnImportFile = (fileData: FileList) => {
    if (fileData.length > 1) return this.setState({ error: "select only one image" });
    let ext = fileData[0].name.split('.').pop() as string;
    
    if (!["png", "jpg", "jpeg"].includes(ext)) return this.setState({ error: "image formate not supported" });

    this.setState({
        imageUrl: URL.createObjectURL(fileData[0]),
        file: fileData[0],
    })
  };

  openImgModel = () => {
    this.setState({ imgModel: true })
  }
  handleOpenUploadBtn = () => {
      document.getElementById("fileInput")?.click()
  }

  handleSubmit = async() => {        
    if (!this.state.imageUrl) return this.handleCloseImgModeal()

    if( this.state.file ){
          const fileBlob = await this.getFileBlob(this.state.file);
          // Convert the Blob object to a Buffer
          const reader = new FileReader();
          reader.readAsArrayBuffer(fileBlob);

          reader.onloadend = () => {
            const buffer = Buffer.from(reader.result as ArrayBuffer);
            this.state.file && this.handleCloseImgModeal(this.state.file,buffer)
            this.setState({file:null,imageUrl:""})
          };
      }
  }

  getFileBlob = (file: File): Promise<Blob> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const blob = new Blob([reader.result as ArrayBuffer], { type: file.type });
          resolve(blob);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
  };

  replaceUrl = (body: string) => {
    let urlRegex = /(https?:\/\/[^\s]+)/g;
        return body.replace(urlRegex, function(url) {
            return `<a href="${url}">${url}</a>`;
      });
  }
  // Customizable Area End
}
