import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgBell } from "./assets";
import React from "react" ;
import ApiCall from "../../../components/src/ApiCall.web";
import { toast } from "react-toastify";
import { getStorageData } from "../../../framework/src/Utilities";

export interface ResponseNotification {
  id: string;
  type: string;
  attributes: {
    id: number;
    created_by: number | null;
    headings: string;
    contents: string;
    app_url: string | null;
    is_read: boolean;
    read_at: string | null;
    created_at: string;
    updated_at: string;
    notificable: {
      id: number;
      likable_id: number;
      commentable_id: number;
      likable_type: string;
      event_type: string;
      event_name: string;
      timezone: string;
      description: string;
      start_date: string;
      end_date: string;
      start_time: string;
      end_time: string;
      street: string | null;
      city: string;
      state: string;
      zipcode: string;
      account_id: number;
      created_at: string;
      updated_at: string;
      event_url: string;
      community_id: number | null;
    } | null;
    completed_assessment: string;
    incompleted_assessment: string;
    percentage: number | null;
    deadline: string | null;
    cover_image: string | null;
    name: string | null;
    document: string | null;
    content_type: string;
  };
}

export interface DeleteNotification {
     message: string
}

export interface DeleteErrorNotification {
  errors: [string]
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any[];
  selectedData: any;
  token: any;
  notification_list:ResponseNotification[];
  loading: boolean;
  setBtnColor:boolean;
  anchorEl: null | HTMLElement;
  anchorId: string;
  page: number;
  has_more: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: string = "";
  markAsReadCallId: string = "";
  deleteCallId: string = "";
  deleteAPICallId: string = "";
  notificationAPICallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [],
      selectedData: null,
      token: "",
      notification_list:[],
      loading: false,
      setBtnColor:false,
      anchorEl: null,
      anchorId: "",
      page: 1,
      has_more: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.notificationApiRequest()
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors) {
        if(apiRequestCallId === this.notificationAPICallId) return this.notificationSucessCallBack(responseJson)
        if(apiRequestCallId === this.deleteAPICallId) return this.deleteNotificationSucessCallBack(responseJson)
      } else if (responseJson && responseJson.errors) {
        this.responseFailureCall(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  iconBellProps = {
    source: imgBell,
  };

  getNotifications() {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getDataCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPoint
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  markAsRead(id: number) {
    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsReadCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  deleteNotifications(id: number) {
    const deletedMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteCallId = deletedMsg.messageId;

    deletedMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deletedMsg.id, deletedMsg);
  }
  
  timeSince(date: string) {
    var seconds = Math.floor(
      (new Date().valueOf() - new Date(date).valueOf()) / 1000
    );
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }
  convertDate(inputFormat: string) {
    function pad(s: any) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
  }

  responseFailureCall = async (apiRequestCallId: string, responseJson: string & DeleteErrorNotification) => {
    if (apiRequestCallId === this.notificationAPICallId) {
      this.notificationFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.deleteAPICallId) {
      this.deleteNotificationFailureCallBack(responseJson);
    }
  };

  notificationApiRequest = async () => {
    this.setState({ loading: true })
    const param = `${configJSON.get_notification}?page=${this.state.page}&per_page=10`
    this.notificationAPICallId = await ApiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getDataMethod,
      endPoint: param,
    });
  };

  notificationSucessCallBack = (response:{ data : ResponseNotification[],meta:{more_records: boolean}}) => {
    this.setState({loading: false})
    if(response.data){
      const newData = response.data.filter(item => !this.state.notification_list.some(existingItem => existingItem.id === item.id));
      const updatedList = [...this.state.notification_list, ...newData];
      this.setState({notification_list:updatedList, page:this.state.page+1, has_more:response.meta.more_records})
    }
  }; 

  notificationFailureCallBack = (response: string) => {
    this.setState({loading:false})
  };

  deleteNotification = async (id: number | string) => {
    const newData = this.state.notification_list.filter(newItem => {
      return newItem.id !== id
    });
    const loginTokn = await getStorageData('LoginSuccessToken');
    const signupToken = await getStorageData('SignupSuccessToken');
    const header = {
      token: signupToken ?? loginTokn
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.deleteAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteEndPoint + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ page: Math.max(1, this.state.page - 1), notification_list: newData })
  };

  deleteNotificationSucessCallBack = (response:DeleteNotification) => {
    toast.success(response.message)
    this.notificationApiRequest()
  }; 

  deleteNotificationFailureCallBack = (response: DeleteErrorNotification) => {
    toast.success(response.errors[0])
  };

  assessmentTest = (id?:number) =>{
     const msg1 = new Message(getName(MessageEnum.NavigationAssessmentTestMessage));
      msg1.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      msg1.addData(
        getName(MessageEnum.UrlPathParamMessage),
        id
      );
      this.send(msg1)
  }

  scoreUpdate = (id:number|string) =>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "AssessmentDashboard");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
    this.deleteNotification(id)
  }

  navigateEvent = (id?: number|string) => {
    this.props.navigation.navigate("CalendarEventDetails",{id:id})
  }

  handleEnchorClick = (event: React.MouseEvent<HTMLElement>, id: string) => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget, anchorId: id });
  };

  handleCloseEnchor = () => {
    this.setState({
      anchorEl: null,
      anchorId: ""
    })
  }

  handleMenuClick = (e:  React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    this.deleteNotification(this.state.anchorId);
    this.handleCloseEnchor();
  }

  handleChatOpen = (type: string| null) => {
    if(type){
    const chatSid = type.split('/')
    const msg = new Message(getName(MessageEnum.NavigationChatViewMessage));
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    )
    msg.addData(
      getName(MessageEnum.UrlPathParamMessage),
      chatSid[2]
    );
    this.send(msg)
    }
  }

  handleReadNotification = async (id: string | number) => {
    const temp: ResponseNotification | undefined = this.state.notification_list.find(item => item.id === id);

    if (temp && !temp.attributes.is_read) {
      temp.attributes.is_read = true;
      const updatedList = this.state.notification_list.map(item =>
        item.id === id ? { ...item, attributes: { ...item.attributes, is_read: true } } : item
      );

      this.setState({ notification_list: updatedList });

      const signupToken = await getStorageData('SignupSuccessToken');
      const loginTokn = await getStorageData('LoginSuccessToken');
      const header = {
        token: signupToken ?? loginTokn
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.markAsReadCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.readEndPoint + id
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.markAsReadMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handlePostRedirection = (id?: string|number) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "SinglePost");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationScreenNameMessage),
      id
    );
    this.send(message);
  }

  handleLikeCommentRedirection = (id: string | number) => {
    const temp = this.state.notification_list.find(item => item.id === id)
    const postId = temp?.attributes.notificable?.likable_id ?? temp?.attributes.notificable?.commentable_id
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "SinglePost");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationScreenNameMessage),
      postId
    );
    this.send(message);
  }

  showMoreList = () => {
    this.notificationApiRequest();
  }
  // Customizable Area End
}
