import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  TextField
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
// Customizable Area End

import ScoringController, {
  Props,
  configJSON,
} from "./ScoringController";

export default class Scoring extends ScoringController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Wrapper>
      <TextField
        disabled={this.props.disabled}
        variant="outlined"
        data-testid="score-id"
        size="medium"
        value={this.props.value}
        className="option-field"
        placeholder={configJSON.placeHolder}
        name={"score"}
        type="number"
        onChange={this.handleChange}
      />
      </Wrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Wrapper = styled('div')({
  "& .option-field":{
    width:"60px"
  },
})
// Customizable Area End
