export const SendFill = require('../assets/send_fill.png')
export const RightArrow = require("../assets/arrow-right.svg");
export const SendFillBlue = require('../assets/send_blue.svg')
export const Save = require('../assets/Save.svg')
export const Link = require('../assets/Link.svg')
export const Notification = require('../assets/Notification.svg')
export const Report = require('../assets/Report.svg')
export const LocationSVG = require('../assets/LocationSVG.svg')
export const arrowleft = require('../assets/arrow-left.svg')
export const arrowsquaredown = require('../assets/arrow-square-down.svg')
export const messagetext = require('../assets/message-text.svg')
export const tickcirclewhite = require('../assets/tick-circle-white.svg')
export const tickcircle = require('../assets/tick-circle.svg')
export const editdarkColor = require('../assets/editdarkColor.svg')
