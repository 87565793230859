import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    MenuItem,IconButton
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import moment from "moment";
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { SendFill, SendFillBlue, Notification,MuteNotification,More, Link, Report, editImg,LocationSVG, Save, Trash, Star, BlueStar, blankCalender, smallLink, DotCalender, clock, Edit, } from "./assets";
import CalenderEventsDetailsController, { Props } from "./CalenderEventsDetailsController.web";
import Loader from "../../../components/src/Loader.web";
import  CreateEventStepOneModal  from "../../postcreation/src/CreateEventStepOneModal.web";
export const configJSON = require("./config");
// Customizable Area End

export default class CalenderEventsDetails extends CalenderEventsDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    interestedButtonRender = () => {
        return (
            <Box m={"5px"} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                <IconButton style={webStyle.iconColor} data-test-id={"interestedButton"} onClick={this.handleinterested}>
                    <img src={this.state.Interested ? BlueStar : Star} alt="Star"></img>
                </IconButton>
                <Typography variant="subtitle2" data-test-id="intrested-text">Interested</Typography>
            </Box>
        )
    };

    shareButtonRender = () => {
        const open = Boolean(this.state.anchorEl);
        return (
            <>
            <Box m={"5px"} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                    <IconButton style={webStyle.iconColor} data-test-id="handleshair_id" aria-controls={open ? 'menu-list-grow': ''} aria-haspopup="true" onClick={this.handleClick}>
                        <img src={SendFill} alt="share"></img>
                    </IconButton>
                    <Typography variant="subtitle2">{configJSON.share}</Typography>
                </Box>
                <Popper className="poper-div" open={open} anchorEl={this.state.anchorEl} role={undefined} transition disablePortal data-testid="poper-share-id">
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper style={webStyle.modalButton}>
                                <ClickAwayListener onClickAway={this.handleClose} >
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                                        <MenuItem onClick={this.copyLink} data-testid="coppy-item">
                                            <Box display={"flex"} alignItems={"center"} p={"17px"} py={"11px"}>
                                                <img src={Link} alt="Link" />
                                                <Box component={"p"} m={0} ml={"17px"} fontFamily={"Verdana"} color={"#808080"} fontSize={"17px"}>Copy link</Box>
                                            </Box>
                                        </MenuItem>
                                        <MenuItem onClick={this.handleSharePost} data-testid="share-item">
                                            <Box display={"flex"} alignItems={"center"} p={"17px"} py={"11px"}>
                                                <img src={SendFillBlue} alt="SendFillBlue" />
                                                <Box component={"p"} m={0} ml={"17px"} fontFamily={"Verdana"} data-testid="text-share" color={"#808080"} fontSize={"17px"}>Share post in app</Box>
                                            </Box>
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </>
        )
    };

    moreButtonRender = () => {
        const open2 = Boolean(this.state.anchorEl2);
        return (
            <>
                <Box m={"5px"} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                    <IconButton style={webStyle.iconColor} data-testid="more-id" aria-controls={open2 ? 'menu-list-grow' : ''} aria-haspopup="true" onClick={this.handleClickMore}><img src={More} alt="more"></img></IconButton>
                    <Typography variant="subtitle2">More</Typography>
                </Box>
                <Popper className="poper-div" open={open2} anchorEl={this.state.anchorEl2} role={undefined} transition disablePortal data-testid="poper-id">
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'start top' : 'start bottom' }}
                        >
                            <Paper style={webStyle.modalButton}>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <MenuList autoFocusItem={open2} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                                        {this.state.allDetails.attributes.is_login_user_event && <MenuItem data-test-id="handle_savePost" onClick={this.handalEditPost} >
                                            <Box display={"flex"} alignItems={"center"} p={"17px"} py={"11px"}>
                                                <img src={editImg} alt="edit"/>
                                                <Box component={"p"} m={0} ml={"17px"} fontFamily={"Verdana"} color={"#808080"} fontSize={"17px"}>Edit</Box>
                                            </Box>
                                        </MenuItem>}
                                        {!this.state.allDetails.attributes.is_login_user_event && <MenuItem data-testid="save-item" onClick={this.saveEventpostapi} >
                                            <Box display={"flex"} alignItems={"center"} p={"17px"} py={"11px"}>
                                                <img src={Save} alt="save"/>
                                                <Box component={"p"} m={0} ml={"17px"} fontFamily={"Verdana"} color={"#808080"} fontSize={"17px"} data-testid="save-message">{this.state.savePost ? "Unsave" : "Save"}</Box>
                                            </Box>
                                        </MenuItem>}
                                        <MenuItem onClick={this.handleNotification}>
                                            <Box display={"flex"} alignItems={"center"} p={"17px"} py={"11px"}>
                                                <img src={this.state.is_Notification ? MuteNotification : Notification} alt="notification"/>
                                                <Box component={"p"} m={0} ml={"17px"} fontFamily={"Verdana"} color={"#808080"} fontSize={"17px"}>Turn {this.state.is_Notification ? "off" : "on"} notification for this event</Box>
                                            </Box>
                                        </MenuItem>
                                        {this.state.allDetails.attributes.is_login_user_event ? <MenuItem onClick={this.deleteEvent} data-testid="delete-item">
                                            <Box display={"flex"} alignItems={"center"} p={"17px"} py={"11px"}>
                                                <img src={Trash} alt="Trash"/>
                                                <Box component={"p"} m={0} ml={"17px"} fontFamily={"Verdana"} color={"#808080"} fontSize={"17px"} data-testid="delete-message">Delete event</Box>
                                            </Box>
                                        </MenuItem> : 
                                        <MenuItem onClick={this.navigateToReport}>
                                        <Box display={"flex"} alignItems={"center"} p={"17px"} py={"11px"}>
                                            <img src={Report} alt="report"/>
                                            <Box component={"p"} m={0} ml={"17px"} fontFamily={"Verdana"} color={"#808080"} fontSize={"17px"}>Report event</Box>
                                        </Box>
                                    </MenuItem>}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </>
        )
    };

    modalButtonRender = () => {
        return (
            <Box sx={{ display: "flex", justifyContent: "center",alignItems: "center", gridGap: { xs: "30px", md: "64px" }}} mt={"12px"} mb="30px">

                {this.interestedButtonRender()}
                {this.shareButtonRender()}
                {this.moreButtonRender()}
            </Box>
        )
    };

    scheduleEventRender = () => {
        const { allDetails } = this.state
        return (
            <Box sx={{display: "flex", flexDirection: "column", gridGap: "18px"}}>
                <Box  display={"flex"} alignItems={"start"}>
                    <img className="img-description" src={blankCalender} alt="calender" />
                    <Box component={"p"} m={0} fontSize={"16px"} color="#808080"><Box component={"span"} m={0} color={"black"}>{allDetails.attributes.event_type + " " + "Event"}</Box> {configJSON.hosted} <Box component="span" fontWeight="bold" color="black" m={0}>{allDetails.attributes.full_name ? allDetails.attributes.full_name : allDetails.attributes.community_name} </Box></Box>
                </Box>
                {allDetails.attributes.event_type === "virtual" ?
                    <Box  display={"flex"} alignItems={"start"}>
                        <img className="img-description" src={smallLink} alt="link" />
                        <Box component={"p"} m={0} fontSize={"16px"} color="#808080"><a href={allDetails.attributes.event_url} target="_blank"  className={"linkColor"}>{allDetails.attributes.event_url}</a></Box>
                    </Box> :
                    <Box  display={"flex"} alignItems={"start"}>
                        <img className="img-description" src={LocationSVG} alt="location" />
                        <Box component={"p"} m={0} fontSize={"16px"} color="#808080">
                            {allDetails.attributes.street && (
                                <span>{allDetails.attributes.street} street <br /></span>
                            )}
                            {allDetails.attributes.city} city
                            <br />{allDetails.attributes.state} state
                            <br />Zipcode-{allDetails.attributes.zipcode}
                        </Box>
                    </Box>
                }
                <Box  display={"flex"} alignItems={"start"}>
                    <img className="img-description" src={DotCalender} alt="calender" />
                    <Box component={"p"} m={0} fontSize={"16px"} color="#808080">{moment(allDetails.attributes.start_date).format("DD MMMM YYYY")} - {moment(allDetails.attributes.end_date).format("DD MMMM YYYY")}</Box>
                </Box>
                <Box  display={"flex"} alignItems={"start"}>
                    <img className="img-description" src={clock} alt="clock" />
                    <Box component={"p"} m={0} fontSize={"16px"} color="#808080">{moment(allDetails.attributes.start_time, 'HH:mm A').format("HH:mm a")} - {moment(allDetails.attributes.end_time, 'HH:mm A').format("HH:mm a")}<br />
                        ({allDetails.attributes.timezone} Time Zone)</Box>
                </Box>
                <Box  display={"flex"} alignItems={"start"}>
                    <img className="img-description" src={Edit} alt="edit" />
                    <Box component={"p"} m={0} fontSize={"16px"} color="#808080">{allDetails.attributes.description}</Box>
                </Box>
            </Box>
        )
    };

    // Customizable Area End

    // Customizable Area Start
    render() {
        return (
            <Wrapper>
                {this.state.editEvent && <CreateEventStepOneModal eventId={this.state.ID} getItem={this.handleCloseEventModal} navigation={this.props.navigation} id={''} openModal={this.state.editEvent} handleClose={this.handleCloseEventModal} info={{
                    name: "",
                    is_private: false,
                    type: "",
                    image: ""
                }} />}
                <Box p={"24px"} borderRadius={"12px"} bgcolor={"#fff"}>
                    {this.state.loading && <Loader loading={this.state.loading} />}
                    <Box borderRadius={"12px"} sx={{ height: { xs: "200px", md: "299px" } }}>
                        <img src={this.state.allDetails.attributes.cover_image} className="mainImg" alt="img" width={"100%"} height={"100%"} />
                    </Box>
                    <Box display={"flex"} justifyContent={"space-between"} mt={"20px"}>
                        <Box component={"p"} fontSize={"20px"} fontFamily={"Verdana"} fontWeight={"bold"} m={0} color="#005487" >{this.state.allDetails.attributes.event_name}</Box>
                        <Box component={"p"} fontSize={"20px"} textAlign={"center"} fontFamily={"Verdana"} fontWeight={"bold"} m={0} color="#005487">{moment(new Date(this.state.allDetails.attributes.start_date)).format("DD")}<br />{moment(new Date(this.state.allDetails.attributes.start_date)).format("MMMM").toUpperCase().slice(0, 3)}</Box>
                    </Box>
                    {this.modalButtonRender()}
                    {this.scheduleEventRender()}
                </Box>
            </Wrapper>
        )
    }
    // Customizable Area End
}

// Customizable Area Start
const webStyle = {

    boxmg: {
        borderRadius: "20px"
    },

    interestedButton: {
        display: "block",
        margin: "5px"
    },

    linkmain_Container: {
        display: "flex"
    },

    div_Color: {
        color: "#01548a"
    },

    gridstyle: {
        display: "flex",
        margin: "10px 0px 10px 0px"
    },

    modalBox: {
        display: "block",
        margin: "5px"
    },
    iconColor: {
        backgroundColor: "#d9d9d9"
    },
    sendImgContainer: {
        width: "28px",
        height: "28px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        padding: "5px",
        background: "#cccbcb",
        marginLeft: "5px"
    },

    imageStyle: {
        width: "23px",
        height: "23px",
        background: "#cccbcb",

    },

    svg_icons: {
        background: "#cccbcb",
        borderRadius: "20px",
        width: "35px",
        height: "35px",
    },

    svgicons_Color: {
        background: "#cccbcb",
        borderRadius: "20px",
        width: "35px",
        height: "35px",
        color: '#0075A9'
    },

    modalButton: {
        boxShadow: "2px 4px 16px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "12px"
    },

    typotext: {
        color: "#828282",
        marginLeft: "10px"
    },
    typotextCopy: {
        color: "#828282",
        marginLeft: "10px",
        fontSize: "0.875rem"
    },

    Icons: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    } as unknown as React.CSSProperties,

};
const Wrapper =styled('section')({
    fontFamily: "Verdana",
    "& .mainImg":{
        borderRadius: "12px"
    },
    "& .img-description":{
        marginRight: "18px"
    },
    "& .linkColor":{
        color: "#005487",
        wordWrap: "break-word",
    }
});

// Customizable Area End
