import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import {
  setStorageData,
} from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import React from "react";
import { handleErrorArray } from "../../../components/src/common";

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  email: string;
  otpTimer: number;
  isDisabled:boolean;
  loading: boolean;
  isOtpSent: boolean;
  focus: number;
  otpFormFields: string[];
  errorMessage: string;
  fullPhoneNumber: string;
  type: string;
  otpToken: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ForgotPasswordPhoneVerificationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createAccountApiPhoneId: string = '';
  apiVerifyotpCallId: string = '';
  createAccountApiEmailId: string = '';
  textInputRefs: React.RefObject<HTMLInputElement>[];
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      email: "",
      loading: false,
      isOtpSent: false,
      focus: 0,
      otpFormFields: ['','','',''],
      errorMessage: '',
      fullPhoneNumber: '',
      type: 'email',
      otpToken: '',
      otpTimer: 60,
      isDisabled:false,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.textInputRefs = Array.from({ length: 4 }).map(() => React.createRef());
    // Customizable Area End
  }

  // Customizable Area Start
  handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phoneRegx = /^[0-9\b]+$/;
    this.setState({errorMessage: ""});
    if (e.target.value === "" || phoneRegx.test(e.target.value)) {
        this.setState({ fullPhoneNumber: e.target.value });
    }
  };


handleOTPInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const nextIndexx = index + 1;
    const prevIndex = index - 1;
    const newValue = e.target.value.slice(-1);

    this.setState((prevState) => {
      const updatedFields = [...prevState.otpFormFields];
      updatedFields[index] = newValue;
      return { otpFormFields: updatedFields };
    }, () => {
      if (newValue && nextIndexx < 4) {
        // Move focus to the next field if a digit is entered
        this.textInputRefs[nextIndexx].current?.focus();
      } else if (!newValue && prevIndex >= 0) {
        // Move focus to the previous field if a digit is erased
        this.textInputRefs[prevIndex].current?.focus();
      }
    });
  };
  
  
  handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement |HTMLDivElement>, index: number) => {
    if (e.key === "Backspace") {
      const { value } = e.target as HTMLInputElement;
      const prevIndexx = index - 1;
  
      if (value === "" && prevIndexx >= 0) {
        // Clear the previous field and move focus to it
        this.setState((prevState) => {
          const updatedFields = [...prevState.otpFormFields];
          updatedFields[prevIndexx] = "";
          return { otpFormFields: updatedFields };
        }, () => {
          this.textInputRefs[prevIndexx].current?.focus();
        });
      }
    }
  };
  validatePhoneNumber = () => {
    let isValid = true;
    if(this.state.fullPhoneNumber.trim().length === 0){
        this.setState({
            errorMessage: "Please Enter Phone number"
        });
        isValid = false;
    }
    return isValid;
}

validateOtp = () => {
    let isOtpValid = true;
    this.state.otpFormFields.forEach((otp: string) => {
        if(otp.trim().length === 0){
            isOtpValid = false;
        }
    });
   
    return isOtpValid;
}
 
handleLogin = () => {
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), "LogIn");

  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  
  this.send(message);
}
changeType= () => {
  this.setState({type: this.state.type === 'email' ? 'phone' : 'email', errorMessage: '' , fullPhoneNumber: '', email: '',isOtpSent:false})
}
 
  
  handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.target.value, errorMessage: '' });
  };

  async receive(_from: string, message: Message) {
  
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.createAccountApiPhoneId) {
     this.responsePhoneVerify(responseJson)
    }

    if (apiRequestCallId === this.createAccountApiEmailId) {
      this.responseEmailLink(responseJson)
    }

    if (apiRequestCallId === this.apiVerifyotpCallId) {
      this.responseOtpVerify(responseJson)
    }

  }

  responseOtpVerify = (responseJson:{messages: {[keys:string]:string}[],errors: {[keys:string]:string}[]}) => {
    this.setState({loading: false,fullPhoneNumber: ''})
    if(responseJson.errors){
      handleErrorArray(responseJson.errors)
    }
    if(responseJson.messages){
      const msg: Message = new Message(
        getName(MessageEnum.NavigationResetPasswordMessage)
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
      toast.success("OTP verified successfully!")
    }
  }

  responsePhoneVerify = async(responseJson: {meta:{token:string},errors: {[keys:string]:string}[]}) => {
    this.setState({loading: false})
    if(responseJson.meta){
      localStorage.clear();
      this.setState({isOtpSent:true,otpToken: responseJson.meta.token})
      await setStorageData('forgetToken',responseJson.meta.token);
    }
    if(responseJson.errors){
      handleErrorArray(responseJson.errors)
    }
  }
  responseEmailLink = (responseJson: {message:string,errors: {[keys:string]:string}[]}) => {
    this.setState({loading:false,email:''})
    if(responseJson.message){
      toast.success(responseJson.message)
      localStorage.clear();
    }
    if(responseJson.errors){
      handleErrorArray(responseJson.errors)
    }
  }
  checkValidation = () => {
    const { email } = this.state;

    let hasError = false;
    if (!email || !validEmailRegex.test(this.state.email)) {
      this.setState({
        errorMessage: configJSON.errorEmailNotValid,
      });
      hasError = true
    }
    return hasError;
  }
  handleEmailSubmit = (e:  React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const hasError = this.checkValidation();
    if(hasError) return

      const formData = new FormData();

      formData.append("email", this.state.email);

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createAccountApiEmailId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.emailRestPass
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        {}
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    
  };


  startTimer = () => {
    // Disable the button to prevent multiple clicks
    this.setState({ isDisabled: true });

    // Set the timer for 60 seconds
  let timerInterval = setInterval(() => {
      this.setState((prevState) => ({
       otpTimer: prevState.otpTimer - 1,
      }));

      // Check if the timer has reached 0
      if (this.state.otpTimer < 0) {
        // Enable the button and reset the timer
        this.setState({
          otpTimer: 60,
          isDisabled: false,
        });

        // Clear the interval to stop the timer
        clearInterval(timerInterval);
      }
    }, 1000);
  };

  resendOtp = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.startTimer()
    this.handlePhoneNumberSubmit()
  }

  handlePhoneNumberSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    this.setState({
      otpFormFields: ["", "", "", ""]
    })
    if (!this.validatePhoneNumber()) {
      return false;
    }
    this.setState({ loading: true })
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
      };

      const attrs = {
        full_phone_number: this.state.fullPhoneNumber,
      };

      const data = {
        attributes: attrs,
      };

      const httpBody = {
        data: data,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createAccountApiPhoneId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.ForgotPasswordPhoneVerficationEndPointWeb
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleOtpSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(!this.validateOtp()){
      return false;
    }
    this.setState({loading:true});
    const header = {
        "Content-Type": "application/json",
        token: this.state.otpToken,
      };
      const data = {
        data: {
          token: this.state.otpToken,
          otp_code: this.state.otpFormFields.join(""),
        },
      };

      const httpBody = data;
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiVerifyotpCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.ForgotPasswordphoneOTPEndpointWeb
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
