// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  InputAdornment,
  Grid,
  FormControl,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import NewLoginController, { Props } from "./NewLoginController.web";
import { SideImage, Logo, Mail, Lock, Eye, Hideeye } from "./assets";
import { styled } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web"; 
// Customizable Area End


export class LoginNew extends NewLoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
  render() {
    const { email, password, emailError, passwordError } = this.state;
    return (
      <>
      {this.state.isLoading && <Loader loading={this.state.isLoading}/>}
        <Wrapper container>
          <Grid item xs={false} md={5} component={Box} sx={{ height: "100vh", display: { xs: 'none', md: 'block' } }}>
            <img src={SideImage} alt="side-panel" width="100%" height="100%" />
          </Grid>

          <Grid item xs={12} md={7} className="scrollWrapper">
            <Box component={'section'} className="rightSection" sx={{ width: "85%", maxWidth: "500px",paddingY: '20px' }}>
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <img src={Logo} alt="logo-img" className="LoginLogoImg" />
                <Box component={'h1'} fontFamily={"Veradana"} fontSize={"50px"} color={"#005487"} fontWeight={'bold'} m={0} mt={{ xs: "30px", md: '40px' }}>Login</Box>
              </Box>
              <form className="form" onSubmit={this.handleSubmit}  data-test-id="publish-btn">
              <Box display={"flex"} flexDirection={'column'} alignItems={'center'} width={'100%'} mt={{ xs: '60px', md: '80px' }} mb={'16px'} gridGap={'24px'}>
                <FormControl fullWidth>
                  <TextField
                    data-test-id="txtInputEmail"
                    variant="outlined"
                    size="medium"
                    placeholder="Email ID"
                    className="inputfield"
                    name="email"
                    type="email"
                    error={Boolean(emailError)}
                    value={email}
                    autoFocus={true}
                    onChange={this.handleChange}
                    fullWidth
                    helperText={emailError}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" >
                          <img src={Mail} alt="email" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    data-test-id="txtInputPassword"
                    variant="outlined"
                    size="medium"
                    placeholder="Password"
                    name="password"
                    type={this.state.showPass ? "text" : "password"}
                    error={Boolean(passwordError)}
                    value={password}
                    onChange={this.handleChange}
                    fullWidth
                    helperText={passwordError}
                    className="inputfield"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" >
                          <img src={Lock} alt="Lock" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start" >
                          <img className="pointer" data-test-id="eye-icon" src={this.state.showPass ? Eye : Hideeye} alt="eye" onClick={this.eyeIconChange} />
                        </InputAdornment>
                      )
                    }}
                  />
                </FormControl>
              </Box>
              <Box display={'flex'} justifyContent={"space-between"} alignItems={'center'} className="checkbox" width={'100%'}>
                <FormControlLabel
                  control={
                    <Checkbox
                      data-test-id="btnCheck"
                      color="primary"
                      checked={this.state.checkedRememberMe}
                      onChange={this.handleChangeCheck}
                      name="agree"
                    />
                  }
                  label="Remember Me"
                />
                <Box fontFamily={"Verdana"} color="#808080" fontSize={{ xs: "14px", md: "16px" }} className={"pointer"} fontWeight={'bold'} data-test-id="forget-id" m={0} onClick={this.handleForgotPassNavigation}>Forgot Password?</Box>
              </Box>
              <Box display="flex" width={'100%'} mt={{ xs: "35px", md: '44px' }} mb={{ xs: '25px', md: '31px' }}>
                <Button variant="contained" type="submit" className={"publishbtnExpand"}>Login</Button>
              </Box>
              </form>
              <Box component={'p'} fontFamily={"Veradana"} fontSize={"17px"} color={"#808080"} fontWeight={'bold'} m={0}>Don&#8217;t have an account? <span className="redirect" data-test-id="redirect-id" onClick={this.handleSignUp}>SignUp.</span></Box>
            </Box>
          </Grid>
        </Wrapper>
      </>
    );
  }
    // Customizable Area End
}

// Customizable Area Start
const Wrapper = styled(Grid)({
  background: "#fff",
  "& .scrollWrapper": {
    display:'grid',
    placeItems: 'center',
    height: '100vh',
    overflowY: 'auto'
  },
  "& .rightSection": {
    display: 'flex',
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  "& .pointer": {
    cursor: 'pointer',
  },
  "& .LoginLogoImg": {
    width: "250px",
    height: "250px",
    borderRadius: '50%',
    boxShadow: "0 4px 8px 0 rgba(32, 32, 32, 0.2)",
  },
  "& .checkbox": {
    "& .MuiFormControlLabel-label": {
      fontsize: '15px',
      color: '#808080'
    }
  },
  "& .inputfield": {
    "& .MuiOutlinedInput-input": {
      paddingBlock: "20px",
    }
  },
  "& .publishbtnExpand": {
    height: "56px",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#fff",
    width: "100%",
    fontFamily: "Verdana",
    backgroundColor: "#005487",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#005487"
    }
  },
  "& .redirect": {
    textDecoration: 'underline',
    textUnderlineOffset: '6px',
    color: '#005487',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  "& .form":{
    width: '100%',
    margin: 0,
  }
});
export default LoginNew
// Customizable Area End