// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export interface ResponseSavePost {
    data: ResponseSavePost[];
    id: string,
    type: string,
    attributes: {
        post_type: string;
        video_thumbnail: string;
        id: number,
        event_type: string, 
        description: string,
        cover_image: string,  
        thumbnail: string,
        images_and_videos:string[],
    }
}

// Customizable Area End
import ApiCall from "../../../components/src/ApiCall.web";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
 // Customizable Area Start
  loading:boolean;
  getSavePost:Array<ResponseSavePost>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SavePostController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  savePostAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
        loading:false,
        getSavePost:[],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.savePostgetApiRequest()  
  }

  navigatePosts = (postId:string) => {
    const msg1 = new Message(getName(MessageEnum.NavigationPostMessage));
        msg1.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        )
        msg1.addData(
          getName(MessageEnum.UrlPathParamMessage),
          postId
        )
        this.send(msg1)
  }

  navigateEvent = (items:string) => {
    this.props.navigation.navigate('CalendarEventDetails',{id:items})
  }

  goBackButton =() => {
    this.props.navigation.goBack()
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(apiRequestCallId === this.savePostAPICallId){
      if (responseJson && !responseJson.errors) {
        this.responseSucessCell(responseJson)
      } else if (responseJson && responseJson.errors) {
        this.responseFailureCall()
      }
    }
    }
  }

  responseSucessCell =  ( responseJson: ResponseSavePost) => {
      this.savePostSucessCallBack(responseJson);
  }

  responseFailureCall =  () => {
    this.setState({loading:false})
  }

  savePostgetApiRequest = async () => {
    this.setState({ loading: true })
    this.savePostAPICallId = await ApiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.apiMethod,
      endPoint: configJSON.savePostEndPoint,
    });
  };

  savePostSucessCallBack = (response:ResponseSavePost) => {
    this.setState({ getSavePost:response.data, loading:false })
  }; 

  destuctureImageVideo = (item : string[]) =>{
    if(item.length>0) return item[0]
    return ""
  }
  // Customizable Area End

}
