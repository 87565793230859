import React from "react";

// Customizable Area Start
import ChatNew from "./ChatNew.web";
import ChatController, { Props } from "./ChatController";
// Customizable Area End


export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
      {this.state.token?<ChatNew navigation={this.props.navigation} id={""}/>:null}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
