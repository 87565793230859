import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";
import { isLocalAdmin } from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  open: boolean;
  handleClose: ()=> void;
  resetData: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  community: boolean;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class SwitchAccountController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createUserId: any;
  getTokenApi: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      community: false,
      loading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId1 = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId1 === this.getTokenApi) {
        if (responseJson) {
          this.setState({loading: false})
          if (responseJson.errors && responseJson.errors[0].message) {
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), "LinkedAccounts");

            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            message.addData(
              getName(MessageEnum.NavigationScreenNameMessage),
              "creation"
            );
            this.send(message);
            this.props.handleClose()
          }
          if (responseJson.token) {
            this.communitySwitchResponse(responseJson) 
          }
        }
      }
    }
    // Customizable Area End
  }
  // web events
  // Customizable Area Start

  async componentDidMount() {
    const isLA = await isLocalAdmin();
    this.setState({ community: isLA })
  }

  communitySwitchResponse = (responseJson:{token: string,token_type: string}) => {
    const isCommunity = responseJson.token_type === "account" ? "false" : "true";
    localStorage.setItem("LoginSuccessToken", responseJson.token)
    localStorage.setItem("community", isCommunity);
    this.props.resetData();
    this.props.handleClose();
    if(isCommunity === "true"){
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "LinkedAccounts");

      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }else{
      this.props.navigation.navigate("OtherUserProfile")
    }
  }

  rendertitle = () => {
    if (this.state.community) {
      return "Switch to Personal Account"
    } else {
      return "Switch to Community Account"
    }
  }
  handleSwitchToken=async()=>{
    this.setState({loading:true})
    const signupTokn = await getStorageData('SignupSuccessToken');
    const loginTokn = await getStorageData('LoginSuccessToken');
    this.getTokenApi = sendAPIRequest("bx_block_chat9/switch_accounts", {
      method: "GET",
      headers: {
        token: signupTokn || loginTokn,
      },
    });
  };
  // Customizable Area End
}
