import { toast } from "react-toastify";
import { getStorageData } from "../../framework/src/Utilities";

export const handleOpenSocialMediaLink = (url : string) => {
    if(!["X","-"].includes(url)){
        window.open(url , "_blank")    
    }
    
}

export const handleErrorArray = (errors: any) => {
    errors.forEach((error: any) => {
        toast.error(Object.values(error).join(","))
    })
}

export const getSignupLoginTokens = async () => {
    let signupToken = await getStorageData('SignupSuccessToken');
    let loginToken = await getStorageData('LoginSuccessToken');
    let profileData = await getStorageData("profileData", true);

    return { signupToken, loginToken, profileData };
}
export const isLocalAdmin = async () =>{
    return await getStorageData('community') === 'true' ? true : false
}

export const hideNeccessaryData = (response: any)=> {
    if(!response.is_in_connection && response.is_private){
        response.email = "X";
        response.pronouns = "X";
        response.full_phone_number = "X";
        response.dob = "X";
        response.race = "X";
        response.language = ["X"];
        response.ethnicity = "X";
        response.timezone = "X";
        response.gender = "X";
        response.social_media_urls = [{url:"X"}];
        response.organization.website = "X";
    };
    return response;
}

export const handleInvalidData = (data: any,defaultData: any) => {
    return data ? data : defaultData
}

export const isFromViewProfile = (navigation: any) => {
    let isViewProfile = navigation.getParam("pastNavigation") === "view-profile"
    return isViewProfile
}