// Customizable Area Start
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import moment from 'moment';
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { handleErrorArray } from "../../../components/src/common";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
interface EventData {
    event_name: string;
    event_type: string;
    event_url: string;
    description: string;
    timezone: string;
    start_date: string;
    end_date: string;
    start_time: string;
    end_time: string;
    city: string;
    state: string;
    street: string;
    zipcode: number;
    cover_image: string;
}
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes: any;
    openModal: boolean;
    handleClose: () => void;
    getItem: (id:string, type: string) => void;
    eventId: string;
    info: {
        name: string;
        is_private: boolean;
        type: string;
        image: string;
    }
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    error: {
        [keys: string]: string;
    };
    loading: boolean;
    eventId: string;
    selectedFile: File |null;
    data : EventData,
    timezoneEndTimeList : {id: number, value: string, label: string}[];
    expandDescription: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
export default class CreateEventStepOneController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiCreateEventPostCallId: string = '';
    apiGetSingleEventId: string = '';
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            // Customizable Area End
        ];
        this.state = {
            // Customizable Area Start
            loading: false,
            eventId: '',
            error: {
                cover_image : '',
                event_type: '',
                event_name: '',
                zipcode: '',
                state: '',
                description: '',
                city: '',
                event_url: '',
                start_date: '',
                end_date: '',
                timezone: '',
                start_time: '',
                end_time: '',
            },
            selectedFile: null,
            data: {
                cover_image: '',
                event_name: '',
                event_type: '',
                street: '',
                zipcode: NaN,
                state: '',
                description: '',
                city: '',
                event_url: '',
                start_date: '',
                end_date: '',
                start_time: '',
                end_time: '',
                timezone: '',
            },
            timezoneEndTimeList: [],
            expandDescription: false,
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }


    // Customizable Area Start
    timezoneList = [
        {
            id: "1",
            label: "Eastern",
            value: "Eastern",
        },
        {
            id: "2",
            label: "Central",
            value: "Central",
        },
        {
            id: "3",
            label: "Mountain",
            value: "Mountain",
        },
        {
            id: '4',
            label: "AZ",
            value: "AZ",
        },
        {
            id: "5",
            label: "Pacific",
            value: "Pacific",
        }
    ];

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson) {
                this.setState({ loading: false });
                if (apiRequestCallId === this.apiCreateEventPostCallId) {
                    if (responseJson.data) {
                       this.handleCreateEditResponse(responseJson)
                    } else {
                        handleErrorArray(responseJson.errors)
                    }
                } if (apiRequestCallId === this.apiGetSingleEventId) {
                    this.handleResponseSinglePost(responseJson);
                }
            }

        }
    }

    handleCreateEditResponse = (responseJson: {
        data: {
            id: string;
            attributes:EventData
        }
    , meta: { message: string } }) => {
        toast.success(responseJson.meta.message)
        this.props.getItem(responseJson.data.id, 'event')
        this.props.handleClose()
        this.setState({
            data: {
                ...responseJson.data.attributes,
                start_date: moment(responseJson.data.attributes.start_date, "DD MMM YYYY").format("DD/MM/YYYY"),
                start_time: moment(responseJson.data.attributes.start_time, "HH:mm:ss").format("h:mm A"),
                end_date: moment(responseJson.data.attributes.end_date, "DD MMM YYYY").format("DD/MM/YYYY"),
                end_time: moment(responseJson.data.attributes.end_time, "HH:mm:ss").format("h:mm A"),
            }
        });
    }

    handleResponseSinglePost = (responseJson: {
        data: {
            attributes: EventData
        }
    }) => {
        if (responseJson.data) {
            this.setState({
                data: {
                    ...responseJson.data.attributes,
                    start_date: moment(responseJson.data.attributes.start_date, "DD MMM YYYY").format("DD/MM/YYYY"),
                    end_date: moment(responseJson.data.attributes.end_date, "DD MMM YYYY").format("DD/MM/YYYY"),
                    start_time: moment(responseJson.data.attributes.start_time, "HH:mm:ss").format("h:mm A"),
                    end_time: moment(responseJson.data.attributes.end_time, "HH:mm:ss").format("h:mm A"),
                }
            });
        }
    }
    async componentDidMount() {
        super.componentDidMount()
        this.generateTimeArray()
        if(this.props.eventId){
        this.getSingleEvent(this.props.eventId);
        this.setState({eventId: this.props.eventId})
        }
    }

    generateTimeArray = () => {
        const timezoneEndTimeList = [];

        for (let i = 0; i < 24; i++) {
            for (let j = 0; j < 60; j += 30) {
                let hour: string = (i % 12 || 12).toString().padStart(2, '0');
                let minute: string = j.toString().padStart(2, '0');
                const period: string = i < 12 ? 'AM' : 'PM';

                const timeString = `${hour}:${minute}`;

                timezoneEndTimeList.push({
                    id: i * 60 + j, // ID can be a unique combination of hours and minutes
                    value: `${timeString} ${period}`,
                    label: `${timeString} ${period}`,
                });
            }
        }

        this.setState({ timezoneEndTimeList });
    }

    handleOnImportFile = (list: FileList) => {
        if (list.length > 1) return this.setState({ error: { ...this.state.error, cover_image: 'Select only one image' } })
        let extension = list[0].name.split('.').pop() as string

        if (!["png", "jpg", "jpeg"].includes(extension)) return

        this.setState({
            selectedFile: list[0],
            data: { ...this.state.data, cover_image: URL.createObjectURL(list[0]) },
            error: { ...this.state.error, cover_image: '' }
        })
    }

    handleOpenUploadBtn = () => {
        document.getElementById("fileInput")?.click()
    }
    radioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ data: { ...this.state.data, event_type: e.target.value }, error: { ...this.state.error, event_type: '' } })
    }
    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ data: { ...this.state.data, [e.target.name]: e.target.value }, error: { ...this.state.error, [e.target.name]: '' } })
    }
    handleChangeDropDown = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({ data: { ...this.state.data, [e.target.name]: e.target.value }, error: { ...this.state.error, [e.target.name]: '' } })
    }
    handleStartDateChange = (e: Date) => {
        const tempDate: string = moment(e).format('DD/MM/YYYY');
        this.setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                start_date: tempDate
            },
            error: {
                ...prev.error,
                start_date: ''
            }
        }
        ))
    }

    handleEndDateChange = (e: Date) => {
        const tempDate: string = moment(e).format('DD/MM/YYYY');
        this.setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                end_date: tempDate
            },
            error: {
                ...prev.error,
                end_date: ''
            }
        }
        ))
    }

    handleExpandDescription = () => {
        !this.state.expandDescription && this.setState({ expandDescription: true })
    }
    checkRequired = () => {
        const data: EventData = this.state.data;
    
        let error: {[keys:string]:string} = this.initializeErrorObject();
        this.checkRequiredFields(error, data);
        this.checkVirtualEventFields(error, data);
        this.checkInPersonEventFields(error, data);
        this.setState({ error });
    
        return Object.values(error).some(value => value !== '');
    }
    
    initializeErrorObject = (): {[keys:string]:string} => {
        return {
            cover_image: '',
            event_name: '',
            event_type: '',
            zipcode: '',
            state: '',
            city: '',
            description: '',
            event_url: '',
            end_date: '',
            start_time: '',
            end_time: '',
            start_date: '',
            timezone: ''
        };
    }
    
    checkRequiredFields = (error: {[keys: string]: string}, data: EventData) => {
        const { event_name, event_type, description, timezone, start_date, end_date, start_time, end_time, cover_image } = data;
        if (!event_name) error.event_name = "Event name is required";
        if (!event_type) error.event_type = "Event type is required";
        if (!description) error.description = "Description is required";
        if (!timezone) error.timezone = "Timezone is required";
        if (!start_date) error.start_date = "Start date is required";
        if (!end_date) error.end_date = "End date is required";
        if (!start_time) error.start_time = "Start time is required";
        if (!end_time) error.end_time = "End time is required";
        if (!cover_image) error.cover_image = "Thumbnail image is required";
    }
    
    checkVirtualEventFields = (error: {[keys:string]:string}, data: EventData) => {
        const { event_type, event_url } = data;
        if (event_type === 'virtual' && !event_url) error.event_url = "Event URL is required";
    }
    
    checkInPersonEventFields = (error: {[keys:string]:string}, data: EventData) => {
        const { event_type, city, state, zipcode } = data;
        if (event_type === 'in-person') {
            if (!city) error.city = "City is required";
            if (!state) error.state = "State is required";
            if (!zipcode || (String(zipcode).length !== 5)) error.zipcode = "Please enter valid 5 digit zipcode";
        }
    }
    

    handlePublish = async () => {
        if (this.state.expandDescription) return this.setState({ expandDescription: false })
        const hasError = this.checkRequired();
        if (hasError) return;
        this.setState({ loading: true });
        let signupToken = await getStorageData("SignupSuccessToken");
        let loginToken = await getStorageData("LoginSuccessToken");
        const header = {
            token: signupToken || loginToken,
        };
        const formData = new FormData();

        formData.append("event[event_type]", this.state.data.event_type);
        formData.append("event[event_name]", this.state.data.event_name);
        formData.append("event[event_url]", this.state.data.event_url);
        formData.append("event[timezone]", this.state.data.timezone);
        formData.append("event[description]", this.state.data.description);
        formData.append("event[start_date]", this.state.data.start_date);
        formData.append("event[end_date]", this.state.data.end_date);
        formData.append("event[start_time]", this.state.data.start_time);
        formData.append("event[end_time]", this.state.data.end_time);
        formData.append("event[street]", this.state.data.street);
        formData.append("event[city]", this.state.data.city);
        formData.append("event[state]", this.state.data.state);
        formData.append("event[zipcode]", String(this.state.data.zipcode));
        this.state.selectedFile && formData.append("event[cover_image]", this.state.selectedFile);

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiCreateEventPostCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.state.eventId ? configJSON.eventPath + this.state.eventId : configJSON.eventPath
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            this.state.eventId ? configJSON.putApiMethode : configJSON.PostAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSingleEvent = async (id: string) => {
        this.setState({ loading: true })
        let loginToken = await getStorageData("LoginSuccessToken");
        let signupToken = await getStorageData("SignupSuccessToken");
        const header = {
            token: signupToken || loginToken,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetSingleEventId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.eventPath + id
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.get
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}