import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  Button,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import ReportController, { Props, configJSON } from "./ReportController.web";
import Loader from "../../../components/src/Loader.web";
import { message, dropdown } from "./assets";
// Customizable Area End

export default class Settings extends ReportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (<>
      {this.state.loading && <Loader loading={this.state.loading} />}
      <Wrapper sx={{}} bgcolor="#fff" borderRadius="12px">
        <Box display="flex" justifyContent="space-between" alignItems="center" p="24px" borderBottom="1px solid #808080">
          <Box color="#005487" fontSize="20px" fontFamily="Verdana, Geneva, Tahoma, sans-serif" display="flex">
            <ArrowBackIosIcon className="icon-color" data-testid="back-btn" onClick={this.handleBack} />{configJSON.optionReport}
          </Box>
        </Box>
        <Box p="24px">
          <Typography variant="h3" className="report-title">{configJSON.reportTitle}</Typography>
          <Typography variant="body1" className="report-description">{configJSON.reportDescription}</Typography>
        </Box>
        <Box p="24px" sx={{ height: { xs: "350px", md: "400px" } }}>
          <Typography variant="subtitle1" className="dropdown-title">{configJSON.reportDropdown}</Typography>
          <CustomSelectDropdown
            placeHolderText="Select"
            fieldId="report-select"
            fieldValue={this.state.fieldValue}
            fieldName="report"
            handleChange={this.handleChangeDropdownValue}
            MenuProps={MenuProps}
            renderValue={this.renderValue}
            fieldError={false}
            fieldMenuItems={this.state.menuItems}
            iconComponent={
              <img src={dropdown} alt="message" />
            }
            startAdornmentIcon={
              <img src={message} alt="message" />
            }
          />
          <Typography variant="subtitle1" className="error">{this.state.error}</Typography>
        </Box>
        <Box width="100%" display="flex" justifyContent="center" p="24px" borderTop="1px solid #808080" boxSizing="border-box">
          <Box sx={{ width: { xs: "80%", md: "65%" }, margin: "auto" }}>
            <Button
              className={"Publishbtn"}
              data-test-id="submit-btn"
              type="submit"
              onClick={this.handleSubmit}
            >Submit</Button>
          </Box>
        </Box>
      </Wrapper>
    </>
    );
    // Customizable Area End

  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  "& .dropdown-title": {
    fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#005487",
  },
  "& .report-title": {
    fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
    fontSize: "17px",
    fontWeight: "bold",
    color: "#000",
  },
  "& .report-description": {
    fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#808080",
    paddingTop: "8px",
  },
  "& .icon-color": {
    color: "#808080",
    cursor: "pointer"
  },
  "& .error": {
    color: "red",
    fontsize: "12px"
  },
  "& .Publishbtn": {
    color: "#fff",
    height: "50px",
    width: "100%",
    border: "solid 1px #005487",
    backgroundColor: "#01548a",
  }
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "320px",
      borderRadius: "12px",
    },
  },
};

// Customizable Area End
