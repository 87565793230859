import React from "react";

// Customizable Area Start
import AchievementListingController, { Props } from "./AchievementListingController.web";
import AchievementSection from "./AchievementSection.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

// Customizable Area Start
        
export default class AchievementListing extends AchievementListingController {

    constructor(props: Props) {
        super(props);
    }

    render() {
        const { isLoading } = this.state
       return (
           <>
               {isLoading && <Loader data-test-id="loader" loading={isLoading} />}
               <AchievementSection
                   data-test-id="list-achivenment"
                   removeAchievement={this.handleRemoveAchievement}
                   isMainListing={true} isOwnProfile={true}
                   navigation={this.props.navigation}
                   data={this.state.achievementList} />
           </>
    )}

}
// Customizable Area End