Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
const urlConfig = require("../../../framework/src/config");
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.PostAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.PostApiMethodType = "GET";
exports.patchPostAPiMethod = "PATCH";
exports.postGetUrl = "posts/posts";
exports.postContentType = "application/json";
exports.deletePostAPiMethod = "DELETE";
exports.CategoryGetUrl = "categories/categories";
exports.btnExampleTitle = "CLICK ME";
exports.getAllCatergoryEndPoint = "categories/categories";
exports.postImage = "posts/posts";
exports.eventPath="bx_block_events/events/"
exports.postUrl = "posts/videos";
exports.postAchievements='bx_block_achievements/achievements';
exports.baseURL = urlConfig.baseURL;

exports.notificationPost="turn_on_notification_setting";
exports.savePost="save_post";
exports.unSavepostEnd="removed_from_save_post";
exports.hidePost="hide_post";
exports.like_unlike_post="like_unlike_post";
exports.postsEnd="posts/posts/";
exports.editPost = "posts/";
exports.sharePost="share_post";
exports.createFollower="create_followers";
exports.removeFollower="remove_follower";
exports.createCommentEnd="create_comment";
exports.get="GET";
exports.putApiMethode="PUT";
exports.deleteMethod="DELETE";
exports.optionDelete="Delete post";
exports.optionNotfication="notification for this post";
exports.optionSave="Save Post";
exports.optionAlreadySave="Unsave";
exports.optionHidePost="I don't want see this post";
exports.optionReport="Report Post";
exports.optionUnfollowUser="Unfollow user";
exports.optionUnfollowPage="Unfollow page";
exports.optionCopy="Copy link";
exports.optionShare="Share post in Website";
exports.optionEdit="Edit Post";
exports.btnFollow="Follow";
exports.postCommunity="communities";
exports.postLikeUserListEnd="user_list_for_liked_post?post_id=";
exports.like_unlike_comment="like_unlike_comment";
exports.create_a_post="Create a post";
// Customizable Area End