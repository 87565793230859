Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisableUserProfiles";
exports.labelBodyText = "CustomisableUserProfiles Body";

exports.btnExampleTitle = "CLICK ME";
exports.getApiMethod = "GET";
exports.putApiMethod = "PUT";
exports.postApiMethod = "POST";
exports.deleteApiMethod = "DELETE"
exports.get_user = "get_user?is_post_invisible=true";
exports.social_media_urls="social_media_urls";
exports.update_user="update_user";
exports.get_organization="bx_block_organizations/organizations";
exports.ethnicity_list="ethnicity_list";
exports.gender_list="gender_list";
exports.language_list="language_list";
exports.get_countries="get_countries";
exports.race_list="race_list";
exports.get_states="get_states";
exports.pronounce_list="pronounce_list";
exports.organization_request = "Thanks for personal information updates please proceed with organization information also.*";
exports.communities="communities?is_post_invisible=true";
exports.remove_follower="remove_follower";
exports.create_followers="create_followers";
exports.community="Community";
// Customizable Area End