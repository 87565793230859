import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { sendAPIRequest } from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  pending_quizes: {quiz_id:number,title:string}[];
  completed_quizes: {quiz_id:number,title:string}[];
  loading:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class QuestionBankController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllQuizeApi: string ="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      pending_quizes: [],
      completed_quizes: [],
      loading:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      )
      let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      
      if (apiRequestCallId && responseJson) {
        if(this.getAllQuizeApi===apiRequestCallId) return this.handleGetTestApiResponse(responseJson)
      }
  }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount()
    const signupToke = await getStorageData('SignupSuccessToken');
    const loginToke = await getStorageData('LoginSuccessToken');
    this.getAllQuizeApi = sendAPIRequest(configJSON.getAllTestEnd, {
      method: configJSON.GetApi,
      headers: {
        "Content-Type": configJSON.apiContentType,
        token: signupToke || loginToke
      }
    });
    this.setState({ loading: true });
  }

  handleGetTestApiResponse=(responseJson: {data:{completed_quizes:[{quiz_id:number,title:string}],pending_quizes:[{quiz_id:number,title:string}]}})=>{
    this.setState({ loading: false})
    if(responseJson.data){
      this.setState({pending_quizes:[...responseJson.data.pending_quizes],completed_quizes:[...responseJson.data.completed_quizes]});
    }
  }

  handleOpenTest=(id:number)=>{
    const pending = this.state.pending_quizes.find(item=>item.quiz_id===id)
    if(pending){
      const msg = new Message(getName(MessageEnum.NavigationQuestionBankCreateMessage));
      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      msg.addData(
        getName(MessageEnum.UrlPathParamMessage),
        id
      );
      this.send(msg);
    }else {
      const msg1 = new Message(getName(MessageEnum.NavigationAssessmentTestMessage));
      msg1.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      msg1.addData(
        getName(MessageEnum.UrlPathParamMessage),
        id
      );
      this.send(msg1)
    }
  }
  // Customizable Area End
}
