export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const CloseIcon = require("../assets/closeSquare.svg");
export const Edit = require("../assets/edit.svg");
export const Add = require("../assets/add.svg");
export const Dropdown = require("../assets/arrow-square-down.svg");
export const greyEdit = require("../assets/greyEdit.svg");
export const Camera = require("../assets/camera.svg");
export const NoUser = require("../assets/user.svg");
export const smallUser = require("../assets/smallUser.svg");
export const Website = require("../assets/global.svg");
export const Email = require("../assets/email.svg");
export const Phone = require("../assets/phone.svg");