import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { Typography, Box, Grid, Card } from "@material-ui/core";
// Customizable Area Start
import  Divider from "@material-ui/core/Divider";
import FriendsListController, { configJSON, Props } from "./FriendsListController.web";
import CommonListing from "./CommonListing.web";
import Loader from "../../../components/src/Loader.web";
import { AddUserIcon } from "./assets";
import  SearchListModal  from "./SearchListModal.web";
import styled from "@material-ui/core/styles/styled";
// Customizable Area End

// Customizable Area Start
export default class FriendsList extends FriendsListController {
    constructor(props: Props) {
        super(props)
    }

    render() {

        return (
            <Wrapper component={"section"} className={"outerMAin"} >
                <div ref={this.friendListRef} id="Friend_List">
                <Loader loading={this.state.isLoading} />
                <Card className={"root"}>
                    <Box padding={"25px"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Box component={"p"} m={0} color={"#005487"} sx={{fontFamily:"Verdana", fontSize:"20px", fontWeight:"bold"}}>{configJSON.friend_list}</Box>
                        {this.isOwnUser && <img onClick={this.handleRequestManagementNavigation} src={AddUserIcon} className={"pointer"}/>}
                    </Box>
                    
                    {this.state.showSearchModal && <SearchListModal
                        friendListRef={this.friendListRef}
                        showSearchModal={this.state.showSearchModal}
                        handleClose={this.closeSearchModal}
                        userId={this.userId} 
                        type={"FRIEND"}
                        isCommunity={false}
                        isOwnProfile={this.isOwnUser}
                        navigation={this.props.navigation}
                      />}
                    <Divider />
                    <CommonListing setShowSearch={this.showSearchModal} listType="FRIENDS" navigation={this.props.navigation} list={this.state.friendsList} handleRemoveFriend={this.handleRemoveFriend} />
                </Card>
                </div>
            </Wrapper>
        );
    }
}

const Wrapper = styled(Box)({
    position: "relative",
    "& .root": {
        maxWidth: "100%",
        borderRadius: "12px",
    },
    "&.pointer":{
        cursor: "pointer"
    },
    "& .outerMAin": {
        overflow: "unset"
    }
})
// Customizable Area End