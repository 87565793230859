Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.endPoint = "notifications/notifications";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";

exports.notifications = "Notifications";
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.deleteMessage = "Notifications deleted!"
exports.notification = "/get_all_notifications";
exports.deleteEndPoint = "/bx_block_notifications/notifications/";
exports.notificationText = "Notifications";
exports.assessmentTitle = "New Assessment Test";
exports.assessmentDetails = "Your organization has created a new assessment test.";
exports.assessmentReminder = "Assessment test reminder";
exports.assessmentTasx = "You haven't completed any assessment test in last";
exports.eventReminder = "Event Reminder";
exports.chatTitle = "Chat notification";
exports.Ignore = "Ignore";
exports.takeTest = "Take Test";
exports.snooze = "Snooze";
exports.retake = "Retake";
exports.check = "Check";
exports.joinHear = "You can join the event from";
exports.scoreyouLike = "Would you like to:"
exports.completedAssessment = "Participants who have completed their assessment";
exports.pendingAssessment = "Participants who have pending assessment";
exports.percentageAssessment = "of your users have completed your assessment test.";
exports.deadline = "Deadline";
exports.get_notification="get_notifications";
exports.readEndPoint="bx_block_notifications/notifications/";
exports.remove="Remove";
// Customizable Area End