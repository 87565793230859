import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Divider
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { RightArrow, CheckList } from "./assets";
import { styled } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import QuestionBankController, {
  Props,
  configJSON,
} from "./QuestionBankController";

export default class QuestionBank extends QuestionBankController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSinglTest = (test:{ quiz_id: number, title: string, }) => {
    return (
      <SingleTest display={"flex"} alignItems={"center"} justifyContent="space-between" key={test.quiz_id} onClick={()=>this.handleOpenTest(test.quiz_id)} data-test-id="name-id">
        <Box display={"flex"} alignItems="center">
          <img src={CheckList} alt="CheckList" />
          <Box component={"p"} m={0} fontWeight={"bold"} fontSize={"20px"} sx={{marginLeft:{xs:"20px",md:"25px"}}}>{test.title}</Box>
        </Box>
        <Box>
          <img src={RightArrow} alt="RightArrow" />
        </Box>
      </SingleTest>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      {this.state.loading && <Loader loading={this.state.loading} />}
      <Wrapper display="flex" flexDirection="column" sx={{ width: { xs: "90%", md: "100%" }, margin: "auto", }} bgcolor="white">
        <Box display="flex" alignItems="center" justifyContent="space-between" padding="20px">
          <Box component="p" sx={{ fontSize: "20px", fontWeight: "bold" }} m={0} color="#005487">{configJSON.viewTest}</Box>
        </Box>
        <Divider className="divider" />
        <Box p={"20px"}>
        <Box component={"p"} fontSize={"16px"} fontWeight={"bold"}>{configJSON.pendingTest}</Box>
        {this.state.pending_quizes&&this.state.pending_quizes.length>0 ?<Box className="gap-15" display={"flex"} flexDirection={"column"}> 
         { this.state.pending_quizes.map(item=>{
           return this.renderSinglTest(item)
          })}</Box>
          :
          <Box component={"p"} fontSize={"14px"} color="red" m={0}>{configJSON.pendingError}</Box>}
        </Box>
        <Box p={"20px"}>
        <Box component={"p"} fontSize={"16px"} fontWeight={"bold"} >{configJSON.complateTest}</Box>
          {this.state.completed_quizes&&this.state.completed_quizes.length>0 ? <Box className="gap-15" display={"flex"} flexDirection={"column"}>
          {this.state.completed_quizes.map(item=>{
           return this.renderSinglTest(item)
          })}</Box>
          :
          <Box component={"p"} fontSize={"14px"} color="red" m={0}>{configJSON.complateError}</Box>}
        </Box>
      </Wrapper>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  borderRadius: "12px",
  fontFamily: "Verdana",
  "& .gap-15":{
    gap:"15px"
  }
});

const SingleTest = styled(Box)({
  height: "88px",
  cursor:"pointer",
  paddingInline: "25px",
  borderRadius: " 12px",
  boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
  backgroundColor: "#fff",
});
// Customizable Area End
