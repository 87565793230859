import React from "react";
import { createStyles, Theme,withStyles } from "@material-ui/core/styles";
// Customizable Area Start
import {
  Box,
} from "@material-ui/core";
import OrganizationProfileSectionController, { Props } from "./OrganizationProfileSectionController.web";
import { EditIcon, LocationIcon, Mail, NameIcon, PhoneIcon, WebsiteIcon } from "./assets";


const OrganizationProfileStyles = (theme: Theme) =>
  createStyles({
    linkColor: {
      color: "#005487",
    },
    pointer: {
      cursor: "pointer"
    }
  });
// Customizable Area End
// Customizable Area Start

export class OrganizationProfileSection extends OrganizationProfileSectionController {
    constructor(props: Props) {
        super(props);
    }
    
  render() {
    const {classes, isOwnProfile} = this.props;
    const organization = this.props.data.attributes.organization

    return (
      <Box component={"section"} bgcolor={"#fff"} p={"24px"} marginBottom={"16px"} sx={{border:"1px solid #808080",borderRadius: "12px"}}>
         <Box display={"flex"} justifyContent={"space-between"}>
          <Box component={"h3"} m={0} fontFamily={"Verdana"} fontSize={"22px"} fontWeight={"bold"} color="#005487">Organization Info <Box component={"span"} fontWeight={"normal"} color="#808080" fontSize={"17px"}>{this.props.data.attributes.is_private?"• Private":"• Public"}</Box></Box>
          {isOwnProfile && <img src={EditIcon} className={classes.pointer} onClick={this.editDetails} data-testid="editDetails" alt="edit"/>}
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} mt={"20px"} alignItems={"start"}>
            <img src={NameIcon} alt="NameIcon"/>
            <Box ml={"17px"}  width={"100%"}>
              <Box component={"label"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Name</Box>
              <Box component={"p"} fontFamily={"Verdana"} color="#808080" fontSize={"16px"} m={0} mt={"4px"} mb={"21px"} fontWeight={"normal"}>{organization?.name}</Box>
            </Box>
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} mt={"20px"} alignItems={"start"}>
            <img src={LocationIcon} alt="LocationIcon"/>
            <Box ml={"17px"}  width={"100%"}>
              <Box component={"label"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Address</Box>
              <Box component={"p"} fontFamily={"Verdana"} color="#808080" fontSize={"16px"} m={0} mt={"4px"} mb={"21px"} fontWeight={"normal"}>{organization?.address}</Box>
            </Box>
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} mt={"20px"} alignItems={"start"}>
            <img src={WebsiteIcon} alt="webicon"/>
            <Box ml={"17px"}  width={"100%"}>
              <Box component={"label"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Website</Box>
              <Box component={"p"} fontFamily={"Verdana"} color="#808080" fontSize={"16px"} m={0} mt={"4px"} mb={"21px"} fontWeight={"normal"}>
              <a href={organization?.website} target="_blank" className={classes.linkColor}>{organization?.website}</a>
              </Box>
            </Box>
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} mt={"20px"} alignItems={"start"}>
            <img src={Mail} alt="Mail"/>
            <Box ml={"17px"}  width={"100%"}>
              <Box component={"label"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>E-mail</Box>
              <Box component={"p"} fontFamily={"Verdana"} color="#808080" fontSize={"16px"} m={0} mt={"4px"} mb={"21px"} fontWeight={"normal"}>
                <a data-testid="email" href={`mailto:${organization?.email}`} className={classes.linkColor}>{organization?.email}</a>
              </Box>
            </Box>
          </Box>
          <Box display={"flex"} borderBottom={"1px solid #808080"} mt={"20px"} alignItems={"start"}>
            <img src={PhoneIcon} alt="PhoneIcon"/>
            <Box ml={"17px"}  width={"100%"}>
              <Box component={"label"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"}>Primary Contact Info</Box>
              <Box component={"p"} fontFamily={"Verdana"} color="#808080" fontSize={"16px"} m={0} mt={"4px"} mb={"21px"} fontWeight={"normal"}>{organization?.contact_number}</Box>
            </Box>
          </Box>
      </Box>
      
    );
  }
}

export default withStyles(OrganizationProfileStyles, { withTheme: true })(OrganizationProfileSection);
// Customizable Area End
