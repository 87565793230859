// Customizable Area Start
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { sendAPIRequest } from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");
// Customizable Area Start
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  flag?: boolean;
  communityFollow?: boolean;
  refreshPost: () => void;
  data: any;
  type: string;
  editPost: (id:string) => void;
  followUpdatedList: (type:string, id:string, action:string) => void;
  followUpdatedCommunityTab: (type:string, id:string, action:string) => void;
  deletePost: (id:string)=> void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  menuOpen: boolean;
  loading: boolean;
  shareOpen: boolean;
  communityFlag:string | null;
  editedPost:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  followAaccountApi: string = "";
  hidePostApi: string = "";
  unFollowAccountApi: string = "";
  copyPostApi: string = "";
  notificationApi: string = "";
  savePostApi: string = "";
  sharePostApi: string = "";
  unSaveApi: string = "";
  deletePostApi: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
    ];

    this.state = {
      menuOpen: false,
      loading: false,
      shareOpen: false,
      editedPost:false,
      communityFlag:localStorage.getItem("community"),
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


    // Customizable Area End
  }

  // Customizable Area Start
  shareRef: React.RefObject<HTMLDivElement> = React.createRef();
  menuRef: React.RefObject<HTMLDivElement> = React.createRef();

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId1 = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson1 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson1 && apiRequestCallId1) {
        this.apiResponse(responseJson1, apiRequestCallId1)
      }
    }
  }

  apiResponse = (responseJson1: any, apiRequestCallId1: string) => {
    if (apiRequestCallId1 === this.followAaccountApi) return this.followAccountResponse(responseJson1);
    if (apiRequestCallId1 === this.notificationApi) return this.notificationResponse(responseJson1);
    if (apiRequestCallId1 === this.unFollowAccountApi) return this.unFollowAccountResponse(responseJson1);
    if (apiRequestCallId1 === this.hidePostApi) return this.hidePostResponse(responseJson1);
    if (apiRequestCallId1 === this.savePostApi) return this.savePostResponse(responseJson1);
    if (apiRequestCallId1 === this.sharePostApi) return this.sharePostResponse(responseJson1);
    if (apiRequestCallId1 === this.unSaveApi) return this.unSavePostResponse(responseJson1);
    if (apiRequestCallId1 === this.copyPostApi) return this.copyPostResponse(responseJson1);
    if (apiRequestCallId1 === this.deletePostApi) return this.deletePostResponse(responseJson1);
  }

  unFollowAccountResponse = (responseJson: { data: { message: string } }) => {
    if (responseJson.data) {
      toast.success("unfollow successfully");

    }
  }

  followAccountResponse = (responseJson: { data: { message: string } ,errors:{message:string}}) => {
    if (responseJson.data) {
      toast.success("follow successfully");
      
    }
    if(responseJson.errors){
      toast.error(responseJson.errors.message);
    }
  }

  hidePostResponse = (responseJson: { data: [{ message: string }] }) => {
    if (responseJson.data) {
      toast.success(responseJson.data[0].message)

    }
  }

  deletePostResponse = (responseJson: { message: string }) => {

    if (responseJson) {
      toast.success(responseJson.message)
      this.props.deletePost(this.props.data.id);

    }
  }

  savePostResponse = (responseJson: { data: [{ message: string }] }) => {
    if (responseJson.data) {
      toast.success(responseJson.data[0].message);
      this.props.refreshPost();
    }
  }

  unSavePostResponse = (responseJson1: { data: [{ message: string }] }) => {
    if (responseJson1.data) { 
      toast.success(responseJson1.data[0].message);
      this.props.refreshPost()
    }
  }

  notificationResponse = (responseJson: { data: [{ message: string }] }) => {
    if (responseJson.data) { 
      toast.success("Notification changed");
      this.props.refreshPost()
    }
  }

  sharePostResponse = (responseJson: { data: [{ message: string }] }) => {
    if (responseJson.data) {
      toast.success(responseJson.data[0].message)
      this.props.refreshPost();
    }
  }

  copyPostResponse = (responseJson: { data: [{ message: string }] }) => {
    if (responseJson.data) {
      navigator.clipboard.writeText(responseJson.data[0].message);
      toast.success("Copied!")
    }
  }

  async componentDidMount() {
    super.componentDidMount()
    window.addEventListener("click", this.closePopup)
  }

  async componentWillUnmount(): Promise<void> {
    window.removeEventListener("click", this.closePopup)
  }

  closePopup = (event: MouseEvent) => {
    const { shareOpen, menuOpen } = this.state;
    const ele = event.target as HTMLElement

    if ((shareOpen) && this.shareRef.current && !this.shareRef.current.contains(event.target as Node)) {
      if (ele.id === "sharee-id") {
        return; // Do not close the popup
      }
      this.setState({ shareOpen: false });
    }
    if ((menuOpen) && this.menuRef.current && !this.menuRef.current.contains(event.target as Node)) {
      if (ele.id === "more-id") {
        return; // Do not close the popup
      }
      this.setState({ menuOpen: false });
    }
  };

  handleMenus = () => {
    this.setState({ menuOpen: !this.state.menuOpen, shareOpen: false })
  }

  openShare = (e:  React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    this.setState({
      menuOpen: false,
      shareOpen: !this.state.shareOpen,
    });
  }

  token = async () => {
    const loginToken = await getStorageData('LoginSuccessToken')
    const signupToken = await getStorageData('SignupSuccessToken');
    return signupToken || loginToken;
  }

  handleFollow = async () => {
    this.setState({ menuOpen: false });
    const type=this.props.data.attributes.account_id? "account" : "community";
  
    const tempId = type === "account" ?this.props.data.attributes.account_id :this.props.data.attributes.community_id
    let body = new FormData();
    this.props.data.attributes.account_id && body.append("account_id", this.props.data.attributes.account_id);
    this.props.data.attributes.community_id && body.append("community_id", this.props.data.attributes.community_id);
    const token = await this.token()
    this.followAaccountApi = sendAPIRequest(configJSON.createFollower, {
      method: configJSON.PostAPiMethod,
      body,
      headers: {
        token: token
      }
    });
    this.props.flag?this.props.followUpdatedCommunityTab(type,tempId,"follow"):this.props.followUpdatedList(type,tempId,"follow")
  }

  handleUnFollow = async () => {
    this.setState({ menuOpen: false })
    const type=this.props.data.attributes.account_id? "account" : "community";
  
    const tempId = type === "account" ?this.props.data.attributes.account_id :this.props.data.attributes.community_id
    let id = this.props.data.attributes.account_id ? `account_id=${this.props.data.attributes.account_id}` : `community_id=${this.props.data.attributes.community_id}`;
    const token = await this.token();
    this.unFollowAccountApi = sendAPIRequest(`${configJSON.removeFollower}?${id}&unfollow_type=post_unfollow`, {
      method: configJSON.deleteMethod,
      headers: {
        token: token
      }
    });
    this.props.flag?this.props.followUpdatedCommunityTab(type,tempId,"unfollow"):this.props.followUpdatedList(type,tempId,"unfollow")
  }

  handleHidePost = async () => {
    this.setState({ menuOpen: false });
    const token = await this.token();
    let body = new FormData();
    body.append("post_id", this.props.data.id);
    this.hidePostApi = sendAPIRequest(configJSON.hidePost, {
      method: configJSON.PostAPiMethod,
      body: body,
      headers: {
        token: token
      }
    });
    this.props.deletePost(this.props.data.id)
  }

  handleNotification = async () => {
    this.setState({ menuOpen: false });
    const on_off = this.props.data.attributes.notification_turn_on ? "turn_off_notification_setting" : "turn_on_notification_setting";
    const token = await this.token();
    let body = new FormData();
    body.append("post_id", this.props.data.id);
    this.notificationApi = sendAPIRequest(on_off, {
      method: configJSON.PostAPiMethod,
      body: body,
      headers: {
        token: token
      }
    });
  }

  handleSavePost = async () => {
    this.setState({ menuOpen: false });
    if (this.props.data.attributes.post_save_by_current_user) return this.handleUnSavePost();
    const token = await this.token()
    let body = new FormData()
    body.append("post_id", this.props.data.id);
    this.savePostApi = sendAPIRequest(configJSON.savePost, {
      method: configJSON.PostAPiMethod,
      body: body,
      headers: {
        token: token
      }
    });
  }

  handleUnSavePost = async () => {
    const token = await this.token()
    const body = new FormData();
    body.append("post_id", this.props.data.id)
    this.unSaveApi = sendAPIRequest(configJSON.unSavepostEnd, {
      method: configJSON.PostAPiMethod,
      body: body,
      headers: {
        token: token
      }
    });
  }

  handleDeletePost = async () => {
    this.setState({ menuOpen: false });
    const token = await this.token()
    this.deletePostApi = sendAPIRequest(`${configJSON.postsEnd}${this.props.data.id}`, {
      method: configJSON.deleteMethod,
      headers: {
        token: token
      }
    });
  
  }

  handleCopy = async () => {
    this.setState({ shareOpen: false });
    const token = await this.token()
    const id = `copy_post_link?id=${this.props.data.id}&action_url=SinglePost`;
    this.copyPostApi = sendAPIRequest(id, {
      method: configJSON.get,
      headers: {
        token: token
      }
    });
  }

  handleSharePost = async () => {
    this.setState({ shareOpen: false });
    let body = new FormData();
    const token = await this.token();
    body.append("post_id", this.props.data.id);
    this.sharePostApi = sendAPIRequest(configJSON.sharePost, {
      method: configJSON.PostAPiMethod,
      body: body,
      headers: {
        token: token
      }
    });
  }

  handleEditpost = () => {
    this.setState({ editedPost: true })


  }

  handleInverseEditPost=()=>{
    this.setState({editedPost:false});
  }

  reportPost=()=>{
    const message = new Message(getName(MessageEnum.NavigationMessage))

    message.addData(getName(MessageEnum.NavigationTargetMessage), "Report");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    message.addData(
      getName(MessageEnum.NavigationScreenNameMessage),
      `post${this.props.data.id}`
    );

    this.send(message);
  }
  // Customizable Area End
}
