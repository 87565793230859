// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const FilterSearchIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <g filter="url(#filter0_d_5_402)">
                <path d="M13.9333 15.8917C13.9333 16.4 13.6 17.0667 13.175 17.325L12 18.0834C10.9083 18.7584 9.39166 18 9.39166 16.65V12.1917C9.39166 11.6 9.05833 10.8417 8.71666 10.425L5.51664 7.05834C5.09164 6.63334 4.75833 5.88335 4.75833 5.37502V3.44168C4.75833 2.43335 5.51668 1.67502 6.44168 1.67502H17.5583C18.4833 1.67502 19.2417 2.43334 19.2417 3.35834V5.20834C19.2417 5.88334 18.8167 6.72502 18.4 7.14168" fill="none" strokeWidth={props.strokeWidth} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.3917 13.7666C16.8644 13.7666 18.0584 12.5728 18.0584 11.1C18.0584 9.62724 16.8644 8.43332 15.3917 8.43332C13.9189 8.43332 12.725 9.62724 12.725 11.1C12.725 12.5728 13.9189 13.7666 15.3917 13.7666Z" fill="none" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18.5583 14.2667L17.725 13.4333" fill="none" strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </SvgIcon>
    );
}

export default FilterSearchIcon;
// Customizable Area End