import React from "react";

import {
  Box,
  Button,
  Typography,
  CircularProgress
  // Customizable Area Start
  , Card,
  Grid,
  IconButton,
  MenuItem,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {  styled } from "@material-ui/core/styles";
import { checkListIcon, scoreCardIcon, dropDownIcon } from "../assets";
import {
  AssessmentTestList,
  BarcData,
  configJSON,
} from "./AssessmentResultController";
import Loader from "../../../../components/src/Loader.web";

import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Label, Tooltip } from 'recharts'

const RootBox = styled(Grid)({
  position: "relative",
  borderRadius: "12px",
})

const DropDownList = styled(Box)({
  position: "absolute",
  top: "50px",
  borderRadius: "12px",
  marginLeft: "auto",
  backgroundColor: "#fff",
  zIndex: 1,
  boxShadow: "2px 4px 16px 4px rgba(0, 0, 0, 0.25)",
  width: "480px",
  boxSizing: "border-box",
  padding: "20px",
  maxHeight: "640px",
  overflowY: "auto",
  "@media(max-width:600px)": {
    width: "320px",
  },
  "@supports (-moz-appearance:none)": {
    maxWidth: "-moz-available"
  },
  "@supports (-webkit-appearance:none)": {
    maxWidth: "-webkit-fill-available", // Apply for WebKit-based browsers
  }
});

const DropdownListItem = styled(MenuItem)({
  fontFamily: "Verdana",
  padding: "19px",
  color: "#808080",
  fontSize: "16px",
  height: "60px",
  borderRadius: "1px",
  boxSizing: "border-box",
  "&:hover": {
    backgroundColor: "#005586",
    color: "#fff",
  },
  '&.Mui-selected': {
    backgroundColor: '#005586',
    color: "#fff",
    "&:hover": {
      backgroundColor: "#005586",
    },
  },
})

const CustomCard = styled((Card))({
  borderRadius: "12.1px",
  marginBottom:"20px"
});

const Heading = styled((Typography))({
  fontFamily: "Verdana",
  lineHeight: 1.1,
  fontWeight: "bold",
  fontSize: "20px",
  color: "#005487",
  letterSpacing: "-0.41px",
})

const Header = styled((Box))({
  borderBottom: "1px solid #808080",
  padding: "24px",
  display: "flex",
  alignItems: "center",
  position: 'relative',
  boxSizing: "border-box"
});

const DropDownIcon = styled((IconButton))({
  color: "#73777A",
  paddingTop: 0,
  paddingBottom: 0,
  borderRadius: "7px",
  "& .dropdown-img": {
    height: "22px",
    width: "22px",
  }
})
const MainBox = styled("section")({
  padding:"24px",
})
const ChartBox = styled((Box))({
  display: "flex",
  justifyContent: "center",
  borderRadius: "12px",
  padding: "32px",
  alignItems: "center",
  height: "630px",
  border: "1px solid #000",
  boxSizing: "border-box",
})

const InfoCard = styled((Card))({
  padding: "52px 0px 51px 0px",
  width: "48%",
  maxWidth:"395px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "16px",
  borderRadius: "12px",
  marginTop: "27px",
  boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
})
const InfoCards = styled((Box))({
  display: "flex",
  justifyContent: "space-between",
})

const InfoCardImg = styled("img")({
  "& > svg": {
    width: "80px",
    color: "#5C92B4",
    height: "80px",
  },
})
const InfoCardIcon = styled((Box))({
  textAlign: "center",
  "& > svg": {
    color: "#5C92B4",
    width: "80px",
    height: "70px",
  },
})
const InfoCardText = styled((Typography))({
  fontSize: "20px",
  textTransform: "capitalize",
  fontWeight: "bold",
})

const DateCard = styled(Box)({
  marginTop: "28px",
  minHeight: "350px",
  padding: "24px 50px 24px",
  borderRadius: "12px",
  boxSizing: "border-box",
})
const InfoScoreText = styled("p")({
  fontSize: "24px",
  textAlign: "center",
  margin: 0,
  color:"#005487",
  fontWeight: "bold",
})

const ComplateBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "25px"  
})

const DateCardColumn = styled('td')({
  fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
  fontSize: "16px",
  fontWeight: "bold",
  color: "#808080",
})

const DateCardList = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "24px"
})
const DateCardItem = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
})
const ButtonContainer = styled((Box))({
  borderTop: "1px solid #808080",
  padding: "23px 0 24px 0",
  display: "flex",
  justifyContent: "center",
})


const DateCardTextHead = styled('th')({
  fontSize: "17px",
  fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
  fontWeight: "bold",
  color: "#005487",
})


const DateCardButton = styled('button')({
  height: "56px",
  width: "417px",
  backgroundColor: "#005586",
  border: "0px",
  cursor: "pointer",
})

const LocalAdminDateCard = styled(Card)({
  padding: "30px 0px 40px 0px",
  borderRadius: "12px",
  boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
})

const LocalAdminMain = styled(Box)({
  paddingLeft: "24px",
  paddingRight: "24px",
  paddingTop: "23px",
  paddingBottom: "24px"
})

const LocalAdminHeading = styled("h3")({
  margin: 0,
  fontSize: "20px",
  textTransform: "capitalize",
  textAlign: "center",
  fontFamily: "Verdana",
  fontWeight: "bold",
  marginBottom: "24px",
})

const LocalAdminBtns = styled('button')({
  height: "56px",
  width: "397px",
  backgroundColor: "#005586",
  padding: 0,
  color: "#fff",
  fontSize: "17px",
  textTransform: "capitalize",
  textAlign: "center",
  fontWeight: "bold",
  border: "0px",
  cursor: "pointer",
})
const LocalAdminButtonContainer = styled(Box)({
  borderTop: "1px solid #808080",
  padding: "23px 34px 24px 34px",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  gap: "16px"
})

// Customizable Area End

import AssessmentResultController, {
  Props
} from "./AssessmentResultController";
import { handleInvalidData } from "../../../../components/src/common";

export default class AssessmentResult extends AssessmentResultController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  Chart = () => {
    const chartData = this.state.barcData.chartData.length >0 ? this.state.barcData.chartData : [{ date: "0", score: 0 }]
    return (
      
        <ResponsiveContainer width={"100%"} height="100%">
          <LineChart
            margin={{ top: 0, right: 20, left: 40, bottom: 60 }}
            data={[...chartData]}
          >
            <Line
            dataKey="score"
            stroke="#005586"
            strokeWidth={4}
              type="linear"
              dot={false}
              connectNulls={true}
            />
            <XAxis
            style={{
              fontWeight: "bold",
              color: "#000",
              fill: "#000",
              fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
            }}
              interval="preserveEnd"
              dataKey="date"
              padding={{ right: 20, left: 30 }}
              tickSize={20}
              minTickGap={10}
            >
              <Label
              style={{
                fontSize: "20px",
                fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                fontWeight: "bold",
                color: "#005586",
                fill: "#01548A",
              }}
                value={this.state.selectedAssessment.toLowerCase().includes("barc") ? "Date of BARC Test" : ("Date of " + this.state.selectedAssessment)}
                position="insideBottom"
                dy={60}
              />
            </XAxis>

            <YAxis
              style={{
                fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                fontWeight: "bold",
                color: "#000",
                fill: "#000",
              }}
              dataKey="score"
              stroke="#808080"
              tickSize={10}
              domain={[0, 'auto']}
              padding={{ top: 30 }}
            >
              <Label
              dx={-30}
              dy={100}
                value="Average Scoring"
                angle={-90}
                position="insideLeft"
                style={{
                  fontSize: "20px",
                  fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                  fontWeight: "bold",
                  color: "#005586",
                  fill: "#01548A",
                }}
              />
            </YAxis>
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
      
    )
  }

  renderHeader = () =>{
    return(
      <Header >
      <Heading >{this.state.selectedAssessment}</Heading>
      <DropDownIcon
        aria-label="Expand"
        onClick={() => this.setState({ showDropDown: !this.state.showDropDown })}
      >
        <img src={dropDownIcon} alt="DropDownIcon" className="dropdown-img"/>
      </DropDownIcon>
        {this.state.showDropDown && (<DropDownList sx={{left:{xs: "unset",sm:"200px"},right: {xs:0, sm: "unset"}}}>
          {this.state.assessmentTestList.map((e: AssessmentTestList, i: number) => {
            return (
              <DropdownListItem selected={this.state.selectedMenu.id === e.id} key={`key-${e.id}-${i + 1}`} onClick={() => this.handleAssessmentChange(e.id, e.title)}>
                <Typography variant="inherit">{e.title}</Typography>
              </DropdownListItem>
            );
          })}
        </DropDownList>)}
    </Header>
    )
  }

  renderLocalAdmin = () => {
    return (
      <Box component={"section"}>
        <LocalAdminMain >
          <LocalAdminDateCard style={{ marginBottom: "40px" }}>
            <LocalAdminHeading >{configJSON.barc_dashboard_label}</LocalAdminHeading>
            <LocalAdminHeading >{this.state.selectedMenu.lable}</LocalAdminHeading>
            {this.state.adminBarcData && this.state.adminBarcData.barc_score.length > 0 ?
              <DateCardList sx={{ paddingX: { xs: "24px", lg: "93px" } }}>
                <Grid component={Box} container>
                  <Grid item xs={4} component={Box} sx={{ fontSize: "17px", fontFamily: "Verdana", fontWeight: "bold", color: "#005487" }} m={0}>
                    {configJSON.usernameLable}
                  </Grid>
                  <Grid item xs={4} component={Box} textAlign={"center"} sx={{ fontSize: "17px", fontFamily: "Verdana", fontWeight: "bold", color: "#005487" }} m={0}>
                    {configJSON.dateLable}
                  </Grid>
                  <Grid item xs={4} component={Box} textAlign={"end"} sx={{ fontSize: "17px", fontFamily: "Verdana", fontWeight: "bold", color: "#005487" }} m={0}>
                    {configJSON.scoresLable}
                  </Grid>
                </Grid>
                {this.state.adminBarcData.barc_score.map((data, i: number) => {
                  return (
                    <Grid component={Box} container key={`key-${data.score}-${i + 0}`}>
                      <Grid item xs={4} component={Box} sx={{ fontSize: "16px", fontFamily: "Verdana", fontWeight: "bold", color: "#808080" }} m={0}>
                        {data.name}
                      </Grid>

                      <Grid item xs={4} textAlign={"center"} component={Box} sx={{ fontSize: "16px", fontFamily: "Verdana", fontWeight: "bold", color: "#808080" }} m={0}>
                        {data.date}
                      </Grid>
                      <Grid item xs={4} textAlign={"end"} component={Box} sx={{ fontSize: "16px", fontFamily: "Verdana", fontWeight: "bold", color: "#808080" }} m={0}>
                        {data.score} / {data.total_score}
                      </Grid>

                    </Grid>
                  );
                })}
              </DateCardList>
              :
              <Box component={"p"} fontFamily={"Verdana"} fontSize={"17px"} m={"24px"}>
                {configJSON.noAssessmentScoreLable}
              </Box>
            }
          </LocalAdminDateCard>
          <LocalAdminDateCard >
            <LocalAdminHeading >{configJSON.oveallAverageScoreLable}</LocalAdminHeading>
            {this.state.adminBarcData?.overall_average_score?.length > 0 ?
              <DateCardList sx={{ paddingX: { xs: "24px", md: "60px" } }}>
                <DateCardItem >
                  <DateCardTextHead >
                    {configJSON.usernameLable}
                  </DateCardTextHead>
                  <DateCardTextHead >
                    {configJSON.scoresLable}
                  </DateCardTextHead>
                </DateCardItem>
                {this.state.adminBarcData.overall_average_score.map((data, i: number) => {
                  return (
                    <DateCardItem key={`key-${data.name}-${i + 1}`}>
                      <Box fontFamily="Verdana" m={0} fontSize={"16px"} color="#808080" fontWeight={"bold"} component={"p"}>
                        {data.name}
                      </Box>
                      <Box fontFamily="Verdana" m={0} fontSize={"16px"} color="#808080" fontWeight={"bold"} component={"p"}>
                        {data.score} / {data.total_avg_score}
                      </Box>
                    </DateCardItem>
                  );
                })}
              </DateCardList>
              :
              <Box component={"p"} fontFamily={"Verdana"} fontSize={"17px"} m={"24px"}>
                {configJSON.noAverageScoreLabel}
              </Box>
            }
          </LocalAdminDateCard>

        </LocalAdminMain>
        <LocalAdminButtonContainer >
          <LocalAdminBtns data-test-id="view-btn" onClick={() => this.navigateUser('View')} >{configJSON.viewTestLable}</LocalAdminBtns>
          <LocalAdminBtns data-test-id="create-test-btn" onClick={() => this.navigateUser('AssessmentTest')} >{configJSON.createTestLable}</LocalAdminBtns>
        </LocalAdminButtonContainer>
      </Box >
    )
  }
  // Customizable Area End


  render() {
    // Customizable Area Start
    return (
    
        <RootBox xs={12} >
          <Loader loading={this.state.showLoading} />

          <CustomCard >
           {this.renderHeader()}
            {this.isLocalAdmin ? (
             this.renderLocalAdmin()
            ) :
              <Box>
                <MainBox >
                  <ChartBox >
                    {this.Chart()}
                  </ChartBox>
                  <InfoCards >
                    <InfoCard>
                      <InfoCardIcon >
                        <InfoCardImg src={scoreCardIcon} alt={'score-icon'} />
                      </InfoCardIcon>
                      <InfoCardText align={"center"}>
                        {configJSON.labelAverageScore}
                      </InfoCardText>
                      <InfoScoreText>
                        <span>
                          {this.state.barcData.avg_score?this.state.barcData.avg_score:0}
                        </span> / {this.state.barcData.total_score}
                      </InfoScoreText>
                    </InfoCard>
                    <InfoCard>
                      <InfoCardIcon >
                        <InfoCardImg src={checkListIcon} alt={'checklist-icon'} />
                      </InfoCardIcon>
                      <InfoCardText align={"center"}>
                        {configJSON.labelNumberOfTests}
                      </InfoCardText>
                      <InfoScoreText>
                        <span>
                          {this.state.barcData.no_of_test_taken}
                        </span>
                      </InfoScoreText>
                    </InfoCard>
                  </InfoCards>
                <DateCard sx={{boxShadow : this.state.barcData.attemptedData.length > 0 ? "0 2px 4px 0 rgba(0, 0, 0, 0.25)" : ""}}>
                  {
                    this.state.barcData.attemptedData.length > 0 && <>
                      <Box
                        component={"p"}
                        fontSize={"24px"}
                        fontWeight={"bold"}
                        fontFamily={"Verdana"}
                        margin={0}
                        textAlign="center"
                      >{configJSON.datesLable}</Box>
                      <Box>
                        <Box
                          component={"p"}
                          fontSize={"17px"}
                          fontWeight={"bold"}
                          margin={0}
                          mt={"20px"}
                          mb={"20px"}
                          fontFamily={"Verdana"}
                        >
                          {configJSON.labelCompleted}
                        </Box>
                        <ComplateBox>{

                          this.state.barcData.attemptedData.map((e: BarcData['data']['attemptedData'][0], i: number) => {
                            return (
                              <DateCardItem key={`key-${e.title}-${i + 1}`}>
                                <DateCardColumn
                                  className={`Heading5 BoldFont LinkEmbedTitle`}
                                >
                                  {e.title}
                                </DateCardColumn>
                                <DateCardColumn
                                  className={`Heading5 BoldFont LinkEmbedTitle`}

                                >
                                  {e.date}
                                </DateCardColumn>
                              </DateCardItem>
                            );
                          })
                        }
                        </ComplateBox>
                      </Box>
                    </>
                  }
                </DateCard>
                </MainBox>
                <ButtonContainer >
                  <DateCardButton onClick={() => this.navigateUser('AssessmentTestListing')} >
                    <Typography
                      className={`Heading5 BoldFont FontColor4`}
                      gutterBottom
                      variant="body2"
                      component="p">
                      {configJSON.labelTakeANewTest}
                    </Typography>
                  </DateCardButton>
                </ButtonContainer>
              </Box>
            }

          </CustomCard>
        </RootBox >
      
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
