// Customizable Area Start
import React from "react"
import { SvgIcon } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export const CheckboxCheckCircleIcon: any = (props: any) => {
    return (
        <SvgIcon {...props}>
            <circle cx="12" cy="12" r="11.5" fill="none" />
            <circle cx="12" cy="12" r="6" />
        </SvgIcon>
    );
}

export default CheckboxCheckCircleIcon;
// Customizable Area End