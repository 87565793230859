import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { handleErrorArray } from "../../../components/src/common";
import { getStorageData } from "../../../framework/src/Utilities";

interface UserList {
  "data": {
    "id": string
    "type": string;
    "attributes": {
      "id": number;
      "user_name": string;
      "profile": string
    }
  }[]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  showLoading: boolean;
  usersList: UserList['data'];
  dropDownList: { title: string, id: string }[];
  showDropDown: boolean;
  selectedMenu: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AssessmentTestUserListingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTestUserApiCallId: string = "";
  quizId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationAssessmentTestUserListMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      showLoading: false,
      usersList: [],
      dropDownList: [
        {
          title: "My Community",
          id: "community"
        },
        {
          title: "Individual User",
          id: "INDUser"
        }
      ],
      selectedMenu: 'INDUser',
      showDropDown: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start


    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.setState({
      showLoading: false
    })
    if (responseJson.errors) {
      handleErrorArray(responseJson.errors)
    }
    if (apiRequestCallId === this.getTestUserApiCallId) {
      this.handleGetTestUserResponse(responseJson);
    }
    // Customizable Area End
  }


  // Customizable Area Start
  async componentDidMount() {
    const quizId = this.props.navigation.getParam('id');
    this.quizId = quizId;
    this.getTestUserData()

  }

  startLoading = () => {
    this.setState({
      showLoading: true
    })
  }

  getTestUserData = async () => {
    this.startLoading();

    const loginToken = await getStorageData('LoginSuccessToken');
    const signupToken = await getStorageData('SignupSuccessToken');

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: signupToken || loginToken
    };

    const getUserDataMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTestUserApiCallId = getUserDataMsg.messageId;

    getUserDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAssessmentTestUserEndpoint
    );

    getUserDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getUserDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAssessmentTestUserApiMethod
    );
    runEngine.sendMessage(getUserDataMsg.id, getUserDataMsg);
  }

  handleMenuChange = (data: string) => {
    if (data === 'community') {
      const msg = new Message(getName(MessageEnum.NavigationAssessmentTestViewListMessage));
          msg.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
          );
          msg.addData(
            getName(MessageEnum.UrlPathParamMessage),
            data
          );
          this.send(msg)
    }
  }

  handleGetTestUserResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        usersList: responseJson.data
      })
    } else {
      this.setState({
        usersList: []
      })
    }
  }

  handleNavigation = (userId: string) => {
    const individualUserNavigation = new Message(getName(MessageEnum.NavigationCommunityDashboardMessage));
      individualUserNavigation.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      individualUserNavigation.addData(
        getName(MessageEnum.UrlPathParamMessage),
        `user-${userId}`
      );
      this.send(individualUserNavigation)
  }

  handleBackNavigation = () => {
    this.props.navigation.navigate("AssessmentDashboard");
  }
  // Customizable Area End
}
