// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
export interface DataType {
  id: string,
  type: string,
  attributes: {
    id: number,
    event_type: string,
    event_name: string,
    event_url: string,
    timezone: string,
    description: string,
    start_date: string,
    end_date: string,
    street: null,
    city: string,
    state: string,
    zipcode: string,
    creating_time: string,
    start_time: string,
    end_time: string,
    account: {
      data: {
        id: string,
        type: string,
        attributes: {
          activated: boolean,
          country_code: string,
          email: string,
          first_name: null,
          full_phone_number: string,
          last_name: null,
          full_name: string,
          job_title: string,
          phone_number: string,
          type: string,
          pronouns: null,
          language: [
            string
          ],
          race: string,
          ethnicity: string,
          gender: string,
          state: string,
          is_private: boolean,
          city: string,
          dob: null,
          timezone: string,
          is_local_admin: boolean,
          created_at: string,
          updated_at: string,
          device_id: null,
          unique_auth_id: string,
          organization: {
            id: number,
            address: string,
            email: string,
            name: string,
            website: string,
            contact_number: string,
            created_at: string,
            updated_at: string,
            account_id: number
          },
          social_media_urls: [],
          facial_authentication_key: null,
          biometric_authentication_key: null,
          user_name: null,
          profile_picture: string,
          background_image: null,
          follower_count: number,
          posts: null,
          achievements: null,
          is_Community_present: boolean,
          selected_communities: null,
          friend_list_count: number,
          is_in_connection: boolean
        }
      }
    },
    cover_image: string,
    is_interested: boolean,
    is_saved: boolean,
    interested_count: number,
    is_login_user_event: boolean,
    created_at: string,
    full_name: string,
    community_name: null,
    profile_picture: string,
    community_profile: null,
    community_account_id: null
  }
}
export interface ListType {
  id: number,
  label: string,
  value: string,
}
export const configJSON = require("./config");

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tab: boolean;
  upCommingEvent: Array<DataType>;
  interestedEventdata: Array<DataType>;
  pastEvent: Array<DataType>;
  interest_Event: boolean;
  createEventModal: boolean;
  eventDesc: string;
  eventDescModal: boolean;
  imageErrorMsg: string;
  uploadImage: string | ArrayBuffer | null | unknown;
  imageOpen: boolean;
  finalPost: boolean;
  open: boolean;
  imageEventOpen: boolean;
  image: any;
  event: string;
  eventUrl: string;
  radioSelect: string;
  timezone: string;
  startTime: string;
  endTime: string;
  dateEnd: any;
  dateStart: any;
  imagePass: string;
  imageWidth: number;
  radioSelectErrorMsg: string;
  createPostError: boolean;
  createPostErrorMsg: string;
  urlLinkError: boolean;
  urlLinkErrorMsg: string;
  descriptionError: boolean;
  descriptionErrorMsg: string;
  loading: boolean;
  interestedImage:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}




export default class MyEventController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  upCommingEventsAPICallId: any;
  postEventsAPICallId: any;
  apiCreateEventPostCallId: any;
  createEventActionApiCallId: any;
  interestedUpCommingEventsAPICallId: any;
  interestedPastEventsAPICallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      tab: true,
      upCommingEvent: [],
      pastEvent: [],
      interestedEventdata: [],
      interest_Event: false,
      createEventModal: false,
      eventDesc: "",
      eventDescModal: false,
      imageErrorMsg: "",
      uploadImage: "",
      imageOpen: false,
      finalPost: false,
      open: false,
      imageEventOpen: false,
      image: "",
      event: "",
      eventUrl: "",
      radioSelect: "virtual",
      timezone: "",
      startTime: "",
      endTime: "",
      dateEnd: "",
      dateStart: "",
      createPostError: false,
      createPostErrorMsg: "",
      urlLinkError: false,
      urlLinkErrorMsg: "",
      descriptionError: false,
      descriptionErrorMsg: "",
      imagePass: "",
      imageWidth: 500,
      radioSelectErrorMsg: "",
      loading: false,
      interestedImage:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.upCommingEvents()

  }


  myEvent_ApiCall = async (data: any) => {
    const { contentType, method, endPoint, body, type } = data;
    let signupToken = await getStorageData("SignupSuccessToken");
    let loginToken = await getStorageData("LoginSuccessToken");
    const header = {
      "Content-Type": contentType,
      token: signupToken || loginToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body && type !== 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      :
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors) {
        this.responseSucessCell(apiRequestCallId, responseJson)
      } else if (responseJson && responseJson.errors) {
        this.responseFailureCall(apiRequestCallId, responseJson)
      }
    }
  }


  responseSucessCell = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.upCommingEventsAPICallId) {
      this.upCommingEventsSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.postEventsAPICallId) {
      this.postEventsSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.interestedUpCommingEventsAPICallId) {
      this.interestedUpCommingEventsSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.interestedPastEventsAPICallId) {
      this.interestedPastEventsSucessCallBack(responseJson);
    }
  }

  responseFailureCall = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.upCommingEventsAPICallId) {
      this.upCommingEventsFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.postEventsAPICallId) {
      this.postEventsFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.interestedUpCommingEventsAPICallId) {
      this.interestedUpCommingEventsFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.interestedPastEventsAPICallId) {
      this.interestedPastEventsFailureCallBack(responseJson);
    }
  }


  upCommingEvents = async () => {
    this.setState({ loading: true })
    this.upCommingEventsAPICallId = await this.myEvent_ApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_events/events/fetch_own_event?event_time=upcoming_event`,

    });
  };

  upCommingEventsSucessCallBack = (res: any) => {

    let isDataSort = res.data.sort((a: any, b: any) => {
      let c: any = new Date(a?.attributes?.start_date);
      let d: any = new Date(b?.attributes.start_date);
      return d - c
    })
    this.setState({ upCommingEvent: isDataSort, loading: false }, () => this.hideLoaderForApi())
  };
  upCommingEventsFailureCallBack = (res: any) => {
  };

  postEvents = async () => {
    this.setState({ loading: true })
    this.postEventsAPICallId = await this.myEvent_ApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_events/events/fetch_own_event?event_time=past_event`,

    });
  };

  postEventsSucessCallBack = (res: any) => {
    let isPastDataSort = res.data.sort((first: any, second: any) => {
      let firstResponse: any = new Date(first?.attributes?.start_date);
      let secondResponse: any = new Date(second?.attributes.start_date);
      return secondResponse - firstResponse
    })
    this.setState({ upCommingEvent: isPastDataSort, loading: false }, () => this.hideLoaderForApi())
  };
  postEventsFailureCallBack = (res: any) => {
  };

  // ======== interested event api call =======

  interestedUpCommingEvents = async () => {
    this.setState({ loading: true })
    this.interestedUpCommingEventsAPICallId = await this.myEvent_ApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_events/events/interested_event_listing?event_time=upcoming_event`,

    });
  };

  interestedUpCommingEventsSucessCallBack = (res: any) => {

    let isInterestedDataSort = res.data.sort((a: any, b: any) => {
      let c: any = new Date(a?.attributes?.start_date);
      let d: any = new Date(b?.attributes.start_date);
      return d - c
    })
    this.setState({ upCommingEvent: isInterestedDataSort, loading: false }, () => this.hideLoaderForApi())
  };
  interestedUpCommingEventsFailureCallBack = (res: any) => {
  };

  interestedPastEvents = async () => {
    this.setState({ loading: true })
    this.interestedPastEventsAPICallId = await this.myEvent_ApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_events/events/interested_event_listing?event_time=past_event`,

    });
  };

  interestedPastEventsSucessCallBack = (res: any) => {
    let isinterestedPastDataSort = res.data.sort((first: any, second: any) => {
      let firstResponse: any = new Date(first?.attributes?.start_date);
      let secondResponse: any = new Date(second?.attributes.start_date);
      return secondResponse - firstResponse
    })
    this.setState({ upCommingEvent: isinterestedPastDataSort, loading: false }, () => this.hideLoaderForApi())
  };
  interestedPastEventsFailureCallBack = (res: any) => {
  };


  // cerate modal 

  // event hamdle button 

  handlePastEvent = () => {
    this.setState({ tab: false });
    this.state.interest_Event ? this.interestedPastEvents() : this.postEvents()
  };
  handleUpcommingEvent = () => {
    this.setState({ tab: true });
    this.state.interest_Event ? this.interestedUpCommingEvents() : this.upCommingEvents()
  };
  handleStar_button = () => {
    this.setState({ interest_Event: !this.state.interest_Event,})
    if(this.state.tab){
      this.interestedUpCommingEvents()
    }else{
        this.interestedPastEvents()
    }
   
    
  }


  hideLoaderForApi = () => {
    document.body.style.overflow = "auto";
  }

  handleNavigate = () => {
    this.props.navigation.navigate("LandingScreen")
    localStorage.setItem("SelectedScreenId", "1");
    localStorage.setItem("openmodal", "true");
  }

  getEventTimeClass=(start_date:any, start_time:any) =>{
    const eventStartTime = moment(start_time, "HH:mm:ss");
    if (new Date(start_date) < new Date()) {
        return "eventCard-time-container-passed";
    } else if (eventStartTime.format('hh:mm A').split(' ')[1] === "AM") {
        return "eventCard-time-container-AM";
    } else if (eventStartTime.format('hh:mm A').split(' ')[1] === "PM") {
        return "eventCard-time-container-PM";
    } else {
        return "";
    }
}

eventNavigation = (id?: string|number) => {
  this.props.navigation.navigate("CalendarEventDetails",{id:id})
}

eventGoBack=()=>{
  this.props.navigation.goBack()
}
  // Customizable Area End
}
