import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { Typography, Box, Grid, Card} from "@material-ui/core";
// Customizable Area Start
import Divider from "@material-ui/core/Divider";
import MutualConnectionListController, { configJSON, Props } from "./MutualConnectionListController.web";
import CommonListing  from "./CommonListing.web";
import Loader from "../../../components/src/Loader.web";
import styled from "@material-ui/core/styles/styled";
// Customizable Area End

// Customizable Area Start
export default class MutualConnectionList extends MutualConnectionListController {
    constructor(props: Props) {
        super(props);
    }


    render() {

        return (
            <Wrapper item xs={12}>
                <Loader loading={this.state.isLoading} />
                <Card className={"root"}>
                    <Box padding={"25px"}>
                        
                        <Box component={"p"} m={0} color={"#005487"} sx={{fontFamily:"Verdana", fontSize:"20px", fontWeight:"bold"}}>
                            {configJSON.mutual_connections}
                        </Box>
                    </Box>
                    <Divider />
                    <CommonListing navigation={this.props.navigation} listType="MUTUAL" list={this.state.mutualFriends} />
                </Card>
            </Wrapper>
        );
    }
}

const Wrapper = styled(Grid)({
    "& .root": {
        maxWidth: "100%",
        marginBottom: "16px",
        borderRadius: "12px",
        position: "relative",
    },
})
// Customizable Area End