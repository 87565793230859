// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { handleErrorArray } from "../../../components/src/common";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes: any
    open: boolean;
    getItem: (id:string, type: string) => void;
    postId: string;
    handleClose: () => void;
    type: string;
    description?: string;
    info: {
        name: string;
        is_private: boolean;
        type: string;
        image: string;
      }
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean;
    videoEmbedUrl: string;
    error: string;
    information: boolean;
    url: string;
    postId: string;
    description: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class VideoTypePostController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    appiCreateVideoPost: string = '';
    singlePostApi: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isLoading: false,
            videoEmbedUrl: "",
            error: '',
            information: false,
            url: '',
            postId: '',
            description: '',
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start    
    async componentDidMount(){
        super.componentDidMount();
        if(this.props.postId){
            this.getSinglePost(this.props.postId);
            this.setState({postId: this.props.postId})
        }
        if(this.props.description){
            this.setState({description:this.props.description})
        }
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            if (responseJson) {
                this.setState({ isLoading: false });
                if (apiRequestCallId === this.appiCreateVideoPost) {
                    if (responseJson.data) {
                       this.handleCreateEditResponse(responseJson)
                    } else {
                        handleErrorArray(responseJson.errors)
                    }
                } if (apiRequestCallId === this.singlePostApi) {
                    this.handleResponseSinglePost(responseJson)
                }
            }
        }
    }
    handleCreateEditResponse = (responseJson: { data: { id: string }, meta: { message: string } }) => {
        toast.success(responseJson.meta.message)
        const isNew = !this.state.postId ? 'new' : 'edit';
        this.props.getItem(responseJson.data.id, isNew);
        this.props.handleClose()
    }

    handleResponseSinglePost = (responseJson: {
        data: {
            attributes: {
                url: string,
                description: string,
            }
        }
    }) => {
        if (responseJson.data) {
            this.setState({
               url: responseJson.data.attributes.url,
               description: responseJson.data.attributes.description
            });
        }
    }
    handlePublish = async () => {
        if (!this.state.url) {
            return this.setState({ error: 'Please enter valid link' })
        }
        this.setState({ isLoading: true })
        let signupToken = await getStorageData("SignupSuccessToken");
        let loginToken = await getStorageData("LoginSuccessToken");
        const header = {
            token: signupToken || loginToken,
        };
        const formData = new FormData();

        formData.append("post[url]", this.state.url);
        formData.append("post[post_type]", this.props.type);
        this.props.description && formData.append("post[description]", this.props.description);

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.state.postId ? configJSON.editPost + this.state.postId : configJSON.postsEnd
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            this.state.postId ? configJSON.putApiMethode : configJSON.PostAPiMethod
        );
        this.appiCreateVideoPost = requestMessage.messageId;

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getSinglePost = async (id: string) => {
        this.setState({ isLoading: true })
        const loginToken2 = await getStorageData('LoginSuccessToken')
        const signupToken1 = await getStorageData('SignupSuccessToken')
        const header = {
            token: signupToken1 || loginToken2
        };
        const getAlonePost = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        getAlonePost.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postsEnd + id
        );

        this.singlePostApi = getAlonePost.messageId;

        getAlonePost.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.get
        );

        getAlonePost.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(getAlonePost.id, getAlonePost);
    }
    openInformation = () => {
        this.setState({ information: true })
    }
    closeInformation = () => {
        this.setState({ information: false })
    }
    handleChangeUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ url: e.target.value, error: '' })
    }
    // Customizable Area End
}