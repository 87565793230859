Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
const urlConfig = require("../../../framework/src/config");
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";

exports.baseURL = urlConfig.baseURL;
exports.groupsApiEndPoint = "account_groups/groups";
exports.tasksAccountsApiEndPoint = "roles_permissions/accounts/list_users";
exports.pronounsListEndPoint = "pronounce_list";
exports.genderListEndPoint = "gender_list";
exports.raceListEndPoint = "race_list";
exports.ethnicityListEndPoint = "ethnicity_list";
exports.languageListEndPoint = "language_list";
exports.getUserEndPoint = "get_user";
exports.updateUserEndPoint = "update_user";
exports.postOrganizationInfo = "bx_block_organizations/organizations";
exports.postSocialMediaURL = "social_media_urls";
exports.getUserCommunity = "get_community";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "* All fields are mandatory.";

exports.textClose = "Close";
exports.textSave = "Save";
exports.textUpdate = "Update";
exports.textAddGroup = "Add Group";
exports.textShowGroup = "Show Group";
exports.textEdit = "Edit";
exports.textDelete = "Delete";
exports.textAccounts = "Accounts";
exports.textAddAcc = "Add Acc.";
exports.textDeleteAcc = "Delete Acc.";
exports.textId = "Id";
exports.textStatus = "Status";
exports.textActions = "Actions";
exports.textAssign = "Assign";
exports.textAssignedTo = "Assigned To";
exports.textName = "Name";
exports.textCreatedAt = "Created At";
exports.textUpdatedAt = "Updated At";
exports.textSelectAccounts = "Select accounts";
exports.updateSocialMediaApiEndpoint = "social_media_urls";
exports.updateSocialMediaApiMethod = "PUT";
exports.titleDeleteModalConfirmation = "Delete Social Media Link";
exports.descriptionDeleteModalConfirmation = "Are you sure you want to delete Social Media Link?";
exports.deleteSocialMedialApiEndpoint = "social_media_urls";
exports.deleteSocialMediaApiMethod = "DELETE";
// Customizable Area End
