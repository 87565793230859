// Customizable Area Start
import React from "react";
import { styled } from "@material-ui/core/styles";
import { Box, Button } from '@material-ui/core';
import PostActionController from "./PostActionController.web";
export const configJSON = require("./config");
import NotificationsOffOutlinedIcon from '@material-ui/icons/NotificationsOffOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { AddIcon, blueLink1, blueSend, SendFill, DisLike, More, Save, Report, editimg } from "./assets";
// Customizable Area End
// Customizable Area Start

// Customizable Area End
export default class PostAction extends PostActionController {
  // Customizable Area Start

  //for my own post
  optionForMyPost = () => {
    const notificationOn = this.props.data.attributes.notification_turn_on;
    return (
      <>
        <Box display="flex"  className="pointer" alignItems="center" data-test-id="edit-post-id" onClick={()=>this.props.editPost(this.props.data.id)}>
          <img src={editimg} alt="editimg" />
          <Box component={"p"} sx={{ fontFamily: "Verdana", fontSize: "17px", paddingLeft: "10px" }} color="#808084" data-test-id="edit-post-text">{configJSON.optionEdit}</Box>
        </Box>
        <Box   className="pointer" display="flex" alignItems="center" data-test-id="save-id" onClick={this.handleSavePost}>
          <img src={Save} alt="save" />
          <Box component={"p"} sx={{ fontFamily: "Verdana", fontSize: "17px", paddingLeft: "10px" }} color="#808084">{this.props.data.attributes.post_save_by_current_user ? configJSON.optionAlreadySave : configJSON.optionSave}</Box>
        </Box>
        <Box className="pointer" display="flex"  alignItems="center" id="notification-id" onClick={this.handleNotification}>
          {notificationOn ? <NotificationsOffOutlinedIcon className="icon-color"  /> : < NotificationsNoneOutlinedIcon className="icon-color" />}
          <Box component={"p"} sx={{ fontFamily: "Verdana", fontSize: "17px", paddingLeft: "10px" }} color="#808084">Turn {`${notificationOn ? "off" : "on"}`} {configJSON.optionNotfication}</Box>
        </Box>
        <Box  className="pointer" display="flex"  alignItems="center" id="delete-id" onClick={this.handleDeletePost}>
          <DeleteForeverOutlinedIcon className="icon-color"  />
          <Box component={"p"} color="#808084" sx={{ fontFamily: "Verdana", fontSize: "17px", paddingLeft: "10px" }} >{configJSON.optionDelete}</Box>
        
        </Box>
    
      </>
    )
  }

  //for other user's post
  optionForOtherPost = () => {
    const notificationOn = this.props.data.attributes.notification_turn_on;
    const isUser = this.props.data.attributes.account_id;

    return (<>
      <Box display="flex" alignItems="center" className="pointer" data-test-id="save-id" onClick={this.handleSavePost}>
        <img alt="save" src={Save} />
        <Box component={"p"} sx={{ fontFamily: "Verdana", fontSize: "17px", paddingLeft: "10px" }} color="#808084">{this.props.data.attributes.post_save_by_current_user ? configJSON.optionAlreadySave : configJSON.optionSave}</Box>
      </Box>
      <Box  className="pointer" display="flex" alignItems="center" id="hide-id" onClick={this.handleHidePost}>
        <DeleteForeverOutlinedIcon className="icon-color" />
        <Box component={"p"} sx={{ fontFamily: "Verdana", fontSize: "17px", paddingLeft: "10px" }} color="#808084">{configJSON.optionHidePost}</Box>
      </Box>
      <Box  className="pointer" display="flex" alignItems="center" id="notification-id" onClick={this.handleNotification}>
        {notificationOn ? <NotificationsOffOutlinedIcon className="icon-color" /> : < NotificationsNoneOutlinedIcon className="icon-color" />}
        <Box component={"p"} sx={{ fontFamily: "Verdana", fontSize: "17px", paddingLeft: "10px" }} color="#808084">Turn {notificationOn ? "off" : "on"}  {configJSON.optionNotfication}</Box>
      </Box>

      <Box display="flex"  className="pointer"  alignItems="center" data-test-id="unfollow-id" onClick={this.handleUnFollow}>
        <img alt="DisLike" src={DisLike} />
        <Box component={"p"} sx={{ fontFamily: "Verdana", fontSize: "17px", paddingLeft: "10px" }} color="#808084">{isUser ? configJSON.optionUnfollowUser : configJSON.optionUnfollowPage}</Box>
      </Box>
      <Box  className="pointer" display="flex" alignItems="center"  data-test-id="report-id" onClick={this.reportPost}>
        <img alt="Report" src={Report}  />
        <Box component={"p"} sx={{ fontFamily: "Verdana", fontSize: "17px", paddingLeft: "10px" }} color="#808084">{configJSON.optionReport}</Box>
      </Box>
    </>
    )
  }

  renderOption = () => {
    const isMine = this.props.data.attributes.is_login_user_post;
    const isFollow = this.props.flag ? this.props.communityFollow : this.props.data.attributes.is_followed_by_login_user;
    return (
      <Wrapper position="relative" className="pointer">
        {(!isFollow && !isMine) ?
        
        <Box data-test-id="add-id" className="pointer" display={"flex"} onClick={this.handleFollow}>
        {this.state.communityFlag=="false" && <><img src={AddIcon} alt="AddIcon" />
            <Button title="Add More" style={{ color: "#005487", fontWeight: 400 }} >{configJSON.btnFollow}</Button></>}
        </Box>
    :
         <Box py={"10px"} px={"15px"}>
            <img src={More}  data-test-id="more-id" id="more-id" alt="more" className="pointer" onClick={this.handleMenus} />
          </Box>}
        
        <Box position="absolute"  display={this.state.menuOpen ? "block" : "none"} bgcolor="#fff" sx={{
          width: "350px",
          right: 0,
          padding: "20px",
          borderRadius: "12px",
          boxShadow: "2px 4px 16px 4px rgba(0, 0, 0, 0.25)",
          zIndex: 2,
        }}>
          <div ref={this.menuRef}></div>
          {isMine ? this.optionForMyPost() : this.optionForOtherPost()}
        </Box>
      </Wrapper>
    )
  }

  renderShare = () => {
    return (<>
      <ShareWrapper position={"relative"}>
        <img src={SendFill}  id="sharee-id" className="pointer" data-test-id={"sharee-id"} alt="share" style={{ width: "20px" }} onClick={this.openShare} />

        {this.state.shareOpen &&
          <Box position="absolute" bgcolor="#fff" sx={{
            right: 0,
            width: "350px",
            borderRadius: "12px",
            boxSizing: "border-box",
            boxShadow: "2px 4px 16px 4px rgba(0, 0, 0, 0.25)",
            padding: "20px 20px 20px 35px",
            zIndex: 2,
          }}
          >
            <div ref={this.shareRef}></div>
            <Box display="flex" alignItems="center" className="pointer" data-test-id="copy-id" onClick={this.handleCopy}>
              <img alt="link" src={blueLink1}  />
              <Box component={"p"} sx={{ fontFamily: "Verdana", fontSize: "17px", paddingLeft: "10px" }} color="#808084">{configJSON.optionCopy}</Box>
            </Box>
            <Box  alignItems="center" display="flex" className="pointer" data-test-id="share-id" onClick={this.handleSharePost}>
              <img alt="send" src={blueSend}  />
              <Box component={"p"} sx={{ fontSize: "17px", fontFamily: "Verdana", paddingLeft: "10px" }} color="#808084">{configJSON.optionShare}</Box>
            </Box>
        
          </Box>
        }
      </ShareWrapper>

    </>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <>
        {this.props.data.type==="post" && 
        (this.props.type === "options" ? this.renderOption() : this.renderShare())}
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const Wrapper = styled(Box)({
  "& .icon-color": {
    color: "#005487"
  },
  "& .pointer": {
    cursor: "pointer"
  }
});
const ShareWrapper = styled(Box)({
  "& .pointer": {
    cursor: "pointer"
  }
});
// Customizable Area End