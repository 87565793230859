import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleClose?: () => void;
  open?: boolean;
  type?: "profile" | "homePage" 
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading: boolean;
  data: {profile_pic:string,name:string,id:string|number,followed:boolean}[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserDetailsId: string = '';
  apiCommunityListingId: string = '';
  apiFollowUnfollowId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLoading: false,
      data: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson) {
      if (apiRequestCallId === this.apiCommunityListingId) {
        this.communityListResponse(responseJson);
      } else if (apiRequestCallId === this.apiGetUserDetailsId) {
        this.getUserDetailsResponse(responseJson);
      } else if (apiRequestCallId === this.apiFollowUnfollowId) {
        this.handleFollowUnfollowResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    this.handleVariety()
  }

  handleVariety = () => {
    this.setState({isLoading:true})
    if(this.props.type === "homePage"){
      this.getUserDetails()
    } else {
      this.communityList();
    }
  }

  communityList = async () => {
    const signupToken = await getStorageData('SignupSuccessToken');
    const loginToken = await getStorageData('LoginSuccessToken');

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: signupToken || loginToken,
    };

    const communityRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    communityRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    communityRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.communities
    );

    communityRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    this.apiCommunityListingId = communityRequest.messageId;
    runEngine.sendMessage(communityRequest.id, communityRequest);
  }

  getUserDetails = async () => {
    const signupToken = await getStorageData('SignupSuccessToken');
    const loginToken = await getStorageData('LoginSuccessToken');

    const header = {
      token: signupToken || loginToken,
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.get_user
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    this.apiGetUserDetailsId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  communityListResponse = (responseJson:{data:{id:string,attributes:{profile_pic:string,is_followed_by_user:boolean,name:string}}[]}) => {
    this.setState({isLoading:false})
    if(responseJson.data?.length>0){
      const list = responseJson.data.map(item=>{
        return {profile_pic: item.attributes.profile_pic, name: item.attributes.name, id: item.id, followed: item.attributes.is_followed_by_user}
      })
      this.setState({ data: [...list] })
    }
  }

  getUserDetailsResponse = (responseJson: { data: { attributes: { selected_communities: { data: { id: string, attributes: { profile_pic: string, name: string } }[] } } } }) => {
    this.setState({isLoading:false})
    if (responseJson.data) {
      const selected_communities = responseJson.data.attributes.selected_communities.data;
      const tempList = selected_communities.map(item => {
        return { profile_pic: item.attributes.profile_pic, name: item.attributes.name, id: item.id, followed: true }
      })
      this.setState({ data: [...tempList] })
    }
  }

  handleFollowUnfollowResponse = (responseJson:{data:{message:string}}) => {
    this.setState({isLoading:false})
    if(responseJson.data){
      this.handleVariety()
      toast.success(responseJson.data.message)
    }
  }

  handleClose = () => {
    if (this.props.handleClose) {
      this.props.handleClose();
    }
  }

  followUnfollow =async (id: string|number)=>{
    const follow = this.state.data.find(item=>item.id===id)?.followed;
    this.setState({isLoading:true})
    const loginToken = await getStorageData('LoginSuccessToken');
    const signupToken = await getStorageData('SignupSuccessToken');
    const followUnfollowUser = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    followUnfollowUser.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      follow ? configJSON.remove_follower + `?community_id=${id}` : configJSON.create_followers
    );

    const header = {
      token: signupToken || loginToken,
    }
    followUnfollowUser.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      follow ? configJSON.deleteApiMethod : configJSON.postApiMethod
    );
    if(!follow){
      let body = new FormData();
      body.append("community_id", (id).toString())
      followUnfollowUser.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    }
    followUnfollowUser.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiFollowUnfollowId = followUnfollowUser.messageId;
    runEngine.sendMessage(followUnfollowUser.id, followUnfollowUser);
  }

  handleNavigation = (id:string|number) => {
    if(this.props.type === "homePage"){
      this.props.navigation.navigate("OtherUserProfile",{id:`${id}-community`})
    }
  }
  // Customizable Area End
}
