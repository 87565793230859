// Customizable Area Start
import React from "react";
import { Box, TextField, Avatar, FormHelperText,Button } from '@material-ui/core';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CreatePostFinalModalController, { Props } from "./CreatePostFinalModalController.web";
import { CameraIcon, CloseIcon, arrowDown, editwhite, globalIcon } from "./assets";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

// Customizable Area Start
const Poststyles = (theme: Theme) =>
    createStyles({
        inputFieldTextArea: {
            backgroundColor: "#f4f4f4",
            borderRadius: "12px",
            "& .MuiOutlinedInput-notchedOutline": {
                border: 0
            }
        },
        heightResponsive: {
            overflowY: "auto",
            maxHeight: "85%",
        },
        communityPic: {
            height: "50px",
            width: "50px",
            borderRadius: "3px"
        },
        profilePic: {
            height: "50px",
            width: "50px",
            borderRadius: "50%"
        },
        error: {
            color: 'red',
            fontSize: "12px",
            fontFamily: "Verdana",
        },
        thumbnail: {
            width: "100%",
            borderRadius: "12px",
            maxHeight: "306px"
        },
        pointer: {
            cursor: "pointer"
        },
        inputHide: {
            display: "none"
        },
        removeIcon: {
            cursor: "pointer",
            position: 'absolute',
            top: '10px',
            right: '10px'
        },
        PublishbtnExpand: {
            height: "48px",
            fontSize: "16px",
            fontWeight: "bold",
            color: "#fff",
            width: "160px",
            fontFamily: "Verdana",
            backgroundColor: "#005487",
            textTransform: "none",
            "&:hover": {
                backgroundColor: "#005487"
            }
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
    })
// Customizable Area End

 export class CreatePostFinalModal extends CreatePostFinalModalController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
             // Customizable Area Start
             <Modal
                 aria-labelledby="transition-modal-title"
                 aria-describedby="transition-modal-description"
                 open={this.props.finalPost}
                 closeAfterTransition
                 BackdropComponent={Backdrop}
                 BackdropProps={{
                     timeout: 200,
                 }}
                className={this.props.classes.modal}
                 onClose={this.props.handleClose}
            > 
                <Fade in={this.props.finalPost}>
                        <Box bgcolor={"#fff"} sx={{ width: { xs: "80%", md: "70%", lg: "50%" }, maxWidth: "604px" }} borderRadius="12px" className={this.props.classes.heightResponsive}>
                        {this.state.isLoading && <Loader loading={this.state.isLoading} />}
                            <Box display={"flex"} justifyContent={"space-between"} p={"24px"} borderBottom={"1px solid #808080"}>
                                <Box component={"h4"} fontSize={"20px"} fontFamily={"Verdana"} fontWeight={"bold"} color={"#808080"} m={0} data-test-id="title">
                                    Create Post
                                </Box>
                                <img src={CloseIcon} alt="close" className={this.props.classes.pointer} onClick={this.props.handleClose} />
                            </Box>
                            <Box p={"20px 24px"} >
                                <Box display="flex" mb={'16px'}>
                                <Avatar src={this.props.info.image} className={this.props.info.type === 'account' ? this.props.classes.profilePic : this.props.classes.communityPic} />
                                    <Box ml="12px">
                                        <Box component={"p"} fontFamily={"Verdana"} fontSize={"17px"} fontWeight={"bold"} m={0}>{this.props.info.name}</Box>
                                        <Box component={"p"} fontFamily={"Verdana"} fontSize={"14px"} fontWeight={"normal"} m={0}mt={"3px"} display={"flex"} alignItems={"center"} gridGap={"5px"}><img src={globalIcon}/> {this.props.info.is_private ? "Private" : "Public"} <img src={arrowDown}/></Box>
                                    </Box>
                                </Box>
                                <TextField
                                    data-test-id="description"
                                    placeholder="Add post description here"
                                    type="text"
                                    minRows={3}
                                    error={Boolean(this.state.error.description)}
                                    name="description"
                                    variant="outlined"
                                    value={this.state.data.description}
                                    onChange={this.handleChangeDescription}
                                    multiline
                                    fullWidth
                                    className={this.props.classes.inputFieldTextArea}
                                />
                                {this.state.error.description && (
                                    <FormHelperText className={this.props.classes.error}>
                                        {this.state.error.description}
                                    </FormHelperText>
                                )}
                                <Box component="div" className={this.props.classes.pointer} position={'relative'} display="flex" data-test-id="drop-id" flexDirection="column" alignItems="center" justifyContent="center" borderRadius={"12px"} mt={"20px"} bgcolor={"#f4f4f4"} sx={{ height: this.state.data.images_and_videos.length > 0 ? "" : "160px", maxHeight: "306px" }} gridGap={"7px"}
                                    onDrop={(e) => {
                                        this.handleOnImportFile(e.dataTransfer.files)
                                        e.preventDefault();
                                    }}
                                    onClick={this.handleOpenUploadBtn}
                                    onDragOver={e => e.preventDefault()}>
                                    <input type='file' data-test-id="fileInput" id="fileInput" className={this.props.classes.inputHide}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.handleOnImportFile(e.target.files!)
                                            e.preventDefault();
                                        }}
                                        accept="image/png, image/jpeg, image/jpg,"
                                    />
                                    <img className={this.props.classes.removeIcon} src={editwhite} alt="close" onClick={this.handleOpenUploadBtn} />
                                    {this.state.data.images_and_videos.length > 0 ?
                                        <img src={this.state.data.images_and_videos[0]} className={this.props.classes.thumbnail} alt="thhumbnail" />
                                        : <>
                                            <img src={CameraIcon} alt="camera" />
                                            <Box component={"p"} fontFamily={"Verdana"} fontWeight={"bold"} color="#808080" fontSize={"17px"} m={0} textAlign={"center"}>Upload Thumbnail Image</Box>
                                            <Box component={"p"} fontFamily={"Verdana"} fontWeight={"normal"} color="#808080" fontSize={"17px"} m={0} textAlign={"center"}>Upload cover image minimum width <br />400 pixel (16:9).</Box>
                                        </>}
                                </Box>
                                {this.state.error.images_and_videos && (
                                    <FormHelperText className={this.props.classes.error}>
                                        {this.state.error.images_and_videos}
                                    </FormHelperText>
                                )}
                            </Box>
                            <Box display="flex" justifyContent="end" alignItems={"end"} mt={"20px"} borderTop={"1px solid #808080"} p={"23px"}>
                                <Button variant="contained" type="submit" data-test-id="publish-btn" onClick={this.handlePublish} className={this.props.classes.PublishbtnExpand}>Done</Button>
                            </Box>
                        </Box>
                </Fade>
            </Modal>
        // Customizable Area End
        )
    }
}

// Customizable Area Start

export default withStyles(Poststyles, { withTheme: true })(CreatePostFinalModal);
// Customizable Area End