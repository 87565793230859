import React from "react";
// Customizable Area Start
import "./SavePost.web.css";
import SavePostController, { Props, ResponseSavePost, configJSON } from "./SavePostController.web";
import { leftarrow, Play, LinkSvg, GallerySlash } from "./assets"
import {
  Grid,
  CardMedia,
  Card,
  Box,
} from "@material-ui/core";
import Loader from "../../../components/src/Loader.web";
import { styled } from "@material-ui/core/styles";
// Customizable Area End


export default class SavePost extends SavePostController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCard = (image: string, altImage: string) => {
    
    const img = image ? image : altImage
    return (
      <Card className="SavePostCard">
         <img
          alt = "post-image"
          src={img}
          className={image ? "SavePostCardmedia" : "SavePostCardmediaAlt"}
        />
      </Card>
    );
  }

  renderTopNav = () => {
    return (
      <Box className="Box_image_style" px={"20px"}>
            <div className="SavePostTopLeftContainer">
              <img 
                data-test-id="goBackButton"
                src={leftarrow}
                alt="left-arrow"
                className="Left-arrow"
                onClick={this.goBackButton} />
              <span className="SavePostHeading">{configJSON.saveText}</span>
            </div>
      </Box>
    );
  };
  
  renderSavedPostTabsContent = () => {
    return (
      <Box className="Box_header" px={"18px"}>
        <GridMaincontainer container spacing={2}>
          {this.state.getSavePost.length > 0 ?
            this.state.getSavePost.map((item: ResponseSavePost) => {
            return (
              <React.Fragment key={item.id}>
                {
                  (item.attributes.post_type === "url") && (
                    <Grid 
                    data-test-id = "navigateUrl" 
                    item xs={6} 
                    sm={4} 
                    md={4} 
                    lg={3} 
                    onClick={() => this.navigatePosts(item.id)}>
                      {this.renderCard(item.attributes.thumbnail ?? "", LinkSvg)}
                    </Grid>
                  )
                }
                {(item.attributes.post_type === "image") && (
                  
                  <Grid 
                  data-test-id = "navigateimage"
                  item xs={6} 
                  sm={4} 
                  md={4} 
                  lg={3}  
                  onClick={() => this.navigatePosts(item.id)}>
                     {this.renderCard(this.destuctureImageVideo(item.attributes.images_and_videos),GallerySlash)}
                  </Grid>
                )}
                {(item.attributes.post_type === "text") && (
                  <Grid 
                  data-test-id = "navigatetext"
                  item xs={6} 
                  sm={4} 
                  md={4} 
                  lg={3} 
                  onClick={() => this.navigatePosts(item.id)}>
                    {this.renderCard("",GallerySlash)}
                  </Grid>
                )}
                {(item.attributes.post_type === "Vimeo" || item.attributes.post_type === "YouTube") &&
                  <Grid 
                  data-test-id = "navigateVimeo"
                  item xs={6} 
                  sm={4} 
                  lg={3}
                  md={4} 
                  onClick={() => this.navigatePosts(item.id)}>
                    <Card className="SavePostCard VideoPosition">
                    <CardMedia
                      component="image"
                      image={item.attributes.video_thumbnail ?? ""}
                      className="SavePostCardmedia"
                    />
                    <img 
                    src={Play} 
                    alt="play" 
                    className="SavePostGalleryPlayIcon" /> 
                    </Card>
                  </Grid>
                }

                {item.type === "event" && (
                  <Grid 
                  data-test-id = "navigateEvent"
                  item xs={6} 
                  sm={4} 
                  lg={3} 
                  md={4} 
                  onClick={() => this.navigateEvent(item.id)}>
                    {this.renderCard(item.attributes.cover_image,GallerySlash)}
                  </Grid>
                )}
              </React.Fragment>
            );
          })
        :<Box component="p" fontFamily={"Verdana"}>{configJSON.save_post_error}</Box>}
        </GridMaincontainer>
      </Box>
    );
  };
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box className="SavePostContainer" >
        {this.state.loading && <Loader loading={this.state.loading} />}
        {this.renderTopNav()}
        {this.renderSavedPostTabsContent()}
      </Box>
      // Customizable Area End
    );

  }
}

// Customizable Area Start
const GridMaincontainer = styled(Grid)({
  maxWidth: "100%",
  maxHeight: "720px",
  overflowY: "auto",
  "& .SavePostCard":{
    cursor: "pointer"
  }
})

// Customizable Area End
