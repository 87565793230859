Object.defineProperty(exports, "__esModule", {
    value: true
  });
  
  // Customizable Area Start
  exports.validationApiContentType = "application/json";
  exports.validationApiMethodType = "GET";
  exports.exampleAPiEndPoint = "EXAMPLE_URL";
  exports.submitAssessementTestAPiMethod = "GET";
  exports.submitAssessmentApiContentType = "application/json";
  exports.AssessementResultTestUrl = `bx_block_assessmenttest/user_all_assessments/`;
  exports.textInputPlaceHolder = "Enter Text";
  exports.labelTitleText = "AssessmentTest";
  exports.labelBodyText = "AssessmentTest Body";
  exports.labelAverageScore = "Average Score";
  exports.labelNumberOfTests = "No. of Test Taken";
  exports.labelCompleted = "Completed";
  exports.labelUpcoming = "Upcoming";
  exports.getAssessmentTestListApiEndpoint = "bx_block_questionbank/account_answers/get_quizzes";
  exports.getAssessmentTestListApiMethod = "GET";
  exports.labelTakeANewTest = "Take a New Test";
  exports.getBarcDataApiEndpoint = 'bx_block_questionbank/account_answers/barc_quiz_details'
  exports.getAdminDashboardDataApiMethod = "GET"
  exports.getAdminDashboardDataApiEndpoint =  "bx_block_questionbank/quizzes/fetch_dashboard_details"
  exports.getBarcDataApiMethod = 'GET'
  exports.getAssessmentDataApiMethod = 'GET'
  exports.getAssessmentDataApiEndpoint = 'bx_block_questionbank/account_answers/get_quiz_detail'
  exports.datesLable = 'Dates'
  exports.noAttemptedDataMessage = "No attempted data."
  exports.noAssessmentScoreLable = "No BARC assesment score found."
  exports.noAverageScoreLabel = "No average Score found."

  exports.dateLable = 'Date'
  exports.usernameLable = 'Username'
  exports.scoresLable = 'Scores'
  exports.viewTestLable = "View Test"
  exports.createTestLable = "Create Test"
  exports.oveallAverageScoreLable = "Overall Average Score";
  exports.barc_dashboard_label="BARC Dashboard";
    // Customizable Area End
