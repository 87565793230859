// Customizable Area Start
import React from "react";
import { Typography, Box, Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import ChatViewController, { Props } from "./ChatViewController";
export const configJSON = require("./config");
import { styled } from "@material-ui/core/styles";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class ConfirmationModel extends ChatViewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        return (
            <Wrapper
                open={this.props.open ?? false}
                onClose={this.props.handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: "100%", height: "100%" }}>
                    <Box sx={{ width: { xs: "80%", sm: "70%", md: "50%", lg: "35%" } }} bgcolor="#fff" borderRadius="12px">
                        <Box sx={{ border: "1px solid black", margin: "20px", padding: "20px", borderRadius: "12px " }}>
                            <Box sx={{ margin: "auto", height: "340px" }}>
                                <Typography variant="h4" style={webStyle.modalTitle}>
                                    {this.props.type === "exit" ? configJSON.optionExit : configJSON.optionDelete}
                                </Typography>
                                <Box component="p" color="#808080" fontFamily="Verdana" fontSize={"17px"}>
                                    {this.props.type === "exit" ? configJSON.exitGroupMessage : configJSON.deleteGroupMessage}
                                </Box>
                            </Box>
                            <Box sx={{ flexDirection: { xs: "column", md: "row", width: "90%", margin: "auto" } }} alignItems="center" display="flex" justifyContent="space-around">
                                <Button
                                    data-test-id="no-id"
                                    className={"PublishbtnTwo"}
                                    type="submit"
                                    onClick={this.props.handleClose}
                                >No</Button>
                                <Button
                                    data-test-id="yes-id"
                                    className={"Publishbtn"}
                                    type="submit"
                                    onClick={this.props.handleAccept}
                                >Yes</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Wrapper>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
const Wrapper = styled(Modal)({
    "& .Publishbtn": {
        color: "#01548a",
        height: "50px",
        border: "solid 1px #005487",
        width: "40%",
        backgroundColor: "#fff",
        fontWeight: "bold",
        marginTop: "8px",
        "&:active": {
            backgroundColor: "#01548a",
            color: "#fff"
        },
    },
    "& .PublishbtnTwo": {
        color: "#01548a",
        height: "50px",
        width: "40%",
        border: "solid 1px #005487",
        backgroundColor: "#fff",
        marginTop: "8px",
        fontWeight: "bold",
        "&:active": {
            backgroundColor: "#01548a",
            color: "#fff"
        },
    },
});
const webStyle = {
    modalTitle: {
        fontWeight: "bold",
        fontSize: "18px ",
        color: "#005487",
        fontFamily: "Verdana",
        textTransform: "capitalize",
    } as const,
    modalSubTitle: {
        fontSize: "17px",
        color: "#808080",
        fontFamily: "Verdana",
        margin: "20px 0 150px"
    } as const,
};
// Customizable Area End
