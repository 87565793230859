// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { sendAPIRequest } from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  open: boolean;
  handleClose: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  menuItems: { id: number, value: string, label: string }[];
  notificationValue: string;
  notificationLabel: string;
  loading: boolean;
  followerList: { id: string, attributes: { profile_picture: string, full_name: string } }[];
  radioValues: string[];
  typedMessage: {
    title: string
    description: string
  };
  errors: {
    title: string,
    description: string,
    notification: string,
  };
  confirmation: string;
  error: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BroadcastController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getFollowersApi: string = "";
  sendMessageApi: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      notificationValue: "",
      notificationLabel:"",
      confirmation: "",
      loading: false,
      menuItems: [
        {
          id: 1,
          label: "Push Notification",
          value: "push_notification",
        },
        {
          id: 2,
          label: "E-mail Notification",
          value: "email_notification",
        },
      ],
      followerList: [],
      radioValues: [],
      typedMessage: {
        title: "",
        description: ""
      },
      errors: {
        title: "",
        description: "",
        notification: "",
      },
      error: ""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId1 = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson1 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId1 === this.getFollowersApi) return this.getFollowersResponse(responseJson1);
      if (apiRequestCallId1 === this.sendMessageApi) return this.sendMessageResponse();
    }
  }

  getFollowersResponse = (responseJson: { data: [{ id: string, attributes: { profile_picture: string, full_name: string } }], errors: unknown }) => {
    this.setState({loading:false})
    if (responseJson.errors) return this.setState({error:"members not found"})
    this.setState({ followerList: responseJson.data,error:"" });
  }
  sendMessageResponse = () => {
    this.setState({ confirmation: "third" })
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    this.getFollowers();
  }

  closeModel = () => {
    this.props.handleClose();
    this.setState({notificationValue:"",confirmation:"",typedMessage:{title:"",description:""},errors:{title:"",description:"",notification:""},error:"",notificationLabel:"",radioValues:[]})
  }

  renderValue = (selected: string) => {
    if (!selected || selected.length === 0) {
      return "Select Notification Type";
    }
    return selected;
  };

  handleChangeDropdownValue = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    const label = this.state.menuItems.find(item=>item.value===value);
    this.setState({ notificationValue: value, notificationLabel: label ? label.label : "", errors:{...this.state.errors,notification:""} });
  };

  handleChangeTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ typedMessage: { ...this.state.typedMessage, [e.target.name]: e.target.value }, errors: { ...this.state.errors, [e.target.name]: "" } })
  }

  handleRadioChange = (e: string) => {
    const selectedValue = e;
    this.setState((prevState) => {
      let updatedRadioValues: string[] = [];

      if (prevState.radioValues.includes(selectedValue)) {
        // If the selected value is already in the radioValues, remove it
        updatedRadioValues = prevState.radioValues.filter(
          (value) => value !== selectedValue
        );
      } else {
        // Otherwise, add the selected value to the radioValues
        updatedRadioValues = [...prevState.radioValues, selectedValue];
      }
      return {
        radioValues: updatedRadioValues,
      };
    });
  };

  changeConfirmation = (e: string) => {
    if (e === "second") {
      let error = { title: "", description: "", notification: "" };
      if (!this.state.typedMessage.title) {
        error.title = "please enter title";
      }
      if (!this.state.typedMessage.description) {
        error.description = "please enter description";
      }
      if (!this.state.notificationValue) {
        error.notification = "please select notification type";
      }
      if (error.title || error.description || error.notification) {
        // If there are errors, update the state with error messages
        return this.setState({ errors: error });
      }
    }
    this.setState({ confirmation: e })
  }

  sendMessage = async () => {
    const signupTokn = await getStorageData('SignupSuccessToken');
    const loginTokn = await getStorageData('LoginSuccessToken');
    let body = new FormData();
    body.append("notification_type", this.state.notificationValue);
    body.append("title", this.state.typedMessage.title);
    body.append("description", this.state.typedMessage.description);
    this.state.radioValues.forEach(item => body.append("account_ids[]", item));
    this.sendMessageApi = sendAPIRequest("broadcast_message", {
      method: "POST",
      body: body,
      headers: {
        token: signupTokn || loginTokn
      }
    })
  }

  getFollowers = async () => {
    this.setState({loading:true})
    const profileData = await getStorageData("profileData",true);
    const signupTokn = await getStorageData('SignupSuccessToken');
    const loginTokn = await getStorageData('LoginSuccessToken');
    const endPoint = `user_followers_list?followed_id=${profileData.id}&type=AccountBlock::Community`
    this.getFollowersApi = sendAPIRequest(endPoint, {
      method: "GET",
      headers: {
        token: signupTokn || loginTokn
      }
    })
  }

  isMobile = () =>{
    return window.innerWidth <= 600;
  }
  // Customizable Area End
}
