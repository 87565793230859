export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Lock= require("../assets/lock.svg")
export const Privacy = require("../assets/privacy.svg")
export const Notification = require("../assets/notification.svg")
export const Logout = require("../assets/logout.svg")
export const Support = require("../assets/support.svg")
export const Deactive = require("../assets/deactive.svg")
export const Eye= require("../assets/eye.svg")
export const Hideeye = require("../assets/hideeye.svg")
export const Profile = require("../assets/profile.svg")
