import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import {
  jpegExtension,
  jpgExtension,
  pdfExtension,
  pngExtension,
} from "./assets";
import React from "react";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

export interface IPreviewModalProps {
  url: string | null;
  mimeType: string | null;
}

export interface IAchievenment {
  id: string;
  type: string;
  attributes: {
    title: string | null;
    organisation_name: string | null;
    issue_month: number | null;
    issue_year: number | null;
    expiration_month: number | null;
    expiration_year: number | null;
    ceu_gained: string | null;
    description: string | null;
    credential_url: string | null;
    is_bulk_upload: boolean;
    account_id: number;
    community_id: string | null;
    full_name: string;
    profile_picture: string;
    achievement_files: {
      data: {
        id: string;
        type: string;
        attributes: {
          title: string;
          description: string;
          achievement_images: {
            file_exetension: string;
            url: string;
            file_name: string;
          }[];
        };
      }[];
    };
    custom_form_file: string | null;
    custom_form_file_extension: string | null;
    curruntId?: string;
    custom_form_file_name: string | null;
  };
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  communityDesc?: string;
  classes?: any;
  data: IAchievenment[];
  isOwnProfile: boolean;
  removeAchievement?: (param: string) => void;
  isMainListing?: boolean;
  navigateCommunity?: () => void;
  flagged?: boolean;
  isPrivate?: boolean;
  otherProfile?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: {};
  deleteParam: string;
  showDeleteModal: boolean;
  showPreviewModal: boolean;
  previewData: IPreviewModalProps;
  communityData: any;
  pathRoute: boolean;
  communityFlag: any;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start

export default class AchievementSectionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];
    this.state = {
      data: {},
      deleteParam: "",
      showDeleteModal: false,
      showPreviewModal: false,
      previewData: { url: "", mimeType: "" },
      communityData: {},
      pathRoute: false,
      communityFlag: "",
    };
    // Customizable Area End
  }

  async receive(_from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    // Customizable Area End
  }

  async componentDidMount() {
    if (localStorage.getItem("communityData") != "") {
      let parsedData = localStorage.getItem("communityData");
      if (parsedData != null) {
        this.setState({ communityData: JSON.parse(parsedData) });
      }
    }
    if (localStorage.getItem("community") == "true") {
      this.setState({
        pathRoute: window.location.pathname
          .toLowerCase()
          .includes("/view-profile"),
      });
    }
  }
  convertNumericMonthtoString = (number: number | null) => {
    const months = [
      "-", // 0
      "Jan", // 1
      "Feb", // 2
      "Mar", // 3
      "Apr", // 4
      "May", // 5
      "Jun", // 6
      "Jul", // 7
      "Aug", // 8
      "Sep", // 9
      "Oct", // 10
      "Nov", // 11
      "Dec", // 12
    ];

    return months[number ?? 0];
  };

  getTransformedData = (datas: IAchievenment[]) => {
    const transformedData: IAchievenment[] = [];
    datas.forEach((data) => {
      if (data?.attributes?.is_bulk_upload) {
        data.attributes.achievement_files.data.forEach((bulkUploadData) => {
          transformedData.push({
            ...data,
            attributes: {
              ...data.attributes,
              title: bulkUploadData.attributes.title,
              description: bulkUploadData.attributes.description,
              custom_form_file_extension:
                bulkUploadData.attributes.achievement_images[0].file_exetension,
              custom_form_file:
                bulkUploadData.attributes.achievement_images[0].url,
              curruntId: bulkUploadData.id, //id of achivenment inside bulk
            },
          });
        });
      } else {
        transformedData.push({
          ...data,
          attributes: {
            ...data?.attributes,
            custom_form_file_extension:
              data?.attributes?.custom_form_file_extension,
            custom_form_file: data?.attributes?.custom_form_file,
            curruntId: "",
          },
        });
      }
    });

    return transformedData;
  };

  handleRemoveAchievementClick = (mainId: string, subId?: string) => {
    if (subId) {
      this.setState({
        deleteParam: `/${mainId}?bulk_achievement_id=${subId}`,
        showDeleteModal: true,
      });
    } else {
      this.setState({
        deleteParam: `/${mainId}`,
        showDeleteModal: true,
      });
    }
  };

  handlePreviewClick = (data: IPreviewModalProps) => {
    this.setState({
      previewData: data,
      showPreviewModal: true,
    });
  };
  handleRemoveModalClose = () => {
    this.setState({
      deleteParam: "",
      showDeleteModal: false,
    });
  };

  handlePreviewModalClose = () => {
    this.setState({
      showPreviewModal: false,
    });
  };

  handleRemoveAchievementConfirmation = () => {
    if (this.props.removeAchievement && this.state.deleteParam) {
      this.props.removeAchievement(this.state.deleteParam);
      this.handleRemoveModalClose();
    }
  };

  returnAchievementLogo = (type: string | null) => {
    let src;
    switch (type) {
      case "jpeg":
        src = jpegExtension;
        break;
      case "jpg":
        src = jpgExtension;
        break;
      case "png":
        src = pngExtension;
        break;
      case "pdf":
        src = pdfExtension;
        break;
    }
    return <img src={src} alt="Certificate" width="44px" height="44px" />;
  };

  handleEdit = (id: string, subId?: string) => {
    const temp = subId ? `${id}-${subId}` : id;
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Achievements"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), temp);
    this.send(message);
  };

  handleNavigation = () => {
    const messag = new Message(getName(MessageEnum.NavigationMessage));
    messag.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Achievements"
    );

    messag.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(messag);
  };
}
// Customizable Area End
