// Customizable Area Start
import React from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box, Grid, CircularProgress,Tabs, Tab, Avatar } from '@material-ui/core';
import { createStyles, withStyles, Theme ,styled} from "@material-ui/core/styles";
import LandingPageNewController, { IEvent, Props } from "./LandingScreenNewController.web";
import CreatePost from "../../postcreation/src/CreatePostNew.web";
import NewPostBlock from "../../postcreation/src/NewPostBlock.web";
import Loader from "../../../components/src/Loader.web";
import "./LandingScreenNew.web.css"
import { AvatarGroup } from '@material-ui/lab';
import moment from "moment";
import  CreatePostLinkModal  from "../../postcreation/src/CreatePostLinkModal.web";
import VideoTypePostStepTwoWeb from "../../postcreation/src/VideoTypePostStepTwo.web";
import CreatePostFinalModalWeb from "../../postcreation/src/CreatePostFinalModal.web";
import CreatePostMainModal from "../../postcreation/src/CreatePostMainModal.web";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

const styles = (theme: Theme) =>
    createStyles({
        nabvar: {
            background: "#01548a",
        },
        title: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        userCard: {
            textAlign: "center",
            boxShadow: "none",
            border: "1px solid #808080",
            width: "74%",
            margin: "0 auto",
            borderRadius:"12px"
        },
        userCommunity: {
            background: "transparent",
            boxShadow: "none",
            border: "1px solid #808080",
            width: "74%",
            margin: "0 auto",
            borderRadius:"12px"
        },
        media:{
            display:"flex",
            alignItems:"center",
            justifyContent:"center"
        },
        avatar: {
            width: "150px",
            height: "150px",
            objectFit:"contain"
        },
        avatarImg:{
            width:"inherit",
            height:"inherit"
        },
        mediaPost: {
            maxWidth: "100%",
            height: "300px",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
        },
        line: {
            fontSize: "40px",
            color: "lightgrey"
        },
        create: {
            border: 'none', "& fieldset": { border: 'none' },
            borderRadius: "10px",
            background: "#F4F4F4",
            height: "30px",
            padding: "10px",
            width: "100%"
        },
        Icons: {
            display: "flex",
            justifyContent: "space-around"
        },
        iconBtn: {
            '&:hover': {
                // backgroundColor: "none",
                borderRadius: "0px"
            },
        },
        postCards:{
            boxShadow: "none",
            position:"relative",
            borderRadius:"12px"
        },
        postComment:{
            borderBottomLeftRadius:"12px",
            borderBottomRightRadius:"12px",
            padding:"10px"
        },
        messComment:{
            outline: "0px solid",
            border:"none",
            padding:"5px",
            width:"97%",
        },
        upcomingCommunityTypo:{
            marginLeft:"5%"
        }
    })


// Customizable Area End

export class LandingScreenNew extends LandingPageNewController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }

    renderEventSection = () => {
        return (
            <ScrollerWrapper>
                <InfiniteScroll
                    dataLength={this.state.postList?.length}
                    next={this.getPostList}
                    className="scoller-infinite"
                    hasMore={this.state.newPostListCount > 0}
                    loader={<Box display={"flex"} justifyContent={"center"}><CircularProgress /></Box>}
                >
                    {(this.props.filter && this.state.filter === 'events') &&
                        this.state.postList
                            .filter(item => "attributes" in item && "event_type" in item.attributes)
                            .map((ite) => {
                                const item = ite as IEvent; // Assuming IEventAttributes is the type for item.attributes
                                return (
                                    <EventContainer container data-test-id={`eventData_${item.id}`} onClick={() => this.navigateToEvents(item.id)}>
                                        <Grid className="GridCardImgContainer" item xs={5}>
                                            {item.attributes.cover_image && <img className="CardImg" src={item.attributes.cover_image} alt={"event-img"} />}
                                            <div className="GradientBox"></div>
                                        </Grid>
                                        <Grid className="GridContentContainer" item xs={7}>
                                            <Box display={"flex"} justifyContent={"space-between"}>
                                                <Box>
                                                    <Box component={"h4"} m={0} sx={{ fontSize: { xs: "18px", sm: "24px" } }} fontWeight={"bold"} fontFamily={"verdana"}>
                                                        {item.attributes.event_name.length > 15 ?
                                                            item.attributes.event_name.slice(0, 15) + '...' :
                                                            item.attributes.event_name
                                                        }
                                                    </Box>
                                                    <Box component={"p"} m={0} mt={"3px"} sx={{ fontSize: { xs: "14px", sm: "18px" } }} fontWeight={"normal"} color="#808080" fontFamily={"verdana"}>
                                                        {item.attributes.full_name ? item.attributes.full_name : item.attributes.community_name}
                                                    </Box>
                                                </Box>
                                                <Box component={"p"} fontFamily={"Verdana"} fontSize={"12px"} fontWeight={"bold"} color={"#005487"} m={0} textAlign={"center"}>
                                                    {moment(new Date(item.attributes.start_date)).format("DD")}<br />
                                                    {moment(new Date(item.attributes.start_date)).format("MMMM").toUpperCase().slice(0, 3)}
                                                </Box>
                                            </Box>
                                            <Box>
                                                <AvatarGroup spacing={20} max={3}>
                                                    {item.attributes.interest_event_count[0].account_images.map((img) => {
                                                        return (
                                                            <Avatar src={String(img.photo)} key={String(img.photo)} />
                                                        )
                                                    })}
                                                </AvatarGroup>
                                            </Box>
                                        </Grid>
                                    </EventContainer>
                                );
                            })}
                </InfiniteScroll>
            </ScrollerWrapper>
        )
    }

    renderModals = () => {
        return (
            <Box width={"100%"} mb={"16px"}>
                {this.state.info.image && <CreatePost getItem={this.getItem} id={""} navigation={""} info={this.state.info}/>}
                {this.state.modal.hyperLinkModal && <CreatePostLinkModal linkOpen={this.state.modal.hyperLinkModal} getItem={this.getItem} postId={this.state.postId} navigation={this.props.navigation} id={''} handleClose={this.closeModal} info={this.state.info}/>}
                {this.state.modal.videoModal && <VideoTypePostStepTwoWeb open={Boolean(this.state.modal.videoModal)} type={this.state.modal.videoModal} getItem={this.getItem} postId={this.state.postId} navigation={this.props.navigation} id={''} handleClose={this.closeModal} info={this.state.info}/>}
                {this.state.modal.editModal && <CreatePostFinalModalWeb finalPost={this.state.modal.editModal} getItem={this.getItem} postId={this.state.postId} navigation={this.props.navigation} id={''} handleClose={this.closeModal} data={{ images_and_videos: [], description: '' }} selectedFile={null} info={this.state.info}/>}
                {this.state.modal.textModal && <CreatePostMainModal openModal={this.state.modal.textModal} navigation={this.props.navigation} postId={this.state.postId} getItem={this.getItem} handleClose={this.closeModal} info={this.state.info}/>}
            </Box>
        )
    }
    // Customizable Area End

    render() {

        // Customizable Area Start
        const { classes } = this.props
      
        return (
            <Box className="LandingScreenMain">
                {this.state.loading && <Loader loading={this.state.loading} />}
                                    {this.renderModals()}
                                    {this.props.filter && <TabsContainer marginY={"16px"}>
                                        <Tabs
                                            value={this.state.tabValue}
                                            onChange={this.handleChangeTab}
                                            variant="scrollable"
                                            data-test-id="tabs-list"
                                            scrollButtons="on"
                                            indicatorColor="primary"
                                            textColor="primary"
                                            aria-label="scrollable force tabs example"
                                        >
                                            <Tab label="Home"  {...this.a11yProps(0)} />
                                            <Tab label="Photo"  {...this.a11yProps(1)} />
                                            <Tab label="Video"  {...this.a11yProps(2)} />
                                            <Tab label="Event"  {...this.a11yProps(3)} />
                                            <Tab label="URL"  {...this.a11yProps(4)} />
                                        </Tabs>
                                    </TabsContainer>}
{( this.state.filter !== 'events') ?
<ScrollerWrapper>
<InfiniteScroll
    dataLength={this.state?.postList?.length}
    next={this.getPostList}
    className="scoller-infinite"
    hasMore={this.state.newPostListCount > 0}
    loader={<Box display={"flex"} justifyContent={"center"}><CircularProgress/></Box>}
  >
    {
           this.state.postList&&this.state.postList.length>0 && this.state.postList.map((item: {[keys:string]:string|number|any|{[keys:string]:string|number|any}}|any, i: number) => {
            const src = this.handleGetPostItemSrc(item)

            const profile = this.handleGetPostItemUserProfilePic(item)
            return (
                <NewPostBlock
                    key={`${item.id+i}`}
                    data={item}
                    followUpdatedList={this.followUpdateList}
                    deletePost={this.deletePost}
                    editPost={this.editedPost}
                    navigation={this.props.navigation}
                    getPostList={this.getPostList}
                    videoTitle={item.attributes.video_title}
                    postCards={classes.postCards}
                    mediaPost={classes.mediaPost}
                    postComment={classes.postComment}
                    id={i}
                    profile={profile}
                    fullName={item.attributes?.full_name?item.attributes?.full_name:item.attributes?.community_name}
                    description={item.attributes.description}
                    createdAt={item.attributes.created_at || item.attributes?.account.data.attributes.created_at}
                    itemType={item.type}
                    url={item.attributes.url}
                    src={src}
                    eventName={item.attributes.event_name}
                    eventDescription={item.attributes.description}
                    startDate={item.attributes.start_date}
                    endDate={item.attributes.end_date}
                    startTime={item.attributes.start_time}
                    endTime={item.attributes.end_time}
                    eventType={item.attributes.event_type}
                    liked={item.like}
                    postType={item.attributes.post_type || item.type}
                    PostId={item.attributes.id}
                    postCommented={item.commentOpen}
                   
                    followUpdatedCommunityTab={()=>{}}
                    postlikecount={item.attributes.post_like_count}
                    // commentData={item?.attributes?.comments}
                    messComment={classes.messComment} 
                    handleInterestedEvent={this.handleInterestedEvent}
                    dataItem={item}
                    eventDetailNavigation={this.eventDetailNavigation}
                    history={undefined}  
                    interestedBtnHighlight={this.state.is_Interested}                                        />
            )

        })
    }

                                      </InfiniteScroll>
                    </ScrollerWrapper>
                    :
                        this.renderEventSection()
                }

            </Box>
        );
        // Customizable Area End
    }

}


// Customizable Area Start

const TabsContainer = styled(Box)({
    maxWidth: "100%",
    "& .MuiTabs-flexContainer":{
      justifyContent: "space-between"
    },
    "& .MuiTab-root":{
      maxWidth: "135px",
      minWidth:"100px"
    },
    "& .Mui-selected": {
      color: "#005487"
    },
    "& .PrivateTabIndicator": {
      backgroundColor: "#005487",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#005487"
    },
    "& .MuiTabs-root":{
      alignItems: "center",
      height:"80px",
      backgroundColor: "#fff",
      borderRadius: "12px"
    }
  })

const ScrollerWrapper = styled('div')({
    "& .scoller-infinite":{
        overflow: 'hidden !important',
        display: "flex",
        flexDirection: "column",
        gap:"16px",
    }
})
const EventContainer = styled(Grid)({
    height: "140px",
    display: "flex",
    width:"auto",
    justifyContent: "flex-end",
    borderRadius: "15px",
    overflow: "hidden",
    marginInline: "2px",
    boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.25)",
    cursor: "pointer",
    "& .GradientBox":{
        position: "absolute",
        top: "0px",
        height: "100%",
        width: "100%",
        background:"linear-gradient(-90deg,#fff 0%,rgba(255, 255, 255, 0.337) 50%,rgba(255, 255, 255, 0) 100%)",
    },
    "& .GridCardImgContainer":{
        position: "relative",
        height: "inherit"
    },
    "& .CardImg":{
        height: "100%",
        width: "100%",
    },
    "& .GridContentContainer":{
        backgroundColor: "white",
        justifyContent: "space-between",
        padding: "20px 16px 16px",
        boxSizing: "border-box",
        height: "inherit",
        display: "flex",
        flexDirection: "column"
    },
    "& .MuiAvatarGroup-avatar":{
        borderWidth: "1px",
        width: "32px",
        height: "32px",
        fontSize: "14px"
    },
});
export default withStyles(styles, { withTheme: true })(LandingScreenNew);
// Customizable Area End