import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/utils";
// Customizable Area Start
import React from "react";
import { blueLike } from "./assets";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

export interface Comment {
    id: string;
    type: string;
    attributes: {
        body: string;
        created_at: string;
        cerated_time: string;
        account_id: number;
        full_name: string;
        profile_picture: string;
        child_comment: {
            data: Comment[];
        };
        comment_media: {
            file: string;
        }[];
        comment_liked_by_login_user: boolean;
        comment_like_count: number;
        comment_count: number;
    };
}
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    postId: string;
    data: Comment;
    hasParent: boolean;
    openChildrenId: string;
    refreshData: () => void;
    setOpenChildrenId: (id: string) => void;
    commentId: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    subCommentMessage: string;
    expandId: string;
    openImgModel: boolean;
    showAll: boolean;
    error: string
    file: File | null,
    parentFiledOpen: boolean,
    imageUrl: string,
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SingleChatListController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    sendCommentApi: string = "";
    likeUnlikeApi: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            subCommentMessage: "",
            expandId: "",
            showAll: false,
            openImgModel: false,
            error: "",
            file:  null,
            imageUrl: "",
            parentFiledOpen: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId1 = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson1 = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId1 && responseJson1) {
                if (!responseJson1.errors) {
                    this.handleResponse(responseJson1, apiRequestCallId1)
                } else {
                    this.parseApiErrorResponse(responseJson1);
                }
            }
        }
    }

    handleResponse = (responseJson1: any, apiRequestCallId1: string) => {
        if (apiRequestCallId1 === this.sendCommentApi) return this.commentResponse(responseJson1);
        if (apiRequestCallId1 === this.likeUnlikeApi) return this.likeUnlikeResponse(responseJson1)
    }

    commentResponse = async (responseJson: any) => {
        if (responseJson.data) {
            this.setState({ subCommentMessage: "" });
            this.props.refreshData();
        }
    }

    likeUnlikeResponse = async (responseJson: { data: [{ message: string }] }) => {
        if (responseJson.data) {
            this.props.refreshData();
        }
    }

    handleChangeSubComment = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ subCommentMessage: e.target.value })
    }

    sendSubComment = async () => {
        if (!this.state.subCommentMessage) return
        const loginToken = await getStorageData('LoginSuccessToken');
        const signupToken = await getStorageData('SignupSuccessToken');
        let body = new FormData();
        body.append("post_id", this.props.postId);
        body.append("comment", this.state.subCommentMessage);
        // body.append("comment_id", this.props.data.id) use this for nested comments elsebelow is for sameparent
        body.append("comment_id", this.props.commentId);

        this.sendCommentApi = sendAPIRequest(configJSON.createCommentEnd, {
            method: configJSON.PostAPiMethod,
            body,
            headers: {
                token: signupToken || loginToken
            }
        });
    }

    handleLikeComment = async (id: string) => {
        const loginToken = await getStorageData('LoginSuccessToken');
        const signupToken1 = await getStorageData('SignupSuccessToken');
        let body = new FormData();
        body.append("comment_id", id);


        this.likeUnlikeApi = sendAPIRequest(configJSON.like_unlike_comment, {
            method: "POST",
            body,
            headers: {
                token: signupToken1 || loginToken
            }
        });
    }

    commentTime = (time: string) => {
        if (time === "less than a minute ago") return "just now"
        return time;
    }

    expandComment = (id:string,parent:boolean) => {     
        const temp= this.state.expandId === id? "" : id;   
        if(parent) {
            this.props.setOpenChildrenId(id)
            this.setState({parentFiledOpen: parent})
        }else{
            this.setState({ expandId: temp });
        }
    };

    showLike = (data: Comment) => {
        if (data.attributes) {
            const isLikedByCurrentUser = data.attributes.comment_liked_by_login_user;
            const likeCount = data.attributes.comment_like_count ? data.attributes.comment_like_count : 0;
            if (isLikedByCurrentUser) {
                if (likeCount > 1) {
                    return <><img src={blueLike} alt="blueLike" />You and {likeCount - 1} other</>
                } else {
                    return <><img src={blueLike} alt="blueLike" />You</>
                }
            } else if (likeCount > 0) {
                return <>{likeCount} like</>
            }
        }
    }

    showComment = (data: Comment) => {
        if (data.attributes) {
            return data.attributes.comment_count > 0 ? `${data.attributes.comment_count} comments` : ""
        }
    }

    handleShowAll = () => {
        this.setState({ showAll: !this.state.showAll })
    }

    handleClose = () => {
        this.setState({ openImgModel: false, imageUrl: "" })
    };

    handleCloseImgModel = async () => {
        const {file}= this.state;
        this.handleClose();
        if (file) {
            const signupToken = await getStorageData('SignupSuccessToken');
            const loginToken = await getStorageData('LoginSuccessToken');
            let body = new FormData();
            body.append("post_id", this.props.postId);
            body.append("comment", this.state.subCommentMessage);
            // body.append("comment_id", this.props.data.id); use this for nested comments elsebelow is for sameparent
            body.append("comment_id", this.props.commentId)
            body.append("comment_media[]", file);

            this.sendCommentApi = sendAPIRequest(configJSON.createCommentEnd, {
                method: configJSON.PostAPiMethod,
                body,
                headers: {
                    token: signupToken || loginToken
                }
            });
        }
    }

    handleOnImportFile = (fileData: FileList) => {
        if (fileData.length > 1) return this.setState({ error: "select only one image" });
        let exte = fileData[0].name.split('.').pop() as string;

        if (!["png", "jpg", "jpeg"].includes(exte)) return this.setState({ error: "image formate not supported" });

        this.setState({
            imageUrl: URL.createObjectURL(fileData[0]),
            file: fileData[0],
        })

    };

    handleOpenUploadBtn = () => {
        document.getElementById("fileInput")?.click();
    }

    replaceUrl = (text: string) => {
        let urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function(url) {
            return `<a href="${url}">${url}</a>`;
        });
    }
    // Customizable Area End
}