import React from "react";

// Customizable Area Start
import ViewProfileScreenController, {
  Props,
} from "./ViewProfileScreenController.web";
import UserProfileWallpaperSection from "./UserProfileWallpaperSection.web";
import UserProfileInformationSection from "./UserProfileInformationSection.web";
import OrganizationProfileSectionWeb from "./OrganizationProfileSection.web";
import ActivitySection from "./ActivitySection.web";
import AchievementSection from "./AchievementSection.web";
import ChangeWallpaperModal from "./ChangeWallpaperModal.web";
import LandingScreenNewWeb from "../../landingpage/src/LandingScreenNew.web";
import { CircularProgress, Box } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start

export default class ViewProfileScreen extends ViewProfileScreenController {
  constructor(props: Props) {
    super(props);
  }
  subRender = () => {
    return (
      <UserProfileInformationSection
        data={{
          id: "",
          attributes: {
            email: "",
            dob: "",
            full_phone_number: "",
            pronouns: "",
            race: "",
            ethnicity: "",
            gender: "",
            timezone: "",
            language: [],
            is_private: false,
            social_media_urls: [],
            selected_communities: null,
          },
        }}
        navigation={undefined}
        id={""}
        isCommunity={false}
        isOwnProfile={false}
      />
    );
  };

  userProfileWallpaper = () => {
    return (
      <div
        style={{
          maxWidth: this.state.communityFlag == "true" ? "94%" : "100%",
        }}
      >
        <UserProfileWallpaperSection
          data={{
            id: "",
            attributes: {
              friend_list_count: 0,
              follower_count: 0,
              background_image: null,
              profile_picture: "",
              full_name: "",
              email: "",
              is_friend_request_sent: false,
              is_already_request_available: false,
              is_in_connection: false,
              is_followed_by_login_user: false,
              city: "",
              state: "",
              job_title: "",
            },
          }}
          navigation={undefined}
          id={""}
          isOwnProfile={false}
          isCommunity={false}
          is_private={false}
        />
      </div>
    );
  };
  render() {
    const { profileDetails } = this.state;
    return this.state.loader && this.state.communityFlag == "false" ? (
      <Box marginTop="12rem" marginLeft="24rem">
        <CircularProgress />
      </Box>
    ) : (
      <React.Fragment>
        {this.userProfileWallpaper()}
        {this.state.communityFlag == "false" && (
          <>
            <this.subRender />
            <OrganizationProfileSectionWeb
              data={{
                attributes: {
                  organization: null,
                  is_private: false,
                },
              }}
              navigation={undefined}
              id={""}
              isOwnProfile={false}
            />
            <ActivitySection
              accountType={""}
              profileId={""}
              navigation={this.props.navigation}
            />
          </>
        )}
        <AchievementSection
          isOwnProfile={true}
          navigateCommunity={this.handleNavigateCommunity}
          removeAchievement={this.removeAchievement}
          data={(profileDetails?.achievements?.data ?? []).map((item) => ({
            id: item.id,
            type: item.type,
            attributes: item.attributes,
          }))}
          navigation={this.props.navigation}
        />
        {this.state.communityFlag == "true" && (
          <LandingScreenNewWeb navigation="" id="" resetData={() => {}} />
        )}
        <ChangeWallpaperModal
          openModal={this.state.showChangeWallpaperModal}
          handleClose={() => this.setEditWallpaperModal(false)}
          handleSubmitClick={this.handleChangeWallpaperSubmit}
        />
      </React.Fragment>
    );
  }
}
// Customizable Area End
