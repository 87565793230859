import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify"
// Customizable Area Start
import { isLocalAdmin } from "../../../components/src/common";
import React from "react";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    classes?: any;
    resetData?: () => void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean;
    followersList: {name: string, id: string, profilePicture: string, is_blocked_by_community: boolean}[];
    isCommunity: boolean;
    showSearchModal: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class FollowersListController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getFollowersListApiCallId: string = "";
    userId: string = "";
    signupToken: string = "";
    loginToken: string = "";
    removeFollowerApiCallId: string = "";
    isOwnUser: boolean = false;
    followerListRef: React.RefObject<any>;
    commun: "COMMUNITY" | "FOLLOWERS" = "FOLLOWERS";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.followerListRef = React.createRef();

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),

            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ]
        this.state = {
            followersList: [],
            isLoading: false,
            isCommunity: false,
            showSearchModal: false
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
        // Customizable Area End
    }

    async receive(_from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );


        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId === this.getFollowersListApiCallId) {
            this.changeLoadingStatus(false)
            if (responseJson.errors) {
                responseJson.errors.forEach((error: any) => {
                    toast.error(Object.values(error).join(","))
                })
                this.setState({
                    followersList:[]
                })
            } else if (responseJson.data) {
                this.setState({
                    followersList: responseJson.data.map((data: {id: string ,attributes: {profile_picture: string,full_name: string,is_blocked_by_community?: boolean}}) => ({ profilePicture: data.attributes.profile_picture,name: data.attributes.full_name, id: data.id, is_blocked_by_community: Boolean(data.attributes.is_blocked_by_community) }))
                })
            }
        }
        if(apiRequestCallId === this.removeFollowerApiCallId){
            this.changeLoadingStatus(false);
            if(responseJson.data && responseJson.data.message){
                toast.success(responseJson.data.message);
                this.getFollowersList(this.state.isCommunity);
            } else if(responseJson.errors){
                const errors = responseJson.errors;
                errors.forEach((error: any) => {
                    toast.error(Object.values(error).join(","))
                })
            }
        }
        // Customizable Area End
    }


    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const { signupToken, loginToken, profileData } = await this.getSignupLoginTokens();

        this.signupToken = signupToken;
        this.loginToken = loginToken;

        const param = this.props.navigation.getParam("id");

        if (param) { // for community and account open from elastic search and view profile
            if (param.includes("community")) {
                this.userId = param.split('-')[1];
                this.commun = "COMMUNITY";
                this.isOwnUser = param.split('-')[1] === profileData.id
                this.setState({ isCommunity: true });
                this.getFollowersList(true);
            } else {
                this.userId = param;
                this.commun = "FOLLOWERS"
                this.isOwnUser = param === profileData.id
                this.setState({ isCommunity: false });
                this.getFollowersList(false);
            }
        }
    }

    getSignupLoginTokens = async () => {
        let signupToken = await getStorageData('SignupSuccessToken');
        let loginToken = await getStorageData('LoginSuccessToken');
        let profileData = await getStorageData("profileData", true);

        return { signupToken, loginToken, profileData };
    }

    changeLoadingStatus = (show: boolean) => {
        this.setState({
            isLoading: show
        })
    }
    getFollowersList = (isLA: boolean) => {
        this.changeLoadingStatus(true)
        const header = {
            token: this.signupToken || this.loginToken,
            "Content-Type": configJSON.apiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getFollowersListApiCallId = requestMessage.messageId;
        const communityParam = this.userId ? configJSON.getFollowersListApiEndpoint + `?followed_id=${this.userId}&type=AccountBlock::Community` : configJSON.getFollowersListApiEndpoint
        const param = isLA ? communityParam : configJSON.getFollowersListApiEndpoint + `?type=AccountBlock::Account&followed_id=${this.userId}`
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            param
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getFollowersListApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    removeFollower = (id: string) => {
        this.changeLoadingStatus(true)
        const header = {
            token: this.signupToken || this.loginToken,
            "Content-Type": configJSON.apiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.removeFollowerApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.removeFollowerApiEndpoint + `?account_id=${id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.removeFollowerApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        this.props.resetData?.();
    }

    showSearchModal = () => {
        this.setState({
            showSearchModal:true,
        })
    }

    closeSearchModal = async () => {
        const isLa =await isLocalAdmin();
        this.getFollowersList(isLa)
        this.setState({
            showSearchModal: false
        });
    }
    // Customizable Area End

}
